import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React from "react";
import * as dataForge from "data-forge"
import ExcelJS from "exceljs"
import { NoteItem, ReplenItem, SingularItem } from "../../types/OmegaTypes";
import dayjs from "dayjs";
import { useOmega } from "../../contexts/OmegaContext";

const PrepData = (data: SingularItem[], filteredData: number[], changesMade: NoteItem[], replenData: ReplenItem[]) => {
    const globalFrame = new dataForge.DataFrame(data)
        .where((item) => filteredData.length === 0 || (filteredData.length > 0 && filteredData.includes(item.key as number)))

    console.log('export data', globalFrame.head(5).toArray())

    const firstSheetData = globalFrame

    const secondSheetData = new dataForge.DataFrame(changesMade);

    const thirdSheetData = new dataForge.DataFrame(replenData);

    const fourthSheetData = globalFrame.select((row) => {
        const relNote = changesMade.find((note) => note.SKU === row.SKU);

        const newRow = {
            'sku': row.SKU,
            'min_price':  relNote ? relNote.stats.newMin : row.ssItem.min_price,
            'max_price': relNote ? relNote.stats.newMax : row.ssItem.max_price,
            'reprice_config_preset': relNote ? relNote.stats.newPreset : row.ssItem.reprice_config_preset,
            'tags': row.ssItem.tags.join(';')
        }

        return newRow
    });


    const workbook = new ExcelJS.Workbook();

    const firstSheet = workbook.addWorksheet('Item Data');
    firstSheet.columns = firstSheetData.getColumnNames().map((column) => ({ header: column, key: column }));
    const secondSheet = workbook.addWorksheet('Notes Saved');
    secondSheet.columns = secondSheetData.getColumnNames().map((column) => ({ header: column, key: column }));
    const thirdSheet = workbook.addWorksheet('Replens Saved');
    thirdSheet.columns = thirdSheetData.getColumnNames().map((column) => ({ header: column, key: column }));
    const fourthSheet = workbook.addWorksheet('SellerSnap Import');
    fourthSheet.columns = fourthSheetData.getColumnNames().map((column) => ({ header: column, key: column }));

    firstSheet.addRows(firstSheetData.toArray());
    secondSheet.addRows(secondSheetData.toArray());
    thirdSheet.addRows(thirdSheetData.toArray());
    fourthSheet.addRows(fourthSheetData.toArray());

    return workbook;
}

interface ExportDownloadProps {
    data: SingularItem[],
    filteredData: number[],
}

export function ExcelExport({ data, filteredData }: ExportDownloadProps) {
    const { changesMade, replenData } = useOmega();

    const download = async () => {
        if (!data || !data.length) return;

        const workbook = PrepData(data, filteredData, changesMade, Object.values(replenData));
        const text = await workbook.xlsx.writeBuffer();

        const file = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = 'Omega_' + dayjs().format('MM-DD-YY') + ".xlsx";
        a.click();
        URL.revokeObjectURL(url);
        a.parentNode?.removeChild(a);
    };

    return (
        <Space>
            <Button
                type="primary"
                onClick={download}
                disabled={!data || data.length === 0}
            >
                <DownloadOutlined /> Download XLSX
            </Button>
        </Space>
    );
}
