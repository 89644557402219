import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: 'AIzaSyDlm-DsYfzKWtCbonLxJsVyZ765DUeZt4I',
    authDomain: 'projectsuite-scan.firebaseapp.com',
    databaseURL: 'https://projectsuite-scan.firebaseio.com',
    projectId: 'projectsuite-scan',
    storageBucket: 'projectsuite-scan.appspot.com',
    messagingSenderId: '1711328969',
    appId: '1:1711328969:web:aa7a3af29989b013',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const firestore = app.firestore();
export default app;
