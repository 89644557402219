import React from "react";
import {Button, Space} from "antd";
import {InboundPallet} from "../../../types/WarehouseTypes";
import {PlusOutlined} from "@ant-design/icons";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import BoxView from "./BoxView";

interface PalletViewProps {
    pallet: InboundPallet;
}

const PalletView: React.FC<PalletViewProps> = ({pallet}) => {
    const {boxesStore} = useStore(
        useShallow((state) => ({
            boxesStore: state.boxes,
            palletsStore: state.pallets,
        }))
    );
    const boxingActions = useStore(
        useShallow((state) => ({
            addItem: state.addItem,
            addBox: state.addBox,
        }))
    );

    return (
        <Space direction="vertical" style={{height: "35vw", overflow: "auto", width: "100%"}}>
            <Space direction="horizontal" wrap>
                {pallet.boxesIds.map((boxId) => (
                    <BoxView key={boxId} box={boxesStore.byId[boxId]} />
                ))}
                <Button
                    style={{width: 50, height: 50}}
                    icon={<PlusOutlined style={{fontSize: 40}} />}
                    onClick={() => boxingActions.addBox(pallet.id)}
                />
            </Space>
        </Space>
    );
};

export default PalletView;
