import {Col, Row, Tabs, TabsProps} from "antd";
import React from "react";
import BrandResearch from "../components/brand/BrandResearch";
import BrandDashboard from "../components/dashboard/BrandDashboard";
import {SupplierProvider} from "../contexts/SupplierContext";
import {useTabLocationDashboard} from "../components/dashboard/utilities/queryTab";
import BrandInsights from "../components/dashboard/BrandInsights";
import {SupplierNamesBrowser} from "../components/omega/oms/SupplierNamesBrowser";

const items: TabsProps["items"] = [
    {
        key: "list",
        label: `List`,
        children: <SupplierNamesBrowser></SupplierNamesBrowser>,
    },
    {
        key: "research",
        label: `Brand Research`,
        children: <BrandResearch></BrandResearch>,
    },
    {
        key: "dashboard",
        label: `Dashboard`,
        children: <BrandDashboard></BrandDashboard>,
    },
    {
        key: "insights",
        label: `Insights`,
        children: <BrandInsights></BrandInsights>,
    },
];

const Brand: React.FC = () => {
    const [activeKey, onChange] = useTabLocationDashboard(
        items.map((item) => item.key),
        "dashboard",
        "brand_tab"
    );

    return (
        <SupplierProvider>
            <Row>
                <Col span={24}>
                    <Tabs activeKey={activeKey} items={items} onChange={onChange}></Tabs>
                </Col>
            </Row>
        </SupplierProvider>
    );
};

export default Brand;
