import { Button, Divider, InputRef, notification, Space, Switch } from 'antd';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    FreightLogRow,
    FreightLogRowRenderType,
} from '../../../types/OmegaTypes';
import {
    makeColumnsEditable,
    makeFreightLogColumns,
} from '../../utilities/OMSColumns';
import { EditableColumnType } from '../table/EditableCell';
import * as _ from 'lodash';
import { UnsavedChangesHandler } from '../../utilities/UnsavedChangesHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../contexts/AuthContext';
import {
    deleteFreightLog,
    getFreightLog,
    updateFreightLog,
} from '../../../services/WholesaleService';
import { DeleteFilled } from '@ant-design/icons';
import EditableTable, { EditableTableHandle } from '../table/EditableTable';
import { validateFreightLog } from '../../utilities/TableDataValidation';
import { freightLogTransformation } from '../../utilities/OMSCalculations';

const defaultItem: FreightLogRow = {
    Ship_Requested: new Date(),
    ShipDateBooked: new Date(),
    ShippedTo: '',
    TotalCost: 0,
    UnitCost: 0,
    Booked: false,
    Supplier_Name: '',
    Timestamp: new Date(),
};

export const FreightLogBrowser: React.FC = () => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<FreightLogRowRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] =
        useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const queryClient = useQueryClient();
    const tableRef = useRef<EditableTableHandle<FreightLogRowRenderType>>(null);

    const {
        data: freightLogData,
        isLoading: freightLogDataLoading,
        isRefetching,
        refetch,
        error
    } = useQuery({
        queryKey: ['freight_log'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const data = (await getFreightLog(token)) as FreightLogRow[];
            return data;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: 'Error',
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const syncTableData = useCallback(() => {
        if (freightLogData) {
            setTableData(
                freightLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [freightLogData, fetchTimestamp]);

    const omsMutation = useMutation({
        mutationFn: async (freightLogItems: FreightLogRow[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error('Auth token not found');
            }

            if (!freightLogData) {
                throw new Error('Freight Log data was not fetched correctly');
            }

            const originalIds = freightLogData.map(item => item._id);
            const localIds = freightLogItems.map(item => item._id);
            const idsToDelete = _.difference(originalIds, localIds);
            const returnedFreightLogData = await updateFreightLog(
                token,
                freightLogItems
            );
            await deleteFreightLog(
                token,
                idsToDelete.filter(id => id !== undefined) as string[]
            );

            return returnedFreightLogData;
        },
        onSuccess: ledger => {
            queryClient.setQueryData(['freight_log'], ledger);
            const tableData = ledger.map((item, idx) => ({
                ...item,
                key: idx,
            }));
            setTableData(tableData);
            setFetchTimestamp(new Date());
            notification.success({
                message: 'Push successful!',
            });

            // Refetch data to sync with master
            refetch({ throwOnError: true });
        },
        onError: error => {
            notification.error({
                message: 'Upload error',
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: FreightLogRowRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (
            row: FreightLogRowRenderType,
            column?: keyof FreightLogRowRenderType
        ) => {
            const newData = [...tableData];
            const index = newData.findIndex(item => row.key === item.key);
            const item = newData[index];
            const newRow = freightLogTransformation(item, row, column);
            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [freightLogData, syncTableData]);

    const columns = useMemo<
        EditableColumnType<FreightLogRowRenderType>[]
    >(() => {
        const freightLogColumns = makeFreightLogColumns(searchInputRef);
        if (editable) {
            freightLogColumns.push({
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter(item =>
                                record._id
                                    ? item._id !== record._id
                                    : item.key !== record.key
                            );

                            setLastUpdateTimestamp(new Date());
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    />
                ),
                fixed: 'right',
                width: '60px',
            });
            return makeColumnsEditable(freightLogColumns, handleSave);
        } else {
            return freightLogColumns;
        }
    }, [handleSave, editable, tableData]);

    const addNewRow = () => {
        const newItem: FreightLogRowRenderType = {
            ...defaultItem,
            key: 0,
            Timestamp: new Date(),
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({ ...item, key: idx })));
    };

    const onDiscardChanges = () => {
        if (freightLogData) {
            setTableData(
                freightLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        const errors = validateFreightLog(tableData);
        if (errors.length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const tableTitle = () => (
        <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'end' }}
            split={<Divider type='vertical' />}
        >
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending || isRefetching}
                onDiscardChanges={onDiscardChanges}
                onSaveChanges={onSaveChanges}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                onClick={() => {
                    addNewRow();
                }}
                disabled={!editable}
            >
                Add row
            </Button>
        </Space>
    );

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <EditableTable<FreightLogRowRenderType>
                ref={tableRef}
                title={tableTitle}
                tableData={tableData}
                columns={columns}
                loading={freightLogDataLoading}
                onRow={row => {
                    let style = {};
                    if (!row.IsProcessed) {
                        style = {
                            backgroundColor: '#518A3D55',
                        };
                    }
                    return {
                        style,
                    };
                }}
            />
        </Space>
    );
};
