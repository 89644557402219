import {RcFile} from "antd/es/upload";
import {FileWithBytes} from "../../../types/WarehouseTypes";
import {randID} from "../boxing/helpers";

export async function imageUrlToBlob(imageUrl: string) {
    try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error("Error converting image URL to Blob:", error);
    }
}

export async function convertToUploadFile(imageUrl: string, name: string): Promise<FileWithBytes> {
    const blob = await imageUrlToBlob(imageUrl);
    const bytes = await getFileBytes(blob as File);
    const id = randID();
    return {
        file: {
            uid: id,
            name,
            url: imageUrl,
            originFileObj: blob as RcFile,
        },
        bytes,
    };
}

export async function getFileBytes(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as ArrayBuffer);
        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(file);
    });
}

export function bytesToBase64(bytes: ArrayBuffer): string {
    const uint8Array = new Uint8Array(bytes);
    const binaryString = String.fromCharCode(...Array.from(uint8Array));
    return btoa(binaryString);
}
