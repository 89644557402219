import {
    Batch,
    BatchCategory,
    InboundBox,
    InboundPallet,
    InboundShipment,
    UPCMapping,
    UPCNotif,
    WarehouseAddress,
    WarehouseProduct,
    WarehouseShipLaterProduct,
    ReturnProduct,
    FileWithBytes,
} from "../types/WarehouseTypes";
import {deleteManageDB, getManageDB, postManageDB} from "./OmegaService";
import {makeCall} from "./common";
import {getInboundShipments} from "./SPApiService";
import {hextorstr, stob64, KEYUTIL, KJUR} from "jsrsasign";
import qz, {PrintData} from "qz-tray";
import {QZ_CERTIFICATE, QZ_PRIVATE_KEY} from "./constants";
import {TransportDetailInput} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";

export const MARKETPLACE_ID = "ATVPDKIKX0DER";

export const GetStoredItems = (token: string) => {
    return getManageDB(token, "prelisting", "warehouse_stored");
};

export function SaveStoredItem(token: string, item: WarehouseShipLaterProduct) {
    return postManageDB(token, "prelisting", "warehouse_stored", [item], []);
}

export function UpdateStoredItem(token: string, item: WarehouseShipLaterProduct) {
    return postManageDB(token, "prelisting", "warehouse_stored", [item], ["_id"]);
}

export function DeleteStoredItem(token: string, id: string) {
    return deleteManageDB(token, "prelisting", "warehouse_stored", "_id", id);
}

export async function getReceivedBatches() {
    const res = await makeCall("shipments_v2/receiving/getBatches", {});
    return res.result as Batch[];
}

export async function saveReceivedProducts(products: Partial<WarehouseProduct>[]) {
    await makeCall("shipments_v2/receiving/addProducts", {}, "POST", {
        products,
    });
}

export async function deleteReceivedProducts(products: {asin: string; batchId: string}[]) {
    await makeCall("shipments_v2/receiving/deleteProducts", {}, "DELETE", {
        products,
    });
}

/**
 * Creates a new batch in the given category.
 * @param category
 * @param products
 */
export async function finishBatch(category: BatchCategory): Promise<Batch> {
    const params = {
        category,
    };

    const res = await makeCall("shipments_v2/receiving/finishBatch", params, "POST");

    return res.result as Batch;
}

export async function updateShipments() {
    await makeCall("shipments_v2/receiving/updateShipments", {});
}

export async function getShipments(address?: WarehouseAddress): Promise<{
    shipments: InboundShipment[];
    pallets: InboundPallet[];
    boxes: InboundBox[];
}> {
    let updatedAfter = new Date();
    updatedAfter.setDate(updatedAfter.getDate() - 14);
    let updatedBefore = new Date();
    let response = await getInboundShipments({
        marketplaceId: MARKETPLACE_ID,
        queryType: "DATE_RANGE",
        lastUpdatedAfter: updatedAfter.toISOString(),
        lastUpdatedBefore: updatedBefore.toISOString(),
        shipmentStatusList: ["WORKING"],
        devMode: false,
    });
    let nextToken = response.NextToken;
    let shipments = response.ShipmentData || [];
    while (nextToken) {
        response = await getInboundShipments({
            marketplaceId: MARKETPLACE_ID,
            queryType: "NEXT_TOKEN",
            nextToken: nextToken,
            devMode: false,
        });
        nextToken = response.NextToken;
        shipments = shipments.concat(response.ShipmentData || []);
    }

    shipments = shipments.filter((s) => /.*WISC RAPIDS/.test(s.ShipmentName || ""));

    let ids = shipments.map((s) => s.ShipmentId);
    // let ids = ["FBA187Y819CK", "FBA15Q7SKWBL", "FBA185MXYLV7", "FBA1861S9HZ7"];
    // let ids = ["FBA1861S9HZ7", 'FBA187PRZHXL'];
    let backendResponse = await makeCall("shipments_v2/boxing/getShipments", {
        shipmentIds: JSON.stringify(ids),
    });
    if (backendResponse.error) {
        throw new Error(backendResponse.error);
    }
    let returnedData = backendResponse.result;
    let returnedShipments: InboundShipment[] = returnedData.shipments;
    if (address) {
        returnedShipments = returnedShipments.filter(
            (s) =>
                s.ShipFromAddress.AddressLine1 === address.addressLine1 &&
                s.ShipFromAddress.City === address.city &&
                s.ShipFromAddress.StateOrProvinceCode === address.stateOrProvinceCode &&
                s.ShipFromAddress.PostalCode.toString() === address.postalCode
        );
    }
    return {
        shipments: returnedShipments,
        pallets: returnedData.pallets,
        boxes: returnedData.boxes,
    };
}

export async function saveShipmentsData(shipments: InboundShipment[], pallets: InboundPallet[], boxes: InboundBox[]) {
    await makeCall("shipments_v2/boxing/saveData", {}, "POST", {
        shipments,
        pallets,
        boxes,
    });
}

export async function removeShipmentsData(palletIds: string[], boxIds: string[]) {
    await makeCall("shipments_v2/boxing/removeData", {}, "DELETE", {
        palletIds,
        boxIds,
    });
}

export async function generateLabel(
    fnsku: string,
    name: string,
    upc: string,
    centerId: string | undefined,
    labelType: "ZPL" | "IMAGE"
): Promise<PrintData[] | string[]> {
    const params: any = {
        fnsku,
        title: name,
        upc,
    };

    if (centerId) {
        params.center_id = centerId;
    }

    const endpointMap = {
        ZPL: "zpl_label_gen",
        IMAGE: "label_gen",
    };

    const res = await makeCall(endpointMap[labelType], params, "GET");

    if (labelType === "IMAGE") {
        return [
            {
                type: "pixel",
                format: "image",
                data: res.label as string,
                flavor: "base64",
                // options: { language: "ZPL" },
            },
        ];
    } else {
        return res.data as string[];
    }
}

export async function setupPrinter(): Promise<string[]> {
    // qz.api.setPromiseType(function promise(resolver) {
    //     return new Promise(resolver);
    // });
    // qz.api.setSha256Type(function (data) {
    //     return sha256(data);
    // });
    const qzActive = qz.websocket.isActive();
    if (!qzActive) {
        qz.security.setSignatureAlgorithm("SHA1");
        qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
                try {
                    let pk = KEYUTIL.getKey(QZ_PRIVATE_KEY);
                    let sig = new KJUR.crypto.Signature({alg: "SHA1withRSA"});
                    sig.init(pk);
                    sig.updateString(toSign);
                    let hex = sig.sign();
                    resolve(stob64(hextorstr(hex)));
                } catch (err: any) {
                    console.error(err);
                    reject(err);
                }
            };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
            resolve(QZ_CERTIFICATE);
        });

        await qz.websocket.connect();
    }
    const printers = await qz.printers.find();

    let result: string[] = [];
    if (typeof printers === "string") {
        result.push(printers);
    } else {
        result = printers;
    }

    return result;
}

export async function printLabel(printer: string, label: PrintData[] | string[], quantity: number = 1) {
    const config = qz.configs.create(printer, {
        margins: {top: 0.25, right: 0.25, bottom: 0.25, left: 0.25},
        size: {width: 2.7, height: 1.5},
        units: "in",
        colorType: "grayscale",
        interpolation: "nearest-neighbor",
        density: 203,
        copies: quantity,
    });

    await qz.print(config, label);
}

export async function prepareTransportDetails(shipmentId: string): Promise<TransportDetailInput> {
    const res = await makeCall("shipments_v2/boxing/prepareTransportDetails", {shipmentId});
    return res.result as TransportDetailInput;
}

export async function addUpcMapping(upc: string, asin: string) {
    const res = await makeCall("warehouse/addMapping", {}, "POST", {
        upc,
        asin,
    });

    return res.result as UPCMapping;
}

export async function deleteUpcMapping(upc: string, asin: string) {
    const res = await makeCall("warehouse/deleteMapping", {}, "DELETE", {
        upc,
        asin,
    });

    return res.result as UPCMapping;
}

export async function getMappings(codes?: string[], codeType?: "upc" | "asin"): Promise<UPCMapping[]> {
    const res = await makeCall("warehouse/getMappings", {codes: codes?.join(","), codeType}, "GET");
    return res.result as UPCMapping[];
}

export async function getUpcNotifs(codes: string[]): Promise<UPCNotif[]> {
    const res = await makeCall("upcNotifs/getMany", {upcs: codes.join(",")}, "GET");
    return res.message;
}

export async function createInitialShipments(ids: string[]) {
    const res = await makeCall("shipments_v2/receiving/createInitialShipments", {}, "POST", {
        shipmentIds: ids,
    });

    return res.result;
}

export async function getReturnProducts(): Promise<ReturnProduct[]> {
    const res = await makeCall("returns_v2/getProducts", {limit: 5000}, "GET");
    return res.result as ReturnProduct[];
}

export const uploadPhotos = async (files: FileWithBytes[], uploadPreset: string = "returns"): Promise<string[]> => {
    const timestamp = Date.now();
    const uploadedUrls = await Promise.all(
        files.map(async ({bytes, file}) => {
            const result = await makeCall("cloudinary/sign", {}, "POST", {
                apiOptions: {
                    timestamp,
                    upload_preset: uploadPreset,
                    public_id: file.uid,
                },
            });
            const {signature, apiKey} = result;
            const uploadResult = await uploadToCloudinary(bytes, signature, timestamp, apiKey, file.uid, uploadPreset);
            return uploadResult;
        })
    );

    return uploadedUrls;
};

export async function uploadToCloudinary(
    bytes: ArrayBuffer,
    signature: string,
    timestamp: number,
    apiKey: string,
    publicId?: string,
    uploadPreset: string = "returns"
): Promise<string> {
    // Prepare form data for Cloudinary upload
    const cloudName = "mooregroup";
    const formData = new FormData();
    formData.append("file", new Blob([bytes]));
    formData.append("api_key", apiKey);
    formData.append("timestamp", timestamp.toString());
    formData.append("signature", signature);
    formData.append("upload_preset", uploadPreset);
    if (publicId) {
        formData.append("public_id", publicId);
    }

    // Upload to Cloudinary using fetch
    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
        method: "POST",
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const uploadResult = await response.json();
    return uploadResult.public_id;
}
