import { BrandDashboardItem, BrandDashboardJournalEntry, BrandData, GlobalNoteItem } from "../types/Brand";
import { getFilteredManageDB, postManageDB } from "./OmegaService";
import { ApiKey, BrandApiKey } from "./constants";
import { BASE_URL } from "./WholesaleService";
import { Delta } from "quill/core";

export function getProducts(collection: string): Promise<BrandData> {
    return fetch(
        `${BASE_URL}/brand?collection=${collection}&key=${BrandApiKey}`
    )
        .then((res) => res.json())
        .then((res) => res.result)
}

export function getCollections(): Promise<string[]> {
    return fetch(
        `${BASE_URL}/brand/collections?key=${BrandApiKey}`
    )
        .then((res) => res.json())
        .then((data) =>
            data.result
                .map((col: any) => col.name)
        );
}

export function postSupplierItems(token: string, items: BrandDashboardItem[]) {
    const updateData = items.map(({ insertTimestamp, Notes, ...item }) => item)
    return postManageDB(token, 'prelisting', 'supplier_items', updateData, ['_id'])
}

export function postSupplierNotes(token: string, items: {
    _id?: string,
    Brand: string,
    title: string,
    ops: Delta[]
}[]) {
    return postManageDB(token, 'prelisting', 'supplier_notes', items, ['_id'])
}

export function postSupplierJournals(token: string, items: BrandDashboardJournalEntry[]) {
    items.forEach((item) => {
        delete item.insertTimestamp
        delete item._id
    })
    return postManageDB(token, 'prelisting', 'supplier_journals', items, [])
}

export function getSupplierNotes(token: string, supplierName: string) {
    return getFilteredManageDB(token, 'prelisting', 'supplier_notes', 'Brand', supplierName)
}

export function getSupplierItems(token: string, supplierName: string) {
    return getFilteredManageDB(token, 'prelisting', 'supplier_items', 'Brand', supplierName)
}

export function getSupplierJournals(token: string, supplierName: string) {
    return getFilteredManageDB(token, 'prelisting', 'supplier_journals', 'Brand', supplierName)
        .then((res) => (res as BrandDashboardJournalEntry[]))
        .then((res) => res.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded) })))
}

export function importProducts(collection: string, brandData: BrandData, token: string) {
    return fetch(`${BASE_URL}/brand?collection=${collection}&firebase_token=${token}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(brandData)
    })
}

export function postSupplierUpdate(token: string, data: any) {
    return postManageDB(token, 'prelisting', 'suppliers', [data], ['name'])
}


export function saveParentProducts(token: string, uploadName: string, items: any[]) {
    items.forEach((item) => {
        delete item.insertTimestamp
        delete item._id
    })

    return postManageDB(token, 'prelisting', 'brand_insight_items', items.map((item) => ({ ...item, uploadName: uploadName })), ['asin', 'uploadName'])
}

export function getBrandInsights(token: string, uploadName: string) {
    return getFilteredManageDB(token, 'prelisting', 'brand_insight_items', 'uploadName', uploadName)
}

export function getBrandInsightUploads(token: string) {
    return fetch(
        `${BASE_URL}/brand/getInsightCollections?firebase_token=${token}`
    )
        .then((res) => res.json())
        .then((res) => res.result)
}

export function getBrandInsightSummaries(token: string, uploadNames: string) {
    return fetch(
        `${BASE_URL}/brand/getInsightSummaries?firebase_token=${token}&uploadNames=${uploadNames}`
    )
        .then((res) => res.json())
        .then((res) => res.result)
}

export function postGlobalNote(token: string, items: GlobalNoteItem[]) {
    items.forEach((item) => {
        delete item.insertTimestamp
    })
    // return postManageDB(token, 'global_wholesale', 'notes', items, ['_id'])
    return fetch(`${BASE_URL}/managedb/add?key=${ApiKey}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            listings: items,
            keys: ['_id'],
            db: 'global_wholesale',
            collection: 'notes',
        }),
    }).then((res) => res.json());
}

export function getGlobalNotes(token: string, idType: string, idList: string[]) {
    return fetch(
        `${BASE_URL}/managedb/getSome?firebase_token=${token}&collection=notes&db=global_wholesale`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                searchValue: idList,
                searchKey: idType,
                searchOp: '$in',
                removeId: false
            }),
        }
    )
        .then(res => res.json())
        .then(data => data.result);
}