import { message } from 'antd';
import dayjs from 'dayjs';
import { downloadHelper } from "./Downloads";

// 232464
// BKLM
// $46.00 
// MENS 6H-14(6H-11H)
// 232464
// NVRD
// $46.00 
// MENS 6H-14(6H-11H)
// 237145
// BBK
// $39.00 
// MENS 6H-15(6H-11H)
// Also made in: Wide
// 237145
// NVBK
// $39.00 
// MENS 6H-15(6H-11H)
// Also made in: Wide

const checkForStyleNumber = (line: string) => line?.trim().replace(/WW|W|NA/g, '').match(/^\d+$/)

const ProcessSketchersFile = (data: string) => {
    // transform the above sample data into a csv file
    const lines = data.split(/\r\n|\n/)
    const output: any[] = []
    const sampleObj: any = {
        'Style': '',
        'Color': '',
        'Price': '',
        'Gender': '',
        'Size': '',
        'SizeTransformed': '',
        'AllSizes': '',
        'UPC': '',
    }
    
    let current: any = {...sampleObj}
    let i = 0;

    while (i < lines.length) {
        const line = lines[i];
        if (line.length > 0) {
            // if a line consists of digits only
            // then it's a style number
            if (checkForStyleNumber(line)) {
                current.Style = line?.trim()
                current.Color = lines[i + 1]?.trim()
            }

            let sizeData;
            // check if the next line is a price or a size
            if (lines[i + 2]?.trim().includes('$') || lines[i + 2]?.trim().match(/\d+(?:\.\d{1,2})?$/)) {
                current.Price = lines[i + 2]?.trim()
                sizeData = lines[i + 3]
            } else {
                current.Price = lines[i + 3]?.trim()
                sizeData = lines[i + 2]
            }
            
            if (sizeData) {
                const [gender, sizingData] = sizeData.split(' ')
                const [sizes, halfSizes] = sizingData.split('(')
                const [minSize, maxSize] = sizes.split('-').map(size => size.replace('H', '.5').replace(')', '')).map(size => +size)
                const [minHalfSize, maxHalfSize] = halfSizes.split('-').map(size => size.replace('H', '.5').replace(')', '')).map(size => +size)
    
                // log everything
                // console.log(sizes, halfSizes, minSize, maxSize, minHalfSize, maxHalfSize)
                // console.log((+maxSize) + 1, (+maxHalfSize) + 1)
                
                // generate sizes based on the min and max sizes, with half sizes in between but only up to maxHalfSize
                let allSizes: string[] = []
                for (let i = minSize; i <= (maxSize);) {
                    allSizes.push(`${i}`)
    
                    if (i >= minHalfSize && i <= maxHalfSize) {
                        i += 0.5
                    } else {
                        i++
                    }
                }
    
                const allSizesString = allSizes
                current.AllSizes = allSizesString
                current.Gender = gender
            }
    
            if (checkForStyleNumber(lines[i + 4]) || (i+4) >= lines.length) {
                for (const size of current.AllSizes) {
                    output.push({...current, Size: size, SizeTransformed: size.replace('.5', 'H')})
                }
                current = {...sampleObj}
                i += 4
            } else if (lines[i + 4]?.trim().includes('made in')) {
                for (const size of current.AllSizes) {
                    output.push({...current, Size: size, SizeTransformed: size.replace('.5', 'H')})
                }
                
                const extraSizes = lines[i + 4].trim().match(/(Extra Wide)|(Wide)|(Narrow)/g)
                
                for (const size of (extraSizes ?? [])) {
                    const newCurrent = {...current}
                    newCurrent.Style = `${current.Style.replace(/WW|W|NA/g, '')}${size === 'Extra Wide' ? 'WW' : size === 'Wide' ? 'W' : 'NA'}`
                    for (const size of newCurrent.AllSizes) {
                        output.push({...newCurrent, Size: size, SizeTransformed: size.replace('.5', 'H')})
                    }
                }
    
                current = {...sampleObj}
                i += 5
            } else {
                console.log('skipping', line, i)
                i++
            }
        } else {
            i++
        }
    }
    
    return output.map(({AllSizes, ...item}) => item)
}

export const processDataSketchersImportHelper = (data: ArrayBuffer[]) => {
    console.log('processing');

    try {
        const result: any[] = []
        for (const input of data) {
            const inputData = new TextDecoder("utf-8").decode(input);
            const output = ProcessSketchersFile(inputData)
            result.push(...output)
        }
        
        console.log(result)
        let chunkSize = 10000;
        for (let i = 0; i <= result.length; i += chunkSize) {
            const chunk = result.slice(i, i + chunkSize);
            downloadHelper(chunk, `${dayjs().format('MM-DD-YYYY')}-Sketchers-Stage1-Result-${Math.floor(i / chunkSize) + 1}`)
        }
    } catch (ex) {
        console.log(ex)
        message.error('Failed to process! Please send file to Karol.')
    }
}