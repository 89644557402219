export const CARRIERS = [
    'ABF',
    'ABF Freight',
    'Armstrong',
    'Armstrong Transport',
    'Armstrong Transportation',
    'BBF Global Logistics',
    'Boren Brothers',
    'Central Transport',
    'Central Transport Intl',
    'Clear Lane Freight',
    'Conway',
    'DHE',
    'Dayton',
    'Dependable Highway Express',
    'Dohrn',
    'Dohrn/Fedex',
    'EDI Express',
    'EXLA',
    'Estes Express',
    'FedEx',
    'FedEx & TForce Freight',
    'FedEx & TForce',
    'FedEx Freight',
    'FedEx Ground',
    'Ground',
    'Holland',
    'KTI LLC',
    'LTL',
    'NOLAN',
    'New Light',
    'New Penn',
    'Nolan Transport',
    'Old Dominion',
    'Orgill Driver',
    'RL Carriers',
    'Reddaway',
    'Roadrunner',
    'SAIA',
    'Southeastern',
    'Sutton',
    'TForce',
    'TForce Freight',
    'Total Quality',
    'Total Transport',
    'UPS',
    'UPS Ground',
    'USF Reddaway',
    'USPS',
    'WEL Logistics',
    'XPO',
    'XPO Logistics',
    'XPO/Dohrn/Saia',
    'YRC',
    'YRC Freight',
] as const;

export type CarrierType = (typeof CARRIERS)[number];
