import { FolderAddOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Button,
    Col,
    Divider,
    InputRef,
    Modal,
    notification,
    Row,
    Space,
} from 'antd';
import * as dataForge from 'data-forge';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { UnsafeFetchAttemptError } from '../../../services/errors';
import {
    deletePOTrackerArchive,
    getPOTrackerArchive,
    updatePOTrackerArchive,
} from '../../../services/WholesaleService';
import {
    OMSTrackerItem,
    OMSTrackerItemRenderType,
} from '../../../types/OmegaTypes';
import { CSVDownload } from '../../utilities/CSVDownload';
import { makePOTrackerColumns } from '../../utilities/OMSColumns';
import { UnsavedChangesHandler } from '../../utilities/UnsavedChangesHandler';
import { BulkActionsButton, BulkActionType } from '../table/BulkActions';
import { EditableColumnType } from '../table/EditableCell';
import EditableTable, { EditableTableHandle } from '../table/EditableTable';

export const POTrackerArchive: React.FC = () => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<OMSTrackerItemRenderType[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] =
        useState<Date>(fetchTimestamp);
    const [modal, modalContext] = Modal.useModal();
    const tableRef =
        useRef<EditableTableHandle<OMSTrackerItemRenderType>>(null);
    const queryClient = useQueryClient();

    const {
        data: poTrackerData,
        isLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ['potracker_archive'],
        queryFn: async () => {
            if (lastUpdateTimestamp > fetchTimestamp) {
                throw new UnsafeFetchAttemptError(
                    'You have unsaved changes in your table that prevent automatic update'
                );
            } else {
                const token = await currentUser!.getIdToken();
                const data = (await getPOTrackerArchive(
                    token
                )) as OMSTrackerItem[];
                return data;
            }
        },

        enabled: true,
        staleTime: Infinity,
        refetchInterval: 1800000,
        refetchIntervalInBackground: true,
        // initialData: [],
    });

    useEffect(() => {
        if (error instanceof UnsafeFetchAttemptError) {
            notification.info({
                message: 'Could not fetch data',
                description: error.message,
            });
        } else if (error) {
            notification.error({
                message: 'Error',
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const omsMutation = useMutation({
        mutationFn: async (data: OMSTrackerItemRenderType[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error('Auth token not found');
            }

            if (!poTrackerData) {
                throw new Error('Invoice data was not fetched correctly');
            }

            // We don't want to delete archived items, so we have to concatenate
            // them with the current table data
            const itemsToDelete = _.differenceBy(
                poTrackerData,
                data,
                item => item.Supplier_PO + item.Supplier_SO
            );
            await updatePOTrackerArchive(token, data);
            if (itemsToDelete.length > 0) {
                await deletePOTrackerArchive(
                    token,
                    itemsToDelete.map(item => item._id)
                );
            }
            return data;
        },
        onSuccess: data => {
            queryClient.setQueryData(['potracker_archive'], data);
            setFetchTimestamp(new Date());
            // Automatically update data in PO Browser
            queryClient.refetchQueries({ queryKey: ['oms_data'] });
            notification.success({
                message: 'Success!',
            });
        },
        onError: error => {
            notification.error({
                message: 'Upload error',
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        if (poTrackerData) {
            setTableData(
                poTrackerData.map((item, idx) => ({ ...item, key: idx }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
        // eslint-disable-next-line
    }, [poTrackerData]);

    const restoreItems = useCallback(
        (keysToArchive: React.Key[]) => {
            const keysSet = new Set(keysToArchive);
            const dataCopy = [...tableData];
            const newData = dataCopy.filter(item => !keysSet.has(item.key));

            setTableData(
                newData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(new Date());
        },
        [tableData]
    );

    const columns = useMemo<
        EditableColumnType<OMSTrackerItemRenderType>[]
    >(() => {
        const ssColumns = makePOTrackerColumns(searchInputRef);
        return ssColumns;
    }, []);

    const bulkActions: BulkActionType[] = [
        {
            label: 'Restore items',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to restore ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        restoreItems(selectedRowKeys);
                    },
                }),
            icon: <FolderAddOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'end' }}
            split={<Divider type='vertical' />}
        >
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved={tableData.length === 0}
                isLoading={omsMutation.isPending}
                title={'Save Tracker'}
                onSaveChanges={() => {
                    omsMutation.mutate(tableData);
                }}
                onDiscardChanges={() => {
                    setTableData(
                        (poTrackerData || []).map((item, idx) => ({
                            ...item,
                            key: idx,
                        }))
                    );
                    setLastUpdateTimestamp(fetchTimestamp);
                }}
            />
            <CSVDownload
                collection={`PO_Tracker_Archive_Export_${dayjs().format(
                    'MM-DD-YY'
                )}`}
                data={tableRef.current?.currentData || []}
                dateColumns={[
                    'Date Paid',
                    'Last Shipment Date',
                    'Ship_Requested',
                ]}
                isLoading={!tableRef.current}
                parse={data => {
                    return new dataForge.DataFrame(data)
                        .subset(columns.map(col => col.dataIndex as string))
                        .toArray();
                }}
            />
            <Button
                type='primary'
                onClick={() => refetch()}
                loading={isRefetching || isLoading}
            >
                Reload
            </Button>
            <BulkActionsButton actions={bulkActions} />
        </Space>
    );

    return (
        <>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row>
                    <Col span={24}>
                        <EditableTable<OMSTrackerItemRenderType>
                            ref={tableRef}
                            title={tableTitle}
                            tableData={tableData}
                            columns={columns}
                            loading={isLoading}
                            onRowSelectionChange={(rowKeys, availableRowKeys) =>
                                setSelectedRowKeys(
                                    rowKeys.length === 0
                                        ? availableRowKeys
                                        : rowKeys
                                )
                            }
                            pagination={{
                                pageSizeOptions: [20, 50, 100],
                                defaultPageSize: 50,
                            }}
                        />
                    </Col>
                </Row>
            </Space>
            {modalContext}
        </>
    );
};
