import { message } from 'antd';
import dayjs from 'dayjs';
import { downloadHelper } from "./Downloads";
import { getSupplierItems } from '../../../services/BrandService';
import * as SheetJS from "xlsx";
import { mapHeadersSheetJS } from './AdidasHelper';

export const processDataVictronHelper = async (data: ArrayBuffer, currentUser: any) => {
    try {
        const osWorkbook = SheetJS.read(data, { raw: true });
        const osSheets = osWorkbook.SheetNames

        let victronOfferData: any[] = [];
        for (let i = 0; i < osSheets.length; i++) {
            const sheetRows: any[] = SheetJS.utils.sheet_to_json(osWorkbook.Sheets[osWorkbook.SheetNames[i]], {
                blankrows: true,
                header: "A"
            })

            let headers;

            for (const row of sheetRows) {
                if (row.A === "Group") {
                    headers = row;
                } else if (row.A && headers) {
                    const product = mapHeadersSheetJS(headers, row);
                    victronOfferData.push(product);
                }
            }
        }

        const victronAsinData = (await currentUser!.getIdToken().then((token: string) => getSupplierItems(token, 'Victron'))).reduce((acc: any, curr: any) => ((acc[curr.SupplierSKU] = curr.ASIN, acc)), {});
        console.log(victronOfferData);
        console.log(victronAsinData)

        let sampleResult = {
            'Article Code': '',
            'Description': '',
            'ASIN': '',
            'Cost': '',
            'Price Break Price': '',
            'Price Break QTY': '',
            'MAP': ''
        }

        let fullResult: any[] = []
        victronOfferData.forEach((row) => {
            const asin = victronAsinData[row['Article code']]
            if (asin) {
                const newRow = { ...sampleResult }
                newRow['Article Code'] = row['Article code']
                newRow['Description'] = row.Description
                newRow['ASIN'] = asin
                newRow['Cost'] = row['Price, USD']
                newRow['Price Break Price'] = Object.keys(row).filter((key) => key.includes('Price per') && row[key] && row[key] !== '').map((key) => row[key])?.[0] ?? ''
                newRow['Price Break QTY'] = Object.keys(row).filter((key) => key.includes('Price per') && row[key] && row[key] !== '')?.[0]?.split('per ')[1] ?? ''
                newRow['MAP'] = (parseFloat(newRow.Cost) * 0.85 * (5/3)).toFixed(2)
                fullResult.push(newRow)
            }
        })

        console.log(fullResult);

        downloadHelper(fullResult, `${dayjs().format('MM-DD-YYYY')}-Victron-Result`)
    } catch (ex) {
        message.error('Failed to process! Please send file to Karol.')
    }
}