import { Divider, Space, Statistic, Tooltip } from 'antd';
import React from 'react';
interface OMSDateStatsSpaceProps<T> {
    data: T[];
    dateColumn: keyof T;
    amountColumn: keyof T;
    inFuture: boolean;
    titleRender: (timeFrame: string) => string;
    descriptionRender?: (timeFrame: string) => string;
    type?: 'regular' | 'warning' | 'danger';
    merge?: boolean;
}

function timeFramesAgg<T>(
    data: T[],
    dateColumn: keyof T,
    amountColumn: keyof T,
    inFuture: boolean
) {
    let days1_30 = 0;
    let days31_60 = 0;
    let days61_90 = 0;
    let days91_ = 0;

    const currentDate = new Date();
    const days30 = new Date(currentDate);
    days30.setDate(currentDate.getDate() + 30);
    const days60 = new Date(currentDate);
    days60.setDate(currentDate.getDate() + 60);
    const days90 = new Date(currentDate);
    days90.setDate(currentDate.getDate() + 90);

    const multiplier = inFuture ? 1 : -1;
    // Multiplier should be 1 if we want to calculate stats for future dates. Otherwise
    // it should be -1
    const currentDateTime = currentDate.getTime() * multiplier;
    const days30Time = days30.getTime() * multiplier;
    const days60Time = days60.getTime() * multiplier;
    const days90Time = days90.getTime() * multiplier;

    for (const item of data) {
        let dateValue = item[dateColumn] as unknown as
            | Date
            | undefined
            | string;
        let amount = (item[amountColumn] as unknown as number) || 0;
        if (!dateValue) continue;
        if (typeof dateValue === 'string') {
            dateValue = new Date(dateValue);
        }

        if (multiplier * dateValue.getTime() < currentDateTime) continue;

        const itemTime = dateValue.getTime() * multiplier;
        if (itemTime < days30Time) {
            days1_30 += amount;
        } else if (itemTime < days60Time) {
            days31_60 += amount;
        } else if (itemTime < days90Time) {
            days61_90 += amount;
        } else {
            days91_ += amount;
        }
    }

    return [
        {
            label: '1-30',
            value: days1_30,
        },
        {
            label: '31-60',
            value: days31_60,
        },
        {
            label: '61-90',
            value: days61_90,
        },
        {
            label: '91+',
            value: days91_,
        },
    ];
}

export function OMSDateStatsSpace<T>({
    data,
    dateColumn,
    amountColumn,
    titleRender,
    descriptionRender,
    inFuture,
    type,
    merge,
}: OMSDateStatsSpaceProps<T>) {
    const timeFrames = timeFramesAgg(data, dateColumn, amountColumn, inFuture);

    let color: string | undefined;
    if (type === 'regular' || !type) {
        color = undefined;
    } else if (type === 'danger') {
        color = '#d62828';
    } else {
        color = '#f48c06';
    }

    let fieldsToRender = timeFrames;
    if (merge) {
        fieldsToRender = [
            {
                label: '',
                value: timeFrames.reduce((prev, curr) => prev + curr.value, 0),
            },
        ];
    }

    return (
        <Space direction='vertical'>
            <Space split={<Divider type='vertical' />}>
                {fieldsToRender.map((field, i) => (
                    <Tooltip
                        key={i}
                        title={
                            descriptionRender
                                ? descriptionRender(field.label)
                                : ''
                        }
                        placement='topLeft'
                        // popupVisible={!!descriptionRender}
                    >
                        <Statistic
                            title={titleRender(field.label)}
                            formatter={value => value.toLocaleString()}
                            prefix='$'
                            value={field.value.toLocaleString()}
                            valueStyle={{ color }}
                        />
                    </Tooltip>
                ))}
            </Space>
        </Space>
    );
}
