import React, {useEffect} from "react";
import {Form, InputNumber, Modal, Row, Space, Tooltip} from "antd";
import {Dimensions} from "../../../types/WarehouseTypes";

interface DimensionsDialogProps {
    dimensions?: Dimensions;
    open: boolean;
    onCancel: () => void;
    onSave: (dimensions: Dimensions) => void;
    title?: string;
}

const DimensionsDialog: React.FC<DimensionsDialogProps> = ({dimensions, open, onCancel, onSave, title}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(dimensions);
    }, [dimensions, form]);

    return (
        <Modal
            title={title || "Dimensions"}
            open={open}
            onCancel={onCancel}
            onOk={async () => {
                try {
                    const values = (await form.validateFields()) as Dimensions;
                    onSave(values);
                } catch (e) {
                    console.error(e);
                }
            }}
        >
            <Form form={form}>
                <Space direction="horizontal" style={{justifyContent: "space-between", margin: 4}}>
                    <Form.Item<Partial<Dimensions>> name="length" rules={[{required: true}]} style={{alignItems: "center"}}>
                        <InputNumber placeholder="Length" addonAfter={<Tooltip title="Length">in</Tooltip>} />
                    </Form.Item>
                    <Form.Item<Partial<Dimensions>> name="width" rules={[{required: true}]}>
                        <InputNumber placeholder="Width" addonAfter={<Tooltip title="Width">in</Tooltip>} />
                    </Form.Item>
                    <Form.Item<Partial<Dimensions>> name="height" rules={[{required: true}]}>
                        <InputNumber placeholder="Height" addonAfter={<Tooltip title="Height">in</Tooltip>} />
                    </Form.Item>
                </Space>
                <Row justify={"center"}>
                    <Form.Item<Partial<Dimensions>> name="weight" rules={[{required: true}]}>
                        <InputNumber placeholder="Weight" addonAfter={<Tooltip title="Weight">lbs</Tooltip>} />
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

export default DimensionsDialog;
