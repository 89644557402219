import React, {useEffect, useMemo} from "react";
import {Modal, Row, Col, Avatar, Button, Space, Tabs, Descriptions} from "antd";
import {UPCNotif, WarehouseProduct} from "../../../types/WarehouseTypes";
import {TabsProps} from "antd/lib";

const NotificationDialog: React.FC<{
    open: boolean;
    notifications: UPCNotif[];
    items: WarehouseProduct[];
    onClose: () => void;
    onFinish: (items: WarehouseProduct[]) => void;
}> = ({onClose, open, notifications, onFinish, items}) => {
    const [localNotifs, setLocalNotifs] = React.useState<UPCNotif[]>([]);
    const [localItems, setLocalItems] = React.useState<WarehouseProduct[]>([]);

    useEffect(() => {
        setLocalNotifs(notifications);
    }, [notifications]);

    useEffect(() => {
        setLocalItems(items);
    }, [items]);

    const globalItems: TabsProps["items"] = useMemo(
        () =>
            localNotifs.map((notif) => {
                const foundItem = localItems.find((item) => item.asin === notif.upc || item.upc === notif.upc);
                if (!foundItem)
                    return {
                        key: notif.upc,
                        label: notif.upc,
                        children: null,
                    };

                return {
                    key: foundItem.asin,
                    label: foundItem.asin,
                    children: (
                        <Space direction="vertical" style={{width: "100%"}}>
                            <Row style={{width: "100%"}} justify={"center"} align={"middle"}>
                                <Col span={4}>
                                    <Avatar shape="square" size={80} src={foundItem.imgURL} />
                                </Col>
                                <Col span={16}>
                                    <Descriptions
                                        size="small"
                                        column={1}
                                        bordered
                                        items={[
                                            {label: "Name", children: foundItem.name},
                                            {label: "ASIN", children: foundItem.asin},
                                            {label: "Message", children: notif.message},
                                        ]}
                                    />
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical" style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
                                        <Button
                                            onClick={() => {
                                                if (localNotifs.length === 1) {
                                                    onFinish(localItems);
                                                    setLocalItems([]);
                                                }
                                                setLocalNotifs(localNotifs.filter((n) => n.upc !== notif.upc));
                                            }}
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (localNotifs.length === 1) {
                                                    onFinish(localItems.filter((i) => i.asin !== foundItem.asin));
                                                    setLocalItems([]);
                                                }
                                                setLocalNotifs(localNotifs.filter((n) => n.upc !== notif.upc));
                                                setLocalItems(localItems.filter((i) => i.asin !== foundItem.asin));
                                            }}
                                            danger
                                        >
                                            Discard
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    ),
                };
            }),
        [localNotifs, localItems, onFinish]
    );

    return (
        <Modal title="Notifications" open={open} footer={null} onCancel={onClose} width={"40vw"}>
            <Tabs items={globalItems} />
        </Modal>
    );
};

export default NotificationDialog;
