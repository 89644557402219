import { Select } from 'antd';
import React from 'react';

const SupplierSelect: React.FC<{
    value?: string;
    onChange: (value: any) => void;
    supplierPOs: { [key: string]: any };
    disabled?: boolean;
}> = ({ onChange, value, supplierPOs, disabled }) => {
    return (
        <Select
            size='large'
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
                (option!.children! as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            placeholder='Please select Supplier'
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
            allowClear
            value={value}
        >
            {Object.keys({ ...supplierPOs })
                .sort()
                .map((Supplier, idx) => (
                    <Select.Option key={idx} value={Supplier}>
                        {Supplier}
                    </Select.Option>
                ))}
        </Select>
    );
};

export default SupplierSelect;
