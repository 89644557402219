import { Divider, Space, Statistic } from 'antd';
import React from 'react';
import { OMSItem } from '../../types/OmegaTypes';

interface OMSStatsSpaceProps {
    data: OMSItem[];
}

export const OMSStatsSpace: React.FC<OMSStatsSpaceProps> = ({ data }) => {
    return (
        <Space split={<Divider type='vertical' />}>
            <Statistic title='Total SKUs' value={data.length} />
            <Statistic
                title='Total units'
                value={data.reduce(
                    (acc, curr) => (acc += curr.Quantity || 0),
                    0
                )}
            />
            <Statistic
                title='Total spent'
                value={data.reduce(
                    (acc, curr) =>
                        (acc += (curr.Quantity || 0) * (curr.Cost || 0)),
                    0
                )}
                precision={2}
                prefix={'$'}
            />
        </Space>
    );
};
