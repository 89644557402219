import { useQuery } from '@tanstack/react-query';
import { App, Divider, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import SingularItemCard from './SingularItemCard';
import { GetASINForSKU, GetSingularItemList, GetSingularItems, getNotes, grabPectyData } from '../../services/OmegaService';
import { NoteItem, PectyRoot, SingularItem } from '../../types/OmegaTypes';
import { useAuth } from '../../contexts/AuthContext';
import { prepareInsight } from './InsightsCalc';
import dayjs from 'dayjs';


const SingleBrowser: React.FC<{ asin?: string }> = ({ asin: propsAsin }) => {
    const { message } = App.useApp();
    const [singularAsin, setSingularAsin] = useState<string | null>(null)
    const [singularSku, setSingularSku] = useState<string | null>(null)
    const [singularItem, setSingularItem] = useState<SingularItem | null>(null)
    const [noteMap, setNoteMap] = useState<{ [key: string]: NoteItem[] } | null>(null)
    const [pectyMap, setPectyMap] = useState<{ [key: string]: PectyRoot } | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { currentUser } = useAuth();


    const { data: singularItemList, isFetching: singularItemListLoading } = useQuery(
        {
            queryKey: ['singular_data_list'],
            queryFn: () => currentUser!.getIdToken().then((token: string) => GetSingularItemList(token, false)),
            staleTime: Infinity,
            enabled: propsAsin === undefined,
        },
    )

    const { data: externalSingularData, isFetching: externalSingularDataLoading } = useQuery(
        {
            queryKey: ['singular_data', singularAsin],
            queryFn: () => singularAsin ? currentUser!.getIdToken().then((token: string) => GetSingularItems(token, false, singularAsin)) : null,
            staleTime: Infinity,
            enabled: !!singularAsin
        },
    )

    useEffect(() => {
        if (propsAsin) {
            setSingularAsin(propsAsin);
        } else {
            const queryString = new URLSearchParams(window.location.search);
            const queryAsin = queryString.get('asin');
            const querySku = queryString.get('sku');

            if (queryAsin) {
                setSingularAsin(queryAsin);
            } else if (querySku) {
                setSingularSku(querySku);
                GetASINForSKU(querySku, 'omega').then((result: any[]) => {
                    const asin = result?.[0]?.ASIN;
                    if (asin) {
                        setSingularAsin(asin);
                    } else {
                        message.error('No ASIN found for the given SKU!')
                    }
                })
            }
        }
    }, [message, propsAsin])

    const { data: noteData, isFetching: noteDataLoading } = useQuery({
        queryKey: ['singularNotes_data', singularItem?.SKU ?? 'default'],
        queryFn: () => singularItem?.SKU ? currentUser!.getIdToken().then((token: string) => getNotes(token, singularItem.SKU)) : [],
        staleTime: Infinity,
    })

    const { data: pectyData, isFetching: pectyDataLoading } = useQuery({
        queryKey: ['pectySingular_data'],
        queryFn: () => grabPectyData(),
        staleTime: Infinity
    })

    useEffect(() => {
        if (noteData) {
            const localNoteMap: {
                [key: string]: NoteItem[],
            } = {};

            console.log('noteData', noteData);
            noteData.forEach((noteItem: NoteItem) => {
                if (localNoteMap[noteItem.SKU]) {
                    localNoteMap[noteItem.SKU].push(noteItem)
                } else {
                    localNoteMap[noteItem.SKU] = [noteItem]
                }
            })

            setNoteMap(localNoteMap)
        }
    }, [noteData])

    useEffect(() => {
        if (pectyData) {
            const localPectyMap: {
                [key: string]: PectyRoot
            } = {}

            pectyData.forEach((pectyItem: PectyRoot) => {

                if (localPectyMap[pectyItem.data.asin]?.dateProcessed < pectyItem.dateProcessed) {
                    localPectyMap[pectyItem.data.asin] = pectyItem
                } else if (localPectyMap[pectyItem.data.asin] === undefined) {
                    localPectyMap[pectyItem.data.asin] = pectyItem
                }
            })

            setPectyMap(localPectyMap);
        }
    }, [pectyData])

    useEffect(() => {
        if (externalSingularData && noteMap && pectyMap) {
            const itemArr = externalSingularData as SingularItem[]

            if (itemArr.length === 0) {
                message.error('No item found for the given ASIN or SKU!')
                setIsLoading(false);
                return;
            }

            let maxQuantItem
            if (singularSku) {
                maxQuantItem = itemArr.find((item) => item.SKU === singularSku)!
            }

            if (!maxQuantItem) {
                maxQuantItem = itemArr.reduce((acc, curr) => curr.Stock > acc.Stock ? curr : (curr["Days In Stock"] > acc["Days In Stock"] && !(curr.Stock <= acc.Stock) ? curr : acc), itemArr[0])
                setSingularSku(maxQuantItem.SKU)
            }

            maxQuantItem.skuItemCount = itemArr.length
            maxQuantItem.skuItemList = itemArr.map((item) => item.ssItem)
            maxQuantItem.itemArr = itemArr;

            itemArr.forEach((item) => {
                item.itemArr = itemArr;
                item.skuItemCount = itemArr.length;
                item.skuItemList = itemArr.map((item) => item.ssItem)
            })

            if (maxQuantItem.skuItemList.filter((item) => item.fba_available_quantity > 0 || item.fulfillable_quantity > 0).length > 1) {
                if (!maxQuantItem.Issues.includes('MultiSKU')) {
                    maxQuantItem.Issues.push('MultiSKU')
                }
            }

            if (pectyMap?.[maxQuantItem.ASIN]) {
                itemArr.forEach((item) => {
                    item.pectyData = pectyMap[item.ASIN]
                    item.insight = prepareInsight(item);
                })

                maxQuantItem.pectyData = pectyMap[maxQuantItem.ASIN]
                maxQuantItem.insight = prepareInsight(maxQuantItem);

                if (maxQuantItem.pectyData?.data?.buyboxHasCoupon === true) {
                    if (maxQuantItem.pectyData?.data?.buyboxSellerId !== 'A3B3XKFL4HJA0A' && maxQuantItem.pectyData?.data?.buyboxSellerName !== 'M Emporium' && !maxQuantItem.Issues.includes('BB Coupon')) {
                        maxQuantItem.Issues.push('BB Coupon')
                    }
                }
            }

            maxQuantItem.notes = noteMap?.[maxQuantItem.SKU] || [];
            maxQuantItem.sinceNote = noteMap?.[maxQuantItem.SKU]?.[0]?.insertTimestamp ? dayjs().diff(dayjs(noteMap[maxQuantItem.SKU]?.[0]?.insertTimestamp), 'days') : -1;

            console.log('setting singular item', maxQuantItem)
            setSingularItem({ ...maxQuantItem })
            setIsLoading(false);
        } else if (noteMap && pectyMap && !singularAsin) {
            setIsLoading(false);
        }
    }, [externalSingularData, noteMap, pectyMap, singularAsin, singularSku, message])

    return (
        <Spin spinning={
            noteDataLoading
            || isLoading
            || pectyDataLoading
            || externalSingularDataLoading
        }>
            {!propsAsin && <Select
                style={{ minWidth: '100%' }}
                disabled={singularItemListLoading}
                onChange={(val) => {
                    if (val !== singularAsin) {
                        setSingularSku(null)
                        setSingularAsin(val)
                        window.history.pushState({}, '', `?asin=${val}`)
                        setIsLoading(true);
                    }
                }}
                showSearch
                filterOption={(input, option) =>
                    (option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
                }
            >
                {singularItemList?.sort((a: any, b: any) => a.Brand === b.Brand ? (a.Title > b.Title ? 1 : -1) : (a.Brand > b.Brand ? 1 : -1)).map((item: any, idx: number) => <Select.Option value={item.ASIN} key={idx}>{item.Brand} - {item.ASIN} - {item.SKU} - {item.Title}</Select.Option>)}
            </Select>}
            <Divider />
            <Select
                style={{ minWidth: '100%' }}
                disabled={!!!singularItem}
                onChange={(val) => {
                    setSingularSku(val)
                }}
                value={singularSku}
                showSearch
                filterOption={(input, option) =>
                    (option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
                }
            >
                {singularItem?.itemArr.map((item: any, idx: number) => <Select.Option value={item.SKU} key={idx}>{item.SKU} - {item.Stock}</Select.Option>)}
            </Select>
            {singularItem ? <SingularItemCard productData={singularItem} prodCount={1} prodIndex={0}></SingularItemCard> : <div style={{ minWidth: '95vw', minHeight: '95vh' }}></div>}
        </Spin>

    )
}

export default SingleBrowser;