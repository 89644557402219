import {Button, Collapse, Divider, InputRef, Space, Statistic, Table, Typography} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {ColumnsType} from "antd/lib/table";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import ReactDragListView from "react-drag-listview";
import {BrandStats, SingularItem} from "../../types/OmegaTypes";
import {CSVDownload} from "../utilities/CSVDownload";
import {getColumnTitle, getNumberFilterSorter, getNumberRenderer, getPriceRenderer} from "../utilities/TableFilterSorters";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
interface DataType extends BrandStats {
    key: number;
}

const BrandSummary: React.FC<{
    tableData: SingularItem[];
}> = React.memo(({tableData}) => {
    const [brandStats, setBrandStats] = useState<DataType[]>([]);
    const [localFilteredData, setLocalFilteredData] = useState<DataType[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const searchInputRef = useRef<InputRef>(null);

    useEffect(() => {
        const localBrandData: {[key: string]: SingularItem[]} = {};

        tableData.forEach((row) => {
            localBrandData[row.Brand] = (localBrandData[row.Brand] || []).concat([row]);
        });

        const localBrandStats: {[key: string]: BrandStats} = {};
        Object.entries(localBrandData)
            .sort((a, b) => (a[0] > b[0] ? 1 : -1))
            .forEach(([brand, brandItems]) => {
                localBrandStats[brand] = {
                    "Brand": brand,
                    "Total SKUs": brandItems.length,
                    "Total units": brandItems.reduce((acc, curr) => (acc += curr.Stock), 0),
                    "Available units": brandItems.reduce((acc, curr) => (acc += curr.availableInventory), 0),
                    "Unavailable units": brandItems.reduce((acc, curr) => (acc += curr.unavailableInventory), 0),
                    "Total sold": brandItems.reduce((acc, curr) => (acc += curr["Sold (30D)"]), 0),
                    "Total value sold": brandItems.reduce((acc, curr) => (acc += curr["Sold (30D)"] * curr["Cost"]), 0),
                    "Inventory Value": brandItems.reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0),
                    "Available Value": brandItems.reduce((acc, curr) => (acc += curr.availableInvValue), 0),
                    "Unavailable Value": brandItems.reduce((acc, curr) => (acc += curr.unavailableInvValue), 0),
                    "Total profit": brandItems.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0),
                    "Total revenue": brandItems.reduce((acc, curr) => (acc += curr["Avg Sell Price"] * curr["Sold (30D)"]), 0),
                    "TTS<90":
                        brandItems.reduce((acc, curr) => (acc += +(curr.EstTTS > 0) * curr.Stock * curr.Cost), 0) > 0
                            ? (brandItems
                                  .filter((item) => item.EstTTS > 0 && item.EstTTS < 90)
                                  .reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0) *
                                  100) /
                              brandItems.reduce((acc, curr) => (acc += +(curr.EstTTS > 0) * curr.Stock * curr.Cost), 0)
                            : 0,
                    "TTS>=90":
                        brandItems.reduce((acc, curr) => (acc += +(curr.EstTTS > 0) * curr.Stock * curr.Cost), 0) > 0
                            ? (brandItems.filter((item) => item.EstTTS >= 90).reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0) *
                                  100) /
                              brandItems.reduce((acc, curr) => (acc += +(curr.EstTTS > 0) * curr.Stock * curr.Cost), 0)
                            : 0,
                    "Sell-through Rate":
                        brandItems.reduce((acc, curr) => (acc += curr.availableInvValue + curr["Sold (30D)"] * curr["Cost"]), 0) > 0
                            ? (brandItems.reduce((acc, curr) => (acc += curr["Sold (30D)"] * curr["Cost"]), 0) /
                                  brandItems.reduce(
                                      (acc, curr) => (acc += curr.availableInvValue + curr["Sold (30D)"] * curr["Cost"]),
                                      0
                                  )) *
                              100
                            : 0,
                    "Mean BB Share": brandItems
                        .filter((item) => item.Stock > 0)
                        .reduce((acc, curr, _, {length}) => (acc += curr.BBShare / length), 0),
                    "Mean CR": brandItems.reduce((acc, curr, _, {length}) => (acc += Math.max(curr.CR, 0) / length), 0),
                    "Mean ROI":
                        (brandItems.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0) /
                            (brandItems.reduce((acc, curr) => (acc += curr.Cost * curr["Sold (30D)"]), 0) || 1)) *
                        100,
                    "Mean GMROI":
                        (12 * brandItems.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0)) /
                        brandItems.reduce((acc, curr) => (acc += curr.Cost * (curr["Sold (30D)"] + curr.availableInventory)), 0),
                    "ROIC":
                        brandItems.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0) /
                        (brandItems.reduce((acc, curr) => (acc += ((curr.Stock + curr["Sold (30D)"]) * curr.Cost) / 2), 0) || 1),
                    "Brand Items": brandItems,
                };
            });

        setBrandStats(Object.values(localBrandStats).map((brand, idx) => ({...brand, key: idx})));
        setLocalFilteredData(Object.values(localBrandStats).map((brand, idx) => ({...brand, key: idx})));
    }, [tableData]);

    useEffect(() => {
        const columns: ColumnsType<DataType> = [
            {
                title: "Brand",
                dataIndex: "Brand",
                key: "Brand",
                width: "110px",
                fixed: "left",
                ...GetExpandedFilter([], "Brand"),
            },
            {
                title: "Total SKUs",
                dataIndex: "Total SKUs",
                key: "Total SKUs",
                width: "110px",
                ...getNumberFilterSorter("Total SKUs"),
                ...getNumberRenderer(),
            },
            {
                title: "Total units",
                dataIndex: "Total units",
                key: "Total units",
                width: "110px",
                ...getNumberFilterSorter("Total units"),
                ...getNumberRenderer(),
            },
            {
                title: "Fulfil. Inv",
                dataIndex: "Available units",
                key: "Available units",
                width: "110px",
                ...getNumberFilterSorter("Available units"),
                ...getNumberRenderer(),
            },
            {
                title: "Unavail. Inv",
                dataIndex: "Unavailable units",
                key: "Unavailable units",
                width: "110px",
                ...getNumberFilterSorter("Unavailable units"),
                ...getNumberRenderer(),
            },
            {
                title: "Total sold",
                dataIndex: "Total sold",
                key: "Total sold",
                width: "110px",
                ...getNumberFilterSorter("Total sold"),
                defaultFilteredValue: ["5 0"],
                ...getNumberRenderer(),
            },
            {
                title: "Total value sold",
                dataIndex: "Total value sold",
                key: "Total value sold",
                width: "110px",
                ...getNumberFilterSorter("Total value sold"),
                ...getPriceRenderer(),
            },
            {
                title: "Inventory Value",
                dataIndex: "Inventory Value",
                key: "Inventory Value",
                width: "110px",
                ...getNumberFilterSorter("Inventory Value"),
                ...getPriceRenderer(),
            },
            {
                title: "Fulfil. Value",
                dataIndex: "Available Value",
                key: "Available Value",
                width: "110px",
                ...getNumberFilterSorter("Available Value"),
                ...getPriceRenderer(),
            },
            {
                title: "Unavail. Value",
                dataIndex: "Unavailable Value",
                key: "Unavailable Value",
                width: "110px",
                ...getNumberFilterSorter("Unavailable Value"),
                ...getPriceRenderer(),
            },
            {
                title: "TTS<90",
                key: "TTS<90",
                dataIndex: "TTS<90",
                width: "110px",
                ...getNumberFilterSorter("TTS<90"),
                render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
            },
            {
                title: "TTS>=90",
                key: "TTS>=90",
                dataIndex: "TTS>=90",
                width: "110px",
                ...getNumberFilterSorter("TTS>=90"),
                render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
            },
            {
                title: "Total profit",
                dataIndex: "Total profit",
                key: "Total profit",
                width: "110px",
                ...getNumberFilterSorter("Total profit"),
                ...getPriceRenderer(),
            },
            {
                title: "Sell-through Rate",
                key: "Sell-through Rate",
                dataIndex: "Sell-through Rate",
                width: "110px",
                ...getNumberFilterSorter("Sell-through Rate"),
                render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
            },
            {
                title: "Mean BB Share",
                key: "Mean BB Share",
                dataIndex: "Mean BB Share",
                width: "110px",
                ...getNumberFilterSorter("Mean BB Share"),
                render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
            },
            {
                title: "Mean CR",
                key: "Mean CR",
                dataIndex: "Mean CR",
                width: "110px",
                ...getNumberFilterSorter("Mean CR"),
                render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
            },
            {
                title: "Brand ROI",
                key: "Mean ROI",
                dataIndex: "Mean ROI",
                width: "110px",
                ...getNumberFilterSorter("Mean ROI"),
                render: (val) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
            },
            {
                title: "Brand GMROI",
                key: "Mean GMROI",
                dataIndex: "Mean GMROI",
                width: "110px",
                ...getNumberFilterSorter("Mean GMROI"),
                render: (val) => <Typography>{!Number.isNaN(val) && Number.isFinite(val) ? `${val.toFixed(2)}` : 0}</Typography>,
            },
            {
                title: "ROIC",
                key: "ROIC",
                dataIndex: "ROIC",
                width: "110px",
                ...getNumberFilterSorter("ROIC"),
                render: (val) => <Typography>{`${(val * 100).toFixed(2)}%`}</Typography>,
            },
        ];

        setColumns(columns);
    }, []);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const dragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const localColumns: any = [...(columns as any)];
            const item = localColumns.splice(fromIndex, 1)[0];
            localColumns.splice(toIndex, 0, item);
            setColumns(localColumns);
        },
        nodeSelector: "button",
    };

    return (
        <>
            <Collapse defaultActiveKey={["2"]}>
                <CollapsePanel key={1} header={"Columns"}>
                    <ReactDragListView.DragColumn {...dragProps}>
                        <div style={{gap: "8px", display: "flex", justifyContent: "space-around", flexWrap: "wrap", flexDirection: "row"}}>
                            {columns.map((column) => {
                                if (hiddenColumns.includes(getColumnTitle(column))) {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="dashed"
                                            onClick={() =>
                                                setHiddenColumns((hidden) => hidden.filter((col) => col !== getColumnTitle(column)))
                                            }
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="primary"
                                            onClick={() => setHiddenColumns((hidden) => hidden.concat([getColumnTitle(column)]))}
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                }
                            })}
                        </div>
                    </ReactDragListView.DragColumn>
                </CollapsePanel>
            </Collapse>
            <Table<DataType>
                rowClassName={() => "editable-row"}
                scroll={{y: "80vh", x: "max-content"}}
                title={() => (
                    <Space
                        align="center"
                        style={{justifyContent: "space-between", width: "100%", overflow: "auto"}}
                        split={<Divider type="vertical" />}
                    >
                        <Space split={<Divider type="vertical" />}>
                            <Statistic title="Total SKUs" value={localFilteredData.reduce((acc, curr) => (acc += curr["Total SKUs"]), 0)} />
                            <Statistic
                                title="Total units"
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Total units"]), 0)}
                            />
                            <Statistic
                                title="Available units"
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Available units"]), 0)}
                            />
                            <Statistic
                                title="Unavailable units"
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Unavailable units"]), 0)}
                            />
                            <Statistic title="Total sold" value={localFilteredData.reduce((acc, curr) => (acc += curr["Total sold"]), 0)} />
                            <Statistic
                                title="Total value sold"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Total value sold"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Inventory Value"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Inventory Value"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Available Value"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Available Value"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Unavailable Value"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Unavailable Value"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Total profit"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Total profit"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Total revenue"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Total revenue"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="TTS < 90"
                                suffix={"%"}
                                value={
                                    localFilteredData.reduce((acc, curr) => (acc += curr["Inventory Value"]), 0) > 0
                                        ? (localFilteredData
                                              .map((brand) => brand["Brand Items"])
                                              .flat()
                                              .filter((item) => item.EstTTS > 0 && item.EstTTS < 90)
                                              .reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0) *
                                              100) /
                                          localFilteredData
                                              .map((brand) => brand["Brand Items"])
                                              .flat()
                                              .filter((item) => item.EstTTS > 0)
                                              .reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0)
                                        : 0
                                }
                                precision={2}
                            />
                            <Statistic
                                title="TTS >= 90"
                                suffix={"%"}
                                value={
                                    localFilteredData.reduce((acc, curr) => (acc += curr["Inventory Value"]), 0) > 0
                                        ? (localFilteredData
                                              .map((brand) => brand["Brand Items"])
                                              .flat()
                                              .filter((item) => item.EstTTS >= 90)
                                              .reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0) *
                                              100) /
                                          localFilteredData
                                              .map((brand) => brand["Brand Items"])
                                              .flat()
                                              .filter((item) => item.EstTTS > 0)
                                              .reduce((acc, curr) => (acc += curr.Stock * curr.Cost), 0)
                                        : 0
                                }
                                precision={2}
                            />
                            <Statistic
                                title="Mean BB Share"
                                suffix={"%"}
                                value={localFilteredData
                                    .map((brand) => brand["Brand Items"])
                                    .flat()
                                    .filter((item) => item.Stock > 0)
                                    .reduce((acc, curr, _, {length}) => (acc += curr.BBShare / length), 0)}
                                precision={2}
                            />
                            <Statistic
                                title="Mean CR"
                                suffix={"%"}
                                value={localFilteredData
                                    .map((brand) => brand["Brand Items"])
                                    .flat()
                                    .reduce((acc, curr, _, {length}) => (acc += Math.max(curr.CR, 0) / length), 0)}
                                precision={2}
                            />
                            <Statistic
                                title="Mean GMROI"
                                value={
                                    (12 *
                                        localFilteredData
                                            .map((brand) => brand["Brand Items"])
                                            .flat()
                                            .reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0)) /
                                    localFilteredData
                                        .map((brand) => brand["Brand Items"])
                                        .flat()
                                        .reduce((acc, curr) => (acc += curr.Cost * (curr["Sold (30D)"] + curr.availableInventory)), 0)
                                }
                                precision={2}
                            />
                            <Statistic
                                title="Mean ROIC"
                                suffix={"%"}
                                value={
                                    (100 *
                                        localFilteredData
                                            .map((brand) => brand["Brand Items"])
                                            .flat()
                                            .reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0)) /
                                    (localFilteredData
                                        .map((brand) => brand["Brand Items"])
                                        .flat()
                                        .reduce((acc, curr) => (acc += ((curr.Stock + curr["Sold (30D)"]) * curr.Cost) / 2), 0) || 1)
                                }
                                precision={2}
                            />
                        </Space>
                        <CSVDownload
                            data={localFilteredData.map(({"Brand Items": useless, ...therest}) => therest)}
                            isLoading={false}
                            collection={`${dayjs().format("MM-DD-YYYY")}_Omega_Brand_Stats`}
                        />
                    </Space>
                )}
                sticky={true}
                bordered={true}
                size="small"
                tableLayout={"auto"}
                dataSource={brandStats}
                pagination={{
                    pageSizeOptions: ["25", "50", "100"],
                    defaultPageSize: 25,
                    showSizeChanger: true,
                }}
                rowSelection={rowSelection}
                columns={columns.filter((column) => !hiddenColumns.includes(getColumnTitle(column)))}
                showSorterTooltip={false}
                onChange={(pagination, filters, sorter, extra) => {
                    setLocalFilteredData(extra.currentDataSource);
                }}
            ></Table>
        </>
    );
});

export default BrandSummary;
