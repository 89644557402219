const MAX_SELLERID = 'A3B3XKFL4HJA0A';
const MAGIC_VOLUME_NUMBER = 1728;
const AMZ_BB_RATIO = 3;
const FBA_BB_RATIO = 1;
const FBM_BB_RATIO = 0.85;
const ASS_SELLER_RATIO = 0.15;
const BASE_PREP_DELAY = 14;

const SALES_DROPOFF = [
    {
        'Change': 0,
        'Drop': 0,
    },
    {
        'Change': 10,
        'Drop': 10,
    },
    {
        'Change': 20,
        'Drop': 35,
    },
    {
        'Change': 30,
        'Drop': 50,
    },
    {
        'Change': 40,
        'Drop': 75,
    },
    {
        'Change': 50,
        'Drop': 95,
    },
];

const WAIT_BENEFIT_DROPOFF = [
    {
        'TimeDiff': 0,
        'Drop': 0,
    },
    {
        'TimeDiff': 15,
        'Drop': 20,
    },
    {
        'TimeDiff': 30,
        'Drop': 40,
    },
    {
        'TimeDiff': 45,
        'Drop': 50,
    },
    {
        'TimeDiff': 60,
        'Drop': 65,
    },
    {
        'TimeDiff': 75,
        'Drop': 70,
    },
];

// const ORDER_RATIOS = {
//     0: 1,
//     1: 0.75,
//     2: 0.65,
//     3: 0.65,
//     4: 0.65,
//     5: 0.60,
//     6: 0.60,
//     7: 0.85,
//     8: 0.7,
//     9: 0.8,
//     10: 1.75,
//     11: 3,
// };

const GMROI_BOUNDARY = 0.75;
const WAIT_BENEFIT_BOUNDARY = 1.25;

const GET_EST_SALES = (insight) => {
    return insight['EstSales (Manual)'] || insight['EstSales (Orders)'] || insight['EstSales (SS)'] || insight['EstSales (Keepa)'] || 0;
};

// /**
//  *
//  * @param {*} asins
//  * @return {*}
//  */
// async function GetKeepa(asins) {
//     const result = [];
//     let i;
//     let temparray;
//     const chunk = 40;
//     for (i = 0; i < asins.length; i += chunk) {
//         temparray = asins.slice(i, i + chunk);
//         const keepaData = await fetch(`https://api.keepa.com/product?key=8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt&domain=1&days=90&update=24&history=0&only-live-offers=1&offers=20&stats=90&asin=${temparray.join(',')}`)
//             .then((res) => res.json());

//         result.push(...keepaData.products);
//     }
//     return result;
// }

/**
 *
 * @param {*} param0
 * @return {*}
 */
function LinearInterpolation({currentValue, dataSet, key, valueKey}) {
    if (currentValue > 0) {
        const SalesRankCorrelation = dataSet;
        const lowerBoundary = SalesRankCorrelation.find((pred) => pred[key] > currentValue);
        const higherBoundary = [...SalesRankCorrelation].reverse().find((pred) => pred[key] <= currentValue);

        if (lowerBoundary && higherBoundary) {
            return lowerBoundary[valueKey] + (currentValue - lowerBoundary[key]) * ((higherBoundary[valueKey] - lowerBoundary[valueKey]) / (higherBoundary[key] - lowerBoundary[key]));
        } else {
            return lowerBoundary?.[valueKey] || higherBoundary?.[valueKey];
        }
    } else {
        return -1;
    }
}

/**
 *
 * @param {*} result
 * @return {*}
 */
function analyzeInsights(result) {
    const yourOffer = result.find((offer) => offer.sellerName === 'M Emporium');
    const gmroiDifferences = result.map((offer) => {
        if (offer.sellerName !== 'M Emporium') {
            return {
                ...offer,
                GMROIDiff: offer.GMROI - (yourOffer?.GMROI || 0),
                ProfitDiff: offer.Profit - (yourOffer?.Profit || 0),
                DaysDiff: offer.YourBandTTS - (yourOffer?.BaseBandTTS || 0),
            };
        } else {
            return {
                ...offer,
                GMROIDiff: 0,
                ProfitDiff: 0,
                DaysDiff: 0,
            };
        }
    }).sort((a, b) => {
        if (b.GMROIDiff - a.GMROIDiff > GMROI_BOUNDARY) {
            return 1;
        } else if (b.GMROIDiff - a.GMROIDiff < -GMROI_BOUNDARY) {
            return -1;
        } else {
            // Compare by WaitBenefit
            if (b.WaitBenefit - a.WaitBenefit > WAIT_BENEFIT_BOUNDARY) {
                return 1;
            } else if (b.WaitBenefit - a.WaitBenefit < -WAIT_BENEFIT_BOUNDARY) {
                return -1;
            } else {
                return 0;
            }
        }
    });

    return gmroiDifferences[0];
}

/**
 *
 * @param {*} pectyData
 * @param {*} resultObject
 * @return {*}
 */
function analyzeBands(pectyData, resultObject) {
    const bands = [];
    pectyData.forEach((offer, idx) => {
        if (offer.sellerId !== MAX_SELLERID) {
            if (bands[bands.length - 1]?.['Qty Within'] === offer['Qty Within'] &&
                bands[bands.length - 1]?.Offers[bands[bands.length - 1]?.Offers.length - 1].priceTotal <= offer['1% Higher'] &&
                bands[bands.length - 1]?.Offers[bands[bands.length - 1]?.Offers.length - 1].priceTotal >= offer['1% Lower']) {
                bands[bands.length - 1].Offers.push(offer);
            } else {
                const newBand = {
                    'Qty Within': offer['Qty Within'],
                    'BaseTTS': offer['Qty Within'] / (GET_EST_SALES(resultObject) / 30),
                    'Offers': [offer],
                    'BaseBandPrice': offer['priceTotal'],
                };
                bands.push(newBand);
            }
        }
    });

    bands.forEach((band, bandIdx) => {
        const currentOffers = band.Offers.map((offer) => ({...offer}));
        let ourQuantity = resultObject['Inventory'];
        let bandTTS = 0;
        for (bandTTS; ourQuantity > 0.3 && bandTTS < 1000; bandTTS++) {
            const BBHolders = currentOffers.reduce((acc, curr) => {
                if (curr.inventoryCount > 0.3) {
                    if (parseInt(curr.sellerRating.match(/\d+/g)?.[1]) < 85) {
                        return acc + ASS_SELLER_RATIO;
                    }

                    if (curr.fba) {
                        if (curr.sellerName === 'Amazon.com') {
                            return acc + AMZ_BB_RATIO;
                        } else {
                            return acc + FBA_BB_RATIO;
                        }
                    } else {
                        return acc + FBM_BB_RATIO;
                    }
                } else {
                    return acc;
                }
            }, 0) + 1;

            const priceChange = 1 - band.BaseBandPrice / resultObject.YourPrice;
            const velocityChange = LinearInterpolation({
                currentValue: Math.abs(priceChange) * 100,
                dataSet: SALES_DROPOFF,
                key: 'Change',
                valueKey: 'Drop',
            });

            const baseSales = (GET_EST_SALES(resultObject) / 30) * (1 + Math.sign(priceChange) * velocityChange / 100);
            band.PriceChange = (-1) * priceChange * 100;
            band.VelocityChange = Math.sign(priceChange) * velocityChange;
            band.BandBaseSales = baseSales * 30;
            currentOffers.forEach((offer, offerIdx) => {
                if (offer.inventoryCount > 0.3) {
                    if (parseInt(offer.sellerRating.match(/\d+/g)?.[1]) > 85) {
                        offer.inventoryCount -= baseSales * (ASS_SELLER_RATIO / BBHolders);
                    }

                    if (offer.fba) {
                        if (offer.sellerName === 'Amazon.com') {
                            offer.inventoryCount -= baseSales * (AMZ_BB_RATIO / BBHolders);
                        } else {
                            offer.inventoryCount -= baseSales * (FBA_BB_RATIO / BBHolders);
                        }
                    } else {
                        offer.inventoryCount -= baseSales * (FBM_BB_RATIO / BBHolders);
                    }
                }
            }, 0);

            // debugger;
            ourQuantity -= baseSales * (FBA_BB_RATIO / BBHolders);
        }

        let waitTime = 0;
        for (let i = 0; i < bandIdx; i++) {
            bandTTS += bands[i].BaseTTS;
            waitTime += bands[i].BaseTTS;
        }

        band.WaitTime = Math.ceil(waitTime);
        band.BandTTS = Math.ceil(bandTTS);
    });

    return bands;
}

// /**
//  *
//  * @param {*} orderData Input ASIN
//  * @return {*}
//  */
// function calculateOrderEstSales(orderData) {
//     const estSales = (orderData
//         .filter((order) => dayjs().diff(dayjs(order['purchase-date']), 'days') <= 30)
//         ?.reduce((sales, order) => {
//             const orderDate = dayjs(order['purchase-date']).month();
//             return sales + (parseInt(order.quantity) / ORDER_RATIOS[orderDate]) * ORDER_RATIOS[dayjs().month()];
//         }, 0)) || 0;

//     return estSales;
// }


/**
 *
 * @param {string} asin Input ASIN
 * @param {*} ssData Input ASIN
 * @param {*} pectyLog Input ASIN
 * @param {*} keepaData Input ASIN
 * @param {*} EstSalesManual Input ASIN
 * @param {*} orderData Input ASIN
 * @return {*}
 */
function insight(asin, ssData, pectyLog, keepaData, EstSalesManual, shippingFee) {
    const resultObject = {
        'ASIN': asin,
        'Title': ssData.title,
        'Link': `https://www.amazon.com/dp/${asin}/?th=1&psc=1`,
        'YourPrice': parseFloat(ssData.listed_price),
        'YourProfit': -1,
        'BBShare (%)': ssData.avg_buybox_share,
        'EstSales (Manual)': EstSalesManual || undefined,
        'EstSales (SS)': ssData.total_order_items > 0 && parseFloat(ssData.avg_buybox_share) > 0 ? (ssData.total_order_items / (ssData.days_in_stock / 30)) / (parseFloat(ssData.avg_buybox_share) / 100) : 0,
        'Cost': parseFloat(ssData.cost),
        'Inventory': parseFloat(ssData.fba_available_quantity ?? ssData.fulfillable_quantity),
        'MinPrice': parseFloat(ssData.min_price),
        'MaxPrice': parseFloat(ssData.max_price),
        'ReferralFee': ssData.fees_structure ? parseFloat(ssData.fees_structure.regular_fee_perc) / 100 : 0.15,
        'FulFee': parseFloat(ssData.fulfillment_cost),
        'StorageFee': 1 || parseFloat((parseFloat(ssData.pkg_volume) / MAGIC_VOLUME_NUMBER * 2.4).toFixed(2)),
        'ShippingFee': shippingFee,
        'TotalCost': -1,
        'TotalInvestment': -1,
        'PectyTimestamp': new Date(),
    };

    const getMaxPrice = (offer) => {
        return Math.min(offer.priceTotal, resultObject.MaxPrice, (resultObject.KeepaMaxBB || Number.POSITIVE_INFINITY));
    };

    resultObject.TotalCost = resultObject.Cost + resultObject.ShippingFee;
    resultObject.TotalInvestment = resultObject.TotalCost * resultObject.Inventory;
    resultObject.PectyTimestamp = new Date(pectyLog.dateProcessed);

    const initialPectyData = Object.values(pectyLog?.data?.offers
        ?.filter((offer) => offer.condition === 'New')
        .reduce((acc, offer) => {
            if (acc[offer.sellerId]) {
                acc[offer.sellerId].inventoryCount += offer.inventoryCount;
            } else {
                acc[offer.sellerId] = {...offer};
            }

            return acc;
        }, {}));

    const cleanedPectyData = [];
    initialPectyData.forEach((offer, idx) => {
        cleanedPectyData.push(offer);
    });

    const analyzedPectyData = cleanedPectyData.map((offer, idx) => {
        const newOffer = {...offer};
        const summedPrice = newOffer.price + newOffer.priceShipping;
        newOffer['1% Lower'] = parseFloat((summedPrice * 0.99).toFixed(2));
        newOffer['1% Higher'] = parseFloat((summedPrice * 1.01).toFixed(2));
        newOffer['Qty Within'] = cleanedPectyData.filter((offer) =>
            offer.sellerId !== MAX_SELLERID &&
            offer.priceTotal >= newOffer['1% Lower'] &&
            offer.priceTotal <= newOffer['1% Higher'],
        )
            .reduce((sum, curr) => sum + curr.inventoryCount, 0);
        newOffer['Units Shared'] = newOffer.sellerId !== MAX_SELLERID ?
            resultObject.Inventory + newOffer.inventoryCount :
            newOffer.inventoryCount;
        return newOffer;
    });

    resultObject.MOQExists = analyzedPectyData.some((offer) => offer.moq)
    const ourOffer = analyzedPectyData.find((offer) => offer.sellerName === 'M Emporium');
    if (ourOffer) {
        resultObject.QTYBelow = analyzedPectyData.filter((offer) => offer.priceTotal < ourOffer.priceTotal).reduce((acc, curr) => acc += curr.inventoryCount, 0)
    } else {
        resultObject.QTYBelow = -1;
    }

    const bands = analyzeBands(analyzedPectyData, resultObject);

    const resultArray = analyzedPectyData.map((oldOffer, idx) => {
        const offer = {...oldOffer};
        if (offer.sellerId !== MAX_SELLERID) {
            const currentBandIndex = bands.findIndex((band) => band['Qty Within'] === offer['Qty Within'] && band.Offers.find((bandOffer) => bandOffer.sellerId === offer.sellerId && bandOffer.priceTotal === offer.priceTotal) !== undefined);
            const currentBand = bands[currentBandIndex];
            if (currentBand) {
                offer.BandIndex = currentBandIndex + 1;
                offer.BaseBandTTS = currentBand.BaseTTS;
                offer.YourBandTTS = currentBand.BandTTS;
                offer.BandWaitTime = currentBand.WaitTime;
                offer.PriceChange = currentBand.PriceChange;
                offer.BandBaseSales = currentBand.BandBaseSales;
                offer.VelocityChange = currentBand.VelocityChange;
                offer['Profit'] = getMaxPrice(offer) -
                    Math.max(getMaxPrice(offer) * resultObject.ReferralFee, ssData.fees_structure?.min_fee || 0.3) -
                    resultObject.FulFee -
                    resultObject.TotalCost
                    - resultObject.StorageFee * Math.min(30, offer.YourBandTTS) / 30 -
                    (resultObject.StorageFee / 3) * Math.max(0, offer.YourBandTTS) / 30;
                offer['TotalProfit'] = offer['Profit'] * resultObject.Inventory;
                offer['GMROI'] = (offer['TotalProfit'] / resultObject.TotalInvestment) * (365 / (offer['YourBandTTS'] + BASE_PREP_DELAY));
            }
        } else {
            offer.inventoryCount = resultObject.Inventory
            offer.BaseBandTTS = (resultObject.Inventory +
                (cleanedPectyData.slice(0, idx + 1).reduce((acc, curr) => curr.sellerId !== MAX_SELLERID ? acc + curr.inventoryCount : acc, 0) || 0)) /
                (GET_EST_SALES(resultObject) / 30);
            offer.YourBandTTS = -1;
            offer.BandWaitTime = -1;
            offer.BandIndex = -1;
            offer['Profit'] = getMaxPrice(offer) -
                Math.max(getMaxPrice(offer) * resultObject.ReferralFee, ssData.fees_structure?.min_fee || 0.3) -
                resultObject.FulFee -
                resultObject.TotalCost
                - resultObject.StorageFee * Math.min(30, offer.BaseBandTTS) / 30 -
                (resultObject.StorageFee / 3) * Math.max(0, offer.BaseBandTTS) / 30;
            resultObject['YourProfit'] = offer['Profit'];
            offer['TotalProfit'] = offer['Profit'] * resultObject.Inventory;
            offer['GMROI'] = (offer['TotalProfit'] / resultObject.TotalInvestment) * (365 / (offer['BaseBandTTS'] + BASE_PREP_DELAY));
        }

        offer.sellerRating = offer.sellerRating.match(/\d+/g)?.[1];

        return offer;
    })
        .map((oldOffer, idx, offerArr) => {
            const offer = {...oldOffer};

            const timeDiff = offer.sellerId !== MAX_SELLERID ? offer['YourBandTTS'] - offerArr[0].YourBandTTS : offer['BaseBandTTS'] - offerArr[0].BaseBandTTS;
            const waitBenefitMulti = 1 - (LinearInterpolation({
                currentValue: timeDiff,
                dataSet: WAIT_BENEFIT_DROPOFF,
                key: 'TimeDiff',
                valueKey: 'Drop',
            }) / 100);

            if (timeDiff !== 0) {
                offer['WaitBenefit'] = (((offer['TotalProfit'] - offerArr[0].TotalProfit)) / (timeDiff)) * waitBenefitMulti;
            } else {
                offer['WaitBenefit'] = 0;
            }

            return offer;
        })
        .map(({condition, inventoryRequestFailed, sellerId, conditionNote, ratingStar, ...prod}) => prod);

    return [resultObject, resultArray];
}

/**
 *
 * @param {*} param0
 * @return {*} whatever
 */
export function prepareInsight({ASIN, pectyData, ssItem, keepaData, 'EstSales (Manual)': EstSalesManual, shippingFee }) {
    if (pectyData?.data?.offers?.filter((offer) => offer.condition === "New")?.length > 0) {
        try {
            const [baseInfo, offers] = insight(ASIN, ssItem, pectyData, keepaData, EstSalesManual, shippingFee );
            const bestOffer = analyzeInsights(offers);
        
            // eslint-disable-next-line no-unused-vars
            const {sellerName, price, priceShipping, fba, inventoryCount, sellerRating, prime, ...theRestOfObj} = bestOffer;
            delete theRestOfObj['1% Lower'];
            delete theRestOfObj['1% Higher'];
            delete theRestOfObj['Qty Within'];
            delete theRestOfObj['Units Shared'];
            delete theRestOfObj['BaseBandTTS'];
            delete Object.assign(theRestOfObj, {'SuggestedPrice': parseFloat(Math.min(theRestOfObj['priceTotal'], baseInfo.MaxPrice).toFixed(2))})['priceTotal'];
        
            if ((theRestOfObj.SuggestedPrice >= 0.95 * baseInfo.YourPrice &&
                theRestOfObj.SuggestedPrice <= 1.05 * baseInfo.YourPrice) ||
                theRestOfObj.ssItem?.days_in_stock < 10) {
                theRestOfObj.SuggestedAction = 'Stay';
            } else if (theRestOfObj.SuggestedPrice < 0.95 * baseInfo.YourPrice) {
                theRestOfObj.SuggestedAction = 'DOWN';
            } else {
                theRestOfObj.SuggestedAction = 'UP';
            }
        
            return {
                ...baseInfo,
                ...theRestOfObj,
                OfferData: offers,
            };
        } catch (ex) {
            console.error('INSIGHT ERROR', ex)
            return null;
        }
    } else {
        return null;
    }
}
