import {notification} from "antd";
import {AppState, BoxingActionsSlice, ImmerStateCreator} from "../../types/storeTypes";
import {
    MARKETPLACE_ID,
    confirmTransport,
    downloadReport,
    estimateTransport,
    getBillOfLading,
    getFeed,
    getLabels,
    getTransportDetails,
    putTransportDetails,
    removeItemFromShipment,
    submitShipmentContents,
    updateInboundShipment,
    voidTransport as voidTransportApi,
} from "../../services/SPApiService";
import {extractSubmitContentsReport, extractTransportDetails} from "../../components/warehouse/boxing/helpers";
import {ShipmentType} from "../../types/WarehouseTypes";
import {TransportDetailInput} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const downloadPdf = async (url: string, filename: string) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
};

export const createBoxingActionsSlice: ImmerStateCreator<AppState, BoxingActionsSlice> = (set, get) => ({
    submitShipment: async (shipmentId: string) => {
        const state = get();

        state.updateShipmentStatus(shipmentId, {
            feed: {processingStatus: "IN_PROGRESS"},
            feedResult: undefined,
            transportDetails: undefined,
        });
        state.addLoadingShipment(shipmentId, "Submitting shipment");

        const shipment = state.shipments.byId[shipmentId];
        const shipmentBoxes = Object.values(state.boxes.byId).filter((box) => box.shipmentId === shipmentId);
        const shipmentPallets = Object.values(state.pallets.byId).filter((pallet) => pallet.shipmentId === shipmentId);

        try {
            // Check if all boxes have dimensions
            const boxesWithoutDimensions = shipmentBoxes.filter((box) => !box.dimensions);
            if (boxesWithoutDimensions.length > 0) {
                throw new Error("All boxes must have dimensions before submitting the shipment");
            }

            // Check if all boxes have items
            const boxesWithoutItems = shipmentBoxes.filter((box) => box.items.length === 0);
            if (boxesWithoutItems.length > 0) {
                throw new Error("All boxes must have items before submitting the shipment");
            }
            // Check if all pallets have dimensions
            const palletsWithoutDimensions = shipmentPallets.filter((pallet) => !pallet.dimensions);
            if (shipment.shipmentType === "LTL" && palletsWithoutDimensions.length > 0) {
                throw new Error("All pallets must have dimensions before submitting the shipment");
            }

            const submitResult = await submitShipmentContents([shipment], shipmentBoxes);

            let feed = await getFeed({
                feedId: submitResult.feedId,
            });
            await sleep(2000);
            while (feed.processingStatus !== "DONE" && feed.processingStatus !== "CANCELLED" && feed.processingStatus !== "FATAL") {
                state.updateShipmentStatus(shipment.ShipmentId, {feed});
                await sleep(20000);
                feed = await getFeed({
                    feedId: submitResult.feedId,
                });
            }

            const feedReport = await downloadReport({
                feedDocumentId: feed.resultFeedDocumentId || "",
            });
            const extractedFeedReport = extractSubmitContentsReport(feedReport);
            state.updateShipmentStatus(shipment.ShipmentId, {feed, feedResult: {...extractedFeedReport, timestamp: new Date().getTime()}});
            state.saveData({shipmentIds: [shipmentId]});

            if (feed.processingStatus === "DONE" && extractedFeedReport.messages.length === 0) {
                notification.success({
                    message: "Shipment submitted",
                    description: "The shipment has been submitted successfully",
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "There was an error submitting the shipment",
                });
            }
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
            state.updateShipmentStatus(shipmentId, {feed: {processingStatus: undefined}, feedResult: undefined});
        }
        state.removeLoadingShipment(shipmentId);
    },
    putTransportDetails: async (shipmentId: string, transportDetailsInput: TransportDetailInput) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        const shipmentPallets = Object.values(state.pallets.byId).filter((pallet) => pallet.shipmentId === shipmentId);

        state.addLoadingShipment(shipmentId, "Estimating transport");

        // Determine the shipment type
        let shipmentType: ShipmentType | undefined = shipment.shipmentType;
        if (!shipmentType) {
            shipmentType = shipmentPallets.length > 1 ? "LTL" : "SP";
        }

        try {
            await putTransportDetails({
                shipmentId,
                body: {
                    IsPartnered: true,
                    ShipmentType: shipmentType,
                    TransportDetails: transportDetailsInput,
                },
            });
            await sleep(1000);
            await estimateTransport(shipmentId);
            await sleep(1000);
            let transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            while (!transportDetails.estimate?.Amount && transportDetails.status !== "ERROR_ON_ESTIMATING") {
                await sleep(10000);
                transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            }
            state.updateShipmentStatus(shipmentId, {transportDetails});
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
            state.updateShipmentStatus(shipmentId, {transportDetails: {status: "ERROR", error: err}});
        }
        state.removeLoadingShipment(shipmentId);
        state.saveData({shipmentIds: [shipmentId]});
    },
    confirmTransportDetails: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        state.addLoadingShipment(shipmentId, "Confirming transport");

        try {
            await confirmTransport(shipmentId);
            let transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            while (!transportDetails.estimate?.VoidDeadline && transportDetails.status !== "ERROR_ON_CONFIRMING") {
                await sleep(10000);
                transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            }
            if (process.env.NODE_ENV === "development") {
                transportDetails.status = "CONFIRMED";
            }
            state.updateShipmentStatus(shipmentId, {transportDetails});
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
            state.updateShipmentStatus(shipmentId, {transportDetails: {...shipment.transportDetails, status: "ERROR", error: err}});
        }
        state.removeLoadingShipment(shipmentId);
        state.saveData({shipmentIds: [shipmentId]});
    },
    voidTransport: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        state.addLoadingShipment(shipmentId, "Voiding transport");

        try {
            await voidTransportApi(shipmentId);
            let transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            while (
                transportDetails.status !== "VOIDED" &&
                transportDetails.status !== "WORKING" &&
                transportDetails.status !== "ERROR_IN_VOIDING"
            ) {
                await sleep(10000);
                transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            }
            state.updateShipmentStatus(shipmentId, {transportDetails});
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
            state.updateShipmentStatus(shipmentId, {transportDetails: {...shipment.transportDetails, status: "ERROR", error: err}});
        }
        state.removeLoadingShipment(shipmentId);
        state.saveData({shipmentIds: [shipmentId]});
    },
    completeShipment: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        state.addLoadingShipment(shipmentId, "Completing shipment");

        try {
            await updateInboundShipment({
                shipmentId,
                body: {
                    MarketplaceId: MARKETPLACE_ID,
                    InboundShipmentItems: [],
                    InboundShipmentHeader: {
                        DestinationFulfillmentCenterId: shipment.DestinationFulfillmentCenterId,
                        LabelPrepPreference: "SELLER_LABEL",
                        ShipFromAddress: shipment.ShipFromAddress,
                        ShipmentName: shipment.ShipmentName,
                        ShipmentStatus: "SHIPPED",
                    },
                },
            });
            state.updateShipmentStatus(shipmentId, {shipmentStatus: "SHIPPED"});

            notification.success({
                message: "Shipment completed",
                description: "The shipment has been completed successfully",
            });
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
        state.removeLoadingShipment(shipmentId);
        state.saveData({shipmentIds: [shipmentId]});
    },
    getLabels: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        const boxes = Object.values(state.boxes.byId).filter((box) => box.shipmentId === shipmentId);
        const pallets = Object.values(state.pallets.byId).filter((pallet) => pallet.shipmentId === shipmentId);
        state.addLoadingShipment(shipmentId, "Printing labels");

        try {
            const res = await getLabels(
                shipment,
                boxes.map((box) => box.id),
                pallets.length
            );

            const {boxes: boxLabels, pallets: palletLabels} = res;

            const blob = new Blob([Uint8Array.from(boxLabels.data).buffer], {
                type: "application/pdf",
            });
            const blobURL = URL.createObjectURL(blob);
            downloadPdf(blobURL, `${shipmentId}-boxes.pdf`);

            if (palletLabels) {
                const palletBlob = new Blob([Uint8Array.from(palletLabels.data).buffer], {
                    type: "application/pdf",
                });
                const palletBlobURL = URL.createObjectURL(palletBlob);
                downloadPdf(palletBlobURL, `${shipmentId}-pallets.pdf`);
            }

            notification.success({
                message: "Labels downloaded",
            });
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        state.removeLoadingShipment(shipmentId);
    },
    getBillOfLading: async (shipmentId: string) => {
        get().addLoadingShipment(shipmentId, "Downloading bill of lading");
        try {
            const res = await getBillOfLading(shipmentId);
            const blob = new Blob([Uint8Array.from(res.data).buffer], {
                type: "application/pdf",
            });
            const blobURL = URL.createObjectURL(blob);
            downloadPdf(blobURL, `${shipmentId}-bill-of-lading.pdf`);
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        get().removeLoadingShipment(shipmentId);
    },
    checkTransport: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        state.addLoadingShipment(shipmentId, "Checking transport");

        try {
            const transportDetails = extractTransportDetails(await getTransportDetails(shipmentId));
            state.updateShipmentStatus(shipmentId, {transportDetails});
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
            state.updateShipmentStatus(shipmentId, {transportDetails: {...shipment.transportDetails, status: "ERROR", error: err}});
        }

        state.removeLoadingShipment(shipmentId);
    },
    removeItemFromShipment: async (shipmentId: string, sku: string, quantity: number) => {
        const state = get();
        state.addLoadingShipment(shipmentId, "Removing item from shipment");
        try {
            const newItems = await removeItemFromShipment(shipmentId, sku, quantity, false);
            set((s) => {
                s.shipments.byId[shipmentId].ExpectedItems = newItems;
            });
            notification.success({
                message: "Item removed",
                description: "The item has been removed from the shipment",
            });
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }

        state.removeLoadingShipment(shipmentId);
    },
});
