import React, {useMemo} from "react";
import {OMSItem} from "../../../types/OmegaTypes";
import {Modal, List, Typography, Row, Col, Skeleton, Avatar, Button} from "antd";
import {WarehouseProduct} from "../../../types/WarehouseTypes";

const RemainingItemsDialog: React.FC<{
    open: boolean;
    items: OMSItem[];
    scannedItems: WarehouseProduct[];
    onClose: () => void;
    onItemSelect: (item: OMSItem) => void;
    onAllItemsSelect: (items: OMSItem[]) => void;
}> = ({items, onClose, open, scannedItems, onItemSelect, onAllItemsSelect}) => {
    const remainingItems = useMemo(() => {
        const scannedAsinsSet = new Set<string>();
        for (const item of items) {
            if (item.ASIN) scannedAsinsSet.add(item.ASIN);
        }
        for (const item of scannedItems) {
            scannedAsinsSet.delete(item.asin);
        }
        return items.filter((item) => scannedAsinsSet.has(item.ASIN || ""));
    }, [items, scannedItems]);

    return (
        <Modal
            title="Remaining Items"
            open={open}
            onCancel={onClose}
            onOk={onClose}
            footer={
                <>
                    <Button
                        onClick={() => {
                            onAllItemsSelect(remainingItems);
                        }}
                        disabled={remainingItems.length === 0}
                    >
                        Select all
                    </Button>
                </>
            }
            width={"50vw"}
        >
            <List
                dataSource={remainingItems}
                style={{maxHeight: "50vh", overflow: "auto"}}
                renderItem={(item) => (
                    <List.Item key={item.ASIN}>
                        <Row style={{width: "100%"}} justify={"center"} align={"middle"}>
                            <Col span={4}>
                                {item.KeepaData?.imagesCSV === null ? (
                                    <Skeleton.Image style={{height: 50}} />
                                ) : (
                                    <Avatar
                                        shape="square"
                                        size={60}
                                        src={`https://images-na.ssl-images-amazon.com/images/I/${item.KeepaData?.imagesCSV?.split(",")[0]}`}
                                    />
                                )}
                            </Col>
                            <Col span={4}>
                                <Typography.Text>{item.ASIN}</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text>{item.UPC}</Typography.Text>
                            </Col>
                            <Col span={8}>
                                <Typography.Text>{item.AMZ_Title}</Typography.Text>
                            </Col>
                            <Col span={4} style={{padding: 10}}>
                                <Button
                                    style={{
                                        width: 80,
                                        height: 40,
                                    }}
                                    onClick={() => onItemSelect(item)}
                                >
                                    Select
                                </Button>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default RemainingItemsDialog;
