export const CURRENT_TO_RUN = `ASIN	MAP Price
B00OKEPHLE	104.99
B00OKEPMUK	104.99
B01BCLXMBO	124.99
B00PBA2P7K	124.99
B00GSWTWSK	119.99
B07DGYBWLR	119.99
B07LGCNPKB	119.99
B07LGCFG29	119.99
B07LGFBSGD	119.99
B00E0N2PGU	119.99
B002CWQLW0	119.99
B017V63I54	104.99
B0176BRYJK	104.99
B017V63FLG	104.99
B0043GB7DG	244.99
B0043GB74A	244.99
B00IPO0FZ8	64.99
B017V63J7Q	104.99
B006YFP6FE	94.99
B005GKI5JW	119.99
B081S42TT4	119.99
B00TUG6H4I	119.99
B00JSQICES	124.99
B01C4VH00K	94.99
B000F7HVI2	99.99
B00F69WQ1G	244.99
B00030NSKG	244.99
B00030NSJM	244.99
B074QP4K4F	119.99
B008H3RL4U	244.99
B01IA7NDXU	149.99
B01IA7KWT8	94.99
B00ITYS8U8	89.99
B00JNPP6O8	99.99
B00OKEPOAS	104.99
B005GKDL9G	119.99
B00E459C6K	159.99
B00E4591RU	159.99
B00E459G9I	159.99
B00E459BBG	159.99
B00E459A6M	159.99
B07PVKYX7H	159.99
B07PXM4FR5	159.99
B00394SH1S	124.99
B07PXLN1RZ	119.99
B00NC6DLPA	119.99
B001RDOD0M	159.99
B00E45GXXA	159.99
B01K9XCUOG	139.99
B01FWBAG46	59.99
B00BZD7FW2	104.99
B00BZD7HKC	104.99
B017V63HYG	104.99
B01MSEQIQ0	54.99
B01N9YQSZA	54.99
B0176BRS1O	104.99
B085J19M6X	54.99
B017V63H5K	104.99
B017V63J0S	104.99
B01IA7JMG2	104.99
B00CM9OS5K	119.99
B07DGYMJXM	119.99
B07DGXB86W	119.99
B008R09WCC	94.99
B011BDXIC6	52.99
B00A4N7ZT2	99.99
B001O2691C	99.99
B000F7JX7O	99.99
B0043G9FV2	244.99
B00WC5KG46	49.99
B00GXG986C	104.99
B01M0GFQY5	269.99
B074QRDZXX	119.99
B074QRCZBC	119.99
B074QR6L9D	119.99
B00OKEPLH4	104.99
B00BZV19T4	104.99
B011BDGML0	49.99
B011AASBNG	49.99
B01IA7NTCK	149.99
B01IA7PQCQ	149.99
B01IA7QCW4	149.99
B01IA7QLPM	149.99
B017V63GCY	104.99
B01K9XD9DW	64.99
B005GKI5JC	119.99
B085HZQRQB	54.99
B00GB08SEI	159.99
B00GB09504	159.99
B00GB08TTM	159.99
B00HXKQ3F6	159.99
B00HKGR4DI	159.99
B005GKDL96	119.99
B0043GB78Q	244.99
B008R08UFM	94.99
B08B8YR2JC	89.99
B00PBA2KPC	124.99
B00PBA2KMU	124.99
B00PBA2KN4	124.99
B00A4N7ZHE	99.99
B00A4N7YOS	99.99
B07SJVRSM8	52.99
B000F7FWVA	99.99
B0176BLBTE	99.99
B00YCXAG00	99.99
B008SCO6K2	64.99
B003WLUXRO	64.99
B003WLWSDQ	64.99
B00IPO0GBG	64.99
B011AAXCJY	49.99
B011BDFOMS	49.99
B00PBA2KPM	124.99
B07NMT4Z6S	119.99
B00GB08J1U	159.99
B0032K5CM6	159.99
B00E45HSHU	159.99
B00E45HGNQ	159.99
B00E45HA0U	159.99
B00E45GSX0	159.99
B00E45GOC0	159.99
B004D7TJIU	159.99
B07PWHXYD3	119.99
B008QN6ANY	139.99
B01IA6ZM44	124.99
B00QAWOW3I	124.99
B00E459ECW	159.99
B07MXNKL8Z	149.99
B00E17V9J4	59.99
B00EHE0FXC	69.99
B00EHE0FYQ	69.99
B00EHE0FXM	69.99
B004FTPMAU	109.99
B0041M5D9Q	119.99
B002CWSQKA	119.99
B002CWQLX4	119.99
B0041LY208	119.99
B00C0ZY39W	89.99
B0048FCXVW	89.99
B00B35OMBO	149.99
B00B35OM96	149.99
B00B35OM9G	149.99
B00B35OM8M	149.99
B00B35OM8W	149.99
B00B35OM82	149.99
B011A9WV74	49.99
B08NXYDLRL	69.99
B084HD1TL6	79.99
B084T8GW6F	99.99
B084T7SR7H	99.99
B00A4N8Z5K	49.99
B00ECXAPPG	69.99
B07P585VGB	129.99
B00ECEXJRG	49.99
B00BZD77MA	104.99
B07892WVC1	69.99
B01FWBA7TU	129.99
B07CWWQS2Y	69.99
B084937WPG	139.99
B07YG93RSS	139.99
B00394S9HK	124.99
B00394S9F2	124.99
B01IA7K71G	94.99
B008R0A0XC	94.99
B00O4FW7IK	124.99
B00HFY0CMK	124.99
B08KPLDVNP	89.99
B01DS3DAD4	89.99
B07D4QY3R6	69.99
B0848JKGD6	139.99
B0848VJRKY	139.99
B0788VBKMM	69.99
B0789F4XFD	69.99
B0848W5ZXN	139.99
B08KH8B133	119.99
B00MQVVDTI	159.99
B00394JGXG	124.99
B00394Q5OY	124.99
B01IA7TW44	119.99
B008R0A1BI	94.99
B00O4FUZ9S	124.99
B004DP6E6C	124.99
B084T87XYY	99.99
B07D4Q1NZ8	69.99
B01N706N3Q	89.99
B01GGSS7ZO	139.99
B06XSNJDHF	89.99
B06XSLXWDV	89.99
B06XSLVSQQ	89.99
B000PCD00A	119.99
B00U5VPOD2	149.99
B0848W5ZXM	139.99
B0848M23RL	139.99
B00N4668YE	159.99
B0848SK9CG	139.99
B0176BRO5E	104.99
B0176BRWX8	104.99
B01K9XDP18	54.99
B085HZF4RQ	54.99
B00GXG963C	104.99
B0176BRTMM	104.99
B0176BRVA2	104.99
B07Q741MH5	149.99
B00CHQSHKA	104.99
B00FG9QERI	159.99
B007BG9Y0S	124.99
B005GKDL8W	119.99
B07DGXT416	119.99
B07DGXJXD6	119.99
B07NDYH6MY	119.99
B081QXCBRD	119.99
B00BUAT0CS	94.99
B011AAZO9A	54.99
B0041M19OO	119.99
B0041LZNGU	119.99
B0041M391K	119.99
B0176BS2UA	59.99
B06XT4R3RV	59.99
B01636SR22	59.99
B00IPO0G1G	64.99
B07PXLQVTC	119.99
B011AADFZA	59.99
B011AARH54	59.99
B00A4N7Z6K	99.99
B008SUDOIE	94.99
B008R08V9W	94.99
B008R08V42	94.99
B008R08VHO	94.99
B01IA6W3QY	124.99
B00QAWOW7O	124.99
B01IA6UMZS	124.99
B000HK79JS	124.99
B000HK79K2	124.99
B000HKB7Z0	124.99
B000HK79KC	124.99
B00AH00LPW	159.99
B01IA7IFLA	94.99
B01IA7IHW2	94.99
B01IA7IBYG	94.99
B00WC5KG6Y	49.99
B005AOO56G	119.99
B005AOO516	119.99
B005AOO4BC	119.99
B00EUG4NH6	119.99
B00O4FWXSE	124.99
B00IPO0G70	64.99
B08B5J3QYZ	74.99
B005AOO4HG	119.99
B07P8DWYZC	149.99
B07Q41HZTN	64.99
B07Q41R5DQ	64.99
B07Q426DB5	64.99
B07Q51VLV2	64.99
B07Q426DB7	64.99
B07Q531T85	64.99
B07Q33PXQ9	64.99
B07Q52G81N	64.99
B07CTB5XRS	179.99
B003U1N468	119.99
B0848G7W52	139.99
B00A4N910S	49.99
B00JF37HAE	104.99
B00CHOLM9A	104.99
B00CHOLMAY	104.99
B00CHOLMCM	104.99
B00CHOLMB8	104.99
B00CHOLMAE	104.99
B0176BT0X8	89.99
B00HNYSD50	149.99
B00M62YAOC	149.99
B00JEAJVBG	104.99
B00BZD7I7O	104.99
B0044FIXOC	104.99
B0044FKSB8	104.99
B00D6K5OR0	104.99
B0043GD4V4	244.99
B000F7FVI4	99.99
B00F69WQ2A	244.99
B0048FCXWQ	89.99
B00B3XMM8Q	89.99
B00F69WQFM	244.99
B0848RDMVV	139.99
B0848SVNYS	139.99
B01IA7IZBK	104.99
B004FTPLVA	139.99
B013F4A840	64.99
B00030NSI8	244.99
B00030NSCE	244.99
B00030NSII	244.99
B01M0S1LCX	269.99
B07GS3PCV6	139.99
B07TRJSKK9	129.99
B07482RMKZ	129.99
B01IA7OT7O	149.99
B01IA7PC1G	149.99
B0176BGVBM	139.99
B0721VH243	129.99
B0721VJ1YR	129.99
B07FVXGHN2	159.99
B08KHNDVFB	119.99
B08KHCQHDL	119.99
B08KH88QS5	119.99
B074TGV9RW	129.99
B074TCLF7P	129.99
B00YFTB1SM	129.99
B072R82VS1	129.99
B08RLP3GSL	74.99
B00HNYSCK6	149.99
B0789FQHWS	69.99
B09BK8GY37	139.99
B08KHP532W	119.99
B073L6Z11G	129.99
B0049HRD5K	299.99
B0049HTEJ8	299.99
B00MQVV3N4	169.99
B09BK6TT4Q	129.99
B0043GB768	244.99
B008H3RKV4	244.99
B008H3RKW8	244.99
B06XTYYWP1	159.99
B01MSEQK7N	54.99
B0043GF6O2	244.99
B0BQX43Y8M	159.99
B01N7SL9KC	149.99
B008H3RKXM	244.99
B008H3RKVO	244.99
B008H3RKXW	244.99
B008H3RKVE	244.99
B00F69WQ3O	244.99
B00030NSJC	244.99
B00030NSDS	244.99`