import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Badge, Button, Popconfirm, Space, Tag} from "antd";
import React, {useEffect} from "react";

interface UnsavedChangesHandlerProps {
    isSaved: boolean;
    disableWhenSaved?: boolean;
    isLoading: boolean;
    onDiscardChanges?: () => void;
    onSaveChanges: () => void;
    saveConfirmMessage?: string;
    changesCounter?: number;
    title?: string;
}

export const UnsavedChangesHandler: React.FC<UnsavedChangesHandlerProps> = ({
    isSaved,
    isLoading,
    onDiscardChanges,
    onSaveChanges,
    saveConfirmMessage,
    disableWhenSaved,
    changesCounter,
    title,
}) => {
    let saveButton = (
        <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            onClick={() => {
                onSaveChanges();
            }}
            disabled={isSaved && disableWhenSaved}
        >
            {title || "Save and Push"}
        </Button>
    );

    if (saveConfirmMessage) {
        saveButton = (
            <Popconfirm
                title={saveConfirmMessage}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => onSaveChanges()}
                overlayStyle={{
                    maxWidth: "300px",
                }}
                placement="bottom"
                disabled={isSaved && disableWhenSaved}
            >
                <Button loading={isLoading} icon={<SaveOutlined />} disabled={isSaved && disableWhenSaved}>
                    {title || "Save and Push"}
                </Button>
            </Popconfirm>
        );
    }

    useEffect(() => {
        const onBeforeUnload = (e: any) => {
            if (!isSaved) {
                e.preventDefault();
                e.returnValue = "";
            }
        };

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [isSaved]);

    return (
        <Space direction="horizontal">
            {!isSaved ? (
                <div>
                    <Tag
                        color="orange"
                        style={{
                            padding: 4,
                            paddingRight: onDiscardChanges ? 2 : undefined,
                            display: "initial",
                        }}
                    >
                        Unsaved changes {changesCounter ? `(${changesCounter})` : ""}
                        {onDiscardChanges && (
                            <Popconfirm
                                title="Do you want to discard unsaved changes?"
                                okText="Yes"
                                cancelText="No"
                                placement="bottom"
                                onConfirm={() => onDiscardChanges()}
                            >
                                <Button
                                    type="link"
                                    style={{width: 15, height: 15}}
                                    icon={
                                        <CloseOutlined
                                            width={10}
                                            height={10}
                                            style={{
                                                fontSize: 10,
                                                color: "grey",
                                                margin: 0,
                                            }}
                                        />
                                    }
                                />
                            </Popconfirm>
                        )}
                    </Tag>
                </div>
            ) : null}
            <Badge dot={!isSaved} size="default" status="warning" title="Unsaved changes">
                {saveButton}
            </Badge>
        </Space>
    );
};
