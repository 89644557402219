import {
    CheckOutlined,
    DeleteFilled,
    FileTextOutlined,
    FolderAddOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Button,
    Col,
    Divider,
    InputRef,
    Modal,
    notification,
    Row,
    Space,
    Switch,
} from 'antd';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { UnsafeFetchAttemptError } from '../../../services/errors';
import {
    generateTrackerData,
    pushTrackerData,
    updateOMSPrelisted,
    deleteOMS,
    updatePOTrackerArchive,
    getOMSTracker,
} from '../../../services/WholesaleService';
import {
    OMSTrackerItem,
    OMSTrackerItemRenderType,
    POSSIBLE_OMS_TRACKER_ISSUES,
} from '../../../types/OmegaTypes';
import { CSVDownload } from '../../utilities/CSVDownload';
import { updatePrelisted } from '../../utilities/OMSCalculations';
import {
    makeColumnsEditable,
    makePOTrackerColumns,
} from '../../utilities/OMSColumns';
import { UnsavedChangesHandler } from '../../utilities/UnsavedChangesHandler';
import { BulkActionsButton, BulkActionType } from '../table/BulkActions';
import { EditableColumnType } from '../table/EditableCell';
import EditableTable, { EditableTableHandle } from '../table/EditableTable';
import dayjs from 'dayjs';
import * as dataForge from 'data-forge';
import * as _ from 'lodash';
import { UploadToSlack } from '../../../services/OmegaService';
import ExcelJS from 'exceljs';
import { GeneratePackageDimsModal } from './GeneratePackageDimsModal';
import { SLACK_CHANNEL_IDS } from '../../utilities/common';
import { ExpandedNote } from '../../utilities/NotesRenderer';

interface IssueData {
    POs: number;
    Shipped: number;
    Unshipped: number;
    Units: number;
    Value: number;
    AmountPaid: number;
    AmountUnshipped: number;
}

const MapIssueToMessageSummary = (issue: string, issueData: IssueData) => {
    if (issue === 'SHIPPED LAST 7 DAYS') {
        return `_${issue}_: ${issueData.Units.toLocaleString()} units valued at $${issueData.Value.toLocaleString()}`;
    } else if (issue === 'PAID/UNSHIPPED') {
        return `_${issue}_: ${issueData.POs.toLocaleString()} POs paid for at $${issueData.AmountPaid.toLocaleString()}`;
    } else if (issue === 'PAID/PARTIAL SHIPPED') {
        return `_${issue}_: ${issueData.POs.toLocaleString()} POs with ${issueData.Unshipped.toLocaleString()} unshipped units valued at $${issueData.AmountUnshipped.toLocaleString()}`;
    } else if (issue === 'PARTIAL SHIPPED') {
        return `_${issue}_: ${issueData.POs.toLocaleString()} POs of ${issueData.Units.toLocaleString()} units valued at $${issueData.Value.toLocaleString()} with ${issueData.Unshipped.toLocaleString()} unshipped units valued at $${issueData.AmountUnshipped.toLocaleString()}`;
    }

    return `_${issue}_: ${issueData.POs.toLocaleString()} POs with ${issueData.Units.toLocaleString()} units valued at $${issueData.Value.toLocaleString()}`;
};

export const POTracker: React.FC = () => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<OMSTrackerItemRenderType[]>([]);
    const [itemsToArchive, setItemsToArchive] = useState<
        OMSTrackerItemRenderType[]
    >([]);
    const [editable, setEditable] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] =
        useState<Date>(fetchTimestamp);
    const [modal, modalContext] = Modal.useModal();
    const tableRef =
        useRef<EditableTableHandle<OMSTrackerItemRenderType>>(null);
    const [packageDimsModalOpen, setPackageDimsModalOpen] = useState(false);
    const queryClient = useQueryClient();

    const {
        data: ssData,
        isLoading,
        isRefetching,
        refetch,
        error
    } = useQuery({
        queryKey: ['potracker'],
        queryFn: async () => {
            if (lastUpdateTimestamp > fetchTimestamp) {
                throw new UnsafeFetchAttemptError(
                    'You have unsaved changes in your table that prevent automatic update'
                );
            } else {
                const token = await currentUser!.getIdToken();
                const data = (await getOMSTracker(
                    token, false
                )) as OMSTrackerItem[];
                return data;
            }
        },
        enabled: true,
        staleTime: Infinity,
        refetchInterval: 1800000,
        refetchIntervalInBackground: true,
        // initialData: [],
    });

    useEffect(() => {
        if (error instanceof UnsafeFetchAttemptError) {
            notification.info({
                message: 'Could not fetch data',
                description: error.message,
            });
        } else if (error) {
            notification.error({
                message: 'Error',
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error])

    const omsMutation = useMutation({
        mutationFn: async (data: OMSTrackerItemRenderType[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error('Auth token not found');
            }

            if (!ssData) {
                throw new Error('Invoice data was not fetched correctly');
            }

            // We don't want to delete archived items, so we have to concatenate
            // them with the current table data
            const itemsToDelete = _.differenceBy(
                ssData,
                data.concat(itemsToArchive),
                item => item.Supplier_PO + item.Supplier_SO
            );
            await pushTrackerData(token, data);
            await updateOMSPrelisted(token, data);
            if (itemsToDelete.length > 0) {
                await deleteOMS(
                    token,
                    itemsToDelete.map(item => ({
                        Supplier_PO: item.Supplier_PO,
                        Supplier_SO: item.Supplier_SO,
                    }))
                );
            }
            if (itemsToArchive.length > 0) {
                await updatePOTrackerArchive(token, itemsToArchive);
            }
            return data;
        },
        onSuccess: data => {
            queryClient.setQueryData(['potracker'], data);
            setFetchTimestamp(new Date());
            setItemsToArchive([]);
            // Automatically update data in PO Browser
            queryClient.refetchQueries({ queryKey: ['oms_data'] });
            notification.success({
                message: 'Success!',
            });
        },
        onError: error => {
            notification.error({
                message: 'Upload error',
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        if (ssData) {
            setTableData(ssData.map((item, idx) => ({ ...item, key: idx })));
            setItemsToArchive([]);
            setLastUpdateTimestamp(fetchTimestamp);
        }
        // eslint-disable-next-line
    }, [ssData]);

    const handleSave = useCallback(
        (row: OMSTrackerItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex(item => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setTableData(newData);
            setLastUpdateTimestamp(new Date());
        },
        [tableData]
    );

    const deleteItems = useCallback(
        (keysToDelete: React.Key[]) => {
            const keysSet = new Set(keysToDelete);
            const dataCopy = [...tableData];
            const newData = dataCopy.filter(item => !keysSet.has(item.key));

            setTableData(
                newData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(new Date());
        },
        [tableData]
    );

    const archiveItems = useCallback(
        (keysToArchive: React.Key[]) => {
            const keysSet = new Set(keysToArchive);
            const dataCopy = [...tableData];
            const newData = dataCopy.filter(item => !keysSet.has(item.key));
            const dataToArchive = dataCopy
                .filter(item => keysSet.has(item.key))
                .map(item => ({ ...item, ArchiveTimestamp: new Date() }));

            setTableData(
                newData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setItemsToArchive(itemsToArchive.concat(dataToArchive));
            setLastUpdateTimestamp(new Date());
        },
        [tableData, itemsToArchive]
    );

    const generateTracker = async () => {
        setIsGenerating(true);
        const token = await currentUser!.getIdToken();
        generateTrackerData(token, false).then(() => {
            refetch();
        }).finally(() => {
            setIsGenerating(false);
        })
    }

    const postSummary = async () => {
        const wholeFrame = new dataForge.DataFrame(tableData)
            .transformSeries({
                'Notes': val => val && Array.isArray(val)
                    ?
                    val.map((note: ExpandedNote | string) => typeof note === 'string'
                        ?
                        note :
                        `${note.noteTimestamp}: ${note.noteText} - ${note.noteAuthor}`
                    ).join(' || \n')
                    :
                    val,
            });
        const allColumns = wholeFrame
            .getColumnNames()
            .map(column => ({ header: column, key: column }));
        const workbook = new ExcelJS.Workbook();
        const issueMap: { [key: string]: IssueData } = {};
        for (const issue of POSSIBLE_OMS_TRACKER_ISSUES) {
            const issueSheet = workbook.addWorksheet(
                issue.replace(/\*|\?|:|\\|\/|\[|\]/g, ' ')
            );
            const issueData = wholeFrame
                .where(row => row.Issues.includes(issue))
                .transformSeries({
                    Ship_Requested: val =>
                        val ? dayjs(val).format('MM-DD-YYYY') : '',
                    'Last Shipment Date': val =>
                        val ? dayjs(val).format('MM-DD-YYYY') : '',
                    'Date Paid': val =>
                        val ? dayjs(val).format('MM-DD-YYYY') : '',
                })
                .toArray();

            issueMap[issue] = {
                POs: issueData.length,
                Shipped: issueData.reduce(
                    (acc, curr) => (acc += curr['Units Shipped']),
                    0
                ),
                Unshipped: issueData.reduce(
                    (acc, curr) => (acc += curr['Units Unshipped']),
                    0
                ),
                Units: issueData.reduce(
                    (acc, curr) => (acc += curr['Total Units']),
                    0
                ),
                Value: issueData.reduce(
                    (acc, curr) => (acc += curr['Order Amount']),
                    0
                ),
                AmountPaid: issueData.reduce(
                    (acc, curr) => (acc += curr['Amount Paid']),
                    0
                ),
                AmountUnshipped: issueData.reduce(
                    (acc, curr) => {
                        console.log(curr['Units Unshipped Value'])
                        return (acc += curr['Units Unshipped Value'])
                    },
                    0
                ),
            };
            issueSheet.columns = allColumns;
            issueSheet.addRows(issueData);
        }
        const text = await workbook.xlsx.writeBuffer();
        const file = new Blob([text], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        const fileIntro = Object.entries(issueMap)
            .map(([issue, issueData]) =>
                MapIssueToMessageSummary(issue, issueData)
            )
            .join('\n');
        await UploadToSlack(
            SLACK_CHANNEL_IDS.BUYERS_OMS,
            file,
            `OMS_Summary_${dayjs().format('MM-DD-YYYY')}.xlsx`,
            `*OMS Summary for ${dayjs().format('MM-DD-YYYY')}*\n\n` + fileIntro
        )
            .then(res => res.json())
            .then(res => console.log(res));
    };

    const columns = useMemo<
        EditableColumnType<OMSTrackerItemRenderType>[]
    >(() => {
        const ssColumns = makePOTrackerColumns(
            searchInputRef,
            editable ? handleSave : undefined
        );
        if (editable) {
            ssColumns.push({
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => deleteItems([record.key])}
                    />
                ),
                fixed: 'right',
                width: '60px',
            });
            return makeColumnsEditable(ssColumns, handleSave);
        } else {
            return ssColumns;
        }
    }, [handleSave, editable, deleteItems]);

    const bulkActions: BulkActionType[] = [
        {
            label: 'Generate Package Dims Report',
            icon: <FileTextOutlined />,
            onClick: () => {
                setPackageDimsModalOpen(true);
            },
        },
        {
            label: 'Mark as prelisted',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to mark ${selectedRowKeys.length} item(s) as prelisted?`,
                    onOk: () => {
                        setTableData(
                            updatePrelisted(tableData, selectedRowKeys)
                        );
                        setLastUpdateTimestamp(new Date());
                    },
                }),
            icon: <CheckOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Archive items',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to archive ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        archiveItems(selectedRowKeys);
                    },
                }),
            icon: <FolderAddOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Delete items',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to delete ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        deleteItems(selectedRowKeys);
                    },
                }),
            icon: <DeleteFilled />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'end' }}
            split={<Divider type='vertical' />}
        >
            <Space>
                Last Refresh: {tableData?.[0] ? dayjs(tableData[0].updateTimestamp).format('MM-DD-YYYY HH:mm:ss') : 'Unknown'}
            </Space>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved={tableData.length === 0}
                isLoading={omsMutation.isPending}
                title={'Save Tracker'}
                onSaveChanges={() => {
                    omsMutation.mutate(tableData);
                }}
                onDiscardChanges={() => {
                    setTableData(
                        (ssData || []).map((item, idx) => ({
                            ...item,
                            key: idx,
                        }))
                    );
                    setLastUpdateTimestamp(fetchTimestamp);
                }}
            />
            <Button
                type='primary'
                onClick={() => refetch()}
                loading={isRefetching || isLoading || isGenerating}
            >
                Update Tracker
            </Button>
            <Button
                type='primary'
                onClick={() => generateTracker()}
                loading={isRefetching || isLoading || isGenerating}
            >
                Generate Tracker
            </Button>
            <Button
                type='primary'
                onClick={() => postSummary()}
                loading={isRefetching || isLoading || isGenerating}
            >
                Post Summary
            </Button>
            <Space>
                Editable: <Switch disabled={isGenerating} checked={editable} onChange={setEditable} />
            </Space>
            <CSVDownload
                collection={`PO_Tracker_Export_${dayjs().format('MM-DD-YY')}`}
                data={tableRef.current?.currentData || []}
                dateColumns={[
                    'Date Paid',
                    'Last Shipment Date',
                    'Ship_Requested',
                ]}
                isLoading={!tableRef.current}
                parse={data => {
                    return new dataForge.DataFrame(data)
                        .transformSeries({
                            'Notes': val => val && Array.isArray(val)
                                ?
                                val.map((note: ExpandedNote | string) => typeof note === 'string'
                                    ?
                                    note :
                                    `${note.noteTimestamp}: ${note.noteText} - ${note.noteAuthor}`
                                ).join(' || ')
                                :
                                val,
                        })
                        .subset(columns.map(col => col.dataIndex as string))
                        .toArray();
                }}
            />
            <BulkActionsButton actions={bulkActions} />
        </Space>
    );

    return (
        <>

            {modalContext}
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row>
                    <Col span={24}>
                        <EditableTable<OMSTrackerItemRenderType>
                            ref={tableRef}
                            title={tableTitle}
                            tableData={tableData}
                            columns={columns}
                            loading={isLoading}
                            onRowSelectionChange={(rowKeys, availableRowKeys) =>
                                setSelectedRowKeys(
                                    rowKeys.length === 0
                                        ? availableRowKeys
                                        : rowKeys
                                )
                            }
                            pagination={{
                                pageSizeOptions: [20, 50, 100],
                                defaultPageSize: 50,
                            }}
                            scroll={{
                                x: '200vw',
                            }}
                        />
                    </Col>
                </Row>
                <GeneratePackageDimsModal
                    open={packageDimsModalOpen}
                    onClose={() => setPackageDimsModalOpen(false)}
                />
            </Space>
        </>
    );
};
