import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { generateBuysheet } from '../../../services/WholesaleService';
import { BuySheetItem } from '../../../types/OmegaTypes';

interface GenerateBuysheetModalProps {
    pos?: string[];
    open: boolean;
    onClose: () => void;
}

export const GenerateBuysheetModal: React.FC<GenerateBuysheetModalProps> = ({
    open,
    onClose,
    pos,
}: GenerateBuysheetModalProps) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (pos) {
            form.setFieldValue('pos', pos.join(','));
        }
    }, [pos, form]);

    function downloadTSV(tsv: string, fileName: string) {
        const blob = new Blob([tsv], { type: 'text/tab-separated-values' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    const generate = async () => {
        setLoading(true);
        try {
            const token = await currentUser!.getIdToken();

            const { pos } = await form.validateFields();

            const generatedData = await generateBuysheet(token, pos);
            const headers: (keyof BuySheetItem)[] = [
                'ASIN',
                'TITLE',
                'Quantity',
                'COSTUNIT',
                'Min Price',
                'Max Price/List',
                'Shipped To',
                'SeasTag',
                'Supp?',
                'PURCHASED DATE',
                'SUPPLIER',
            ];

            const rows: string[] = [];
            generatedData.forEach(row => {
                const values = headers.map(header => row[header]);
                rows.push(values.join('\t'));
            });

            const tsv = headers.join('\t') + '\n' + rows.join('\n');
            const filename =
                new Date().toISOString().slice(0, 10) + '-BuysheetExport.tsv';
            downloadTSV(tsv, filename);
        } catch (e) {
            notification.error({
                // @ts-ignore
                description: e.message,
                message: 'Error',
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            title={'Please enter the POs or SOs to create the buysheet from:'}
            centered
            open={open}
            width={420}
            onCancel={onClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
            okButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <Form layout='vertical' form={form} onFinish={generate}>
                <Form.Item
                    name='pos'
                    label='POs and SOs'
                    rules={[{ required: true }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ alignItems: 'center' }}>
                    <Button
                        title='Generate'
                        loading={loading}
                        type='primary'
                        style={{ width: '100%' }}
                        htmlType='submit'
                    >
                        Generate
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
