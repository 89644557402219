import dayjs from 'dayjs';
import {
    FreightLogRowRenderType,
    OMSItem,
    OMSItemRenderType,
    OMSMap,
} from '../../types/OmegaTypes';
import { RevFeeFromSellerSnap } from '../omega/BrandCalcs';

// function RevFeeFromSellerSnap(price: number, item: OMSItemRenderType) {
//     const asin = item['ASIN'] || '';

//     const sellerSnapData = item.SellerSnapData || {};
//     const feesStructure = sellerSnapData[asin]?.fees_structure;
//     if (feesStructure) {
//         const refFeePercentage =
//             parseFloat(feesStructure.regular_fee_perc) / 100;

//         if (feesStructure.reduced_fee_perc) {
//             const reducedFee = feesStructure.reduced_fee_perc / 100;
//             if (feesStructure.reduced_fee_type === 'full amount') {
//                 if (price > feesStructure.reduced_fee_threshold) {
//                     return Math.max(reducedFee * price, feesStructure.min_fee);
//                 }
//             } else if (
//                 feesStructure.reduced_fee_type === 'portion above threshold'
//             ) {
//                 return (
//                     Math.min(feesStructure.reduced_fee_threshold, price) *
//                         refFeePercentage +
//                     reducedFee *
//                         Math.max(price - feesStructure.reduced_fee_threshold, 0)
//                 );
//             } else {
//                 // something is broken, lemao!!!
//             }
//         }

//         return Math.max(refFeePercentage * price, feesStructure.min_fee);
//     } else {
//         return price * 0.15;
//     }
// }

function approxRefFee(totalCost: number, item: OMSItemRenderType) {
    let currTotal = totalCost;
    let refFee = RevFeeFromSellerSnap(
        currTotal,
        (item.SellerSnapData ?? {})[item.ASIN ?? ''] ?? {}
    );
    for (let i = 0; i < 7; i++) {
        refFee = RevFeeFromSellerSnap(
            currTotal + refFee,
            (item.SellerSnapData ?? {})[item.ASIN ?? ''] ?? {}
        );
    }

    return refFee;
}

/**
 * Updates PO Browser temporary columns based on other editable columns.
 * @param products
 */
export const updateMasterValues = (products: OMSItem[]) => {
    const newProducts: OMSItem[] = products.map(item => {
        const newItem = { ...item };
        if (newItem.Quantity && newItem.Shipped !== undefined) {
            newItem.Unshipped = newItem.Quantity - newItem.Shipped;
        } else if (newItem.Quantity === 0) {
            newItem.Unshipped = 0;
        } else {
            newItem.Unshipped = undefined;
        }
        return newItem;
    });

    return newProducts;
};

/**
 * Updates Stage 3 temporary columns based on other editable columns.
 * @param products
 */
export const updateStage3Values = (
    products: OMSItemRenderType[],
    keys: React.Key[],
    triggerColumn?: keyof OMSItemRenderType
) => {
    const keysSet = new Set(keys);
    const newProducts: OMSItemRenderType[] = products.map(item => {
        if (keysSet.size === 0 || keysSet.has(item.key)) {
            return stage3Transformation(item, item, triggerColumn);
        } else {
            return item;
        }
    });

    return newProducts;
};

export const parseOMSColumns = (products: any[]): OMSItem[] => {
    const numberColumns = [
        'Quantity',
        'Cost',
        'Max Price/List',
        'Min Price',
        'Ship Now',
        'Ship Later',
    ];
    const productsCopy: OMSItem[] = products.map(item => {
        const newItem = { ...item };
        for (const column of numberColumns) {
            let originalValue = newItem[column];
            if (typeof originalValue === 'string' && originalValue !== '') {
                originalValue = originalValue.replaceAll(',', '.');
            }
            const parsedValue = parseFloat(originalValue);
            if (isNaN(parsedValue)) {
                newItem[column] = undefined;
            } else {
                newItem[column] = parsedValue;
            }
        }

        return newItem;
    });
    return productsCopy;
};

export const countRowChanges = (
    row: OMSItem,
    dbData: OMSMap,
    columnsToCheck: string[],
    columnsToIgnore: string[] = []
): number => {
    let counts = 0;
    for (const key of columnsToCheck) {
        // Make sure everything is converted to string to prevent any type mismatches
        // Row is not important and can be different
        const DATE_COLUMNS = ['Ship_Requested', 'Supplier_Date'];

        if (
            key !== 'Row' &&
            row[key] != null &&
            row[key] !== '' &&
            String(row[key]) !== String(dbData[row._id || ''][key])
        ) {
            // console.log('Row', row._id, 'Column', key, 'changed', row[key], dbData[row._id || ''][key])
            if (DATE_COLUMNS.includes(key)) {
                const rowDate = dayjs(row[key] as string).format('MM/DD/YYYY')
                const dbDate = dayjs(dbData[row._id || ''][key] as string).format('MM/DD/YYYY')
                if (rowDate !== dbDate) {
                    counts++;
                }
            } else if (columnsToIgnore.includes(key)) {
                // Do nothing
            } else {
                counts++;
            }
        }
    }

    return counts;
};

export const countChanges = (
    products: OMSItem[],
    dbData: OMSMap,
    columnsToCheck: string[],
    columnsToIgnore: string[] = []
): number => {
    return products.reduce(
        (prev, curr) =>
            prev +
            countRowChanges(curr, dbData, columnsToCheck, columnsToIgnore),
        0
    );
};

export const updateShipNow = (
    data: OMSItemRenderType[],
    keysToFilter: React.Key[] = []
): OMSItemRenderType[] => {
    const keysSet = new Set(keysToFilter);

    const newData = data.map(item => {
        const newItem = { ...item };
        // If not rows were selected update every row
        if (keysSet.size === 0 || keysSet.has(item.key)) {
            newItem['Ship Now'] = newItem['Quantity'];
            newItem['Ship Later'] = 0;
        }
        return newItem;
    });
    return newData;
};

export const updateToMap = (
    data: OMSItemRenderType[],
    keysToFilter: React.Key[] = []
): OMSItemRenderType[] => {
    const keysSet = new Set(keysToFilter);

    const newData = data.map(item => {
        const newItem = { ...item };
        // If not rows were selected update every row
        if (keysSet.size === 0 || keysSet.has(item.key)) {
            if (newItem['MAP']) {
                newItem['Min Price'] = newItem['MAP'];
                newItem['Max Price/List'] = newItem['MAP'];
            }
        }
        return newItem;
    });
    return newData;
};

export const updatePrelisted = <
    T extends { key: React.Key; 'Prelisted IL?'?: string }
>(
    data: T[],
    selectedRows: React.Key[],
    value: 'YES' | 'NO' = 'YES'
): T[] => {
    const keysSet = new Set(selectedRows);
    const newData = data.map(item => {
        let itemCopy = { ...item };
        // If not rows were selected update every row
        if (keysSet.size === 0 || keysSet.has(item.key)) {
            itemCopy['Prelisted IL?'] = value;
        }
        return itemCopy;
    });

    return newData;
};

type RowTransformation<DataType = OMSItemRenderType> = (
    oldRow: DataType,
    newRow: DataType,
    column?: keyof DataType
) => DataType;

/**
 * Creates a function that executes the given list of transformations on an item.
 *
 * @param transformations list of transformations to apply to an item
 * @returns
 */
export const getRowTransformations = <DataType = OMSItemRenderType>(
    transformations: RowTransformation<DataType>[]
): RowTransformation<DataType> => {
    return (oldRow: DataType, newRow: DataType, column?: keyof DataType) => {
        if (transformations.length === 0) return newRow;

        let transformedNewRow = newRow;
        for (const transformation of transformations) {
            transformedNewRow = transformation(
                oldRow,
                transformedNewRow,
                column
            );
        }
        return transformedNewRow;
    };
};

export const updateShipLater = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    if (
        column === 'Ship Now' &&
        newRow['Quantity'] &&
        newRow['Ship Now'] !== undefined &&
        newRow['Ship Now'] !== null
    ) {
        let rowCopy = { ...newRow };
        rowCopy['Ship Later'] = Math.max(
            newRow['Quantity'] - newRow['Ship Now'],
            0
        );
        return rowCopy;
    }
    return newRow;
};

export const updateUnshipped = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    if (column === 'Quantity') {
        let rowCopy = { ...newRow };
        if (
            newRow.Quantity &&
            newRow.Shipped !== undefined &&
            newRow.Shipped !== null
        ) {
            rowCopy.Unshipped = newRow.Quantity - newRow.Shipped;
        } else {
            rowCopy.Unshipped = undefined;
        }
        return rowCopy;
    }
    return newRow;
};

export const updateShipTotal = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    const rowCopy = { ...newRow };
    if (rowCopy['Ship Later'] == null || rowCopy['Ship Now'] == null) {
        rowCopy.Ship_Total = null;
    } else {
        rowCopy.Ship_Total = rowCopy['Ship Later'] + rowCopy['Ship Now'];
    }
    return rowCopy;
};

export const updateUnitCost = (
    oldRow: FreightLogRowRenderType,
    newRow: FreightLogRowRenderType,
    column?: keyof FreightLogRowRenderType
) => {
    if (column === 'TotalCost' || column === 'ItemsNum') {
        let rowCopy = { ...newRow };
        if (
            newRow.TotalCost !== undefined &&
            newRow.TotalCost !== null &&
            newRow.ItemsNum
        ) {
            rowCopy.UnitCost = newRow.TotalCost / newRow.ItemsNum;
        } else {
            rowCopy.UnitCost = undefined;
        }
        return rowCopy;
    }
    return newRow;
};

export const updateUnitCostItemizedFreight = <
    DataType extends {
        TotalCost?: number;
        UnitsShipped?: number;
        UnitCost?: number;
    }
>(
    oldRow: DataType,
    newRow: DataType,
    column?: keyof DataType
) => {
    if (
        column === 'TotalCost' ||
        column === 'UnitsShipped' ||
        column === undefined
    ) {
        let rowCopy = { ...newRow };
        if (
            newRow.TotalCost !== undefined &&
            newRow.TotalCost !== null &&
            newRow.UnitsShipped
        ) {
            rowCopy.UnitCost = newRow.TotalCost / newRow.UnitsShipped;
        } else {
            rowCopy.UnitCost = undefined;
        }
        return rowCopy;
    }
    return newRow;
};

export const updateMinPrice = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    if (column === 'DesiredROI') {
        let rowCopy = { ...newRow };
        rowCopy['Min Price'] = rowCopy.MVSP;
        return rowCopy;
    }
    return newRow;
};

export const updateMVSP = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    const rowCopy = { ...newRow };
    rowCopy.ShipFee = 1 + rowCopy.PackageWeight * 0.5;

    if (rowCopy['Cost'] == null) {
        rowCopy.MVSP = undefined;
        rowCopy.RoundUp = undefined;
    } else {
        const totalCost: number =
            rowCopy.Cost * (1 + rowCopy.DesiredROI / 100) +
            rowCopy.ShipFee +
            rowCopy.FulfillmentFee;
        rowCopy.ReferralFee = approxRefFee(totalCost, rowCopy);
        rowCopy.MVSP = totalCost + rowCopy.ReferralFee;
        rowCopy.MVSP = Math.round((rowCopy.MVSP || 0) * 100) / 100;
        rowCopy.RoundUp = Math.ceil(rowCopy.MVSP) - 0.05;
    }
    return rowCopy;
};

export const updateProfit = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    const rowCopy = { ...newRow };
    rowCopy.ShipFee = 1 + rowCopy.PackageWeight * 0.5;

    if (rowCopy['Min Price'] == null || rowCopy['Cost'] == null) {
        rowCopy.Profit = undefined;
        rowCopy.ROI = undefined;
    } else {
        rowCopy.Profit =
            (rowCopy['Min Price'] || 0) -
            rowCopy['Cost'] -
            rowCopy.ShipFee -
            rowCopy.ReferralFee -
            rowCopy.FulfillmentFee;
        rowCopy.ROI = (rowCopy.Profit / rowCopy.Cost) * 100;
    }

    // console.log('Using Min Price at', rowCopy['Min Price'], 'with profit', rowCopy.Profit, 'and ROI', rowCopy.ROI, 'for', rowCopy['ASIN']);
    return rowCopy;
};

export const updateParentAsin = (
    oldRow: OMSItemRenderType,
    newRow: OMSItemRenderType,
    column?: keyof OMSItemRenderType
) => {
    if (newRow['ParentASIN'] == null) {
        let rowCopy = { ...newRow };
        if (rowCopy.KeepaData?.parentAsin) {
            rowCopy['ParentASIN'] = rowCopy.KeepaData.parentAsin;
        }
        return rowCopy;
    }
    return newRow;
};

export const importStageTransformation = getRowTransformations([
    updateShipLater,
]);

export const stage3Transformation = getRowTransformations([
    updateShipLater,
    updateShipTotal,
    updateParentAsin,
    updateMVSP,
    updateMinPrice,
    updateProfit,
]);

export const masterStageTransformation = getRowTransformations([
    updateShipLater,
    updateUnshipped,
]);

export const freightLogTransformation = getRowTransformations([updateUnitCost]);
export const freightLogItemizedEntryTransformation = getRowTransformations([
    updateUnitCostItemizedFreight,
]);

export const freightLogItemizedTransformation = getRowTransformations([
    updateUnitCostItemizedFreight,
]);
