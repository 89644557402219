import {
    Contact,
    PartneredLtlDataInput,
    TransportDetailInput,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {Button, Collapse, DatePicker, Descriptions, Form, Input, Modal, Select, notification} from "antd";
import {Rule} from "antd/es/form";
import {FormProps} from "antd/lib";
import {DescriptionsItemType} from "antd/lib/descriptions";
import React, {useEffect, useState} from "react";
import {FREIGHT_CLASSES} from "../../../types/WarehouseTypes";
import dayjs from "dayjs"

interface Props {
    open: boolean;
    transportDetails?: TransportDetailInput;
    onConfirm: (transportDetails: TransportDetailInput) => void;
    onCancel: () => void;
}

const COMMON_RULES: Rule[] = [{required: true, message: "Pleast enter the value"}];

const ConfirmTransportDetailsDialog: React.FC<Props> = ({open, transportDetails, onCancel, onConfirm}) => {
    const [transportDetailsLocal, setTransportDetailLocal] = useState<PartneredLtlDataInput>({});
    const [form] = Form.useForm();

    const isWrongData = !transportDetails?.PartneredLtlData;

    useEffect(() => {
        if (transportDetails?.PartneredLtlData) {
            setTransportDetailLocal(transportDetails.PartneredLtlData);
            form.setFieldsValue(transportDetails.PartneredLtlData.Contact);
        }
    }, [transportDetails, form]);

    const contact = transportDetailsLocal.Contact;

    const onContactFinish: FormProps<Contact>["onFinish"] = async (values) => {
        setTransportDetailLocal({...transportDetailsLocal, Contact: values});
    };

    const items: DescriptionsItemType[] = [
        {
            label: "Contact",
            children: (
                <Collapse
                    size="small"
                    style={{width: "100%"}}
                    items={[
                        {
                            key: "contact",
                            label: contact ? `${contact?.Name} (${contact?.Phone}, ${contact?.Email})` : "NOT SET",
                            children: (
                                <Form form={form} onFinish={onContactFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>
                                    <Form.Item label="Name" name="Name" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Phone" name="Phone" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Email" name="Email" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Fax" name="Fax">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 4, span: 16}}>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ),
                        },
                    ]}
                />
            ),
        },
        {
            key: "boxesCount",
            label: "Boxes Count",
            children: transportDetailsLocal.BoxCount,
        },
        {
            key: "freightReadyDate",
            label: "Freight Ready Date",
            children: (
                <DatePicker
                    value={dayjs(transportDetailsLocal.FreightReadyDate)}
                    format={"YYYY-MM-DD"}
                    onChange={(date) => {
                        setTransportDetailLocal({...transportDetailsLocal, FreightReadyDate: date.toISOString().split("T")[0]});
                    }}
                />
            ),
        },
        {
            key: "freightClass",
            label: "Freight Class",
            children: (
                <Select
                    style={{width: "30%"}}
                    options={FREIGHT_CLASSES.map((cls) => ({label: cls, value: cls}))}
                    value={transportDetailsLocal.SellerFreightClass}
                    onChange={(value) => {
                        setTransportDetailLocal({...transportDetailsLocal, SellerFreightClass: value});
                    }}
                />
            ),
        },
        {
            key: "totalWeight",
            label: "Shipment Weight",
            children: transportDetailsLocal.TotalWeight?.Value,
        },
        {
            key: "value",
            label: "Declared Value",
            children: `$${transportDetailsLocal.SellerDeclaredValue?.Value?.toFixed(2)}`,
        },
    ];

    return (
        <Modal
            title="Transport details"
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={onCancel}>Close</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (transportDetailsLocal.Contact) {
                                onConfirm({
                                    PartneredLtlData: transportDetailsLocal,
                                });
                            } else {
                                notification.error({
                                    message: "You must add contact information",
                                });
                            }
                        }}
                    >
                        Confirm
                    </Button>
                </>
            }
            width={"50vw"}
        >
            {isWrongData ? null : <Descriptions labelStyle={{width: "20%"}} bordered column={1} items={items} />}
        </Modal>
    );
};

export default ConfirmTransportDetailsDialog;
