import { DeleteFilled, NumberOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
    Button,
    Divider,
    InputRef,
    Modal,
    notification,
    Space,
    Switch,
} from 'antd';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import {
    getSellerSnapExport,
    pushSellerSnapUpdate,
} from '../../../services/WholesaleService';
import {
    SellerSnapExportData,
    SellerSnapExportDataRenderType,
    SellerSnapUpdateItem,
} from '../../../types/OmegaTypes';
import {
    makeColumnsEditable,
    makeSellerSnapExportColumns,
} from '../../utilities/OMSColumns';
import { validateSellerSnapExport } from '../../utilities/TableDataValidation';
import { BulkActionsButton, BulkActionType } from '../table/BulkActions';
import { EditableColumnType } from '../table/EditableCell';
import EditableTable from '../table/EditableTable';
import { RED } from '../../utilities/TableFilterSorters';
import { CSVDownload } from '../../utilities/CSVDownload';
import dayjs from 'dayjs';
import * as dataForge from 'data-forge';

export const SellerSnapExportBrowser: React.FC = () => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<
        SellerSnapExportDataRenderType[]
    >([]);
    const [editable, setEditable] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [modal, modalContext] = Modal.useModal();

    const {
        data: ssData,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ['ss_export'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const data = (await getSellerSnapExport(
                token
            )) as SellerSnapExportData[];
            return data;
        },
        enabled: false,
        staleTime: Infinity,
        initialData: [],
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: 'Error',
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    useEffect(() => {
        setTableData(ssData.map((item, idx) => ({ ...item, key: idx })));
    }, [ssData]);

    const handleSave = useCallback(
        (row: SellerSnapExportDataRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex(item => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setTableData(newData);
        },
        [tableData]
    );

    const deleteItems = useCallback(
        (keysToDelete: React.Key[]) => {
            const keysSet = new Set(keysToDelete);
            const dataCopy = [...tableData];
            const newData = dataCopy.filter(item => !keysSet.has(item.key));

            setTableData(
                newData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        },
        [tableData]
    );

    const updateToMap = useCallback(
        (keysToDelete: React.Key[]) => {
            const keysSet = new Set(keysToDelete);
            const dataCopy = [...tableData];

            setTableData(
                dataCopy.map((item, idx) => {
                    if (keysSet.has(item.key) && item.MAP) {
                        return {
                            ...item,
                            min_price: item.MAP,
                            max_price: item.MAP,
                        };
                    } else {
                        return item;
                    }
                })
            );
        },
        [tableData]
    );

    const columns = useMemo<
        EditableColumnType<SellerSnapExportDataRenderType>[]
    >(() => {
        const ssColumns = makeSellerSnapExportColumns(searchInputRef);
        if (editable) {
            ssColumns.push({
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => deleteItems([record.key])}
                    />
                ),
                fixed: 'right',
                width: '60px',
            });
            return makeColumnsEditable(ssColumns, handleSave);
        } else {
            return ssColumns;
        }
    }, [handleSave, editable, deleteItems]);

    const pushSSData = async (data: SellerSnapExportDataRenderType[]) => {
        setIsUploading(true);
        try {
            const uploadData: SellerSnapUpdateItem[] = data.map(
                ({
                    sku,
                    cost,
                    min_price,
                    max_price,
                    tags,
                    reprice_config_preset,
                    repricing,
                    MAP,
                }) => ({
                    sku,
                    cost,
                    min_price,
                    max_price,
                    tags,
                    reprice_config_preset,
                    repricing,
                    map: MAP ?? '',
                })
            );

            const pushResult = await pushSellerSnapUpdate(uploadData);
            const updated =
                pushResult.reduce(
                    (acc: any, curr: any) => acc + curr?.data?.updated,
                    0
                ) || 0;
            notification.info({
                message: 'Update completed',
                description: `${updated} out of ${uploadData.length} items updated`,
            });
        } catch (e) {
            notification.error({
                message: 'Error',
                description: (e as Error).message,
            });
        }
        setIsUploading(false);
    };

    const bulkActions: BulkActionType[] = [
        {
            label: 'Delete items',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to delete ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        deleteItems(selectedRowKeys);
                    },
                }),
            icon: <DeleteFilled />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Set Min/Max to MAP',
            onClick: () => {
                modal.confirm({
                    title: `Are you sure you want to update ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        updateToMap(selectedRowKeys);
                    },
                });
            },
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'end' }}
            split={<Divider type='vertical' />}
        >
            <Button
                type='primary'
                onClick={() => refetch()}
                loading={isRefetching}
            >
                Generate Export
            </Button>
            <Button
                type='primary'
                loading={isUploading}
                disabled={tableData.length === 0}
                onClick={() => {
                    if (validateSellerSnapExport(tableData).length === 0) {
                        pushSSData(tableData);
                    }
                }}
            >
                Push Export
            </Button>
            <CSVDownload
                collection={`SellerSnap_Export_${dayjs().format('MM-DD-YY')}`}
                data={tableData || []}
                isLoading={!(tableData?.length > 0)}
                dateColumns={['PurchaseDate']}
                parse={data => {
                    return new dataForge.DataFrame(data)
                        .subset(columns.map(col => col.dataIndex as string))
                        .toArray();
                }}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <BulkActionsButton actions={bulkActions} />
        </Space>
    );

    return (
        <>
            {modalContext}
            <Space direction='vertical' style={{ width: '100%' }}>
                <EditableTable<SellerSnapExportDataRenderType>
                    title={tableTitle}
                    tableData={tableData}
                    columns={columns}
                    loading={isRefetching}
                    onRowSelectionChange={(rowKeys, availableRowKeys) =>
                        setSelectedRowKeys(
                            rowKeys.length === 0 ? availableRowKeys : rowKeys
                        )
                    }
                    onRow={row => {
                        let style = {};
                        if (
                            row.min_price != null &&
                            row.max_price != null &&
                            row.min_price > row.max_price
                        ) {
                            style = {
                                backgroundColor: RED,
                            };
                        }
                        return {
                            style,
                        };
                    }}
                />
            </Space>
        </>
    );
};
