import {MinMaxFilterDropdown} from "./MinMaxFilterDropdown";
import React from "react";
import {Button, DatePicker, Popover, Space, Typography, notification} from "antd";
import SellPricePopover from "../wholesale/table/SellPricePopover";
import {SearchOutlined, WarningFilled} from "@ant-design/icons";
import FilterDropdown from "./FilterDropdown";
import {isValidUrl} from "./common";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const RED = "#711010";
export const ORANGE = "#9e6d0e";

export function addCommasToNumber(number, precision = 2) {
    const [integerPart, decimalPart] = number.toFixed(precision).split(".");
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart !== undefined ? formattedIntegerPart + "." + decimalPart : formattedIntegerPart;
}

export function search(dataIndex, value, record) {
    if (!record[dataIndex]) return false;

    return record[dataIndex].toString().toLowerCase().includes(value?.toString()?.toLowerCase());
}

export function getSearch(dataIndex) {
    return (value, record) => search(dataIndex, value, record);
}

export function getDateSearch(dataIndex) {
    return (value, record) => {
        let date = dayjs(record[dataIndex]).format("MM/DD/YYYY");
        return date.includes(value.toString());
    };
}

export const getDefaultFilterSorter = (key, searchInputRef) => {
    return {
        sorter: {
            compare: (a, b) => {
                // check if theyre numbers, if so, compare them as numbers
                if (!isNaN(a[key]) && !isNaN(b[key])) {
                    return a[key] - b[key];
                } else {
                    const localA = a[key] ? a[key] : "";
                    const localB = b[key] ? b[key] : "";
                    console.log(key, a, b, localA, localB);
                    return localA.localeCompare(localB);
                }
            },
            multiple: 1,
        },
        filterDropdown: (props) => <FilterDropdown {...props} inputRef={searchInputRef} />,
        onFilter: getSearch(key),
        onFilterDropdownOpenChange: (visible) => visible && setTimeout(() => searchInputRef.current?.select()),
    };
};

export const getDefaultDateSorter = (key, searchInputRef) => {
    return {
        sorter: {
            compare: (a, b) => {
                const localA = a[key] ? a[key] : -1;
                const localB = b[key] ? b[key] : -1;
                return new Date(localA) - new Date(localB);
            },
            multiple: 1,
        },
        filterDropdown: (props) => <FilterDropdown {...props} inputRef={searchInputRef} />,
        onFilter: getDateSearch(key),
        onFilterDropdownOpenChange: (visible) => visible && setTimeout(() => searchInputRef.current?.select()),
    };
};

export const getNumberFilterSorter = (key, multiple = 1) => {
    return {
        sorter: {
            compare: (a, b) => {
                const localA = a[key] ? a[key] : -1;
                const localB = b[key] ? b[key] : -1;
                return localA - localB;
            },
            multiple: multiple,
        },
        filterDropdown: (props) => <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={0} />,
        onFilter: (value, record) => {
            const [min, max] = value.split(" ").map((val) => parseFloat(val));
            return (record[key] >= min && (max === 0 || record[key] <= max)) || record.recentlyEdited;
        },
        // filterResetToDefaultFilteredValue: true,
    };
};

export const getDateFilter = (dataIndex) => {
    return {
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <div style={{padding: 8, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "8px"}}>
                <Space>
                    <DatePicker.RangePicker
                        format={"MM-DD-YY"}
                        onChange={(e) => {
                            console.log(e);
                            if (e) {
                                setSelectedKeys([[e[0], e[1]]]);
                            } else {
                                setSelectedKeys([]);
                            }
                        }}
                        allowClear={true}
                    />
                </Space>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm({closeDropdown: true});
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90, marginLeft: 8}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedKeys([]);
                            confirm({closeDropdown: true});
                        }}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />,
        onFilter: (value, record) => {
            const [start, end] = value;

            if (!start && !end) {
                return true;
            }

            return (
                dayjs(record[dataIndex]).isSameOrAfter(dayjs(start), "day") && dayjs(record[dataIndex]).isSameOrBefore(dayjs(end), "day")
            );
        },
    };
};

export const getBooleanFilter = (key) => {
    return {
        filters: [
            {
                text: "True",
                value: true,
            },
            {
                text: "False",
                value: false,
            },
        ],
        filterMultiple: false,
        // filterResetToDefaultFilteredValue: true,
        onFilter: (value, record) => !!record[key] === value || record.recentlyEdited,
    };
};

export const getDateRenderer = () => {
    return {
        render: (val) => {
            return val ? dayjs(val).format("MM/DD/YYYY") : "";
        },
    };
};

export const getPriceRenderer = (placeholder = "?") => {
    return {
        render: (val) => {
            return val === undefined || val === "" || val === -1 || val === null ? placeholder : `$${addCommasToNumber(val)}`;
        },
    };
};

export const getSellPriceRenderer = () => {
    return {
        render: (val, record) => {
            const prices = [record.currentBuyBoxPrice, record.buyBox30Avg, record.buyBox90Avg];
            const minBB = Math.min(...prices);
            const maxBB = Math.max(...prices);

            return (
                <Popover
                    id="wholesale-popover"
                    content={<SellPricePopover record={record}></SellPricePopover>}
                    trigger="hover"
                    title={
                        maxBB / minBB >= 1.3 ? (
                            <>
                                <WarningFilled style={{color: "red"}} /> High variance detected <WarningFilled style={{color: "red"}} />
                            </>
                        ) : null
                    }
                >
                    <div>
                        <Typography>
                            {val === undefined || val === -1 || val === null ? "Suppressed" : `$${val.toFixed(2)}`}{" "}
                            {(record.currentBuyBoxPrice === -1 ||
                                maxBB / minBB >= 1.3 ||
                                record.buyBox30Avg === undefined ||
                                record.buyBox90Avg === undefined) &&
                            val !== -1 ? (
                                <WarningFilled style={{color: "red"}} />
                            ) : null}
                        </Typography>
                    </div>
                </Popover>
            );
        },
    };
};

export const getShareRenderer = (precision = 0, placeholder = "Unknown") => {
    return {
        render: (val) => (val === undefined || val === -1 || val === null ? placeholder : `${val.toFixed(precision)}%`),
    };
};

export const getNumberRenderer = (precision, placeholder = "?") => {
    return {
        render: (val) =>
            val === undefined || val === null || val === -1 ? placeholder : `${precision !== undefined ? val.toFixed(precision) : val}`,
    };
};

export const getNumberRendererRepricerVariant = (float = false, placeholder = "?") => {
    return {
        render: (val) => (val === undefined || val === null || val === -1 ? placeholder : `${float ? val.toFixed(2) : val}`),
    };
};

export const getColumnTitle = (column) => {
    if (typeof column.title === "string") {
        return column.title;
    } else {
        return column.key;
    }
};

export const getASINRenderer = () => {
    return {
        render: (val) => (
            <a href={`https://www.amazon.com/dp/${val}?th=1&psc=1`} target="_blank" rel="noreferrer">
                {val}
            </a>
        ),
    };
};

export const getBoolRenderer = () => {
    return {
        render: (val) => {
            if (val === true) return "YES";
            if (val === false) return "NO";
            return "";
        },
    };
};

export const getModifiedCellRenderer = (column, dbData, identifier = "_id", dateTransformer) => {
    return {
        onCell: (data) => {
            let style = {};
            const id = data[identifier];

            let currValue = data[column] == null ? "" : data[column];
            let oldValue = dbData[id]?.[column] == null ? "" : dbData[id]?.[column];

            if (dateTransformer) {
                oldValue = dateTransformer(oldValue);
                currValue = dateTransformer(currValue);
            }

            if (dbData[id] && String(currValue) !== String(oldValue)) {
                style = {
                    backgroundColor: "#c5600099",
                };
            }
            return {style};
        },
    };
};

export const getMinPriceCellRenderer = () => {
    return {
        onCell: (data) => {
            let style = {};
            if (data.ROI && data.DesiredROI && data.ROI < data.DesiredROI) {
                style = {
                    backgroundColor: RED,
                };
            } else if (data["Min Price"] && data.MAP && data["Min Price"] !== data.MAP) {
                style = {
                    backgroundColor: ORANGE,
                };
            }
            return {style};
        },
    };
};

export const getSellPriceCellRenderer = () => {
    return {
        onCell: (data) => {
            let style = {};
            if (data["Sell Price"] && data["Min Price"] && data["Sell Price"] < data["Min Price"]) {
                style = {
                    backgroundColor: RED,
                };
            }
            return {style};
        },
    };
};

export const getMaxPriceCellRenderer = () => {
    return {
        onCell: (data) => {
            let style = {};
            let basePrice = data.KeepaData?.stats?.avg30?.[4] || -1;
            if (basePrice === -1) {
                basePrice = data.KeepaData?.stats?.avg30?.[18] || -1;
            }
            if (basePrice === -1) {
                basePrice = data.KeepaData?.stats?.avg30?.[1] || -1;
            }
            if (
                (data["Max Price/List"] && data["Min Price"] && data["Max Price/List"] < data["Min Price"]) ||
                (data["Max Price/List"] && basePrice !== -1 && data["Max Price/List"] > (1.5 * basePrice) / 100)
            ) {
                style = {
                    backgroundColor: RED,
                };
            } else if (data.MAP && data["Max Price/List"] && data["Max Price/List"] !== data.MAP) {
                style = {
                    backgroundColor: ORANGE,
                };
            }
            return {style};
        },
    };
};

export const getSalesRankCellRenderer = (maxSalesRank) => {
    return {
        onCell: (data) => {
            if (maxSalesRank === 0) {
                return {};
            }

            let salesRank = data.AMZ_SalesRank || 0;
            let alpha = salesRank / maxSalesRank;
            let style = {
                backgroundColor: `rgba(255, 0, 0, ${alpha})`,
            };
            return {style};
        },
    };
};

export const getCopyCellRenderer = (column) => {
    return {
        onCell: (data) => {
            return {
                onContextMenu: (event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(String(data[column]));
                    notification.success({
                        message: "Copied!",
                    });
                },
            };
        },
    };
};

export const getURLRenderer = (multiple = false) => {
    return {
        render: (val) =>
            multiple ? (
                val?.split(",").map((url, idx) => {
                    return isValidUrl(url) ? (
                        <a
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(event) => event.stopPropagation()}
                            style={{
                                wordWrap: "break-word",
                                width: "135px",
                                overflowWrap: "break-word",
                                display: "block",
                                wordBreak: "break-word",
                            }}
                        >
                            [{idx + 1}] Link to an Invoice
                        </a>
                    ) : (
                        url
                    );
                })
            ) : isValidUrl(val) ? (
                <a
                    href={val}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(event) => event.stopPropagation()}
                    style={{
                        wordWrap: "break-word",
                        width: "125px",
                        overflowWrap: "break-word",
                        display: "block",
                        wordBreak: "break-word",
                    }}
                >
                    {val}
                </a>
            ) : (
                val
            ),
    };
};

export const getPOTrackerNotesCellRenderer = (column) => {
    return {
        onCell: (data) => {
            let style = {};
            if (data[column] && data[column].length > 0) {
                style = {
                    backgroundColor: "#a8071ab0",
                };
            }
            return {style};
        },
    };
};

export const getArrayRenderer = () => {
    return {
        render: (val) => val?.join(", "),
    };
};

export const highlightOnCondition = (condition) => {
    return {
        onCell: (data) => {
            let style = {};
            if (condition(data)) {
                style = {
                    backgroundColor: RED,
                };
            }
            return {style};
        },
    };
};
