import { Col, Row, Statistic } from "antd";
import React from "react";

const BBSharePopover: React.FC<{
    bbShare7D: number
    bbShare14D: number
    bbShare30D: number
}> = ({ 
    bbShare7D,
    bbShare14D,
    bbShare30D 
}) => {
        return (
            <div style={{ width: '400px' }}>
                <Row justify="center" style={{ width: '100%', height: '50%', textAlign: 'center' }}>
                    <Col span={8} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                        <Statistic title="7 Days" value={bbShare7D} precision={2} suffix={'%'} />
                    </Col>
                    <Col span={8} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                        <Statistic title="14 Days" value={bbShare14D} precision={2} suffix={'%'} />
                    </Col>
                    <Col span={8} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                        <Statistic title="30 Days" value={bbShare30D} precision={2} suffix={'%'} />
                    </Col>
                </Row>
            </div>
        )
    }

export default BBSharePopover