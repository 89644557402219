import {GetTransportDetailsResult} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {
    InboundBox,
    InboundPallet,
    InboundShipment,
    InboundShipmentFeedResult,
    InboundShipmentItem,
    TransportDetails,
} from "../../../types/WarehouseTypes";

export function randID() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2, 5);
}

export function verifyShipment(
    shipment: InboundShipment,
    boxes: InboundBox[],
    pallets: InboundPallet[]
): {
    shipment: InboundShipment;
    pallets: InboundPallet[];
    boxes: InboundBox[];
} {
    const localPallets = pallets.filter((p) => p.shipmentId === shipment.ShipmentId).sort((a, b) => a.number - b.number);
    const localBoxes = boxes.filter((b) => b.shipmentId === shipment.ShipmentId).sort((a, b) => a.number - b.number);

    if (localPallets.length === 0) {
        const newPallet: InboundPallet = {
            id: randID(),
            shipmentId: shipment.ShipmentId,
            number: 1,
            boxesIds: [],
            dimensions: {
                length: 48,
                width: 40,
                height: 48,
                weight: 40,
            },
        };
        localPallets.push(newPallet);
    }

    if (localBoxes.length === 0) {
        const newBox: InboundBox = {
            id: randID(),
            shipmentId: shipment.ShipmentId,
            number: 1,
            palletId: localPallets[0].id,
            items: [],
        };
        localBoxes.push(newBox);
        localPallets[0].boxesIds.push(newBox.id);
        localPallets[0].activeBox = newBox.id;
    }

    // Make sure that each pallet and each box has a correct numbering
    localPallets.forEach((p, index) => {
        p.number = index + 1;
        p.boxesIds.forEach((boxId, index) => {
            const box = localBoxes.find((b) => b.id === boxId);
            if (box) {
                box.number = index + 1;
            }
        });
    });

    shipment.palletsIds = localPallets.map((p) => p.id);
    if (!shipment.shipmentType) {
        shipment.shipmentType = localPallets.length > 1 ? "LTL" : "SP";
    }

    return {
        shipment,
        pallets: localPallets,
        boxes: localBoxes,
    };
}

export function countItems(boxes: InboundBox[], sku?: string): number {
    let count = 0;
    boxes.forEach((box) => {
        box.items.forEach((item) => {
            if (!sku || item.SellerSKU === sku) {
                count += item.QuantityInBox || 0;
            }
        });
    });

    return count;
}

export function countItemsByKey(boxes: InboundBox[], by: keyof InboundShipmentItem = "SellerSKU"): {[key: string]: number} {
    const count: {[key: string]: number} = {};
    boxes.forEach((box) => {
        box.items.forEach((item) => {
            if (count[item[by]]) {
                count[item[by]] += item.QuantityInBox || 0;
            } else {
                count[item[by]] = item.QuantityInBox || 0;
            }
        });
    });

    return count;
}

export function extractSubmitContentsReport(response: any): InboundShipmentFeedResult {
    const data = typeof response === "string" ? JSON.parse(response) : response;
    const report = data.AmazonEnvelope.Message.ProcessingReport;

    const finalMessages: {resultCode: string; resultDescription: string}[] = [];
    if (report.Result) {
        const messages = Array.isArray(report.Result) ? report.Result : [report.Result];
        messages.forEach((msg: any) => {
            finalMessages.push({
                resultCode: msg.ResultCode,
                resultDescription: msg.ResultDescription,
            });
        });
    }

    return {
        statusCode: report.StatusCode,
        messages: finalMessages,
    };
}

export function extractTransportDetails(response: GetTransportDetailsResult): TransportDetails {
    const transportDetails = response.TransportContent;
    const status = transportDetails?.TransportResult?.TransportStatus || "ERROR";
    const partneredSmallParcelData = transportDetails?.TransportDetails?.PartneredSmallParcelData;
    const partneredLtlData = transportDetails?.TransportDetails?.PartneredLtlData;
    const estimate = partneredSmallParcelData?.PartneredEstimate || partneredLtlData?.PartneredEstimate;
    const error = transportDetails?.TransportResult?.ErrorDescription;

    return {
        status,
        estimate,
        partneredLtlData,
        partneredSmallParcelData,
        error,
    };
}
