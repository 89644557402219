const median = (numbers) => {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
};

const PRICE_INDICES = [18];
const mapSalesRankHistory = (salesRankCSV, index = 3) => {
    const salesRanks = {
        2020: Array(12)
            .fill(0)
            .map((el) => []),
        2021: Array(12)
            .fill(0)
            .map((el) => []),
        2022: Array(12)
            .fill(0)
            .map((el) => []),
        2023: Array(12)
            .fill(0)
            .map((el) => []),
        2024: Array(12)
            .fill(0)
            .map((el) => []),
    };

    for (let i = 0; i < salesRankCSV.length; i += 2) {
        const date = getTimeFromKeepa(salesRankCSV[i]);
        const year = date.getFullYear();
        const month = date.getMonth();
        if (year >= 2020 && year <= 2024 && salesRankCSV[i + 1] !== -1) {
            salesRanks[year][month].push(salesRankCSV[i + 1]);
        }
    }

    const result = {
        2020: Array(12),
        2021: Array(12),
        2022: Array(12),
        2023: Array(12),
        2024: Array(12),
    };

    Object.entries(salesRanks).forEach(([year, months]) => {
        months.forEach((month, idx) => {
            result[year][idx] =
                month.length > 0
                    ? Math.floor(month.reduce((a, b) => a + b) / month.length) / (PRICE_INDICES.includes(index) ? 100 : 1)
                    : -1;
        });
    });

    return result;
};

const analyzeSalesRankHistory = (salesRankHistory) => {
    const parsed = Object.entries(salesRankHistory)
        .map(([year, values]) => values.map((val, idx) => ({year, month: idx, val})))
        .flat();

    // group by month
    const grouped = parsed.reduce(
        (acc, curr) => {
            acc[curr.month].push(curr);
            return acc;
        },
        Array(12)
            .fill(0)
            .map((el) => [])
    );

    // get the median for each month
    // const medians = grouped.map(group => median(group.map(el => el.val)))

    // get the average for each months, ignoring -1
    const averages = grouped.map((group) =>
        group.filter((el) => el.val !== -1).reduce((acc, curr, _, {length}) => acc + curr.val / length, 0)
    );

    // get the average for each quarter of the year, ignoring -1
    const quarterAverages = Array(4)
        .fill(0)
        .map((_, idx) => {
            const quarter = averages.slice(idx * 3, idx * 3 + 3).filter((el) => el !== -1);
            return quarter.length > 0 ? quarter.reduce((acc, curr, _, {length}) => acc + curr / length, 0) : -1;
        });

    // find min and max by month
    const minMax = grouped.map((group) => {
        const filtered = group.filter((el) => el.val !== -1);
        return filtered.length > 0 ? [Math.min(...filtered.map((el) => el.val)), Math.max(...filtered.map((el) => el.val))] : [-1, -1];
    });

    // find the best and worst month
    const bestMonth = averages.indexOf(Math.min(...averages));
    const worstMonth = averages.indexOf(Math.max(...averages));

    // find the best and worst quarter
    const bestQuarter = quarterAverages.indexOf(Math.min(...quarterAverages));
    const worstQuarter = quarterAverages.indexOf(Math.max(...quarterAverages));

    // how many months deviate more than 35% from the best month
    // const deviatingMonthCount = averages.filter(el => el !== -1).filter(el => el > averages[bestMonth] * 1.35).length;

    // how many months deviate more than 35% from either side of the median month
    const deviatingMonthCount = averages
        .filter((el) => el !== -1)
        .filter((el) => el > median(averages) * 1.35 || el < median(averages) * 0.65).length;

    // map each month to how much it deviates from the best month
    const monthlyDeviations = averages.map((el, idx) => (el !== -1 ? el - averages[bestMonth] : -1));

    // find lowest, highest and median value, regardless of month
    const lowestSalesRank = Math.min(...parsed.map((el) => el.val).filter((el) => el !== -1));
    const highestSalesRank = Math.max(...parsed.map((el) => el.val).filter((el) => el !== -1));
    const medianSalesRank = median(parsed.map((el) => el.val).filter((el) => el !== -1));

    return {
        averages,
        quarterAverages,
        minMax,
        lowestSalesRank,
        highestSalesRank,
        medianSalesRank,
        bestMonth,
        worstMonth,
        bestQuarter,
        worstQuarter,
        deviatingMonthCount,
        monthlyDeviations,
    };
};

const mapBuyBoxHistory = (buyBoxSellerIdHistory) => {
    const history = (buyBoxSellerIdHistory ?? [])
        .map((val, idx) => {
            if (idx % 2 === 0) {
                return getTimeFromKeepa(parseInt(val));
            } else {
                return val;
            }
        })
        .reduce((acc, curr, idx) => {
            if (idx % 2 === 0) {
                acc.push({
                    Timestamp: curr,
                    Seller: "",
                });
            } else {
                acc[acc.length - 1].Seller = curr;
            }

            return acc;
        }, []);

    // duplicate the last item in the buybox price history to make sure we have a value for today
    if (history.length > 0) {
        history.push({
            Timestamp: new Date(),
            Seller: history[history.length - 1].Seller,
        });
    }

    // fill the array in such a way that each 1 hour period between timestamps is represented
    const filledHistory = [];
    for (let i = 0; i < history.length - 1; i++) {
        const curr = history[i];
        const next = history[i + 1];
        const diff = next.Timestamp - curr.Timestamp;
        const diffHours = diff / (1000 * 60 * 60);
        if (diffHours > 1) {
            for (let j = 0; j < diffHours; j++) {
                filledHistory.push({
                    Timestamp: new Date(curr.Timestamp.getTime() + j * 1000 * 60 * 60),
                    Seller: curr.Seller,
                });
            }
        } else {
            filledHistory.push(curr);
        }
    }

    return filledHistory;
};

const analyzeBuyBoxHistory = (buyBoxHistory) => {
    // const defaultObj = {
    //     'Timestamp': new Date(),
    //     'Seller': 'testSeller',
    // }

    // group owners by month in the past year
    const grouped = buyBoxHistory
        .filter((el) => isAfter(el.Timestamp, new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)))
        .reduce(
            (acc, curr) => {
                const month = curr.Timestamp.getMonth();
                acc[month].push(curr);
                return acc;
            },
            Array(12)
                .fill(0)
                .map((el) => [])
        );

    // get the count of unique owners in each month
    const uniqueOwners = grouped.map(
        (group) => group.filter((el, idx, arr) => arr.findIndex((el2) => el2.Seller === el.Seller) === idx).length
    );

    // get the average count of unique owners
    const averageUniqueOwners = uniqueOwners.reduce((acc, curr, _, {length}) => acc + curr / length, 0);
    const medianUniqueOwners = median(uniqueOwners);

    // find how often ATVPDKIKX0DER is the owner
    const amazonCount = buyBoxHistory.filter((el) => el.Seller === "ATVPDKIKX0DER").length;

    // calculate how long each seller was in the buybox by substracting the timestamp of the next seller from the current seller
    const timeInBuyBox = buyBoxHistory.map((el, idx, arr) => {
        if (idx === arr.length - 1) {
            return {
                Timestamp: el.Timestamp,
                Seller: el.Seller,
                TimeInBuyBox: 0,
            };
        } else {
            return {
                Timestamp: el.Timestamp,
                Seller: el.Seller,
                TimeInBuyBox: arr[idx + 1].Timestamp - el.Timestamp,
            };
        }
    });

    // find the average time in buybox for each seller in the past year
    const averageTimeInBuyBox = timeInBuyBox
        .filter((el) => isAfter(el.Timestamp, new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)))
        .reduce((acc, curr) => {
            if (!acc[curr.Seller]) {
                acc[curr.Seller] = [curr.TimeInBuyBox];
            } else {
                acc[curr.Seller].push(curr.TimeInBuyBox);
            }

            return acc;
        }, {});

    Object.keys(averageTimeInBuyBox).forEach((key) => {
        averageTimeInBuyBox[key] =
            averageTimeInBuyBox[key].reduce((acc, curr) => acc + curr, 0) /
            timeInBuyBox
                .filter((el) => isAfter(el.Timestamp, new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)))
                .reduce((acc, curr) => acc + curr.TimeInBuyBox, 0);
    });

    // find the average time in buybox for each seller in the past 30 days, expressed as a percentage of the total time
    const timeInBuyBoxPast30Days = timeInBuyBox.filter((el) => isAfter(el.Timestamp, new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)));
    const averageTimeInBuyBoxPast30Days = timeInBuyBoxPast30Days.reduce((acc, curr) => {
        if (!acc[curr.Seller]) {
            acc[curr.Seller] = [curr.TimeInBuyBox];
        } else {
            acc[curr.Seller].push(curr.TimeInBuyBox);
        }

        return acc;
    }, {});

    Object.keys(averageTimeInBuyBoxPast30Days).forEach((key) => {
        averageTimeInBuyBoxPast30Days[key] =
            averageTimeInBuyBoxPast30Days[key].reduce((acc, curr) => acc + curr, 0) /
            timeInBuyBoxPast30Days.reduce((acc, curr) => acc + curr.TimeInBuyBox, 0);
    });

    // find unique owners in the past 30 days
    const uniqueOwnersPastMonth = [...new Set(timeInBuyBoxPast30Days.map((el) => el.Seller))];

    // find unique owners, filtering for timestamps in the past year
    const uniqueOwnersPastYear = [
        ...new Set(
            buyBoxHistory.filter((el) => isAfter(el.Timestamp, new Date(Date.now() - 365 * 24 * 60 * 60 * 1000))).map((el) => el.Seller)
        ),
    ];

    return {
        grouped,
        uniqueOwners,
        averageUniqueOwners,
        medianUniqueOwners,
        amazonCount,
        averageTimeInBuyBox,
        uniqueOwnersPastMonth,
        uniqueOwnersPastYear,
        timeInBuyBox,
        averageTimeInBuyBoxPast30Days,
    };
};

const getTimeFromKeepa = (keepaTime) => {
    return new Date((keepaTime + 21564000) * 60000);
};

function isAfter(a, b) {
    return a.getTime() - b.getTime() > 0;
}

const findBuyBoxShare = (item) => {
    const buyBoxHistory = item.bbHistoryAnalyzed;
    const buyBoxPriceHistory = (item.csv[18] ?? [])
        .map((val, idx) => {
            if (idx % 3 === 0) {
                return getTimeFromKeepa(parseInt(val));
            } else {
                return val;
            }
        })
        .reduce((acc, curr, idx) => {
            if (idx % 3 === 0) {
                acc.push({
                    Timestamp: curr,
                    Price: "",
                });
            } else if (idx % 3 === 1) {
                acc[acc.length - 1].Price = curr >= 0 ? curr / 100 : -1;
            } else {
                acc[acc.length - 1].Price += curr >= 0 ? curr / 100 : 0;
            }

            return acc;
        }, []);

    // duplicate the last item in the buybox price history to make sure we have a value for today
    if (buyBoxPriceHistory.length > 0) {
        buyBoxPriceHistory.push({
            Timestamp: new Date(),
            Price: buyBoxPriceHistory[buyBoxPriceHistory.length - 1].Price,
        });
    }

    // fill the array in such a way that each 1 hour period between timestamps is represented
    const filledBuyBoxPriceHistory = [];
    for (let i = 0; i < buyBoxPriceHistory.length - 1; i++) {
        const curr = buyBoxPriceHistory[i];
        const next = buyBoxPriceHistory[i + 1];
        const diff = next.Timestamp - curr.Timestamp;
        const diffHours = diff / (1000 * 60 * 60);
        if (diffHours > 1) {
            for (let j = 0; j < diffHours; j++) {
                filledBuyBoxPriceHistory.push({
                    Timestamp: new Date(curr.Timestamp.getTime() + j * 1000 * 60 * 60),
                    Price: curr.Price,
                });
            }
        } else {
            filledBuyBoxPriceHistory.push(curr);
        }
    }

    // find how much the buybox price deviates over the past 90 days;
    const buyBoxPriceHistoryPast90Days = filledBuyBoxPriceHistory.filter((el) =>
        isAfter(el.Timestamp, new Date(Date.now() - 90 * 24 * 60 * 60 * 1000))
    );
    const buyBoxPriceHistoryPast90DaysAverage =
        buyBoxPriceHistoryPast90Days.length > 0
            ? buyBoxPriceHistoryPast90Days.reduce((acc, curr) => acc + curr.Price, 0) / buyBoxPriceHistoryPast90Days.length
            : -1;

    const buyBoxPriceHistoryPast90DaysMedian =
        buyBoxPriceHistoryPast90Days.length > 0 ? median(buyBoxPriceHistoryPast90Days.map((el) => el.Price)) : -1;

    // find how much the buybox price deviates over the past 30 days;
    const buyBoxPriceHistoryPast30Days = filledBuyBoxPriceHistory.filter((el) =>
        isAfter(el.Timestamp, new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
    );
    const buyBoxPriceHistoryPast30DaysAverage =
        buyBoxPriceHistoryPast30Days.length > 0
            ? buyBoxPriceHistoryPast30Days.reduce((acc, curr) => acc + curr.Price, 0) / buyBoxPriceHistoryPast30Days.length
            : -1;

    const buyBoxPriceHistoryPast30DaysMedian =
        buyBoxPriceHistoryPast30Days.length > 0 ? median(buyBoxPriceHistoryPast30Days.map((el) => el.Price)) : -1;

    // find how much the buybox price deviates over the past 365 days;
    const buyBoxPriceHistoryPast365Days = filledBuyBoxPriceHistory.filter((el) =>
        isAfter(el.Timestamp, new Date(Date.now() - 365 * 24 * 60 * 60 * 1000))
    );
    const buyBoxPriceHistoryPast365DaysAverage =
        buyBoxPriceHistoryPast365Days.length > 0
            ? buyBoxPriceHistoryPast365Days.reduce((acc, curr) => acc + curr.Price, 0) / buyBoxPriceHistoryPast365Days.length
            : -1;

    const buyBoxPriceHistoryPast365DaysMedian =
        buyBoxPriceHistoryPast365Days.length > 0 ? median(buyBoxPriceHistoryPast365Days.map((el) => el.Price)) : -1;

    // find the standard deviation of the buybox price in the past 90 days
    const buyBoxPriceHistoryPast90DaysStandardDeviation = Math.sqrt(
        buyBoxPriceHistoryPast90Days.reduce((acc, curr) => acc + Math.pow(curr.Price - buyBoxPriceHistoryPast90DaysAverage, 2), 0) /
            buyBoxPriceHistoryPast90Days.length
    );

    // if standard deviation is high, mark as volatile
    const isVolatile = buyBoxPriceHistoryPast90DaysStandardDeviation / buyBoxPriceHistoryPast90DaysMedian > 0.15;

    // find averageTimeInBuyBoxPast30DaysMedian
    const averageTimeInBuyBoxPast30DaysMedian = median(Object.values(buyBoxHistory.averageTimeInBuyBoxPast30Days));

    // find owners from the past 30 days that have at least 90% of the median bb share
    const realOwnersInPast30Days = Object.entries(buyBoxHistory.averageTimeInBuyBoxPast30Days)
        .filter((el) => el[1] >= 0.9 * averageTimeInBuyBoxPast30DaysMedian)
        .map((el) => el[0]);

    const bbShareIfEvenlySplit = 1 / (realOwnersInPast30Days.length + 1);

    const returnObj = {
        ASIN: item.asin,
        buyBoxPriceHistoryPast90DaysAverage: buyBoxPriceHistoryPast90DaysAverage,
        buyBoxPriceHistoryPast90DaysMedian: buyBoxPriceHistoryPast90DaysMedian,
        buyBoxPriceHistoryPast30DaysAverage: buyBoxPriceHistoryPast30DaysAverage,
        buyBoxPriceHistoryPast30DaysMedian: buyBoxPriceHistoryPast30DaysMedian,
        buyBoxPriceHistoryPast365DaysAverage: buyBoxPriceHistoryPast365DaysAverage,
        buyBoxPriceHistoryPast365DaysMedian: buyBoxPriceHistoryPast365DaysMedian,
        buyBoxPriceHistoryPast90DaysStandardDeviation: buyBoxPriceHistoryPast90DaysStandardDeviation,
        averageTimeInBuyBoxPast30DaysMedian: averageTimeInBuyBoxPast30DaysMedian,
        realOwnersInPast30Days: realOwnersInPast30Days,
        isVolatile: isVolatile,
        uniqueOwnersPastMonth: buyBoxHistory.uniqueOwnersPastMonth,
        uniqueOwnersPastYear: buyBoxHistory.uniqueOwnersPastYear,
        averageTimeInBuyBoxPast30Days: buyBoxHistory.averageTimeInBuyBoxPast30Days,
        bbShareIfEvenlySplit: bbShareIfEvenlySplit,
    };

    return returnObj;
};

export const fullKeepaAnalysis = (product) => {
    const salesRankHistory = analyzeSalesRankHistory(mapSalesRankHistory(product.csv[3]));
    const buyBoxHistory = mapBuyBoxHistory(product.buyBoxSellerIdHistory);
    const analyzedBuyBoxHistory = analyzeBuyBoxHistory(buyBoxHistory);

    // find the most encountered bb owner
    const mostOftenWon30D = Object.entries(analyzedBuyBoxHistory.averageTimeInBuyBoxPast30Days).sort((a, b) => b[1] - a[1])[0];
    const mostOftenWon365D = Object.entries(analyzedBuyBoxHistory.averageTimeInBuyBox).sort((a, b) => b[1] - a[1])[0];

    // find the average bb price
    const bbSharePredictor = findBuyBoxShare({...product, bbHistoryAnalyzed: analyzedBuyBoxHistory});

    return {
        asin: product.asin,
        title: product.title,
        salesRankHistory,
        buyBoxHistory,
        analyzedBuyBoxHistory,
        bbSharePredictor,
        mostOftenWon30D,
        mostOftenWon365D,
    };
};
