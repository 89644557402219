import {Divider, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {SingularItem} from "../../types/OmegaTypes";
import SingularItemCard from "./SingularItemCard";

interface DataType extends SingularItem {
    key: React.Key;
}

const updateUrl = (newSkuParam: string) => {
    const queryString = new URLSearchParams(window.location.search);
    queryString.set("sku", newSkuParam);
    const newRelativePathQuery = window.location.pathname + "?" + queryString.toString();
    window.history.pushState(null, "", newRelativePathQuery);
};

const SingularItemBrowser: React.FC<{tableData: DataType[]; selectedBrands: string[]}> = ({selectedBrands, tableData}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedProduct, setSelectedProduct] = useState<SingularItem | null>(null);
    const [spinning, setSpinning] = useState(false);
    const [singularSku, setSingularSku] = useState<string | null>(null);

    const proxyChange = (newIndex: number, fromUrl: boolean) => {
        setSelectedIndex(newIndex);
        if (fromUrl) {
            console.log("Changing from URL to index", newIndex);
        }
    };

    // useEffect(() => {
    //     console.log("Running tableData useEffect with tableData", tableData);
    //     if (tableData.length > 0) {
    //         const queryString = new URLSearchParams(window.location.search);
    //         if (queryString.has("sku")) {
    //             const querySKU = queryString.get("sku");
    //             const queryProdIndex = tableData.findIndex((prod) => prod.SKU === querySKU);
    //             console.log(`Changing to ${queryProdIndex} from table effect`);
    //             proxyChange(Math.max(queryProdIndex, 0), true);
    //         } else {
    //             console.log("Changing to 0 from table effect");
    //             proxyChange(0, false);
    //         }
    //     }
    // }, [tableData]);

    useEffect(() => {
        setSpinning(false);
        if (tableData.length > 0 && selectedIndex < tableData.length && selectedIndex >= 0) {
            console.log("New selected index is", selectedIndex, tableData, tableData[selectedIndex]);
            updateUrl(tableData[selectedIndex].SKU);
            setSelectedProduct(tableData[selectedIndex]);
            setSingularSku(tableData[selectedIndex].SKU);
        } else if (tableData.length > 0) {
            proxyChange(0, false);
        }
    }, [selectedIndex, tableData]);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            console.log("document.activeElement?.nodeName", document.activeElement?.nodeName);
            const NODE_NAMES_TO_IGNORE = ["TEXTAREA", "INPUT"];
            if (
                event.key === "ArrowLeft" &&
                !NODE_NAMES_TO_IGNORE.includes(document.activeElement?.nodeName ?? "") &&
                tableData.length > 1
            ) {
                setSpinning(true);
                setTimeout(() => setSelectedIndex((idx) => (((idx - 1) % tableData.length) + tableData.length) % tableData.length), 200);
            } else if (
                event.key === "ArrowRight" &&
                !NODE_NAMES_TO_IGNORE.includes(document.activeElement?.nodeName ?? "") &&
                tableData.length > 1
            ) {
                console.log("200 lule");
                setSpinning(true);
                setTimeout(() => setSelectedIndex((idx) => (idx + 1) % tableData.length), 200);
            } else if (
                event.key === "ArrowUp" &&
                !NODE_NAMES_TO_IGNORE.includes(document.activeElement?.nodeName ?? "") &&
                tableData.length > 1 &&
                selectedProduct?.itemArr.length > 1
            ) {
                event.preventDefault();
                setSpinning(true);
                setTimeout(() => {
                    // set the item to the next sku of this item
                    if (selectedProduct) {
                        const currentSku = selectedProduct.SKU;
                        const allItems = selectedProduct.itemArr;
                        const nextIndex = allItems.findIndex((item: any) => item.SKU === currentSku) + 1;
                        const nextProduct = allItems[nextIndex % allItems.length];
                        updateUrl(selectedProduct.SKU);
                        setSelectedProduct(nextProduct);
                        setSingularSku(nextProduct.SKU);
                        setSpinning(false);
                    }
                }, 100);
            } else if (
                event.key === "ArrowDown" &&
                !NODE_NAMES_TO_IGNORE.includes(document.activeElement?.nodeName ?? "") &&
                tableData.length > 1 &&
                selectedProduct?.itemArr.length > 1
            ) {
                event.preventDefault();
                setSpinning(true);
                setTimeout(() => {
                    // set the item to the next sku of this item
                    if (selectedProduct) {
                        const currentSku = selectedProduct.SKU;
                        const allItems = selectedProduct.itemArr;
                        const nextIndex = allItems.findIndex((item: any) => item.SKU === currentSku) - 1;
                        const nextProduct = allItems[(nextIndex + allItems.length) % allItems.length];
                        updateUrl(selectedProduct.SKU);
                        setSelectedProduct(nextProduct);
                        setSingularSku(nextProduct.SKU);
                        setSpinning(false);
                    }
                }, 100);
            }
        };

        window.addEventListener("keydown", keyDownHandler);

        return () => {
            window.removeEventListener("keydown", keyDownHandler);
        };
    });

    return (
        <Spin spinning={spinning}>
            <Select
                showSearch
                size="large"
                optionFilterProp="children"
                placeholder="Please select a product"
                {...(tableData.length > 0 ? {value: selectedIndex} : {})}
                onChange={(value: number) => {
                    proxyChange(value, false);
                }}
                style={{width: "100%"}}
                filterOption={(input, option) =>
                    (option!.children! as unknown as string[]).some((el: string) => el.toLowerCase().includes(input.toLowerCase().trim()))
                }
            >
                {tableData?.length > 0
                    ? tableData.map((item, idx) => (
                          <Select.Option key={idx} value={idx}>
                              {item.ASIN} - {item.SKU} - {item.Title}
                          </Select.Option>
                      ))
                    : null}
            </Select>
            <Divider style={{margin: "1px"}} />
            <Select
                style={{minWidth: "100%"}}
                disabled={!!!selectedProduct}
                onChange={(val) => {
                    setSelectedProduct(selectedProduct?.itemArr.find((item: any) => item.SKU === val) || null);
                    setSingularSku(val);
                }}
                value={singularSku}
                showSearch
                filterOption={(input, option) =>
                    (option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
                }
            >
                {selectedProduct?.itemArr.map((item: any, idx: number) => (
                    <Select.Option value={item.SKU} key={idx}>
                        {item.SKU} - {item.Stock}
                    </Select.Option>
                ))}
            </Select>
            {
                // tableData.length > 0 && selectedIndex < tableData.length && !!!selectedProduct ?
                //     <SingularItemCard key={tableData[selectedIndex]?.SKU} productData={tableData[selectedIndex]} prodIndex={selectedIndex} prodCount={tableData.length} />
                //     :
                selectedProduct && (
                    <SingularItemCard
                        key={selectedProduct?.SKU}
                        productData={selectedProduct}
                        prodIndex={selectedIndex}
                        prodCount={tableData.length}
                    />
                )
            }
        </Spin>
    );
};

export default SingularItemBrowser;
