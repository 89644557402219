import {Button, Col, Divider, Input, InputRef, Modal, notification, Row, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {OMSItemRenderType, OMSItem, SkuAsinItem} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeStage2Columns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {getOMS, getSkuAsin, updateOMS} from "../../../services/WholesaleService";
import {validateOMSStage2Columns} from "../../utilities/TableDataValidation";
import {grabKeepa} from "../../../services/KeepaService";
import {ExclamationCircleOutlined, FundProjectionScreenOutlined, RetweetOutlined, VerticalAlignTopOutlined} from "@ant-design/icons";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {OMSTableTitle} from "../table/OMSTableTitle";
import {
    BulkActionsButton,
    BulkActionType,
    makeCopyAsinsAction,
    makeCopyDataIndexAction,
    useSelectMultipleColumnModal,
} from "../table/BulkActions";
import {CSVDownload} from "../../utilities/CSVDownload";
import dayjs from "dayjs";
import * as dataForge from "data-forge";
import TextArea from "antd/es/input/TextArea";

const CreateAsinListMap = (data: OMSItem[]) => {
    const asinListMap: {[key: string]: string[]} = {};
    data.forEach((item) => {
        const asinList = asinListMap[item.Supplier_SKU] || [];
        if (item.ASIN && !asinList.includes(item.ASIN)) asinList.push(item.ASIN);
        asinListMap[item.Supplier_SKU] = asinList;
    });

    return asinListMap;
};

export const Stage2Browser: React.FC = () => {
    const queryClient = useQueryClient();
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const posInputRef = useRef<InputRef>(null);
    const [modal, contextHolder] = Modal.useModal();
    const [tableData, setTableData] = useState<OMSItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const [pos, setPos] = useState<string | undefined>();
    const [isKeepaLoading, setIsKeepaLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const tableRef = useRef<EditableTableHandle<OMSItemRenderType>>(null);

    const filteredTableData = useMemo(() => tableData.filter((item) => !item.deleted), [tableData]);

    const [selectMultipleModal, selectMultipleModalContext] = useSelectMultipleColumnModal(filteredTableData, selectedRowKeys, []);

    const {
        data: omsData,
        isLoading: omsDataLoading,
        isRefetching,
        refetch,
    } = useQuery({
        queryKey: ["oms_stage2_data", pos],
        queryFn: async () => {
            if (!pos) return [];

            const token = await currentUser!.getIdToken();
            if (token) {
                const products = await getOMS(token, pos);
                return products.reverse();
            }
            return [];
        },
        enabled: false,
        staleTime: Infinity,
        initialData: [],
    });

    useEffect(() => {
        refetch();
    }, [pos, refetch]);

    const omsMutation = useMutation({
        mutationFn: async (data: OMSItem[]) => {
            const token = await currentUser!.getIdToken();
            const dataToSave = data.map(({key, ...item}) => ({
                ...item,
                "S2_Pushed?": "YES",
                "S2_Pushed_By": currentUser!.email || "NA",
            }));
            await updateOMS(token, dataToSave, true);
            return dataToSave;
        },
        onSuccess: (data) => {
            // setTableData(data.map((item, idx) => ({ key: idx, ...item })));
            const newData = data.map((item, idx) => ({...item, key: idx}));
            queryClient.setQueryData(["oms_stage2_data", pos], newData);
            setFetchTimestamp(new Date());
            // Automatically update data in PO Browser
            queryClient.refetchQueries({queryKey: ["oms_data"]});
            notification.success({
                message: "Push successful!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const syncTableData = useCallback(() => {
        if (omsData) {
            // find out how many ASINs each UPC is linked to
            const asinListMap = CreateAsinListMap(omsData);

            setTableData(
                omsData.map((item, idx) => ({
                    ...item,
                    PO_ASINCount: asinListMap[item.Supplier_SKU].length,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
        // eslint-disable-next-line
    }, [omsData]);

    useEffect(() => {
        syncTableData();
    }, [omsData, syncTableData]);

    const downloadKeepaData = useCallback(
        async (data: OMSItemRenderType[], idToUse: string) => {
            setIsKeepaLoading(true);
            try {
                const updatedProducts = await grabKeepa(data, idToUse);
                const productsMap: {[key: string]: OMSItem} = {};
                updatedProducts.forEach((prod) => {
                    productsMap[prod._id || ""] = prod;
                });
                const newTableData = tableData.map((item, idx) => ({
                    ...item,
                    ...productsMap[item._id || ""],
                }));

                const asinListMap = CreateAsinListMap(newTableData);
                setTableData(newTableData.map((item, idx) => ({...item, PO_ASINCount: asinListMap[item.Supplier_SKU].length})));
                setLastUpdateTimestamp(new Date());
            } catch (e) {
                notification.error({
                    message: "Keepa error",
                    description: (e as Error).message,
                });
            }
            setIsKeepaLoading(false);
        },
        [tableData]
    );

    const handleSave = useCallback(
        (row: OMSItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });

            const asinListMap = CreateAsinListMap(newData);

            setTableData(
                newData
                    .map((item) => {
                        if (item.MAP && item.MAP > 0 && !item["Seasonal Tag"]?.includes("MAP")) {
                            item["Seasonal Tag"] = (item["Seasonal Tag"]?.length ?? 0) > 0 ? `${item["Seasonal Tag"]};MAP` : "MAP";
                        }

                        return item;
                    })
                    .map((item, idx) => ({
                        ...item,
                        PO_ASINCount: asinListMap[item.Supplier_SKU].length,
                    }))
            );
            setLastUpdateTimestamp(new Date());

            if (item.ASIN !== row.ASIN) {
                downloadKeepaData([row], "ASIN");
            }
        },
        [tableData, downloadKeepaData]
    );

    const updateData = (newData: OMSItem[], timestamp?: Date) => {
        setTableData(
            newData
                .map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
                .map((item) => {
                    if (item.MAP && item.MAP > 0 && !item["Seasonal Tag"]?.includes("MAP")) {
                        item["Seasonal Tag"] = (item["Seasonal Tag"]?.length ?? 0) > 0 ? `${item["Seasonal Tag"]};MAP` : "MAP";
                    }

                    return item;
                })
        );
        setLastUpdateTimestamp(timestamp || new Date());
    };

    const columns = useMemo<EditableColumnType<OMSItemRenderType>[]>(() => {
        const salesRanks = filteredTableData.map((item) => item.AMZ_SalesRank).filter((rank) => rank) as number[];

        let maxSalesRank = 1;
        if (salesRanks.length > 0) {
            maxSalesRank = Math.max(...salesRanks);
        }

        const stage2Columns = makeStage2Columns(searchInputRef, maxSalesRank);
        if (editable) {
            return makeColumnsEditable(stage2Columns, handleSave);
        } else {
            return stage2Columns;
        }
    }, [handleSave, editable, filteredTableData]);

    const onSaveChanges = () => {
        if (validateOMSStage2Columns(tableData).length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const getPOs = () => {
        let posText = posInputRef.current?.input?.value;
        if (posText) {
            // Remove whitespaces
            // posText = posText.replace(/\s/g, '');

            // If the pos string isn't changed, force a refetch
            if (posText !== pos) {
                setPos(posText);
            } else {
                refetch();
            }
        }
    };

    const bulkActions: BulkActionType[] = [
        makeCopyAsinsAction(filteredTableData, selectedRowKeys, "\n"),
        makeCopyDataIndexAction("Supplier_SKU", filteredTableData, selectedRowKeys, "\n"),
        {
            label: "Change OMS tags",
            onClick: () => {
                selectMultipleModal.show({
                    columnName: "OMS_Tag",
                    title: "OMS tag",
                    onUpdate: updateData,
                });
            },
            icon: <RetweetOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Check MultiASIN Prelist",
            onClick: () => {
                const keysSet = new Set(selectedRowKeys);

                const codeMap: {[key: string]: string[]} = {};
                filteredTableData.forEach((item) => {
                    // If not rows were selected update every row
                    if (keysSet.size === 0 || keysSet.has(item.key)) {
                        codeMap[item.UPC] = (codeMap[item.UPC] || []).concat(...item.ASINList);
                    }
                });

                currentUser!
                    .getIdToken()
                    .then((token: string) => getSkuAsin(token))
                    .then((skuAsinData: any) => {
                        const skuAsinMap: {
                            [key: string]: string[];
                        } = {};

                        skuAsinData.forEach((skuAsinItem: SkuAsinItem) => {
                            if (skuAsinMap[skuAsinItem.ASIN]) {
                                skuAsinMap[skuAsinItem.ASIN].push(skuAsinItem.SKU);
                            } else {
                                skuAsinMap[skuAsinItem.ASIN] = [skuAsinItem.SKU];
                            }
                        });

                        let multiAsinPrelistUpcs = [];
                        for (const [upc, asinList] of Object.entries(codeMap)) {
                            let asinsPrelisted = [];

                            for (const asin of asinList) {
                                if (skuAsinMap[asin]) {
                                    asinsPrelisted.push(asin);
                                }
                            }

                            if (asinsPrelisted.length > 1) {
                                multiAsinPrelistUpcs.push({
                                    upc,
                                    asinsPrelisted: Array.from(new Set(asinsPrelisted)),
                                });
                            }
                        }

                        if (multiAsinPrelistUpcs.length > 0) {
                            console.log(multiAsinPrelistUpcs);
                            modal.confirm({
                                title: `Some of the items in this PO have been prelisted under multiple ASINs previously:`,
                                icon: <ExclamationCircleOutlined />,
                                content: (
                                    <Space style={{width: "90%"}} direction="vertical" split={<Divider type="horizontal"></Divider>}>
                                        {multiAsinPrelistUpcs.map((upcData, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <label htmlFor={`upcData${idx}`}>
                                                        The UPC {upcData.upc} has been prelisted under the following ASINs before:
                                                    </label>
                                                    <TextArea id={`upcData${idx}`} value={upcData.asinsPrelisted.join(", ")} />
                                                </div>
                                            );
                                        })}
                                    </Space>
                                ),
                            });
                        }
                    });
            },
            icon: <VerticalAlignTopOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <OMSTableTitle
            tableData={filteredTableData}
            actions={
                <>
                    <UnsavedChangesHandler
                        isSaved={lastUpdateTimestamp <= fetchTimestamp}
                        disableWhenSaved={filteredTableData.length === 0}
                        isLoading={omsMutation.isPending}
                        onDiscardChanges={syncTableData}
                        onSaveChanges={onSaveChanges}
                    />
                    <Space>
                        Editable: <Switch checked={editable} onChange={setEditable} />
                    </Space>
                    <CSVDownload
                        collection={`Stage_2_Export_${dayjs().format("MM-DD-YY")}`}
                        data={tableRef.current?.currentData || []}
                        isLoading={!tableRef.current}
                        parse={(data) => {
                            if (validateOMSStage2Columns(data).length > 0) return undefined;

                            return new dataForge.DataFrame(data).subset(columns.map((col) => col.dataIndex as string)).toArray();
                        }}
                    />
                    <Button
                        type="primary"
                        icon={<FundProjectionScreenOutlined />}
                        onClick={() => downloadKeepaData(tableData, "UPC")}
                        loading={isKeepaLoading}
                        disabled={tableData.length === 0}
                    >
                        Grab Keepa
                    </Button>
                    <BulkActionsButton actions={bulkActions} />
                </>
            }
        />
    );

    return (
        <>
            {selectMultipleModalContext}
            {contextHolder}
            <Space direction="vertical" style={{width: "100%"}}>
                <Row>
                    <Col span={22}>
                        <Input placeholder="Enter POs" allowClear ref={posInputRef} />
                    </Col>
                    <Col span={2}>
                        <Button loading={omsDataLoading || isRefetching} onClick={getPOs}>
                            Get
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <EditableTable<OMSItemRenderType>
                            ref={tableRef}
                            title={tableTitle}
                            tableData={filteredTableData}
                            columns={columns}
                            loading={omsDataLoading || isRefetching}
                            onRowSelectionChange={(rowKeys, availableRowKeys) =>
                                setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                            }
                        />
                    </Col>
                </Row>
            </Space>
        </>
    );
};
