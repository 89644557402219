import {
    Button,
    Col,
    Row,
    Space,
    Switch,
    Divider,
    InputRef,
    notification,
    Collapse,
    Select,
    Statistic,
    Form,
    Modal,
    message,
    Card,
    Spin,
    Table,
    Tooltip,
} from "antd";
import React, {useEffect, useRef, useState, useCallback, useMemo} from "react";
import {useSupplierContext} from "../../contexts/SupplierContext";
import EditableTable, {EditableTableHandle} from "../omega/table/EditableTable";
import {BrandDashboardItem, BrandDashboardItemRenderType, GlobalNoteItem} from "../../types/Brand";
import {UnsavedChangesHandler} from "../utilities/UnsavedChangesHandler";
import {CSVDownload} from "../utilities/CSVDownload";
import dayjs from "dayjs";
import * as dataForge from "data-forge";
import * as _ from "lodash";
import {validateBrandItems} from "../utilities/TableDataValidation";
import {EditableColumnType} from "../omega/table/EditableCell";
import {makeColumnsEditable} from "../utilities/OMSColumns";
import {makeBrandDashboardItemsColumns} from "./items/BrandItemsColumns";
import {AuditOutlined, CopyOutlined, DeleteFilled, DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {UploadHandler} from "../utilities/UploadHandler";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {getKeepa} from "../../services/KeepaService";
import {useAuth} from "../../contexts/AuthContext";
import {CURRENT_TO_RUN} from "./values/maptemp";
import * as SheetJS from "xlsx";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
import {useQuery} from "@tanstack/react-query";
import {getAggregatedWholesaleData} from "../../services/WholesaleService";
import {
    getBoolRenderer,
    getBooleanFilter,
    getNumberFilterSorter,
    getPriceRenderer,
    getShareRenderer,
} from "../utilities/TableFilterSorters";
import {FormInstance} from "antd/lib";
import {BulkActionsButton, useSelectMultipleColumnModal} from "../omega/table/BulkActions";
import {BRAND_OPPORTUNITIES} from "../../types/OmegaTypes";

const defaultItem: BrandDashboardItem = {
    ASIN: "",
    Brand: "",
    SupplierSKU: "",
    Cost: -1,
    Title: "",
    UPC: "",
    ParentASIN: "",
    MAP: undefined,
    MSRP: undefined,
    Revenue: undefined,
    Spend: undefined,
    UnitSales: undefined,
    followMap: false,
    followBizMap: false,
    ourInvolvement: "",
    prepInstructions: "",
    DiscountNeeded: -1,
    ExclusivityValue: -1,
    opportunities: [],
    Notes: [],
    DateAdded: new Date(),
    DateUpdated: new Date(),
    deleted: false,
};

const getDefaultItem = (brand: string) => ({
    ...defaultItem,
    Brand: brand,
});

const requiredColumns = ["ASIN", "Brand", "SupplierSKU", "Title", "Cost", "UPC", "MAP", "MSRP", "prepInstructions"];

const processMapRun = async (brandToRun: string, email: string, product: BrandDashboardItemRenderType[]) => {
    // filter for MAP items
    // const mapItems = product.filter((item) => item.MAP && item.MAP > 0 && (item.followMap === true || item.followBizMap === true))
    // const mapItems = product.filter((item) => item.MAP && item.MAP > 0)
    // console.log(mapItems.map((item) => ({ASIN: item.ASIN, MAP: item.MAP})))
    console.log(CURRENT_TO_RUN.split("\n").slice(1));

    const asins = CURRENT_TO_RUN.split("\n")
        .slice(1)
        .map((line) => line.trim().split(/,|\t/))
        .map((line) => {
            return {
                ASIN: line[0],
                MAP: parseFloat(line[1]),
            };
        })
        .filter((item) => item.MAP > 0 && item.ASIN !== "");

    console.log(asins);

    // grab keepa for every 100 products
    let i = 0;
    let chunk = 100;
    let keepaData: any[] = [];

    for (i = 0; i < asins.length; i += chunk) {
        const results = await getKeepa(
            asins
                .slice(i, i + chunk)
                .map((prod) => prod.ASIN)
                .join(","),
            "asin",
            "&history=0&stats=0&buybox=0&rating=0"
        );
        if (results.products === undefined) {
            message.error("Keepa API empty response");
        } else {
            keepaData.push(...(results.products ?? []));
        }
    }

    console.log(keepaData);

    const keepaMapBreakers = keepaData
        .map((prod) => ({
            ASIN: prod.asin,
            Price: Math.max(prod.stats.current[1], 0) / 100,
            MAP: asins.find((asin) => asin.ASIN === prod.asin)?.MAP || 0,
        }))
        .filter((prod) => prod.Price > 0)
        .filter((prod) => prod.Price < prod.MAP);

    console.log(keepaMapBreakers);

    const requestData = {
        email: email,
        brandToRun: brandToRun,
        asinsToRun: keepaMapBreakers,
    };

    console.log(requestData);

    const requestResp = await fetch(`https://api.repricer.projectsuite.io/maptool/scrape_screenshots?key=maximumrepricer`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
    }).then((res) => res.json());

    console.log(requestResp);
};

const findIssues = (row: BrandDashboardItemRenderType, data: BrandDashboardItemRenderType[]) => {
    // if two rows have the same ASIN, then they are duplicates
    const issues = [];

    const duplicateRows = data.filter((item) => item.ASIN === row.ASIN && item.key !== row.key);
    if (duplicateRows.length > 0) {
        issues.push(`Duplicate ASIN`);
    }

    if (row.deleted === true) {
        issues.push("Deleted");
    }

    return issues;
};

// const getNestedTable = (record: any, index: number, indent: number, expanded: boolean) => {
//     const newColumns = [
//         {
//             title: 'Parent',
//             dataIndex: 'ParentASIN',
//             key: 'ParentASIN',
//             width: 150,
//         }, {
//             title: 'IsParent',
//             dataIndex: 'IsParent',
//             key: 'IsParent',
//             width: 50,
//             ...getBoolRenderer(),
//             ...getBooleanFilter('IsParent')
//         },
//         {
//             title: () => <Tooltip placement="top" title={"Title of a random variation"}>Sample Title</Tooltip>,
//             dataIndex: 'Title',
//             key: 'Title',
//             width: "275px",
//             sorter: {
//                 compare: (a: any, b: any) => a.Title!.localeCompare(b.Title!),
//                 multiple: 1
//             },
//             ...GetExpandedFilter([], 'Title'),
//         }
//     ]

//     console.log('record', record, index)
//     console.log('recordchildren', record.childrenVars)

//     return <Table
//         sticky
//         size="small"
//         tableLayout={"fixed"}
//         columns={newColumns}
//         dataSource={record.childrenVars}
//         key={index}
//         rowKey={(record) => record.key}
//     />
// }

const getImportSetting = async (settingForm: FormInstance<any>) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form
                    form={settingForm}
                    name="settingFormValues"
                    layout="vertical"
                    initialValues={{
                        costOverwrite: false,
                        revenueOverwrite: false,
                        deleteOverwrite: false,
                    }}
                    onFinish={() => null}
                >
                    <Form.Item name="costOverwrite" label="Do you want to overwrite Cost values" valuePropName="checked">
                        <Switch title=""></Switch>
                    </Form.Item>
                    <Form.Item name="revenueOverwrite" label="Do you want to overwrite Revenue values" valuePropName="checked">
                        <Switch title=""></Switch>
                    </Form.Item>
                    <Form.Item name="deleteOverwrite" label="Do you want to overwrite Delete values" valuePropName="checked">
                        <Switch title=""></Switch>
                    </Form.Item>
                </Form>
            ),
            onOk() {
                settingForm.validateFields().then((values) => {
                    resolve(values);
                });
            },
            onCancel() {
                settingForm.resetFields();
                reject({costOverwrite: false, revenueOverwrite: false, deleteOverwrite: false});
            },
        });
    });
};

const BrandItems: React.FC = () => {
    const {
        selectedSupplier: supplier,
        updateSupplier,
        updateSupplierItems,
        selectedSupplierItems,
        selectedSupplierItemNotes,
    } = useSupplierContext();
    const {currentUser, userData} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const tableRef = useRef<EditableTableHandle<BrandDashboardItemRenderType>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);
    const [showDeleted, setShowDeleted] = useState<boolean>(false);
    const [tableData, setTableData] = useState<BrandDashboardItemRenderType[]>([]);
    const [finalTableData, setFinalTableData] = useState<BrandDashboardItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(new Date());
    const [tagForm] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [parentMode, setParentMode] = useState<boolean>(false);
    const [parentData, setParentData] = useState<any[]>([]);
    const [settingForm] = Form.useForm();
    const [opportunitiesModal, opportunitiesModalContext] = useSelectMultipleColumnModal(
        tableData,
        selectedRowKeys,
        BRAND_OPPORTUNITIES,
        (val) => val
    );

    const {data: wholesaleData} = useQuery({
        queryKey: ["wholesaleData", supplier?.name, selectedSupplierItems],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getAggregatedWholesaleData(
                    token,
                    selectedSupplierItems!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!selectedSupplierItems,
    });

    useEffect(() => {
        if (selectedSupplierItems && selectedSupplierItemNotes && wholesaleData) {
            setIsLoading(true);
            const noteMap = selectedSupplierItemNotes.reduce((acc: {[key: string]: GlobalNoteItem[]}, note) => {
                const key = note.ASIN;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(note);
                return acc;
            }, {});

            const wholesaleMap = wholesaleData.reduce((acc: {[key: string]: any}, item: any) => {
                acc[item.asin] = item;
                return acc;
            }, {});

            console.log("updating local supplier items");
            setTableData(
                selectedSupplierItems.map((item, idx) => ({
                    ...item,
                    OurRevenue: wholesaleMap[item.ASIN]
                        ? wholesaleMap[item.ASIN].avg_sell_price * wholesaleMap[item.ASIN].ordered_units
                        : 0,
                    Notes: (noteMap[item.ASIN] || []).sort((a, b) => dayjs(b.updateTimestamp).diff(dayjs(a.updateTimestamp))),
                    key: idx,
                }))
            );
            setFetchTimestamp(new Date());
            setIsLoading(false);
        }
    }, [selectedSupplierItems, selectedSupplierItemNotes, wholesaleData]);

    const updateData = useCallback((newData: BrandDashboardItemRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    }, []);

    const handleSave = useCallback(
        (row: BrandDashboardItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData, updateData]
    );

    const addNewRow = useCallback(
        (newRowProps: any = {}) => {
            const newItem: BrandDashboardItemRenderType = {
                ...getDefaultItem(supplier?.name || ""),
                ...newRowProps,
                key: tableData.length,
            };
            const newData = [newItem, ...tableData];
            setTableData(newData);
        },
        [tableData, supplier?.name]
    );

    const onDiscardChanges = useCallback(() => {
        if (selectedSupplierItems) {
            setTableData(
                selectedSupplierItems.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    }, [selectedSupplierItems, fetchTimestamp]);

    const onSaveChanges = useCallback(() => {
        setIsLoading(true);
        const errors = validateBrandItems(tableData);

        if (errors.length === 0) {
            updateSupplierItems(tableData);
        } else {
            setIsLoading(false);
        }
    }, [tableData, updateSupplierItems]);

    const loadFiles = async (data: any) => {
        let dataFrames = [];
        for (const file of data) {
            // if (file.filename.includes('xlsx')) {
            if (file.filename.split(".").pop().includes("xlsx")) {
                const resultWorkBoot = SheetJS.read(file.result, {raw: true});
                const sheetRows: string[][] = SheetJS.utils.sheet_to_json(resultWorkBoot.Sheets["Dashboard Data"], {
                    blankrows: true,
                    rawNumbers: false,
                });

                dataFrames.push(new dataForge.DataFrame(sheetRows));
            } else {
                dataFrames.push(dataForge.fromCSV(new TextDecoder("utf-8").decode(file.result)));
            }
        }

        return dataFrames;
    };

    const processData = (data: {filename: string; result: any}[]) => {
        console.log("data", data);
        setIsLoading(true);

        getImportSetting(settingForm)
            .then((settings: any) => {
                loadFiles(data).then((data) => {
                    let dataFrame: dataForge.IDataFrame<number, any> = dataForge.DataFrame.concat(data);
                    console.log(dataFrame.toArray());

                    // Check if all required columns are present
                    const columnsDiff = _.difference(["ASIN"], dataFrame.getColumnNames());

                    if (columnsDiff.length > 0) {
                        notification.error({
                            message: "Wrong columns",
                            description: (
                                <>
                                    Some columns are missing:
                                    {columnsDiff.map((col) => (
                                        <p style={{margin: 0}}>{col}</p>
                                    ))}
                                </>
                            ),
                        });
                        return;
                    }

                    dataFrame = dataFrame
                        .ensureSeries({
                            UPC: (df) => df.getSeries("ASIN").select((val) => ""),
                            Revenue: (df) => {
                                const columns = df.getColumnNames();
                                if (columns.includes("Estimated Monthly Revenue")) {
                                    return df.getSeries("Estimated Monthly Revenue").select((val: string) => val || "0");
                                } else if (columns.includes("EstChildSales")) {
                                    return df.getSeries("EstChildSales").select((val: string) => val || "0");
                                } else {
                                    return df.getSeries("ASIN").select((val) => undefined);
                                }
                            },
                            Spend: (df) => {
                                const columns = df.getColumnNames();
                                if (columns.includes("EstChildSpend")) {
                                    return df.getSeries("EstChildSpend").select((val: string) => val || "0");
                                } else {
                                    return df.getSeries("ASIN").select((val) => undefined);
                                }
                            },
                            UnitSales: (df) => {
                                const columns = df.getColumnNames();
                                if (columns.includes("Estimated Monthly Units Sold")) {
                                    return df.getSeries("Estimated Monthly Units Sold").select((val: string) => val || "0");
                                } else if (columns.includes("EstChildUnitSales")) {
                                    return df.getSeries("EstChildUnitSales").select((val: string) => val || "0");
                                } else {
                                    return df.getSeries("ASIN").select((val) => undefined);
                                }
                            },
                            SupplierSKU: (df) => {
                                const columns = df.getColumnNames();
                                if (columns.includes("Supplier_SKU")) {
                                    return df.getSeries("Supplier_SKU").select((val: string) => val || "0");
                                } else if (columns.includes("SKU")) {
                                    return df.getSeries("SKU").select((val: string) => val || "0");
                                } else {
                                    return df.getSeries("ASIN").select((val) => "");
                                }
                            },
                            prepInstructions: (df) => {
                                const columns = df.getColumnNames();
                                if (columns.includes("prepInstructions")) {
                                    return df.getSeries("prepInstructions").select((val: string) => val || "");
                                } else {
                                    return df.getSeries("ASIN").select((val) => "");
                                }
                            },
                        })
                        .generateSeries({
                            ParentASIN: (row) => {
                                if (row["Parent ASIN"]) {
                                    return row["Parent ASIN"];
                                } else if (row["ParentASIN"]) {
                                    return row["ParentASIN"];
                                } else if (row["Parent"]) {
                                    return row["Parent"];
                                } else {
                                    return "";
                                }
                            },
                            opportunities: (row) => {
                                if (row["opportunities"]) {
                                    return row["opportunities"].split(";").map((opportunity: string) => opportunity.trim());
                                } else {
                                    return [];
                                }
                            },
                        })
                        .subset(Object.keys(defaultItem))
                        .transformSeries({
                            Cost: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            MAP: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            MSRP: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            Revenue: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            Spend: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            UnitSales: (val) => (val ? parseInt(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            DiscountNeeded: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            ExclusivityValue: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
                            UPC: (val) => (val ? val : ""),
                            followMap: (val) => (val ? val === "true" : false),
                            followBizMap: (val) => (val ? val === "true" : false),
                            deleted: (val) => false,
                            Notes: (val) => [],
                        });

                    let incomingData: BrandDashboardItem[] = dataFrame.toArray().map((prod) => ({
                        ...getDefaultItem(supplier?.name || ""),
                        ...prod,
                        Brand: supplier?.name || prod.Brand,
                        DateUpdated: new Date(),
                    }));

                    console.log("defaultitem", getDefaultItem(""));
                    console.log("incomingData", incomingData);

                    setTableData((oldTableData) => {
                        // for each item in oldTableData, overwrite it with the new data if it exists, otherwise just add the new item
                        const newTableData = oldTableData.map((item) => {
                            const incomingItem =
                                incomingData.find(
                                    (prod) =>
                                        [prod.ASIN, prod.UPC, prod.SupplierSKU].join("-") ===
                                        [item.ASIN, item.UPC, item.SupplierSKU].join("-")
                                ) ??
                                incomingData.find((prod) => [prod.ASIN, prod.UPC].join("-") === [item.ASIN, item.UPC].join("-")) ??
                                incomingData.find((prod) => prod.ASIN === item.ASIN);
                            if (incomingItem) {
                                const newItem = {
                                    ...getDefaultItem(supplier?.name || ""),
                                    ...item,
                                };
                                for (const key in newItem) {
                                    if (settings.costOverwrite === false && key === "Cost") {
                                        continue;
                                    } else if (settings.revenueOverwrite === false && key === "Revenue") {
                                        continue;
                                    } else if (settings.deleteOverwrite === false && key === "deleted") {
                                        continue;
                                    }

                                    if (
                                        // @ts-ignore
                                        incomingItem[key] !== undefined &&
                                        // @ts-ignore
                                        incomingItem[key] !== "" &&
                                        // @ts-ignore
                                        incomingItem[key] !== null
                                    ) {
                                        // @ts-ignore
                                        newItem[key] = incomingItem[key];
                                    }
                                }
                                return newItem;
                            } else {
                                return item;
                            }
                        });

                        // add any new items that don't exist in oldTableData
                        // const newItems = incomingData.filter((prod) => !oldTableData.find((item) => [prod.ASIN, prod.UPC].join('-') === [item.ASIN, item.UPC].join('-')));
                        const newItems = incomingData.filter((prod) => !oldTableData.find((item) => prod.ASIN === item.ASIN));
                        return [...newTableData, ...newItems].map((item, idx) => ({
                            ...item,
                            key: idx,
                            DateAdded: item.DateAdded || new Date(),
                        }));
                    });

                    setLastUpdateTimestamp(new Date());
                    setIsLoading(false);
                });
            })
            .catch((settings) => {
                notification.error({
                    message: "Settings not filled",
                    description: "Please fill in the settings to continue",
                });
                setIsLoading(false);
                return;
            });
    };

    const columns = useMemo<EditableColumnType<BrandDashboardItemRenderType>[]>(() => {
        const brandDashboardItemsColumns = makeBrandDashboardItemsColumns(
            searchInputRef,
            selectedSupplierItems.reduce(
                // prettier-ignore
                (acc: { [key: string]: BrandDashboardItem }, item) => (((acc[`${item.ASIN}-${item.UPC}-${item.SupplierSKU}`] = item), acc)),
                {}
            ),
            editable ? handleSave : undefined
        );
        if (editable) {
            brandDashboardItemsColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record: BrandDashboardItemRenderType) => (
                    <Space direction="horizontal">
                        <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                                addNewRow({
                                    DateAdded: new Date(),
                                    DateUpdated: new Date(),
                                    Brand: record.Brand,
                                    Cost: record.Cost,
                                    ASIN: "",
                                    SupplierSKU: record.SupplierSKU,
                                    Title: record.Title,
                                    ParentASIN: record.ParentASIN,
                                    MAP: record.MAP,
                                    MSRP: record.MSRP,
                                    followMap: record.followMap,
                                    followBizMap: record.followBizMap,
                                    ourInvolvement: record.ourInvolvement,
                                    prepInstructions: record.prepInstructions,
                                    opportunities: record.opportunities,
                                    Notes: [],
                                    UPC: "",
                                    deleted: false,
                                });
                            }}
                        />
                        <Button
                            danger
                            icon={<DeleteFilled />}
                            onClick={() => {
                                if (record._id === undefined) {
                                    // remove the item from tableData
                                    const dataCopy = [...tableData];
                                    const index = dataCopy.findIndex((item) => item.key === record.key);
                                    dataCopy.splice(index, 1);

                                    // update the tableData
                                    setTableData(dataCopy);
                                    setLastUpdateTimestamp(new Date());
                                } else {
                                    const dataCopy = [...tableData];
                                    const itemToDelete = dataCopy.find((item) => item.key === record.key)!;
                                    itemToDelete.deleted = true;

                                    setLastUpdateTimestamp(new Date());
                                    setTableData(dataCopy);
                                }
                            }}
                        />
                    </Space>
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(brandDashboardItemsColumns, handleSave);
        } else {
            return brandDashboardItemsColumns;
        }
    }, [handleSave, editable, tableData, addNewRow, selectedSupplierItems]);

    const tableTitle = useCallback(
        () => (
            <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
                <UnsavedChangesHandler
                    isSaved={lastUpdateTimestamp <= fetchTimestamp}
                    disableWhenSaved
                    isLoading={false}
                    onDiscardChanges={onDiscardChanges}
                    onSaveChanges={onSaveChanges}
                />
                <CSVDownload
                    collection={`Supplier_Items_Export_${dayjs().format("MM-DD-YY")}`}
                    data={tableRef.current?.currentData?.filter((row) => row.deleted !== true) || []}
                    isLoading={!tableRef.current}
                    dateColumns={["DateAdded", "DateUpdated"]}
                    parse={(data) => {
                        if (validateBrandItems(data).length > 0) return undefined;

                        return new dataForge.DataFrame(
                            data.map((item) => ({
                                ...getDefaultItem(supplier?.name || ""),
                                ...item,
                            }))
                        )
                            .subset(columns.map((col) => col.dataIndex as string))
                            .renameSeries({
                                SupplierSKU: "SKU",
                            })
                            .transformSeries({
                                Notes: (val) =>
                                    val.map((note: any) => `${note.noteTimestamp}: ${note.noteText} - ${note.noteAuthor}`).join(" || "),
                            })
                            .toArray();
                    }}
                />
                <Button
                    onClick={() => {
                        processMapRun(
                            supplier?.name!,
                            currentUser?.email!,
                            finalTableData.filter((item) => selectedRowKeys.includes(item.key))
                        );
                    }}
                    disabled={!userData?.role?.includes("ADMIN") && (supplier?.name === undefined || !currentUser?.email!)}
                >
                    Run MAP
                </Button>
                <Space>
                    Editable: <Switch checked={editable} onChange={setEditable} />
                </Space>
                <Space>
                    Show Deleted: <Switch checked={showDeleted} onChange={setShowDeleted} />
                </Space>
                <Button
                    onClick={() => {
                        addNewRow();
                    }}
                    disabled={!editable}
                >
                    Add row
                </Button>
                <BulkActionsButton
                    actions={[
                        {
                            label: "Delete",
                            onClick: () => {
                                const keysSet = new Set(selectedRowKeys);
                                const newData = finalTableData.map((item) => {
                                    const newItem = {...item};
                                    // If not rows were selected update every row
                                    if (keysSet.size === 0 || keysSet.has(item.key)) {
                                        newItem.deleted = true;
                                        newItem.DateUpdated = new Date();
                                    }
                                    return newItem;
                                });
                                updateData(newData);
                            },
                            icon: <DeleteOutlined />,
                            disabled: selectedRowKeys.length === 0,
                        },
                        {
                            label: "Change Opportunities",
                            onClick: () =>
                                opportunitiesModal.show({
                                    columnName: "opportunities",
                                    title: "Opportunities",
                                    onUpdate: updateData,
                                }),
                            icon: <AuditOutlined />,
                            disabled: selectedRowKeys.length === 0,
                        },
                    ]}
                />
            </Space>
        ),
        [
            lastUpdateTimestamp,
            fetchTimestamp,
            onDiscardChanges,
            onSaveChanges,
            userData?.role,
            supplier?.name,
            currentUser?.email,
            editable,
            showDeleted,
            columns,
            finalTableData,
            selectedRowKeys,
            addNewRow,
            updateData,
            opportunitiesModal,
        ]
    );

    useEffect(() => {
        if (tagForm) {
            tagForm.setFieldsValue({
                supplierBrands: supplier?.supplierBrands || [],
            });
        }
    }, [tagForm, supplier?.supplierBrands]);

    const editValue = useCallback(() => {
        modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form form={tagForm} name={`edit-supplierBrands`} layout="vertical" preserve={true}>
                    <Form.Item
                        name={`supplierBrands`}
                        key={`supplierBrands`}
                        label={`What brands would you like to associate with your brand?`}
                    >
                        <Select
                            mode="tags"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            size="large"
                            placeholder="Please select a brand"
                            style={{width: "100%"}}
                            filterOption={(input, option) =>
                                (option?.value?.toString().toLowerCase().indexOf(input.toLowerCase()) || 1) >= 0
                            }
                        ></Select>
                    </Form.Item>
                </Form>
            ),
            onOk() {
                tagForm.validateFields().then((values) => {
                    console.log("bulk form values", values);
                    if (values.supplierBrands !== undefined) {
                        updateSupplier(
                            {
                                name: supplier!.name,
                                supplierBrands: values.supplierBrands,
                            },
                            "items",
                            "Connected Brands",
                            `Connected Brands have been updated to ${values.supplierBrands.join(",")}`
                        );

                        tagForm.setFieldsValue({
                            supplierBrands: values.supplierBrands,
                        });
                    } else {
                        message.error("Please fill in the form");
                    }
                });
            },
            onCancel() {
                tagForm.setFieldsValue({
                    supplierBrands: supplier?.supplierBrands || [],
                });
            },
        });
    }, [modal, tagForm, supplier, updateSupplier]);

    useEffect(() => {
        setFinalTableData(
            tableData
                .filter((item) => !item.deleted || showDeleted)
                .map((row, idx, arr) => ({
                    ...row,
                    Issues: findIssues(row, arr),
                }))
        );
        setIsLoading(false);
    }, [tableData, showDeleted]);

    useEffect(() => {
        const parentData = finalTableData.reduce((acc: any[], item) => {
            // group items by parent asin
            if (item.ParentASIN) {
                const parentItem = acc.find((accItem) => accItem.ParentASIN === item.ParentASIN);
                if (parentItem) {
                    parentItem.childCount += 1;
                    parentItem.totalRevenue += item.Revenue || 0;
                    parentItem.avgCost += Math.max(item.Cost ?? 0, 0);
                    parentItem.totalSpend += Math.max(item.Spend ?? 0, 0);
                    parentItem.childrenVars.push(item);
                } else {
                    acc.push({
                        ParentASIN: item.ParentASIN,
                        childCount: 1,
                        IsParent: true,
                        Title: item.Title,
                        totalRevenue: item.Revenue || 0,
                        childrenVars: [item],
                        avgCost: Math.max(item.Cost ?? 0, 0),
                        totalSpend: Math.max(item.Spend ?? 0, 0),
                        key: item.ParentASIN,
                    });
                }
            } else {
                acc.push({
                    ParentASIN: item.ASIN,
                    IsParent: false,
                    childCount: 1,
                    Title: item.Title,
                    totalRevenue: item.Revenue || 0,
                    childrenVars: [item],
                    avgCost: Math.max(item.Cost ?? 0, 0),
                    totalSpend: Math.max(item.Spend ?? 0, 0),
                    key: item.ASIN,
                });
            }

            return acc;
        }, []);

        // find avg cost
        parentData.forEach((item) => {
            const costItems = item.childrenVars.filter((child: BrandDashboardItem) => child.Cost > 0).length;
            item.avgCost = costItems > 0 ? item.avgCost / costItems : -1;
            item.percRevenue = (100 * item.totalRevenue) / finalTableData.reduce((acc, item) => acc + (item.Revenue || 0), 0);
        });

        setParentData(parentData);
    }, [finalTableData]);

    return (
        <>
            {contextHolder}
            {opportunitiesModalContext}
            <Spin spinning={isLoading}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Collapse defaultActiveKey={[1, 3]}>
                            <CollapsePanel key={1} header={"Import"}>
                                <Space split={<Divider type="vertical" />}>
                                    <UploadHandler
                                        onComplete={processData}
                                        template={requiredColumns}
                                        fileName="brand_items_template.csv"
                                        arrayMode={true}
                                        acceptedFileTypes=".csv,.tsv,.xlsx"
                                    />
                                    <Space>
                                        Parent Mode: <Switch checked={parentMode} onChange={setParentMode} />
                                    </Space>
                                </Space>
                            </CollapsePanel>
                            <CollapsePanel key={2} header={"Data"}>
                                <Card bordered={true}>
                                    <Statistic
                                        title={
                                            <span>
                                                Brands: <EditOutlined onClick={() => editValue()} />
                                            </span>
                                        }
                                        value={supplier?.supplierBrands?.join(", ") ?? ""}
                                        valueStyle={{
                                            color: "rgb(134 247 33)",
                                            overflowY: "auto",
                                            fontSize: "12px",
                                        }}
                                        formatter={(value) => value}
                                    />
                                </Card>
                            </CollapsePanel>
                            <CollapsePanel key={3} header={"Statistics"}>
                                <Row gutter={[8, 8]}>
                                    <Col span={5}>
                                        <Card bordered={true}>
                                            <Statistic
                                                title={"Monthly Revenue"}
                                                value={`$${finalTableData
                                                    .filter((item) => parentMode || selectedRowKeys.includes(item.key))
                                                    .reduce((acc, item) => acc + (item.Revenue || 0), 0)
                                                    .toLocaleString()} out of $${finalTableData
                                                    .reduce((acc, item) => acc + (item.Revenue || 0), 0)
                                                    .toLocaleString()}`}
                                                valueStyle={{
                                                    overflowY: "auto",
                                                    fontSize: "12px",
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={3}>
                                        <Card bordered={true}>
                                            <Statistic
                                                title={"MG Items"}
                                                value={finalTableData
                                                    .filter((item) => parentMode || selectedRowKeys.includes(item.key))
                                                    .reduce((acc, item) => (acc += +!!item.ourInvolvement?.includes("MG")), 0)}
                                                valueStyle={{
                                                    overflowY: "auto",
                                                    fontSize: "12px",
                                                }}
                                                precision={0}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={3}>
                                        <Card bordered={true}>
                                            <Statistic
                                                title={"All Items"}
                                                value={`${
                                                    finalTableData.filter((item) => parentMode || selectedRowKeys.includes(item.key)).length
                                                } out of ${finalTableData.length}`}
                                                valueStyle={{
                                                    overflowY: "auto",
                                                    fontSize: "12px",
                                                }}
                                                precision={0}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={3}>
                                        <Card bordered={true}>
                                            <Statistic
                                                loading={wholesaleData === undefined}
                                                title={"Our Revenue"}
                                                value={wholesaleData?.reduce(
                                                    (acc: number, curr: any) => (acc += curr.avg_sell_price * curr.ordered_units),
                                                    0
                                                )}
                                                valueStyle={{
                                                    overflowY: "auto",
                                                    fontSize: "12px",
                                                }}
                                                precision={2}
                                                prefix={"$"}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </CollapsePanel>
                        </Collapse>
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            gap: "6px",
                        }}
                    >
                        {parentMode ? (
                            <Table
                                id="components-table-demo-nested"
                                loading={isLoading}
                                columns={[
                                    {
                                        title: "Parent",
                                        dataIndex: "ParentASIN",
                                        key: "ParentASIN",
                                        width: 150,
                                    },
                                    {
                                        title: "IsParent",
                                        dataIndex: "IsParent",
                                        key: "IsParent",
                                        width: 50,
                                        ...getBoolRenderer(),
                                        ...getBooleanFilter("IsParent"),
                                    },
                                    {
                                        title: () => (
                                            <Tooltip placement="top" title={"Title of a random variation"}>
                                                Sample Title
                                            </Tooltip>
                                        ),
                                        dataIndex: "Title",
                                        key: "Title",
                                        width: "275px",
                                        sorter: {
                                            compare: (a, b) => a.Title!.localeCompare(b.Title!),
                                            multiple: 1,
                                        },
                                        ...GetExpandedFilter([], "Title"),
                                    },
                                    {
                                        title: "Children",
                                        dataIndex: "childCount",
                                        key: "childCount",
                                        width: 150,
                                    },
                                    {
                                        title: "Cost (avg)",
                                        dataIndex: "avgCost",
                                        key: "avgCost",
                                        width: 150,
                                        ...getNumberFilterSorter("avgCost"),
                                        ...getPriceRenderer(),
                                    },
                                    {
                                        title: "Revenue",
                                        dataIndex: "totalRevenue",
                                        key: "totalRevenue",
                                        width: 150,
                                        ...getNumberFilterSorter("totalRevenue"),
                                        ...getPriceRenderer(),
                                        defaultSortOrder: "descend",
                                    },
                                    {
                                        title: "PercRev",
                                        dataIndex: "percRevenue",
                                        key: "percRevenue",
                                        width: 150,
                                        ...getNumberFilterSorter("percRevenue"),
                                        ...getShareRenderer(2),
                                    },
                                    {
                                        title: "Spend",
                                        dataIndex: "totalSpend",
                                        key: "totalSpend",
                                        width: 150,
                                        ...getNumberFilterSorter("totalSpend"),
                                        ...getPriceRenderer(),
                                    },
                                ]}
                                sticky={true}
                                bordered={true}
                                size="small"
                                tableLayout={"fixed"}
                                dataSource={parentData}
                                showSorterTooltip={false}
                                key={"table-uniq-key"}
                                expandable={{
                                    expandedRowRender: (record, index, indent, expanded) => (
                                        <EditableTable<BrandDashboardItemRenderType>
                                            tableId="nested-table"
                                            ref={null}
                                            title={tableTitle}
                                            tableData={record.childrenVars}
                                            columns={columns}
                                            tableKey={index}
                                            key={index}
                                            scroll={{y: "70vh"}}
                                        />
                                    ),
                                    defaultExpandedRowKeys: [],
                                    indentSize: 0,
                                }}
                            />
                        ) : (
                            <EditableTable<BrandDashboardItemRenderType>
                                ref={tableRef}
                                title={tableTitle}
                                tableData={finalTableData}
                                columns={columns}
                                onRowSelectionChange={(rowKeys, availableRowKeys) =>
                                    setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                                }
                            />
                        )}
                    </Col>
                </Row>
            </Spin>
        </>
    );
};

export default BrandItems;
