import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';

const NavButton: React.FC<{ url: string; name: string }> = props => (
    <NavLink to={props.url}>
      <Button>{props.name}</Button>
    </NavLink>
);

export default NavButton;
