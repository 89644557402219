import React from "react";
import {Modal, Space, Typography, Input, Button} from "antd";

interface VerifyLabelDialogProps {
    onVerify: () => void;
    open: boolean;
    onCancel: () => void;
}

const VerifyLabelDialog: React.FC<VerifyLabelDialogProps> = ({open, onCancel, onVerify}) => {
    const [label, setLabel] = React.useState<string>("");

    return (
        <Modal title="Verify Label" open={open} onCancel={onCancel} footer={null}>
            <Space
                direction="vertical"
                styles={{
                    item: {
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                }}
                style={{width: "100%", justifyContent: "center", alignItems: "center"}}
            >
                <Typography.Text>Scan the label and verify if this is the value you expect</Typography.Text>
                <Input
                    size="large"
                    placeholder="Label"
                    styles={{
                        input: {
                            width: "100%",
                        },
                    }}
                    value={label}
                    onChange={(event) => setLabel(event.target.value)}
                />
                <Space style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                    <Button
                        onClick={() => {
                            if (label === "") {
                                return;
                            }
                            onVerify();
                            setLabel("");
                        }}
                        style={{padding: 4, margin: 4}}
                        type="primary"
                    >
                        Approve
                    </Button>
                </Space>
            </Space>
        </Modal>
    );
};

export default VerifyLabelDialog;
