import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Form, Input, message, Modal, Space, theme } from "antd";
import { ColumnType } from "antd/es/table";
import { FilterValue } from "antd/es/table/interface";
import React, { Dispatch, SetStateAction } from "react";
import { useOmega } from "../../../contexts/OmegaContext";
import { copyToClipboard } from "../../omega/ItemBrowser";
import { getColumnTitle } from "../../utilities/TableFilterSorters";
import './Popover.scss';
import { WholesaleDataType } from "./WholesaleColumns";
import { confirmModal } from "./WholesaleUtilities";
const { useToken } = theme;

const UserCollectionsDropdown: React.FC<{
    columns: ColumnType<WholesaleDataType>[],
    allColumns: ColumnType<WholesaleDataType>[],
    setHiddenColumns: Dispatch<SetStateAction<string[]>>,
    filteredInfo: Record<string, FilterValue | null>,
    setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>
}> = ({
    columns,
    allColumns,
    setHiddenColumns,
    setFilteredInfo,
    filteredInfo
}) => {
    const { wsUserCollections, setWSUserCollections } = useOmega();
    const [modal, contextHolder] = Modal.useModal();
    const { token } = useToken();
    const [inputForm] = Form.useForm();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        padding: '8px',
    };

    const menuStyle = {
        boxShadow: 'none',
    };

    return (
        <>
            {contextHolder}
            <Dropdown
                menu={{
                    items: Object.entries(wsUserCollections).map((([col, filters], idx) => ({
                        key: idx,
                        label: col,
                        children: [
                            {
                                key: `${idx}-0`,
                                label: 'Use',
                                onClick: (e) => {
                                    const missingColumns: string[] = Object.keys((filters as { [key: string]: any })).filter((colName) => !columns.find((col: ColumnType<WholesaleDataType>) => col.dataIndex! === colName))
                                    const actualMissingColumns = allColumns.filter((col) => missingColumns.includes(col.dataIndex! as string))

                                    if (actualMissingColumns.length !== missingColumns.length) {
                                        const columnsNotFound = missingColumns.filter((colIndex) => !actualMissingColumns.find((col) => col.dataIndex! === colIndex))
                                        message.error(`Your upload is missing some of the columns [${columnsNotFound.join(', ')}] required by the filter! Those filters will not apply!`)
                                    }

                                    const missingColumnNames = actualMissingColumns.map((col) => getColumnTitle(col))
                                    setHiddenColumns((prev) => prev.filter((col) => !missingColumnNames.includes(col)))
                                    setFilteredInfo(filters)
                                },
                            },
                            {
                                key: `${idx}-1`,
                                label: 'Share',
                                onClick: (e) => {
                                    const filterCopy = wsUserCollections[col];
                                    console.log('share this lule', col, filterCopy, JSON.stringify(filterCopy))
                                    copyToClipboard(JSON.stringify(filterCopy), 'Filter Preset')
                                }
                            },
                            {
                                key: `${idx}-2`,
                                label: 'Delete',
                                onClick: (e) => {
                                    console.log('delete this lule', col)
                                    confirmModal(modal, () => {
                                        const filterCopy = { ...wsUserCollections }
                                        delete filterCopy[col];
                                        setWSUserCollections(filterCopy)
                                    }, 'Do you really want to delete this preset?')
                                }
                            },
                        ]
                    })))
                }}
                dropdownRender={(menu) => (
                    <Space direction="vertical" style={contentStyle} >
                        {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                        <Divider style={{ margin: '6px 0px' }}></Divider>
                        <Button block type="primary" onClick={() => {
                            modal.confirm({
                                title: `Fill in the values for this action`,
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    <Form
                                        form={inputForm}
                                        name="inputFormCustom"
                                        layout="vertical"
                                        initialValues={{ days: 30 }}
                                        onFinish={() => null}
                                    >
                                        <Form.Item
                                            name="colName"
                                            label="What would you like to call your new filter collection?"
                                            rules={[{ required: true, message: 'Filter collection name cannot be blank!' }]}
                                        >
                                            <Input title="Name" />
                                        </Form.Item>
                                    </Form>,
                                onOk() {
                                    inputForm.validateFields().then((values) => {
                                        console.log('bulk form values', values)
                                        const cleanedFilteredInfo = Object.fromEntries(Object.entries(filteredInfo).filter(([_, v]) => v != null));
                                        setWSUserCollections({ ...wsUserCollections, [values.colName]: cleanedFilteredInfo })
                                        inputForm.resetFields();
                                    });
                                },
                                onCancel() {
                                    console.log('Cancelled Bulk');
                                    inputForm.resetFields();
                                },
                            })
                        }}>Save Current Filters</Button>
                        <Button block type="primary" onClick={() => {
                            modal.confirm({
                                title: `Fill in the values for this action`,
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    <Form
                                        form={inputForm}
                                        name="inputFormCustom"
                                        layout="vertical"
                                        initialValues={{ days: 30 }}
                                        onFinish={() => null}
                                    >
                                        <Form.Item
                                            name="colName"
                                            label="What would you like to call your new filter collection?"
                                            rules={[{ required: true, message: 'Filter collection name cannot be blank!' }]}
                                        >
                                            <Input title="Name" />
                                        </Form.Item>
                                        <Form.Item
                                            name="filterValues"
                                            label="Paste your received filter string here"
                                            rules={[{ required: true, message: 'Filter values cannot be blank!' }]}
                                        >
                                            <Input.TextArea title="Filters" />
                                        </Form.Item>
                                    </Form>,
                                onOk() {
                                    inputForm.validateFields().then((values) => {
                                        console.log('bulk form values', values)
                                        console.log('filter values', values.filterValues, JSON.parse(values.filterValues))
                                        setWSUserCollections({ ...wsUserCollections, [values.colName]: JSON.parse(values.filterValues) })
                                        inputForm.resetFields();
                                    }).catch((ex) => {
                                        message.error(`Failed to add Filter Preset, contact Karol with error + the values you've tried to add`)
                                    });
                                },
                                onCancel() {
                                    console.log('Cancelled Bulk');
                                    inputForm.resetFields();
                                },
                            })
                        }}>Add Preset</Button>
                    </Space>
                )}
            >
                <Button>
                    <Space onClick={(e) => e.preventDefault()}>
                        User Collections
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </>
    )
}

export default UserCollectionsDropdown;