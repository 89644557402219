import {
    Button,
    Col,
    Divider,
    InputRef,
    Row,
    Space,
    Spin,
    Switch,
    notification,
} from 'antd';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../contexts/AuthContext';
import {
    deleteFreightLogItemized,
    getFreightLogItemized,
    updateFreightLogItemized,
} from '../../../services/WholesaleService';
import { POSelect } from './POSelect';
import {
    FreightLogItemizedRow,
    FreightLogItemizedRowRenderType,
} from '../../../types/OmegaTypes';
import { EditableColumnType } from '../table/EditableCell';
import {
    makeColumnsEditable,
    makeFreightLogItemizedColumns,
} from '../../utilities/OMSColumns';
import { DeleteFilled } from '@ant-design/icons';
import { UnsavedChangesHandler } from '../../utilities/UnsavedChangesHandler';
import EditableTable from '../table/EditableTable';
import { freightLogItemizedTransformation } from '../../utilities/OMSCalculations';
import { validateFreightLogItemized } from '../../utilities/TableDataValidation';
import { CSVDownload } from '../../utilities/CSVDownload';
import dayjs from 'dayjs';

export const FreightLogItemizedBrowser: React.FC = () => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [editable, setEditable] = useState(false);
    const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
    const [selectablePos, setSelectablePOs] = useState<string[]>([]);
    const [tableData, setTableData] = useState<
        FreightLogItemizedRowRenderType[]
    >([]);
    const [poProducts, setPoProducts] = useState<{
        [key: string]: FreightLogItemizedRow[];
    }>({});
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] =
        useState<Date>(fetchTimestamp);
    const queryClient = useQueryClient();
    const [idsToDelete, setIdsToDelete] = useState(new Set<string>());

    const {
        data: freightLogData,
        isLoading: freightLogDataLoading,
        isRefetching,
    } = useQuery({
        queryKey: ['freight_log_itemized'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const data = await getFreightLogItemized(token);
                return data.map(item => ({
                    ...item,
                    UnitsShipped: item.UnitsShipped || 0,
                    UnitsBackordered: item.UnitsBackordered || 0,
                })) as FreightLogItemizedRow[];
            } else {
                return [];
            }
        },
        staleTime: Infinity,
    });

    const filterTableData = useCallback(() => {
        let newTableData = freightLogData || [];
        const pos = selectedPOs.length > 0 ? selectedPOs : selectablePos;
        if (pos.length > 0) {
            newTableData = pos
                .map(po => poProducts[po])
                .flat()
                .filter(item => item);
        }
        return newTableData;
    }, [selectedPOs, selectablePos, poProducts, freightLogData]);

    useEffect(() => {
        setTableData(
            tableData.filter(item => !idsToDelete.has(item._id || ''))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idsToDelete]);

    const omsMutation = useMutation({
        mutationFn: async (ledgerItems: FreightLogItemizedRow[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error('Auth token not found');
            }

            if (!freightLogData) {
                throw new Error('Freight Log data was not fetched correctly');
            }
            const returnedLedgerData = await updateFreightLogItemized(
                token,
                ledgerItems
            );
            await deleteFreightLogItemized(token, Array.from(idsToDelete));

            return returnedLedgerData;
        },
        onSuccess: returnedData => {
            const originalData = freightLogData;

            if (originalData) {
                const newDataMap = Object.fromEntries<FreightLogItemizedRow>(
                    returnedData.map(nd => [nd._id || '', nd])
                );
                const newData = originalData
                    .map(item => ({
                        ...item,
                        ...newDataMap[item._id || ''],
                    }))
                    .filter(item => !idsToDelete.has(item._id || ''));

                queryClient.setQueryData(['freight_log_itemized'], newData);
            }
            setIdsToDelete(new Set());
            setFetchTimestamp(new Date());
            notification.success({
                message: 'Push successful!',
            });
        },
        onError: error => {
            notification.error({
                message: 'Upload error',
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        if (freightLogData) {
            setFetchTimestamp(new Date());
        }
    }, [freightLogData]);

    const updateTableData = useCallback(() => {
        let newTableData = filterTableData();
        setTableData(newTableData.map((item, idx) => ({ ...item, key: idx })));
        // Picking different POs and suppliers deletes any unsaved changes
        setLastUpdateTimestamp(fetchTimestamp);
    }, [filterTableData, fetchTimestamp]);

    useEffect(() => {
        updateTableData();
    }, [updateTableData]);

    const updateData = (newData: FreightLogItemizedRowRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (
            row: FreightLogItemizedRowRenderType,
            column?: keyof FreightLogItemizedRowRenderType
        ) => {
            const newData = [...tableData];
            const index = newData.findIndex(item => row.key === item.key);
            const item = newData[index];
            const newRow = freightLogItemizedTransformation(item, row, column);
            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            updateData(newData);
        },
        [tableData]
    );

    const columns = useMemo<
        EditableColumnType<FreightLogItemizedRowRenderType>[]
    >(() => {
        const ledgerColumns = makeFreightLogItemizedColumns(searchInputRef);
        if (editable) {
            ledgerColumns.push({
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const newSet = new Set(idsToDelete);
                            newSet.add(record._id || '');
                            setIdsToDelete(newSet);

                            setLastUpdateTimestamp(new Date());
                        }}
                    />
                ),
                fixed: 'right',
                width: '60px',
            });
            return makeColumnsEditable(ledgerColumns, handleSave);
        } else {
            return ledgerColumns;
        }
    }, [handleSave, editable, idsToDelete]);

    const onSaveChanges = () => {
        if (validateFreightLogItemized(tableData).length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const tableTitle = () => (
        <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'end' }}
            split={<Divider type='vertical' />}
        >
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending || isRefetching}
                onDiscardChanges={updateTableData}
                onSaveChanges={onSaveChanges}
            />
            <CSVDownload
                collection={`Itemized_Freight_Export_${dayjs().format(
                    'MM-DD-YY'
                )}`}
                data={tableData || []}
                dateColumns={['Ship_Requested', 'ShipDateBooked', 'Timestamp']}
                isLoading={!tableData}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
        </Space>
    );

    return (
        <Spin spinning={freightLogDataLoading || isRefetching}>
            <POSelect<FreightLogItemizedRow>
                onSelectedPOsChange={pos => setSelectedPOs(pos)}
                onSelectablePOsChange={(pos, allPOsNum) => {
                    // We only want to keep track of POs when specific supplier was selected
                    // If the POs length is equal to the all POs number, no supplier was selected
                    // and we should reset the state
                    if (pos.length < allPOsNum) {
                        setSelectablePOs(pos);
                    } else {
                        setSelectablePOs([]);
                    }
                }}
                onPOProductsChange={products => setPoProducts(products)}
                data={freightLogData}
                disabled={fetchTimestamp < lastUpdateTimestamp}
            />
            <Divider />
            <Row>
                <Col span={24}>
                    <EditableTable<FreightLogItemizedRowRenderType>
                        title={tableTitle}
                        tableData={tableData}
                        columns={columns}
                        loading={freightLogDataLoading}
                    />
                </Col>
            </Row>
        </Spin>
    );
};
