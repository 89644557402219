import React, {useEffect} from "react";
import {Button, Divider, Space, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import NavButton from "./NavButton";
import {useAuth} from "../../contexts/AuthContext";
import {themeConst} from "../../services/constants";

const curTheme = localStorage.theme === themeConst.LIGHT ? themeConst.LIGHT : themeConst.DARK;

const ProjectHeader: React.FC = () => {
    const {logout, currentUser, userData} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (curTheme === themeConst.LIGHT) {
            document.body.style.backgroundColor = "#ffffff";
            document.querySelector(".ant-layout-header")?.setAttribute("style", "background-color: #ffffff; height: auto");
        } else {
            document.body.style.backgroundColor = "#000000";
            document.querySelector(".ant-layout-header")?.setAttribute("style", "background-color: #000000; height: auto");
        }
    }, []);

    // Create a list of options in the app bar
    const signInBar = [
        <Space align="center" direction="horizontal" key="0" wrap={true}>
            <Typography.Title
                style={{
                    marginBottom: "0",
                    marginBlockStart: "0",
                    color: curTheme === themeConst.LIGHT ? "#000000" : "#ffffff",
                }}
                level={5}
            >
                Welcome, {currentUser?.email?.split("@")?.[0]}
            </Typography.Title>
            <Divider type="vertical"></Divider>
            {userData?.role === "ADMIN" || userData?.role?.includes("MANAGER") || userData?.role?.includes("SOURCING") ? (
                <NavButton url="/sourcing" name="Sourcing" key="0" />
            ) : null}
            {/* {userData?.role === 'ADMIN' || userData?.role?.includes('MANAGER') ? <NavButton url='/monitoring' name='Monitoring' key='1' /> : null} */}
            <Divider type="vertical"></Divider>
            {userData?.role === "ADMIN" || userData?.role?.includes("WHOLESALE") || userData?.role?.includes("NOTIFS") ? (
                <NavButton url="/wholesale" name="Wholesale" key="2" />
            ) : null}
            {userData?.role === "ADMIN" || userData?.role?.includes("WHOLESALE") || userData?.role?.includes("BRAND") ? (
                <NavButton url="/brand" name="Brand" key="4" />
            ) : null}
            <Divider type="vertical"></Divider>
            {userData?.role === "ADMIN" || userData?.role?.includes("OMEGA") ? <NavButton url="/omega" name="Omega" key="3" /> : null}
            {userData?.role === "ADMIN" || userData?.role?.includes("OMS") ? <NavButton url="/oms" name="OMS" key="7" /> : null}
            <Divider type="vertical"></Divider>
            <NavButton url="/helpers" name="Helpers" key="6" />
            {userData?.role === "ADMIN" || userData?.role?.includes("WAREHOUSE") ? (
                <NavButton url="/warehouse" name="Warehouse" key="8" />
            ) : null}
            <Divider type="vertical"></Divider>
            <Button
                key="99"
                onClick={() => {
                    if (curTheme === themeConst.DARK) {
                        localStorage.setItem("theme", themeConst.LIGHT);
                    } else {
                        localStorage.setItem("theme", themeConst.DARK);
                    }
                    window.location.reload();
                }}
            >
                {curTheme}
            </Button>
            <Button
                onClick={async () => {
                    try {
                        await logout();
                        navigate("/login");
                    } catch (e) {
                        console.log(e);
                    }
                }}
                key="5"
                style={{display: "flex", justifySelf: "center", alignSelf: "center"}}
            >
                Sign Out
            </Button>
        </Space>,
    ];

    const loginBar = [
        <Space direction="horizontal" key="0">
            <Typography.Title
                style={{
                    marginBottom: "0",
                    marginBlockStart: "0",
                    color: curTheme === themeConst.LIGHT ? "#000000" : "#ffffff",
                }}
                level={5}
            >
                Please log in.
            </Typography.Title>

            <NavButton url="/login" name="Login" key="1" />
        </Space>,
    ];

    return (
        <Space style={{width: "100%", justifyContent: "space-between", maxHeight: "100px"}} align="center">
            <Typography.Title
                style={{
                    marginBottom: "0",
                    marginBlockStart: "0",
                    // color: curTheme === themeConst.LIGHT ? "#000000" : "#ffffff",
                    color: "#ec7339",
                }}
                level={2}
            >
                Project: Suite
            </Typography.Title>
            {(currentUser && signInBar) || loginBar}
        </Space>
    );
};

export default ProjectHeader;
