import React, {useEffect, useState} from "react";
import {FilterDropdownProps} from "antd/lib/table/interface";
import {CopyFilled, DeleteFilled, DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Divider, Select, Space, Switch, Tag} from "antd";
import type {SelectProps} from "antd";
import {copyToClipboard} from "../omega/ItemBrowser";
import {RED} from "./TableFilterSorters";
type TagRender = SelectProps["tagRender"];

interface ExpandedFilterDropdownProps extends FilterDropdownProps {
    selectChildren: string[];
    skipBlank: boolean;
}

const trimInput = (value: string[]) => {
    return value.map((el) => el.trim());
};

const GetSelectLabel = (
    type: string,
    selectChildren: string[],
    selectValues: string[],
    selectFunction: React.Dispatch<React.SetStateAction<string[]>>,
    tagRender?: TagRender
) => {
    return (
        <>
            <div style={{marginBottom: "3px"}}>
                <b>{type}</b>{" "}
                <Button
                    onClick={() => {
                        copyToClipboard(selectValues.join("\n"), type);
                    }}
                    icon={<CopyFilled style={{color: "springgreen"}} />}
                    size="small"
                    shape="round"
                />
                <Button
                    onClick={() => {
                        selectFunction([]);
                    }}
                    icon={<DeleteFilled style={{color: RED}} />}
                    size="small"
                    shape="round"
                />
            </div>
            <Select
                mode="tags"
                tokenSeparators={["\n"]}
                allowClear
                showSearch
                size="large"
                value={selectValues}
                placeholder="Please select your values"
                onChange={(value: string[]) => {
                    selectFunction(trimInput(value));
                }}
                style={{width: 350, minHeight: 40, maxHeight: 250, overflowY: "auto", marginBottom: 8, display: "block"}}
                filterOption={true}
                optionFilterProp="value"
                options={selectChildren.map((child, idx) => ({key: idx, value: child, label: child}))}
                tagRender={tagRender}
            />
        </>
    );
};

export function GetExpandedFilter(
    selectValues: string[],
    dataIndex: string,
    selector?: (record: any) => string | any[],
    strictStringMode: boolean = false,
    skipBlank: boolean = false
) {
    const GetValue = (record: any, dataIndex: string, selector?: (record: any) => string | any[]): string | any[] => {
        if (selector) {
            return selector(record);
        } else {
            const currValue = record[dataIndex];
            if (typeof currValue === "number") {
                return currValue.toString();
            } else {
                return currValue;
            }
        }
    };

    return {
        filterDropdown: (props: FilterDropdownProps) => (
            <ExpandedFilterDropdown selectChildren={selectValues} skipBlank={skipBlank} {...props} />
        ),
        onFilter: (value: any, record: any) => {
            const filters: string[] = JSON.parse(value);
            const skipBlank = filters.filter((issue) => issue.includes("[BLANK]")).length > 0;
            const onlyBlank = filters.filter((issue) => issue.includes("[ONLY-BLANK]")).length > 0;

            const currValue = GetValue(record, dataIndex, selector);

            if (onlyBlank) {
                if (currValue === undefined || currValue === null || currValue.length === 0) return true;
                return false;
            }

            if (skipBlank) {
                if (currValue === undefined || currValue === null || currValue.length === 0) return false;
            }

            if (Array.isArray(currValue)) {
                return (
                    filters.filter((issue) => issue.includes("[AND]")).every((issue) => currValue.includes(issue.replace("[AND]", ""))) &&
                    (filters.filter((issue) => issue.includes("[OR]")).length === 0 ||
                        filters.filter((issue) => issue.includes("[OR]")).some((issue) => currValue.includes(issue.replace("[OR]", "")))) &&
                    !filters.filter((issue) => issue.includes("[NOT]")).some((issue) => currValue.includes(issue.replace("[NOT]", "")))
                );
            } else if (typeof currValue === "string") {
                if (strictStringMode) {
                    return (
                        filters
                            .filter((issue) => issue.includes("[AND]"))
                            .every((issue) => (currValue as string).toLowerCase() === issue.replace("[AND]", "").toLowerCase()) &&
                        (filters.filter((issue) => issue.includes("[OR]")).length === 0 ||
                            filters
                                .filter((issue) => issue.includes("[OR]"))
                                .some((issue) => (currValue as string).toLowerCase() === issue.replace("[OR]", "").toLowerCase())) &&
                        !filters
                            .filter((issue) => issue.includes("[NOT]"))
                            .some((issue) => (currValue as string).toLowerCase() === issue.replace("[NOT]", "").toLowerCase())
                    );
                } else {
                    return (
                        filters
                            .filter((issue) => issue.includes("[AND]"))
                            .every((issue) => (currValue as string).toLowerCase().includes(issue.replace("[AND]", "").toLowerCase())) &&
                        (filters.filter((issue) => issue.includes("[OR]")).length === 0 ||
                            filters
                                .filter((issue) => issue.includes("[OR]"))
                                .some((issue) => (currValue as string).toLowerCase().includes(issue.replace("[OR]", "").toLowerCase()))) &&
                        !filters
                            .filter((issue) => issue.includes("[NOT]"))
                            .some((issue) => (currValue as string).toLowerCase().includes(issue.replace("[NOT]", "").toLowerCase()))
                    );
                }
            } else {
                return true;
            }
        },
    };
}

export function ExpandedFilterDropdown(props: ExpandedFilterDropdownProps) {
    const [selectANDValues, setSelectANDValues] = useState<string[]>([]);
    const [selectORValues, setSelectORValues] = useState<string[]>([]);
    const [selectNOTValues, setSelectNOTValues] = useState<string[]>([]);
    const [skipBlank, setSkipBlank] = useState<boolean>(props.skipBlank);
    const [onlyBlank, setOnlyBlank] = useState<boolean>(false);

    useEffect(() => {
        if (props.selectedKeys && props.selectedKeys.length > 0) {
            const filters: string[] = JSON.parse(props.selectedKeys[0] as string);
            setSelectORValues(filters.filter((val) => val.includes("[OR]")).map((val) => val.replace("[OR]", "")));
            setSelectANDValues(filters.filter((val) => val.includes("[AND]")).map((val) => val.replace("[AND]", "")));
            setSelectNOTValues(filters.filter((val) => val.includes("[NOT]")).map((val) => val.replace("[NOT]", "")));
            setSkipBlank(filters.filter((val) => val.includes("[BLANK]")).length > 0);
            setOnlyBlank(filters.filter((val) => val.includes("[ONLY-BLANK]")).length > 0);
        }
    }, [props]);

    const tagRender: TagRender = (props) => {
        const {label, value, closable, onClose} = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{padding: 3}}>
                <DeleteOutlined
                    onClick={() => {
                        setSelectANDValues(selectANDValues.filter((val) => val !== value));
                        setSelectORValues(selectORValues.filter((val) => val !== value));
                        setSelectNOTValues((prevValues) => Array.from(new Set([...prevValues, value])));
                    }}
                ></DeleteOutlined>{" "}
                {label}
            </Tag>
        );
    };

    return (
        <div style={{padding: 8}}>
            {GetSelectLabel("AND", props.selectChildren, selectANDValues, setSelectANDValues, tagRender)}
            {GetSelectLabel("OR", props.selectChildren, selectORValues, setSelectORValues, tagRender)}
            {GetSelectLabel("EXCLUDE", props.selectChildren, selectNOTValues, setSelectNOTValues)}
            <Divider style={{margin: "8px 0px"}} />
            <Space>
                <Switch
                    checkedChildren="Skip Blank"
                    unCheckedChildren="Allow Blank"
                    checked={skipBlank}
                    onChange={(val) => setSkipBlank(val)}
                />
                <Switch
                    checkedChildren="Only Blank"
                    unCheckedChildren="Allow Filled"
                    checked={onlyBlank}
                    onChange={(val) => setOnlyBlank(val)}
                />
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={() => {
                        console.log(selectANDValues, selectORValues, selectNOTValues, skipBlank, onlyBlank);
                        const filterArray = [
                            ...selectORValues.map((issue) => `[OR]${issue}`),
                            ...selectANDValues.map((issue) => `[AND]${issue}`),
                            ...selectNOTValues.map((issue) => `[NOT]${issue}`),
                        ];

                        if (skipBlank) {
                            filterArray.push("[BLANK]sure");
                        }

                        if (onlyBlank) {
                            filterArray.push("[ONLY-BLANK]sure");
                        }

                        const filterString = JSON.stringify(filterArray);
                        props.setSelectedKeys([filterString]);
                        props.confirm({closeDropdown: true});
                    }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        props.clearFilters?.();
                        props.confirm();
                        setSelectORValues([]);
                        setSelectANDValues([]);
                        setSelectNOTValues([]);
                        setSkipBlank(false);
                        setOnlyBlank(false);
                    }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    );
}
