import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Row, Col, Divider, Input, Spin, Space, Typography, Button, TabsProps, Tabs, notification} from "antd";
import {OMSItem} from "../../../types/OmegaTypes";
import {POSelect} from "../../omega/oms/POSelect";
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {getOMS, getOMSPOs} from "../../../services/WholesaleService";
import RemainingItemsDialog from "./RemainingItemsDialog";
import {
    fetchProductsData,
    fetchInvData,
    getSearchType,
    getBatchCategory,
    parseBatches,
    parseOMSProducts,
    checkUpcNotifs,
    searchByFNSKU,
    searchByLPN,
} from "./helpers";
import {Batch, BatchCategory, UPCNotif, WarehouseProduct, batchCategories} from "../../../types/WarehouseTypes";
import ExtractedItemsDialog from "./ExtractedItemsDialog";
import {deleteReceivedProducts, getMappings, getReceivedBatches, saveReceivedProducts} from "../../../services/WarehouseService";
import BatchTable from "./BatchTable";
import MoveItemDialog from "./MoveItemDialog";
import SelectInvDataDialog from "./SelectInvDataDialog";
import NotificationDialog from "./NotificationDialog";
import PrintHandler from "./PrintHandler";
import {useStore} from "../../../store/useStore";

const ReceivingDashboard: React.FC = () => {
    const {currentUser} = useAuth();
    const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
    const {printerLoading, handlePrintLabels, printAutomatically} = useStore((state) => ({
        printerLoading: state.printerLoading,
        handlePrintLabels: state.handlePrint,
        printAutomatically: state.printAutomatically,
    }));
    const [scannedItems, setScannedItems] = useState<{
        [key in BatchCategory]?: WarehouseProduct[];
    }>({HazMat: []});
    const [extractedItems, setExtractedItems] = useState<WarehouseProduct[]>([]);
    const [showRemainingItems, setShowRemainingItems] = useState(false);
    const [showExtractedItems, setShowExtractedItems] = useState(false);
    const [showMoveItemDialog, setShowMoveItemDialog] = useState(false);
    const [itemToEdit, setItemToEdit] = useState<WarehouseProduct | undefined>(undefined);
    const [code, setCode] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState<UPCNotif[]>([]);

    const {data: omsData} = useQuery({
        queryKey: ["oms_data", selectedPOs.join(",")],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const poString = selectedPOs
                    .map((identifier) =>
                        identifier
                            .split(" -- PO: ")[1]
                            .split(" -- SO: ")
                            .filter((el) => el !== "?")
                            .pop()
                    )
                    .join(",");
                const data = await getOMS(token, poString, "warehouse");
                return data;
            } else {
                return [];
            }
        },
        staleTime: Infinity,
        enabled: !!(selectedPOs && selectedPOs.length > 0),
    });

    const {
        data: poData,
        isLoading: poListLoading,
        isRefetching: poListRefetching,
    } = useQuery({
        queryKey: ["oms_dashboard_data"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const data = await getOMSPOs(token);
                return data;
            } else {
                return [];
            }
        },
        staleTime: Infinity,
    });

    const {data: batches} = useQuery({
        queryKey: ["batches", currentUser?.uid],
        queryFn: async () => {
            const data = await getReceivedBatches();
            return data;
        },
    });

    const scannedList = useMemo(() => {
        return Object.values(scannedItems).flat();
    }, [scannedItems]);

    const scannedPONumber = useMemo(() => {
        return scannedList.filter((item) => omsData?.find((poItem) => poItem.ASIN === item.asin)).length;
    }, [omsData, scannedList]);

    const parseBatchesCallback = useCallback((batches: Batch[]) => {
        const items = parseBatches(batches);
        setScannedItems(items);
    }, []);

    const saveProducts = async (items: WarehouseProduct[]) => {
        await saveReceivedProducts(items);
    };

    const deleteProducts = async (items: {asin: string; batchId: string}[]) => {
        await deleteReceivedProducts(items);
    };

    useEffect(() => {
        if (batches) {
            parseBatchesCallback(batches);
        }
    }, [batches, parseBatchesCallback]);

    const upcAndAsinSearch = async (code: string, identifiersType: "UPC" | "ASIN") => {
        setLoading(true);
        if (identifiersType === "UPC") {
            const mappings = await getMappings([code], "upc");
            if (mappings && mappings.length > 0) {
                code = mappings[0].asin;
                identifiersType = "ASIN";
            }
        }

        const items = await fetchProductsData(code, identifiersType);
        const invProducts = await fetchInvData(items);

        let products: WarehouseProduct[] = [];

        for (const p of invProducts) {
            for (const id of p.invData) {
                products.push({
                    ...p,
                    quantity: id.quantity,
                    invQuantity: id.quantity,
                    condition: id.condition,
                    invData: p.invData,
                    sku: id.sku,
                    fnsku: id.fnsku,
                });
            }
        }
        products = products.map((product) => {
            return {
                ...product,
                batchCategory: getBatchCategory(product),
            };
        });

        // For each product find the supplier PO it comes from
        products = products.map((product) => {
            const po = omsData?.find((item) => item.ASIN === product.asin);
            if (po) {
                product.supplierPO = po.Supplier_PO;
            }
            return product;
        });

        if (products.length === 0) {
            throw new Error("No MSKU found");
        }

        setExtractedItems(products);
        setShowExtractedItems(true);
        setCode("");
        setLoading(false);
    };

    const handleScan = async (code: string) => {
        const searchType = getSearchType(code);
        if (searchType === "UPC" || searchType === "ASIN") {
            await upcAndAsinSearch(code, searchType);
        } else if (searchType === "FNSKU") {
            const asin = await searchByFNSKU(code);
            await upcAndAsinSearch(asin, "ASIN");
        } else if (searchType === "LPN") {
            const asin = await searchByLPN(code);
            await upcAndAsinSearch(asin, "ASIN");
        } else {
            notification.error({
                message: "Invalid code",
                description: "Please enter a valid UPC or ASIN",
            });
        }
    };

    const handleNotifications = async (items: WarehouseProduct[]) => {
        try {
            const notifs = await checkUpcNotifs(items);

            if (notifs.length > 0) {
                setNotifications(notifs);
                setExtractedItems(items);
            } else {
                await updateItems(items);
            }
        } catch (e: any) {
            notification.error({
                message: "Error",
                description: e.message,
            });
        }
        setLoading(false);
    };

    const updateItems = async (items: WarehouseProduct[]) => {
        if (items.length === 0) {
            return;
        }

        const groups: {[key in BatchCategory]?: WarehouseProduct[]} = {...scannedItems};
        const groupIds: {[key in BatchCategory]?: string} = Object.fromEntries(
            batchCategories.map((category) => [category, batches?.find((batch) => batch.category === category)?._id])
        );
        const asinToQty: {[key: string]: number} = {};
        for (const item of scannedList) {
            asinToQty[item.asin + item.batchCategory] = item.quantity;
        }

        if (items.length === 1 && printAutomatically) {
            handlePrintLabels(items[0], items[0].quantity);
        }

        let limitReached = false;
        for (const item of items) {
            const batchCategory: BatchCategory = item.batchCategory || "Miscellaneous goods";
            // Find batch ID
            const batchId = groupIds[batchCategory];

            if (batchId) {
                item.batchId = batchId;
                // Find the product in the scanned items
                item.quantity = item.quantity + (asinToQty[item.asin + batchCategory] || 0);

                const group = groups[batchCategory];

                if (asinToQty[item.asin + batchCategory]) {
                    // If the item already exists, update the quantity and put it at the top of the list
                    groups[batchCategory] = group ? [item, ...group.filter((scannedItem) => scannedItem.asin !== item.asin)] : [item];
                } else {
                    if (group && group.length > 200) {
                        limitReached = true;
                        break;
                    }
                    // If the item does not exist, add it to the scanned items
                    groups[batchCategory] = [item, ...(group || [])];
                }
            }
        }

        setScannedItems(groups);
        if (limitReached) {
            notification.warning({
                message: "Limit reached",
                description: "You have reached the limit of 200 items per batch.",
            });
        } else {
            notification.success({
                message: "Items added",
                description: `Successfully added ${items.length} item(s) to the batches`,
            });
        }

        await saveProducts(items);
    };

    const handlePOItems = async (items: OMSItem[]) => {
        const parsed = await parseOMSProducts(items);

        await handleNotifications(parsed);
    };

    const globalIitems: TabsProps["items"] = useMemo(
        () =>
            batchCategories
                .filter((category) => (scannedItems[category] || []).length > 0)
                .map((category) => {
                    const items = scannedItems[category] || [];
                    const units = items.reduce((acc, item) => acc + item.quantity, 0);
                    return {
                        key: category,
                        label: `${category} (${items.length} SKUs, ${units} units)`,
                        children: (
                            <BatchTable
                                items={items}
                                onItemDelete={(asin) => {
                                    const item = items.find((item) => item.asin === asin);
                                    if (item) {
                                        deleteProducts([{asin, batchId: item.batchId || ""}]);
                                    }
                                    setScannedItems((prevState) => {
                                        const updatedItems = prevState[category]?.filter((item) => item.asin !== asin);
                                        return {
                                            ...prevState,
                                            [category]: updatedItems,
                                        };
                                    });
                                }}
                                onItemQuantityChange={(asin, quantity) => {
                                    const items = scannedItems[category] || [];
                                    const index = items.findIndex((item) => item.asin === asin);
                                    if (index > -1) {
                                        items[index] = {
                                            ...items[index],
                                            quantity,
                                        };
                                        // We only want to save this item
                                        saveProducts([items[index]]);
                                        setScannedItems((prevState) => {
                                            return {
                                                ...prevState,
                                                [category]: [...items],
                                            };
                                        });
                                    }
                                }}
                                onMoveItem={(item) => {
                                    setExtractedItems([item]);
                                    setShowMoveItemDialog(true);
                                }}
                                onInvSelect={(asin) => {
                                    const item = items.find((item) => item.asin === asin);
                                    if (item) {
                                        setItemToEdit(item);
                                    }
                                }}
                                onPrintLabels={(item, quantity) => {
                                    handlePrintLabels(item, quantity);
                                }}
                            />
                        ),
                    };
                }),
        [scannedItems, handlePrintLabels]
    );

    return (
        <Spin spinning={poListLoading || poListRefetching || loading || printerLoading}>
            <Row justify={"center"}>
                <Col span={8}>
                    <Input
                        size="large"
                        placeholder="Scan items"
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        onPressEnter={async () => {
                            try {
                                setLoading(true);

                                await handleScan(code);
                            } catch (e: any) {
                                notification.error({
                                    message: "Error",
                                    description: e.message,
                                });
                            }
                            setLoading(false);
                        }}
                    />
                    <Space direction="vertical" style={{width: "100%", marginTop: 4}}>
                        <PrintHandler showAutoPrintSwitch />
                        {omsData && (
                            <Row style={{justifyContent: "end", alignItems: "center"}}>
                                <Typography.Text>
                                    PO Items: {scannedPONumber} / {omsData?.length}
                                </Typography.Text>
                                <Button style={{marginLeft: 8}} onClick={() => setShowRemainingItems(true)}>
                                    Remaining Items
                                </Button>
                            </Row>
                        )}
                    </Space>
                </Col>
                <Col span={15} offset={1}>
                    <POSelect<OMSItem>
                        onSelectedPOsChange={(pos) => setSelectedPOs(pos)}
                        onPOProductsChange={(products) => {}}
                        data={poData}
                        disabled={!(poData && poData.length > 0)}
                    />
                </Col>
            </Row>
            <Divider />

            <Tabs centered defaultActiveKey={batchCategories[0]} items={globalIitems} />

            <RemainingItemsDialog
                open={showRemainingItems}
                items={omsData || []}
                scannedItems={scannedList}
                onClose={() => setShowRemainingItems(false)}
                onItemSelect={(item) => {
                    if (item.ASIN) {
                        setShowRemainingItems(false);
                        handleScan(item.ASIN);
                    } else {
                        notification.error({
                            message: "No ASIN found",
                            description: "This item does not have an ASIN",
                        });
                    }
                }}
                onAllItemsSelect={async (items) => {
                    setShowRemainingItems(false);
                    setLoading(true);
                    try {
                        await handlePOItems(items);
                    } catch (e) {
                        notification.error({
                            message: "Error",
                            description: "There was an error processing the items",
                        });
                    }
                    setLoading(false);
                }}
            />

            <ExtractedItemsDialog
                onClose={() => setShowExtractedItems(false)}
                items={extractedItems}
                open={showExtractedItems}
                onOk={(item) => {
                    setLoading(true);
                    setShowExtractedItems(false);
                    handleNotifications([item]);
                }}
            />
            <MoveItemDialog
                open={showMoveItemDialog && extractedItems.length === 1}
                onCancel={() => setShowMoveItemDialog(false)}
                item={extractedItems[0]}
                onMove={(item, batchCategory) => {
                    if (item.batchCategory === batchCategory) {
                        notification.error({
                            message: "Same category",
                            description: "This item is already in the selected category",
                        });
                        return;
                    }
                    const prevCategory = item.batchCategory;
                    const batchId = batches?.find((batch) => batch.category === batchCategory)?._id;

                    if (!batchId) {
                        notification.error({
                            message: "No batch found",
                        });
                        return;
                    }
                    const newItem = {...item, batchCategory, batchId};

                    setScannedItems((prevState) => {
                        const newState = {...prevState, [batchCategory]: [...(prevState[batchCategory] || []), newItem]};
                        if (prevCategory) {
                            const prevItems = prevState[prevCategory]?.filter((i) => i.asin !== item.asin);
                            newState[prevCategory] = prevItems;
                        }
                        return newState;
                    });
                    setShowMoveItemDialog(false);
                    saveProducts([newItem]);
                    deleteProducts([{asin: item.asin, batchId: item.batchId || ""}]);
                }}
            />
            <SelectInvDataDialog
                item={itemToEdit}
                onClose={() => setItemToEdit(undefined)}
                onSave={(data) => {
                    setScannedItems((prevState) => {
                        const items = prevState[data.batchCategory || "Miscellaneous goods"] || [];
                        const index = items.findIndex((item) => item.asin === data.asin);
                        if (index > -1) {
                            items[index] = data;
                        }
                        return {
                            ...prevState,
                            [data.batchCategory || "Miscellaneous goods"]: [...items],
                        };
                    });
                    saveProducts([data]);
                    setItemToEdit(undefined);
                }}
                open={!!itemToEdit}
            />
            <NotificationDialog
                open={notifications.length > 0}
                notifications={notifications}
                items={extractedItems}
                onClose={() => setNotifications([])}
                onFinish={(items) => {
                    updateItems(items);
                    setNotifications([]);
                }}
            />
        </Spin>
    );
};

export default ReceivingDashboard;
