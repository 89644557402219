import {Col, Divider, Row, Tabs, TabsProps, Typography} from "antd";
import React from "react";
import BrandValues from "./BrandValues";
import {useTabLocationDashboard} from "./utilities/queryTab";
import BrandJournal from "./BrandJournal";
import MultiNoteEditor from "./MultiNoteEditor";

const items: TabsProps["items"] = [
    {
        key: "values",
        label: `Strategist Values`,
        children: <BrandValues valuesLayout="default"></BrandValues>,
    },
    {
        key: "new_values",
        label: `Buyer Values`,
        children: <BrandValues valuesLayout="experimental"></BrandValues>,
    },
    {
        key: "notes",
        label: `Strategist's notes`,
        children: (
            <Row>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Notes
                    </Typography.Title>
                    <MultiNoteEditor title="notes"></MultiNoteEditor>
                </Col>
                <Col span={2} style={{display: "flex", justifyContent: "center", alignContent: "center", width: "100%"}}>
                    <Divider type="vertical" style={{height: "100%"}} />
                </Col>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Strategy
                    </Typography.Title>
                    <MultiNoteEditor title="strategy"></MultiNoteEditor>
                </Col>
            </Row>
        ),
    },
    {
        key: "buyers_notes",
        label: `Buyers' notes`,
        children: (
            <Row>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Buyers' notes
                    </Typography.Title>
                    <MultiNoteEditor title="buyers_notes"></MultiNoteEditor>
                </Col>
                <Col span={2} style={{display: "flex", justifyContent: "center", alignContent: "center", width: "100%"}}>
                    <Divider type="vertical" style={{height: "100%"}} />
                </Col>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Journal Entries
                    </Typography.Title>
                    <BrandJournal></BrandJournal>
                </Col>
            </Row>
        ),
    },
    {
        key: "purchasing_guide",
        label: `Purchasing guide`,
        children: <MultiNoteEditor title="guide"></MultiNoteEditor>,
    },
    {
        key: "journal",
        label: `Journal`,
        children: (
            <Row>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Journal
                    </Typography.Title>
                    <MultiNoteEditor title="journal"></MultiNoteEditor>
                </Col>
                <Col span={2} style={{display: "flex", justifyContent: "center", alignContent: "center", width: "100%"}}>
                    <Divider type="vertical" style={{height: "100%"}} />
                </Col>
                <Col span={11}>
                    <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
                        Journal Entries
                    </Typography.Title>
                    <BrandJournal></BrandJournal>
                </Col>
            </Row>
        ),
    },
    // {
    //     key: "experimental_notes",
    //     label: "Notes V2",
    //     children: (
    //         <Row>
    //             <Col span={11}>
    //                 <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
    //                     Journal
    //                 </Typography.Title>
    //                 <MultiNoteEditor title="journal"></MultiNoteEditor>
    //             </Col>
    //             <Col span={2} style={{display: "flex", justifyContent: "center", alignContent: "center", width: "100%"}}>
    //                 <Divider type="vertical" style={{height: "100%"}} />
    //             </Col>
    //             <Col span={11}>
    //                 <Typography.Title style={{textAlign: "center", marginBlockStart: "0px"}} level={4}>
    //                     Journal Entries
    //                 </Typography.Title>
    //                 <BrandJournal></BrandJournal>
    //             </Col>
    //         </Row>
    //     ),
    // },
];

const BrandInformation: React.FC = () => {
    const [activeKey, onChange] = useTabLocationDashboard(
        items.map((item) => item.key),
        "values",
        "brand_info_tab"
    );

    return (
        <Row>
            <Col span={24}>
                <Tabs items={items} activeKey={activeKey} onChange={onChange}></Tabs>
            </Col>
        </Row>
    );
};

export default BrandInformation;
