import { useQuery } from "@tanstack/react-query";
import { Button, Col, Divider, Row, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getBrandInsightSummaries, getBrandInsightUploads } from "../../services/BrandService";
import { getNumberFilterSorter, getNumberRenderer, getPriceRenderer, getShareRenderer } from "../utilities/TableFilterSorters";
import { ColumnsType } from "antd/es/table";

const columns: ColumnsType<any> = [
    {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
        defaultSortOrder: 'descend',
        render: (text: string) => text.split('_')[0]
    },
    {
        title: 'Supplier',
        dataIndex: 'Date',
        key: 'Date',
        defaultSortOrder: 'descend',
        render: (text: string) => text.split('_')[1]
    },
    {
        title: 'Parent Count',
        dataIndex: 'Parent Count',
        key: 'Parent Count',
        ...getNumberFilterSorter('Parent Count'),
    },
    {
        title: 'Child Count',
        dataIndex: 'Child Count',
        key: 'Child Count',
        ...getNumberFilterSorter('Child Count'),
        ...getNumberRenderer(0),
    },
    {
        title: 'Stocked Children',
        dataIndex: 'Stocked Children',
        key: 'Stocked Children',
        ...getNumberFilterSorter('Stocked Children'),
        ...getNumberRenderer(0),
    },
    {
        title: 'Expected Unit Sales',
        dataIndex: 'Expected Unit Sales',
        key: 'Expected Unit Sales',
        ...getNumberFilterSorter('Expected Unit Sales'),
        ...getNumberRenderer(0),
    },
    {
        title: 'Our Unit Sales',
        dataIndex: 'Our Unit Sales',
        key: 'Our Unit Sales',
        ...getNumberFilterSorter('Our Unit Sales'),
        ...getNumberRenderer(0),
    },
    {
        title: 'Expected Sales',
        dataIndex: 'Expected Sales',
        key: 'Expected Sales',
        ...getNumberFilterSorter('Expected Sales'),
        ...getPriceRenderer(),
    },
    {
        title: 'Our Sales',
        dataIndex: 'Our Sales',
        key: 'Our Sales',
        ...getNumberFilterSorter('Our Sales'),
        ...getPriceRenderer(),
    },
    {
        title: 'Market Share',
        dataIndex: 'Market Share',
        key: 'Market Share',
        ...getNumberFilterSorter('Market Share'),
        ...getShareRenderer(2),
    },
]

const BrandInsightsSummary: React.FC = () => {
    const { currentUser } = useAuth();
    const [selectedCollections, setSelectedCollections] = useState<string[]>([])
    const [parentProducts, setParentProducts] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const { data: brandInsightsCollections } = useQuery({
        queryKey: ['brandInsightsCollections'], 
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const names = await getBrandInsightUploads(token)
            return names;
        },
        staleTime: 120
    })

    useEffect(() => {
        const fetchData = async (collections: string[]) => {
            setIsLoading(true)
            const token = await currentUser!.getIdToken();
            const data = await getBrandInsightSummaries(token, collections.join(','))

            // console.log(data);
            setParentProducts(data)
            setIsLoading(false)
        }

        if (selectedCollections.length > 0) {
            fetchData(selectedCollections)
        }

    }, [selectedCollections, currentUser])

    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Select
                    style={{ minWidth: '100%' }}
                    placeholder="Select Collection"
                    mode="multiple"
                    options={(brandInsightsCollections ?? []).map((uploadName: string) => ({
                        value: uploadName,
                        label: uploadName,
                    }))}
                    value={selectedCollections}
                    filterOption={(input, option) =>
                        (option?.value?.toString().toLowerCase().indexOf(input.toLowerCase()) || 1) >= 0
                    }
                    onChange={(val) => {
                        setSelectedCollections(val)
                    }}
                    searchValue={searchText}
                    onSearch={value => {
                        setSearchText(value)
                    }}
                    allowClear={true}
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        const searchInput: string = searchText.toLowerCase();

                                        const newColls = brandInsightsCollections.filter((coll: string) => coll.toLowerCase().includes(searchInput))

                                        setSelectedCollections([...selectedCollections, ...newColls]);
                                    }}
                                >
                                    Select filtered
                                </Button>
                                <Button
                                    type='primary'
                                    onClick={() => setSelectedCollections([])}
                                >
                                    Select none
                                </Button>
                            </Space>
                        </>
                    )}
                />
            </Col>
            <Col span={24}>
                <Table
                    loading={isLoading}
                    columns={columns}
                    sticky={true}
                    bordered={true}
                    size="small"
                    tableLayout={"auto"}
                    dataSource={parentProducts}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100'],
                        defaultPageSize: 25,
                    }}
                    rowKey={(record) => `${record.Date}`}
                    showSorterTooltip={false}
                    scroll={{ y: "82vh", x: "max-content" }}
                />
            </Col>
        </Row>
    )
}

export default BrandInsightsSummary;