import { UploadOutlined } from '@ant-design/icons';
import { Button, Space, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import React from 'react';

interface UploadHandlerProps {
    onComplete: (value: any) => void;
    template?: string[];
    fileName?: string;
    arrayMode?: boolean;
    acceptedFileTypes?: string;
}

export const UploadHandler: React.FC<UploadHandlerProps> = ({
    onComplete,
    template,
    fileName = 'stage_01_template.csv',
    arrayMode = false,
    acceptedFileTypes = '.tsv,.csv',
}) => {
    const [uploading, setUploading] = React.useState(false);
    const [fileList, setFileList] = React.useState<RcFile[]>([]);

    const beforeUpload = (file: RcFile) => {
        setFileList((fileList) => [...fileList, file]);
        return false;
    };

    const onRemoveBrand = (file: UploadFile) => {
        const newFileList = fileList.filter(el => el.uid !== file.uid);
        setFileList(newFileList);
    };

    const handleUpload = () => {
        setUploading(true);
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        if (arrayMode) {
            let filesPromises = fileList.map(file => {
                let reader = new FileReader();
                return new Promise(resolve => {
                    reader.onload = () => resolve({
                        filename: file.name,
                        result: reader.result
                    });
                    reader.readAsArrayBuffer(file as Blob)
                });
            });
    
            Promise.all(filesPromises).then((res) => {
                setFileList([]);
                setUploading(false);
                onComplete(res);
            })
        } else {
            const reader = new FileReader();
            reader.onload = async e => {
                const text = e.target!.result;
                onComplete(text as string);
            };
            
            reader.readAsText(fileList[0]);
            setFileList([]);
            setUploading(false);
        }
    };

    const download = () => {
        if (!template || !template.length) return;

        const text = template.join(',');

        const file = new Blob([text], { type: 'text/plain;charset=utf-8' });
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
        a.parentNode?.removeChild(a);
    };

    return (
        <Space direction='horizontal' align='start'>
            {template && <Button onClick={download}>Download template</Button>}
            <Button
                type='primary'
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
            >
                {uploading ? 'Uploading' : 'Start upload'}
            </Button>
            <Upload
                beforeUpload={beforeUpload}
                onRemove={onRemoveBrand}
                accept={acceptedFileTypes}
                multiple={true}
                listType='text'
                fileList={fileList}
            >
                <Button block icon={<UploadOutlined />}>
                    Click to upload
                </Button>
            </Upload>
        </Space>
    );
};
