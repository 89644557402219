import React, {useEffect, useState} from "react";
import {Table, Button, Input, Popconfirm, Form, Spin, Space, notification} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {addUpcMapping, deleteUpcMapping, getMappings} from "../../services/WarehouseService";
import {UPCMapping} from "../../types/WarehouseTypes";

const UPCMappingDashboard: React.FC = () => {
    const [mappings, setMappings] = useState<UPCMapping[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [form] = Form.useForm();

    const handleDelete = async (upc: string, asin: string) => {
        setIsSaving(true);
        try {
            await deleteUpcMapping(upc, asin);
            setMappings(mappings.filter((item) => !(item.upc === upc && item.asin === asin)));
        } catch (e: any) {
            notification.error({message: "Error deleting mapping", description: e.message});
        }
        setIsSaving(false);
    };

    const handleAdd = async () => {
        setIsSaving(true);
        const values = await form.validateFields();
        const newMapping = await addUpcMapping(values.upc, values.asin);
        setMappings([...mappings, newMapping]);
        form.resetFields();
        setIsSaving(false);
    };

    const {data, isLoading, isRefetching, error} = useQuery({
        queryKey: ["upc_mappings"],
        queryFn: async () => {
            const mappings = await getMappings(undefined, undefined);
            return mappings;
        },

        staleTime: Infinity,
    });

    useEffect(() => {
        if (error) {
            notification.error({message: "Error fetching mappings", description: error.message});
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            setMappings(data);
        }
    }, [data]);

    const columns = [
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "Who added?",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Action",
            key: "action",
            render: (_: any, record: UPCMapping) => (
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.upc, record.asin)}>
                    <Button type="link">Delete</Button>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Spin spinning={isLoading || isRefetching}>
            <Space
                style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 8,
                }}
            >
                <Form form={form} layout="inline" onFinish={handleAdd}>
                    <Form.Item name="upc" rules={[{required: true, message: "Please input UPC!"}]}>
                        <Input placeholder="UPC" />
                    </Form.Item>

                    <Form.Item name="asin" rules={[{required: true, message: "Please input ASIN!"}]}>
                        <Input placeholder="ASIN" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" icon={<PlusOutlined />} loading={isSaving}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
            <Table columns={columns} dataSource={mappings} rowKey={(row) => row.asin + row.upc} />
        </Spin>
    );
};

export default UPCMappingDashboard;
