import React from "react";
import {Button, Divider, Select, SelectProps, Space} from "antd";

interface SelectWithButtonProps extends SelectProps {
    onClick: () => void;
    buttonTitle: string;
}

/**
 * Component that renders an additional button as part of the Select component.
 * @param props
 * @returns
 */
const SelectWithButton: React.FC<SelectWithButtonProps> = ({onClick, buttonTitle, ...props}) => {
    return (
        <Select
            {...props}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{margin: "8px 0"}} />
                    <Space style={{width: "100%"}} direction="vertical" align="center">
                        <Button onClick={onClick}>{buttonTitle}</Button>
                    </Space>
                </>
            )}
        />
    );
};

export default SelectWithButton;
