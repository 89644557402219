import { FilterValue } from "antd/es/table/interface";
import React, { useContext, useEffect, useState } from "react";
import { NoteItem, ReplenItem, ReplenParams } from "../types/OmegaTypes";

const DEFAULT_REPLEN_PARAMS = {
    antBBShare: 1,
    targetDIS: 75,
    antVelocity: 1,
    desiredRoi: 25,
    canCancel: false,
}

const OmegaContext = React.createContext<{
    changesMade: NoteItem[];
    setChangesMade: (changes: NoteItem[]) => void;
    userCollections: {[key: string]: Record<string, FilterValue | null>};
    setUserCollections: (collections: {[key: string]: Record<string, FilterValue | null>}) => void;
    wsUserCollections: {[key: string]: Record<string, FilterValue | null>};
    setWSUserCollections: (collections: {[key: string]: Record<string, FilterValue | null>}) => void;
    wsUserColumnPresets: Record<string, string[]>;
    setWSUserColumnPresets: (collections: Record<string, string[]>) => void;
    replenData: {[sku: string]: ReplenItem};
    setReplenData: (replen: {[sku: string]: ReplenItem}) => void;
    replenParams: ReplenParams;
    setReplenParams: (replenParams: ReplenParams) => void;
}>({
    changesMade: [],
    setChangesMade: () => { },
    userCollections: {},
    setUserCollections: () => { },
    wsUserCollections: {},
    setWSUserCollections: () => { },
    wsUserColumnPresets: {},
    setWSUserColumnPresets: () => { },
    replenData: {},
    setReplenData: () => { },
    replenParams: DEFAULT_REPLEN_PARAMS,
    setReplenParams: () => { },
});

export function useOmega() {
    return useContext(OmegaContext);
}

export function OmegaProvider({ children }: { children: any }) {
    const [changesMade, setChangesMade] = useState<NoteItem[]>([]);
    const [replenParams, setReplenParams] = useState<ReplenParams>(DEFAULT_REPLEN_PARAMS);
    const [replenData, setReplenData] = useState<{[sku: string]: ReplenItem}>({});
    const [userCollections, setUserCollections] = useState<{[key: string]: Record<string, FilterValue | null>}>({})
    const [wsUserCollections, setWSUserCollections] = useState<{[key: string]: Record<string, FilterValue | null>}>({})
    const [wsUserColumnPresets, setWSUserColumnPresets] = useState<Record<string, string[]>>({})
    
    useEffect(() => {
        console.log('OMEGA-CONTEXT: Loading session data from localStorage')
        const localChangesMade = JSON.parse(localStorage.getItem('changesMade') ?? "[]");
        const localUserCollections = JSON.parse(localStorage.getItem('userCollections') ?? "[]");
        const localWSUserCollections = JSON.parse(localStorage.getItem('wsUserCollections') ?? "[]");
        const localWSUserColumnPresets = JSON.parse(localStorage.getItem('wsUserColumnPresets') ?? "[]");
        const localReplenData = JSON.parse(localStorage.getItem('replenData') ?? "{}");

        if (Array.isArray(localReplenData)) {
            setReplenData(Object.fromEntries(localReplenData.map((replen) => ([replen.SKU, replen]))));
        } else {
            setReplenData(localReplenData);
        }
        
        setChangesMade(localChangesMade);
        setUserCollections(localUserCollections);
        setWSUserCollections(localWSUserCollections);
        setWSUserColumnPresets(localWSUserColumnPresets);
    }, [])

    const processChanges = (changes: NoteItem[]) => {
        console.log('OMEGA-CONTEXT: saving changesMade to localStorage')
        localStorage.setItem('changesMade', JSON.stringify(changes))
        setChangesMade(changes);
    }

    const processCollections = (userCols: {[key: string]: Record<string, FilterValue | null>}) => {
        console.log('OMEGA-CONTEXT: saving userCollections to localStorage')
        localStorage.setItem('userCollections', JSON.stringify(userCols))
        setUserCollections(userCols);
    }

    const processWSCollections = (userCols: {[key: string]: Record<string, FilterValue | null>}) => {
        console.log('OMEGA-CONTEXT: saving WSUserCollections to localStorage')
        localStorage.setItem('wsUserCollections', JSON.stringify(userCols))
        setWSUserCollections(userCols);
    }

    const processWSColumnPresets  = (userCols: Record<string, string[]>) => {
        console.log('OMEGA-CONTEXT: saving WSUserColumnPresets to localStorage')
        localStorage.setItem('wsUserColumnPresets', JSON.stringify(userCols))
        setWSUserColumnPresets(userCols);
    }

    const processReplens = (replen: {[sku: string]: ReplenItem}) => {
        console.log('OMEGA-CONTEXT: saving replenData to localStorage')
        localStorage.setItem('replenData', JSON.stringify(Object.values(replen)))
        setReplenData(replen);
    }

    const processReplenParams = (replenParams: ReplenParams) => {
        console.log('OMEGA-CONTEXT: saving replenData to localStorage')
        localStorage.setItem('replenParams', JSON.stringify(replenParams))
        setReplenParams(replenParams);
    }

    const contextValue = { 
        changesMade, 
        setChangesMade: processChanges, 
        userCollections, 
        setUserCollections: processCollections, 
        wsUserCollections, 
        setWSUserCollections: processWSCollections, 
        wsUserColumnPresets, 
        setWSUserColumnPresets: processWSColumnPresets, 
        replenData, 
        setReplenData: processReplens, 
        replenParams, 
        setReplenParams: processReplenParams
     };

    console.log('context values', contextValue)

    return (
        <OmegaContext.Provider value={contextValue}>
            {children}
        </OmegaContext.Provider>
    );
}
