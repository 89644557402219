import { Divider, Space } from 'antd';
import React from 'react';
import { OMSItemRenderType } from '../../../types/OmegaTypes';
import { OMSStatsSpace } from '../../utilities/OMSStatsSpace';

interface OMSTableTitleProps {
    tableData: OMSItemRenderType[];
    actions?: React.ReactNode;
}

export const OMSTableTitle: React.FC<OMSTableTitleProps> = ({
    tableData,
    actions,
}: OMSTableTitleProps) => {
    return (
        <Space
            align='center'
            style={{
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                overflow: 'auto',
            }}
            split={<Divider type='vertical' />}
        >
            <OMSStatsSpace data={tableData} />

            {actions && (
                <Space align='center' split={<Divider type='vertical' />}>
                    {actions}
                </Space>
            )}
        </Space>
    );
};
