import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';

/**
 * Hook for syncing the URL query with tabs.
 * @param availableKeys
 * @param defaultKey
 * @returns
 */
export const useTabLocationDashboard = (
    availableKeys: string[],
    defaultKey: string,
    queryKey: string = 'tab',
): [string, (newKey: string) => void] => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState('values');

    useEffect(() => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const currentTab = existingQueries[queryKey] || defaultKey;
        if (typeof currentTab === 'string') {
            setActiveKey(
                availableKeys.includes(currentTab) ? currentTab : defaultKey
            );
        }
    }, [location, availableKeys, defaultKey, queryKey]);

    const onChange = (newKey: string) => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const queryString = qs.stringify(
            { ...existingQueries, [queryKey]: newKey },
            { skipNulls: true }
        );

        navigate(`${location.pathname}?${queryString}`);
        setActiveKey(newKey);
    };

    return [activeKey, onChange];
};