import { DatePicker } from 'antd';
import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface DateTimePickerProps {
    value?: Date | string | Dayjs;
    onBlur?: React.FocusEventHandler | undefined;
    onChange?: ((dateString: string | undefined) => void) | undefined;
    onOk?: ((date: Dayjs) => void) | undefined;
    onFocus?: (event: React.FocusEvent) => void;
    open?: boolean;
    allowClear?: boolean;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
    value = new Date(),
    open,
    onBlur,
    onChange,
    onOk,
    onFocus,
    allowClear
}) => {
    const localValue = useMemo(() => {
        return value ? dayjs(value) : dayjs(new Date());
    }, [value]);

    return (
        <DatePicker
            autoFocus
            value={localValue}
            onChange={date => {
                if (onChange) {
                    onChange(date.toISOString());
                };
            }}
            onOk={onOk}
            open={open}
            defaultValue={dayjs()}
            allowClear={allowClear}
        />
    );
};
