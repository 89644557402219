import {Button, Divider, InputRef, notification, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {CurrentShipmentLogItem, CurrentShipmentLogItemRenderType} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeCurrentShipmentLogColumns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as _ from "lodash";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {deleteCurrentShipmentLog, getCurrentShipmentLog, updateCurrentShipmentLog} from "../../../services/WholesaleService";
import {DeleteFilled} from "@ant-design/icons";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";

const defaultItem: CurrentShipmentLogItem = {
    Supplier_Names: [],
    ShipDateBooked: undefined,
    Supplier_POs: undefined,
    Pallets: undefined,
    Units: undefined,
    ShipValue: undefined,
    ShipTo: undefined,
    Carrier: undefined,
    ETA: undefined,
    Tracking: undefined,
    Notes: undefined,
    AddToFL: "No",
    Status: "Draft",
    Timestamp: new Date(),
};

export const CurrentShipmentLog: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<CurrentShipmentLogItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const tableRef = useRef<EditableTableHandle<CurrentShipmentLogItemRenderType>>(null);
    const [showIncomplete, setShowIncomplete] = useState(true);

    const {
        data: shipmentLogData,
        isLoading: shipmentLogDataLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ["current_shipment_log"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const data = await getCurrentShipmentLog(token);
            return data;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    });

    const syncTableData = useCallback(() => {
        if (shipmentLogData) {
            setTableData(
                shipmentLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [shipmentLogData, fetchTimestamp]);

    const omsMutation = useMutation({
        mutationFn: async (logItems: CurrentShipmentLogItem[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            if (!shipmentLogData) {
                throw new Error("Current shipment log was not fetched correctly");
            }

            const originalIds = shipmentLogData.map((item) => item._id);
            const localIds = logItems.map((item) => item._id);
            const idsToDelete = _.difference(originalIds, localIds);

            // find items that are different from the original data
            const itemsToUpdate = logItems.filter((item) => {
                const originalItem = shipmentLogData.find((originalItem) => originalItem._id === item._id);
                const stripOfId = _.omit(item, "key");
                const stripOfOriginalId = _.omit(originalItem, "key");
                return !_.isEqual(stripOfId, stripOfOriginalId);
            });

            const returnedData = await updateCurrentShipmentLog(token, itemsToUpdate);
            await deleteCurrentShipmentLog(token, idsToDelete.filter((id) => id !== undefined) as string[]);
            return logItems;
        },
        onSuccess: (ledger) => {
            // queryClient.setQueryData(['current_shipment_log'], ledger);
            // const tableData = ledger.map((item, idx) => ({
            //     ...item,
            //     key: idx,
            // }));
            // setTableData(tableData);

            notification.success({
                message: "Push successful, refreshing...",
            });

            // Refetch data to sync with master
            refetch({throwOnError: true});
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: CurrentShipmentLogItemRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: CurrentShipmentLogItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [shipmentLogData, syncTableData]);

    const columns = useMemo<EditableColumnType<CurrentShipmentLogItemRenderType>[]>(() => {
        const ledgerColumns = makeCurrentShipmentLogColumns(searchInputRef, editable ? handleSave : undefined);
        if (editable) {
            ledgerColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                            setLastUpdateTimestamp(new Date());
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    />
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(ledgerColumns, handleSave);
        } else {
            return ledgerColumns;
        }
    }, [handleSave, editable, tableData]);

    const addNewRow = () => {
        const newItem: CurrentShipmentLogItemRenderType = {
            ...defaultItem,
            key: 0,
            Timestamp: new Date(),
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({...item, key: idx})));
    };

    const onDiscardChanges = () => {
        if (shipmentLogData) {
            setTableData(
                shipmentLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        omsMutation.mutate(tableData);
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending || isRefetching}
                onDiscardChanges={onDiscardChanges}
                onSaveChanges={onSaveChanges}
            />
            <Space>
                Only incomplete: <Switch checked={showIncomplete} onChange={setShowIncomplete} />
            </Space>
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                onClick={() => {
                    addNewRow();
                }}
                disabled={!editable}
            >
                Add row
            </Button>
        </Space>
    );

    const filteredTableData = useMemo(() => {
        if (showIncomplete) {
            return tableData.filter((item) => item.Status !== "Complete");
        } else {
            return tableData;
        }
    }, [tableData, showIncomplete]);

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <EditableTable<CurrentShipmentLogItemRenderType>
                ref={tableRef}
                title={tableTitle}
                tableData={filteredTableData}
                columns={columns}
                loading={shipmentLogDataLoading}
                onRow={(row) => {
                    let style = {};
                    if (row.Status === "Draft") {
                        style = {
                            backgroundColor: "#f0a20244",
                        };
                    }
                    return {
                        style,
                    };
                }}
            />
        </Space>
    );
};
