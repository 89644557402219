import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { generatePackageDimsReport } from '../../../services/WholesaleService';
import dayjs from 'dayjs';

interface GeneratePackageDimsModalProps {
    pos?: string[];
    open: boolean;
    onClose: () => void;
}

export const GeneratePackageDimsModal: React.FC<
    GeneratePackageDimsModalProps
> = ({ open, onClose, pos }: GeneratePackageDimsModalProps) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (pos) {
            form.setFieldValue('pos', pos.join(','));
        }
    }, [pos, form]);

    const generate = async () => {
        setLoading(true);
        try {
            const token = await currentUser!.getIdToken();

            const { pos } = await form.validateFields();

            const reportBlob = await generatePackageDimsReport(token, pos);
            if (!reportBlob) {
                throw new Error("File couldn't be downloaded");
            }

            const filename = `PO_Export_${dayjs().format('MM-DD-YY')}.xlsx`;

            const blob = new Blob([reportBlob]);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        } catch (e) {
            notification.error({
                // @ts-ignore
                description: e.message,
                message: 'Error',
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            title={
                'Please enter the POs or SOs to create package dims report from:'
            }
            centered
            open={open}
            width={420}
            onCancel={onClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
            okButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <Form layout='vertical' action='' form={form} onFinish={generate}>
                <Form.Item
                    name='pos'
                    label='POs and SOs'
                    rules={[{ required: true }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ alignItems: 'center' }}>
                    <Button
                        title='Generate'
                        loading={loading}
                        type='primary'
                        style={{ width: '100%' }}
                        htmlType='submit'
                    >
                        Generate
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
