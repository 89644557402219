import React, { MouseEvent, useEffect, useState } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Space, Button, InputNumber } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export interface MinMaxFilterDropdownProps extends FilterDropdownProps {
  defaultMin: number;
  defaultMax: number;
}



export function MinMaxFilterDropdown(props: MinMaxFilterDropdownProps) {
  const [min, setMin] = useState(props.defaultMin);
  const [max, setMax] = useState(props.defaultMax);

  const pressMouseHandler: (event: MouseEvent<HTMLElement>) => void = (event: MouseEvent<HTMLElement>) => {
    props.confirm()
  }
  
  useEffect(() => {
    if (props.selectedKeys && props.selectedKeys.length > 0) {
        const filters: string = props.selectedKeys[0] as string
        const [newMin, newMax] = filters.split(" ");
        setMin(Number(newMin))
        setMax(Number(newMax));
    }
}, [props])

  return (
    <div style={{ padding: 8 }}>
      <InputNumber
        // ref={props.inputRef}
        placeholder="Min"
        type="number"
        value={min}
        // step={"0.01"}
        decimalSeparator={'.'}
        lang={'en'}
        onChange={(e) => {
          if (e !== null) {
            setMin(e)
            props.setSelectedKeys([e.toString() + " " + max])
          }
          // setMin(e)
          // console.log(e.target.value, Number(e.target.value))
          // props.setSelectedKeys([e.target.value + " " + max]);
        }}
        style={{ width: 252, marginBottom: 8, display: "block" }}
      />
      <InputNumber
        // ref={props.inputRef}
        placeholder="Max"
        type="number"
        value={max}
        // step={"0.01"}
        decimalSeparator={'.'}
        lang={'en'}
        onChange={(e: any) => {
          if (e !== null) {
            setMax(e)
            props.setSelectedKeys([min + " " + e.toString()])
          }
          // setMax(Number(e.target.value))
          // props.setSelectedKeys([min + " " + e.target.value]);
        }}
        style={{ width: 252, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 80 }}
          onClick={(e: any) => {
            props.setSelectedKeys([min + " " + max])
            pressMouseHandler(e)
          }}
        >
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            props.setSelectedKeys(["0 0"]);
            props.clearFilters && props.clearFilters()
            props.confirm()
          }} style={{ width: 80 }}>
          Stop
        </Button>
        <Button
          size="small"
          onClick={() => {
            props.setSelectedKeys(["0 0"]);
            setMin(0)
            setMax(0)
            props.clearFilters && props.clearFilters()
            props.confirm()
          }} style={{ width: 80 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}
