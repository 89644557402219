import {Button, Divider, Space, Tooltip} from "antd";
import React from "react";

const WholesaleFilterPresets: React.FC<{
    selectedFilterPreset: string;
    setSelectedFilterPreset: React.Dispatch<string>;
    changeSellerSnap: (timespan: string) => void;
}> = ({selectedFilterPreset, setSelectedFilterPreset, changeSellerSnap}) => {
    return (
        <Space align="center" style={{width: "100%", overflow: "auto"}}>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                onClick={() => {
                    setSelectedFilterPreset("default");
                }}
            >
                Default
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "SUPPRESSED"}
                onClick={() => {
                    setSelectedFilterPreset("SUPPRESSED");
                    // setSelectedColumnPreset('WS_SUPPRESSED_PRESET')
                }}
            >
                Suppressed
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "STAGE_01"}
                onClick={() => {
                    setSelectedFilterPreset("STAGE_01");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Filter: NOT Saved
                            <br />
                            Filter: Est Sales {">="} 1 <br />
                            Filter: ROI {">"} 25% <br />
                            Filter: Profit {">"} 2 <br />
                            <b>AND:</b> <br />
                            Filter: VarRevs {">"} 1 <br />
                            <b>OR</b> <br />
                            Filter: No Parent
                            <br />
                            Filter: Reviews {">"} 10 <br />
                        </div>
                    }
                >
                    Stage 01
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "STAGE_02"}
                onClick={() => {
                    setSelectedFilterPreset("STAGE_02");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Filter: NOT Saved <br />
                            Filter: Offers {"="} 0 <br />
                            <b>AND:</b> <br />
                            Filter: VarRevs {">"} 1 <br />
                            <b>OR</b> <br />
                            Filter: No Parent
                            <br />
                            Filter: Reviews {">"} 10 <br />
                        </div>
                    }
                >
                    Stage 02
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "STAGE_03"}
                onClick={() => {
                    setSelectedFilterPreset("STAGE_03");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Filter: NOT Saved <br />
                            Filter: No Parent
                            <br />
                            Filter: SRDrops {">"} 5 <br />
                            Filter: ROI {">"} 25% <br />
                            Filter: Reviews {">"} 10 <br />
                        </div>
                    }
                >
                    Stage 03
                </Tooltip>
            </Button>
            {/* <Button 
                            style={{marginBottom: '4px', marginRight: '2px'}} 
                            size="small" 
                            type="primary"
                            danger={selectedFilterPreset !== 'default' && selectedFilterPreset === 'STAGE_04'}
                            onClick={() => {
                                setSelectedFilterPreset('STAGE_04')
                            }}
                        >
                            <Tooltip placement="top" title={
                                <div>
                                    Filter: Saved items <br/>
                                    Filter: BuyQTY {'>'} 0 <br/>
                                    Filter: AvgSellPrice {'<'} 90%*MVSP <br/>
                                </div>
                            }>Stage 04</Tooltip>
                        </Button> */}
            <Divider type="vertical"></Divider>
            {/* REPLEN STAGES */}
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "REP_STAGE_01"}
                onClick={() => {
                    setSelectedFilterPreset("REP_STAGE_01");
                    changeSellerSnap("true");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            SS: 30 days <br />
                            Filter: DIS {">"} 1 <br />
                            Filter: OrdersAll {">"} 1 <br />
                            Sort: DIS
                        </div>
                    }
                >
                    [REP 01] Recent
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "REP_STAGE_02"}
                onClick={() => {
                    setSelectedFilterPreset("REP_STAGE_02");
                    changeSellerSnap("ninety");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            SS: 90 days <br />
                            Filter: Not Saved (or saved with 0 QTY) items <br />
                            Filter: DIS {">"} 1 <br />
                            Filter: OrdersAll {">"} 1 <br />
                            Sort: DIS
                        </div>
                    }
                >
                    [REP 02] 90 Days
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "REP_STAGE_03"}
                onClick={() => {
                    setSelectedFilterPreset("REP_STAGE_03");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Filter: Not Saved (or saved with 0 QTY) items <br />
                            Sort: Velocity
                        </div>
                    }
                >
                    [REP 03] Leftovers
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "REP_STAGE_04"}
                onClick={() => {
                    setSelectedFilterPreset("REP_STAGE_04");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            No Filters <br />
                            Sort: RepROI (lowest to highest)
                        </div>
                    }
                >
                    [REP 04] Review
                </Tooltip>
            </Button>
            <Divider type="vertical"></Divider>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "STAGE_X"}
                onClick={() => {
                    setSelectedFilterPreset("STAGE_X");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            If Target DIS: ROI-Based:
                            <br />
                            {"\t"}Filter: Item is Saved
                            <br />
                            {"\t"}Filter: (BuyQTY + Inv + Ship Later + MAX(Discrepancy,0) + Inc30){"/"}EstSales {">"} 4 months
                            <br />
                            Else: <br />
                            {"\t"}Filter: Item is Saved
                            <br />
                            {"\t"}Filter: (BuyQTY + Inv + Ship Later + MAX(Discrepancy,0) + Inc30){"/"}EstSales {">"} 2*TargetDIS
                        </div>
                    }
                >
                    [BAD] Stage X
                </Tooltip>
            </Button>
            <Button
                style={{marginBottom: "4px", marginRight: "2px"}}
                size="small"
                type="primary"
                danger={selectedFilterPreset !== "default" && selectedFilterPreset === "STAGE_Y"}
                onClick={() => {
                    setSelectedFilterPreset("STAGE_Y");
                }}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Filter: Item is Saved
                            <br />
                            Filter: Avg Parent ROI {"<"} DesiredROI
                        </div>
                    }
                >
                    [BAD] Stage Y
                </Tooltip>
            </Button>
        </Space>
    );
};

export default WholesaleFilterPresets;
