
export const WS_DEFAULT_PRESET = [
    'ASIN',
    'Title',
    'Amazon Image',
    'Parent',
    'Reviews',
    'VarRevs',
    'MVSP',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BuyQTY',
    'OH',
    'Offers',
    'OrderAmt',
    'Issues',
    'OrdersOur365D',
    'Comments',
    'Keepa',
    'Save',
    // 'ASIN',
    // 'Title',
    // 'SellPrice',
    // 'Profit',
    // 'ROI',
    // 'EstSales',
    // 'BBOwner',
    // 'ToBuy',
    // 'BuyQTY',
    // 'MaximumViableCost',
    // 'MinimumViableSellPrice',
    // 'OH',
    // 'Inv',
    // 'FBA Avail',
    // 'AskPrice',
    // 'Issues',
    // 'Keepa',
    // 'Save'
]


export const WS_SUPPRESSED_PRESET = [
    'ASIN',
    'Title',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BBOwner',
    'BB OOS',
    'ToBuy',
    'BuyQTY',
    'MaximumViableCost',
    'MinimumViableSellPrice',
    'OH',
    'Inv',
    'FBA Avail',
    'AskPrice',
    'Issues',
    'Keepa',
    'Save'
]

export const WS_LUCAS_PRESET = [
    'Amazon Image',
    'Parent',
    'Reviews',
    'VarRevs',
    'Title',
    'MinimumViableSellPrice',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BuyQTY',
    'OH',
    'TotalInv',
    'Orders7D',
    'Orders30D',
    'BBShare',
    'DIS',
    'AvgSellPrice',
    'AskPrice',
    'Issues',
    'Keepa',
    'Save'
]

export const WS_LUCAS_SECOND_PRESET = [
    'Velocity',
    'Amazon Image',
    'Parent',
    'VarRevs',
    'Title',
    'AvgSellPrice',
    'MinimumViableSellPrice',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BuyQTY',
    'TotalInv',
    'Orders30D',
    'BBShare',
    'DIS',
    'Issues',
    'Keepa',
    'Save'
]

export const WS_LUCAS_THIRD_PRESET = [
    'Velocity',
    'Amazon Image',
    'Reviews',
    'VarRevs',
    'Title',
    'MinimumViableSellPrice',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BuyQTY',
    'TotalInv',
    'Inc30D',
    'OrdersAll',
    'BBShare',
    'DIS',
    'AvgSellPrice',
    'AvgROI',
    'AskPrice',
    'Issues',
    'Keepa',
    'Save'
]

export const WS_LUCAS_ADIDAS_PRESET = [
    'ASIN',
    'Amazon Image',
    'Parent',
    'Reviews',
    'VarRews',
    'Title',
    'MinimumViableSellPrice',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'BuyQTY',
    'OH',
    'TotalInv',
    'BBShare',
    'DIS',
    'RepEstSales',
    'AvgSellPrice',
    'Supplier_Ship Date',
    'Keepa',
    'Save'
]

export const WS_MONITOR_SUPPRESSED_PRESET = [
    'ASIN',
    'Amazon Image',
    'Parent',
    'SS_Max',
    'Supplier_CompetitivePrice',
    'MinimumViableSellPrice',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'Inv',
    'Orders30D',
    'DIS',
    'BBShare',
    'AvgSellPrice',
    'AskPrice',
    'Issues',
    'Keepa',
    'Comments',
    'Save'
]

export const WS_OMS_S3_PRESET = [
    'ASIN',
    'Title',
    'SellPrice',
    'ROI',
    'Profit',
    'EstSales',
    'MinPrice',
    'MaxPrice',
    'Seasonal Tags',
    'BuyQTY',
    'AskPrice',
    'Issues',
    'Keepa',
    'Save'
]

export const WS_DASHBOARD_PRESET = [
    'ASIN',
    'Title',
    'Amazon Image',
    'Parent',
    'Reviews',
    'VarRevs',
    'SellPrice',
    'Profit',
    'ROI',
    'EstSales',
    'Issues',
    'EstChildSales',
    'EstParentSales',
    'DiscNeeded',
    'AskPrice',
    'Opportunities',
    'Notes',
    'Keepa',
    'Save'
]

export const WS_REPLEN_PRESET = [
    'ASIN',
    'Parent',
    'Amazon Image',
    'Title',
    'MVSP',
    'SellPrice',
    'AvgSell',
    'ROI',
    'RepROI',
    'EstSales',
    'RepEstSales',
    'RepToBuy',
    'BuyQTY',
    'OH',
    'Inv',
    'TotalInv',
    'DIS',
    'OrdersOur30D',
    'OrdersOur7D',
    'BBShare',
    'OrderAmt',
    'Issues',
    'Keepa',
    'Save',
    // 'Title',
    // 'SellPrice',
    // 'ROI',
    // 'AvgSellPrice',
    // 'RepProfit',
    // 'RepROI',
    // 'DIS',
    // 'Orders30D',
    // 'OrdersAll',
    // 'RepEstSales',
    // 'EstSales',
    // 'RepToBuy',
    // 'BuyQTY',
    // 'OH',
    // 'BBShare',
    // 'Inv',
    // 'Inc30D',
    // 'AskPrice',
    // 'Tags',
]

export const WS_SHIPLATER_REPLEN_PRESET = [
    'ASIN',
    'Title',
    'SellPrice',
    'AvgSellPrice',
    'DIS',
    'Orders30D',
    'OrdersAll',
    'RepEstSales',
    'EstSales',
    'RepToBuy',
    'BuyQTY',
    'OH',
    'Inv',
    'BBShare',
    'Tags',
    'Issues',
    'Keepa',
    'Save',
]

export const WS_CAIO_REPLEN_PRESET = [
    'ASIN',
    'Amazon Image',
    'Title',
    'Size',
    'MinimumViableSellPrice',
    'SellPrice',
    'ROI',
    'AvgSellPrice',
    'RepProfit',
    'RepROI',
    'DIS',
    'Orders30D',
    'OrdersAll',
    'RepEstSales',
    'EstSales',
    'RepToBuy',
    'BuyQTY',
    'BBShare',
    'Inv',
    'Inc30D',
    'Tags',
    'Issues',
    'Keepa',
    'Save',
]

export const WS_FUTURE_PRESET = [
    'ASIN',
    'Title',
    'SellPrice',
    'Profit',
    'ROI',
    'RepEstSales',
    'EstSales',
    'BBOwner',
    'ToBuy',
    'BuyQTY',
    'OH',
    'Inv',
    'Inc30D',
    'FBA Avail',
    'AskPrice',
    'Issues',
    'Keepa',
    'Save',
]


export const OMEGA_LUCAS_PRESET = [
    'Parent',
    'ASIN',
    'Amazon Image',
    'CurrBB',
    'AvgSell',
    'Cost',
    'ROI',
    'Profit',
    'Title',
    'Notes',
    'Sold (30D)',
    'DIS',
    'TTS',
    'Stock',
    'Sessions',
    'OurSess',
    'OurSess-Sales',
    'CR',
    'OMS',
    'EstSales (30D)',
    'BBShare',
    'Tags'
]

export const OMEGA_LUCAS_SECOND_PRESET = [
    'ASIN',
    'BBShare',
    'Velocity (30D)',
    'Amazon Image',
    'Parent',
    'Title',
    'TTS',
    'Stock',
    'DIS',
    'Sold (30D)',
    'Cost',
    'Listed Price',
    'AvgSell',
    'Min Price',
    'Max Price',
    'ROI',
    'Profit',
    'Notes',
    'Sessions',
    'OurSess',
    'OurSess-Sales',
    'OMS'
]

export const OMEGA_MAX_PRESET = [
    'ASIN',
    'Title',
    'Cost',
    'Min Price',
    'Max Price',
    'CurrBB',
    'DIS',
    'Fulfil. Inv',
    'Fulfil. Value',
    'Tags',
    'Action',
    'All Sales',
    'QTYBelow',
    'Pecty',
]

export const OMEGA_MAX_LS_PRESET = [
    'ASIN',
    'Title',
    'Issues',
    'CurrBB',
    'AvgSell',
    'ROI',
    'DIS',
    'Fulfil. Inv',
    'Fulfil. Value',
    'InvOver90D',
    'InvUnder90D',
    'TTS',
    'Tags',
    'Action',
    'Sessions',
    'OurSess',
    'OurSess-Sales',
    'CR'
]

export const PRESET_LOOKUP = {
    'WS_DEFAULT_PRESET': WS_DEFAULT_PRESET,
    'WS_DASHBOARD_PRESET': WS_DASHBOARD_PRESET,
    'WS_SUPPRESSED_PRESET': WS_SUPPRESSED_PRESET,
    'WS_LUCAS_PRESET': WS_LUCAS_PRESET,
    'WS_LUCAS_SECOND_PRESET': WS_LUCAS_SECOND_PRESET,
    'WS_LUCAS_THIRD_PRESET': WS_LUCAS_THIRD_PRESET,
    'WS_LUCAS_ADIDAS_PRESET': WS_LUCAS_ADIDAS_PRESET,
    'WS_MONITOR_SUPPRESSED_PRESET': WS_MONITOR_SUPPRESSED_PRESET,
    'WS_OMS_S3_PRESET': WS_OMS_S3_PRESET,
    'WS_REPLEN_PRESET': WS_REPLEN_PRESET,
    'WS_CAIO_REPLEN_PRESET': WS_CAIO_REPLEN_PRESET,
    'WS_SHIPLATER_REPLEN_PRESET': WS_SHIPLATER_REPLEN_PRESET,
    'WS_FUTURE_PRESET': WS_FUTURE_PRESET,
}