import {Form, Input, InputNumber, InputRef, Select, SelectProps} from "antd";
import {ColumnType} from "antd/lib/table/interface";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import type {FormInstance} from "antd/es/form";
import {DateTimePicker} from "./DateTimePicker";
import {ORDER_TYPES, SEASONAL_TAGS, SUPPLIER_USERS, VENDOR_TYPES} from "../../../types/OmegaTypes";
import {useSuppliers} from "../oms/dataHandlers";
import AddSelect from "../../utilities/AddSelect";
import {DefaultOptionType} from "antd/es/select";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface DataType {
    key: React.Key;
    [key: string]: any;
}

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const SuppliersSelect: React.FC<SelectProps & {save?: () => void}> = ({save, ...props}) => {
    const {data: suppliers} = useSuppliers();

    return (
        <Select<string, {label: string; value: string}>
            defaultOpen
            {...props}
            showSearch
            allowClear
            options={(suppliers || []).map((supplier) => ({
                label: supplier.name,
                value: supplier.name,
            }))}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            onBlur={save}
            autoFocus
        />
    );
};

// yes/no is responsible for handling literal "YES" or "NO" strings
export type CellInputType =
    | "int"
    | "price"
    | "text"
    | "date"
    | "yes/no"
    | "bool"
    | "float"
    | "seasonal tags"
    | "order types"
    | "vendor types"
    | "suppliers"
    | "supplier users"
    | "tags"
    | "select"
    | "editable select"
    | "multi-select";

export interface CellInputOptions {
    selectableValues?: string[] | DefaultOptionType[];
    isArray?: boolean;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    inputType: CellInputType;
    inputOptions?: CellInputOptions;
    children: React.ReactNode;
    dataIndex: keyof DataType;
    record: DataType;
    required?: boolean;
    handleSave: (record: DataType) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    inputType,
    inputOptions,
    record,
    required,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const inputNumberRef = useRef<HTMLInputElement>(null);
    const form = useContext(EditableContext)!;

    const toggleEdit = useCallback(() => {
        setEditing(!editing);
        let value = record[dataIndex];
        if (inputType === "bool") {
            value = value ? "YES" : "NO";
        } else if (["seasonal tags", "order types", "tags", "multi-select"].includes(inputType) && !inputOptions?.isArray) {
            value = value ? value.split(";") : [];
        }

        form.setFieldsValue({[dataIndex]: value});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIndex, form, editing, record, inputType]);

    const save = useCallback(() => {
        // There is some issue with the validateFields function
        // the trick with timeout 0 helps here
        setTimeout(async () => {
            try {
                const values = await form.validateFields();

                if (record[dataIndex] !== values[dataIndex]) {
                    if (inputType === "bool") {
                        values[dataIndex] = values[dataIndex] === "YES";
                    } else if (["seasonal tags", "order types", "tags", "multi-select"].includes(inputType) && !inputOptions?.isArray) {
                        values[dataIndex] = values[dataIndex].join(";");
                    } else if (inputType === "text") {
                        values[dataIndex] = values[dataIndex].trim();
                    }

                    handleSave({...record, ...values});
                }
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
            toggleEdit();
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, dataIndex, handleSave, toggleEdit, form, inputType]);

    let inputNode = useMemo(() => {
        let input;
        let options: DefaultOptionType[] | undefined;
        if (typeof inputOptions?.selectableValues?.[0] === "string") {
            options = inputOptions?.selectableValues?.map((value) => ({
                label: value as string,
                value: value as string,
            }));
        } else if (inputOptions?.selectableValues) {
            options = inputOptions?.selectableValues as DefaultOptionType[];
        }
        switch (inputType) {
            case "text":
                input = <Input.TextArea ref={inputRef} onPressEnter={save} onBlur={save} />;
                break;
            case "int":
                input = (
                    <InputNumber
                        ref={inputNumberRef}
                        onPressEnter={save}
                        onBlur={save}
                        // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                    />
                );
                break;
            case "float":
                input = <InputNumber ref={inputNumberRef} onPressEnter={save} onBlur={save} step={0.1} />;
                break;
            case "price":
                input = <InputNumber ref={inputNumberRef} onPressEnter={save} onBlur={save} prefix="$" precision={2} step={1.0} />;
                break;
            case "date":
                input = <DateTimePicker onBlur={save} onOk={save} onChange={save} open={true} allowClear={!required} />;
                break;
            case "bool":
            case "yes/no":
                input = (
                    <Select
                        allowClear
                        options={[
                            {
                                value: "YES",
                                label: "YES",
                            },
                            {
                                value: "NO",
                                label: "NO",
                            },
                        ]}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "seasonal tags":
                input = (
                    <Select
                        mode="multiple"
                        allowClear
                        options={SEASONAL_TAGS.map((tag) => ({
                            label: tag,
                            value: tag,
                        }))}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "order types":
                input = (
                    <Select
                        mode="multiple"
                        allowClear
                        options={ORDER_TYPES.map((tag) => ({
                            label: tag,
                            value: tag,
                        }))}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "vendor types":
                input = (
                    <Select
                        allowClear
                        options={VENDOR_TYPES.map((tag) => ({
                            label: tag,
                            value: tag,
                        }))}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "suppliers":
                input = <SuppliersSelect save={save} mode={inputOptions?.isArray ? "multiple" : undefined} />;
                break;
            case "supplier users":
                input = (
                    <Select
                        mode="multiple"
                        allowClear
                        options={SUPPLIER_USERS.map((tag) => ({
                            label: tag,
                            value: tag,
                        }))}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "tags":
                input = (
                    <Select mode="tags" allowClear options={options} tokenSeparators={["\r\n", "\n"]} onBlur={save} autoFocus defaultOpen />
                );
                break;
            case "select":
                input = (
                    <Select
                        allowClear
                        options={options}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            case "editable select":
                input = <AddSelect save={save} selectableValues={options} />;
                break;
            case "multi-select":
                input = (
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                    />
                );
                break;
            default:
                input = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
                break;
        }
        return input;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputType, inputRef, inputNumberRef, save, required]);

    // if (dataIndex === 'DueDate') {
    //     console.log('editable', editable);
    //     console.log('inputNode', inputNode)
    //     console.log('save', save)
    // }

    useEffect(() => {
        if (editing) {
            switch (inputType) {
                case "text":
                    inputRef.current!.focus({
                        cursor: "end",
                        preventScroll: false,
                    });
                    break;
                case "int":
                    inputNumberRef.current!.focus();
                    break;
                case "price":
                    inputNumberRef.current!.focus();
                    break;
            }
        }
    }, [editing, inputType]);

    let childNode = children;

    if (editable && editing) {
        childNode = (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {inputNode}
            </Form.Item>
        );
    }

    return (
        <td
            onClick={() => {
                if (editable && !editing) toggleEdit();
            }}
            onFocusCapture={() => {
                if (editable && !editing) toggleEdit();
            }}
            {...restProps}
            tabIndex={0}
        >
            {childNode}
        </td>
    );
};

export interface EditableColumnType<DataType> extends ColumnType<DataType> {
    inputType?: CellInputType;
    inputOptions?: CellInputOptions;
    editable?: boolean;
    required?: boolean;
    extendedFilterClickHandling?: boolean;
}
