import {Button, Col, Descriptions, Divider, Dropdown, Modal, Popover, Row, Space, Spin, Tabs, TabsProps, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {InboundPallet, InboundShipment} from "../../../types/WarehouseTypes";
import RemainingItemsDialog from "./RemainingItemsDialog";
import {DeleteOutlined, DownOutlined, ReloadOutlined} from "@ant-design/icons";
import PalletView from "./PalletView";
import ShippingChargesDialog from "./ShippingChargesDialog";
import {MenuProps} from "antd/lib";
import DimensionsDialog from "./DimensionsDialog";
import {prepareTransportDetails} from "../../../services/WarehouseService";
import {TransportDetailInput} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import ConfirmTransportDetailsDialog from "./ConfirmTransportDetailsDialog";

interface SingleShipmentViewProps {
    shipment?: InboundShipment;
}

const SingleShipmentView: React.FC<SingleShipmentViewProps> = ({shipment}) => {
    const {palletsStore, loadingShipments} = useStore(
        useShallow((state) => ({
            palletsStore: state.pallets,
            loadingShipments: state.loadingShipments,
        }))
    );
    const boxingActions = useStore(
        useShallow((state) => ({
            addPallet: state.addPallet,
            removePallet: state.removePallet,
            addBox: state.addBox,
            addItem: state.addItem,
            setActivePallet: state.setActivePallet,
            submitShipment: state.submitShipment,
            putTransportDetails: state.putTransportDetails,
            voidTransport: state.voidTransport,
            getLabels: state.getLabels,
            getBillOfLading: state.getBillOfLading,
            completeShipment: state.completeShipment,
            addPalletDimensions: state.addPalletDimensions,
            addLoadingShipment: state.addLoadingShipment,
            removeLoadingShipment: state.removeLoadingShipment,
            checkTransport: state.checkTransport,
            removeItemFromShipment: state.removeItemFromShipment,
        }))
    );
    const [showRemainingItemsDialog, setShowRemainingItemsDialog] = useState(false);
    const [showShippingChargesDialog, setShowShippingChargesDialog] = useState(false);
    const [showDimensionsDialog, setShowDimensionsDialog] = useState(false);
    const [showTransportDetailsDialog, setShowTransportDetailsDialog] = useState(false);
    const [transportDetails, setTransportDetails] = useState<TransportDetailInput | undefined>();
    const [modal, contextHolder] = Modal.useModal();

    const pallets: InboundPallet[] = useMemo(
        () => Object.values(palletsStore.byId).filter((p) => p.shipmentId === shipment?.ShipmentId),
        [palletsStore, shipment]
    );

    useEffect(() => {
        // Make sure that the active tab is always a valid pallet
        if (shipment && !pallets.find((p) => p.id === shipment?.activePallet)) {
            boxingActions.setActivePallet(shipment.ShipmentId, pallets[0].id);
        }
    }, [pallets, shipment, boxingActions]);

    const tabItems: TabsProps["items"] = useMemo(() => {
        const items: TabsProps["items"] = pallets.map((pallet) => ({
            key: pallet.id,
            label: `Pallet ${pallet.number}`,
            children: <PalletView pallet={pallet} />,
        }));
        return items;
    }, [pallets]);

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            const newId = boxingActions.addPallet(shipment!.ShipmentId);
            boxingActions.setActivePallet(shipment!.ShipmentId, newId);
        } else if (typeof targetKey === "string") {
            if (shipment?.palletsIds?.length === 1) {
                modal.warning({
                    title: "You can't remove the last pallet",
                });
                return;
            } else {
                modal.confirm({
                    title: "Do you want to remove this pallet?",
                    onOk: () => {
                        boxingActions.removePallet(targetKey);
                        boxingActions.setActivePallet(shipment!.ShipmentId, pallets[0].id);
                    },
                });
            }
        }
    };

    const newActions: MenuProps["items"] = [
        {
            key: "getLabels",
            label: "Get labels",
            onClick: () => {
                boxingActions.getLabels(shipment!.ShipmentId);
            },
        },
        {
            key: "getBillOfLading",
            label: "Get bill of lading",
            disabled: shipment?.shipmentType === "SP",
            onClick: () => {
                boxingActions.getBillOfLading(shipment!.ShipmentId);
            },
        },
        {
            key: "voidTransport",
            label: "Void transport",
            onClick: () => {
                modal.confirm({
                    title: "Do you want to void the transport?",
                    onOk: () => {
                        boxingActions.voidTransport(shipment!.ShipmentId);
                    },
                });
            },
        },
    ];
    const activePallet = palletsStore.byId[shipment?.activePallet || ""];

    const weight = activePallet?.dimensions?.weight.toString() || "--";
    const height = activePallet?.dimensions?.height.toString() || "--";
    const width = activePallet?.dimensions?.width.toString() || "--";
    const length = activePallet?.dimensions?.length.toString() || "--";

    const submissionTimestamp = new Date(shipment?.feed?.createdTime || 0).getTime();
    const lastUpdateTimestamp = shipment?.lastUpdate || 0;

    if (!shipment) {
        return <Typography.Title>First select a shipment</Typography.Title>;
    }

    const shipmentLoadingState = loadingShipments.find((s) => s.id === shipment.ShipmentId);

    return (
        <Spin spinning={shipmentLoadingState !== undefined} tip={shipmentLoadingState?.message}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Space direction="vertical" style={{width: "100%", alignItems: "center"}} styles={{item: {width: "100%"}}}>
                    <Row align="middle">
                        <Col span={6}>
                            <Space>
                                <Button onClick={() => setShowRemainingItemsDialog(true)}>Remaining items</Button>
                                <Button onClick={() => boxingActions.checkTransport(shipment.ShipmentId)} icon={<ReloadOutlined />} />
                            </Space>
                        </Col>
                        <Col span={18}>
                            <Typography.Title level={4} style={{margin: 8}}>
                                Shipment: {shipment.ShipmentName}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Descriptions
                        size="small"
                        column={2}
                        bordered
                        style={{width: "100%"}}
                        items={[
                            {
                                key: "submission_status",
                                label: "Submission Status",
                                children: <Space style={{width: 150}}>{shipment?.feed?.processingStatus || "---"}</Space>,
                            },
                            {
                                key: "transport_status",
                                label: "Transport Status",
                                children: <Space style={{width: 150}}>{shipment?.transportDetails?.status || "---"}</Space>,
                            },
                            {
                                key: "shipment_status",
                                label: "Shipment Status",
                                children: <Space style={{width: 150}}>{shipment?.ShipmentStatus || "---"}</Space>,
                            },
                            {
                                key: "submission_date",
                                label: "Submission Date",
                                children: (
                                    <Space style={{width: 150}}>
                                        {shipment?.feed?.createdTime ? new Date(shipment?.feed?.createdTime).toLocaleString() : "---"}
                                    </Space>
                                ),
                            },
                            {
                                key: "errors",
                                label: "Errors",
                                children: shipment?.feedResult?.messages.length ? (
                                    <Popover
                                        style={{width: 150}}
                                        content={
                                            <Space direction="vertical">
                                                {shipment?.feedResult?.messages.map((message, index) => (
                                                    <Typography.Text key={index}>{message.resultDescription}</Typography.Text>
                                                ))}
                                            </Space>
                                        }
                                    >
                                        <Space style={{width: 150}}>{shipment?.feedResult?.messages.length} error(s)</Space>
                                    </Popover>
                                ) : (
                                    <Space style={{width: 150}}>None</Space>
                                ),
                            },
                        ]}
                    />
                </Space>
                <Space direction="horizontal" style={{justifyContent: "space-evenly", width: "100%"}}>
                    <Button
                        type="primary"
                        onClick={() => boxingActions.submitShipment(shipment.ShipmentId)}
                        disabled={shipment.transportDetails?.status === "CONFIRMED"}
                    >
                        Submit
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            (shipment.feedResult?.messages && shipment.feedResult?.messages.length > 0) ||
                            shipment.transportDetails?.status === "CONFIRMED" ||
                            shipment?.feed?.processingStatus !== "DONE" ||
                            submissionTimestamp < lastUpdateTimestamp
                        }
                        onClick={async () => {
                            boxingActions.addLoadingShipment(shipment.ShipmentId, "Preparing transport details...");
                            const transportDetails = await prepareTransportDetails(shipment.ShipmentId);
                            setTransportDetails(transportDetails);
                            boxingActions.removeLoadingShipment(shipment.ShipmentId);
                            if (transportDetails.PartneredLtlData) {
                                setShowTransportDetailsDialog(true);
                            } else {
                                boxingActions.putTransportDetails(shipment.ShipmentId, transportDetails);
                            }
                        }}
                    >
                        Confirm boxes
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            (shipment.feedResult?.messages && shipment.feedResult?.messages.length > 0) ||
                            !shipment.transportDetails?.estimate ||
                            submissionTimestamp < lastUpdateTimestamp
                        }
                        onClick={() => {
                            setShowShippingChargesDialog(true);
                        }}
                    >
                        Charges
                    </Button>
                    <Button
                        type="primary"
                        disabled={shipment.transportDetails?.status !== "CONFIRMED"}
                        onClick={() => {
                            modal.confirm({
                                title: "Do you want to complete the shipment?",
                                onOk: () => {
                                    boxingActions.completeShipment(shipment.ShipmentId);
                                },
                            });
                        }}
                    >
                        Complete
                    </Button>
                    <Dropdown menu={{items: newActions}} placement="bottomRight" arrow>
                        <Button shape="circle" icon={<DownOutlined />} />
                    </Dropdown>
                </Space>
                <Divider style={{margin: 2}} />
                <Spin spinning={shipment?.transportDetails?.status === "CONFIRMED"} indicator={<></>}>
                    {shipment.shipmentType === "SP" ? (
                        <PalletView pallet={pallets[0]} />
                    ) : (
                        <Tabs
                            type="editable-card"
                            activeKey={shipment.activePallet}
                            onChange={(key) => boxingActions.setActivePallet(shipment.ShipmentId, key)}
                            items={tabItems}
                            onEdit={onEdit}
                            size="large"
                            removeIcon={<DeleteOutlined style={{fontSize: 20}} />}
                            renderTabBar={(props, DefaultTabBar) => {
                                return (
                                    <Row style={{width: "100%"}} justify={"start"} align={"middle"}>
                                        <DefaultTabBar {...props} style={{width: "70%"}} />
                                        <Divider type="vertical" style={{height: 30, marginLeft: 8}} />
                                        <Space direction="horizontal" style={{justifyContent: "center", width: "20%"}}>
                                            <Space.Compact direction="vertical">
                                                <Typography.Text type="secondary" style={{padding: 0}}>
                                                    {length} x {width} x {height} in
                                                </Typography.Text>
                                                <Typography.Text type="secondary">{weight} lbs</Typography.Text>
                                            </Space.Compact>
                                            <Button style={{marginLeft: 8}} onClick={() => setShowDimensionsDialog(true)}>
                                                Edit
                                            </Button>
                                        </Space>
                                    </Row>
                                );
                            }}
                        />
                    )}
                </Spin>

                <RemainingItemsDialog
                    shipment={shipment}
                    open={showRemainingItemsDialog}
                    onCancel={() => setShowRemainingItemsDialog(false)}
                    onSelect={(item, quantity) => {
                        boxingActions.addItem(item, quantity, shipment.activePallet || pallets[0].id);
                    }}
                    onRemove={(item, quantity) => {
                        modal.confirm({
                            title: "Are you sure you want to remove this item?",
                            content: (
                                <>
                                    You are about to remove <b>{quantity}</b> unit(s) of <b>{item.SellerSKU}</b> from the shipment. This
                                    action cannot be undone.
                                </>
                            ),
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            onOk() {
                                boxingActions.removeItemFromShipment(shipment.ShipmentId, item.SellerSKU, quantity);
                                setShowRemainingItemsDialog(false);
                            },
                            onCancel() {},
                        });
                    }}
                />
                <ShippingChargesDialog
                    shipment={shipment}
                    open={showShippingChargesDialog}
                    onCancel={() => {
                        setShowShippingChargesDialog(false);
                    }}
                />
                <DimensionsDialog
                    title="Edit Pallet Dimensions"
                    open={showDimensionsDialog}
                    onSave={(dimensions) => {
                        boxingActions.addPalletDimensions(activePallet.id, dimensions);
                        setShowDimensionsDialog(false);
                    }}
                    onCancel={() => setShowDimensionsDialog(false)}
                    dimensions={activePallet?.dimensions}
                />
                <ConfirmTransportDetailsDialog
                    open={showTransportDetailsDialog}
                    onCancel={() => setShowTransportDetailsDialog(false)}
                    transportDetails={transportDetails}
                    onConfirm={(details) => {
                        setShowTransportDetailsDialog(false);
                        boxingActions.putTransportDetails(shipment.ShipmentId, details);
                    }}
                />
                {contextHolder}
            </Space>
        </Spin>
    );
};

export default SingleShipmentView;
