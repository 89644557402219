import {Button, Divider, Form, Input, InputRef, Modal, notification, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {InvoiceData, InvoiceDataRenderType} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeInvoiceColumns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as _ from "lodash";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {deleteInvoices, generateTrackerData, getInvoices, updateInvoices} from "../../../services/WholesaleService";
import {CopyOutlined, DeleteFilled, ExclamationCircleOutlined} from "@ant-design/icons";
import {validateInvoices} from "../../utilities/TableDataValidation";
import EditableTable from "../table/EditableTable";

const defaultItem: InvoiceData = {
    Supplier_Name: "",
    Supplier_PO: "",
    Dropbox_URL: "",
    Timestamp: new Date(),
};

export const InvoiceBrowser: React.FC = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [tagForm] = Form.useForm();
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<InvoiceDataRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const queryClient = useQueryClient();

    const {
        data: invoiceData,
        isLoading: invoiceDataLoading,
        isRefetching,
        error,
    } = useQuery({
        queryKey: ["invoices"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const names = (await getInvoices(token)) as InvoiceData[];
            return names;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const syncTableData = useCallback(() => {
        if (invoiceData) {
            setTableData(
                invoiceData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [invoiceData, fetchTimestamp]);

    const omsMutation = useMutation({
        mutationFn: async (invoices: InvoiceData[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            if (!invoiceData) {
                throw new Error("Invoice data was not fetched correctly");
            }

            const originalIds = invoiceData.map((item) => item._id);
            const localIds = invoices.map((item) => item._id);
            const idsToDelete = _.difference(originalIds, localIds);
            const returnedInvoices = await updateInvoices(token, invoices);
            await deleteInvoices(token, idsToDelete.filter((id) => id !== undefined) as string[]);
            generateTrackerData(token, false);

            return returnedInvoices;
        },
        onSuccess: (invoices) => {
            queryClient.setQueryData(["invoices"], invoices);
            const tableData = invoices.map((item, idx) => ({
                ...item,
                key: idx,
            }));
            setTableData(tableData);
            setFetchTimestamp(new Date());
            notification.success({
                message: "Push successful!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: InvoiceDataRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: InvoiceDataRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [invoiceData, syncTableData]);

    const columns = useMemo<EditableColumnType<InvoiceDataRenderType>[]>(() => {
        const stage1Columns = makeInvoiceColumns(searchInputRef);
        if (editable) {
            stage1Columns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Space>
                        <Button
                            danger
                            icon={<DeleteFilled />}
                            onClick={() => {
                                const dataCopy = [...tableData];
                                const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                                setLastUpdateTimestamp(new Date());
                                setTableData(
                                    newData.map((item, idx) => ({
                                        ...item,
                                        key: idx,
                                    }))
                                );
                            }}
                        />
                        <Button
                            icon={<CopyOutlined />}
                            disabled={!record.Dropbox_URL}
                            onClick={() => {
                                const dataCopy = [...tableData];
                                const url = record.Dropbox_URL;

                                if (url) {
                                    const [InvoiceId, Supplier_Name, Supplier_PO] = decodeURI(url)
                                        .split("/")
                                        .pop()!
                                        .split("?")[0]
                                        .split(/ |-|\.pdf/)
                                        .filter((el) => el && el !== "Invoice");

                                    const newData = dataCopy.map((item) =>
                                        item.key === record.key
                                            ? {
                                                  ...item,
                                                  Supplier_Name,
                                                  Supplier_PO,
                                                  Dropbox_URL: url,
                                                  InvoiceId,
                                              }
                                            : item
                                    );

                                    setLastUpdateTimestamp(new Date());
                                    setTableData(
                                        newData.map((item, idx) => ({
                                            ...item,
                                            key: idx,
                                        }))
                                    );
                                }
                            }}
                        />
                    </Space>
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(stage1Columns, handleSave);
        } else {
            return stage1Columns;
        }
    }, [handleSave, editable, tableData]);

    const addNewRow = () => {
        const newItem: InvoiceDataRenderType = {
            ...defaultItem,
            key: 0,
            Timestamp: new Date(),
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({...item, key: idx})));
    };

    const uploadRows = () => {
        // create a modal form with textarea to allow user to paste in a list of URLs
        // each URL should be on a new line
        // when the user clicks submit, the URLs should be parsed and added to the table

        modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form form={tagForm} name={`upload-urls`} layout="vertical" preserve={true}>
                    <Form.Item name={"uploadUrls"} key={1} label={`Paste in the Dropbox URLs, preferably separated by a new line`}>
                        <Input.TextArea
                            placeholder={`Enter the URLs here, one per line`}
                            autoSize={{minRows: 2, maxRows: 6}}
                            allowClear
                            required
                        />
                    </Form.Item>
                </Form>
            ),
            onOk() {
                tagForm.validateFields().then((values) => {
                    const urls = values.uploadUrls.split(/\n|&dl=0/).filter((url: string) => url.length > 0);
                    console.log("bulk form values", urls);
                    const parsedUrls = urls.map((url: string) => {
                        const [InvoiceId, Supplier_Name, Supplier_PO] = decodeURI(url)
                            .split("/")
                            .pop()!
                            .split("?")[0]
                            .split(/ |-|\.pdf/)
                            .filter((el) => el && el !== "Invoice");
                        return {
                            ...defaultItem,
                            Supplier_Name,
                            Supplier_PO,
                            Dropbox_URL: url,
                            InvoiceId,
                            key: 0,
                            Timestamp: new Date(),
                        };
                    });

                    const newData = [...parsedUrls, ...tableData];
                    setTableData(
                        newData.map((item, idx) => ({
                            ...item,
                            key: idx,
                        }))
                    );
                    setLastUpdateTimestamp(new Date());
                });
            },
            onCancel() {
                tagForm.setFieldsValue({uploadUrls: ""});
            },
        });
    };

    const onDiscardChanges = () => {
        if (invoiceData) {
            setTableData(
                invoiceData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        const errors = validateInvoices(tableData);
        if (errors.length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending}
                onDiscardChanges={onDiscardChanges}
                onSaveChanges={onSaveChanges}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                onClick={() => {
                    addNewRow();
                }}
                disabled={!editable}>
                Add row
            </Button>
            <Button
                onClick={() => {
                    uploadRows();
                }}
                disabled={!editable}>
                Upload URLs
            </Button>
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            {contextHolder}
            <EditableTable<InvoiceDataRenderType>
                title={tableTitle}
                tableData={tableData}
                columns={columns}
                loading={isRefetching || invoiceDataLoading}
            />
        </Space>
    );
};
