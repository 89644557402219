import {Button, Col, Divider, InputRef, notification, Popconfirm, Row, Space, Switch, App} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {OMSItem, OMSItemRenderType, OMSMap} from "../../../types/OmegaTypes";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {UploadHandler} from "../../utilities/UploadHandler";
import {EditableColumnType} from "../table/EditableCell";
import * as dataForge from "data-forge";
import * as _ from "lodash";
import {makeColumnsEditable, makeOMSImportColumns} from "../../utilities/OMSColumns";
import {OMSStatsSpace} from "../../utilities/OMSStatsSpace";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getOMSByIds, updateOMS} from "../../../services/WholesaleService";
import {validateOMSMasterColumns} from "../../utilities/TableDataValidation";
import {countChanges, countRowChanges, importStageTransformation, updateShipNow} from "../../utilities/OMSCalculations";
import EditableTable from "../table/EditableTable";
import {BulkActionsButton, BulkActionType, makeCopyAsinsAction, useDateColumnModal} from "../table/BulkActions";
import {NumberOutlined, PullRequestOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {confirmModal} from "../../wholesale/table/WholesaleUtilities";

export const OMSImport: React.FC = () => {
    const queryClient = useQueryClient();
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<OMSItemRenderType[]>([]);
    const [editable, setEditable] = useState(false);
    const [showOnlyChanged, setShowOnlyChanged] = useState(false);
    const [ids, setIds] = useState<string[]>([]);
    const [changesCounter, setChangesCounter] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [columnKeys, setColumnKeys] = useState<string[]>([]);
    const {modal} = App.useApp();

    const {
        data: dbData,
        isLoading: isFetching,
        error,
    } = useQuery({
        queryKey: ["db_oms", ids],
        queryFn: async () => {
            let result: OMSItem[] = [];
            const token = await currentUser!.getIdToken();

            if (ids.length > 0 && token) {
                result = await getOMSByIds(token, ids);
            }

            result.forEach((item) => {
                item.MAP = 1;
            });

            return Object.fromEntries(result.map((item) => [item._id, item])) as OMSMap;
        },
        gcTime: 0,
    });

    useEffect(() => {
        if (error) {
            setTableData([]);
            notification.error({
                message: "Fetching error",
                description: error.message,
            });
        }
    }, [error]);

    useEffect(() => {
        if (!dbData) return;

        const ids = Object.keys(dbData);
        const orphanedIds = _.difference(
            tableData.map((item) => item._id || ""),
            ids
        );

        if (orphanedIds.length > 0) {
            notification.warning({
                message: "Unknown items",
                description: (
                    <>
                        There are some unknown ids:
                        {orphanedIds.map((id) => (
                            <p style={{margin: 0}}>{id}</p>
                        ))}
                    </>
                ),
            });
        }

        // Filter out items that were not found in the database
        let incomingData = tableData.filter((item) => dbData[item._id || ""]);
        const incomingChangesCounter = countChanges(incomingData, dbData, columnKeys);
        setChangesCounter(incomingChangesCounter);
        if (incomingChangesCounter === 0 && incomingData.length > 0) {
            notification.info({
                message: "Nothing new!",
                description: "No changes were found",
            });
        }

        const incomingColumns = incomingData.map((item) => Object.keys(item)).flat();

        let dataFrame: dataForge.IDataFrame<number, any> = new dataForge.DataFrame(incomingData);

        incomingData = dataFrame.subset(_.intersection(incomingColumns, columnKeys)).toArray();

        console.log("incomingData", incomingData);

        setTableData(
            // If dbData has some additional columns, make sure that their values are displayed
            incomingData.map((item, idx) => ({
                ...dbData[item._id || ""],
                ...item,
                key: idx,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbData]);

    const updateData = useCallback(
        (newData: OMSItemRenderType[]) => {
            setTableData(newData);
            if (dbData) {
                setChangesCounter(countChanges(newData, dbData, columnKeys));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dbData]
    );

    const handleSave = useCallback(
        (row: OMSItemRenderType, column?: keyof OMSItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            const newRow = importStageTransformation(item, row, column);

            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            updateData(newData);
        },
        [tableData, updateData]
    );

    const columns = useMemo<EditableColumnType<OMSItemRenderType>[]>(() => {
        let masterColumns = makeOMSImportColumns(searchInputRef, dbData || {});
        if (editable) {
            masterColumns = makeColumnsEditable(masterColumns, handleSave);
        }
        setColumnKeys(_.difference(masterColumns.map((col) => col.dataIndex || "") as string[], ["Row", "key"]));
        return masterColumns;
    }, [handleSave, editable, dbData]);

    const filteredTableData = useMemo(() => {
        if (showOnlyChanged && dbData) {
            const colsToCheck = columns.map((col) => col.dataIndex || "") as string[];
            return tableData.filter((item) => countRowChanges(item, dbData || {}, colsToCheck));
        } else {
            return tableData;
        }
    }, [tableData, showOnlyChanged, dbData, columns]);

    const [dateModal, dateModalContext] = useDateColumnModal(filteredTableData, selectedRowKeys);

    const omsMutation = useMutation({
        mutationFn: async (data: OMSItem[]) => {
            const token = await currentUser!.getIdToken();

            // turn undefined MAP values to null
            const uploadData = data.map((item) => {
                if (item.MAP === undefined) {
                    return {...item, MAP: null};
                }
                return item;
            });

            await updateOMS(token, uploadData, false);
            return data;
        },
        onSuccess: (data) => {
            // Update the local state and the query state
            const newDbData = Object.fromEntries(data.map((item) => [item._id, item])) as OMSMap;
            queryClient.setQueryData(["db_oms", ids], newDbData);
            setTableData(data.map((item, idx) => ({...item, key: idx})));
            const incomingChangesCounter = countChanges(data, newDbData, columnKeys);
            setChangesCounter(incomingChangesCounter);

            // Automatically update data in PO Browser
            queryClient.refetchQueries({queryKey: ["oms_data"]});

            notification.success({
                message: "Data saved!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const processData = (data: string) => {
        let dataFrame: dataForge.IDataFrame<number, any> = dataForge.fromCSV(data);

        dataFrame = dataFrame.parseInts(["Quantity", "Ship Now", "Ship Later"]);
        dataFrame = dataFrame.parseFloats(["Cost", "Min Price", "Max Price/List", "Sell Price", "MAP"]);

        let incomingData: OMSItem[] = dataFrame.toArray();
        incomingData.forEach((row) => {
            Object.keys(row).forEach((key: string) => {
                row[key] = row[key] === "" ? undefined : row[key];
            });
        });
        incomingData = incomingData.map((item) => ({
            ...item,
            Row: _.uniqueId(),
        }));

        setTableData(
            // If dbData has some additional columns, make sure that their values are displayed
            incomingData
                .map((item, idx) => ({
                    ...dbData?.[item._id || ""],
                    ...item,
                    key: idx,
                }))
                .map((item) => {
                    if (item.MAP && item.MAP > 0 && !item["Seasonal Tag"]?.includes("MAP")) {
                        item["Seasonal Tag"] = (item["Seasonal Tag"]?.length ?? 0) > 0 ? `${item["Seasonal Tag"]};MAP` : "MAP";
                    }

                    // @ts-ignore
                    item["Ship_Requested"] = dayjs(item["Ship_Requested"]).toISOString();
                    // @ts-ignore
                    item["Supplier_Date"] = dayjs(item["Supplier_Date"]).toISOString();

                    return item;
                })
        );
        setIds(incomingData.map((item) => item._id || ""));
        setEditable(false);
    };

    const onSaveChanges = () => {
        if (validateOMSMasterColumns(tableData)) {
            const shipLaterData = tableData.filter((row) => row.Shipped && row.Shipped > 0 && row["Ship Later"] && row["Ship Later"] > 0);

            if (shipLaterData.length > 0) {
                // if items previously didn't have Ship Later, but now they do, we need to show a warning
                const previousData = shipLaterData.map((row) => dbData![row._id || ""]);
                const nowHasShipLater = shipLaterData.filter(
                    (row) => (previousData.find((prevRow) => prevRow["Ship Later"])?.["Ship Later"] || 0) < (row["Ship Later"] || 0)
                );

                if (nowHasShipLater.length > 0) {
                    confirmModal(
                        modal,
                        () => {
                            omsMutation.mutate(tableData);
                        },
                        `You must notify the Logistics channel about ShipLater changes since the items have already shipped!`
                    );
                } else {
                    omsMutation.mutate(tableData);
                }
            } else {
                omsMutation.mutate(tableData);
            }
        }
    };

    const bulkActions: BulkActionType[] = [
        makeCopyAsinsAction(filteredTableData, selectedRowKeys),
        {
            label: "Fill Ship Now",
            onClick: () => {
                const newData = updateShipNow(filteredTableData, selectedRowKeys);
                setTableData(newData);
                if (dbData) {
                    setChangesCounter(countChanges(newData, dbData, columnKeys));
                }
            },
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Ship Requested",
            onClick: () =>
                dateModal.show({
                    columnName: "Ship_Requested",
                    title: "Ship Requested",
                    onUpdate: updateData,
                }),
            icon: <PullRequestOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <Space
            align="center"
            style={{
                justifyContent: "space-between",
                width: "100%",
                paddingRight: 8,
                paddingLeft: 8,
                overflow: "auto",
            }}
            split={<Divider type="vertical" />}
        >
            <OMSStatsSpace data={filteredTableData} />

            <Space align="center" split={<Divider type="vertical" />}>
                <UnsavedChangesHandler
                    isSaved={changesCounter === 0}
                    disableWhenSaved={filteredTableData.length === 0}
                    changesCounter={changesCounter}
                    isLoading={omsMutation.isPending}
                    onSaveChanges={onSaveChanges}
                />
                <Space>
                    Editable: <Switch checked={editable} onChange={setEditable} />
                </Space>
                <Space>
                    Only changed: <Switch checked={showOnlyChanged} onChange={setShowOnlyChanged} />
                </Space>
                <Popconfirm
                    title={"Are you sure?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                        setIds([]);
                        setTableData([]);
                        setChangesCounter(0);
                        setShowOnlyChanged(false);
                    }}
                    placement="top"
                    disabled={filteredTableData.length === 0}
                >
                    <Button disabled={filteredTableData.length === 0}>Reset</Button>
                </Popconfirm>
                <BulkActionsButton actions={bulkActions} />
            </Space>
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <UploadHandler onComplete={processData} />
            </Row>
            <Row>
                <Col span={24}>
                    <EditableTable<OMSItemRenderType>
                        title={tableTitle}
                        tableData={isFetching ? undefined : filteredTableData}
                        columns={columns}
                        loading={isFetching}
                        onRowSelectionChange={(rowKeys, availableRowKeys) =>
                            setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                        }
                    />
                </Col>
            </Row>
            {dateModalContext}
        </Space>
    );
};
