import { Card, Col, ConfigProvider, Divider, List, Row, Statistic, Tag, Typography } from "antd";
import React from "react"
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';
import { OMSItem } from "../../../types/OmegaTypes";
import dayjs from "dayjs";

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
    </div>
);

const InventoryPopover: React.FC<{record: WholesaleItem}> = React.memo(({record}) => {
    return (
        <Card style={{width: 450}} size="small">
            <Row gutter={8} align="middle">
                <Col span={24}>
                    <Card style={{overflow: "auto", height: '100%', maxHeight: "220px", padding: '0px'}} bodyStyle={{padding: 0}}>
                        <ConfigProvider renderEmpty={customizeRenderEmpty}>
                            <List
                                // header={<div style={{ textAlign: 'center' }}>{`Exists in ${record.POs?.length || 0} POs`}</div>}
                                bordered
                                size="small"
                                dataSource={[...record.OMSItems]
                                    .sort((a, b) => dayjs(a.ShipDate).toDate().getTime() - dayjs(b.ShipDate).toDate().getTime())}
                                style={{overflow: "auto"}}
                                renderItem={(item: OMSItem) => {
                                    const maxCost = record.OMSItems.map((item) => item.Cost).reduce((acc: number, curr) => curr !== null && curr > acc ? curr : acc, 0)
                                    const minCost = record.OMSItems.map((item) => item.Cost).reduce((acc: number, curr) => curr !== null && curr < acc ? curr : acc, Number.POSITIVE_INFINITY)
                                    const tintFactor = maxCost !== minCost ? (item.Cost! - maxCost!) / (minCost! - maxCost!) : 1

                                    return <List.Item style={{ justifyContent: 'center', padding: '8px 0px' }}>
                                        <Row style={{ width: '100%', textAlign: 'center' }}>
                                            <Col span={4}>
                                                <Tag color={`rgb(${199 * (1 - tintFactor)}, ${15 * (1 - tintFactor)}, ${15 * (1 - tintFactor)}, 1)`} style={{ fontSize: '12px', marginRight: '0', padding: '0 4px' }}>${item.Cost!.toFixed(2)}</Tag>
                                            </Col>
                                            <Col span={6}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{item.Supplier_SO || item.Supplier_PO}</Typography.Text>
                                            </Col>
                                            <Col span={6}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{item.ShipDate}</Typography.Text>

                                            </Col>
                                            <Col span={3}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{item.Quantity}</Typography.Text>

                                            </Col>
                                            <Col span={2}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{item.ShipDateType}</Typography.Text>

                                            </Col>
                                            <Col span={3}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{item.IsPaid ? 'Paid' : ''}</Typography.Text>

                                            </Col>
                                        </Row>
                                    </List.Item>
                                }}
                            />
                        </ConfigProvider>
                    </Card>
                </Col>  
            </Row>
            <Row style={{marginTop: '8px'}} gutter={8} align="middle">
                <Col span={5}>
                    <Statistic title="Ordered:" value={record.OMSQuantity}/>
                    <Divider style={{margin: '8px 0px'}}></Divider>
                    <Statistic title="Shipped (AZ):" value={record.ShippedQuantity}/>
                </Col>
                <Col span={6}>
                    <Statistic title="Incoming (All):" value={record.IncomingQuantity}/>
                    <Divider style={{margin: '8px 0px'}}></Divider>
                    <Statistic title="Unshipped (AZ):" value={(record.OMSQuantity ?? 0) - (record.ShippedQuantity ?? 0)}/>
                </Col>
                <Col span={7}>
                    <Statistic title="Incoming (30D):" value={record.IncomingQuantity30D}/>
                    <Divider style={{margin: '8px 0px'}}></Divider>
                    <Statistic title="Ship Later:" value={((record.ShipLater ?? 0) || 'None')}/>
                </Col>
                <Col span={6}>
                    <Statistic title="Incoming (90D):" value={record.IncomingQuantity90D}/>
                    <Divider style={{margin: '8px 0px'}}></Divider>
                    <Statistic title="Discrepancy:" value={record.Discrepancy}/>
                </Col>
            </Row>
        </Card>
    )
})

export default InventoryPopover;