import React from "react";
import {InboundShipment} from "../../../types/WarehouseTypes";
import {
    PartneredLtlDataOutput,
    PartneredSmallParcelDataOutput,
    PartneredSmallParcelPackageOutput,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {Button, Col, Descriptions, Modal, Popover, Row, Space, Table, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";

interface PartneredLtlDataViewProps {
    data: PartneredLtlDataOutput;
}

const PartneredLtlDataView: React.FC<PartneredLtlDataViewProps> = ({data}) => {
    return (
        <Descriptions
            size="small"
            column={2}
            bordered
            items={[
                {
                    label: "Contact",
                    children: `${data.Contact.Name} (${data.Contact.Phone}, ${data.Contact.Email})`,
                    span: 2,
                },
                {
                    label: "Box Count",
                    children: data.BoxCount,
                },
                {
                    label: "Freight Ready Date",
                    children: data.FreightReadyDate,
                },
                {
                    label: "Freight Class",
                    children: data.SellerFreightClass,
                },
                {
                    label: "Seller Declared Value",
                    children: `$${data.SellerDeclaredValue?.Value?.toFixed(2) || "---"}`,
                },
                {
                    label: "Carrier Name",
                    children: data.CarrierName,
                },
                {
                    label: "Amazon Reference ID",
                    children: data.AmazonReferenceId,
                },
                {
                    label: "Is Bill Of Lading Available",
                    children: data.IsBillOfLadingAvailable ? "Yes" : "No",
                },
                {
                    label: "Pallets",
                    children: (
                        <Popover
                            placement="left"
                            content={
                                <Table
                                    bordered
                                    columns={[
                                        {
                                            title: "Dimensions",
                                            dataIndex: "Dimensions",
                                            key: "Dimensions",
                                            render: (dimensions) =>
                                                `${dimensions.Length} x ${dimensions.Width} x ${dimensions.Height} ${dimensions.Unit}`,
                                        },
                                        {
                                            title: "Weight",
                                            dataIndex: "Weight",
                                            key: "Weight",
                                            render: (weight) => `${weight.Value} ${weight.Unit}`,
                                        },
                                    ]}
                                    dataSource={data.PalletList}
                                    pagination={false}
                                />
                            }
                        >
                            <Space style={{width: "100%"}}>{data.PalletList.length}</Space>
                        </Popover>
                    ),
                },
            ]}
        />
    );
};

interface PartneredSmallParcelDataViewProps {
    data: PartneredSmallParcelDataOutput;
}

const PartneredSmallParcelDataView: React.FC<PartneredSmallParcelDataViewProps> = ({data}) => {
    const columns: ColumnsType<PartneredSmallParcelPackageOutput> = [
        {
            title: "Carrier Name",
            dataIndex: "CarrierName",
            key: "CarrierName",
        },
        {
            title: "Weight",
            dataIndex: "Weight",
            key: "Weight",
            render: (weight) => `${weight.Value} ${weight.Unit}`,
        },
        {
            title: "Dimensions",
            dataIndex: "Dimensions",
            key: "Dimensions",
            render: (dimensions) => `${dimensions.Length} x ${dimensions.Width} x ${dimensions.Height} ${dimensions.Unit}`,
        },
    ];

    const dataSource = data.PackageList || [];

    return <Table bordered columns={columns} dataSource={dataSource} pagination={false} style={{maxHeight: "50vh", overflow: "auto"}} />;
};

interface ShippingChargesDialogProps {
    shipment: InboundShipment;
    open: boolean;
    onCancel: () => void;
}

const ShippingChargesDialog: React.FC<ShippingChargesDialogProps> = ({shipment, open, onCancel}) => {
    const boxingActions = useStore(
        useShallow((state) => ({
            confirmTransportDetails: state.confirmTransportDetails,
        }))
    );
    const transportDetails = shipment.transportDetails;
    return (
        <Modal
            title="Shipping Charges"
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={onCancel}>Close</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            boxingActions.confirmTransportDetails(shipment.ShipmentId);
                            onCancel();
                        }}
                    >
                        Confirm
                    </Button>
                </>
            }
            styles={{
                content: {
                    alignItems: "center",
                    justifyContent: "center",
                },
            }}
            width={"60vw"}
        >
            {transportDetails?.partneredLtlData && <PartneredLtlDataView data={transportDetails.partneredLtlData} />}
            {transportDetails?.partneredSmallParcelData && (
                <PartneredSmallParcelDataView data={transportDetails.partneredSmallParcelData} />
            )}
            <Row style={{padding: 6}}>
                <Col span={6}>
                    <Typography.Text>Estimated cost: </Typography.Text>
                </Col>
                <Col span={18}>
                    <Typography.Text strong>${shipment.transportDetails?.estimate?.Amount.Value || "---"}</Typography.Text>
                </Col>
            </Row>
        </Modal>
    );
};

export default ShippingChargesDialog;
