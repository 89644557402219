import React, {useEffect, useState} from "react";
import {Input, Button, Space} from "antd";

interface QuantityButtonProps {
    onAdd: (quantity: number) => void;
    onDelete?: (quantity: number) => void;
    initialQuantity: number;
    maxQuantity: number;
    title: string;
    disabled?: boolean;
}

const QuantityButton: React.FC<QuantityButtonProps> = ({onAdd, onDelete, initialQuantity, maxQuantity, title, disabled}) => {
    const [quantity, setQuantity] = useState<number>(1);

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(event.target.value);
        setQuantity(newQuantity);
    };

    const handlePrintLabel = () => {
        onAdd(quantity);
    };

    useEffect(() => {
        setQuantity(initialQuantity);
    }, [initialQuantity]);

    return (
        <Space>
            <Input
                type="number"
                min={1}
                max={maxQuantity}
                style={{width: 80}}
                value={quantity}
                onChange={handleQuantityChange}
                disabled={disabled}
            />
            <Button onClick={handlePrintLabel} disabled={disabled}>
                {title}
            </Button>
            {onDelete && (
                <Button onClick={() => onDelete(quantity)} disabled={disabled} danger>
                    Delete
                </Button>
            )}
        </Space>
    );
};

export default QuantityButton;
