import { DeleteFilled } from '@ant-design/icons';
import {
    Button,
    Input,
    List,
    Modal,
    Space,
    ConfigProvider,
    Row,
    Col,
    Typography,
    Divider,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import dayjs from 'dayjs';

export interface ExpandedNote {
    noteText: string;
    noteAuthor: string;
    noteTimestamp: string;
    edited?: boolean;
}
interface NotesRendererProps {
    notes?: (string | ExpandedNote)[];
    onFinish: (notes: (string | ExpandedNote)[]) => void;
    editable?: boolean;
}

const customizeRenderEmpty = () => (
    <div
        style={{
            textAlign: 'center',
            minHeight: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        Click to add a new note
    </div>
);

const getRenderedNote = (item: string | ExpandedNote) => {
    if (typeof item === 'string') {
        return <span style={{ width: '100%', textAlign: 'center' }}>{item}</span>;
    } else {
        return (
            <Row style={{ width: '100%', textAlign: 'center' }}>
                <Col
                    span={17}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography.Text style={{ fontSize: '12px' }}>
                        {item.noteText}
                    </Typography.Text>
                </Col>
                <Col span={1}>
                    <Divider
                        style={{ height: '100%' }}
                        type='vertical'
                    ></Divider>
                </Col>
                <Col
                    span={6}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography.Text style={{ fontSize: '12px' }}>
                        {item.noteTimestamp}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: '12px' }}>
                        by{' '}
                        {item.noteAuthor && item.noteAuthor.includes('@')
                            ? item.noteAuthor.split('@')[0]
                            : item.noteAuthor}
                    </Typography.Text>
                </Col>
            </Row>
        );
    }
};

export default function NotesRenderer({
    notes,
    onFinish,
    editable,
}: NotesRendererProps) {
    const { currentUser } = useAuth();
    const [localNotes, setNotes] = useState<(string | ExpandedNote)[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (notes && notes.length > 0) {
            setNotes(
                notes.map(note =>
                    typeof note === 'string' ? note : { ...note }
                )
            );
        } else {
            setNotes([]);
        }
    }, [notes]);

    const finishEditing = () => {
        setOpen(false);
        const newNotes = localNotes.filter(
            note =>
                (typeof note === 'string' && note.length > 0) ||
                (typeof note === 'object' && note.noteText?.length > 0)
        );
        if (JSON.stringify(newNotes) !== JSON.stringify(notes || [])) {
            onFinish(newNotes);
        }
    };

    return (
        <>
            <div
                style={{
                    minWidth: '325px',
                    minHeight: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => {
                    if (editable) {
                        setOpen(true);
                    }
                }}
            >
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <List<string | ExpandedNote>
                        dataSource={localNotes}
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                {getRenderedNote(item)}
                            </List.Item>
                        )}
                        size='small'
                        style={{
                            maxHeight: '120px',
                            overflow: 'auto',
                            width: '100%',
                        }}
                    />
                </ConfigProvider>
            </div>
            <Modal
                title=''
                centered
                open={open}
                width={500}
                bodyStyle={{
                    maxHeight: 300,
                    minHeight: 200,
                    overflow: 'auto',
                    width: '100%',
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={finishEditing}
                closable={false}
            >
                <Space
                    style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        onClick={() => {
                            const newNotes = [...localNotes];
                            newNotes.unshift({
                                noteText: '',
                                noteAuthor: currentUser?.email || 'Unknown',
                                noteTimestamp: dayjs().format('YY-MM-DD'),
                            });
                            setNotes(newNotes);
                        }}
                        type='primary'
                    >
                        Add
                    </Button>
                </Space>
                <List<string | ExpandedNote>
                    dataSource={localNotes}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                <Button
                                    danger
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                        const newNotes = [...localNotes];
                                        newNotes.splice(index, 1);
                                        setNotes(newNotes);
                                    }}
                                />,
                            ]}
                            // style={{ padding: '4px' }}
                        >
                            {typeof item === 'string' ? (
                                <Input
                                    value={item}
                                    onChange={event => {
                                        const newValue = event.target.value;
                                        const newNotes = [...localNotes];
                                        newNotes[index] = {
                                            noteText: newValue,
                                            noteAuthor:
                                                currentUser?.email || 'Unknown',
                                            noteTimestamp:
                                                dayjs().format('YY-MM-DD'),
                                        };

                                        setNotes(newNotes);
                                    }}
                                />
                            ) : (
                                <>
                                    <Row
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Col
                                            span={17}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Input.TextArea
                                                style={{
                                                    height: '100%',
                                                    resize: 'none',
                                                }}
                                                value={item.noteText}
                                                onChange={event => {
                                                    const newValue =
                                                        event.target.value;
                                                    const newNotes = [
                                                        ...localNotes,
                                                    ];
                                                    newNotes[index] = {
                                                        noteText: newValue,
                                                        noteAuthor:
                                                            currentUser?.email ||
                                                            'Unknown',
                                                        noteTimestamp:
                                                            dayjs().format(
                                                                'YY-MM-DD'
                                                            ),
                                                    };

                                                    setNotes(newNotes);
                                                }}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Divider
                                                style={{ height: '100%' }}
                                                type='vertical'
                                            ></Divider>
                                        </Col>
                                        <Col
                                            span={6}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography.Text
                                                style={{ fontSize: '12px' }}
                                            >
                                                {item.noteTimestamp}
                                            </Typography.Text>
                                            <Typography.Text
                                                style={{ fontSize: '12px' }}
                                            >
                                                by{' '}
                                                {item.noteAuthor &&
                                                item.noteAuthor.includes('@')
                                                    ? item.noteAuthor.split(
                                                          '@'
                                                      )[0]
                                                    : item.noteAuthor}
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}
