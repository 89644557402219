import React from "react";
import {Button, notification} from "antd";
import {
    APInvoiceTrackerDataRenderType,
    FreightLogItemizedEntryRenderType,
    FreightLogItemizedRowRenderType,
    FreightLogRowRenderType,
    InvoiceDataRenderType,
    LedgerData,
    OMSItemRenderType,
    ORDER_TYPES,
    SellerSnapExportDataRenderType,
    SupplierRenderType,
    VENDOR_TYPES,
} from "../../types/OmegaTypes";
import {some} from "lodash";
import {isValidUrl} from "./common";
import {BrandDashboardItemRenderType} from "../../types/Brand";
import {copyToClipboard} from "../omega/ItemBrowser";

abstract class ColumnValidator<T = OMSItemRenderType> {
    columnName: keyof T;
    errorMsg: string;

    constructor(columnName: keyof T, errorMsg: string) {
        this.columnName = columnName;
        this.errorMsg = errorMsg;
    }

    /**
     * Should return true if a cell is correct.
     * @param cellData
     */
    abstract checkCondition(cellData: T[keyof T]): boolean;

    /**
     * Takes whole table data and returns a list of indices of rows with errors.
     */
    validate(data: T[]): number[] {
        const columnData = data.map((el) => el[this.columnName]);
        let incorrectRows: number[] = [];
        columnData.forEach((cell, index) => {
            if (!this.checkCondition(cell)) {
                //@ts-ignore
                incorrectRows.push(data[index]?.key || index);
            }
        });
        return incorrectRows;
    }
}

class EmptyCellValidator<T> extends ColumnValidator<T> {
    constructor(columnName: keyof T) {
        super(columnName, "Empty cells are not allowed");
    }

    checkCondition(cellData: T[keyof T]): boolean {
        return !(typeof cellData === "string" && cellData.trim() === "") && cellData !== undefined && cellData !== null;
    }
}

class UPCValidator<T extends {UPC?: string}> extends ColumnValidator<T> {
    allowsEmpty: boolean;

    constructor(allowsEmpty: boolean = false) {
        super("UPC", "Some values are incorrect");
        this.allowsEmpty = allowsEmpty;
    }

    checkCondition(cellData: T["UPC"]): boolean {
        return (
            (this.allowsEmpty && cellData === "") ||
            (cellData && (cellData.length === 10 || cellData.length === 12 || cellData.length === 13))
        );
    }
}

class StringLengthValidator<T> extends ColumnValidator<T> {
    allowedLength: number;

    constructor(columnName: keyof T, allowedLength: number) {
        super(columnName, "Some values are too long");
        this.allowedLength = allowedLength;
    }

    checkCondition(cellData: T[keyof T]): boolean {
        return typeof cellData === "string" && cellData.length <= this.allowedLength;
    }
}

class DuplicationValidator<T> extends ColumnValidator<T> {
    availableValues: any;

    constructor(columnName: keyof T, dataItems: T[]) {
        super(columnName, "Duplicated values");
        const valuesMap: any = {};
        dataItems.forEach((item) => {
            const key = item[columnName];
            valuesMap[key] = (valuesMap[key] || 0) + 1;
        });
        this.availableValues = valuesMap;
    }

    checkCondition(cellData: T[keyof T]): boolean {
        if (this.availableValues[cellData] !== 1) {
            console.log(cellData, this.availableValues[cellData]);
        }
        return this.availableValues[cellData] === 1;
    }
}

class PrefixValidator<T> extends ColumnValidator<T> {
    forbiddenPrefixes: string[];

    constructor(columnName: keyof T) {
        super(columnName, "Forbidden prefixes");
        this.forbiddenPrefixes = ["L_", "M_", "J_"];
    }

    checkCondition(cellData: T[keyof T]): boolean {
        return typeof cellData === "string" && !some(this.forbiddenPrefixes.map((prefix) => cellData.startsWith(prefix)));
    }
}

class CharactersValidator<T> extends ColumnValidator<T> {
    constructor(columnName: keyof T) {
        super(columnName, "Forbidden characters");
    }

    checkCondition(cellData: T[keyof T]): boolean {
        return typeof cellData === "string" && !cellData.match(/[ ,.;:!?]+/);
    }
}

class URLValidator<T> extends ColumnValidator<T> {
    allowEmptyCells: boolean;

    constructor(columnName: keyof T, allowEmptyCells: boolean = false) {
        super(columnName, "Not a valid URL");
        this.allowEmptyCells = allowEmptyCells;
    }

    checkCondition(cellData: T[keyof T]): boolean {
        if (this.allowEmptyCells) {
            return !cellData || isValidUrl(cellData);
        } else {
            return isValidUrl(cellData);
        }
    }
}

class AvailableValuesValidator<T> extends ColumnValidator<T> {
    availableValues: Set<T[keyof T]>;

    constructor(columnName: keyof T, values: T[keyof T][]) {
        super(columnName, "Unavailable value");
        this.availableValues = new Set(values);
    }

    checkCondition(cellData: T[keyof T]): boolean {
        if (typeof cellData === "string") {
            const itemsToCheck = cellData.split(";");
            return itemsToCheck.every((value) => this.availableValues.has(value as any));
        }
        return this.availableValues.has(cellData);
    }
}

class NumberValidator<T> extends ColumnValidator<T> {
    constructor(columnName: keyof T) {
        super(columnName, "Some values are not numbers");
    }

    checkCondition(cellData: T[keyof T]): boolean {
        return !isNaN(Number(cellData));
    }
}

interface ValidationError<T = OMSItemRenderType> {
    columnName: keyof T;
    reason: string;
    incorrectRows: number[];
}

function validateColumns<T>(data: T[], validators: ColumnValidator<T>[]): ValidationError<T>[] {
    const errors: ValidationError<T>[] = [];

    for (const validator of validators) {
        const incorrectRows = validator.validate(data);
        if (incorrectRows.length > 0) {
            errors.push({
                columnName: validator.columnName,
                reason: validator.errorMsg,
                incorrectRows,
            });
        }
    }

    return errors;
}

function showErrors<T>(
    errors: ValidationError<T>[],
    errorRowsLimit: number = 10,
    showRows: boolean = true,
    pageName: string | undefined = undefined
) {
    if (errors.length > 0) {
        const description = errors.map((error, i) => {
            let header = <b>{String(error.columnName)}:</b>;
            let msg = ` ${error.reason}`;
            let incorrectRowsNum = error.incorrectRows.length;
            if (incorrectRowsNum > 0 && showRows) {
                let msgSuffix = "";
                if (incorrectRowsNum > errorRowsLimit) {
                    msgSuffix = "...";
                }
                msg += ` (rows: ${error.incorrectRows.slice(0, errorRowsLimit).join(", ")}${msgSuffix})`;
            }
            return (
                <>
                    <p style={{margin: 0}} key={i}>
                        {header}
                        {msg}
                    </p>
                    <br />
                    <Button type="primary" onClick={() => copyToClipboard(error.incorrectRows.join("\n"), "Rows indexes copied!")}>
                        Copy for Filter
                    </Button>
                </>
            );
        });

        let message = "Validation error";
        if (pageName) {
            message += ` in ${pageName}`;
        }
        notification.error({
            message: message,
            description: <>{description}</>,
            duration: 6,
        });
    }
}

export function validateOMSMasterColumns(data: OMSItemRenderType[]): ValidationError[] {
    const omsMasterValidators: ColumnValidator[] = [new EmptyCellValidator("Quantity"), new EmptyCellValidator("Cost")];

    const errors = validateColumns(data, omsMasterValidators);
    showErrors(errors);

    return errors;
}

export function validateOMSStage1Columns(data: OMSItemRenderType[], supplierNames: string[]): ValidationError[] {
    const omsStage1Validators: ColumnValidator[] = [
        new EmptyCellValidator("Supplier_Name"),
        new EmptyCellValidator("Supplier_Date"),
        new EmptyCellValidator("Supplier_PO"),
        new EmptyCellValidator("Supplier_SKU"),
        new EmptyCellValidator("Supplier_Title"),
        new EmptyCellValidator("UPC"),
        new EmptyCellValidator("Quantity"),
        new EmptyCellValidator("Cost"),
        new EmptyCellValidator("Ship_Requested"),
        new EmptyCellValidator("OrderType"),
        new EmptyCellValidator("VendorType"),
        new UPCValidator(),
        new AvailableValuesValidator("Supplier_Name", supplierNames),
        new AvailableValuesValidator("OrderType", ORDER_TYPES.slice()),
        new AvailableValuesValidator("VendorType", VENDOR_TYPES.slice()),
    ];

    const errors = validateColumns(data, omsStage1Validators);
    showErrors(errors);

    return errors;
}

export function validateOMSStage2Columns(data: OMSItemRenderType[]): ValidationError[] {
    const omsStage2Validators: ColumnValidator[] = [
        new EmptyCellValidator("Supplier_PO"),
        new EmptyCellValidator("Supplier_Title"),
        new EmptyCellValidator("UPC"),
        new EmptyCellValidator("Quantity"),
        new EmptyCellValidator("Cost"),
    ];

    const errors = validateColumns(data, omsStage2Validators);
    showErrors(errors);

    return errors;
}

export function validateOMSStage3Columns(data: OMSItemRenderType[]): ValidationError[] {
    const omsStage3Validators: ColumnValidator[] = [
        new EmptyCellValidator("Supplier_PO"),
        new EmptyCellValidator("Quantity"),
        new EmptyCellValidator("Cost"),
    ];

    const errors = validateColumns(data, omsStage3Validators);
    showErrors(errors);

    return errors;
}

export function validateSuppliers(data: SupplierRenderType[]): ValidationError<SupplierRenderType>[] {
    const suppliersValidators: ColumnValidator<SupplierRenderType>[] = [
        new StringLengthValidator<SupplierRenderType>("name", 50),
        new DuplicationValidator<SupplierRenderType>("name", data),
        new PrefixValidator<SupplierRenderType>("name"),
        new CharactersValidator<SupplierRenderType>("name"),
        // new EmptyCellValidator<SupplierRenderType>("user"),
    ];

    const errors = validateColumns(data, suppliersValidators);
    showErrors(errors);

    return errors;
}

export function validateInvoices(data: InvoiceDataRenderType[]): ValidationError<InvoiceDataRenderType>[] {
    const emptyColumnsToCheck: (keyof InvoiceDataRenderType)[] = ["Supplier_Name", "Supplier_PO", "Dropbox_URL"];

    const invoicesValidators: ColumnValidator<InvoiceDataRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<InvoiceDataRenderType>(column)
    );

    invoicesValidators.push(new URLValidator<InvoiceDataRenderType>("Dropbox_URL"));

    const errors = validateColumns(data, invoicesValidators);
    showErrors(errors);

    return errors;
}

export function validateSellerSnapExport(data: SellerSnapExportDataRenderType[]): ValidationError<SellerSnapExportDataRenderType>[] {
    const emptyColumnsToCheck: (keyof SellerSnapExportDataRenderType)[] = ["cost", "min_price", "max_price"];

    const ssValidators: ColumnValidator<SellerSnapExportDataRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<SellerSnapExportDataRenderType>(column)
    );

    const errors = validateColumns(data, ssValidators);
    showErrors(errors);

    return errors;
}

export function validateAPTracker(
    data: APInvoiceTrackerDataRenderType[],
    supplierNames?: string[],
    showRows: boolean = true,
    showPageName: boolean = false
): ValidationError<APInvoiceTrackerDataRenderType>[] {
    const emptyColumnsToCheck: (keyof APInvoiceTrackerDataRenderType)[] = ["IssueDate", "DueDate", "Brand", "OrderAmount"];

    const invoicesValidators: ColumnValidator<APInvoiceTrackerDataRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<APInvoiceTrackerDataRenderType>(column)
    );
    invoicesValidators.push(new URLValidator<APInvoiceTrackerDataRenderType>("InvoiceFileURL", true));
    if (supplierNames) {
        invoicesValidators.push(new AvailableValuesValidator("Brand", supplierNames));
    }

    const errors = validateColumns(data, invoicesValidators);
    showErrors(errors, 10, showRows, showPageName ? "AP Tracker" : undefined);

    return errors;
}

export function validateBrandItems(
    data: BrandDashboardItemRenderType[],
    showRows: boolean = true,
    showPageName: boolean = false
): ValidationError<BrandDashboardItemRenderType>[] {
    const emptyColumnsToCheck: (keyof BrandDashboardItemRenderType)[] = ["ASIN", "Brand", "DateAdded"];

    const appendedData = data
        .map((item) => {
            return {
                ...item,
                "ASIN-UPC-SKU": [item.ASIN, item.UPC, item.SupplierSKU].join("-"),
            };
        })
        .filter((item) => item.deleted !== true);

    const invoicesValidators: ColumnValidator<BrandDashboardItemRenderType>[] = [
        ...emptyColumnsToCheck.map((column) => new EmptyCellValidator<BrandDashboardItemRenderType>(column)),
        new UPCValidator<BrandDashboardItemRenderType>(true),
        new DuplicationValidator<BrandDashboardItemRenderType>("ASIN", appendedData),
        new DuplicationValidator<BrandDashboardItemRenderType>("ASIN-UPC-SKU", appendedData),
    ];

    const errors = validateColumns(appendedData, invoicesValidators);
    showErrors(errors, 10, showRows, showPageName ? "Brand Items" : undefined);

    return errors;
}

export function validateFreightLog(data: FreightLogRowRenderType[]): ValidationError<FreightLogRowRenderType>[] {
    const emptyColumnsToCheck: (keyof FreightLogRowRenderType)[] = ["Supplier_Name", "Supplier_PO"];

    const invoicesValidators: ColumnValidator<FreightLogRowRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<FreightLogRowRenderType>(column)
    );
    invoicesValidators.push(new URLValidator<FreightLogRowRenderType>("ShipmentPlanFile", true));

    const errors = validateColumns(data, invoicesValidators);
    showErrors(errors);

    return errors;
}

export function validateFreightLogEntry(data: FreightLogItemizedEntryRenderType[]): ValidationError<FreightLogItemizedEntryRenderType>[] {
    const emptyColumnsToCheck: (keyof FreightLogItemizedEntryRenderType)[] = [
        "Supplier_Name",
        "Supplier_PO",
        "UnitsShipped",
        "UnitsBackordered",
        "ShipDateBooked",
        "TotalCost",
        "ShippedTo",
        "Booked",
    ];

    const invoicesValidators: ColumnValidator<FreightLogItemizedEntryRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<FreightLogItemizedEntryRenderType>(column)
    );

    invoicesValidators.push(
        ...[
            new NumberValidator<FreightLogItemizedEntryRenderType>("UnitsShipped"),
            new NumberValidator<FreightLogItemizedEntryRenderType>("UnitsBackordered"),
            new NumberValidator<FreightLogItemizedEntryRenderType>("TotalCost"),
            new URLValidator<FreightLogItemizedEntryRenderType>("ShipmentPlanFile", true),
        ]
    );

    const errors = validateColumns(data, invoicesValidators);
    showErrors(errors);

    return errors;
}

export function validateFreightLogItemized(data: FreightLogItemizedRowRenderType[]): ValidationError<FreightLogItemizedRowRenderType>[] {
    const emptyColumnsToCheck: (keyof FreightLogItemizedRowRenderType)[] = ["UnitsShipped", "UnitsBackordered", "TotalCost"];

    const invoicesValidators: ColumnValidator<FreightLogItemizedRowRenderType>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<FreightLogItemizedRowRenderType>(column)
    );

    const errors = validateColumns(data, invoicesValidators);
    showErrors(errors);

    return errors;
}

export function validateLedger(
    data: LedgerData[],
    supplierNames?: string[],
    showRows: boolean = true,
    showPageName: boolean = false
): ValidationError<LedgerData>[] {
    const emptyColumnsToCheck: (keyof LedgerData)[] = ["PaidAmount", "PaidDate"];

    const invoicesValidators: ColumnValidator<LedgerData>[] = emptyColumnsToCheck.map(
        (column) => new EmptyCellValidator<LedgerData>(column)
    );
    if (supplierNames) {
        invoicesValidators.push(new AvailableValuesValidator("Supplier_Name", supplierNames));
    }

    const errors = validateColumns(data, invoicesValidators);

    showErrors(errors, 10, showRows, showPageName ? "Ledger" : undefined);

    return errors;
}
