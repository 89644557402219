import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { getOMS } from '../../../services/WholesaleService';
import * as dataForge from 'data-forge';

interface GenerateScanpowerModalProps {
    pos?: string[];
    open: boolean;
    onClose: () => void;
}

export const GenerateScanpowerModal: React.FC<GenerateScanpowerModalProps> = ({
    open,
    onClose,
    pos,
}: GenerateScanpowerModalProps) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (pos) {
            form.setFieldValue('pos', pos.join(','));
        }
    }, [pos, form]);

    function downloadCSV(tsv: string, fileName: string) {
        const blob = new Blob([tsv], { type: 'text/comma-separated-values' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    const generate = async () => {
        setLoading(true);
        try {
            const token = await currentUser!.getIdToken();
            const { pos } = await form.validateFields();

            const generatedData = await getOMS(token, pos);
            
            const dataFrame = new dataForge.DataFrame(generatedData)
                .subset([
                    'Supplier_PO',
                    'Supplier_SO',
                    'Supplier_Name',
                    'Supplier_SKU',
                    'Supplier_Title',
                    'Supplier_Notes',
                    'UPC',
                    'Quantity',
                    'Cost',
                    'Upc Match?',
                    'ASIN',
                    'Max Price/List',
                    'MSKU',
                ])
                .renameSeries({
                    Supplier_PO: 'PO',
                    Supplier_SO: 'SO',
                    Supplier_Name: 'Source',
                    Supplier_SKU: 'Supp_SKU',
                    Supplier_Title: 'Title',
                    Supplier_Notes: 'Notes',
                    'Upc Match?': 'UPC Match?',
                    'Max Price/List': 'List Price',
                });
            
            const csv = dataFrame.toCSV();
            const filename =
                new Date().toISOString().slice(0, 10) + '-ScanPowerExport.csv';
            downloadCSV(csv, filename);
        } catch (e) {
            notification.error({
                // @ts-ignore
                description: e.message,
                message: 'Error',
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            title={'Please enter the POs or SOs to create the buysheet from:'}
            centered
            open={open}
            width={420}
            onCancel={onClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
            okButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <Form layout='vertical' form={form} onFinish={generate}>
                <Form.Item
                    name='pos'
                    label='POs and SOs'
                    rules={[{ required: true }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ alignItems: 'center' }}>
                    <Button
                        title='Generate'
                        loading={loading}
                        type='primary'
                        style={{ width: '100%' }}
                        htmlType='submit'
                    >
                        Generate
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
