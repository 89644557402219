import React, {useEffect, useState} from "react";
import {Modal, List, InputNumber, Button, Row, Col, Skeleton, Avatar, Typography, Card, Select} from "antd";
import {WarehouseProduct, BatchCategory, batchCategories} from "../../../types/WarehouseTypes";

interface ScannedItemsDialogProps {
    items: WarehouseProduct[];
    open: boolean;
    onClose: () => void;
    onOk: (updatedItem: WarehouseProduct) => void;
}

const properties: {label: string; key: keyof WarehouseProduct}[] = [
    {label: "Name", key: "name"},
    {label: "ASIN", key: "asin"},
    {label: "UPC", key: "upc"},
    {label: "SKU", key: "sku"},
    {label: "Inv Quantity", key: "invQuantity"},
    {label: "Batch Category", key: "batchCategory"},
];

const ExtractedItemsDialog: React.FC<ScannedItemsDialogProps> = ({items, open, onClose, onOk}) => {
    const [selectedItem, setSelectedItem] = useState<WarehouseProduct | null>(null);
    const [quantity, setQuantity] = useState<number>(1);
    const [batchCategory, setBatchCategory] = useState<BatchCategory>("Miscellaneous goods");

    useEffect(() => {
        if (open) {
            setQuantity(1);
            if (items.length > 0) {
                setSelectedItem(items[0]);
                setBatchCategory(items[0].batchCategory || "Miscellaneous goods");
            } else {
                setSelectedItem(null);
            }
        }
    }, [open, items]);

    const handleOk = () => {
        if (!selectedItem) return;
        const updatedItem = {...selectedItem, quantity, batchCategory};
        onOk(updatedItem);
    };

    const renderStatRow = (item: WarehouseProduct, key: keyof WarehouseProduct, label: string) => {
        let valueNode = <></>;
        if (key === "batchCategory") {
            valueNode = (
                <Select
                    value={batchCategory}
                    options={batchCategories.map((category) => ({
                        value: category,
                        label: <span>{category}</span>,
                    }))}
                    onChange={(value) => setBatchCategory(value)}
                    style={{width: 150}}
                />
            );
        } else if (key === "invData" || key === "dimensions") {
            valueNode = <></>;
        } else {
            valueNode = <Typography.Text>{item[key] ?? "n/a"}</Typography.Text>;
        }

        return (
            <List.Item>
                <Row style={{width: "100%"}}>
                    <Col
                        span={8}
                        style={{
                            justifyContent: "end",
                            width: "100%",
                        }}
                    >
                        <Typography.Text
                            type="secondary"
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {label}
                        </Typography.Text>
                    </Col>
                    <Col span={16}>{valueNode}</Col>
                </Row>
            </List.Item>
        );
    };

    return (
        <Modal title="Extracted Items" open={open} onOk={handleOk} onCancel={onClose} style={{maxHeight: "40vh"}}>
            <List
                dataSource={items}
                style={{maxHeight: "80vh", overflow: "auto"}}
                renderItem={(item) => (
                    <Card
                        style={{
                            // Add border to every item to prevent the list from jumping, only change color if selected
                            border: item === selectedItem ? "2px solid #28682EFF" : "2px solid #00000000",
                            borderRadius: "5px",
                        }}
                    >
                        <Row style={{width: "100%", paddingLeft: 4}}>
                            <Col span={10}>
                                <Row>
                                    {!item.imgURL ? (
                                        <Skeleton.Image style={{height: 150}} />
                                    ) : (
                                        <Avatar shape="square" size={150} src={item.imgURL} />
                                    )}
                                </Row>
                                <Row
                                    style={{
                                        marginTop: 10,
                                        width: "100%",
                                    }}
                                >
                                    {item === selectedItem ? (
                                        <>
                                            <Typography.Text>Quantity</Typography.Text>
                                            <InputNumber
                                                title="Quantity"
                                                min={1}
                                                value={quantity}
                                                onChange={(value) => setQuantity(value || 1)}
                                                style={{width: 150}}
                                            />
                                        </>
                                    ) : (
                                        <Button onClick={() => setSelectedItem(item)} style={{width: 150, height: 50}}>
                                            Select
                                        </Button>
                                    )}
                                </Row>
                            </Col>
                            <Col span={14}>
                                <List
                                    dataSource={properties}
                                    renderItem={(property) => renderStatRow(item, property.key, property.label)}
                                />
                            </Col>
                        </Row>
                    </Card>
                )}
            />
        </Modal>
    );
};

export default ExtractedItemsDialog;
