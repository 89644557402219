import { Select } from "antd";
import React from "react";

const ColumnSelect: React.FC<{onChange: (val: string) => void, columns: string[]}> = ({onChange, columns}) => {
    return (
        <Select style={{minWidth: '120px'}} onChange={onChange} placeholder="Choose the responding column...">
            {columns?.map((collection, i) => (
                <Select.Option value={collection} key={i}>
                {collection}
                </Select.Option>
            ))}
        </Select>
    )
}

export default ColumnSelect;