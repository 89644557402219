import React from "react";
import {Modal, List, Space, Typography} from "antd";

interface PrinterListDialogProps {
    printers: string[];
    selectedPrinter: string | undefined;
    onSelectPrinter: (printer: string) => void;
    open: boolean;
    onCancel: () => void;
}

const PrinterListDialog: React.FC<PrinterListDialogProps> = ({printers, selectedPrinter, onSelectPrinter, open, onCancel}) => {
    const handleSelectPrinter = (printer: string) => {
        onSelectPrinter(printer);
    };

    return (
        <Modal title="Printer List" open={open} onCancel={onCancel} footer={null}>
            <List
                dataSource={printers}
                renderItem={(printer) => (
                    <Space
                        onClick={() => handleSelectPrinter(printer)}
                        style={{
                            fontWeight: printer === selectedPrinter ? "bold" : "normal",
                            cursor: "pointer",
                            border: `1px solid ${printer === selectedPrinter ? "#365FE3" : "#00000000"}`,
                            width: "100%",
                            padding: 4,
                            margin: 4,
                            borderRadius: 4,
                        }}>
                        <Typography.Text>{printer}</Typography.Text>
                    </Space>
                )}
            />
        </Modal>
    );
};

export default PrinterListDialog;
