import {Column} from "@ant-design/charts";
import {useQuery} from "@tanstack/react-query";
import {Card, Col, Row, Spin, Statistic, Table} from "antd";
import {InputRef} from "antd/es/input";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {getScheduleData, getShipmentData} from "../../services/WholesaleService";
import {themeConst} from "../../services/constants";
import {
    getArrayRenderer,
    getDateRenderer,
    getDefaultDateSorter,
    getNumberFilterSorter,
    getNumberRenderer,
    getPriceRenderer,
} from "../utilities/TableFilterSorters";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const curTheme = localStorage.theme === themeConst.LIGHT ? "classic" : "classicDark";

const IsWithinPastXDays = (date: string, days: number) => {
    return dayjs(date).isSameOrAfter(dayjs().subtract(days, "day"), "day") && dayjs(date).isSameOrBefore(dayjs(), "day");
};

const GetWarehouseShipments = (address: RegExp, shipments: any[]) => {
    return shipments.filter((row: any) => address.test(row?.ShipFromAddress?.AddressLine1?.toLowerCase()));
};

const GetWarehouseItems = (shipments: any[], items: any[]) => {
    const warehouseItems = items.filter((row: any) => shipments.find((shipment: any) => shipment.ShipmentId === row.ShipmentId));
    return warehouseItems.reduce((acc: number, curr: any) => (acc += curr.QuantityShipped), 0);
};

const WAREHOUSE_BASE_DATA = [
    {
        Warehouse: "All",
        Regex: null,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "WI (OL)",
        Regex: /80 oliver/,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "WI (CH)",
        Regex: /2030 chestnut/,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "NJ",
        Regex: /1736 dutch/,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "DE",
        Regex: /250 executive/,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "IL",
        Regex: /2874 veterans dr/,
        Shipments: 0,
        Units: 0,
    },
    {
        Warehouse: "CA",
        Regex: /23891 via fabricante/,
        Shipments: 0,
        Units: 0,
    },
];

const GetWarehouseData = (shipments: any[], items: any[]) => {
    const warehouseData = [...WAREHOUSE_BASE_DATA];
    warehouseData.forEach((row: any) => {
        if (row.Regex) {
            row.Shipments = GetWarehouseShipments(row.Regex, shipments);
            row.Units = GetWarehouseItems(GetWarehouseShipments(row.Regex, shipments), items);
        } else {
            row.Shipments = shipments;
            row.Units = items.reduce((acc: number, curr: any) => (acc += curr.QuantityShipped), 0);
        }
    });

    return warehouseData;
};

const WarehouseDashboard: React.FC = () => {
    const {currentUser} = useAuth();
    const [stats, setStats] = useState<any>({
        unitsShippedPastWeekChestnut: 0,
        unitsShippedPastWeekOliver: 0,
        unitsShippedPastMonthChestnut: 0,
        unitsShippedPastMonthOliver: 0,
        returnsPastWeek: 0,
        returnsPastMonth: 0,
        hoursScheduledPastWeek: 0,
        unitsProcessedPerHour: 0,
    });
    const [chartData, setChartData] = useState<any[]>([]);
    const [incomingShipmentsData, setIncomingShipmentsData] = useState<any[]>([]);
    const searchInputRef = useRef<InputRef>(null);

    const {data: shipmentData, isFetching: shipmentDataLoading} = useQuery({
        queryKey: ["warehouse_data", "shipmentData"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getShipmentData(token, 30)),
        staleTime: Infinity,
    });

    const {data: scheduleData} = useQuery({
        queryKey: ["warehouse_data", "scheduleData"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getScheduleData(token)),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (shipmentData) {
            console.log(shipmentData);

            const shipments = shipmentData.result.shipments;
            const incomingShipments = shipmentData.result.incomingShipments;
            const items = shipmentData.result.items;
            const returns = shipmentData.result.returns;

            const GetWeekShipment = (timeFilter: any) => {
                const weekShipments = shipments.filter(timeFilter);
                const weekItems = items.filter((row: any) => weekShipments.find((shipment: any) => shipment.ShipmentId === row.ShipmentId));

                return GetWarehouseData(weekShipments, weekItems);
            };

            const data = [
                ...GetWeekShipment((row: any) => dayjs(row.insertTimestamp) > dayjs().startOf("week")).map((row: any) => ({
                    type: "Current Week",
                    ...row,
                })),
                ...GetWeekShipment(
                    (row: any) =>
                        dayjs(row.insertTimestamp) > dayjs().subtract(1, "week").startOf("week") &&
                        dayjs(row.insertTimestamp) <= dayjs().subtract(1, "week").endOf("week")
                ).map((row: any) => ({
                    type: "Last Week",
                    ...row,
                })),
                ...GetWeekShipment(
                    (row: any) =>
                        dayjs(row.insertTimestamp) > dayjs().subtract(2, "week").startOf("week") &&
                        dayjs(row.insertTimestamp) <= dayjs().subtract(2, "week").endOf("week")
                ).map((row: any) => ({
                    type: "2 weeks ago",
                    ...row,
                })),
                ...GetWeekShipment(
                    (row: any) =>
                        dayjs(row.insertTimestamp) > dayjs().subtract(3, "week").startOf("week") &&
                        dayjs(row.insertTimestamp) <= dayjs().subtract(3, "week").endOf("week")
                ).map((row: any) => ({
                    type: "3 weeks ago",
                    ...row,
                })),
            ];

            setStats({
                // unitsShippedPastWeek: items.filter((row: any) => dayjs(row.insertTimestamp) > dayjs().subtract(1, 'week').startOf('week') && dayjs(row.insertTimestamp) <= dayjs().subtract(1, 'week').endOf('week')).reduce((acc: number, curr: any) => acc += curr.QuantityShipped, 0),
                // unitsShippedPastMonth: items.filter((row: any) => IsWithinPastXDays(row.insertTimestamp, 30)).reduce((acc: number, curr: any) => acc += curr.QuantityShipped, 0),
                unitsShippedPastWeekChestnut: data
                    .filter((row: any) => row.type === "Last Week" && row.Warehouse === "WI (CH)")
                    .reduce((acc: number, curr: any) => (acc += curr.Units), 0),
                unitsShippedPastWeekOliver: data
                    .filter((row: any) => row.type === "Last Week" && row.Warehouse === "WI (OL)")
                    .reduce((acc: number, curr: any) => (acc += curr.Units), 0),
                unitsShippedPastMonthChestnut: data
                    .filter((row: any) => row.Warehouse.includes("WI (CH)"))
                    .reduce((acc: number, curr: any) => (acc += curr.Units), 0),
                unitsShippedPastMonthOliver: data
                    .filter((row: any) => row.Warehouse.includes("WI (OL)"))
                    .reduce((acc: number, curr: any) => (acc += curr.Units), 0),
                returnsPastWeek: returns
                    .filter(
                        (row: any) =>
                            dayjs(row.scanDate) > dayjs().subtract(1, "week").startOf("week") &&
                            dayjs(row.scanDate) <= dayjs().subtract(1, "week").endOf("week")
                    )
                    .reduce((acc: number, curr: any) => (acc += parseInt(curr["shipped-quantity"])), 0),
                returnsPastMonth: returns
                    .filter((row: any) => IsWithinPastXDays(row.scanDate, 30))
                    .reduce((acc: number, curr: any) => (acc += parseInt(curr["shipped-quantity"])), 0),
                hoursScheduledPastWeek: "N/A",
                unitsProcessedPerHour: "N/A",
            });

            setChartData(data);
            setIncomingShipmentsData(
                incomingShipments.filter(
                    (row: any) =>
                        // dayjs(row.ShipDateBooked) > dayjs().startOf('day')
                        !row.Arrived
                )
            );

            console.log(data);
        }
    }, [shipmentData]);

    useEffect(() => {
        if (scheduleData) {
            const parsedScheduleData = scheduleData
                .filter((row: any) => row[0] && dayjs(row[0]).isValid())
                .map((row: any) => ({date: dayjs(row[0]), hours: parseFloat(row[1])}))
                .filter(
                    (data: any) =>
                        dayjs(data.date) > dayjs().subtract(1, "week").startOf("week") &&
                        dayjs(data.date) <= dayjs().subtract(1, "week").endOf("week")
                );
            const totalHours = parsedScheduleData.reduce((acc: number, curr: any) => (acc += curr.hours), 0);
            setStats((prevStats: any) => ({
                ...prevStats,
                hoursScheduledPastWeek: totalHours,
                unitsProcessedPerHour: prevStats.unitsShippedPastWeekOliver / totalHours,
            }));
        }
    }, [scheduleData, stats]);

    const config = {
        data: chartData,
        title: {
            align: "center",
            title: "Units Shipped Past Month",
        },
        seriesField: "Warehouse",
        colorField: "Warehouse",
        xField: "type",
        yField: "Units",
        legend: true,
        label: {
            fill: "white",
            fillOpacity: 1,
            textBaseline: "bottom",
            position: "top",
            textAlign: "center",
        },
        axis: {
            x: {
                title: "Date",
            },
            y: {
                title: "Units shipped",
            },
        },
        theme: curTheme,
    };

    return (
        <Spin spinning={shipmentDataLoading}>
            <Row gutter={[8, 8]}>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Units Shipped Past Week (Oliver)</h3>}
                            value={stats.unitsShippedPastWeekOliver}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Units Shipped Past Month (Oliver)</h3>}
                            value={stats.unitsShippedPastMonthOliver}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Hours Scheduled Past Week (Oliver)</h3>}
                            value={stats.hoursScheduledPastWeek}
                            precision={0}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Units Processed / Hour (Oliver)</h3>}
                            value={stats.unitsProcessedPerHour}
                            precision={2}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Units Shipped Past Week (Chestnut)</h3>}
                            value={stats.unitsShippedPastWeekChestnut}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Units Shipped Past Month (Chestnut)</h3>}
                            value={stats.unitsShippedPastMonthChestnut}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Returns Units Processed Past Week</h3>}
                            value={stats.returnsPastWeek}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={3}>
                    <Card bordered={false}>
                        <Statistic
                            className="value-statistic"
                            title={<h3>Returns Units Processed Past Month</h3>}
                            value={stats.returnsPastMonth}
                            style={{overflowY: "auto", overflowWrap: "break-word", maxWidth: "100%", height: "160px"}}
                        />
                    </Card>
                </Col>
                <Col span={12} style={{height: 530, maxHeight: 530}}>
                    <Card bordered={false}>
                        <Column {...config} />
                    </Card>
                </Col>
                <Col span={12} style={{height: 530, maxHeight: 530}}>
                    <Card bordered={false} styles={{body: {maxHeight: 530, overflow: "auto"}}}>
                        <Table
                            dataSource={incomingShipmentsData}
                            columns={[
                                {
                                    title: "Supplier",
                                    dataIndex: "Supplier_Names",
                                    key: "Supplier_Names",
                                    width: "135px",
                                    fixed: "left",
                                    ...getArrayRenderer(),
                                    ...GetExpandedFilter([], "Supplier_Names"),
                                },
                                {
                                    title: "Ship Date Booked",
                                    dataIndex: "ShipDateBooked",
                                    key: "ShipDateBooked",
                                    fixed: "left",
                                    width: "110px",
                                    defaultSortOrder: "descend",
                                    ...getDefaultDateSorter("ShipDateBooked", searchInputRef),
                                    ...getDateRenderer(),
                                },
                                {
                                    title: "POs",
                                    dataIndex: "Supplier_POs",
                                    key: "Supplier_POs",
                                    width: "135px",
                                    fixed: "left",
                                    ...getArrayRenderer(),
                                    ...GetExpandedFilter([], "Supplier_POs"),
                                },
                                {
                                    title: "Pallets",
                                    dataIndex: "Pallets",
                                    key: "Pallets",
                                    width: "80px",
                                    ...getNumberFilterSorter("Pallets"),
                                    ...getNumberRenderer(undefined, ""),
                                },
                                {
                                    title: "Units",
                                    dataIndex: "Units",
                                    key: "Units",
                                    width: "80px",
                                    ...getNumberFilterSorter("Units"),
                                    ...getNumberRenderer(undefined, ""),
                                },
                                {
                                    title: "Ship Value",
                                    dataIndex: "ShipValue",
                                    key: "ShipValue",
                                    width: "90px",
                                    ...getNumberFilterSorter("ShipValue"),
                                    ...getPriceRenderer(""),
                                },
                                {
                                    title: "Ship To",
                                    dataIndex: "ShipTo",
                                    key: "ShipTo",
                                    width: "90px",
                                    ...GetExpandedFilter([], "ShipTo"),
                                },
                                {
                                    title: "Carrier",
                                    dataIndex: "Carrier",
                                    key: "Carrier",
                                    width: "135px",
                                    ...GetExpandedFilter([], "Carrier"),
                                },
                                {
                                    title: "ETA",
                                    dataIndex: "ETA",
                                    key: "ETA",
                                    width: "110px",
                                    ...getDefaultDateSorter("ETA", searchInputRef),
                                    ...getDateRenderer(),
                                },
                            ]}
                            pagination={false}
                        ></Table>
                    </Card>
                </Col>
            </Row>
        </Spin>
    );
};

export default WarehouseDashboard;
