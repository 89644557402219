import {Feed} from "@scaleleap/selling-partner-api-sdk/lib/api-models/feeds-api-model-v20210630";
import {
    PartneredEstimate,
    PartneredLtlDataOutput,
    PartneredSmallParcelDataOutput,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {UploadFile} from "antd";

export interface WarehouseAddress {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    stateOrProvinceCode: string;
    postalCode: string;
    countryCode: string;
    name: string;
    companyName?: string;
}

export interface WarehouseContact {
    name: string;
    phone: string;
    email: string;
    fax?: string;
}

export interface WarehouseShipLaterProduct {
    upc: string;
    asin: string;
    box: string;
    aisle: number;
    location: string;
    quantity: number;
    title: string;
    image: string;
    _id?: string;
}

export const sizeTiers = [
    "Small",
    "Large",
    "Small oversize",
    "Medium oversize",
    "Large oversize",
    "Special oversize",
    "Unspecified",
] as const;

export type SizeTier = (typeof sizeTiers)[number];

export const batchCategories = ["Shoes", "Oversized", "Standard", "Jewelry", "HazMat", "Miscellaneous goods", "Damaged"] as const;

export type BatchCategory = (typeof batchCategories)[number];

export interface InvData {
    sku: string;
    fnsku: string;
    quantity: number;
    condition: string;
}

export interface WarehouseProductBase {
    asin: string;
    brand: string;
    name: string;
    imgURL: string;
    allImgURLs?: string[];
    color: string;
    upc?: string;
    ean?: string;
    size?: string;
    salesRank?: number;
    sizeTier?: SizeTier;
    productGroup?: string;
    category?: string;
    description?: string;
    modelNumber?: string;
    partNumber?: string;
    style?: string;
    dimensions?: Dimensions;
}

export interface WarehouseProduct extends WarehouseProductBase {
    sku?: string;
    fnsku?: string;
    quantity: number;
    invQuantity?: number;
    condition?: string;
    invData: InvData[];
    batchCategory?: BatchCategory;
    batchId?: string;
    submittedQuantity?: number;
    email?: string;
    supplierPO?: string;
}

export interface Batch {
    _id: string;
    category: BatchCategory;
    date: Date;
    items?: WarehouseProduct[];
    finished: boolean;
    email?: string;
}

export interface InboundShipmentPlanStatus {
    shipmentId: string;
    status: "SUCCESS" | "ERROR";
    error?: string;
}

export interface Dimensions {
    length: number;
    width: number;
    height: number;
    weight: number;
    weightUnits?: string;
}

export interface InboundShipmentItem {
    FulfillmentNetworkSKU: string;
    SellerSKU: string;
    ShipmentId: string;
    ASIN: string;
    ImgURL?: string;
    Name?: string;
    PrepDetailsList: any; // Allowing any type for flexibility
    QuantityInCase: number;
    QuantityReceived: number;
    QuantityShipped: number;
    QuantityInBox?: number;
    QuantityLeft?: number;
    UPC?: string;
    DestinationFulfillmentCenterId?: string;
    PrintedQuantity?: number;
    productGroup?: string;
    cost?: number;
}

export const FREIGHT_CLASSES = ["60", "65", "70", "85", "92.5", "100", "125", "175", "200", "250", "300", "400"] as const;

export type FreightClass = (typeof FREIGHT_CLASSES)[number];

export const SHIPMENT_TYPES = ["SP", "LTL"] as const;

export type ShipmentType = (typeof SHIPMENT_TYPES)[number];

export type TransportStatus =
    | "WORKING"
    | "ESTIMATING"
    | "ESTIMATED"
    | "ERROR_ON_ESTIMATING"
    | "CONFIRMING"
    | "CONFIRMED"
    | "ERROR_ON_CONFIRMING"
    | "VOIDING"
    | "VOIDED"
    | "ERROR_IN_VOIDING"
    | "ERROR";

export type ShipmentStatus =
    | "WORKING"
    | "SHIPPED"
    | "RECEIVING"
    | "CANCELLED"
    | "DELETED"
    | "CLOSED"
    | "ERROR"
    | "IN_TRANSIT"
    | "DELIVERED"
    | "CHECKED_IN";

export interface InboundShipmentFeedResult {
    statusCode: string;
    messages: {resultCode: string; resultDescription: string}[];
    timestamp?: number;
}

export interface TransportDetails {
    status: TransportStatus;
    /**
     * Estimates extracted either from SP or LTL shipment data for convenience.
     */
    estimate?: PartneredEstimate;
    partneredSmallParcelData?: PartneredSmallParcelDataOutput;
    partneredLtlData?: PartneredLtlDataOutput;
    error?: any;
}

export interface InboundShipment {
    ShipmentId: string;
    AreCasesRequired: boolean;
    BoxContentsSource: string;
    DestinationFulfillmentCenterId: string;
    EstimatedBoxContentsFee: any;
    LabelPrepType: string;
    LastUpdatedAfter: string;
    LastUpdatedBefore: string;
    ShipFromAddress: any;
    ShipmentName: string;
    ShipmentStatus: ShipmentStatus;
    ExpectedItems: InboundShipmentItem[];
    palletsIds?: string[];
    activePallet?: string;
    shipmentType?: ShipmentType;
    feedResult?: InboundShipmentFeedResult;
    feed?: Partial<Feed>;
    transportDetails?: TransportDetails;
    lastUpdate?: number;
}

export interface InboundPallet {
    id: string;
    number: number;
    shipmentId: string;
    boxesIds: string[];
    dimensions: Dimensions;
    activeBox?: string;
}

export interface InboundBox {
    id: string;
    number: number;
    palletId: string;
    shipmentId: string;
    dimensions?: Dimensions;
    items: InboundShipmentItem[];
}

export interface UPCMapping {
    upc: string;
    asin: string;
    email: string;
}

export interface UPCNotif {
    upc: string;
    message: string;
    timestamp: Date;
}

export type ReturnAction = "relist" | "mf-used" | "other-sell-elsewhere" | "damage-discard" | "fraud-discard" | "fraud-keep";

export const RETURN_ACTION_LOOKUP: Record<ReturnAction, string> = {
    "relist": "Relist on Amazon",
    "mf-used": "MF - USED",
    "other-sell-elsewhere": "Sell elsewhere",
    "damage-discard": "Damaged - Donate",
    "fraud-discard": "Fraud - Donate",
    "fraud-keep": "Fraud - Sell in store",
};

export interface ReturnProduct extends Partial<WarehouseProductBase> {
    "license-plate-number": string;
    "tracking-number": string;
    "action"?: ReturnAction;
    "asin": string;
    "carrier": string;
    "customer-comments": string;
    "customer-order-id": string;
    "detailed-disposition": string;
    "disposition": string;
    "fnsku": string;
    "fulfillment-center-id": string;
    "id": string;
    "order-id": string;
    "product-name": string;
    "quantity": string;
    "reason": string;
    "removal-order-type": string;
    "request-date": string;
    "return-date": string;
    "scanDate": string;
    "shipment-date": string;
    "shipped-quantity": number;
    "sku": string;
    "status": string;
    "finished"?: boolean;
    "submitted"?: boolean;
    "caseCreated"?: boolean;
    "reimbursed"?: boolean;
    "photos"?: string[];
    "price"?: number;
    // eBay fields
    "eBayCondition"?: string;
    "eBayQuantity"?: number;
    "eBayAspects"?: any;
    "eBayCategory"?: EBayCategory;
    "eBayOfferId"?: string;
}

export interface ReturnProductRenderType extends ReturnProduct {
    key: React.Key;
}

export interface EBayCategory {
    categoryId: string;
    categoryName: string;
    categorySubtreeNodeHref?: string;
    categoryTreeNodeLevel?: number;
}

export interface EBayCategorySuggestion {
    category: EBayCategory;
    categoryTreeNodeAncestors: EBayCategory[];
}

export interface EBayAspectConstraint {
    aspectDataType: "DATE" | "NUMBER" | "STRING";
    aspectFormat?: string;
    itemToAspectCardinality: "MULTI" | "SINGLE";
    aspectMode: "SELECTION_ONLY" | "FREE_TEXT";
    aspectRequired: boolean;
    aspectUsage: string;
    aspectEnabledForVariations: boolean;
    aspectApplicableTo: ("ITEM" | "PRODUCT")[];
}

export interface EBayAspectValue {
    localizedValue: string;
}

export interface EBayAspect {
    localizedAspectName: string;
    aspectConstraint: EBayAspectConstraint;
    aspectValues: EBayAspectValue[];
}

export interface ItemCondition {
    conditionId: string;
    conditionDescription: string;
}

export interface ItemConditionPolicy {
    categoryTreeId: string;
    categoryId: string;
    itemConditionRequired: boolean;
    itemConditions: ItemCondition[];
}

export interface FileWithBytes {
    file: UploadFile;
    bytes: ArrayBuffer;
}
