import React, { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import WholesaleImport from '../components/wholesale/WholesaleImport';
import WholesaleTab from '../components/wholesale/WholesaleTab';
import { useQueryClient } from '@tanstack/react-query';
const { TabPane } = Tabs;

const Wholesale: React.FC = () => {
    const [forceKey, setForceKey] = useState('2');
    const queryClient = useQueryClient();

    const resetQueries = () => {
        window.history.replaceState(null, `Project Sourcing`, `/wholesale`);
        setForceKey('');
        queryClient.invalidateQueries({
            queryKey: ['wholesale'],
        });
        console.log('Reset queries due to tab change!');
    };

    useEffect(() => {
        const localCollection = new URLSearchParams(
            window.location.href.split('?')[1]
        ).get('collection');
        if (localCollection) {
            setForceKey(localCollection.includes('_saved') ? '3' : '2');
        }
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    onChange={resetQueries}
                    {...(forceKey ? { activeKey: forceKey } : {})}
                >
                    <TabPane tab='Import' key='1'>
                        <WholesaleImport></WholesaleImport>
                    </TabPane>
                    <TabPane tab='Browse all' key='2'>
                        <WholesaleTab name={'All'} saved={false}></WholesaleTab>
                    </TabPane>
                    <TabPane tab='Saved' key='3'>
                        <WholesaleTab
                            name={'Saved'}
                            saved={true}
                        ></WholesaleTab>
                    </TabPane>
                    <TabPane tab='Review' key='4'>
                        WIP
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    );
};

export default Wholesale;
