import {message} from "antd";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {getWholesaleStatistics} from "../../../services/WholesaleService";
import {downloadHelper} from "./Downloads";

export const processDataWSInfoHelper = async (data: ArrayBuffer[]) => {
    try {
        // read all the files
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData).subset(["ASIN", "asin", "Asin"]);
            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        // get unique ASINs
        const uniqueASINs = Array.from(new Set(fullOutput.map((row) => row["ASIN"] ?? row["asin"] ?? row["Asin"])));

        console.log(`Unique ASINs: ${uniqueASINs.length} | Total ASINs: ${fullOutput.length}`);
        console.log("Unique ASINs:", uniqueASINs);

        return getWholesaleStatistics(uniqueASINs)
            .then((res) => {
                message.success(`Successfully got Item Info for ${uniqueASINs.length} ASINs!`);
                downloadHelper(res, `WS-Info-${dayjs().format("YYYY-MM-DD")}`);
            })
            .catch((err) => {
                console.log(err);
                message.error("Failed to get Item Info! Please send file to Karol.");
            });
    } catch (ex) {
        console.log(ex);
        message.error("Failed to process! Please send file to Karol.");
    }
};
