import React, {useMemo} from "react";
import {Modal, Table, Skeleton, Avatar, Typography} from "antd";
import {InboundBox, InboundShipment, InboundShipmentItem} from "../../../types/WarehouseTypes";
import {ColumnsType} from "antd/lib/table";
import {useStore} from "../../../store/useStore";
import QuantityButton from "./QuantityButton";
import {countItemsByKey} from "./helpers";

interface RemainingItemsDialogProps {
    shipment: InboundShipment;
    open: boolean;
    onCancel: () => void;
    onSelect: (item: InboundShipmentItem, quantity: number) => void;
    onRemove: (item: InboundShipmentItem, quantity: number) => void;
    title?: string;
}

const RemainingItemsDialog: React.FC<RemainingItemsDialogProps> = ({shipment, open, onCancel, onSelect, onRemove, title}) => {
    const boxesStore = useStore((state) => state.boxes);

    const columns: ColumnsType<InboundShipmentItem> = [
        {
            title: "Image",
            dataIndex: "ImgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "Name",
            key: "name",
            width: "150px",
            render: (name) => (
                <Typography.Text ellipsis style={{width: "150px"}}>
                    {name}
                </Typography.Text>
            ),
        },
        {
            title: "UPC",
            dataIndex: "UPC",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "ASIN",
            key: "asin",
        },
        {
            title: "FNSKU",
            dataIndex: "FulfillmentNetworkSKU",
            key: "fnsku",
        },
        {
            title: "SKU",
            dataIndex: "SellerSKU",
            key: "sku",
            width: "150px",
        },
        {
            title: "Quantity",
            dataIndex: "QuantityShipped",
            key: "QuantityShipped",
        },
        {
            title: "Action",
            key: "action",
            render: (item: InboundShipmentItem) => (
                <QuantityButton
                    title="Add"
                    initialQuantity={item.QuantityLeft || 1}
                    maxQuantity={item.QuantityLeft || 1}
                    onAdd={(quantity) => onSelect(item, quantity)}
                    onDelete={(quantity) => {
                        onRemove(item, quantity);
                    }}
                />
            ),
        },
    ];

    const boxes = useMemo(() => {
        return Object.values(boxesStore.byId).filter((box) => box.shipmentId === shipment.ShipmentId) as InboundBox[];
    }, [boxesStore, shipment]);

    const remainingItems = useMemo(() => {
        const boxed = countItemsByKey(boxes);

        const allItems = shipment.ExpectedItems.map((item) => ({
            ...item,
            QuantityLeft: item.QuantityShipped - (boxed[item.SellerSKU] || 0),
        }));
        return allItems.filter((item) => item.QuantityLeft > 0);
    }, [boxes, shipment]);

    return (
        <Modal title={title || "Remaining items"} open={open} onCancel={onCancel} footer={null} width={"85vw"}>
            <Table
                style={{height: "50vh", overflow: "auto"}}
                dataSource={remainingItems}
                columns={columns}
                rowKey="SellerSKU"
                pagination={false}
            />
        </Modal>
    );
};

export default RemainingItemsDialog;
