import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Sourcing from "./routes/Sourcing";
import Wholesale from "./routes/Wholesale";

import "./App.css";
import {Layout} from "antd";
import Login from "./routes/auth/Login";
import PrivateRoute from "./components/PrivateRoute";
import {AuthProvider} from "./contexts/AuthContext";
import ForgotPassword from "./routes/auth/ForgotPassword";
import Brand from "./routes/Brand";
import Helpers from "./routes/Helpers";
import Omega from "./routes/Omega";
import {OmegaProvider} from "./contexts/OmegaContext";
import OMS from "./routes/OMS";
import ProjectHeader from "./components/navigation/Header";
import Warehouse from "./routes/Warehouse";

import {App as AntdApp} from "antd";
import Hotjar from "@hotjar/browser";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const {Content, Header} = Layout;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

function App() {
    const siteId = 4954343;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <AntdApp>
                    <AuthProvider>
                        <OmegaProvider>
                            <Layout className="layout">
                                <Header style={{height: "auto"}}>
                                    <ProjectHeader></ProjectHeader>
                                </Header>
                                <Content
                                    style={{
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className="site-layout-content"
                                        style={{
                                            flexBasis: "98vw",
                                            maxWidth: "98vw",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Routes>
                                            <Route
                                                path="/sourcing"
                                                element={
                                                    <PrivateRoute desiredRole="MANAGER,SOURCING">
                                                        <Sourcing />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/wholesale"
                                                element={
                                                    <PrivateRoute desiredRole="WHOLESALE,NOTIFS">
                                                        <Wholesale />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/brand"
                                                element={
                                                    <PrivateRoute desiredRole="WHOLESALE,BRAND">
                                                        <Brand />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/omega"
                                                element={
                                                    <PrivateRoute desiredRole="OMEGA">
                                                        <Omega />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/oms"
                                                element={
                                                    <PrivateRoute desiredRole="OMS">
                                                        <OMS />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/helpers"
                                                element={
                                                    <PrivateRoute desiredRole="WHOLESALE,NOTIFS">
                                                        <Helpers />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/warehouse"
                                                element={
                                                    <PrivateRoute desiredRole="MANAGER,WAREHOUSE">
                                                        <Warehouse />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route path="/login" element={<Login />} />

                                            {/* <Route path="/signup" element={<Signup />} /> */}

                                            <Route path="/forgot-password" element={<ForgotPassword />} />

                                            <Route path="/" />
                                        </Routes>
                                    </div>
                                </Content>
                            </Layout>
                        </OmegaProvider>
                    </AuthProvider>
                </AntdApp>
            </QueryClientProvider>
        </Router>
    );
}

export default App;
