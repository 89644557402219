import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import InsightsTable from "./InsightsTable";
import { SingularItem } from "../../types/OmegaTypes";
import { Col, Divider, Popover, Row, Statistic, Tag } from "antd";
import OrdersPopover from "./OrdersPopover";
import { ReconciliationOutlined } from "@ant-design/icons";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const PreviousNotePopup = ({ productData }: { productData: SingularItem }) => {
    return (
        <Row gutter={[12, 20]}>
            <Col span={17}>
                <InsightsTable prevData={null} insight={productData.insight} lite={true} ></InsightsTable>
            </Col>

            <Col span={1}>
                <Divider type="vertical" style={{ height: '100%' }}></Divider>
            </Col>

            <Col span={3} style={{ textAlign: 'center', justifyContent: 'space-around', display: 'flex', flexDirection: 'column' }} >
                {/* <Statistic title="Cost" value={productData.Cost} precision={2} prefix={'$'} /> */}
                <Statistic title="Fulfillable" value={productData.ssItem.fulfillable_quantity} precision={0} />
                <Statistic title="Min Price" value={productData.ssItem.min_price} precision={2} prefix={'$'} />
                <Statistic title={<>Curr BB <Tag color={productData.ssItem.bb_fulfillment === 'FBM' ? 'rgb(255 0 0)' : 'green'} style={{ marginRight: 0 }}>{productData.ssItem.bb_fulfillment}</Tag></>} value={productData.ssItem.cur_buybox_price ?? 'Suppressed'} precision={2} prefix={'$'} />
                <Divider style={{ margin: '10px 0' }}></Divider>
                <Statistic title="BuyBox Share" value={productData.BBShare} precision={2} suffix={'%'} />
                <Statistic title="Orders" value={productData.ssItem.total_order_items} precision={0}
                    formatter={(val) =>
                        productData.ordersData
                            ?
                            <Popover
                                trigger='click'
                                content={
                                    <OrdersPopover ordersData={productData.ordersData} />
                                }
                            >
                                <span style={{ textAlign: 'center' }}>{val} <ReconciliationOutlined type="primary" /></span>
                            </Popover>
                            :
                            `${val}`
                    }
                />
                <Statistic title="GMROI" value={productData.GMROI >= 0 ? productData.GMROI : 'Unknown'} precision={2} />
            </Col>

            <Col span={3} style={{ textAlign: 'center', justifyContent: 'space-around', display: 'flex', flexDirection: 'column', gap: '6px' }} >
                <Statistic title={<>Listed <Tag color={productData.ssItem.fulfillment_channel === 'FBM' ? 'rgb(255 0 0)' : 'green'} style={{ marginRight: 0 }}>{productData.ssItem.fulfillment_channel}</Tag></>} value={productData.ssItem.listed_price} precision={2} prefix={'$'} />
                <Statistic title="Max Price" value={productData.ssItem.max_price || 'Unknown'} precision={2} prefix={'$'} />
                <Statistic title="Avg. Sell Price" value={productData.ssItem.avg_selling_price ?? 'Unknown'} precision={2} prefix={'$'} />
                <Divider style={{ margin: '10px 0' }}></Divider>
                <Statistic title="Sessions" value={`${productData.OurSessions} / ${productData.Sessions}`} />
                <Statistic title="All Sales:" value={productData["EstSales (30D)"]} precision={2} />
                <Statistic title="CR" value={productData.CR >= 0 ? productData.CR : 'Unknown'} precision={2} suffix={'%'} />
            </Col>
        </Row>
    );
};

export default PreviousNotePopup;
