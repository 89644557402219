import React, {useState} from "react";
import {Button, Card, Checkbox, Col, Divider, Modal, Row, Space, Typography} from "antd";
import {InboundBox} from "../../../types/WarehouseTypes";
import {CopyOutlined, DeleteOutlined, EditOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import DimensionsDialog from "./DimensionsDialog";
import EditBoxDialog from "./EditBoxDialog";

interface BoxViewProps {
    box: InboundBox;
}

const BoxView: React.FC<BoxViewProps> = ({box}) => {
    const [dimensionsDialogOpen, setDimensionsDialogOpen] = useState(false);
    const [editBoxDialogOpen, setEditBoxDialogOpen] = useState(false);
    const palletsStore = useStore((state) => state.pallets);
    const boxingActions = useStore(
        useShallow((state) => ({
            setActiveBox: state.setActiveBox,
            removeBox: state.removeBox,
            addBoxDimensions: state.addBoxDimensions,
            duplicateBox: state.duplicateBox,
        }))
    );

    if (!box) {
        return null;
    }

    const pallet = palletsStore.byId[box.palletId];

    const weight = box.dimensions?.weight.toString() || "--";
    const height = box.dimensions?.height.toString() || "--";
    const width = box.dimensions?.width.toString() || "--";
    const length = box.dimensions?.length.toString() || "--";

    return (
        <Card
            title={`Box ${box.number}`}
            style={{width: 160, margin: 4, borderColor: pallet.activeBox === box.id ? "#3166D4" : undefined}}
            styles={{body: {padding: 4}}}
            extra={<Button icon={<DeleteOutlined />} onClick={() => boxingActions.removeBox(box.id)} type="text" />}
            actions={[
                <Checkbox
                    key="select"
                    checked={pallet.activeBox === box.id}
                    onClick={() => boxingActions.setActiveBox(box.palletId, box.id)}
                    style={{fontSize: 15}}
                />,
                <UnorderedListOutlined key="items" style={{fontSize: 15}} onClick={() => setEditBoxDialogOpen(true)} />,
                <EditOutlined key="edit" style={{fontSize: 15}} onClick={() => setDimensionsDialogOpen(true)} />,
                <CopyOutlined key="copy" style={{fontSize: 15}} onClick={() => boxingActions.duplicateBox(box.id)} />,
            ]}
        >
            <Row justify={"space-around"} style={{margin: 0, padding: 0}}>
                <Space>
                    <Typography.Text style={{fontSize: 12}} strong>
                        {box.items.length}
                    </Typography.Text>
                    <Typography.Text style={{fontSize: 10}} type="secondary">
                        {" "}
                        SKUs
                    </Typography.Text>
                </Space>
                <Space>
                    <Typography.Text style={{fontSize: 12}} strong>
                        {box.items.reduce((acc, item) => acc + (item.QuantityInBox || 0), 0)}
                    </Typography.Text>
                    <Typography.Text style={{fontSize: 10}} type="secondary">
                        {" "}
                        Units
                    </Typography.Text>
                </Space>
            </Row>
            <Row justify={"center"} align={"middle"} style={{textAlign: "center"}}>
                <Col span={15}>
                    <Typography.Text type="secondary">
                        {height} x {width} x {length} in
                    </Typography.Text>
                </Col>
                <Divider type="vertical" style={{margin: 0}} />
                <Col span={8}>
                    <Typography.Text type="secondary">{weight} lbs</Typography.Text>
                </Col>
            </Row>
            <DimensionsDialog
                title="Edit Box Dimensions"
                dimensions={box.dimensions}
                open={dimensionsDialogOpen}
                onCancel={() => {
                    setDimensionsDialogOpen(false);
                }}
                onSave={(dimensions) => {
                    if (dimensions.weight > 45 || dimensions.height > 24 || dimensions.width > 24 || dimensions.length > 24) {
                        Modal.confirm({
                            title: "Warning",
                            content: "Box dimensions exceed Amazon's requirements. Are you sure you want to proceed?",
                            onOk: () => boxingActions.addBoxDimensions(box.id, dimensions),
                        });
                    } else {
                        boxingActions.addBoxDimensions(box.id, dimensions);
                    }
                    setDimensionsDialogOpen(false);
                }}
            />
            <EditBoxDialog box={box} open={editBoxDialogOpen} onCancel={() => setEditBoxDialogOpen(false)} />
        </Card>
    );
};

export default BoxView;
