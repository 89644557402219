import {GetExpandedFilter} from "../../utilities/ExpandedFilterDropdown";
import React from "react";
import {InputRef, Space, Tag, Tooltip, message, notification} from "antd";
import {
    getDateRenderer,
    getDefaultDateSorter,
    getModifiedCellRenderer,
    getNumberFilterSorter,
    getPriceRenderer,
    getShareRenderer,
    getNumberRenderer,
} from "../../utilities/TableFilterSorters.js";
import {EditableColumnType} from "../../omega/table/EditableCell";
import {BrandDashboardItem, BrandDashboardItemRenderType, GlobalNoteItem} from "../../../types/Brand";
import {BRAND_OPPORTUNITIES, INVOLVEMENT_MODES} from "../../../types/OmegaTypes";
import Link from "antd/es/typography/Link";
import StrictNotesRenderer from "../../utilities/StrictNotesRenderer";
import {ExpandedNote} from "../../utilities/NotesRenderer";
import {postGlobalNote} from "../../../services/BrandService";
import {InfoCircleFilled} from "@ant-design/icons";

const getInvolvementTag = (value: string) => {
    switch (value) {
        case "MG Exclusive":
            return (
                <Tag style={{marginRight: "0"}} color="success">
                    {value}
                </Tag>
            );
        case "":
            return <></>;
        case undefined:
            return <></>;
        default:
            return <Tag style={{marginRight: "0"}}>{value}</Tag>;
    }
};

export const makeBrandDashboardItemsColumns = (
    searchInputRef: React.RefObject<InputRef>,
    dbData: {[key: string]: BrandDashboardItem},
    handleSave?: (row: BrandDashboardItemRenderType) => void
): EditableColumnType<BrandDashboardItemRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "50px",
        align: "center",
        ...GetExpandedFilter([], "key", undefined, true),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        width: "85px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "ASIN"),
        render: (text: string) => (
            <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                {text}
            </Link>
        ),
        extendedFilterClickHandling: true,
    },
    {
        title: "Parent",
        dataIndex: "ParentASIN",
        key: "ParentASIN",
        width: "95px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "ParentASIN"),
        render: (text: string) => (
            <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                {text}
            </Link>
        ),
        extendedFilterClickHandling: true,
    },
    {
        title: "Brand",
        dataIndex: "Brand",
        key: "Brand",
        width: "100px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "Brand"),
    },
    {
        title: "SKU",
        dataIndex: "SupplierSKU",
        key: "SupplierSKU",
        width: "100px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "SupplierSKU"),
        extendedFilterClickHandling: true,
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        width: "100px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "UPC"),
        extendedFilterClickHandling: true,
    },
    {
        title: "Title",
        dataIndex: "Title",
        key: "Title",
        width: "250px",
        editable: true,
        align: "center",
        ...GetExpandedFilter([], "Title"),
        ...getModifiedCellRenderer("Title", dbData, "key"),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "80px",
        editable: true,
        inputType: "price",
        required: true,
        align: "center",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer("?"),
        ...getModifiedCellRenderer("Cost", dbData, "key"),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "80px",
        editable: true,
        inputType: "price",
        required: true,
        align: "center",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
        ...getModifiedCellRenderer("MAP", dbData, "key"),
    },
    // {
    //     title: 'MSRP',
    //     dataIndex: 'MSRP',
    //     key: 'MSRP',
    //     width: '80px',
    //     editable: true,
    //     inputType: 'price',
    //     align: 'center',
    //     ...getNumberFilterSorter('MSRP'),
    //     ...getPriceRenderer(''),
    //     ...getModifiedCellRenderer('MSRP', dbData, 'key'),
    // },
    {
        title: () => (
            <Tooltip placement="top" title="Our possible revenue on 100% BBShare">
                Revenue <InfoCircleFilled></InfoCircleFilled>
            </Tooltip>
        ),
        dataIndex: "Revenue",
        key: "Revenue",
        width: "90px",
        editable: true,
        inputType: "price",
        align: "center",
        ...getNumberFilterSorter("Revenue"),
        ...getPriceRenderer(""),
    },
    {
        title: () => (
            <Tooltip placement="top" title="Our current revenue (past 30 days)">
                OurRev <InfoCircleFilled></InfoCircleFilled>
            </Tooltip>
        ),
        dataIndex: "OurRevenue",
        key: "OurRevenue",
        width: "90px",
        editable: true,
        inputType: "price",
        align: "center",
        ...getNumberFilterSorter("OurRevenue"),
        ...getPriceRenderer(""),
    },
    {
        title: () => (
            <Tooltip placement="top" title="Our possible spend on 100% BBShare">
                Spend <InfoCircleFilled></InfoCircleFilled>
            </Tooltip>
        ),
        dataIndex: "Spend",
        key: "Spend",
        width: "85px",
        editable: true,
        inputType: "price",
        align: "center",
        ...getNumberFilterSorter("Spend"),
        ...getPriceRenderer(""),
    },
    {
        title: () => (
            <Tooltip placement="top" title="How many units move per month">
                UnitSales <InfoCircleFilled></InfoCircleFilled>
            </Tooltip>
        ),
        dataIndex: "UnitSales",
        key: "UnitSales",
        width: "100px",
        editable: true,
        inputType: "price",
        align: "center",
        ...getNumberFilterSorter("UnitSales"),
        ...getNumberRenderer(),
    },
    // {
    //     title: 'Follow MAP',
    //     dataIndex: 'followMap',
    //     key: 'followMap',
    //     width: '75px',
    //     editable: true,
    //     inputType: 'yes/no',
    //     align: 'center',
    //     ...getModifiedCellRenderer('followMap', dbData, 'key'),
    //     ...getBooleanFilter('followMap'),
    // },
    // {
    //     title: 'Follow BizMAP',
    //     dataIndex: 'followBizMap',
    //     key: 'followBizMap',
    //     width: '85px',
    //     editable: true,
    //     inputType: 'yes/no',
    //     align: 'center',
    //     ...getModifiedCellRenderer('followBizMap', dbData, 'key'),
    //     ...getBooleanFilter('followBizMap'),
    // },
    {
        title: "Involvement",
        dataIndex: "ourInvolvement",
        key: "ourInvolvement",
        width: "100px",
        editable: true,
        inputType: "select",
        inputOptions: {
            selectableValues: INVOLVEMENT_MODES.slice(),
        },
        align: "center",
        ...getModifiedCellRenderer("ourInvolvement", dbData, "key"),
        ...GetExpandedFilter(INVOLVEMENT_MODES.slice(), "ourInvolvement"),
        render: (value, record) => {
            return getInvolvementTag(value);
        },
    },
    {
        title: "Discount Needed",
        dataIndex: "DiscountNeeded",
        key: "DiscountNeeded",
        width: "95px",
        editable: true,
        inputType: "int",
        align: "center",
        ...getNumberFilterSorter("DiscountNeeded"),
        ...getShareRenderer(0, ""),
    },
    // {
    //     title: 'Exclusivity Value',
    //     dataIndex: 'ExclusivityValue',
    //     key: 'ExclusivityValue',
    //     width: '95px',
    //     editable: true,
    //     inputType: 'price',
    //     align: 'center',
    //     ...getNumberFilterSorter('ExclusivityValue'),
    //     ...getPriceRenderer(''),
    // },
    {
        title: "Prep",
        dataIndex: "prepInstructions",
        key: "prepInstructions",
        width: "100px",
        editable: true,
        inputType: "text",
        align: "center",
        ...getModifiedCellRenderer("prepInstructions", dbData, "key"),
        ...GetExpandedFilter([], "prepInstructions"),
    },
    {
        title: "Opportunities",
        key: "opportunities",
        dataIndex: "opportunities",
        width: "100px",
        editable: true,
        inputType: "tags",
        inputOptions: {
            selectableValues: BRAND_OPPORTUNITIES.slice(),
            isArray: true,
        },
        ...GetExpandedFilter(BRAND_OPPORTUNITIES, "opportunities"),
        render: (opportunities) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {opportunities?.map((issue: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {issue.toUpperCase()}
                    </Tag>
                ))}
            </Space>
        ),
    },
    {
        title: "Notes",
        key: "Notes",
        dataIndex: "Notes",
        width: "225px",
        ...GetExpandedFilter([], "Notes", (record) =>
            record.Notes.map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join(",")
        ),
        render: (value, record) => (
            <StrictNotesRenderer
                editable={!!handleSave}
                notes={value}
                onFinish={(newNotes) => {
                    const newGlobalNotes: GlobalNoteItem[] = newNotes.map((note) => ({
                        ASIN: record.ASIN,
                        ParentASIN: record.ParentASIN ?? "",
                        isParentNote: false,
                        ...note,
                    }));

                    const globalNotesToSave = newGlobalNotes.filter((globalNote) => globalNote.edited);

                    // store the notes in the database
                    if (globalNotesToSave.length > 0) {
                        postGlobalNote(
                            "",
                            globalNotesToSave.map((globalNote) => ({...globalNote, edited: false}))
                        )
                            .then((res) => {
                                if (res.error === null) {
                                    message.success("Notes saved!");

                                    if (res.writeResult.upserted?.length > 0) {
                                        for (const upsertInfo of res.writeResult.upserted) {
                                            globalNotesToSave[upsertInfo.index]._id = upsertInfo._id;
                                        }
                                    }

                                    const newRecord = {
                                        ...record,
                                        Notes: newGlobalNotes.map((globalNote) => ({...globalNote, edited: false})),
                                    };

                                    console.log(newRecord);

                                    handleSave?.(newRecord);
                                } else {
                                    notification.error({message: `Failed to save notes for ASIN ${record.ASIN}`, description: res.error});
                                }
                            })
                            .catch((ex) => {
                                notification.error({message: `Failed to save notes for ASIN ${record.ASIN}`, description: ex.toString()});
                            });
                    }
                }}
            />
        ),
    },
    {
        title: "Issues",
        key: "Issues",
        dataIndex: "Issues",
        width: "85px",
        ...GetExpandedFilter([], "Issues"),
        render: (issues) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {issues?.map((issue: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {issue.toUpperCase()}
                    </Tag>
                ))}
            </Space>
        ),
    },
    // {
    //     title: 'Last WS Cost',
    //     dataIndex: 'lastWSCost',
    //     key: 'lastWSCost',
    //     width: '70px',
    //     editable: true,
    //     inputType: 'text',
    // },
    // {
    //     title: 'Last WS Coll',
    //     dataIndex: 'lastWSColl',
    //     key: 'lastWSColl',
    //     width: '70px',
    //     editable: true,
    //     inputType: 'text',
    // },
    {
        title: "Date Added",
        dataIndex: "DateAdded",
        key: "DateAdded",
        width: "100px",
        inputType: "date",
        align: "center",
        ...getDefaultDateSorter("DateAdded", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Date Updated",
        dataIndex: "DateUpdated",
        key: "DateUpdated",
        width: "100px",
        inputType: "date",
        align: "center",
        ...getDefaultDateSorter("DateUpdated", searchInputRef),
        ...getDateRenderer(),
    },
];
