import {Button, Col, Divider, Row, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import SupplierSelect from "./SupplierSelect";
import {uniq} from "lodash";

interface Item {
    Supplier_Name: string;
    Supplier_PO?: string;
    Supplier_SO?: string;
}

interface POSelectProps<T extends Item> {
    data?: T[];
    onSelectedPOsChange: (pos: string[]) => void;
    onSelectablePOsChange?: (pos: string[], allPOsNum: number) => void;
    onPOProductsChange: (products: {[key: string]: T[]}) => void;
    disabled?: boolean;
}

export function POSelect<T extends Item>({
    data,
    onSelectedPOsChange,
    onSelectablePOsChange,
    onPOProductsChange,
    disabled,
}: POSelectProps<T>) {
    const [selectedSupplier, setSelectedSupplier] = useState<string | undefined>();
    const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
    const [selectablePos, setSelectablePOs] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");
    const [supplierPOs, setSupplierPOs] = useState<{
        [key: string]: string[];
    }>({});

    useEffect(() => {
        if (data) {
            let localSupplierPOs: {
                [key: string]: string[];
            } = {};
            const localPOProducts: {
                [key: string]: T[];
            } = {};

            [...data].forEach((item) => {
                const currentSupplier = item.Supplier_Name;
                const currentPOIdentifier = `${currentSupplier} -- PO: ${item.Supplier_PO || "?"} -- SO: ${item.Supplier_SO || "?"}`;

                if (localSupplierPOs[currentSupplier]) {
                    localSupplierPOs[currentSupplier].push(currentPOIdentifier);
                } else {
                    localSupplierPOs[currentSupplier] = [currentPOIdentifier];
                }

                if (localPOProducts[currentPOIdentifier]) {
                    localPOProducts[currentPOIdentifier].push(item);
                } else {
                    localPOProducts[currentPOIdentifier] = [item];
                }
            });

            // Remove duplicated POs from the dictionary
            localSupplierPOs = Object.fromEntries(Object.entries(localSupplierPOs).map((entry) => [entry[0], uniq(entry[1])]));

            // If a supplier is already selected, check if it's still available
            if (selectedSupplier && !localSupplierPOs[selectedSupplier]) {
                setSelectedSupplier(undefined);
            } else if (selectedSupplier) {
                setSelectablePOs(localSupplierPOs[selectedSupplier].flat());
            } else {
                setSelectablePOs(Object.values(localSupplierPOs).flat());
            }

            setSupplierPOs(localSupplierPOs);
            onPOProductsChange(localPOProducts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        onSelectedPOsChange(selectedPOs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPOs]);

    useEffect(() => {
        if (onSelectablePOsChange) {
            onSelectablePOsChange(selectablePos, Object.keys(supplierPOs).length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectablePos, supplierPOs]);

    const handleChange = (value: string | string[] | undefined, type: string) => {
        if (type === "supplier") {
            setSelectedPOs([]);
            if (value) {
                setSelectablePOs(supplierPOs[value as string]);
            } else {
                setSelectablePOs(Object.values(supplierPOs).flat());
            }
        } else if (type === "pos") {
            setSelectedPOs(value as string[]);
        }
    };

    useEffect(() => {
        handleChange(selectedSupplier, "supplier");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSupplier]);

    return (
        <Row>
            <Col span={24}>
                <SupplierSelect
                    onChange={(value: string) => setSelectedSupplier(value)}
                    supplierPOs={supplierPOs}
                    disabled={disabled}
                    value={selectedSupplier}
                />
                <Select
                    mode="multiple"
                    size="large"
                    value={selectedPOs}
                    placeholder="Please select Supplier's POs"
                    filterOption={(input, option) => {
                        const inputIDs: string[] = input.split(" ");
                        return (
                            inputIDs.some((value) => (option!.label as unknown as string)?.toLowerCase().includes(value.toLowerCase())) ??
                            false
                        );
                    }}
                    onChange={(value: string[]) => handleChange(value, "pos")}
                    onSearch={(value) => setSearchText(value)}
                    style={{
                        width: "100%",
                        marginTop: 10,
                        overflowY: "auto",
                        maxHeight: "120px",
                    }}
                    disabled={disabled}
                    allowClear={true}
                    dropdownRender={(menu) => (
                        <div
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {menu}
                            <Divider style={{margin: "8px 0"}} />
                            <Space style={{padding: "0 8px 4px"}}>
                                <Button type="primary" onClick={() => handleChange(selectablePos, "pos")}>
                                    Select all
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={(e) => {
                                        const inputIDs: string[] = searchText.split(" ").map((el) => el.toLowerCase());
                                        handleChange(
                                            selectablePos.filter((po) => inputIDs.some((id) => po.toLowerCase().includes(id))),
                                            "pos"
                                        );
                                    }}
                                >
                                    Select filtered
                                </Button>
                                <Button type="primary" onClick={() => handleChange([], "pos")}>
                                    Select none
                                </Button>
                            </Space>
                        </div>
                    )}
                    options={selectablePos.map((PO, idx) => ({
                        label: PO,
                        value: PO,
                    }))}
                />
            </Col>
        </Row>
    );
}
