import React, {useEffect, useMemo, useState} from "react";
import {
    Modal,
    Card,
    DescriptionsProps,
    Descriptions,
    Space,
    Collapse,
    Table,
    Row,
    Col,
    Button,
    Popconfirm,
    Tooltip,
    Typography,
    Skeleton,
    Avatar,
} from "antd";
import {InboundShipmentPlan} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {InboundShipmentPlanStatus, WarehouseProduct} from "../../../types/WarehouseTypes";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const PlanDescription: React.FC<{
    shipmentPlan: InboundShipmentPlan;
    items: WarehouseProduct[];
    selected: boolean;
    onSelect: (value: boolean) => void;
    status?: InboundShipmentPlanStatus;
}> = ({shipmentPlan, items, selected, onSelect, status}) => {
    const address = shipmentPlan.ShipToAddress;
    const fields: DescriptionsProps["items"] = [
        {key: "2", label: "Address", children: address.AddressLine1},
        {key: "3", label: "City", children: address.City},
        {key: "4", label: "State", children: address.StateOrProvinceCode},
    ];
    const localItems = shipmentPlan.Items.map((item) => {
        const product = items.find((product) => product.sku === item.SellerSKU);
        return {...item, ...product};
    });

    const qtySum = localItems.reduce((acc, item) => acc + item.Quantity, 0);

    const borderColor = useMemo(() => {
        if (status?.status === "SUCCESS") {
            return "green";
        } else if (selected) {
            return "#365FE3";
        } else {
            return;
        }
    }, [selected, status]);

    return (
        <Card style={{borderColor: borderColor, marginTop: 4}}>
            <Space direction="vertical">
                <Row justify={"center"}>
                    <Col span={20}>
                        <Descriptions title={`Center ${shipmentPlan.DestinationFulfillmentCenterId}`} size="small" items={fields} />
                        <Collapse>
                            <Collapse.Panel header={`${localItems.length} items (${qtySum} units)`} key="1">
                                <Table
                                    dataSource={localItems}
                                    columns={[
                                        {
                                            title: "Image",
                                            dataIndex: "imgURL",
                                            key: "imgURL",
                                            render: (imgURL) =>
                                                !imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />,
                                        },
                                        {title: "Name", dataIndex: "name"},
                                        {title: "SKU", dataIndex: "SellerSKU"},
                                        {title: "Quantity", dataIndex: "Quantity"},
                                    ]}
                                    showHeader={false}
                                    pagination={false}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    <Col span={4} flex="1">
                        <Space direction="vertical" style={{alignItems: "center", width: "100%"}}>
                            {status?.status === "SUCCESS" ? (
                                <CheckCircleOutlined style={{fontSize: 40, color: "green"}} />
                            ) : (
                                <Button onClick={() => onSelect(!selected)}>{selected ? "Selected" : "Select"}</Button>
                            )}
                            {status?.status === "ERROR" && (
                                <Tooltip title={status?.error || "error"} color="red" placement="bottom">
                                    <CloseCircleOutlined style={{fontSize: 40, color: "red"}} />
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Space>
        </Card>
    );
};

const ShipmentsInfoDialog: React.FC<{
    shipmentPlans: InboundShipmentPlan[];
    shipmentPlansStatuses: InboundShipmentPlanStatus[];
    currentBatch: WarehouseProduct[];
    open: boolean;
    onClose: () => void;
    onCreateShipments: (selectedShipments: string[]) => void;
    onFinishBatch: () => void;
    createShipmentsLoading?: boolean;
    finishBatchLoading?: boolean;
}> = ({
    shipmentPlans,
    shipmentPlansStatuses,
    currentBatch,
    open,
    onClose,
    onCreateShipments,
    onFinishBatch,
    createShipmentsLoading,
    finishBatchLoading,
}) => {
    const [selectedPlans, setSelectedPlans] = useState<Set<string>>(new Set());
    const [statuses, setStatuses] = useState<{[key: string]: InboundShipmentPlanStatus}>({});

    useEffect(() => {
        const selected = shipmentPlans.reduce<Set<string>>((acc, plan) => {
            acc.add(plan.ShipmentId);
            return acc;
        }, new Set());
        setSelectedPlans(selected);
    }, [shipmentPlans]);

    useEffect(() => {
        const statusesMap = shipmentPlansStatuses.reduce<{[key: string]: InboundShipmentPlanStatus}>((acc, status) => {
            acc[status.shipmentId] = status;
            return acc;
        }, {});
        setStatuses(statusesMap);
    }, [shipmentPlansStatuses]);

    const selectedCount = useMemo(
        () => Array.from(selectedPlans).filter((id) => statuses[id]?.status !== "SUCCESS").length,
        [selectedPlans, statuses]
    );

    const failedCount = useMemo(() => shipmentPlansStatuses.filter((status) => status.status === "ERROR").length, [shipmentPlansStatuses]);
    const createdCount = useMemo(
        () => shipmentPlansStatuses.filter((status) => status.status === "SUCCESS").length,
        [shipmentPlansStatuses]
    );

    return (
        <Modal
            title="Shipment plans"
            open={open}
            width={800}
            style={{maxHeight: "50vh"}}
            footer={
                <Space>
                    {failedCount > 0 && <Typography.Text type="danger">{`${failedCount} shipments failed to create`}</Typography.Text>}
                    {createdCount === 0 && <Button onClick={onClose}>Cancel</Button>}
                    <Popconfirm
                        title={`Are you sure you want to create ${
                            selectedCount === shipmentPlans.length ? "all" : `${selectedCount} / ${shipmentPlans.length}`
                        } shipments?`}
                        onConfirm={() => {
                            const selectedPlansArray = Array.from(selectedPlans);
                            onCreateShipments(selectedPlansArray.filter((id) => statuses[id]?.status !== "SUCCESS"));
                        }}
                        okText="Yes"
                        cancelText="No"
                        disabled={selectedCount === 0}
                    >
                        <Button type="primary" loading={createShipmentsLoading} disabled={selectedCount === 0}>
                            Create shipments ({selectedCount})
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title={`All items that were not selected or failed will be moved to the next batch.`}
                        onConfirm={() => {
                            onFinishBatch();
                        }}
                        okText="Confirm"
                        cancelText="Cancel"
                        disabled={createdCount === 0}
                    >
                        <Button type="primary" disabled={createdCount === 0} loading={finishBatchLoading}>
                            Finish batch
                        </Button>
                    </Popconfirm>
                </Space>
            }
        >
            <Space style={{maxHeight: "70vh", overflow: "auto"}} direction="vertical">
                {shipmentPlans.map((shipmentPlan, index) => (
                    <PlanDescription
                        key={index}
                        shipmentPlan={shipmentPlan}
                        items={currentBatch}
                        selected={selectedPlans.has(shipmentPlan.ShipmentId)}
                        status={statuses[shipmentPlan.ShipmentId]}
                        onSelect={(value) => {
                            const selectedPlansCopy = new Set(selectedPlans);
                            if (value) {
                                selectedPlansCopy.add(shipmentPlan.ShipmentId);
                            } else {
                                selectedPlansCopy.delete(shipmentPlan.ShipmentId);
                            }
                            setSelectedPlans(selectedPlansCopy);
                        }}
                    />
                ))}
            </Space>
        </Modal>
    );
};

export default ShipmentsInfoDialog;
