import React from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import { Dayjs } from "dayjs";

export interface DateRangeFilterDropdownProps extends FilterDropdownProps {
  dateRange?: [Dayjs, Dayjs];
  setDateRange: (values?: [Dayjs, Dayjs]) => void;
}

export function DateRangeFilterDropdown(props: DateRangeFilterDropdownProps) {
  return (
    <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        // @ts-ignore
        value={props.dateRange}
        onChange={(values) => props.setDateRange(values as unknown as [Dayjs, Dayjs])}
        locale={locale}
        format="YYYY/DD/MM"
      />
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => {
          props.setSelectedKeys([Math.random()]);
          props.confirm({closeDropdown: true});
        }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          props.setDateRange(undefined);
          props.clearFilters?.();
          props.confirm();
        }}
      >
        Reset
      </Button>
    </div>
  );
}
