import { Table, Tooltip } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo } from "react";
import { CheckOutlined, WarningFilled, CrownOutlined } from "@ant-design/icons";
import { getNumberFilterSorter, getNumberRendererRepricerVariant, getPriceRenderer } from "../utilities/TableFilterSorters";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const columns = [
    {
        title: 'Seller',
        dataIndex: 'sellerName',
        key: 'sellerName',
        width: "150px",
        render: (value, record) =>
            record.inventoryCount > record.BandBaseSales ? (
                <>{value} <Tooltip trigger="hover" title="Over one month of stock"><CrownOutlined style={{color: 'yellow'}}  /></Tooltip></>
            ) : value
    },
    {
        title: 'Type',
        dataIndex: 'fba',
        key: 'fba',
        width: "50px",
        render: (val) => val ? "FBA" : "FBM"
    },
    {
        title: 'Price',
        dataIndex: 'priceTotal',
        key: 'priceTotal',
        width: "100px",
        ...getNumberFilterSorter('priceTotal'),
        ...getPriceRenderer(),
    },
    {
        title: 'Quantity',
        dataIndex: 'inventoryCount',
        key: 'inventoryCount',
        width: "100px",
        ...getNumberFilterSorter('inventoryCount')
    },
    {
        title: 'Change',
        dataIndex: 'changeSincePrev',
        key: 'changeSincePrev',
        width: "100px",
        ...getNumberFilterSorter('changeSincePrev')
    },
    {
        title: 'MOQ',
        dataIndex: 'moq',
        key: 'moq',
        width: "45px",
        render: (value, record) =>
            value || record.sellerName?.includes('Amazon.com') ? (
                <WarningFilled style={{color: 'red', width: '100%'}} />
            ) : null
    },
    {
        title: 'GMROI',
        dataIndex: 'GMROI',
        key: 'GMROI',
        width: "100px",
        ...getNumberFilterSorter('GMROI'),
        render: (val, record) => {
            return val === undefined || val === -1 || val === null
                ? "Unknown"
                : `${parseFloat(val).toFixed(2)}`;
        },
    },
    {
        title: 'Profit',
        dataIndex: 'Profit',
        key: 'Profit',
        width: "100px",
        ...getNumberFilterSorter('Profit'),
        ...getPriceRenderer(),
    },
    {
        title: 'Total Profit',
        dataIndex: 'TotalProfit',
        key: 'TotalProfit',
        width: "100px",
        ...getNumberFilterSorter('TotalProfit'),
        ...getPriceRenderer(),
    },
    {
        title: 'Your TTS',
        dataIndex: 'YourBandTTS',
        key: 'YourBandTTS',
        width: "100px",
        ...getNumberFilterSorter('YourBandTTS'),
        ...getNumberRendererRepricerVariant()
    },
    // {
    //     title: 'Price +%',
    //     dataIndex: 'PriceChange',
    //     key: 'PriceChange',
    //     width: "80px",
    //     ...getNumberFilterSorter('PriceChange'),
    //     ...getNumberRendererRepricerVariant(true)
    // },
    {
        title: 'BandSales',
        dataIndex: 'BandBaseSales',
        key: 'BandBaseSales',
        width: "100px",
        ...getNumberFilterSorter('BandBaseSales'),
        ...getNumberRendererRepricerVariant(true)
    },
    {
        title: 'Wait Period',
        dataIndex: 'BandWaitTime',
        key: 'BandWaitTime',
        width: "100px",
        ...getNumberFilterSorter('BandWaitTime'),
        ...getNumberRendererRepricerVariant()
    },
    // {
    //     title: 'Wait Benefit',
    //     dataIndex: 'WaitBenefit',
    //     key: 'WaitBenefit',
    //     width: "100px",
    //     ...getNumberFilterSorter('WaitBenefit'),
    //     ...getNumberRendererRepricerVariant(true)
    // },
    {
        title: 'Prime',
        dataIndex: 'prime',
        key: 'prime',
        width: "50px",
        render: (value) =>
            value ? (
                <CheckOutlined style={{ width: "100%" }} />
            ) : null
    },
]


const liteColumns = [
    {
        title: 'Seller',
        dataIndex: 'sellerName',
        key: 'sellerName',
        width: "150px",
        render: (value, record) =>
            record.inventoryCount > record.BandBaseSales ? (
                <>{value} <Tooltip trigger="hover" title="Over one month of stock"><CrownOutlined style={{color: 'yellow'}}  /></Tooltip></>
            ) : value
    },
    {
        title: 'Type',
        dataIndex: 'fba',
        key: 'fba',
        width: "50px",
        render: (val) => val ? "FBA" : "FBM"
    },
    {
        title: 'Price',
        dataIndex: 'priceTotal',
        key: 'priceTotal',
        width: "100px",
        ...getNumberFilterSorter('priceTotal'),
        ...getPriceRenderer(),
    },
    {
        title: 'Quantity',
        dataIndex: 'inventoryCount',
        key: 'inventoryCount',
        width: "100px",
        ...getNumberFilterSorter('inventoryCount')
    },
    {
        title: 'MOQ',
        dataIndex: 'moq',
        key: 'moq',
        width: "45px",
        render: (value, record) =>
            value || record.sellerName?.includes('Amazon.com') ? (
                <WarningFilled style={{color: 'red', width: '100%'}} />
            ) : null
    },
    {
        title: 'Prime',
        dataIndex: 'prime',
        key: 'prime',
        width: "50px",
        render: (value) =>
            value ? (
                <CheckOutlined style={{ width: "100%" }} />
            ) : null
    },
]

const InsightsTable = ({ insight, prevData, lite }) => {
    const tableData = useMemo(() => {
        return insight?.OfferData?.map((offer, idx) => ({ 
            ...offer, 
            IsBuyBoxWinner: offer.sellerId === insight.buyboxSellerId, 
            changeSincePrev: prevData?.OfferData?.find((prevOffer) => prevOffer.sellerName === offer.sellerName) ? offer.inventoryCount - prevData.OfferData.find((prevOffer) => prevOffer.sellerName === offer.sellerName)?.inventoryCount : offer.inventoryCount,
            key: idx 
        })) ?? []
    }, [insight, prevData])

    return (
        <Table
            rowKey={(row) => row.key}
            rowClassName={(record, index) => {
                let classNames = []
                
                if (record.sellerName === 'M Emporium') {
                    classNames.push("keepa-estsales")
                }

                return classNames.join(" ")
            }}
            bordered={true}
            columns={lite === true ? liteColumns : columns}
            pagination={{
                pageSizeOptions: ["10", "15", "25"],
                defaultPageSize: 10,
                showSizeChanger: true,
            }}
            scroll={{ y: "33vh" }}
            style={{height: '100%'}}
            sticky
            size="small"
            tableLayout={"fixed"}
            dataSource={tableData}
        />
    );
};

export default InsightsTable;
