import {DownloadOutlined} from "@ant-design/icons";
import {Card, Divider, message, Space, Statistic, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {getFeeData} from "../../../services/WholesaleService";
import "./Popover.scss";
import {WHOLESALE_CELL_COLORS, WholesaleDataType} from "./WholesaleColumns";
import {appendFeeDataToProduct} from "./WholesaleUtilities";

const ProfitPopover: React.FC<{record: WholesaleDataType; handleSave: any}> = React.memo(({record, handleSave}) => {
    const {currentUser} = useAuth();
    const [shippingCost, setShippingCost] = useState("");
    const [prepFee, setPrepFee] = useState("");
    const profitStyle = {border: "4px solid", borderRadius: "10px", padding: "3px", borderColor: "white"};
    const roiStyle = {border: "4px solid", borderRadius: "10px", padding: "3px", borderColor: "white"};

    if (record.ROI2 < 15) {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.RED;
    } else if (record.ROI2 < 25) {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.YELLOW;
    } else {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.GREEN;
    }

    if ((record.productGroup === "Shoes" && record.Profit > 5) || (record.productGroup !== "Shoes" && record.Profit > 2.5)) {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.GREEN;
    } else {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.RED;
    }

    if (record.currentBuyBoxPrice <= 0) {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.BLUE;
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.BLUE;
    }

    const handleFeeEdit = (val?: string) => {
        if (val) {
            if (Number.isNaN(parseFloat(val))) {
                return;
            }

            handleSave({...record, editedShippingCost: parseFloat(val), editSiblings: false});
            setShippingCost(parseFloat(val).toString());
        }
    };

    const handlePrepFeeEdit = (val?: string, SmallLightFeeApplied = false) => {
        if (val) {
            if (Number.isNaN(parseFloat(val))) {
                return;
            }

            handleSave({...record, prepFee: parseFloat(val), SmallLightFeeApplied: SmallLightFeeApplied, editSiblings: false});
            setPrepFee(parseFloat(val).toString());
        }
    };

    const updateRefFeeFromSPAPI = () => {
        if (record.sellPrice >= 0) {
            const reqObj = {
                asin: record.ASIN,
                price: record.sellPrice,
            };

            message.loading(`Grabbing Fee Data for ${record.ASIN}`);
            currentUser!
                .getIdToken()
                .then((token) => getFeeData(token, [reqObj]))
                .then((res) => {
                    if (res.result?.length > 0) {
                        for (const feeResult of res.result) {
                            if (feeResult.Status === "Success" && feeResult.FeesEstimateIdentifier.IdValue === record.ASIN) {
                                const newItem = appendFeeDataToProduct(record, feeResult);
                                handleSave(newItem);
                                message.success(`Fee Data grab finished!`);
                            } else {
                                message.error(`Failed to grab Fee Data for ${feeResult.FeesEstimateIdentifier.IdValue}!`);
                            }
                        }
                    } else {
                        if (res.error) {
                            message.error(res.error);
                        } else {
                            message.info("No results found - please try again!");
                        }
                    }
                });
        } else {
            message.error("Sell price must be greater than 0!");
        }
    };

    useEffect(() => {
        setShippingCost(record.shippingCost.toFixed(2));
        setPrepFee(record.prepFee.toFixed(2));
    }, [record.shippingCost, record.prepFee]);

    return (
        <Card size="small">
            <Space align="center" direction="horizontal" style={{display: "flex", justifyContent: "space-around"}}>
                <Statistic title="Sell Price:" value={record.sellPrice} precision={2} prefix={"$"} />
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic
                    title="Referral Fee:"
                    value={record.refFee}
                    suffix={<DownloadOutlined style={{color: "rgb(22, 104, 220)"}} onClick={updateRefFeeFromSPAPI} />}
                    precision={2}
                    prefix={"$"}
                />
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic
                    title="FBA Fee (Parent):"
                    value={prepFee}
                    formatter={(val) => (
                        <Typography.Text
                            className="editable-stat"
                            editable={{
                                icon: false,
                                tooltip: `Set the Pick & Pack Fee`,
                                onChange: (val) => handlePrepFeeEdit(val),
                                onEnd: () => handlePrepFeeEdit(""),
                                onCancel: () => handlePrepFeeEdit(""),
                            }}
                        >
                            ${val} <span onClick={() => handlePrepFeeEdit(record.meanFee)}>(${record.meanFee})</span>
                        </Typography.Text>
                    )}
                />
                {/* <Switch
                    checkedChildren="S&L ON"
                    unCheckedChildren="S&L OFF"
                    // defaultChecked={false}
                    checked={record.SmallLightFeeApplied}
                    disabled={record.sellPrice > 12}
                    onChange={(val) => handlePrepFeeEdit((val ? record.SmallLightFee : record.Fees).toFixed(2), val ? true : false)}
                /> */}
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic
                    title="Ship Fee:"
                    value={shippingCost}
                    formatter={(val) => (
                        <Typography.Text
                            className="editable-stat"
                            editable={{
                                icon: false,
                                tooltip: `Set the shipping fee`,
                                onChange: (val) => handleFeeEdit(val),
                                onEnd: () => handleFeeEdit(""),
                                onCancel: () => handleFeeEdit(""),
                            }}
                        >
                            ${val}
                        </Typography.Text>
                    )}
                />
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Returns:" value={record.totalReturnCost} precision={2} prefix={"$"} />
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic
                    title={`Storage ${record.Q4StorageFee ? "Q4 " : ""} (Volume):`}
                    value={`$${record.storageFee.toFixed(2)} (${(record.packVol / 1728).toFixed(3)} ft³)`}
                />
                <Divider></Divider>
                {/* <Typography> {'('} </Typography>
                <Statistic title="Volume:" value={record.packVol / 1728} precision={2} suffix={'ft³'}/>
                <Divider></Divider>
                <Typography> {')'} </Typography> */}
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Cost:" value={record.AskPrice} precision={2} prefix={"$"} />
                <Divider></Divider>
                <Typography> = </Typography>
                <Divider></Divider>
                <Statistic style={profitStyle} title="Profit:" value={record.Profit} precision={2} prefix={"$"} />
                <Divider></Divider>
                <Statistic style={roiStyle} title="ROI:" value={record.ROI2} precision={0} suffix={"%"} />
            </Space>
        </Card>
    );
});

export default ProfitPopover;
