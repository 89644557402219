import React, {useEffect, useState} from "react";
import {WarehouseProduct} from "../../../types/WarehouseTypes";
import {Avatar, Col, Descriptions, Modal, Row, Select, Skeleton, Typography} from "antd";

interface Props {
    item?: WarehouseProduct;
    onClose: () => void;
    open: boolean;
    onSave: (data: WarehouseProduct) => void;
}

const SelectInvDataDialog: React.FC<Props> = ({item, onClose, open, onSave}) => {
    const [selectedSku, setSelectedSku] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (item) {
            setSelectedSku(item.sku);
        }
    }, [item]);

    if (!item) {
        return null;
    }

    return (
        <Modal
            title="Select Inventory Data"
            open={open}
            onCancel={onClose}
            onOk={() => {
                const data = item.invData?.find((data) => data.sku === selectedSku);
                if (!data) {
                    return;
                }

                onSave({...item, fnsku: data.fnsku, invQuantity: data.quantity, sku: data.sku, condition: data.condition});
            }}
            width={"60vw"}
        >
            <Row>
                <Col span={7}>{item.imgURL ? <Avatar src={item.imgURL} size={140} shape="square" /> : <Skeleton.Image />}</Col>
                <Col span={17}>
                    <Descriptions
                        column={1}
                        size="small"
                        bordered
                        items={[
                            {key: "1", label: "Name", children: item.name},
                            {key: "2", label: "ASIN", children: item.asin},
                        ]}
                    />
                    {item.invData ? (
                        <Row align={"middle"} style={{marginTop: 8}}>
                            <Typography.Text style={{fontSize: 14}}>Selected SKU: </Typography.Text>
                            <Select
                                value={selectedSku}
                                onChange={(value) => setSelectedSku(value)}
                                style={{flex: 1, marginLeft: 8}}
                                options={item.invData.map((data) => ({
                                    value: data.sku,
                                    label: (
                                        <span>
                                            {data.sku} ({data.quantity}, {data.condition})
                                        </span>
                                    ),
                                }))}
                            />
                        </Row>
                    ) : (
                        <Typography.Text style={{fontSize: 14}}>No inventory data available</Typography.Text>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default SelectInvDataDialog;
