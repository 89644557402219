export const ApiKey: string = "maximumsourcing";

export const BrandApiKey: string = "buyerteam2907";

export const KeepaApiKey: string = "rrvqj5ft5nb2reie0agvilsvpclklsmp1kme0fn01diu0a7p32egrbrj1ntb4rmm";

export const SlackApiToken: string = "xoxb-435982843776-2281539260468-WIkWuZjt2mDxN9vTpM8v4AE4";

export const themeConst = {
    DARK: "Dark 🌙",
    LIGHT: "Light ☀️",
};

export const QZ_CERTIFICATE =
    "-----BEGIN CERTIFICATE-----\nMIIDxzCCAq+gAwIBAgIUXggMRV9P8cAzjQW92CLv/Ki51hcwDQYJKoZIhvcNAQEL\nBQAwcjELMAkGA1UEBhMCUEwxEzARBgNVBAgMCkRvbG55c2xhc2sxEDAOBgNVBAcM\nB1dyb2NsYXcxFDASBgNVBAoMC1Byb2plY3RTY2FuMRQwEgYDVQQLDAtQcm9qZWN0\nU2NhbjEQMA4GA1UEAwwHUHJvamVjdDAgFw0xOTA3MDUxOTM4MTJaGA8yMDUwMTIy\nODE5MzgxMlowcjELMAkGA1UEBhMCUEwxEzARBgNVBAgMCkRvbG55c2xhc2sxEDAO\nBgNVBAcMB1dyb2NsYXcxFDASBgNVBAoMC1Byb2plY3RTY2FuMRQwEgYDVQQLDAtQ\ncm9qZWN0U2NhbjEQMA4GA1UEAwwHUHJvamVjdDCCASIwDQYJKoZIhvcNAQEBBQAD\nggEPADCCAQoCggEBAKUs2+qJDXH3GW7RI1Zry3Fygqbg6z1cnZv7yCF4dTLxVizX\nZxeUcN9bnzLSQ2VSdYyulGLySyyIWJ0w3KQdnxQKfvIf+RtSTVZ+uNeJVZd8DbRz\nDpqtSN3BBXKHfnRulSTooHg7QKeNRC2XqiJfPc9C2JrDh4wj0wFrXkRWJ1MLfsdg\n7YXnrcqYd5jN8tuMQSWANu1ja0lihSjxrs/gMsQUM1Oj3TX2hTvvgdDxWrDr2mKj\nWJwUtOZ+qqp/MAzu5Ju2IsfNDSd/a6GBrSZlPZ/Es5zYdDGq3WA3gr0IUm+LdFl6\nYf9fRlN9hw7VRDErQgju8s5jEkY2c01uljmuVGkCAwEAAaNTMFEwHQYDVR0OBBYE\nFF9TLaOLs4guoiqTQgNElHrDeN3ZMB8GA1UdIwQYMBaAFF9TLaOLs4guoiqTQgNE\nlHrDeN3ZMA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBAKMQFdUU\nLfIxU3Z03rnv3fmlMYztb3bxmRT0hzvSKRIYddKWRnNBCY7u5TE+9J05YESNBzix\n96mAHcCAo8X21wxXLEASlgap092aVpRlav2za6xN1+tSpD4aqGXMkF3MkAzmpExi\nmUOj4WqhFCCnPVEScSlHgeiYVNAxdmT5rEIy0/7St6hLJCyXvsZ40I4zbAD6N7hr\nzdrPS3ZxKJgMSya8yxGeCeLuZ3fg8ZHJfacAzJQjvd42DOlo+5IsTygybIkhu96H\npzJxm8RtTagVsF6Pl8oWrs4QVB6Cr+Bqoqe6Mfp2v1wDWzeonH6HGZKlO5VqVQp6\nXebBc/++5zC9PDU=\n-----END CERTIFICATE-----";

export const QZ_PRIVATE_KEY =
    "-----BEGIN RSA PRIVATE KEY-----\nMIIEowIBAAKCAQEApSzb6okNcfcZbtEjVmvLcXKCpuDrPVydm/vIIXh1MvFWLNdn\nF5Rw31ufMtJDZVJ1jK6UYvJLLIhYnTDcpB2fFAp+8h/5G1JNVn6414lVl3wNtHMO\nmq1I3cEFcod+dG6VJOigeDtAp41ELZeqIl89z0LYmsOHjCPTAWteRFYnUwt+x2Dt\nheetyph3mM3y24xBJYA27WNrSWKFKPGuz+AyxBQzU6PdNfaFO++B0PFasOvaYqNY\nnBS05n6qqn8wDO7km7Yix80NJ39roYGtJmU9n8SznNh0MardYDeCvQhSb4t0WXph\n/19GU32HDtVEMStCCO7yzmMSRjZzTW6WOa5UaQIDAQABAoIBAFIu37zyNB5gM+dP\nZ6W4PKFVuGwPvPvq+QCDZjBBHw8ZxSbKhiDVWtPHYo6Gz8G6JYK52lrg80ZVEMIl\nbaK6mkv+bOtLvUY6zQAcTRvw2QVmU+tU1A9h6L9tKqixgdmsq8iRdkZcNO8o+qM0\nsxInETnuqJdYcjjoGjS6l0ypESff464aHPpzHuWwXL65DXBoa1xdP3yMeXCBuI28\n51eF4M37d82b0OWmbHZ+QyYloSfUakdXLb6cQ4FtrDfib47+f6ff3K8hjvJly0N3\npLdKAJ1EItFctYxGkizCZ9wPEM/sJS9OJ1I78J8gXQoHC2CweVeM1n9wTMlVDieU\nppzabYECgYEA0KJKoPKVEyysyVVFQlkdf9FEGZhbqJd+PpWUdWN8/RTJSdL+mgFv\n0ccp07kUMxlcsvzq+5EGjsRShoHV0rBkwrgNw+lRYv0uvfqOl50NtOfYBrDYRPTT\nMDJYeuofAMH3tE8aodJAqtqegCizsbCInVxAdulV202EssKrTkaZuIsCgYEAyqzE\nT+5kPfh+YMR+SzDdE7GfogdOr1/HUECKyDYEzwdAUK94jk95vwlqCD+9cVjVmz8F\nEsKfl7TBAvQKR56AeIMwwDox0bPTFVYIH3fkSNmNNWugqgRY0bTenhHKEN6XGgPx\nCIbIKbSHoVJgtp5QCVp7X+mFNIJaJA3hkWvpkVsCgYEAocem/QT+ah7zYS+ZBCjc\nQhgAqaRGNwY6I8eTli9uZUUwDOnq+XrxvYalvYK/0hvxkO2kPSypHB3KLHkTWEvl\n5kQYkHkk3hCbpT9cQN7NGSHKlu2c1mPOO4pep/lRzyGZtZy9dwv1j0b1L0XgiYHt\nfbBiYJLxO5vxsThY49rFKp8CgYAYmd3+Yc5zIz2b214FHHpJUK8yyLZJXVPhLuRp\npvzwcE3jPVlh1M3PXYIsXChPoNvUy1J/U3ZxwLQI+rxgsyGe/zWkr6uqa6x8k4PK\n5g+ktBuK13SaDosFcXHwN/KSF6K5uq7APTQAs/QepdQtdov6hqwhGURc+lGcvL6b\nm0CvrwKBgDQ0HlTPUvpqijhLwfhj96VPk6Pn2gu9UIrAFWfH/2w+n8bGSeM4689J\nZVQeN2SktzJSHFf4GKnApu8+wuwqUEzVL8IWslWnx+d2R2oC692Yw7nqxnFqEVJT\njyKwEiH9ELh5+k8xxWOTDV9mwgnAn6GTP85DY5PdGU1980o5ozs8\n-----END RSA PRIVATE KEY-----";
