import {makeCall} from "../services/common";
import {AppState, ImmerStateCreator, UserDataSlice} from "../types/storeTypes";

// Map between the key in the database and the key in the state
const REQUIRED_DATA: {[k: string]: keyof UserDataSlice} = {
    warehouse_address: "warehouseAddress",
    warehouse_contact_info: "warehouseContactInfo",
};

export const createUserDataSlice: ImmerStateCreator<AppState, UserDataSlice> = (set, get) => ({
    uid: undefined,
    warehouseAddress: undefined,
    warehouseContactInfo: undefined,
    setUid: (uid) => {
        set((state) => {
            state.uid = uid;
        });
    },
    saveWarehouseAddress: async (address) => {
        await makeCall("settings", {}, "PUT", {settings: [{key: "warehouse_address", value: address}]});
        set((state) => {
            state.warehouseAddress = address;
        });
    },
    saveWarehouseContactInfo: async (contactInfo) => {
        await makeCall("settings", {}, "PUT", {settings: [{key: "warehouse_contact_info", value: contactInfo}]});
        set((state) => {
            state.warehouseContactInfo = contactInfo;
        });
    },
    getUserData: async () => {
        const res = await makeCall(
            "settings",
            {
                searchKey: "key",
                searchValue: Object.keys(REQUIRED_DATA).join(","),
            },
            "GET"
        );

        res.result.forEach((setting: {value: any; key: string}) => {
            set((state) => {
                state[REQUIRED_DATA[setting.key]] = setting.value;
            });
        });
    },
});
