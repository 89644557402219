import { Col, Row, Tabs, TabsProps } from "antd";
import React from "react";
import { useTabLocationDashboard } from "./utilities/queryTab";
import BrandInsightsSummary from "./BrandInsightsSummary";
import BrandInsightsAnalyze from "./BrandInsightsAnalyze";


const items: TabsProps['items'] = [
    {
        key: 'analyze',
        label: `Analyze`,
        children: <BrandInsightsAnalyze></BrandInsightsAnalyze>,
    },
    {
        key: 'summary',
        label: `Summary`,
        children: <BrandInsightsSummary></BrandInsightsSummary>,
    },
];

const BrandInsights: React.FC = () => {
    const [activeKey, onChange] = useTabLocationDashboard(
        items.map((item) => item.key),
        'analyze',
        'insights_tab'
    )

    return (
        <Row>
            <Col span={24}>
                <Tabs items={items} activeKey={activeKey} onChange={onChange}/>
            </Col>
        </Row>
    )
}

export default BrandInsights;