import {Button, Col, InputRef, Modal, notification, Row, Space, Switch} from "antd";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {OMSItem, OMSItemRenderType, OMSTrackerItem, ORDER_TYPES, VENDOR_TYPES} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeStage1Columns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as dataForge from "data-forge";
import * as _ from "lodash";
import {UploadHandler} from "../../utilities/UploadHandler";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {getOMSTrackerEntry, pushTrackerData, updateOMS} from "../../../services/WholesaleService";
import {ApartmentOutlined, AuditOutlined, DeleteFilled, FieldStringOutlined, PullRequestOutlined} from "@ant-design/icons";
import {validateOMSStage1Columns} from "../../utilities/TableDataValidation";
import {OMSTableTitle} from "../table/OMSTableTitle";
import EditableTable from "../table/EditableTable";
import {
    BulkActionsButton,
    BulkActionType,
    useSuppliersColumnModal,
    useDateColumnModal,
    useSelectMultipleColumnModal,
    useSelectColumnModal,
} from "../table/BulkActions";
import {useSuppliers} from "./dataHandlers";
import TrackerEntryModal from "./TrackerEntryModal";

const defaultItem: OMSItem = {
    Cost: 0,
    Quantity: 0,
    Ship_Requested: new Date(),
    Supplier_Date: new Date(),
    Supplier_Name: "",
    Supplier_Notes: "",
    Supplier_PO: "",
    Supplier_SKU: "",
    Supplier_SO: "",
    Supplier_Title: "",
    ASIN: undefined,
    UPC: "",
};

const requiredColumns = [
    "Supplier_Name",
    "Supplier_Date",
    "Supplier_PO",
    "Supplier_SO",
    "Supplier_SKU",
    "Supplier_Title",
    "Supplier_Notes",
    "ASIN",
    "UPC",
    "Quantity",
    "Cost",
    "Ship_Requested",
    "Replenishable?",
    "OrderType",
    "VendorType",
    "MAP",
];

export const Stage1Browser: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [originalTableData, setOriginalTableData] = useState<OMSItemRenderType[]>([]);
    const [tableData, setTableData] = useState<OMSItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [trackerItem, setTrackerItem] = useState<Partial<OMSTrackerItem>>({});
    const [trackerEnryModalOpen, setTrackerEnryModalOpen] = useState(false);
    const queryClient = useQueryClient();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const {data: suppliers} = useSuppliers();
    const [suppliersModal, suppliersModalContextHolder] = useSuppliersColumnModal(tableData, selectedRowKeys);
    const [dateModal, dateModalContextHolder] = useDateColumnModal(tableData, selectedRowKeys);
    const [orderTypeModal, orderTypeModalContext] = useSelectMultipleColumnModal(tableData, selectedRowKeys, ORDER_TYPES);
    const [vendorTypeModal, vendorTypeModalContext] = useSelectColumnModal(tableData, selectedRowKeys, VENDOR_TYPES);

    const omsMutation = useMutation({
        mutationFn: async (data: OMSItem[]) => {
            const token = await currentUser!.getIdToken();
            const dataToSave = data.map(({key, ...item}) => ({
                ...item,
                "S1_Pushed?": "YES",
                "S1_Pushed_By": currentUser!.email || "NA",
            }));
            await updateOMS(token, dataToSave, false, "stage_01");
            return dataToSave;
        },
        onSuccess: (data) => {
            setTableData(data.map((item, idx) => ({...item, key: idx})));
            setOriginalTableData(data.map((item, idx) => ({...item, key: idx})));
            setSubmitted(true);
            setFetchTimestamp(new Date());
            notification.success({
                message: "Push successful!",
            });
            // Automatically update data in PO Browser
            queryClient.refetchQueries({queryKey: ["oms_data"]});
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: OMSItemRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: OMSItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    const columns = useMemo<EditableColumnType<OMSItemRenderType>[]>(() => {
        const stage1Columns = makeStage1Columns(searchInputRef);
        if (editable && !submitted) {
            stage1Columns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter((item) => item.Row !== record.Row);

                            if (newData.length === 0) {
                                setFetchTimestamp(new Date());
                                setOriginalTableData([]);
                            } else {
                                setLastUpdateTimestamp(new Date());
                            }
                            setTableData(newData);
                        }}
                    />
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(stage1Columns, handleSave);
        } else {
            return stage1Columns;
        }
    }, [handleSave, editable, submitted, tableData]);

    const processData = (data: string) => {
        let dataFrame: dataForge.IDataFrame<number, any> = dataForge.fromCSV(data);

        // Check if all required columns are present
        const columnsDiff = _.difference(requiredColumns, dataFrame.getColumnNames());
        if (columnsDiff.length > 0) {
            notification.error({
                message: "Wrong columns",
                description: (
                    <>
                        Some columns are missing:
                        {columnsDiff.map((col) => (
                            <p style={{margin: 0}}>{col}</p>
                        ))}
                    </>
                ),
            });
            return;
        }
        dataFrame = dataFrame.subset(requiredColumns);
        dataFrame = dataFrame.parseInts(["Quantity"]);
        dataFrame = dataFrame.parseDates(["Supplier_Date", "Ship_Requested"]);
        dataFrame = dataFrame.transformSeries({
            "Cost": (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
            "MAP": (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
            "Replenishable?": (val) => {
                if (!val) return undefined;
                if (["yes", "true"].includes(val.toLowerCase())) return true;
                return false;
            },
            "Supplier_Date": (val) => (isNaN(val) ? undefined : val),
            "Ship_Requested": (val) => (isNaN(val) ? undefined : val),
        });

        let incomingData: OMSItem[] = dataFrame.toArray();
        incomingData = incomingData.map((item, idx) => ({
            ...item,
            Row: _.uniqueId(),
        }));
        setTableData(incomingData.map((item, idx) => ({...item, key: idx})));
        setOriginalTableData(incomingData.map((item, idx) => ({...item, key: idx})));
        setLastUpdateTimestamp(new Date());
        setSubmitted(false);
        setEditable(false);
    };

    const addNewRow = () => {
        const newItem: OMSItemRenderType = {
            Row: _.uniqueId(),
            key: 0,
            ...defaultItem,
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({...item, key: idx})));
    };

    const handleTrackerEdit = () => {
        Modal.confirm({
            title: "PO Tracker Entry?",
            content: `Make sure to push the items first! Do you want to provide extra details for PO ${tableData[0].Supplier_PO} with SO: ${tableData[0].Supplier_SO}?`,
            onOk: async () => {
                const trackerItem = await getOMSTrackerEntry(tableData[0].Supplier_PO, tableData[0].Supplier_SO);

                console.log("trackerItem", trackerItem);
                if (trackerItem) {
                    setTrackerItem(trackerItem);
                    setTrackerEnryModalOpen(true);
                } else {
                    notification.error({
                        message: "Tracker Entry not found",
                        description: `No tracker entry found for PO ${tableData[0].Supplier_PO} with SO: ${tableData[0].Supplier_SO}, try again in a minute!`,
                    });
                }
            },
        });
    };

    const onDiscardChanges = () => {
        setTableData(
            originalTableData.map((item) => ({
                ...item,
            }))
        );
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        const errors = validateOMSStage1Columns(tableData, suppliers?.map((item) => item.name) || []);
        if (errors.length === 0) {
            let warnings = ``;

            // Check if any of the items have Ship_Requested date in the past
            const pastShipRequested = tableData.filter((item) => item.Ship_Requested < new Date());

            // Check if any of the items have Ship_Requested date over 2 years from now
            const futureShipRequested = tableData.filter(
                (item) => item.Ship_Requested > new Date(new Date().setFullYear(new Date().getFullYear() + 2))
            );

            if (pastShipRequested.length > 0) {
                warnings = warnings.concat(
                    `The following items have Ship_Requested date in the past: ${pastShipRequested
                        .map((item) => Number(item.key) + 1)
                        .join(", ")}. `
                );
            }

            if (futureShipRequested.length > 0) {
                warnings = warnings.concat(
                    `The following items have Ship_Requested date over 2 years from now: ${futureShipRequested
                        .map((item) => Number(item.key) + 1)
                        .join(", ")}. `
                );
            }

            if (warnings.length > 0) {
                Modal.confirm({
                    title: `Are you sure you want to proceed with the following warnings?`,
                    content: warnings,
                    onOk: () => {
                        omsMutation.mutate(tableData);
                    },
                });
            } else {
                omsMutation.mutate(tableData);
            }
        }
    };

    const bulkActions: BulkActionType[] = [
        {
            label: "Set Ship Requested",
            onClick: () =>
                dateModal.show({
                    columnName: "Ship_Requested",
                    title: "Ship Requested",
                    onUpdate: updateData,
                }),
            icon: <PullRequestOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Supplier Names",
            onClick: () => {
                suppliersModal.show({
                    onUpdate: updateData,
                });
            },
            icon: <FieldStringOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Change order type",
            onClick: () =>
                orderTypeModal.show({
                    columnName: "OrderType",
                    title: "order type",
                    onUpdate: updateData,
                }),
            icon: <AuditOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Change vendor type",
            onClick: () =>
                vendorTypeModal.show({
                    columnName: "VendorType",
                    title: "vendor type",
                    onUpdate: updateData,
                }),
            icon: <ApartmentOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <OMSTableTitle
            tableData={tableData}
            actions={
                <>
                    <UnsavedChangesHandler
                        isSaved={lastUpdateTimestamp <= fetchTimestamp}
                        disableWhenSaved
                        isLoading={omsMutation.isPending}
                        onDiscardChanges={onDiscardChanges}
                        onSaveChanges={onSaveChanges}
                        saveConfirmMessage={`You won't be able to modify these changes in this stage after saving. 
                                        Go to the PO Browser if you want to change anything afterwards.`}
                    />
                    <Space>
                        Editable: <Switch disabled={submitted} checked={editable} onChange={setEditable} />
                    </Space>
                    <Button
                        onClick={() => {
                            addNewRow();
                        }}
                        disabled={!editable || submitted}
                    >
                        Add row
                    </Button>
                    <Button
                        onClick={() => {
                            handleTrackerEdit();
                        }}
                        disabled={!(tableData?.length > 0)}
                    >
                        Edit Tracker Entry
                    </Button>
                    <BulkActionsButton actions={bulkActions} />
                </>
            }
        />
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <UploadHandler onComplete={processData} template={requiredColumns} />
            </Row>
            <Row>
                <Col span={24}>
                    <EditableTable<OMSItemRenderType>
                        title={tableTitle}
                        tableData={tableData}
                        columns={columns}
                        onRowSelectionChange={(rowKeys, availableRowKeys) =>
                            setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                        }
                    />
                </Col>
            </Row>
            {suppliersModalContextHolder}
            {dateModalContextHolder}
            {orderTypeModalContext}
            {vendorTypeModalContext}
            <TrackerEntryModal
                open={trackerEnryModalOpen}
                onFinish={async (updatedItem) => {
                    if (updatedItem) {
                        const token = await currentUser!.getIdToken();
                        pushTrackerData(token, [updatedItem], false).then(() => {
                            setTrackerEnryModalOpen(false);
                            notification.success({
                                message: "Tracker Entry updated",
                            });
                        });
                    } else {
                        setTrackerEnryModalOpen(false);
                    }
                }}
                initialValues={trackerItem}
            />
        </Space>
    );
};
