import {message} from "antd";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {downloadHelper} from "./Downloads";

export const processDataUnderArmourHelper = (data: ArrayBuffer[]) => {
    console.log("processing");

    try {
        let uniqueUpcs: {[key: string]: boolean} = {};
        let upcDataMap: {[key: string]: boolean} = {};
        let resultMap: {[key: string]: any} = {};
        let fullOutput: any[] = [];
        let i = 0;
        for (const input of data) {
            i++;
            const jnputData = new TextDecoder("utf-8").decode(input);
            const uaFrame = dataForge
                .fromCSV(jnputData.replace(/("="")|(""")/g, `"`))
                .where(
                    (row) =>
                        dayjs(row.EffectiveDate ?? row.effectiveDate).diff(dayjs(), "days") < 30 &&
                        parseInt(row.Inventory ?? row.inventory) >= 5
                )
                .select((row) => {
                    const newRow = {...row};
                    newRow.OurPrice = (parseFloat(row.Price ?? row.price) * 0.8 * 1.1).toFixed(2);
                    return newRow;
                })
                .where((row) => upcDataMap[`${row.UPC ?? row.upc}-${row.EffectiveDate ?? row.effectiveDate}`] === undefined)
                .bake();

            console.log(i);
            if (uaFrame.count() === 0) {
                console.log("Skipping frame with 0 rows", i);
                continue;
            }

            console.log(uaFrame.first());

            uaFrame.forEach((row) => {
                upcDataMap[`${row.UPC ?? row.upc}-${row.EffectiveDate ?? row.effectiveDate}`] = true;
                if (resultMap[`${row.UPC ?? row.upc}`]) {
                    resultMap[`${row.UPC ?? row.upc}`][row.EffectiveDate ?? row.effectiveDate] = row;
                } else {
                    resultMap[`${row.UPC ?? row.upc}`] = {
                        [row.EffectiveDate ?? row.effectiveDate]: row,
                    };
                }
            });

            fullOutput = fullOutput.concat(uaFrame.toArray());

            const upcs = uaFrame.select((row) => row.UPC ?? row.upc).toArray();
            for (const upc of upcs) {
                uniqueUpcs[upc] = true;
            }
        }

        console.log(resultMap);

        let result: any[] = [];
        Object.entries(resultMap).forEach(([upc, datesObj]) => {
            Object.entries(datesObj)
                .sort((a, b) => dayjs(a[0]).diff(dayjs(b[0])))
                .forEach(([date, row]: [string, any], idx, arr: any[]) => {
                    result.push({
                        ...row,
                        NextQuant: arr[idx + 1] ? arr[idx + 1][1]["inventory"] ?? arr[idx + 1][1]["Inventory"] : -1,
                        NextDate: arr[idx + 1] ? arr[idx + 1][0] : "",
                        Encounter: idx + 1,
                    });
                });
        });

        let chunkSize = 10000;
        const upcFile = Object.keys(uniqueUpcs).map((val) => ({UPC: val}));
        for (let i = 0; i <= upcFile.length; i += chunkSize) {
            const chunk = upcFile.slice(i, i + chunkSize);
            downloadHelper(chunk, `UA-${dayjs().format("MM-DD-YYYY")}-Unique-UPCs-${Math.floor(i / chunkSize) + 1}`);
        }

        console.log(result);

        let finalFrame = new dataForge.DataFrame(result)
            .renameSeries({
                catalogName: "Catalog",
                styleCode: "Style",
                colorCode: "Color Code",
                sizeCode: "Size",
                productName: "Description",
                primaryColor: "Color",
                secondaryColor: "Second Color",
                logoColor: "Logo Color",
                effectiveDate: "Ship Date",
                inventory: "OH",
                upc: "UPC",
                sku: "SKU",
                price: "Wholesale Price",
                msrp: "Retail Price",
                gender: "Gender",
                category: "Category",
                endUse: "Use",
                OurPrice: "Cost",
                NextQuant: "Next Quantity",
                NextDate: "Next Date",
            })
            .generateSeries({
                Price: (row: any) => (parseFloat(row["Cost"]) / 1.1).toFixed(2),
            })
            .toArray();

        for (let i = 0; i <= finalFrame.length; i += chunkSize) {
            const chunk = finalFrame.slice(i, i + chunkSize);
            console.log(chunk);
            downloadHelper(chunk, `UA-${dayjs().format("MM-DD-YYYY")}-Result-${Math.floor(i / chunkSize) + 1}`);
        }
    } catch (ex) {
        console.log(ex);
        message.error("Failed to process! Please send file to Karol.");
    }
};

export const processDataUnderArmourKeepaFilterHelper = (data: ArrayBuffer[]) => {
    console.log("processing");

    try {
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge
                .fromCSV(jnputData)
                .parseInts(["Sales Rank: Current", "Amazon: 90 days OOS", "Reviews: Review Count"]);

            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        const result = fullOutput.filter(
            (row) =>
                (row["New: Current"] === "" && row["Reviews: Review Count"] > 10) ||
                (row["Sales Rank: Current"] <= 400000 && row["Amazon: 90 days OOS"] > 0 && row["Reviews: Review Count"] > 20)
        );

        let chunkSize = 10000;
        for (let i = 0; i <= result.length; i += chunkSize) {
            const chunk = result.slice(i, i + chunkSize);
            downloadHelper(chunk, `UA-${dayjs().format("MM-DD-YYYY")}-Keepa-Result-${Math.floor(i / chunkSize) + 1}`);
        }
    } catch (ex) {
        message.error("Failed to process! Please send file to Karol.");
    }
};
