import {Button, Space, Row, Col, Spin, Empty, List, Card, Descriptions, Input, Switch, notification, Select} from "antd";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useStore} from "../../../store/useStore";
import {InboundShipment, SHIPMENT_TYPES} from "../../../types/WarehouseTypes";
import {useShallow} from "zustand/react/shallow";
import SingleShipmentView from "./SingleShipmentView";
import MultipleShipmentsView from "./MultipleShipmentsView";
import {countItemsByKey} from "./helpers";

const ShipmentList: React.FC<{shipments: InboundShipment[]; onSelect: (shipmentId: string) => void}> = ({shipments, onSelect}) => {
    const selectedId = useStore((state) => state.state.selectedShipmentId);
    const boxesStore = useStore((state) => state.boxes.byId);
    const setShipmentType = useStore((state) => state.setShipmentType);
    if (!shipments.length) {
        return <Empty style={{width: "100%"}} />;
    }

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <List
                style={{height: "80vh", overflow: "auto"}}
                header="Shipments"
                dataSource={shipments}
                renderItem={(shipment) => {
                    const boxes = Object.values(boxesStore).filter((box) => box.shipmentId === shipment.ShipmentId);
                    const skus = countItemsByKey(boxes);
                    const addedUnits = Object.values(skus).reduce((a, b) => a + b, 0);
                    const allUnits = shipment.ExpectedItems.map((item) => item.QuantityShipped).reduce((a, b) => a + b, 0);

                    return (
                        <List.Item>
                            <Card
                                title={shipment.ShipmentName}
                                style={{width: "100%", margin: 4, borderColor: selectedId === shipment.ShipmentId ? "#3166D4" : undefined}}
                                extra={
                                    <Button onClick={() => onSelect(shipment.ShipmentId)}>
                                        {selectedId === shipment.ShipmentId ? "Selected" : "Select"}
                                    </Button>
                                }
                            >
                                <Descriptions
                                    column={1}
                                    bordered
                                    size="small"
                                    items={[
                                        {key: "2", label: "Shipment ID", children: shipment.ShipmentId},
                                        {key: "3", label: "Destination Center ID", children: shipment.DestinationFulfillmentCenterId},
                                        {key: "4", label: "SKUs", children: `${Object.keys(skus).length}/${shipment.ExpectedItems.length}`},
                                        {
                                            key: "5",
                                            label: "Units",
                                            children: `${addedUnits}/${allUnits}`,
                                        },
                                        {
                                            key: "6",
                                            label: "Shipment Type",
                                            children: (
                                                <Select
                                                    style={{width: "100%"}}
                                                    options={SHIPMENT_TYPES.map((type) => ({label: type, value: type}))}
                                                    value={shipment.shipmentType}
                                                    onChange={(value) => {
                                                        setShipmentType(shipment.ShipmentId, value);
                                                    }}
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </Card>
                        </List.Item>
                    );
                }}
            />
        </Space>
    );
};

const BoxingDashboard: FC = () => {
    const {shipmentsStore} = useStore(
        useShallow((state) => ({
            shipmentsStore: state.shipments,
            palletsStore: state.pallets,
            boxesStore: state.boxes,
        }))
    );
    const boxingActions = useStore(
        useShallow((state) => ({
            getData: state.getData,
            scanItem: state.scanItem,
        }))
    );
    const [boxingState, setBoxingState] = useStore(useShallow((state) => [state.state, state.setState]));
    const [code, setCode] = useState("");
    const [isSingleView, setIsSingleView] = useState(true);

    const shipments = useMemo(() => shipmentsStore.allIds.map((id) => shipmentsStore.byId[id]), [shipmentsStore]);

    useEffect(() => {
        boxingActions.getData();
    }, [boxingActions]);

    const handleScan = (code: string) => {
        try {
            if (isSingleView && !boxingState.selectedShipmentId) {
                throw new Error("Please select a shipment first");
            }
            code = code.toUpperCase();
            boxingActions.scanItem(code);
            setCode("");
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
    };

    return (
        <Spin spinning={boxingState.isDataLoading} style={{width: "100%"}}>
            <Row style={{width: "100%"}}>
                <Col xs={10} lg={8} xxl={6} style={{paddingRight: 8}}>
                    <ShipmentList
                        shipments={shipments}
                        onSelect={(shipmentId) => {
                            setBoxingState({selectedShipmentId: shipmentId});
                            setIsSingleView(true);
                        }}
                    />
                </Col>
                <Col xs={14} lg={16} xxl={18} style={{paddingLeft: 8}}>
                    <Row>
                        <Col span={18}>
                            <Input
                                size="large"
                                placeholder="Scan items"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                                onPressEnter={() => {
                                    handleScan(code);
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <Space style={{width: "100%", justifyContent: "space-evenly"}}>
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        handleScan(code);
                                    }}
                                >
                                    Scan
                                </Button>
                                <Switch
                                    value={isSingleView}
                                    onChange={(checked) => {
                                        setIsSingleView(checked);
                                        setBoxingState({selectedShipmentId: undefined});
                                    }}
                                    checkedChildren={"Single shipment"}
                                    unCheckedChildren={"Multiple shipments"}
                                />
                            </Space>
                        </Col>
                    </Row>
                    {isSingleView ? (
                        <SingleShipmentView shipment={shipmentsStore.byId[boxingState.selectedShipmentId || ""]} />
                    ) : (
                        <MultipleShipmentsView />
                    )}
                </Col>
            </Row>
        </Spin>
    );
};

export default BoxingDashboard;
