import {DatePicker, Form, Input, InputNumber, Modal, Select, notification} from "antd";
import React, {useEffect, useState} from "react";
import {APInvoiceTrackerDataRenderType, LedgerData, OMSTrackerItem} from "../../../types/OmegaTypes";
import {SuppliersSelect} from "../table/EditableCell";
import {useAuth} from "../../../contexts/AuthContext";
import {updateAPTrackerData, updateLedgerData} from "../../../services/WholesaleService";
import {validateAPTracker, validateLedger} from "../../utilities/TableDataValidation";
import {useQueryClient} from "@tanstack/react-query";
import {useSuppliers} from "./dataHandlers";

interface TrackerEntryModalProps {
    open: boolean;
    initialValues: Partial<OMSTrackerItem>;
    onFinish: (updatedItem?: Partial<OMSTrackerItem>) => void;
}

export default function TrackerEntryModal({open, initialValues, onFinish}: TrackerEntryModalProps) {
    const {currentUser} = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    const save = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: err.message,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...initialValues,
        });
    }, [initialValues, form]);

    return (
        <Modal
            title="Edit Tracker Entry"
            open={open}
            onCancel={() => {
                onFinish();
            }}
            onOk={() => {
                save();
            }}
            confirmLoading={loading}
        >
            <Form labelCol={{span: 6}} wrapperCol={{span: 14}} form={form}>
                <Form.Item label="Name" name="Supplier_Name">
                    <Input disabled />
                </Form.Item>
                <Form.Item label="PO" name="Supplier_PO">
                    <Input disabled />
                </Form.Item>
                <Form.Item label="SO" name="Supplier_SO">
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Routing Instructions" name="Routing Instructions">
                    <Input />
                </Form.Item>
                <Form.Item label="PaymentNotes" name="PaymentNotes">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}
