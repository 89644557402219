import {DatePicker, Form, Input, InputNumber, Modal, Select, notification} from "antd";
import React, {useEffect, useState} from "react";
import {APInvoiceTrackerDataRenderType, LedgerData} from "../../../types/OmegaTypes";
import {SuppliersSelect} from "../table/EditableCell";
import {useAuth} from "../../../contexts/AuthContext";
import {updateAPTrackerData, updateLedgerData} from "../../../services/WholesaleService";
import {validateAPTracker, validateLedger} from "../../utilities/TableDataValidation";
import {useQueryClient} from "@tanstack/react-query";
import {useSuppliers} from "./dataHandlers";

interface AddLedgerModalProps {
    open: boolean;
    initialValues: Partial<LedgerData>;
    apTrackerItem?: APInvoiceTrackerDataRenderType;
    onFinish: (updatedItem?: APInvoiceTrackerDataRenderType) => void;
}

export default function AddLedgerModal({open, initialValues, apTrackerItem, onFinish}: AddLedgerModalProps) {
    const {currentUser} = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {data: suppliers} = useSuppliers();
    const queryClient = useQueryClient();

    const save = async () => {
        setLoading(true);
        try {
            if (!apTrackerItem) {
                throw new Error("AP Tracker item not selected. Close the popup and try again.");
            }

            const values = await form.validateFields();
            // Convert IsCommission to boolean from YES/NO string
            values.IsCommission = values.IsCommission === "YES";
            // Convert PaidDate to string
            values.PaidDate = values.PaidDate?.toISOString();

            values.Timestamp = new Date();

            const ledgerErrors = validateLedger(
                [values],
                suppliers?.map((sup) => sup.name),
                false,
                true
            );
            const newApTrackerItem: APInvoiceTrackerDataRenderType = {
                ...apTrackerItem,
                IsPaid: true,
            };
            const apTrackerErrors = validateAPTracker([newApTrackerItem], undefined, false, true);

            if (ledgerErrors.length === 0 && apTrackerErrors.length === 0) {
                const token = await currentUser!.getIdToken();
                await updateLedgerData(token, [values]);
                await updateAPTrackerData(token, [newApTrackerItem]);
                queryClient.refetchQueries({queryKey: ["ledger"]});
                notification.success({
                    message: "Push successful!",
                });
                onFinish(newApTrackerItem);
            }
        } catch (err) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: err.message,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...initialValues,
            PaidAmount: initialValues.PaidAmount || 0,
            PaidDate: initialValues.PaidDate || undefined,
            IsCommission: initialValues.IsCommission || "NO",
        });
    }, [initialValues, form]);

    return (
        <Modal
            title="Add Ledger item"
            open={open}
            onCancel={() => onFinish()}
            onOk={() => {
                save();
            }}
            confirmLoading={loading}
        >
            <Form labelCol={{span: 6}} wrapperCol={{span: 14}} form={form}>
                <Form.Item label="Name" name="Supplier_Name">
                    <SuppliersSelect defaultOpen={false} />
                </Form.Item>
                <Form.Item label="PO" name="Supplier_PO">
                    <Input />
                </Form.Item>
                <Form.Item label="SO" name="Supplier_SO">
                    <Input />
                </Form.Item>
                <Form.Item label="Invoice Number" name="InvoiceId">
                    <Input />
                </Form.Item>
                <Form.Item label="Distributor Invoice Number" name="DistributorInvoiceId">
                    <Input />
                </Form.Item>
                <Form.Item label="Notes" name="Notes">
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Is Commission" name="IsCommission">
                    <Select
                        allowClear
                        options={[
                            {
                                value: "YES",
                                label: "YES",
                            },
                            {
                                value: "NO",
                                label: "NO",
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Paid Amount" name="PaidAmount">
                    <InputNumber
                        formatter={(value) => {
                            const parsedValue = parseFloat(value as string);

                            // Make sure that the number is always rounded to 2 decimal places
                            if (Number.isNaN(parsedValue)) {
                                return `$${value}`;
                            } else {
                                return `$${Math.round(parsedValue * 100) / 100}`;
                            }
                        }}
                        step={0.01}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    />
                </Form.Item>
                <Form.Item label="Paid Date" name="PaidDate">
                    <DatePicker allowClear={false} />
                </Form.Item>
                <Form.Item label="Payment Method" name="PaymentMethod">
                    <Input />
                </Form.Item>
                <Form.Item label="Credit Id (if used)" name="CreditId">
                    <Input />
                </Form.Item>
                {/* <Button type='primary' onClick={() => form.setFieldValue('CreditId', apTrackerItem?._id)}>Fill CreditId</Button> */}
            </Form>
        </Modal>
    );
}
