import { Button, Col, Row, Statistic, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { SingularItem } from "../../types/OmegaTypes";
import { approxMVSP, RevFeeFromSellerSnap } from "./BrandCalcs";
import { FindFulfillmentFee, FindSizeTier, PRICE_PER_DIMPOUND } from "../wholesale/ItemCalcs";
import dayjs from "dayjs";
import { useOmega } from "../../contexts/OmegaContext";

const ProfitSimulator: React.FC<{ productData: SingularItem, newMinPrice: number, setMVSPCard: React.Dispatch<React.SetStateAction<number>> }> = ({ productData, newMinPrice, setMVSPCard }) => {
    const { replenParams, setReplenParams } = useOmega();
    const [sellPrice, setSellPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [fulfillmentFee, setFulfillmentFee] = useState(0)
    const [referalFee, setReferalFee] = useState(0)
    const [mvsp, setMVSP] = useState(0)

    const [sizeTier, setSizeTier] = useState("")
    const [shipDimWeight, setShipDimWeight] = useState(0)
    const [peakRate, setPeakRate] = useState(false)

    const [profit, setProfit] = useState(0)
    const [roi, setRoi] = useState(0)

    const [desiredRoi, setDesiredRoi] = useState(25);

    useEffect(() => {
        setDesiredRoi(replenParams.desiredRoi)
    }, [replenParams])

    useEffect(() => {
        const newReferalFee = RevFeeFromSellerSnap(sellPrice, productData.ssItem)
        const newProfit = sellPrice - cost - newReferalFee - shipping - fulfillmentFee
        const newRoi = newProfit / cost * 100

        setMVSP(approxMVSP((1 + (desiredRoi / 100)) * cost + shipping + fulfillmentFee, productData.ssItem))
        setReferalFee(newReferalFee);
        setProfit(newProfit);
        setRoi(newRoi);
    }, [cost, fulfillmentFee, sellPrice, shipping, productData.ssItem, desiredRoi])

    useEffect(() => {
        setMVSPCard(mvsp)
    }, [mvsp, setMVSPCard])

    const handleParamEdit = (key: string, value: string | null) => {
        if (value !== null && value !== undefined) {
            let newVal;
            switch (key) {
                case 'sellPrice':
                    newVal = parseFloat(value.replace("$", ""))
                    setSellPrice(newVal);
                    const ourFulFee = FindFulfillmentFee(sizeTier, shipDimWeight, peakRate, productData.invHealthItem?.["storage-type"])
                    if (newVal < 10) {
                        const newFulFee = ourFulFee > 0 ? (ourFulFee - 0.77) : (productData["Listed Price"] >= 10 ? productData.fulfillmentFee - 0.77 : productData.fulfillmentFee)
                        setFulfillmentFee(newFulFee)
                    } else if (newVal >= 10) {
                        const newFulFee = ourFulFee > 0 ? ourFulFee : productData.fulfillmentFee
                        setFulfillmentFee(newFulFee)
                    }
                    break;
                case 'desiredRoi':
                    newVal = parseFloat(value)
                    setReplenParams({ ...replenParams, desiredRoi: newVal })
                    break;
                case 'cost':
                    setCost(parseFloat(value.replace("$", "")));
                    break;
                case 'shipping':
                    setShipping(parseFloat(value.replace("$", "")));
                    break;
                default:
                    break;
            }
        }
    }

    // const grabCompetitive = () => {
    //     getCompetitivePrice(productData.ASIN).then((res) => {
    //         if (res?.[0]?.competitivePrice) {
    //             message.success('Competitive price successfully grabbed!')
    //             handleParamEdit('sellPrice', res[0].competitivePrice.toFixed(2))
    //         } else {
    //             message.error('No Competitive Price found for this ASIN - blame Bezos!')
    //         }
    //     })
    // }

    useEffect(() => {
        setSellPrice(newMinPrice)
        setCost(productData.Cost)
        const [localSizeTier, shipDimWeight] = FindSizeTier({
            adjustedWeight: parseFloat(productData.ssItem.pkg_weight ?? 1),
            packHeight: parseFloat(productData.ssItem.pkg_height ?? 0),
            packLength: parseFloat(productData.ssItem.pkg_length ?? 0),
            packWidth: parseFloat(productData.ssItem.pkg_width ?? 0),
        })

        console.log('localSizeTier', localSizeTier, shipDimWeight, productData.ssItem.pkg_weight, productData.ssItem.pkg_height, productData.ssItem.pkg_length, productData.ssItem.pkg_width)
        setShipping(shipDimWeight > 0 ? shipDimWeight * PRICE_PER_DIMPOUND : productData.shippingFee)

        setSizeTier(localSizeTier)
        setShipDimWeight(shipDimWeight)
        setPeakRate(dayjs().month() >= 9)

        const fulfillmentFee = FindFulfillmentFee(localSizeTier, shipDimWeight, dayjs().month() >= 9, productData.invHealthItem?.["storage-type"])
        setFulfillmentFee(fulfillmentFee > 0 ? fulfillmentFee : productData.fulfillmentFee)
    }, [productData, newMinPrice])

    return (
        <>
            <Row justify="center" style={{ width: '100%', height: '50%', textAlign: 'center' }}>
                <Col span={5} style={{ gap: '5px', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                    <Button size="small" type="primary" onClick={() => handleParamEdit('sellPrice', productData["Min Price"].toFixed(2))}>Min</Button>
                    <Button size="small" type="primary" onClick={() => handleParamEdit('sellPrice', productData["Avg Sell Price"].toFixed(2))}>Avg</Button>
                    <Button size="small" type="primary" onClick={() => handleParamEdit('sellPrice', mvsp.toFixed(2))}>MVSP</Button>
                    {/* <Button disabled={!productData.insight?.SuggestedPrice} size="small" type="primary" onClick={() => handleParamEdit('sellPrice', productData.insight.SuggestedPrice.toFixed(2))}>Suggested</Button>
                    <Button size="small" type="primary" onClick={() => grabCompetitive()}>Competitive</Button> */}
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Sell Price:" value={sellPrice.toFixed(2)} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                        icon: false,
                        tooltip: `Set the item's desired maximum price`,
                        onChange: (val) => handleParamEdit('sellPrice', val),
                        onEnd: () => handleParamEdit('sellPrice', null),
                        onCancel: () => handleParamEdit('sellPrice', null),
                    }}>{`$${val}`}</Typography.Text>} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Cost:" value={cost.toFixed(2)} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                        icon: false,
                        tooltip: `Set the item's desired cost`,
                        onChange: (val) => handleParamEdit('cost', val),
                        onEnd: () => handleParamEdit('cost', null),
                        onCancel: () => handleParamEdit('cost', null),
                    }}>{`$${val}`}</Typography.Text>} />
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Shipping:" value={shipping.toFixed(2)} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                        icon: false,
                        tooltip: `Set the item's desired shipping cost`,
                        onChange: (val) => handleParamEdit('shipping', val),
                        onEnd: () => handleParamEdit('shipping', null),
                        onCancel: () => handleParamEdit('shipping', null),
                    }}>{`$${val}`}</Typography.Text>} />
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Des. ROI:" value={desiredRoi} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                        icon: false,
                        tooltip: `Set the item's desired ROI`,
                        onChange: (val) => handleParamEdit('desiredRoi', val),
                        onEnd: () => handleParamEdit('desiredRoi', null),
                        onCancel: () => handleParamEdit('desiredRoi', null),
                    }}>{`${val}%`}</Typography.Text>} />
                </Col>
            </Row>
            <Row justify="center" style={{ width: '100%', height: '50%', textAlign: 'center' }}>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Fulfillment" value={fulfillmentFee} precision={2} prefix={'$'} />
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Referral" value={referalFee} precision={2} prefix={'$'} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="Profit" value={profit} precision={2} prefix={'$'} />
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="ROI" value={roi} precision={2} suffix={'%'} />
                </Col>
                <Col span={5} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="MVSP" value={mvsp} precision={2} prefix={'$'} />
                </Col>
            </Row>
        </>
    )
}

export default ProfitSimulator