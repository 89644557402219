import { Button, Col, Divider, Input, Modal, Row, Timeline, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useSupplierContext } from "../../contexts/SupplierContext";
import { BrandDashboardJournalEntry } from "../../types/Brand";

const BrandJournal: React.FC = () => {
    const { selectedSupplier: supplier, selectedSupplierJournals: supplierJournals, updateSupplierJournals } = useSupplierContext()
    const [journals, setJournals] = useState<BrandDashboardJournalEntry[]>([])
    const [item, setItem] = useState<BrandDashboardJournalEntry>({
        Entry: '',
        Author: '',
        DateAdded: new Date(),
        Brand: '',
    })
    const [open, setOpen] = useState(false)
    const { currentUser } = useAuth();

    useEffect(() => {
        if (supplierJournals) {
            setJournals(supplierJournals)
        }
    }, [supplierJournals])

    const addEntry = () => {
        setItem({
            Entry: '',
            Author: currentUser?.email || 'Unknown',
            DateAdded: new Date(),
            Brand: supplier!.name,
        })
        setOpen(true);
    }

    const finishEditing = () => {
        setOpen(false);
        if (item.Entry.length > 0) {
            updateSupplierJournals([item])
        }
    };

    const transformEntry = (entry: string) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        let textToReturn: any = entry;
        if (linkRegex.test(entry)) {
            const textParts = entry.split(linkRegex);
            const highlightedText = textParts.map((part, insiderIdx) => {
                if (linkRegex.test(part)) {
                    return <a key={insiderIdx} href={part} rel="noopener noreferrer" target="_blank">{part.length >= 50 ? `${part.slice(0, 50)}...` : part}</a>;
                } else {
                    return part;
                }
            });

            textToReturn = highlightedText;
        }
        return <Typography.Text style={{ fontSize: '14px' }}>{textToReturn}</Typography.Text>
    }

    return (
        <Row gutter={[8, 20]}>
            <Col span={24} style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '6px' }}>
                <Button type="primary" onClick={addEntry}>Add Entry</Button>
                <Button disabled>Clear Entry</Button>
            </Col>
            <Col offset={1} span={22} >
                <Timeline 
                    items={journals.map((journal) => ({
                        children: transformEntry(journal.Entry),
                        label: <Typography.Text><i>{journal.Author}</i>, at <b>{journal.DateAdded.toLocaleString()}</b></Typography.Text>,
                    }))} 
                    mode="left" 
                />
            </Col>
            <Modal
                title=''
                centered
                open={open}
                width={600}
                bodyStyle={{
                    overflow: 'auto',
                    width: '100%',
                }}
                onCancel={() => setOpen(false)}
                maskClosable={false}
                onOk={finishEditing}
                closable={false}
            >
                <>
                    <Row
                        style={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Col
                            span={17}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Input.TextArea
                                style={{
                                    height: '200px',
                                    resize: 'none',
                                }}
                                value={item.Entry}
                                onChange={event => {

                                    const newValue =
                                        event.target.value;
                                    const newItem = {
                                        ...item
                                    }

                                    newItem.Entry = newValue
                                    setItem(newItem);
                                }}
                            />
                        </Col>
                        <Col span={1}>
                            <Divider
                                style={{ height: '100%' }}
                                type='vertical'
                            ></Divider>
                        </Col>
                        <Col
                            span={6}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography.Text
                                style={{ fontSize: '12px' }}
                            >
                                {item.DateAdded.toLocaleString()}
                            </Typography.Text>
                            <Typography.Text
                                style={{ fontSize: '12px' }}
                            >
                                by{' '}
                                {item.Author &&
                                    item.Author.includes('@')
                                    ? item.Author.split(
                                        '@'
                                    )[0]
                                    : item.Author}
                            </Typography.Text>
                        </Col>
                    </Row>
                </>
                {/* <Space
                    style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        onClick={() => {
                            const newJournals = [...journals];
                            newJournals.unshift({
                                Entry: '',
                                Author: currentUser?.email || 'Unknown',
                                DateAdded: new Date(),
                                Brand: supplier!.name,
                            });
                            setJournals(newJournals);
                        }}
                        type='primary'
                    >
                        Add
                    </Button>
                </Space>
                <List<BrandDashboardJournalEntry>
                    dataSource={journals}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                <Button
                                    type="primary"
                                    icon={<FileAddFilled />}
                                    onClick={() => {
                                        // const newNotes = [...journals];
                                        // newNotes.splice(index, 1);
                                        // setJournals(newNotes);
                                    }}
                                />,
                                <Button
                                    danger
                                    disabled
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                        const newNotes = [...journals];
                                        newNotes.splice(index, 1);
                                        setJournals(newNotes);
                                    }}
                                />,
                            ]}
                        >
                            {typeof item === 'string' ? (
                                <Input
                                    value={item}
                                    onChange={event => {
                                        const newValue = event.target.value;
                                        const newNotes = [...journals];
                                        newNotes[index] = {
                                            Entry: newValue,
                                            Author:
                                                currentUser?.email || 'Unknown',
                                            DateAdded:
                                                new Date(),
                                            Brand: supplier!.name,
                                        };

                                        setJournals(newNotes);
                                    }}
                                />
                            ) : (
                                <>
                                    <Row
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Col
                                            span={17}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Input.TextArea
                                                style={{
                                                    height: '100%',
                                                    resize: 'none',
                                                }}
                                                value={item.Entry}
                                                onChange={event => {
                                                    const newValue =
                                                        event.target.value;
                                                    const newNotes = [
                                                        ...journals,
                                                    ];
                                                    newNotes[index] = {
                                                        Entry: newValue,
                                                        Author: currentUser?.email || 'Unknown',
                                                        DateAdded: new Date(),
                                                        Brand: supplier!.name,
                                                    };

                                                    setJournals(newNotes);
                                                }}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Divider
                                                style={{ height: '100%' }}
                                                type='vertical'
                                            ></Divider>
                                        </Col>
                                        <Col
                                            span={6}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography.Text
                                                style={{ fontSize: '12px' }}
                                            >
                                                {item.DateAdded.toLocaleString()}
                                            </Typography.Text>
                                            <Typography.Text
                                                style={{ fontSize: '12px' }}
                                            >
                                                by{' '}
                                                {item.Author &&
                                                item.Author.includes('@')
                                                    ? item.Author.split(
                                                          '@'
                                                      )[0]
                                                    : item.Author}
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </List.Item>
                    )}
                /> */}
            </Modal>
        </Row>

    )
}

export default BrandJournal;