import ExcelJS from "exceljs";
import { downloadHelper } from "./Downloads";

export const processData47Helper = async (data: ArrayBuffer) => {
    console.log('processing');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);

    const result: any[] = [];
    const sampleData = {
        'UPC': '',
        'OH': '',
        'Name': '',
        'SKU': '',
        'Color': '',
        'Size': '',
        'Wholesale': '',
        'MSRP': '',
        'League': '',
        'Style': '',
        'DateAvailable': '',
        'OurPrice': '',
    };


    workbook.worksheets[0].eachRow({ includeEmpty: true }, function (row, rowNumber) {
        const rowValues = row.values as any[];
        //append an empty value to start of array to make up for the missing first column
        

        if (rowValues[8] === 'UPC') {
            // header row, skip
            console.log(rowValues)
        } else {
            if ((parseInt(rowValues[15]) > 1 || rowValues[15] === '999+') && rowValues[19] !== 'NFL NATIONAL FOOTBALL LEAGUE') {
                const newData = {
                    ...sampleData
                }

                newData.UPC = rowValues[8];
                newData.OH = typeof rowValues[15] === 'string' ? rowValues[15].replace('+', '') : rowValues[15];
                newData.Name = rowValues[1];
                newData.SKU = rowValues[9];
                newData.Color = rowValues[3];
                newData.Size = rowValues[6];
                newData.Wholesale = rowValues[11];
                newData.MSRP = rowValues[11];
                newData.League = rowValues[19];
                newData.Style = rowValues[21];
                newData.DateAvailable = rowValues[16].toISOString().split('T')[0];
                newData.OurPrice = (parseFloat(rowValues[11]) * 1.1).toFixed(2);

                result.push(newData)
            }
        }
    });

    console.log('done processing');
    console.log(result);

    // filter result for DateAvailable in the next 6 days
    const filteredResult = result.filter((item) => {
        const date = new Date(item.DateAvailable);
        const today = new Date();
        today.setDate(today.getDate() - 7);
        const sixDays = new Date();
        sixDays.setDate(today.getDate() + 7);

        return date >= today && date <= sixDays;
    });

    console.log(filteredResult);

    downloadHelper(filteredResult, `${new Date().toLocaleDateString().replace(/\//g, "-")}-47`);
}