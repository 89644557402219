import {useQuery} from "@tanstack/react-query";
import {Col, Row, Select, Space, Spin, Switch, Tabs} from "antd";
import {FilterValue} from "antd/lib/table/interface";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {getNotes, GetSingularItems, grabPectyData} from "../../services/OmegaService";
import {NoteItem, PectyRoot, SingularItem} from "../../types/OmegaTypes";
import BrandSummary from "./BrandSummary";
import {prepareInsight} from "./InsightsCalc";
import ItemBrowser, {filterOmegaTableData} from "./ItemBrowser";
import NoteBrowser from "./NoteBrowser";
import ReplenBrowser from "./ReplenBrowser";
import SingularItemBrowser from "./SingularItemBrowser";
const {TabPane} = Tabs;

const BrandBrowser: React.FC = () => {
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
    const [loading, setLoading] = useState(true);
    const [brandList, setBrandList] = useState<string[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string[]>([]);
    const [noteMap, setNoteMap] = useState<{[key: string]: NoteItem[]} | null>(null);
    const [pectyMap, setPectyMap] = useState<{[key: string]: PectyRoot} | null>(null);
    const [singularData, setSingularData] = useState<SingularItem[]>([]);
    const [singularTableData, setSingularTableData] = useState<SingularItem[]>([]);
    const [singularBrowserData, setSingularBrowserData] = useState<SingularItem[]>([]);
    const [skipZeroes, setSkipZeroes] = useState<boolean>(true);
    const {currentUser, userData} = useAuth();

    const {data: externalSingularData, isFetching: externalSingularDataLoading} = useQuery({
        queryKey: ["singular_data"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => GetSingularItems(token, true)),
        staleTime: Infinity,
    });

    const {data: noteData, isFetching: noteDataLoading} = useQuery({
        queryKey: ["singularNotes_data"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getNotes(token)),
        staleTime: Infinity,
    });

    const {data: pectyData, isFetching: pectyDataLoading} = useQuery({
        queryKey: ["pectySingular_data"],
        queryFn: () => grabPectyData(),
        staleTime: Infinity,
    });

    // useEffect(() => {
    //     const queryString = new URLSearchParams(window.location.search);
    //     const queryBrand = queryString.get('brand');

    //     if (queryBrand) {
    //         setSelectedBrand(queryBrand.split(';'));
    //         console.log('setting selected brand to', queryBrand.split(';'))
    //     }
    // }, [])

    useEffect(() => {
        if (noteData) {
            const localNoteMap: {
                [key: string]: NoteItem[];
            } = {};

            console.log("noteData", noteData);
            noteData.forEach((noteItem: NoteItem) => {
                if (localNoteMap[noteItem.SKU]) {
                    localNoteMap[noteItem.SKU].push(noteItem);
                } else {
                    localNoteMap[noteItem.SKU] = [noteItem];
                }
            });

            setNoteMap(localNoteMap);
        }
    }, [noteData]);

    useEffect(() => {
        if (pectyData) {
            const localPectyMap: {
                [key: string]: PectyRoot;
            } = {};

            pectyData.forEach((pectyItem: PectyRoot) => {
                if (localPectyMap[pectyItem.data.asin]?.dateProcessed < pectyItem.dateProcessed) {
                    localPectyMap[pectyItem.data.asin] = pectyItem;
                } else if (localPectyMap[pectyItem.data.asin] === undefined) {
                    localPectyMap[pectyItem.data.asin] = pectyItem;
                }
            });

            setPectyMap(localPectyMap);
        }
    }, [pectyData]);

    useEffect(() => {
        if (externalSingularData) {
            const allowedBrands = userData?.omegaBrands;
            const result: SingularItem[] = [];
            const brandMap: {[key: string]: boolean} = {};
            const asinMap: {[key: string]: SingularItem[]} = {};

            externalSingularData.forEach((item: SingularItem) => {
                let brand = item.Brand;
                if (allowedBrands && !allowedBrands.includes(brand.toLowerCase())) {
                    //
                } else {
                    brandMap[brand] = true;
                    asinMap[item.ASIN] = (asinMap[item.ASIN] ?? []).concat([item]);
                }
            });

            Object.values(asinMap).forEach((itemArr) => {
                const maxQuantItem = itemArr.reduce(
                    (acc, curr) =>
                        curr.Stock > acc.Stock
                            ? curr
                            : curr["Days In Stock"] > acc["Days In Stock"] && !(curr.Stock <= acc.Stock)
                            ? curr
                            : acc,
                    itemArr[0]
                );
                maxQuantItem.skuItemCount = itemArr.length;
                maxQuantItem.skuItemList = itemArr.map((item) => item.ssItem);
                maxQuantItem.itemArr = itemArr;

                itemArr.forEach((item) => {
                    item.itemArr = itemArr;
                    item.skuItemCount = itemArr.length;
                    item.skuItemList = itemArr.map((item) => item.ssItem);
                });

                if (
                    maxQuantItem.skuItemList.filter((item) => item.fba_available_quantity > 0 || item.fulfillable_quantity > 0).length > 1
                ) {
                    itemArr.forEach((item) => {
                        item.Issues.push("MultiSKU");
                    });
                }

                result.push(maxQuantItem);
            });

            setBrandList(Object.keys(brandMap));
            setSingularData(updatePecty(result));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalSingularData, userData?.omegaBrands]);

    useEffect(() => {
        setLoading(true);
        if (selectedBrand.length > 0) {
            const brandData = singularData
                .filter((item) => selectedBrand.includes(item.Brand))
                .filter((item) => !skipZeroes || item.availableInventory > 0)
                // .map(object => ({ ...object }))
                .sort((a, b) => (a.Brand === b.Brand ? (a.Parent > b.Parent ? 1 : -1) : a.Brand > b.Brand ? 1 : -1));

            const ammendedData = updateNotes(updatePecty(brandData));
            setSingularTableData(ammendedData);
            setSingularBrowserData(filterOmegaTableData(filteredInfo, ammendedData));
        } else {
            const brandData = singularData.filter((item) => !skipZeroes || item.availableInventory > 0);
            // .map(object => ({ ...object }))

            const ammendedData = updateNotes(updatePecty(brandData));
            setSingularTableData(ammendedData);
            setSingularBrowserData(filterOmegaTableData(filteredInfo, ammendedData));
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrand, singularData, skipZeroes]);

    const updateNotes = useCallback(
        (data: SingularItem[]): SingularItem[] => {
            const newData = [...data];
            newData.forEach((item) => {
                item.itemArr.forEach((subItem: SingularItem) => {
                    subItem.notes = noteMap?.[subItem.SKU] || [];
                    subItem.sinceNote = noteMap?.[subItem.SKU]?.[0]?.insertTimestamp
                        ? dayjs().diff(dayjs(noteMap[subItem.SKU]?.[0]?.insertTimestamp), "days")
                        : -1;
                });

                item.notes = noteMap?.[item.SKU] || [];
                item.sinceNote = noteMap?.[item.SKU]?.[0]?.insertTimestamp
                    ? dayjs().diff(dayjs(noteMap[item.SKU]?.[0]?.insertTimestamp), "days")
                    : -1;
            });

            return newData;
            // return data.map((item) => ({
            //     ...item,
            //     notes: noteMap?.[item.SKU] || [],
            //     sinceNote: noteMap?.[item.SKU]?.[0]?.insertTimestamp ? dayjs().diff(dayjs(noteMap[item.SKU]?.[0]?.insertTimestamp), 'days') : -1
            // }))
        },
        [noteMap]
    );

    const updatePecty = useCallback(
        (data: SingularItem[]): SingularItem[] => {
            const result = data.map((item) => {
                if (pectyMap?.[item.ASIN] && (!item.insight || pectyMap?.[item.ASIN]?.dateProcessed > item.pectyData?.dateProcessed)) {
                    for (const subItem of item.itemArr) {
                        subItem.pectyData = pectyMap[subItem.ASIN];
                        subItem.insight = prepareInsight(subItem);

                        if (subItem.pectyData?.data?.buyboxHasCoupon === true) {
                            if (
                                subItem.pectyData?.data?.buyboxSellerId !== "A3B3XKFL4HJA0A" &&
                                subItem.pectyData?.data?.buyboxSellerName !== "M Emporium" &&
                                !subItem.Issues.includes("BB Coupon")
                            ) {
                                subItem.Issues.push("BB Coupon");
                            }
                        }

                        if (subItem.insight?.QTYBelow > 0.3 * subItem.Stock) {
                            if (!subItem.Issues.includes("HighBelow")) {
                                subItem.Issues.push("HighBelow");
                            }
                        }

                        subItem.QTYBelow = subItem.insight?.QTYBelow;
                    }
                    const newItem = {...item, pectyData: pectyMap[item.ASIN]};
                    newItem.insight = prepareInsight(newItem);

                    return newItem;
                } else {
                    return item;
                }
            });
            return result;
        },
        [pectyMap]
    );

    const actuallyChangeSingularBrowserDataFromTable = useCallback((tableData: any[]) => {
        // const queryString = new URLSearchParams(window.location.search);

        // if (tableData[0]?.SKU) {
        //     queryString.set("sku", tableData[0]?.SKU);
        // } else {
        //     queryString.delete("sku")
        // }

        // const newRelativePathQuery = window.location.pathname + '?' + queryString.toString();
        // window.history.pushState(null, '', newRelativePathQuery);
        setSingularBrowserData(tableData);
    }, []);

    // Append notes to items
    useEffect(() => {
        setSingularTableData((result) => updateNotes(result));
        setSingularBrowserData((result) => updateNotes(result));
    }, [updateNotes]);

    useEffect(() => {
        setSingularTableData((result) => updatePecty(result));
        setSingularBrowserData((result) => updatePecty(result));
    }, [updatePecty]);

    console.log("brandbrowser reload", window.location.href);

    return (
        <Spin spinning={loading || noteDataLoading || pectyDataLoading || externalSingularDataLoading}>
            <Row>
                <Col span={24}>
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        size="large"
                        placeholder="Please select a brand"
                        {...(selectedBrand ? {value: selectedBrand} : {})}
                        onChange={(value: string[]) => setSelectedBrand(value)}
                        style={{width: "100%"}}
                        filterOption={(input, option) =>
                            (option!.children! as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {[...brandList]
                            .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                            .map((brand, idx) => (
                                <Select.Option key={`${brand}-${idx}`} value={brand}>
                                    {brand}
                                </Select.Option>
                            ))}
                    </Select>
                </Col>
            </Row>
            <Row style={{marginTop: "8px"}}>
                <Col span={24}>
                    <Tabs
                        defaultActiveKey={window.location.href.includes("sku=") ? "3" : "2"}
                        tabBarExtraContent={
                            <Space>
                                <Text>Select mode (before filtering the table): </Text>
                                <Switch
                                    checkedChildren="Inv Mgmt"
                                    unCheckedChildren="Replen"
                                    defaultChecked={true}
                                    onChange={() => setSkipZeroes((prev) => !prev)}
                                />
                            </Space>
                        }
                    >
                        <TabPane tab="Statistics" key="1">
                            <BrandSummary
                                tableData={singularData.filter((item) => !skipZeroes || item.availableInventory > 0)}
                            ></BrandSummary>
                        </TabPane>
                        <TabPane tab="Items (Table)" key="2">
                            <ItemBrowser
                                setSingularBrowserData={actuallyChangeSingularBrowserDataFromTable}
                                setTopFilteredInfo={setFilteredInfo}
                                tableData={singularTableData}
                            />
                        </TabPane>
                        <TabPane tab="Items (Singular)" key="3">
                            <SingularItemBrowser selectedBrands={selectedBrand} tableData={singularBrowserData} />
                        </TabPane>
                        <TabPane tab="Notes (current session)" key="4">
                            <NoteBrowser />
                        </TabPane>
                        <TabPane tab="Replens (current session)" key="5">
                            <ReplenBrowser />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Spin>
    );
};

export default BrandBrowser;
