import {Button, Divider, Space, Table, TablePaginationConfig} from "antd";
import {ColumnType, FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection} from "antd/lib/table/interface";
import React, {PropsWithChildren, useEffect, useImperativeHandle, useRef, useState} from "react";
import {EditableCell, EditableColumnType, EditableRow} from "./EditableCell";
import {extraFilterClickHandlers} from "../../wholesale/table/WholesaleUtilities";

const filterTableData = <DataType,>(filterInfo: Record<string, FilterValue | null>, columns: ColumnType<DataType>[], data: DataType[]) => {
    // @ts-ignore
    const filterEntries: [string, (string | number | boolean)[]][] = Object.entries(filterInfo).filter(([_, value]) => value !== null);
    if (filterEntries.length > 0) {
        const filtered = data.filter((record) =>
            filterEntries.every(([key, value]) => {
                const filterLogic = columns.find((column: ColumnType<DataType>) => column.key === key)?.onFilter;
                return filterLogic && filterLogic(value[0], record);
            })
        );

        return filtered;
    } else {
        return data;
    }
};

// Overwrite the default forwardRef to make it support generic types
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

interface EditableTableProps<T extends {key: React.Key}> {
    tableData?: T[];
    loading?: boolean;
    columns: EditableColumnType<T>[];
    onRowSelectionChange?: (rowKeys: React.Key[], availableRowKeys: React.Key[]) => void;
    scroll?: {
        x?: string | number;
        y?: string | number;
        scrollToFirstRowOnChange?: boolean;
    };
    tableId?: string;
    tableKey?: React.Key;
    title?: (data: readonly T[]) => React.ReactNode;
    onRow?: (data: T, index?: number) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
    pagination?: TablePaginationConfig;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<T> | SorterResult<T>[],
        extra: TableCurrentDataSource<T>
    ) => void;
    extraActions?: (data: readonly T[]) => React.ReactNode;
}

// Interface defining ref values
export interface EditableTableHandle<T> {
    setSelectedRowKeys: (keys: React.Key[]) => void;
    selectedRowKeys: React.Key[];
    currentData: T[];
}

function EditableTable<T extends {key: React.Key}>(
    {
        tableData,
        loading,
        columns,
        onRowSelectionChange,
        scroll,
        title,
        onRow,
        pagination,
        onChange,
        tableId,
        tableKey,
        extraActions,
    }: PropsWithChildren<EditableTableProps<T>>,
    ref: React.Ref<EditableTableHandle<T>>
) {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // This can differ from tableData because of filters applied to it
    const [availableRowKeys, setAvailableRowKeys] = useState<React.Key[]>([]);
    const tableRef = useRef(null);
    const [currentData, setCurrentData] = useState<T[]>([]);
    const [filterInfo, setFilterInfo] = useState<Record<string, FilterValue | null>>({});

    // We want to keep track of the table length
    // const setTableDataLength = useState(0)[1];
    // const prevTableDataLength = usePrevious<number>(tableDataLength);

    useImperativeHandle(ref, () => ({
        currentData,
        selectedRowKeys,
        setSelectedRowKeys: (keys) => {
            setSelectedRowKeys(keys);
        },
    }));

    const rowSelection: TableRowSelection<T> = {
        fixed: true,
        onChange: (rowKeys, selectedRows, info) => {
            // If the difference is more than 1, all rows are should be selected
            if (info.type === "all" && rowKeys.length - selectedRowKeys.length > 1) {
                setSelectedRowKeys(availableRowKeys);
            } else if (info.type === "all" && rowKeys.length - selectedRowKeys.length < -1) {
                setSelectedRowKeys([]);
            } else {
                setSelectedRowKeys(rowKeys);
            }
        },
        selectedRowKeys: selectedRowKeys,
    };

    useEffect(() => {
        if (onRowSelectionChange) {
            onRowSelectionChange(selectedRowKeys, availableRowKeys);
        }
    }, [selectedRowKeys, availableRowKeys]); // eslint-disable-line

    useEffect(() => {
        // We are interested only in changes of the number
        // of items in the table
        // if (tableData.length !== prevTableDataLength) {
        //     setAvailableRowKeys(tableData.map(item => item.key));
        // }
        if (tableData) {
            // For now just remove all selection when something changes
            setSelectedRowKeys([]);
            const filteredData = filterTableData<T>(filterInfo, columns, tableData);
            setCurrentData(filteredData);
            setAvailableRowKeys(filteredData.map((item) => item.key));
            // setTableDataLength(tableData.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, filterInfo]);

    useEffect(() => {
        // Filter selectedRowKeys to include only
        // new values
        const keysSet = new Set(availableRowKeys);
        setSelectedRowKeys(selectedRowKeys.filter((key) => keysSet.has(key)));
    }, [availableRowKeys]); // eslint-disable-line

    useEffect(() => {
        const list = document.querySelectorAll(".ant-tabs-tabpane-active tbody");
        for (const table of Array.from(list)) {
            Array.from(table.querySelectorAll(".ant-table-row"))
                .slice(-1)[0]
                ?.querySelectorAll(".ant-table-cell")
                .forEach((node) => ((node as HTMLElement).style.borderBottom = "1px solid rgba(253, 253, 253, 0.12)"));

            if (tableId === undefined || tableId === "table-fixed-height") {
                if (table.querySelector(".ant-table-measure-row") !== null) {
                    table.appendChild(table.querySelector(".ant-table-measure-row")!);
                }
            }
        }

        const headerTables = document.querySelectorAll(".ant-table-header table");
        for (const headerTable of Array.from(headerTables)) {
            if (
                headerTable &&
                Array.from(headerTable.children)
                    .map((el) => el.nodeName)
                    .includes("COLGROUP")
            ) {
                // remove the duplicate if two colgroup exists
                if (headerTable.querySelectorAll("colgroup").length > 1) {
                    let foundCount = 0;
                    headerTable.querySelectorAll("colgroup").forEach((el, index) => {
                        foundCount += 1;
                        if (foundCount > 1) {
                            el.remove();
                        }
                    });
                }
            } else {
                // find colgroup in table body, copy over
                const bodyTable = document.querySelector(".ant-table-body table");
                if (bodyTable) {
                    const colgroup = bodyTable.querySelector("colgroup");
                    if (colgroup) {
                        headerTable?.appendChild(colgroup.cloneNode(true));
                    }
                }
            }
        }
    });

    return (
        <Table<T>
            key={tableKey ?? 1}
            id={tableId ?? "table-fixed-height"}
            ref={tableRef}
            title={(data) => (
                <Space direction="vertical" style={{width: "100%"}}>
                    <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
                        {onRowSelectionChange ? (
                            <Button
                                onClick={() => {
                                    setSelectedRowKeys(data.map((item) => item.key));
                                }}
                            >
                                Select current page
                            </Button>
                        ) : null}
                        {extraActions && extraActions(data)}
                        <Button
                            onClick={() => {
                                setFilterInfo({});
                                setSelectedRowKeys([]);
                                const filteredData = filterTableData<T>({}, columns, tableData || []);
                                setCurrentData(filteredData);
                                setAvailableRowKeys(filteredData.map((item) => item.key));
                            }}
                        >
                            Reset filters
                        </Button>
                    </Space>
                    {title && title(data)}
                </Space>
            )}
            components={{
                body: {
                    cell: EditableCell,
                    row: EditableRow,
                },
            }}
            style={
                {
                    // height: '100%',
                    // height: '95vh',
                    // width: '100%',
                }
            }
            onRow={onRow}
            rowKey={(record) => record.key}
            rowClassName={() => "editable-row"}
            scroll={{y: "85vh", x: "max-content", ...scroll}}
            sticky={true}
            bordered={true}
            size="small"
            tableLayout={"auto"}
            dataSource={tableData}
            pagination={{
                pageSizeOptions: ["20", "50"],
                defaultPageSize: 20,
                showSizeChanger: true,
                ...pagination,
            }}
            // columns={columns}
            columns={columns.map((column) => {
                const newColumn = {
                    ...column,
                    filteredValue: filterInfo[column.dataIndex! as string] || null,
                };

                if (column.extendedFilterClickHandling) {
                    newColumn.onCell = (record) => {
                        return {
                            onClick: (event) => extraFilterClickHandlers(filterInfo, setFilterInfo, newColumn, record)(event),
                        };
                    };
                }

                return newColumn;
            })}
            loading={loading}
            showSorterTooltip={false}
            rowSelection={onRowSelectionChange ? rowSelection : undefined}
            onChange={(pagination, filters, sorters, extra) => {
                setFilterInfo(filters);
                if (onChange) {
                    onChange(pagination, filters, sorters, extra);
                }

                console.log("extra", extra, "pagination", pagination, "filters", filters, "sorters", sorters);
            }}
        />
    );
}

export default React.forwardRef(EditableTable);
