import dayjs from "dayjs";
import * as SheetJS from "xlsx";
import {sheetJSDownload} from "./Downloads";

export const processDataOrgillInvoiceHelper = (data: ArrayBuffer[]) => {
    console.log("processing orgill invoice", data);

    const products: string[][] = [
        ["LINE", "Item", "Ord Qty", "UNIT", "DESCRIPTION", "UNIT Cost", "Ext Cost", "Shelf Pk", "UPC Code", "CO", "SupplierPO"],
    ];
    for (const file of data) {
        console.log("current file", file);
        const orgillWorkbook = SheetJS.read(file, {raw: true});
        const sheets = orgillWorkbook.SheetNames;

        let currentPO = "Unknown";
        let foundItems = false;
        for (let i = 0; i < sheets.length; i++) {
            const sheetRows: string[][] = SheetJS.utils.sheet_to_json(orgillWorkbook.Sheets[orgillWorkbook.SheetNames[i]], {
                blankrows: true,
                header: 1,
            });

            for (const row of sheetRows) {
                if (row.includes("INVOICE")) {
                    currentPO = "Unknown";
                } else if (row.find((el) => el?.includes("Cust PO"))) {
                    const currPO = row.find((el) => el.includes("Cust PO"))?.split(":")?.[1] ?? "";
                    currentPO = currPO;
                } else if (row[0]?.includes("LINE")) {
                    foundItems = true;
                } else if (foundItems) {
                    if (row[0]?.match(/\d+/g) && row.find((row) => row?.match(/\d{12}|\d{13}/g))) {
                        const newRow = [...row.filter((el) => el), currentPO];
                        products.push(newRow);
                    } else {
                        products.push([""]);
                    }
                }
            }
        }
        console.log(products);
    }

    sheetJSDownload(products, `${dayjs().format("YYYY-MM-DD")}_OrgillInvoiceResult`);
};
