import React, {useRef, useState} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {Space, Skeleton, Avatar, InputRef, notification, Button, Tooltip} from "antd";
import {useAuth} from "../../../contexts/AuthContext";
import {getReturnProducts, uploadPhotos} from "../../../services/WarehouseService";
import {RETURN_ACTION_LOOKUP, ReturnProductRenderType} from "../../../types/WarehouseTypes";
import {EditableColumnType} from "../../omega/table/EditableCell";
import EditableTable from "../../omega/table/EditableTable";
import {makeColumnsEditable} from "../../utilities/OMSColumns";
import {getBooleanFilter, getBoolRenderer, getDefaultFilterSorter} from "../../utilities/TableFilterSorters";
import {makeCall} from "../../../services/common";
import {CloudDownloadOutlined, CloudUploadOutlined, ReloadOutlined} from "@ant-design/icons";
import UploadPhotosDialog from "./UploadPhotosDialog";

const ViewReturns: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const queryClient = useQueryClient();
    const [selectedItem, setSelectedItem] = useState<ReturnProductRenderType | undefined>(undefined);
    const [isUploading, setIsUploading] = useState(false);

    const {
        data: returnProducts,
        isLoading,
        isRefetching,
        refetch,
    } = useQuery({
        queryKey: ["return_products"],
        queryFn: async () => {
            const data = await getReturnProducts();
            return data.map((item, idx) => ({...item, key: idx})) as ReturnProductRenderType[];
        },
        enabled: !!currentUser,
    });

    const columns: EditableColumnType<ReturnProductRenderType>[] = [
        {
            title: "Product Name",
            dataIndex: "product-name",
            key: "product-name",
            width: "200px",
            ...getDefaultFilterSorter("product-name", searchInputRef),
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
            width: "100px",
            ...getDefaultFilterSorter("product-name", searchInputRef),
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
            width: "100px",
            ...getDefaultFilterSorter("product-name", searchInputRef),
        },
        {
            title: "Order ID",
            dataIndex: "customer-order-id",
            key: "customer-order-id",
            width: "140px",
            ...getDefaultFilterSorter("product-name", searchInputRef),
        },
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            width: "60px",
            render: (imgURL) =>
                !imgURL ? <Skeleton.Image style={{width: 40, height: 40}} /> : <Avatar src={imgURL} size={40} shape="square" />,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: "60px",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            editable: true,
            inputType: "select",
            inputOptions: {
                selectableValues: Object.keys(RETURN_ACTION_LOOKUP).map((key) => ({
                    label: RETURN_ACTION_LOOKUP[key as keyof typeof RETURN_ACTION_LOOKUP],
                    value: key,
                })),
            },
            width: "100px",
            render: (action: keyof typeof RETURN_ACTION_LOOKUP) => RETURN_ACTION_LOOKUP[action],
            ...getDefaultFilterSorter("product-name", searchInputRef),
        },
        {
            title: "Case created",
            dataIndex: "caseCreated",
            key: "caseCreated",
            width: "60px",
            editable: true,
            inputType: "bool",
            ...getBooleanFilter("caseCreated"),
            ...getBoolRenderer(),
        },
        {
            title: "Reimbursed",
            dataIndex: "reimbursed",
            key: "reimbursed",
            width: "65px",
            editable: true,
            inputType: "bool",
            ...getBooleanFilter("reimbursed"),
            ...getBoolRenderer(),
        },
        {
            title: "Submitted",
            dataIndex: "submitted",
            key: "submitted",
            width: "60px",
            editable: true,
            inputType: "bool",
            ...getBooleanFilter("submitted"),
            ...getBoolRenderer(),
        },
        {
            title: "Photos",
            dataIndex: "photos",
            key: "photos",
            width: "150px",
            render: (photos: string[] | undefined, record: ReturnProductRenderType) => (
                <Space>
                    <Tooltip title="Download Photos">
                        <Button
                            icon={<CloudDownloadOutlined />}
                            onClick={() => handleDownloadPhotos(photos, record)}
                            disabled={!photos || photos.length === 0}
                            shape="circle"
                        />
                    </Tooltip>
                    <Tooltip title="Upload Photos">
                        <Button shape="circle" icon={<CloudUploadOutlined />} onClick={() => setSelectedItem(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const editableColumns = makeColumnsEditable(columns, (record, column) => {
        makeCall("returns_v2/addProducts", {}, "POST", {products: [record]}).then(() => {
            notification.success({
                message: "Success",
                description: "Product updated",
            });
        });
        // Update the query data and the client
        const updatedData = returnProducts?.map((item) => (item.key === record.key ? record : item));
        queryClient.setQueryData(["return_products"], updatedData);
    });

    const handleDownloadPhotos = async (photos: string[] | undefined, record: ReturnProductRenderType) => {
        if (!photos || photos.length === 0) return;

        const baseFileName = `${record["license-plate-number"]}`;

        for (let i = 0; i < photos.length; i++) {
            const id = photos[i];
            const url = `https://res.cloudinary.com/mooregroup/image/upload/${id}.png`;

            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const fileName = `${baseFileName}_${i + 1}.png`;

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error(`Failed to download photo ${i + 1}:`, error);
                notification.error({
                    message: "Download Error",
                    description: `Failed to download photo ${i + 1}`,
                });
            }
        }
    };

    const handleUploadPhotos = async (files: {file: any; bytes: ArrayBuffer}[]) => {
        if (!selectedItem) {
            notification.error({
                message: "Something went wrong",
            });
            return;
        }

        try {
            setIsUploading(true);
            const uploadedUrls = await uploadPhotos(files);
            const updatedPhotos = [...(selectedItem.photos || []), ...uploadedUrls];

            // Update the record with the new photos
            const updatedRecord = {...selectedItem, photos: updatedPhotos};

            // Update the query data and the client
            const updatedData = returnProducts?.map((item) => (item.key === selectedItem.key ? updatedRecord : item));
            queryClient.setQueryData(["return_products"], updatedData);

            // Save the updated record to the backend
            await makeCall("returns_v2/addProducts", {}, "POST", {products: [updatedRecord]});

            notification.success({
                message: "Success",
                description: "Photos uploaded successfully",
            });
        } catch (error) {
            console.error("Failed to upload photos:", error);
            notification.error({
                message: "Error",
                description: "Failed to upload photos",
            });
        } finally {
            setSelectedItem(undefined);
        }
        setIsUploading(false);
    };

    const handleReload = () => {
        refetch();
    };

    return (
        <div>
            <Space direction="vertical" style={{width: "100%"}}>
                <EditableTable<ReturnProductRenderType>
                    scroll={{y: "75vh", x: "max-content"}}
                    tableData={returnProducts}
                    columns={editableColumns}
                    loading={isLoading || isUploading || isRefetching}
                    extraActions={() => <Button icon={<ReloadOutlined />} onClick={handleReload} />}
                />
            </Space>
            <UploadPhotosDialog open={!!selectedItem} onClose={() => setSelectedItem(undefined)} onFinish={handleUploadPhotos} />
        </div>
    );
};

export default ViewReturns;
