import React, {useState} from "react";
import {Avatar, Col, Descriptions, Modal, Row, Select, Skeleton, Typography} from "antd";
import {batchCategories, BatchCategory, WarehouseProduct} from "../../../types/WarehouseTypes";

interface MoveItemDialogProps {
    open: boolean;
    item?: WarehouseProduct;
    onCancel: () => void;
    onMove: (item: WarehouseProduct, batchCategory: BatchCategory) => void;
}

const MoveItemDialog: React.FC<MoveItemDialogProps> = ({open, onCancel, onMove, item}) => {
    const [batchCategory, setBatchCategory] = useState<BatchCategory>("Standard");

    if (!item) {
        return null;
    }

    return (
        <Modal title="Move Item" open={open} onCancel={onCancel} onOk={() => onMove(item, batchCategory)} width={600}>
            <Row>
                <Col span={6}>{item.imgURL ? <Avatar src={item.imgURL} size={120} shape="square" /> : <Skeleton.Image />}</Col>
                <Col span={18}>
                    <Descriptions
                        column={1}
                        size="small"
                        bordered
                        items={[
                            {key: "1", label: "Name", children: item.name},
                            {key: "2", label: "ASIN", children: item.asin},
                            {key: "3", label: "SKU", children: item.sku},
                        ]}
                    />
                    <Row align={"middle"} style={{marginTop: 8}}>
                        <Typography.Text style={{fontSize: 14}}>New Category: </Typography.Text>
                        <Select
                            value={batchCategory}
                            onChange={(value) => setBatchCategory(value)}
                            style={{width: "50%", marginLeft: 8}}
                            options={batchCategories.map((category) => ({
                                value: category,
                                label: <span>{category}</span>,
                            }))}
                        />
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default MoveItemDialog;
