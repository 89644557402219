import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Input, message, Modal, notification, Row, Space, Spin, Switch, Tooltip, Typography, Upload} from "antd";
import {RcFile} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import dayjs from "dayjs";
import React from "react";
import {processData47Helper} from "../components/utilities/helpers/47Helper";
import {
    processDataAdidasHelper,
    processDataAdidasOMSHelper,
    processDataAdidasOrderHelper,
    processDataAdidasUPCHelper,
} from "../components/utilities/helpers/AdidasHelper";
import {downloadAsExcel, downloadHelper} from "../components/utilities/helpers/Downloads";
import {processDataNewEraHelper, processDataNewEraReverseHelper} from "../components/utilities/helpers/NewEraHelper";
import {processDataOrgillInvoiceHelper} from "../components/utilities/helpers/OrgillHelper";
import {processDataRestrictionHelper} from "../components/utilities/helpers/RestrictionHelper";
import {processDataUnderArmourHelper, processDataUnderArmourKeepaFilterHelper} from "../components/utilities/helpers/UAHelper";
import {useAuth} from "../contexts/AuthContext";
import {getShipLaterExpanded} from "../services/OmegaService";
import {
    processDataKeepaBBHelper,
    processDataKeepaFilterHelper,
    processDataKeepaNewItemsHelper,
} from "../components/utilities/helpers/KeepaFilterHelper";
import {processDataSKUGrabberHelper} from "../components/utilities/helpers/SKUGrabberHelper";
import {processDataVictronHelper} from "../components/utilities/helpers/VictronHelper";
import {processDataSketchersImportHelper} from "../components/utilities/helpers/SketchersHelper";
import {downloadPectyData, processDataPectyHelper} from "../components/utilities/helpers/PectyHelper";
import {makeCall} from "../services/common";
import {processDataWSInfoHelper} from "../components/utilities/helpers/WSHelper";
const {Text} = Typography;

enum helperType {
    "newera_quickorder",
    "newera_buysheet",
    "newera_import",
    "helper47",
    "underarmour",
    "underarmour_keepa_filter",
    "adidas",
    "adidas_upc",
    "adidas_upc_new",
    "adidas_order",
    "orgill_invoice",
    "adidas_oms_oc",
    "adidas_oms_os",
    "restriction_checker",
    "keepa_filter",
    "keepa_olditems",
    "keepa_newitems",
    "sku_grabber",
    "victron_ws_form",
    "sketchers_stage1",
    "keepa_bb",
    "pecty_data",
    "smartscout_map",
    "ws_info",
}

const ARRAY_MODE: {[key: string]: boolean} = {
    [helperType.orgill_invoice]: true,
    [helperType.underarmour]: true,
    [helperType.underarmour_keepa_filter]: true,
    [helperType.restriction_checker]: true,
    [helperType.keepa_filter]: true,
    [helperType.newera_import]: true,
    [helperType.newera_quickorder]: true,
    [helperType.newera_buysheet]: true,
    [helperType.keepa_olditems]: true,
    [helperType.keepa_newitems]: true,
    [helperType.sketchers_stage1]: true,
    [helperType.keepa_bb]: true,
    [helperType.pecty_data]: true,
    [helperType.smartscout_map]: true,
    [helperType.ws_info]: true,
};

const TEXT_COL_SPAN = 6;
const BUTTON_COL_SPAN = 6;

const Helpers: React.FC = () => {
    const {currentUser} = useAuth();
    const [modal, contextHolder] = Modal.useModal();
    const [fileMap, setFileMap] = React.useState<{[key in helperType]?: RcFile[] | RcFile | undefined}>({
        [helperType.newera_quickorder]: undefined,
        [helperType.newera_buysheet]: undefined,
        [helperType.newera_import]: undefined,
        [helperType.helper47]: undefined,
        [helperType.underarmour]: undefined,
        [helperType.adidas]: undefined,
        [helperType.adidas_upc]: undefined,
        [helperType.adidas_order]: undefined,
        [helperType.adidas_oms_oc]: undefined,
        [helperType.adidas_oms_os]: undefined,
        [helperType.orgill_invoice]: undefined,
        [helperType.restriction_checker]: undefined,
        [helperType.keepa_filter]: undefined,
        [helperType.keepa_olditems]: undefined,
        [helperType.keepa_newitems]: undefined,
        [helperType.sku_grabber]: undefined,
        [helperType.victron_ws_form]: undefined,
        [helperType.sketchers_stage1]: undefined,
        [helperType.keepa_bb]: undefined,
        [helperType.pecty_data]: undefined,
        [helperType.smartscout_map]: undefined,
        [helperType.ws_info]: undefined,
    });
    const [uploading, setUploading] = React.useState(false);
    const [pectyCollectionName, setPectyCollectionName] = React.useState<string>("random");
    const [mapMode, setMapMode] = React.useState<boolean>(false);

    const beforeUpload = (file: RcFile, target: helperType) => {
        const arrayMode = ARRAY_MODE[target];
        if (arrayMode) {
            setFileMap((prev) => ({...prev, [target]: ((prev[target] as RcFile[]) ?? []).concat([file])}));
        } else {
            setFileMap((prev) => ({...prev, [target]: file}));
        }
        return false;
    };

    const onRemove = (file: UploadFile, target: helperType) => {
        const arrayMode = ARRAY_MODE[target];
        if (arrayMode) {
            const previousSet: RcFile[] = (fileMap[target] as RcFile[] | undefined) ?? [];
            const newSet = previousSet.filter((existingFile) => existingFile.uid !== file.uid);
            setFileMap((prev) => {
                const newState = {...prev};
                newState[target] = newSet;
                return newState;
            });
        } else {
            setFileMap((prev) => {
                const {[target]: removedFile, ...newState} = prev;
                return newState;
            });
        }
    };

    const processDataNewEra = async (data: ArrayBuffer[]) => {
        processDataNewEraHelper(data).finally(() => setUploading(false));
    };

    const processData47 = async (data: ArrayBuffer) => {
        processData47Helper(data).finally(() => setUploading(false));
    };

    const processDataUnderArmour = async (data: ArrayBuffer[]) => {
        processDataUnderArmourHelper(data);
        setUploading(false);
    };

    const processDataUnderArmourKeepaFilter = async (data: ArrayBuffer[]) => {
        processDataUnderArmourKeepaFilterHelper(data);
        setUploading(false);
    };

    const processDataNewEraReverse = async (newEraData: ArrayBuffer[], buysheetData: ArrayBuffer[]) => {
        console.log("processing");
        processDataNewEraReverseHelper(newEraData, buysheetData).finally(() => setUploading(false));
    };

    const preprocessDataDoubleUpload = async (helperFirst: helperType, helperSecond: helperType, processData: any) => {
        const newEraReader = new FileReader();
        const wsReader = new FileReader();
        const done: any = {
            newera: false,
            ws: false,
        };

        newEraReader.onload = async (e) => {
            console.log("preprocessing file", e);
            const text = e.target!.result;
            done.newera = text;
        };

        wsReader.onload = async (e) => {
            console.log("preprocessing file", e);
            const text = e.target!.result;
            done.ws = text;
        };

        const isDone = () => done.newera && done.ws;

        newEraReader.onloadend = async (e) => {
            if (isDone()) {
                console.log("DONE FROM NEWERA");
                processData(done.newera, done.ws);
                setUploading(false);
            }
        };

        wsReader.onloadend = async (e) => {
            if (isDone()) {
                console.log("DONE FROM WS");
                processData(done.newera, done.ws);
                setUploading(false);
            }
        };

        newEraReader.readAsArrayBuffer(fileMap[helperFirst] as Blob);
        wsReader.readAsArrayBuffer(fileMap[helperSecond] as Blob);
    };

    const processDataDoubleUploadArray = async (helperFirst: helperType, helperSecond: helperType, processData: any) => {
        let filesFirst = (fileMap[helperFirst] as RcFile[]).map((file) => {
            let reader = new FileReader();
            return new Promise((resolve) => {
                reader.onload = () => resolve(reader.result);
                reader.readAsArrayBuffer(file as Blob);
            });
        });

        let filesSecond = (fileMap[helperSecond] as RcFile[]).map((file) => {
            let reader = new FileReader();
            return new Promise((resolve) => {
                reader.onload = () => resolve(reader.result);
                reader.readAsArrayBuffer(file as Blob);
            });
        });

        let res = await Promise.all([Promise.all(filesFirst), Promise.all(filesSecond)]);
        processData(res[0], res[1]);
        setUploading(false);
    };

    const processDataAdidas = async (data: ArrayBuffer) => {
        console.log("processing");
        processDataAdidasHelper(modal, currentUser, data).finally(() => setUploading(false));
    };

    const processDataAdidasUPC = async (data: ArrayBuffer, isNew: boolean = false) => {
        console.log("processing");
        processDataAdidasUPCHelper(currentUser, data, isNew).finally(() => setUploading(false));
    };

    const processDataAdidasOrder = async (data: ArrayBuffer) => {
        console.log("processing");
        processDataAdidasOrderHelper(data).finally(() => setUploading(false));
    };

    const processDataRestrictionChecker = async (data: ArrayBuffer[]) => {
        console.log("processing");
        processDataRestrictionHelper(data).finally(() => setUploading(false));
    };

    const processDataKeepaFilter = async (data: ArrayBuffer[]) => {
        console.log("processing");
        processDataKeepaFilterHelper(data);
        setUploading(false);
    };

    const processDataOrgillInvoice = async (data: ArrayBuffer[]) => {
        console.log("processing orgill invoice", data);
        processDataOrgillInvoiceHelper(data);
        setUploading(false);
    };

    const processDataSketchersStage1 = async (data: ArrayBuffer[]) => {
        console.log("processing orgill invoice", data);
        processDataSketchersImportHelper(data);
        setUploading(false);
    };

    const processDataAdidasOMS = async (adidasOCData: ArrayBuffer, adidasOSDData: ArrayBuffer) => {
        console.log("processing");
        processDataAdidasOMSHelper(adidasOCData, adidasOSDData).then(() => setUploading(false));
    };

    const processDataSKUGrabber = async (data: ArrayBuffer) => {
        console.log("processing");
        processDataSKUGrabberHelper(data).finally(() => setUploading(false));
    };

    const processDataKeepaBB = async (data: ArrayBuffer[]) => {
        console.log("processing");
        processDataKeepaBBHelper(data).finally(() => setUploading(false));
    };

    const processDataPecty = async (data: ArrayBuffer[]) => {
        console.log("processing");
        processDataPectyHelper(data, pectyCollectionName).finally(() => setUploading(false));
    };

    const processSmartScoutMap = async (data: ArrayBuffer[]) => {
        const arrays = data.map((arrayBuffer) => {
            const arr = new Uint8Array(arrayBuffer);
            return Array.from(arr);
        });
        try {
            const res = await makeCall("smartscout/map", {key: "maximumsecurity"}, "POST", arrays, "v2");

            downloadAsExcel(new Uint8Array(res.file).buffer, res.filename);

            if (res.messages) {
                res.messages.forEach((message: any) => {
                    notification.open({
                        type: message.type,
                        message: message.message,
                        placement: "topLeft",
                    });
                });
            }
        } catch (e: any) {
            notification.error({message: "Error", description: e.message});
        }
        setUploading(false);
    };

    const processDataWSInfo = async (data: ArrayBuffer[]) => {
        console.log("processing");
        processDataWSInfoHelper(data).finally(() => setUploading(false));
    };

    const processDataVictronWSForm = async (data: ArrayBuffer) => {
        console.log("processing");
        processDataVictronHelper(data, currentUser).finally(() => setUploading(false));
    };

    const downloadShipLater = () => {
        return currentUser!.getIdToken().then((token: string) => getShipLaterExpanded(token));
    };

    const handleUpload = async (helper: helperType) => {
        setUploading(true);

        if (helper === helperType.newera_quickorder) {
            processDataDoubleUploadArray(helperType.newera_quickorder, helperType.newera_buysheet, processDataNewEraReverse);
        } else if (helper === helperType.adidas_oms_oc) {
            preprocessDataDoubleUpload(helperType.adidas_oms_oc, helperType.adidas_oms_os, processDataAdidasOMS);
        } else if (helper === helperType.keepa_newitems) {
            processDataDoubleUploadArray(helperType.keepa_olditems, helperType.keepa_newitems, processDataKeepaNewItemsHelper);
        } else if (ARRAY_MODE[helper]) {
            let files = (fileMap[helper] as RcFile[]).map((file) => {
                let reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = () => resolve(reader.result);
                    reader.readAsArrayBuffer(file as Blob);
                });
            });

            // At this point you'll have an array of results
            let res = await Promise.all(files);

            switch (helper) {
                case helperType.orgill_invoice:
                    processDataOrgillInvoice(res as ArrayBuffer[]);
                    break;
                case helperType.underarmour:
                    processDataUnderArmour(res as ArrayBuffer[]);
                    break;
                case helperType.underarmour_keepa_filter:
                    processDataUnderArmourKeepaFilter(res as ArrayBuffer[]);
                    break;
                case helperType.restriction_checker:
                    processDataRestrictionChecker(res as ArrayBuffer[]);
                    break;
                case helperType.keepa_filter:
                    processDataKeepaFilter(res as ArrayBuffer[]);
                    break;
                case helperType.newera_import:
                    processDataNewEra(res as ArrayBuffer[]);
                    break;
                case helperType.sketchers_stage1:
                    processDataSketchersStage1(res as ArrayBuffer[]);
                    break;
                case helperType.keepa_bb:
                    processDataKeepaBB(res as ArrayBuffer[]);
                    break;
                case helperType.pecty_data:
                    processDataPecty(res as ArrayBuffer[]);
                    break;
                case helperType.smartscout_map:
                    processSmartScoutMap(res as ArrayBuffer[]);
                    break;
                case helperType.ws_info:
                    processDataWSInfo(res as ArrayBuffer[]);
                    break;
                default:
                    break;
            }
        } else {
            const reader = new FileReader();
            reader.onload = async (e) => {
                console.log("preprocessing");
                const text = e.target!.result;

                switch (helper) {
                    case helperType.helper47:
                        processData47(text as ArrayBuffer);
                        break;
                    case helperType.adidas:
                        processDataAdidas(text as ArrayBuffer);
                        break;
                    case helperType.adidas_upc:
                        processDataAdidasUPC(text as ArrayBuffer, false);
                        break;
                    case helperType.adidas_upc_new:
                        processDataAdidasUPC(text as ArrayBuffer, true);
                        break;
                    case helperType.adidas_order:
                        processDataAdidasOrder(text as ArrayBuffer);
                        break;
                    case helperType.sku_grabber:
                        processDataSKUGrabber(text as ArrayBuffer);
                        break;
                    case helperType.victron_ws_form:
                        processDataVictronWSForm(text as ArrayBuffer);
                        break;
                    default:
                        message.error("Invalid helper type");
                        setUploading(false);
                        break;
                }
            };

            if (helper === helperType.underarmour) {
                reader.readAsText(fileMap[helper] as Blob);
            } else {
                reader.readAsArrayBuffer(fileMap[helper] as Blob);
            }
        }
    };

    return (
        <Spin spinning={uploading}>
            <>
                {contextHolder}
                <Row gutter={[16, 16]} justify="center" align="middle">
                    <Col span={10}>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>NewEra Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.newera_import)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.newera_import)}
                                        accept={".xlsx"}
                                        name="NewEraData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.newera_import)}
                                        disabled={!fileMap[helperType.newera_import]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>'47 Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.helper47)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.helper47)}
                                        accept={".xlsx"}
                                        name="47Data"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.helper47)}
                                        disabled={!fileMap[helperType.helper47]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>New Era Reverse Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal" align="center">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.newera_quickorder)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.newera_quickorder)}
                                        accept={".xlsx"}
                                        name="NewEraFillData"
                                        listType="text"
                                    >
                                        <Button icon={<UploadOutlined />}>Quick-Order Sell Sheet </Button>
                                    </Upload>
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.newera_buysheet)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.newera_buysheet)}
                                        accept={".xlsx"}
                                        name="NewEraBuysheetData"
                                        listType="text"
                                    >
                                        <Button icon={<UploadOutlined />}>WS Export</Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.newera_quickorder)}
                                        disabled={!fileMap[helperType.newera_quickorder] || !fileMap[helperType.newera_buysheet]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>UnderArmour Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.underarmour)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.underarmour)}
                                        accept={".csv"}
                                        name="UAData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.underarmour)}
                                        disabled={!fileMap[helperType.underarmour]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>
                                    UnderArmour Filter Helper{" "}
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div style={{textAlign: "center"}}>
                                                New Price === "" AND Review Count {">"} 10 <br />
                                                OR <br />
                                                SR {"<="} 400k AND AZ OOS {">"} 0% AND Review Count {">"} 20
                                            </div>
                                        }
                                    >
                                        <ExclamationCircleOutlined />
                                    </Tooltip>{" "}
                                    :
                                </Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.underarmour_keepa_filter)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.underarmour_keepa_filter)}
                                        accept={".csv"}
                                        name="UAData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.underarmour_keepa_filter)}
                                        disabled={!fileMap[helperType.underarmour_keepa_filter]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Adidas UPC Uploader:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => {
                                            beforeUpload(file, helperType.adidas_upc);
                                            beforeUpload(file, helperType.adidas_upc_new);
                                            return false;
                                        }}
                                        onRemove={(file: UploadFile) => {
                                            onRemove(file, helperType.adidas_upc);
                                            onRemove(file, helperType.adidas_upc_new);
                                        }}
                                        accept={".xlsx"}
                                        name="AdiUPCData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.adidas_upc)}
                                        disabled={!fileMap[helperType.adidas_upc]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload (UPC DB)"}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.adidas_upc_new)}
                                        disabled={!fileMap[helperType.adidas_upc]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload (Get New)"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Adidas ATS Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.adidas)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.adidas)}
                                        accept={".xlsx"}
                                        name="AdiData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.adidas)}
                                        disabled={!fileMap[helperType.adidas]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Adidas Order Form Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.adidas_order)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.adidas_order)}
                                        accept={".xlsx"}
                                        name="AdiData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.adidas_order)}
                                        disabled={!fileMap[helperType.adidas_order]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Adidas OMS Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.adidas_oms_oc)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.adidas_oms_oc)}
                                        accept={".xlsx,.xls"}
                                        name="AdidasOCData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload OC
                                        </Button>
                                    </Upload>
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.adidas_oms_os)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.adidas_oms_os)}
                                        accept={".xlsx,.xls"}
                                        name="AdidasOSData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload OS
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.adidas_oms_oc)}
                                        disabled={!fileMap[helperType.adidas_oms_oc] || !fileMap[helperType.adidas_oms_os]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Keepa BB Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.keepa_bb)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.keepa_bb)}
                                        accept={".csv,.tsv"}
                                        name="keepaBB"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.keepa_bb)}
                                        disabled={
                                            !fileMap[helperType.keepa_bb] ||
                                            (Array.isArray(fileMap[helperType.keepa_bb]) &&
                                                (fileMap[helperType.keepa_bb] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>
                                    Pecty Helper{" "}
                                    <Tooltip
                                        placement="top"
                                        title={<div style={{textAlign: "center"}}>Make sure to fill in your collection name</div>}
                                    >
                                        <ExclamationCircleOutlined />
                                    </Tooltip>{" "}
                                    :
                                </Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="vertical">
                                    <Input
                                        placeholder="Collection Name"
                                        value={pectyCollectionName}
                                        onChange={(e) => {
                                            setPectyCollectionName(e.target.value);
                                        }}
                                    ></Input>
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.pecty_data)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.pecty_data)}
                                        accept={".csv,.tsv"}
                                        name="pectyData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                if (pectyCollectionName === "") {
                                                    message.error("Please fill in the collection name");
                                                    return;
                                                } else if (pectyCollectionName.match(/[\s\W\d]/g)) {
                                                    message.error("Collection name cannot contain spaces, numbers or special characters");
                                                    return;
                                                } else {
                                                    handleUpload(helperType.pecty_data);
                                                }
                                            }}
                                            disabled={
                                                !fileMap[helperType.pecty_data] ||
                                                (Array.isArray(fileMap[helperType.pecty_data]) &&
                                                    (fileMap[helperType.pecty_data] as RcFile[]).length === 0)
                                            }
                                            loading={uploading}
                                        >
                                            {uploading ? "Uploading" : "Start Upload"}
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                if (pectyCollectionName === "") {
                                                    message.error("Please fill in the collection name");
                                                    return;
                                                } else if (pectyCollectionName.match(/[\s\W\d]/g)) {
                                                    message.error("Collection name cannot contain spaces, numbers or special characters");
                                                    return;
                                                } else {
                                                    message.info("Starting your download...");
                                                    downloadPectyData(pectyCollectionName, mapMode);
                                                }
                                            }}
                                            disabled={pectyCollectionName === ""}
                                            loading={uploading}
                                        >
                                            {"Download"}
                                        </Button>
                                        <Switch
                                            checkedChildren="Map Mode"
                                            unCheckedChildren="Normal Mode"
                                            value={mapMode}
                                            onChange={(checked) => setMapMode(checked)}
                                        ></Switch>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                    </Col>
                    <Col span={10}>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Orgill Invoice Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.orgill_invoice)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.orgill_invoice)}
                                        accept={".xlsx,.xls"}
                                        name="OrgillInvoiceData"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.orgill_invoice)}
                                        disabled={
                                            !fileMap[helperType.orgill_invoice] ||
                                            (Array.isArray(fileMap[helperType.orgill_invoice]) &&
                                                (fileMap[helperType.orgill_invoice] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Restriction Checker Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.restriction_checker)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.restriction_checker)}
                                        accept={".csv"}
                                        name="RestrictionChecker"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.restriction_checker)}
                                        disabled={
                                            !fileMap[helperType.restriction_checker] ||
                                            (Array.isArray(fileMap[helperType.restriction_checker]) &&
                                                (fileMap[helperType.restriction_checker] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>
                                    Keepa Filter Helper{" "}
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div style={{textAlign: "center"}}>
                                                New Price === "" AND Review Count {">"} 10 <br />
                                                OR <br />
                                                SR {"<="} 800k AND Review Count {">"} 20
                                            </div>
                                        }
                                    >
                                        <ExclamationCircleOutlined />
                                    </Tooltip>{" "}
                                    :
                                </Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.keepa_filter)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.keepa_filter)}
                                        accept={".csv"}
                                        name="RestrictionChecker"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.keepa_filter)}
                                        disabled={
                                            !fileMap[helperType.keepa_filter] ||
                                            (Array.isArray(fileMap[helperType.keepa_filter]) &&
                                                (fileMap[helperType.keepa_filter] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Keepa New Items Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.keepa_olditems)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.keepa_olditems)}
                                        accept={".csv"}
                                        name="keepaOld"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload Old Items
                                        </Button>
                                    </Upload>
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.keepa_newitems)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.keepa_newitems)}
                                        accept={".csv"}
                                        name="keepaNew"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload New Items
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.keepa_newitems)}
                                        disabled={!fileMap[helperType.keepa_olditems] || !fileMap[helperType.keepa_newitems]}
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>SKU Grabber:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.sku_grabber)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.sku_grabber)}
                                        accept={".csv"}
                                        name="SkuGrabber"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.sku_grabber)}
                                        disabled={
                                            !fileMap[helperType.sku_grabber] ||
                                            (Array.isArray(fileMap[helperType.sku_grabber]) &&
                                                (fileMap[helperType.sku_grabber] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Victron WS Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.victron_ws_form)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.victron_ws_form)}
                                        accept={".xls"}
                                        name="VictronWSHelper"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.victron_ws_form)}
                                        disabled={
                                            !fileMap[helperType.victron_ws_form] ||
                                            (Array.isArray(fileMap[helperType.victron_ws_form]) &&
                                                (fileMap[helperType.victron_ws_form] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Sketchers Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.sketchers_stage1)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.sketchers_stage1)}
                                        accept={".csv"}
                                        name="SketchersHelper"
                                        listType="text"
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.sketchers_stage1)}
                                        disabled={
                                            !fileMap[helperType.sketchers_stage1] ||
                                            (Array.isArray(fileMap[helperType.sketchers_stage1]) &&
                                                (fileMap[helperType.sketchers_stage1] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>SmartScout MAP Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.smartscout_map)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.smartscout_map)}
                                        accept={".csv"}
                                        name="SketchersHelper"
                                        listType="text"
                                        multiple
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.smartscout_map)}
                                        disabled={
                                            !fileMap[helperType.smartscout_map] ||
                                            (Array.isArray(fileMap[helperType.smartscout_map]) &&
                                                (fileMap[helperType.smartscout_map] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Item Info Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Space direction="horizontal">
                                    <Upload
                                        beforeUpload={(file: RcFile) => beforeUpload(file, helperType.ws_info)}
                                        onRemove={(file: UploadFile) => onRemove(file, helperType.ws_info)}
                                        accept={".csv"}
                                        name="SketchersHelper"
                                        listType="text"
                                        multiple
                                    >
                                        <Button block icon={<UploadOutlined />}>
                                            Click to upload
                                        </Button>
                                    </Upload>
                                    <Button
                                        type="primary"
                                        onClick={() => handleUpload(helperType.ws_info)}
                                        disabled={
                                            !fileMap[helperType.ws_info] ||
                                            (Array.isArray(fileMap[helperType.ws_info]) &&
                                                (fileMap[helperType.ws_info] as RcFile[]).length === 0)
                                        }
                                        loading={uploading}
                                    >
                                        {uploading ? "Uploading" : "Start Upload"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Ship Later Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        downloadShipLater().then((result) =>
                                            downloadHelper(
                                                result.map(({_id, ...rest}: any) => rest),
                                                `${dayjs().format("MM-DD-YYYY")}-ShipLaterExpanded`
                                            )
                                        )
                                    }
                                >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Seasonal Sales Rank / Offers Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        "https://docs.google.com/spreadsheets/d/1KVzLit9XcLhVeDoVjkVFMfLxR7_QBvCRzdekgVZhUKE/edit#gid=1199487503"
                                    }
                                >
                                    <Button type="primary">Link</Button>
                                </a>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col span={TEXT_COL_SPAN}>
                                <Text style={{marginRight: "10px"}}>Restriction Helper:</Text>
                            </Col>
                            <Col span={BUTTON_COL_SPAN}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        "https://docs.google.com/spreadsheets/d/1DrZJkIhxMD58yZubv-jz5Bn40miUHWWJQUGdMQQLzwI/edit#gid=46890888"
                                    }
                                >
                                    <Button type="primary">Link</Button>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        </Spin>
    );
};

export default Helpers;
