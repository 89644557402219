import {Card, Divider, Space, Statistic, Typography} from "antd";
import React from "react";
import "./Popover.scss";
import {WholesaleDataType} from "./WholesaleColumns";

const GET_GMROI_COLOR = (val: number) => {
    if (val >= 2) {
        return "lightgreen";
    } else if (val >= 1) {
        return "yellow";
    } else if (val >= 0) {
        return "white";
    } else {
        return "red";
    }
};

const GMROIPopover: React.FC<{record: WholesaleDataType}> = React.memo(({record}) => {
    const selectedTtsStyle = {border: "2px solid", borderRadius: "10px", padding: "5px", borderColor: "lightgreen"};
    const gmroiStyle = {border: "2px solid", borderRadius: "10px", padding: "5px", borderColor: "white"};

    return (
        <Card size="small">
            <Space
                split={<Divider type="vertical"></Divider>}
                align="center"
                direction="horizontal"
                style={{display: "flex", justifyContent: "space-around"}}
            >
                <Statistic
                    style={record.wsGMROITTS !== -1 && record.wsGMROITTS === record.wsTTS ? selectedTtsStyle : {}}
                    title="WsTTS:"
                    value={record.wsTTS}
                    precision={0}
                />
                <Statistic
                    style={record.wsGMROITTS !== -1 && record.wsGMROITTS === record.wsVelTTS ? selectedTtsStyle : {}}
                    title="VelTTS:"
                    value={record.wsVelTTS}
                    precision={0}
                />
                <Divider type="vertical"></Divider>
                <Statistic title="Profit (CC):" value={record.ProfitGMROI} precision={2} prefix={"$"} />
                <Typography> / </Typography>
                <Typography>{"("}</Typography>
                <Statistic title="AskPrice:" value={record.AskPrice} precision={2} prefix={"$"} />
                <Typography> + </Typography>
                <Statistic title="Shipping:" value={record.shippingCost} precision={2} prefix={"$"} />
                <Typography>{")"}</Typography>
                <Typography>*</Typography>
                <Statistic title="Year:" value={365} precision={0} />
                {record.wsGMROITTS + record.prepDelay > record.netTerm ? (
                    <>
                        <Typography> / {"("}</Typography>
                        <Statistic title="TTS:" value={record.wsGMROITTS} precision={0} />
                        <Typography> + </Typography>
                        <Statistic title="Prep:" value={record.prepDelay} precision={0} />
                        <Typography> - </Typography>
                        <Statistic title="NetTerm:" value={record.netTerm} precision={0} />
                        <Typography>{")"} = </Typography>
                    </>
                ) : (
                    <></>
                )}
                <Statistic
                    style={{...gmroiStyle, borderColor: GET_GMROI_COLOR(record.wsGMROI)}}
                    title="GMROI:"
                    value={record.wsGMROI}
                    valueStyle={{color: GET_GMROI_COLOR(record.wsGMROI)}}
                    precision={2}
                />
            </Space>
        </Card>
    );
});

export default GMROIPopover;
