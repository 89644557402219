import {DownloadOutlined} from "@ant-design/icons";
import {Button, Dropdown, MenuProps} from "antd";
import * as dataForge from "data-forge";
import moment from "moment";
import React from "react";

const download = (
    data: any[] | undefined | null,
    collection: string,
    dateColumns?: string[],
    parse?: (data: any[]) => any[] | undefined
) => {
    if (!data || !data.length) return;

    if (parse) {
        data = parse(data);
    }

    // Check if parsing didn't remove data
    if (!data || !data.length) return;

    let df: dataForge.IDataFrame<number, any> = new dataForge.DataFrame(data);

    if (dateColumns) {
        const transformDict = Object.fromEntries(
            dateColumns.map((col) => [col, (val: string) => (val ? moment(val).format("MM/DD/YYYY") : "")])
        );
        df = df.transformSeries(transformDict);
    }
    const text = df.toCSV();

    const file = new Blob([text], {type: "text/plain;charset=utf-8"});
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = collection + ".csv";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
};

export interface CSVDownloadProps {
    data: any[] | null | undefined;
    isLoading: boolean;
    collection: string;
    filteredData?: number[];
    columnData?: any[];
    dateColumns?: string[];
    title?: string;
    parse?: (data: any[]) => any[] | undefined;
    extraOptions?: any;
}

export function csvDownloadFunction(data: any[], collection: string, parse?: ((data: any[]) => any[]) | undefined) {
    if (!data || !data.length) return;

    if (parse) {
        data = parse(data);
    }

    // Check if parsing didn't remove data
    if (!data || !data.length) return;

    let df: dataForge.IDataFrame<number, any> = new dataForge.DataFrame(data);

    const text = df.toCSV();

    const file = new Blob([text], {type: "text/plain;charset=utf-8"});
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = collection + ".csv";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
}

export function CSVDownload({data, isLoading, collection, parse, title, dateColumns}: CSVDownloadProps) {
    return (
        <Button
            type="primary"
            onClick={() => download(data, collection, dateColumns, parse)}
            disabled={!data || data.length === 0 || isLoading}
        >
            <DownloadOutlined /> {title ? title : `Download CSV`}
        </Button>
    );
}

export interface CSVDownloadAction {
    label: string;
    parse?: (data: any[]) => any[] | undefined;
    dateColumns?: string[];
    collection: string;
}

interface CSVDownloadExtraProps {
    data: any[] | undefined;
    mainAction: CSVDownloadAction;
    extraActions: CSVDownloadAction[];
}

export function CSVDownloadExtra({data, mainAction, extraActions}: CSVDownloadExtraProps) {
    const menuItems: MenuProps["items"] = extraActions.map((action, idx) => ({
        label: action.label,
        key: idx,
        onClick: () => download(data, action.collection, action.dateColumns, action.parse),
        disabled: !data || data.length === 0,
    }));

    return (
        <Dropdown.Button
            type="primary"
            onClick={() => download(data, mainAction.collection, mainAction.dateColumns, mainAction.parse)}
            disabled={!data || data.length === 0}
            menu={{items: menuItems}}
        >
            {mainAction.label}
        </Dropdown.Button>
    );
}
