/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Row, Spin } from 'antd';
import { getOMS, getOMSPOs } from '../../../services/WholesaleService';
import { useAuth } from '../../../contexts/AuthContext';
import POBrowserTable from './POBrowserTable';
import { OMSItem } from '../../../types/OmegaTypes';
import { useQuery } from '@tanstack/react-query';
import { updateMasterValues } from '../../utilities/OMSCalculations';
import { POSelect } from './POSelect';

const POBrowser: React.FC = () => {
    const { currentUser } = useAuth();
    const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
    const [tableData, setTableData] = useState<OMSItem[]>([]);
    const [poProducts, setPoProducts] = useState<{
        [key: string]: OMSItem[];
    }>({});
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] =
        useState<Date>(fetchTimestamp);

    const {
        data: omsData,
        isLoading: omsDataLoading,
        isRefetching,
    } = useQuery({
        queryKey: ['oms_data', selectedPOs.join(',')],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const poString = selectedPOs
                    .map(identifier =>
                        identifier
                            .split(' -- PO: ')[1]
                            .split(' -- SO: ')
                            .filter(el => el !== '?')
                            .pop()
                    )
                    .join(',');
                const data = await getOMS(token, poString);
                return updateMasterValues(data);
            } else {
                return [];
            }
        },
        staleTime: Infinity,
        enabled: !!(selectedPOs && selectedPOs.length > 0),
    });

    const {
        data: poData,
        isLoading: poListLoading,
        isRefetching: poListRefetching,
    } = useQuery({
        queryKey: ['oms_dashboard_data'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const data = await getOMSPOs(token);
                return updateMasterValues(data);
            } else {
                return [];
            }
        },
        staleTime: Infinity,
    });

    useEffect(() => {
        if (omsData) {
            setFetchTimestamp(new Date());
        }
    }, [omsData]);

    const updateTableData = useCallback(() => {
        if (!omsData) return;

        const newTableData = omsData!.filter(item => {
            const poIdentifier = `${item.Supplier_Name} -- PO: ${
                item.Supplier_PO || '?'
            } -- SO: ${item.Supplier_SO || '?'}`;

            return selectedPOs.includes(poIdentifier);
        });

        setTableData(newTableData.filter(item => !item.deleted));
        // Picking different POs and suppliers deletes any unsaved changes
        setLastUpdateTimestamp(fetchTimestamp);
    }, [selectedPOs, fetchTimestamp, omsData]);

    useEffect(() => {
        updateTableData();
    }, [updateTableData]);

    return (
        <Spin spinning={poListLoading || poListRefetching}>
            <POSelect<OMSItem>
                onSelectedPOsChange={pos => setSelectedPOs(pos)}
                onPOProductsChange={products => setPoProducts(products)}
                data={poData}
                disabled={!(poData && poData.length > 0)}
            />
            <Divider />
            <Row>
                <Col span={24}>
                    <POBrowserTable
                        tableData={tableData.map((item, idx) => ({
                            ...item,
                            key: idx,
                        }))}
                        onDataChange={data => {
                            setTableData(data);
                            setLastUpdateTimestamp(new Date());
                        }}
                        isSaved={fetchTimestamp >= lastUpdateTimestamp}
                        onDiscardChanges={() => {
                            updateTableData();
                        }}
                        isDataLoading={
                            !!(selectedPOs && selectedPOs.length > 0) &&
                            (omsDataLoading || isRefetching)
                        }
                        currentQueryKey={['oms_data', selectedPOs.join(',')]}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default POBrowser;
