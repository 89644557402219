import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { PectyOffer, SingularItem } from "../../types/OmegaTypes";
import { Card, Col, Row, Statistic } from "antd";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const InsightsSummary = ({ productData, insight }: { productData: SingularItem, insight: any }) => {

    const belowMin = insight?.OfferData.filter((offer: PectyOffer) => offer.sellerName !== 'M Emporium').reduce((acc: number, curr: PectyOffer) => curr.priceTotal < productData["Min Price"] ? acc += curr.inventoryCount : acc, 0)
    const belowUs = insight?.OfferData.filter((offer: PectyOffer) => offer.sellerName !== 'M Emporium').reduce((acc: number, curr: PectyOffer) => curr.priceTotal < productData["Listed Price"] ? acc += curr.inventoryCount : acc, 0)

    return (
        <Row style={{height: '100%'}} gutter={[12, 20]}>
            <Col span={24} style={{ textAlign: 'center', justifyContent: 'space-around', display: 'flex', flexDirection: 'column', height: '100%' }} >
                <Card size="small" title={`Pecty Summary`} style={{ height: '100%', textAlign: 'center' }} bodyStyle={{ height: '84%', textAlign: 'center', padding: '4px' }} >
                    <Row align="top" justify="center" style={{ height: '100%' }} >
                        <Col style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} flex={1} span={12}>
                            <Statistic title="Successful" value={productData?.pectyData?.data?.offersInventoryRequest === 'complete' ? 'Yes' : 'No'} />
                            <Statistic title="Below Our Listed" value={belowUs} />
                            <Statistic title="Current TTS" value={insight?.YourBandTTS} precision={0} />
                        </Col>
                        <Col style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} flex={1} span={12}>
                            <Statistic title="Timestamp" value={productData?.pectyData?.dateProcessed ? dayjs(productData?.pectyData?.dateProcessed).tz('America/Chicago').format("MM-DD-YYYY HH:mm") : 'None'} />
                            <Statistic title="Below Our Min" value={belowMin} precision={0} />
                            <Statistic title="Suggested Price" value={productData.insight?.SuggestedPrice} precision={2} prefix={'$'} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default InsightsSummary;
