import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Spin, Upload, List, Modal, message } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCollections, getProducts, importProducts } from "../../services/BrandService";
import CollectionsDropdown from "../utilities/CollectionsDropdown";
import { analyzeBrand } from "./AnalyzeData";
import BrandTable from "./BrandTable";
import { BrandData } from "../../types/Brand";
import dayjs from "dayjs"
import { UploadFile } from "antd/lib/upload/interface";
import { useAuth } from "../../contexts/AuthContext";

const styles = {
    card: {
        maxHeight: "100%"
    },
    cardBody: {
        height: 200,
        maxHeight: 200,
        overflow: "auto"
    },
    listSingle: {
        height: 262,
        maxHeight: 262,
        overflow: "auto"
    },
    listDouble: {
        height: 524,
        maxHeight: 524,
        overflow: "auto"
    }
};

const BrandResearch: React.FC = () => {
    const { currentUser } = useAuth()
    const [collection, setCollection] = React.useState('')
    const [fileList, setFileList] = React.useState<RcFile[]>([])
    const [brandData, setBrandData] = React.useState<BrandData>({
        brand: '',
        percentageOfStocked: -1,
        totalBrandValue: -1,
        mostCommonSellers: [],
        parentFrame: []
    })
    const [uploading, setUploading] = React.useState(false)

    const { data, isLoading } = useQuery({
        queryKey: [collection, 'brandresearch'],
        queryFn: async ({ queryKey }) => {
            const col = queryKey[0] as string;
            return col ? getProducts(col) : brandData
        }
    });

    useEffect(() => {
        if (!isLoading && data) {
            setBrandData(data)
        }
    }, [data, isLoading])

    const beforeUpload = (file: RcFile) => {
        const newFileList = [...fileList, file]
        setFileList(newFileList)
        return false;
    }

    const onRemoveBrand = (file: UploadFile) => {
        const newFileList = fileList.filter(el => el.uid !== file.uid)
        setFileList(newFileList)
    }

    const processData = async (data: string) => {
        const result = analyzeBrand(data)
        console.log(result)
        setBrandData(result)
        const collectionString = `${result.brand.replace(/,|\./g, '')}-${dayjs().format('MM-DD-YYYY')}`
        currentUser?.getIdToken()
            .then((token) => importProducts(collectionString, result, token))
            .then(res => res.json())
            .then(res => {
                if (res.error === null) {
                    message.success(`Brand data successfully saved into collection ${collectionString}!`)
                    setCollection(collectionString)
                    setFileList([])
                } else {
                    Modal.error({
                        title: `Failed to save - ${res.msg}`,
                        content: `${res.error}`,
                    });
                }
            }).catch(err => {
                Modal.error({
                    title: `Failed to save`,
                    content: `${err.message}`,
                });
            }).finally(() => setUploading(false))
    }

    const handleUpload = (values: any) => {
        setUploading(true)
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        const reader = new FileReader()
        reader.onload = async (e) => { 
            const text = (e.target!.result)
            processData(text as string)
        };

        reader.readAsText(fileList[0])
    };

    return (
        <Spin spinning={uploading}>
            <Row gutter={[16, 16]} justify="center">
                <Col span={8}>
                    <Space direction="horizontal">
                    <Upload beforeUpload={beforeUpload} onRemove={onRemoveBrand} accept={'.tsv,.csv'} name="brandData" listType="text">
                        <Button block icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        >
                        {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                    </Space>
                </Col>
                <Col span={12}>
                    <CollectionsDropdown onChange={(x) => setCollection(x)} queryKey='brandresearch' getCollections={() => getCollections()}></CollectionsDropdown>
                </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]} justify="center">
                <Col span={8}>
                    <Card title="Most Common BuyBox Owners" style={styles.card} bodyStyle={styles.cardBody} >
                        <List<any>
                            size="small"
                            bordered
                            dataSource={brandData.mostCommonSellers}
                            style={{
                                overflow: 'auto', 
                                }}
                            renderItem={item => <List.Item><a rel="noopener noreferrer" target="_blank" href={`https://www.amazon.com/sp?_encoding=UTF8&asin=B07MMBLY9X&isAmazonFulfilled=1&ref_=olp_merch_name_16&seller=${item.SellerId}`}>{item.SellerId}</a> - {item.BBsOwned}</List.Item>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Various data metrics:" style={styles.card} bodyStyle={styles.cardBody} >
                        <p>% of Stocked Items: {brandData.percentageOfStocked.toFixed(2)}%</p>
                        <p>Monthly Brand Value: ${brandData.totalBrandValue.toLocaleString()}</p>
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]} justify="space-around">
                <Col span={24}>
                    <BrandTable collection={collection} data={brandData.parentFrame}/>
                </Col>
            </Row>
        </Spin>
    )
}

export default BrandResearch;