import {
    CatalogApiGetCatalogItemRequest,
    CatalogApiSearchCatalogItemsRequest,
    Item,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/catalog-items-api-model-v20220401";
import {
    CreateInboundPlanRequest,
    CreateInboundPlanResponse,
    FbaInboundApiGeneratePlacementOptionsRequest,
    FbaInboundApiGetInboundOperationStatusRequest,
    FbaInboundApiGetInboundPlanRequest,
    GeneratePlacementOptionsResponse,
    InboundOperationStatus,
    InboundPlan,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {
    CommonTransportResult,
    CreateInboundShipmentPlanRequest,
    CreateInboundShipmentPlanResult,
    FbaInboundApiCreateInboundShipmentRequest,
    FbaInboundApiGetShipmentsRequest,
    FbaInboundApiPutTransportDetailsRequest,
    FbaInboundApiUpdateInboundShipmentRequest,
    GetShipmentsResult,
    GetTransportDetailsResult,
    InboundShipmentPlan,
    InboundShipmentResponse,
    LabelPrepPreference,
    ShipmentStatus,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import {makeCall, RequestExtraParams} from "./common";
import {InboundBox, InboundShipment, InboundShipmentItem, WarehouseAddress, WarehouseProduct} from "../types/WarehouseTypes";
import {
    CreateFeedResponse,
    Feed,
    FeedsApiGetFeedDocumentRequest,
    FeedsApiGetFeedRequest,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/feeds-api-model-v20210630";

export const MARKETPLACE_ID = "ATVPDKIKX0DER";

export async function searchCatalogItems(request: CatalogApiSearchCatalogItemsRequest & RequestExtraParams): Promise<Item[]> {
    const res = await makeCall("spApi/catalogItems/searchCatalogItems", {...request, devMode: false});

    return res.result || [];
}

export async function getCatalogItem(request: CatalogApiGetCatalogItemRequest & RequestExtraParams): Promise<Item> {
    const res = await makeCall("spApi/catalogItems/getCatalogItem", {...request, devMode: false});

    return res.result;
}

export async function createInboundPlan(request: CreateInboundPlanRequest & RequestExtraParams): Promise<CreateInboundPlanResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/createInboundPlan", {}, "POST", request);

    return res.result;
}

export async function getInboundOperationStatus(
    request: FbaInboundApiGetInboundOperationStatusRequest & RequestExtraParams
): Promise<InboundOperationStatus> {
    const res = await makeCall("spApi/fulfillmentInbound2024/getInboundOperationStatus", request);
    return res.result;
}

export async function getInboundPlan(request: FbaInboundApiGetInboundPlanRequest & RequestExtraParams): Promise<InboundPlan> {
    const res = await makeCall("spApi/fulfillmentInbound2024/getInboundPlan", request);
    return res.result;
}

export async function generatePlacementOptions(
    request: FbaInboundApiGeneratePlacementOptionsRequest
): Promise<GeneratePlacementOptionsResponse> {
    const res = await makeCall("spApi/fulfillmentInbound2024/generatePlacementOptions", {}, "POST", request);
    return res.result;
}

export async function createInboundShipmentPlan(
    products: WarehouseProduct[],
    address: WarehouseAddress,
    labelPrepPreference: LabelPrepPreference = LabelPrepPreference.SellerLabel
): Promise<CreateInboundShipmentPlanResult> {
    // First check if all products have SKU
    const productsWithoutSKU = products.filter((product) => !product.sku);
    if (productsWithoutSKU.length > 0) {
        throw new Error(`The following products do not have SKU: ${productsWithoutSKU.map((product) => product.asin).join(", ")}`);
    }

    const {name, addressLine1, addressLine2, city, stateOrProvinceCode, postalCode, countryCode} = address;

    let request: CreateInboundShipmentPlanRequest = {
        InboundShipmentPlanRequestItems: products.map((product) => {
            if (!product.sku) {
                throw new Error(`Product with ASIN ${product.asin} does not have SKU`);
            }
            return {
                ASIN: product.asin,
                Condition: "NewItem",
                Quantity: product.quantity,
                SellerSKU: product.sku,
            };
        }),
        LabelPrepPreference: labelPrepPreference,
        ShipFromAddress: {
            AddressLine1: addressLine1,
            City: city,
            CountryCode: countryCode,
            Name: name,
            PostalCode: postalCode,
            StateOrProvinceCode: stateOrProvinceCode,
        },
    };

    if (addressLine2 && addressLine2.trim() !== "") {
        request.ShipFromAddress.AddressLine2 = addressLine2;
    }

    const res = await makeCall("spApi/fulfillmentInbound/createInboundShipmentPlan", {devMode: false}, "POST", request);
    return res.result;
}

export async function createInboundShipment(
    shipmentPlan: InboundShipmentPlan,
    shipmentName: string,
    labelPrepPreference: LabelPrepPreference,
    shipmentStatus: ShipmentStatus,
    address: WarehouseAddress,
    devMode?: boolean
): Promise<InboundShipmentResponse> {
    const {name, addressLine1, addressLine2, city, stateOrProvinceCode, postalCode, countryCode} = address;

    let request: FbaInboundApiCreateInboundShipmentRequest = {
        shipmentId: shipmentPlan.ShipmentId,
        body: {
            InboundShipmentHeader: {
                DestinationFulfillmentCenterId: shipmentPlan.DestinationFulfillmentCenterId,
                LabelPrepPreference: labelPrepPreference,
                ShipmentName: shipmentName,
                ShipmentStatus: shipmentStatus,
                ShipFromAddress: {
                    AddressLine1: addressLine1,
                    City: city,
                    CountryCode: countryCode,
                    Name: name,
                    PostalCode: postalCode,
                    StateOrProvinceCode: stateOrProvinceCode,
                },
                IntendedBoxContentsSource: "FEED",
            },
            MarketplaceId: MARKETPLACE_ID,
            InboundShipmentItems: shipmentPlan.Items.map((product) => ({
                SellerSKU: product.SellerSKU,
                QuantityShipped: product.Quantity,
            })),
        },
    };

    // let sandboxRequest: FbaInboundApiCreateInboundShipmentRequest = {
    //     shipmentId: 'ShipmentId',
    //     body: {
    //         InboundShipmentHeader: {
    //             ShipmentName: '43545345',
    //             ShipFromAddress: {
    //                 Name: '35435345',
    //                 AddressLine1: '123 any st',
    //                 DistrictOrCounty: 'Washtenaw',
    //                 City: 'Ann Arbor',
    //                 StateOrProvinceCode: 'Test',
    //                 CountryCode: 'US',
    //                 PostalCode: '48103',
    //             },
    //             DestinationFulfillmentCenterId: 'AEB2',
    //             AreCasesRequired: true,
    //             ShipmentStatus: 'WORKING',
    //             LabelPrepPreference: 'SELLER_LABEL',
    //             IntendedBoxContentsSource: 'NONE',
    //         },
    //         InboundShipmentItems: [
    //             {
    //                 ShipmentId: '345453',
    //                 SellerSKU: '34534545',
    //                 FulfillmentNetworkSKU: '435435435',
    //                 QuantityShipped: 0,
    //                 QuantityReceived: 0,
    //                 QuantityInCase: 0,
    //                 ReleaseDate: '2020-04-23',
    //                 PrepDetailsList: [
    //                     {
    //                         PrepInstruction: 'Polybagging',
    //                         PrepOwner: 'AMAZON',
    //                     },
    //                 ],
    //             },
    //         ],
    //         MarketplaceId: 'MarketplaceId',
    //     },
    // };

    if (addressLine2 && addressLine2.trim() !== "") {
        request.body.InboundShipmentHeader.ShipFromAddress.AddressLine2 = addressLine2;
    }

    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/createInboundShipment", {devMode, ...queryParams}, "POST", body);

    return res.result;
}

export async function updateInboundShipment(
    request: FbaInboundApiUpdateInboundShipmentRequest & RequestExtraParams
): Promise<InboundShipmentResponse> {
    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/updateInboundShipment", queryParams, "PUT", body);
    return res.result;
}

export async function getInboundShipments(request: FbaInboundApiGetShipmentsRequest & RequestExtraParams): Promise<GetShipmentsResult> {
    const res = await makeCall("spApi/fulfillmentInbound/getShipments", request);
    return res.result;
}

export async function submitShipmentContents(
    shipments: InboundShipment[],
    boxes: InboundBox[],
    devMode?: boolean
): Promise<CreateFeedResponse> {
    const res = await makeCall(`shipments_v2/boxing/submitContents`, {devMode}, "POST", {
        shipments,
        boxes,
    });
    return res.result;
}

export async function getFeed(request: FeedsApiGetFeedRequest & RequestExtraParams): Promise<Feed> {
    const res = await makeCall("spApi/feeds/getFeed", request);
    return res.result;
}

export async function downloadReport(request: FeedsApiGetFeedDocumentRequest & RequestExtraParams) {
    const res = await makeCall("spApi/feeds/downloadReport", request);
    return res.result;
}

export async function putTransportDetails(
    request: FbaInboundApiPutTransportDetailsRequest & RequestExtraParams
): Promise<CommonTransportResult> {
    const {body, ...queryParams} = request;
    const res = await makeCall("spApi/fulfillmentInbound/putTransportDetails", queryParams, "PUT", body);
    return res.result;
}

export async function estimateTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/estimateTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

export async function getTransportDetails(shipmentId: string, devMode?: boolean): Promise<GetTransportDetailsResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/getTransportDetails`, {shipmentId, devMode});
    return res.result;
}

export async function confirmTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/confirmTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

export async function voidTransport(shipmentId: string, devMode?: boolean): Promise<CommonTransportResult> {
    const res = await makeCall(`spApi/fulfillmentInbound/voidTransport`, {shipmentId, devMode}, "POST");
    return res.result;
}

export async function getLabels(shipment: InboundShipment, boxIds?: string[], numberOfPallets?: number, devMode?: boolean): Promise<any> {
    const res = await makeCall(`shipments_v2/boxing/getLabels`, {devMode}, "POST", {shipment, boxIds, numberOfPallets});
    return res.result;
}

export async function getBillOfLading(shipmentId: string, devMode?: boolean): Promise<any> {
    const res = await makeCall(`shipments_v2/boxing/getBillOfLading`, {devMode, shipmentId});
    return res.result;
}

export async function removeItemFromShipment(
    shipmentId: string,
    sku: string,
    quantity: number,
    devMode?: boolean
): Promise<InboundShipmentItem[]> {
    const res = await makeCall(`shipments_v2/boxing/deleteItem`, {shipmentId, sku, quantity, devMode}, "DELETE");
    return res.result;
}
