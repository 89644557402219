import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function checkRole(userRole: string | undefined, desiredRole: string) {
    const desired = desiredRole.split(',');
    console.log(desired, userRole);
    if (userRole) {
        if (desired.some(desired => userRole.includes(desired))) {
            return true;
        } else if (userRole === 'ADMIN') {
            return true;
        }
    }

    return false;
}

type PrivateRouteProps = RouteProps & {
    desiredRole: string;
};

export default function PrivateRoute({
    desiredRole,
    children,
    ...rest
}: PrivateRouteProps) {
    const { currentUser, userData } = useAuth();
    if (userData) {
        if (
            (checkRole(userData?.role, desiredRole) ||
                process.env.NODE_ENV === 'development') &&
            currentUser
        ) {
            return <>{children}</>
        } else if (currentUser) {
            return <Navigate to='/' />;
        } else {
            return <Navigate to='/login' />;
        }
    }

    return null;
}
