import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Typography, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Signin.scss';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';

interface LoginFormType {
    username: string;
    password: string;
    remember: boolean;
}

export default function Login() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser, login } = useAuth();
    const navigate = useNavigate();

    const onFinish = async (values: LoginFormType) => {
        console.log('Received values of form: ', values);

        let { username, password, remember } = values;
        try {
            setError('');
            setLoading(true);
            await login(username, password, remember);
            setLoading(false);
            // Redirect to the main page
            navigate('/');
        } catch {
            setLoading(false);
            setError('Failed to sign in');
        }
    };

    if (currentUser) {
        return <Navigate to="/"/>
    }

    return (
        <div id='login-section'>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                Login
            </Typography.Title>
            {error && (
                <Alert message={error} type='error' className='error-alert' />
            )}
            <Form
                name='normal_login'
                className='login-form'
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name='username'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className='site-form-item-icon' />
                        }
                        placeholder='Username'
                    />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <LockOutlined className='site-form-item-icon' />
                        }
                        type='password'
                        placeholder='Password'
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name='remember' valuePropName='checked' noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <a className='login-form-forgot' href='/forgot-password'>
                        Forgot password
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                        loading={loading}
                        disabled={loading}
                    >
                        Log in
                    </Button>
                    {/* Or <a href='/signup'>register now!</a> */}
                </Form.Item>
            </Form>
        </div>
    );
}
