import {Avatar, Col, Descriptions, Divider, List, Row, Skeleton, Space, Typography} from "antd";
import React, {useMemo} from "react";
import {useStore} from "../../../store/useStore";
import {InboundShipment} from "../../../types/WarehouseTypes";
import {countItems} from "./helpers";
import {useShallow} from "zustand/react/shallow";
import QuantityButton from "./QuantityButton";
import SelectWithButton from "../../utilities/SelectWithButton";

const ShipmentRow: React.FC<{shipment: InboundShipment; sku: string}> = ({shipment, sku}) => {
    const {palletsStore, boxesStore} = useStore((state) => ({
        palletsStore: state.pallets,
        boxesStore: state.boxes,
    }));
    const boxingActions = useStore(
        useShallow((state) => ({
            setActivePallet: state.setActivePallet,
            setActiveBox: state.setActiveBox,
            addItem: state.addItem,
            addPallet: state.addPallet,
            addBox: state.addBox,
        }))
    );
    const item = shipment.ExpectedItems.find((item) => item.SellerSKU === sku);
    const pallets = shipment.palletsIds?.map((id) => palletsStore.byId[id]) || [];
    const activePallet = palletsStore.byId[shipment.activePallet || ""] || pallets[0];
    const activeBox = boxesStore.byId[activePallet.activeBox || ""];
    const boxes = useMemo(() => activePallet.boxesIds?.map((id) => boxesStore.byId[id]) || [], [boxesStore, activePallet]);
    const allBoxes = useMemo(
        () => Object.values(boxesStore.byId).filter((box) => box.shipmentId === shipment.ShipmentId),
        [boxesStore, shipment]
    );

    const qtyLeft = useMemo(() => {
        const qtyInBoxes = countItems(allBoxes, sku);
        if (item) {
            return item.QuantityShipped - qtyInBoxes;
        } else {
            return 0;
        }
    }, [allBoxes, sku, item]);

    return (
        <List.Item key={shipment.ShipmentId} style={{backgroundColor: "#121212FF"}}>
            <Row style={{width: "100%"}} justify={"center"} align={"middle"}>
                <Col span={3}>
                    <Typography.Title level={5} style={{margin: 0}}>
                        {shipment.DestinationFulfillmentCenterId}
                    </Typography.Title>
                </Col>
                <Col span={3}>
                    <Typography.Title level={5} style={{margin: 0}}>
                        {countItems([activeBox], sku)} added
                    </Typography.Title>
                </Col>
                <Col span={3}>
                    <Typography.Title level={5} style={{margin: 0}}>
                        {qtyLeft} left
                    </Typography.Title>
                </Col>
                <Col span={5}>
                    <SelectWithButton
                        value={activePallet.id}
                        style={{width: 120}}
                        options={pallets.map((p) => ({label: `Pallet ${p.number}`, value: p.id}))}
                        onChange={(value) => {
                            if (value) {
                                boxingActions.setActivePallet(shipment.ShipmentId, value);
                            }
                        }}
                        buttonTitle="Add pallet"
                        onClick={() => {
                            boxingActions.addPallet(shipment.ShipmentId);
                        }}
                    />
                </Col>
                <Col span={5}>
                    <SelectWithButton
                        value={activePallet.activeBox}
                        style={{width: 120}}
                        options={boxes.map((b) => ({label: `Box ${b.number}`, value: b.id}))}
                        onChange={(value) => {
                            if (value) {
                                boxingActions.setActiveBox(activePallet.id, value);
                            }
                        }}
                        buttonTitle="Add box"
                        onClick={() => {
                            boxingActions.addBox(activePallet.id);
                        }}
                    />
                </Col>
                <Col span={5} style={{padding: 10}}>
                    <QuantityButton
                        title="Add"
                        initialQuantity={qtyLeft || 1}
                        maxQuantity={qtyLeft || 1}
                        onAdd={(quantity) => {
                            if (item) {
                                boxingActions.addItem(item, quantity, activePallet.id);
                            }
                        }}
                        disabled={qtyLeft === 0}
                    />
                </Col>
            </Row>
        </List.Item>
    );
};

const MultipleShipmentsView: React.FC = () => {
    const {shipmentsStore} = useStore((state) => ({
        shipmentsStore: state.shipments,
    }));

    const [state, setState] = useStore((state) => [state.state, state.setState]);

    const item = state.scannedItem;

    if (!item) {
        return (
            <Space>
                <Typography.Title>No item scanned</Typography.Title>
            </Space>
        );
    }

    const shipments = state.currentShipmentIds?.map((id) => shipmentsStore.byId[id]) || [];

    return (
        <Space style={{width: "100%"}} direction="vertical">
            <Divider orientation="left">Item</Divider>
            <Row align="middle" justify="space-between">
                <Col span={4}>{item?.ImgURL ? <Avatar src={item.ImgURL} size={70} shape="square" /> : <Skeleton.Image />}</Col>
                <Col span={20} style={{justifyContent: "center"}}>
                    <Descriptions
                        size="small"
                        column={2}
                        bordered
                        items={[
                            {key: "title", label: "Title", children: item?.Name},
                            {key: "fnsku", label: "FNSKU", children: item?.FulfillmentNetworkSKU},
                            {key: "asin", label: "ASIN", children: item?.ASIN},
                            {
                                key: "upc",
                                label: "UPC",
                                children: item?.UPC,
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Divider />
            <Space style={{width: "100%"}} direction="vertical">
                <List
                    bordered={true}
                    dataSource={shipments}
                    style={{maxHeight: "50vh", overflow: "auto"}}
                    renderItem={(shipment) => <ShipmentRow shipment={shipment} sku={item.SellerSKU} />}
                />
            </Space>
        </Space>
    );
};

export default MultipleShipmentsView;
