import ExcelJS from "exceljs";
import { downloadHelper, excelDownloadHelper } from "./Downloads";

export const processDataNewEraHelper = async (data: ArrayBuffer[]) => {
    console.log('processing');
    const result: any[] = [];
    for (const input of data) {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(input);
    
        const sampleData = {
            'UPC': '',
            'OH': '',
            'Size': '',
            'Desc': '',
            'Material': '',
            'Net': '',
            'MSRP': '',
        };
    
        const SIZE_START_INDEX = 5;
        const DATA_INDEX = 3;
        let currentProducts: any[] = [];
        let sizeRow = false;
        let stockRow = false;
        let dataRow = false;
        let upcRow = false;
        for (const sheet of workbook.worksheets) {
            const lastRowNum = sheet.rowCount;
            for (let i = 1; i < lastRowNum; i++) {
                const rowValues = (sheet.getRow(i).values as any[]);
                if (rowValues !== undefined && rowValues !== null) {
                    if (rowValues.includes('MATERIAL')) {
                        sizeRow = true;
                    } else {
                        if (stockRow) {
                            const stock = rowValues.slice(SIZE_START_INDEX);
                            currentProducts = currentProducts.map((product, idx) => ({ ...product, 'OH': stock[idx].replace('Out of Stock', '0') }));
    
                            stockRow = false;
                            upcRow = true;
                        } else if (upcRow) {
                            const UPCs = rowValues.slice(SIZE_START_INDEX);
                            currentProducts = currentProducts.map((product, idx) => ({ ...product, 'UPC': UPCs[idx] }));
    
                            upcRow = false;
                            dataRow = true;
                        } else if (dataRow) {
                            const productData = rowValues[DATA_INDEX].split('\n');
                            console.log(productData);
                            const desc = productData[0];
                            const msrp = productData.find((row: any) => row.includes('MSRP'))?.split('$')?.[1];
                            const net = productData.find((row: any) => row.includes('Net Price'))?.split('$')?.[1];
    
                            currentProducts = currentProducts.map((product, idx) => ({
                                ...product,
                                'Desc': desc,
                                'Material': rowValues[DATA_INDEX - 1],
                                'Net': net || -1,
                                'MSRP': msrp || net || -1,
                                'OurCost': net ? (parseFloat(net) * 1.1).toFixed(2) : -1
                            }))
                                .filter((product) => parseInt(product.OH) > 0);
    
                            result.push(...currentProducts);
                            dataRow = false;
                        }
                    }
    
                    if (sizeRow) {
                        const sizes = rowValues.slice(SIZE_START_INDEX);
                        currentProducts = sizes.map((size) => ({ ...sampleData, 'Size': size }));
    
                        sizeRow = false;
                        stockRow = true;
                    }
                }
            }
        }
    }
    
    downloadHelper(result, `${new Date().toLocaleDateString().replace(/\//g, "-")}-new_era`);

}

export const processDataNewEraReverseHelper = async (newEraData: ArrayBuffer[], buysheetData: ArrayBuffer[]) => {
    console.log('processing');

    const OFFER_DATA_SHEET = 'Offer Data';
    const upcQuantMap: { [key: string]: number } = {};
    const upcQuantCheckMap: { [key: string]: boolean } = {};

    for (const buysheet of buysheetData) {
        const buysheetWorkboot = new ExcelJS.Workbook();
        await buysheetWorkboot.xlsx.load(buysheet);
        
        console.log(buysheetWorkboot.worksheets);
    
        buysheetWorkboot.worksheets.find(worksheet => worksheet.name === OFFER_DATA_SHEET)!.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            const rowValues = row.values as any[];
            if (rowValues !== undefined && rowValues !== null) {
                console.log(rowValues)
                const UPC_INDEX = 1;
                const QUANT_INDEX = 2;
    
                const [upc, quant] = [rowValues[UPC_INDEX], rowValues[QUANT_INDEX]];
    
                if (upc && quant && upc !== 'UPC') {
                    upcQuantMap[upc] = quant + (upcQuantMap[upc] ?? 0);
                    upcQuantCheckMap[upc] = false;
                }
            }
        });
    }

    console.log(upcQuantMap);
    for (const outputData of newEraData) {
        const outputWorkbook = new ExcelJS.Workbook();
        await outputWorkbook.xlsx.load(outputData);
    
        const resultWorkbook = new ExcelJS.Workbook();
    
        // const NEW_ERA_SHEET = 'Order Sheet'
        // const outputSheet = outputWorkbook.worksheets.find(worksheet => worksheet.name === NEW_ERA_SHEET)

        for (const outputSheet of outputWorkbook.worksheets) {
            const resultSheet = resultWorkbook.addWorksheet(outputSheet.name);
            let grandTotal = 0;
            outputSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                const rowValues = row.values as any[];
                console.log(rowValues);
                const UPC_INDEX = rowValues.findIndex((val) => val === 'UPC')
                const GRAND_TOTAL_INDEX = rowValues.findIndex((val) => val === 'Grand Total')
                console.log(UPC_INDEX, GRAND_TOTAL_INDEX);
                if (UPC_INDEX !== -1) {
                    const nextRow = outputSheet.getRow(rowNumber + 1);
                    const totalQtyCell = nextRow.getCell(UPC_INDEX + 1);
                    let totalQty = 0;
                    rowValues.forEach((val, idx) => {
                        if (val.length === 12) { // UPC VAL
                            const prodQty = (upcQuantMap[val] ?? 0);
                            upcQuantCheckMap[val] = true;
                            totalQty += prodQty;
                            grandTotal += prodQty;
                            nextRow.getCell(idx).value = prodQty;

                            if (upcQuantMap[val]) {
                                upcQuantMap[val] -= prodQty;
                            }
                        }
                    })
                    totalQtyCell.value = totalQty;
                } else if (GRAND_TOTAL_INDEX !== -1) {
                    outputSheet.getRow(rowNumber).getCell(GRAND_TOTAL_INDEX + 1).value = grandTotal;
                    rowValues[GRAND_TOTAL_INDEX + 1] = grandTotal;
                }
    
                resultSheet.addRow(rowValues);
            });
        }
    
        console.log(Object.entries(upcQuantCheckMap).filter((entry) => entry[1] === false));
    
        excelDownloadHelper(resultWorkbook);
    }
    
}