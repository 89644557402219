import React from "react";
import { Tabs, TabsProps } from "antd";
import HandleReturns from "./HandleReturns";
import ViewReturns from "./ViewReturns";

const items: TabsProps['items'] = [
  {
    key: "handle",
    label: "Handle returns",
    children: <HandleReturns />,
  },
  {
    key: "view",
    label: "View returns",
    children: <ViewReturns />,
  },
];

const ReturnsManagement: React.FC = () => {
  return <Tabs defaultActiveKey="handle" items={items} />;
};

export default ReturnsManagement;