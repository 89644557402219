import { Card, Divider, Space, Statistic, Typography, Select } from "antd";
import React, { useEffect, useState } from "react"
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';

const { Option } = Select;

const ReplenSalesPopover: React.FC<{ record: WholesaleItem, handleSave: any }> = React.memo(({ record, handleSave }) => {
    const [bbMultiplier, setBbMultiplier] = useState("")
    const [anticipatedVelocity, setAnticipatedVelocity] = useState(1)

    useEffect(() => {
        setBbMultiplier((record.replenBBMultiplier).toString())
        setAnticipatedVelocity(record.anticipatedVelocity)
    }, [record])

    const handleBBSave = (val: string) => {
        if (Number.isNaN(parseFloat(val))) {
            return
        }

        setBbMultiplier(parseFloat(val).toString())
        handleSave({ ...record, replenBBMultiplier: parseFloat(val) });
    }

    const handleAntVelSave = (val?: string) => {
        if (val !== undefined) {
            if (Number.isNaN(parseInt(val))) {
                return
            }

            setAnticipatedVelocity((parseInt(val) / 100))
            handleSave({ ...record, anticipatedVelocity: parseInt(val) / 100 });
        }
    }

    return (
        <Space data-asin={record.ASIN} direction="vertical">
            <Card size="small">
                <Space align="center" direction="horizontal" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Statistic title="BB Multiplier ×:" value={bbMultiplier} formatter={(val) =>
                        <Select size={"small"} bordered={false} style={{ width: '65px' }} value={`${(parseFloat(bbMultiplier) * 100).toFixed(0)}%`} onChange={handleBBSave}>
                            <Option key={1} value={0.05}>5%</Option>
                            <Option key={2} value={0.10}>10%</Option>
                            <Option key={3} value={0.15}>15%</Option>
                            <Option key={4} value={0.20}>20%</Option>
                            <Option key={5} value={0.25}>25%</Option>
                            <Option key={6} value={0.30}>30%</Option>
                            <Option key={7} value={0.30}>35%</Option>
                            <Option key={8} value={0.40}>40%</Option>
                            <Option key={9} value={0.45}>45%</Option>
                            <Option key={10} value={0.50}>50%</Option>
                            <Option key={11} value={0.55}>55%</Option>
                            <Option key={12} value={0.60}>60%</Option>
                            <Option key={13} value={0.65}>65%</Option>
                            <Option key={14} value={0.70}>70%</Option>
                            <Option key={15} value={0.75}>75%</Option>
                            <Option key={16} value={0.80}>80%</Option>
                            <Option key={17} value={0.85}>85%</Option>
                            <Option key={18} value={0.90}>90%</Option>
                            <Option key={19} value={0.95}>95%</Option>
                            <Option key={20} value={1}>100%</Option>
                        </Select>
                    } />
                    <Divider type="vertical"></Divider>
                    <Statistic title="Velocity:" value={record.Velocity} precision={2} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="Ant. Velocity:" value={anticipatedVelocity * 100} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                        icon: false,
                        triggerType: ['text'],
                        onChange: handleAntVelSave,
                        onEnd: handleAntVelSave,
                        onCancel: handleAntVelSave,
                    }}>{val}%</Typography.Text>} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="All Sales (30D):" value={record.replenAllSales} precision={2} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="Stock:" value={record.InvAvailableQuantity + record.replenIncoming + (+record.replenShipLaterInclude * record.ShipLater)} />
                </Space>
            </Card>
            <Card size="small">
                <Space align="center" direction="horizontal" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Statistic title="Target DIS:" value={record.replenTargetDTB} precision={0} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="TTS (Inv+SL):" value={record.replenTTS} precision={0} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="Prep Delay:" value={record.prepDelay} precision={0} />
                    <Divider type="vertical"></Divider>
                    <Statistic title="DTB:" value={Math.max(record.replenDaysToBuy, 0)} precision={0} />
                    <Divider></Divider>
                    <Typography> * </Typography>
                    <Divider></Divider>
                    <Statistic title="EstSales (30D):" value={record.replenEstSales} precision={2} />
                    <Divider></Divider>
                    <Typography> - </Typography>
                    <Divider></Divider>
                    <Statistic title="Incoming (60D):" value={record.replenIncoming} precision={2} />
                    <Typography> = </Typography>
                    <Divider></Divider>
                    <Statistic title="To Buy:" value={record.replenToBuy} precision={0} />
                </Space>
            </Card>
        </Space>
    )
})

export default ReplenSalesPopover;