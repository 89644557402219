import { Button, DatePicker, Form, Input, message, Modal } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { SingularItem } from "../../types/OmegaTypes";
import { SlackApiToken } from "../../services/constants";
dayjs.extend(duration)
dayjs.extend(relativeTime)

const ScheduleSlackMessage = (asin: string, sku: string, createdAt: string, email: string, text: string, postAt: number, channel = 'C045F1A2Q31') => {
    const details: { [key: string]: any } = {
        'text':
            `*Omega Reminder* for ASIN <https://www.amazon.com/dp/${asin}?th=1&psc=1|${asin}>\n`
            + `Created by: ${email} at ${createdAt}\n`
            + `Message: ${text}\n`
            + `<https://sourcing.projectsuite.io/omega?sku=${sku}|Omega Link>`,
        'post_at': postAt,
        'channel': channel,
        'token': SlackApiToken
    };

    const formBody = [];
    for (const property of Object.keys(details)) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    const body = formBody.join("&")

    return fetch('https://slack.com/api/chat.scheduleMessage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body
    })
}

const ScheduleButton = ({ productData }: { productData: SingularItem }) => {
    const [modal, contextHolder] = Modal.useModal();
    const { currentUser } = useAuth();
    const [inputForm] = Form.useForm();

    const openModal = () => {
        modal.confirm({
            title: `You're about to schedule a Slack Message for ASIN: ${productData.ASIN}`,
            width: '500px',
            content:
                <Form
                    form={inputForm}
                    name="bulkFormCustom"
                    layout="vertical"
                    initialValues={{ scheduledDate: dayjs() }}
                    onFinish={() => null}
                >
                    <Form.Item
                        name="msg"
                        label="What do you want to be reminded about?"
                        rules={[{ required: true, message: 'Message cannot be blank!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="scheduledDate"
                        label="When do you want the message to send?"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            () => ({
                                validator(_, value) {
                                    if (value > dayjs()) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The date must be in the future!'));
                                },
                            }),
                        ]}
                    >
                        <DatePicker
                            autoFocus
                            showTime
                            allowClear={false}
                        />
                    </Form.Item>
                </Form>,
            onOk: (close: any) => {
                inputForm.validateFields().then((values) => {
                    console.log('bulk form values', values, values['scheduledDate'].unix(), values['msg'])
                    ScheduleSlackMessage(
                        productData.ASIN,
                        productData.SKU,
                        dayjs().tz('America/Chicago').format("MM-DD-YYYY HH:mm CST"),
                        currentUser!.email as string,
                        values['msg'],
                        values['scheduledDate'].unix(),
                    )
                        .then((res) => res.json())
                        .then((res) => {
                            if (res.ok === true) {
                                message.success('Successfully scheduled the message!')
                            } else {
                                message.error(`Slack Schedule error: ${res.error}`)
                            }
                            close();
                        })
                        .catch((ex) => {
                            message.error(`Slack Schedule error: ${ex.message}`)
                            console.log(ex);
                            close();
                        })
                    inputForm.resetFields();
                }).catch((ex) => {
                    console.log(ex);
                    message.error(`Form error: ${ex.errorFields.map((error: any) => error.errors).join(' ')}`)
                });
            },
            onCancel() {
                console.log('Cancelled Bulk');
                inputForm.resetFields();
            },
        })
    }

    return (
        <>
            {contextHolder}
            <Button style={{ width: '80%' }} type="primary" onClick={() => openModal()}>Schedule</Button>
        </>

    );
};

export default ScheduleButton;
