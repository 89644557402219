import React, {useEffect, useState} from "react";
import {Col, Row, Tabs, TabsProps} from "antd";
import POBrowser from "../components/omega/oms/POBrowser";
import {Stage1Browser} from "../components/omega/oms/Stage1Browser";
import {Stage2Browser} from "../components/omega/oms/Stage2Browser";
import {Stage3Browser} from "../components/omega/oms/Stage3Browser";
import {OMSImport} from "../components/omega/oms/OMSImport";
import {SupplierNamesBrowser} from "../components/omega/oms/SupplierNamesBrowser";
import {useSuppliers} from "../components/omega/oms/dataHandlers";
import {useAuth} from "../contexts/AuthContext";
import {InvoiceBrowser} from "../components/omega/oms/InvoiceBrowser";
import {LedgerBrowser} from "../components/omega/oms/LedgerBrowser";
import {SellerSnapExportBrowser} from "../components/omega/oms/SellerSnapExportBrowser";
import {POTracker} from "../components/omega/oms/POTracker";
import {APInvoiceTracker} from "../components/omega/oms/APInvoiceTracker";
import {useNavigate, useLocation} from "react-router-dom";
import qs from "qs";
import {FreightLogBrowser} from "../components/omega/oms/FreightLogBrowser";
import {POTrackerArchive} from "../components/omega/oms/POTrackerArchive";
import {FreightLogItemizedEntryPage} from "../components/omega/oms/FreightLogItemizedEntry";
import {FreightLogItemizedBrowser} from "../components/omega/oms/FreightLogItemizedBrowser";
import {CurrentShipmentLog} from "../components/omega/oms/CurrentShipmentLog";
import {Inquiries} from "../components/omega/oms/Inquiries";

const TABS_STRUCTURE: {[key: string]: string[]} = {
    importing: ["stage1", "po_edit", "stage2", "stage3", "freight_log_itemized_entry"],
    management: [
        "po_browser",
        "po_tracker",
        "po_tracker_archive",
        "ap_tracker",
        "ss_export",
        "ledger",
        "freight_log",
        "freight_log_itemized",
        "invoices",
        "current_shipment_log",
        "inquiries",
    ],
    settings: ["supplier_names"],
};

// Creates a maping innerTab => topTab based on the tabs structure
const TABS_MAPPING = Object.fromEntries(
    Object.entries(TABS_STRUCTURE)
        .map(([topTab, innerTabs]) => innerTabs.map((innerTab) => [innerTab, topTab]))
        .flat()
);

/**
 * Hook for syncing the URL query with tabs.
 * @param availableKeys
 * @param defaultKey
 * @returns
 */
const useTabLocation = (availableKeys: string[], defaultKey: string): [string, (newKey: string) => void] => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("stage1");

    useEffect(() => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const currentTab = existingQueries.tab || defaultKey;
        if (typeof currentTab === "string") {
            setActiveKey(availableKeys.includes(currentTab) ? currentTab : defaultKey);
        }
    }, [location, availableKeys, defaultKey]);

    const onChange = (newKey: string) => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const queryString = qs.stringify({...existingQueries, tab: newKey}, {skipNulls: true});

        navigate(`${location.pathname}?${queryString}`);
        setActiveKey(newKey);
    };

    return [activeKey, onChange];
};

const ImportTabs: React.FC = () => {
    const items: TabsProps["items"] = [
        {
            key: "po_edit",
            label: "PO Edit",
            children: <OMSImport />,
        },
        {
            key: "stage1",
            label: "Stage 1",
            children: <Stage1Browser />,
        },
        {
            key: "stage2",
            label: "Stage 2",
            children: <Stage2Browser />,
        },
        {
            key: "stage3",
            label: "Stage 3",
            children: <Stage3Browser />,
        },
        {
            key: "freight_log_itemized_entry",
            label: "Freight Log Itemized Entry",
            children: <FreightLogItemizedEntryPage />,
        },
    ];
    const [activeKey, onChange] = useTabLocation(
        items.map((item) => item.key),
        "stage1"
    );

    return (
        <Row>
            <Col span={24}>
                <Tabs activeKey={activeKey} items={items} onChange={onChange} />
            </Col>
        </Row>
    );
};

const ManagementTabs: React.FC = () => {
    const items: TabsProps["items"] = [
        {
            key: "po_browser",
            label: "PO Browser",
            children: <POBrowser />,
        },
        {
            key: "po_tracker",
            label: "PO Tracker",
            children: <POTracker />,
        },
        {
            key: "po_tracker_archive",
            label: "PO Tracker Archive",
            children: <POTrackerArchive />,
        },
        {
            key: "ap_tracker",
            label: "AP Tracker",
            children: <APInvoiceTracker />,
        },
        {
            key: "ss_export",
            label: "SS Export",
            children: <SellerSnapExportBrowser />,
        },
        {
            key: "ledger",
            label: "Ledger",
            children: <LedgerBrowser />,
        },
        {
            key: "freight_log",
            label: "Freight Log",
            children: <FreightLogBrowser />,
        },
        {
            key: "freight_log_itemized",
            label: "Freight Log Itemized",
            children: <FreightLogItemizedBrowser />,
        },
        {
            key: "invoices",
            label: "Invoices",
            children: <InvoiceBrowser />,
        },
        {
            key: "current_shipment_log",
            label: "Current Shipment Log",
            children: <CurrentShipmentLog />,
        },
        {
            key: "inquiries",
            label: "Inquiries",
            children: <Inquiries />,
        },
    ];
    const [activeKey, onChange] = useTabLocation(
        items.map((item) => item.key),
        "po_browser"
    );

    return (
        <Row>
            <Col span={24}>
                <Tabs activeKey={activeKey} items={items} onChange={onChange} />
            </Col>
        </Row>
    );
};

const SettingsTabs: React.FC = () => {
    const {userData} = useAuth();

    const items: TabsProps["items"] = [];
    if (userData?.role.includes("SUPPLIER_EDIT") || userData?.role === "ADMIN") {
        items.push({
            key: "supplier_names",
            label: "Supplier Names",
            children: <SupplierNamesBrowser />,
        });
    }
    const [activeKey, onChange] = useTabLocation(
        items.map((item) => item.key),
        "supplier_names"
    );

    return (
        <Row>
            <Col span={24}>
                <Tabs activeKey={activeKey} items={items} onChange={onChange} />
            </Col>
        </Row>
    );
};

/**
 * Top level OMS tabs.
 * @returns
 */
const OMSInner: React.FC = () => {
    useSuppliers();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("management");

    useEffect(() => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const currentTab = existingQueries.tab;
        if (typeof currentTab === "string") {
            // The tab query param holds only inner tab keys
            // We have to find the top tab based on this key
            setActiveKey(TABS_MAPPING[currentTab] || "management");
        }
    }, [location]);

    const onChange = (newKey: string) => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const queryString = qs.stringify(
            {
                ...existingQueries,
                // Set the default inner tab every the top level tab is changed
                tab: TABS_STRUCTURE[newKey][0],
            },
            {skipNulls: true}
        );

        navigate(`${location.pathname}?${queryString}`);
        setActiveKey(newKey);
    };

    const items: TabsProps["items"] = [
        {
            key: "importing",
            label: "Importing",
            children: <ImportTabs />,
        },
        {
            key: "management",
            label: "Management",
            children: <ManagementTabs />,
        },
        {
            key: "settings",
            label: "Settings",
            children: <SettingsTabs />,
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <Tabs activeKey={activeKey} items={items} onChange={onChange} />
            </Col>
        </Row>
    );
};

const OMS: React.FC = () => {
    return <OMSInner />;
};

export default OMS;
