import {Button, Divider, InputRef, notification, Row, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {LedgerData, LedgerDataRenderType} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeLedgerColumns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as _ from "lodash";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {deleteLedgerData, generateTrackerData, getLedgerData, updateLedgerData} from "../../../services/WholesaleService";
import {DeleteFilled} from "@ant-design/icons";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {UploadHandler} from "../../utilities/UploadHandler";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {CSVDownload} from "../../utilities/CSVDownload";
import {OMSDateStatsSpace} from "../../utilities/OMSDateStatsSpace";
import {validateLedger} from "../../utilities/TableDataValidation";
import {copyToClipboard} from "../ItemBrowser";
import {useSuppliers} from "./dataHandlers";

const defaultItem: LedgerData = {
    _id: undefined,
    Supplier_Name: "",
    Supplier_PO: undefined,
    Supplier_SO: undefined,
    InvoiceId: undefined,
    DistributorInvoiceId: undefined,
    Notes: undefined,
    IsCommission: undefined,
    PaidAmount: 0,
    PaidDate: new Date(),
    PaymentMethod: "",
    Timestamp: new Date(),
};

const requiredColumns = [
    "_id",
    "Supplier_Name",
    "Supplier_PO",
    "Supplier_SO",
    "InvoiceId",
    "DistributorInvoiceId",
    "Notes",
    "IsCommission",
    "PaidAmount",
    "PaidDate",
    "PaymentMethod",
];

export const LedgerBrowser: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<LedgerDataRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const queryClient = useQueryClient();
    const tableRef = useRef<EditableTableHandle<LedgerDataRenderType>>(null);
    const [showUnsynced, setShowUnsynced] = useState(false);
    const {data: suppliers} = useSuppliers();

    const filteredTableData = useMemo(() => {
        if (showUnsynced) {
            return tableData.filter((item) => !item.IsProcessed);
        } else {
            return tableData;
        }
    }, [tableData, showUnsynced]);

    const {
        data: ledgerData,
        isLoading: ledgerDataLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ["ledger"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const data = (await getLedgerData(token)) as LedgerData[];
            return data;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const syncTableData = useCallback(() => {
        if (ledgerData) {
            setTableData(
                ledgerData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [ledgerData, fetchTimestamp]);

    const omsMutation = useMutation({
        mutationFn: async (ledgerItems: LedgerData[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            if (!ledgerData) {
                throw new Error("Ledger data was not fetched correctly");
            }

            const originalIds = ledgerData.map((item) => item._id);
            const localIds = ledgerItems.map((item) => item._id);
            const idsToDelete = _.difference(originalIds, localIds);
            const returnedLedgerData = await updateLedgerData(token, ledgerItems);
            await deleteLedgerData(token, idsToDelete.filter((id) => id !== undefined) as string[]);

            queryClient.invalidateQueries({queryKey: ["ap_tracker"]});
            generateTrackerData(token, false);

            return returnedLedgerData;
        },
        onSuccess: (ledger) => {
            queryClient.setQueryData(["ledger"], ledger);
            const tableData = ledger.map((item, idx) => ({
                ...item,
                key: idx,
            }));
            setTableData(tableData);
            setFetchTimestamp(new Date());
            notification.success({
                message: "Push successful!",
            });

            // Refetch data to sync with master
            refetch({throwOnError: true});
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: LedgerDataRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: LedgerDataRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [ledgerData, syncTableData]);

    const columns = useMemo<EditableColumnType<LedgerDataRenderType>[]>(() => {
        const ledgerColumns = makeLedgerColumns(searchInputRef);
        if (editable) {
            ledgerColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Button
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                            setLastUpdateTimestamp(new Date());
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    />
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(ledgerColumns, handleSave);
        } else {
            return ledgerColumns;
        }
    }, [handleSave, editable, tableData]);

    const addNewRow = () => {
        const newItem: LedgerDataRenderType = {
            ...defaultItem,
            key: 0,
            Timestamp: new Date(),
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({...item, key: idx})));
    };

    const onDiscardChanges = () => {
        if (ledgerData) {
            setTableData(
                ledgerData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        const errors = validateLedger(
            tableData,
            suppliers?.map((sup) => sup.name)
        );
        if (errors.length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const processData = (data: string) => {
        let dataFrame: dataForge.IDataFrame<number, any> = dataForge.fromCSV(data);

        const columnsDiff = _.difference(requiredColumns, dataFrame.getColumnNames());
        if (columnsDiff.length > 0) {
            notification.error({
                message: "Wrong columns",
                description: (
                    <>
                        Some columns are missing:
                        {columnsDiff.map((col) => (
                            <p style={{margin: 0}}>{col}</p>
                        ))}
                    </>
                ),
            });
            return;
        }
        dataFrame = dataFrame.subset(requiredColumns);
        dataFrame = dataFrame.parseDates(["PaidDate"]);
        dataFrame = dataFrame.transformSeries({
            PaidAmount: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
            OrderTotal: (val) => (val ? parseFloat(val.replace(/\$/g, "").replace(/,/g, "")) : undefined),
        });
        const newData = [...tableData];
        const dataMap = Object.fromEntries(newData.map((item) => [item._id || "", item]));
        dataFrame.reverse().forEach((row) => {
            row.IsCommission = row.IsCommission === "true";
            if (row._id && dataMap[row._id]) {
                dataMap[row._id] = {...dataMap[row._id], ...row};
            } else {
                newData.unshift({...defaultItem, ...row});
            }
        });
        setTableData(
            newData.map((item, idx) => {
                if (item._id && dataMap[item._id]) {
                    return {...dataMap[item._id], key: idx};
                } else {
                    return {...item, key: idx};
                }
            })
        );
        setLastUpdateTimestamp(new Date());
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "space-between"}} split={<Divider type="vertical" />}>
            <OMSDateStatsSpace<LedgerDataRenderType>
                data={tableData}
                dateColumn="PaidDate"
                amountColumn="PaidAmount"
                inFuture={false}
                descriptionRender={(timeFrame) => `Amount paid in last ${timeFrame} days`}
                titleRender={(timeFrame) => `${timeFrame} days`}
            />
            <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
                <UnsavedChangesHandler
                    isSaved={lastUpdateTimestamp <= fetchTimestamp}
                    disableWhenSaved
                    isLoading={omsMutation.isPending || isRefetching}
                    onDiscardChanges={onDiscardChanges}
                    onSaveChanges={onSaveChanges}
                />
                <CSVDownload
                    collection={`Ledger_Export_${dayjs().format("MM-DD-YY")}`}
                    data={tableRef.current?.currentData || []}
                    isLoading={!tableRef.current}
                    dateColumns={["PaidDate"]}
                    parse={(data) => {
                        const df = new dataForge.DataFrame(data.map((item) => ({...defaultItem, ...item})));
                        return df.subset(requiredColumns).toArray();
                    }}
                />
                <Space>
                    Show unsynced: <Switch checked={showUnsynced} onChange={setShowUnsynced} />
                </Space>
                <Space>
                    Editable: <Switch checked={editable} onChange={setEditable} />
                </Space>
                <Button
                    disabled={tableData === undefined}
                    onClick={() => {
                        if (!ledgerData) return;

                        const ledgerMap: {
                            [key: string]: LedgerDataRenderType[];
                        } = {};

                        for (const ledgerEntry of tableData) {
                            const indexType = `${ledgerEntry.Supplier_PO}-${ledgerEntry.InvoiceId}-${ledgerEntry.PaidAmount}`;
                            if (ledgerMap[indexType]) {
                                ledgerMap[indexType] = [...ledgerMap[indexType], ledgerEntry];
                            } else {
                                ledgerMap[indexType] = [ledgerEntry];
                            }
                        }

                        const duplicates = Object.values(ledgerMap).filter((entries) => entries.length > 1);

                        if (duplicates.length > 0) {
                            notification.info({
                                message: "Duplicates found",
                                description: (
                                    <Space direction="vertical">
                                        <Space
                                            direction="vertical"
                                            style={{
                                                maxHeight: "400px",
                                                overflow: "auto",
                                            }}
                                        >
                                            {duplicates.map((entries, idx) => (
                                                <span>
                                                    Found {entries.length} duplicates for <br />
                                                    {entries[0].Supplier_PO} - {entries[0].InvoiceId} - {entries[0].PaidAmount}
                                                </span>
                                            ))}
                                        </Space>
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                copyToClipboard(
                                                    duplicates
                                                        .map((entries) => entries.map((entry) => entry.key))
                                                        .flat()
                                                        .join("\n"),
                                                    "Rows indexes copied!"
                                                )
                                            }
                                        >
                                            Copy for Filter
                                        </Button>
                                    </Space>
                                ),
                            });
                        } else {
                            notification.info({
                                message: "No duplicates found",
                            });
                        }
                    }}
                >
                    Find duplicates
                </Button>
                <Button
                    onClick={() => {
                        addNewRow();
                    }}
                    disabled={!editable}
                >
                    Add row
                </Button>
            </Space>
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <UploadHandler onComplete={processData} template={requiredColumns} />
            </Row>
            <EditableTable<LedgerDataRenderType>
                ref={tableRef}
                title={tableTitle}
                tableData={filteredTableData}
                columns={columns}
                loading={ledgerDataLoading}
                onRow={(row) => {
                    let style = {};
                    if (!row.IsProcessed) {
                        style = {
                            backgroundColor: "#518A3D55",
                        };
                    }
                    return {
                        style,
                    };
                }}
            />
        </Space>
    );
};
