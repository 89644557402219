import {Select, SelectProps} from "antd";
import {DefaultOptionType} from "antd/es/select";
import React from "react";

export default function AddSelect({
    save,
    selectableValues,
    onChange,
    value,
    ...props
}: SelectProps & {
    save?: () => void;
    selectableValues?: DefaultOptionType[];
}) {
    return (
        <Select<string[]>
            {...props}
            value={value || undefined}
            mode="tags"
            allowClear
            autoFocus
            onBlur={save}
            defaultOpen
            onChange={(value, option) => {
                onChange?.(value.length ? value[value.length - 1] : undefined, option);
            }}
            options={selectableValues}
        />
    );
}
