import { Card, Divider, Space, Statistic, Typography } from "antd";
import React, { useState } from "react"
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';
import { WHOLESALE_CELL_COLORS } from "./WholesaleColumns";

const ReplenProfitPopover: React.FC<{record: WholesaleItem, handleSave: any}> = React.memo(({record, handleSave}) => {
    const [shippingCost, setShippingCost] = useState(record.shippingCost.toFixed(2))
    const profitStyle = {border: '4px solid', borderRadius: '10px', padding: '3px', borderColor: 'white'};
    const roiStyle = {border: '4px solid', borderRadius: '10px', padding: '3px', borderColor: 'white'};

    if (record.ROI2 < 15) {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.RED
    } else if (record.ROI2 < 25) {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.YELLOW
    } else {
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.GREEN
    }

    if ((record.productGroup === 'Shoes' && record.Profit > 5) || (record.productGroup !== 'Shoes' && record.Profit > 2.5) ) {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.GREEN
    } else {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.RED
    }

    if (record.currentBuyBoxPrice <= 0) {
        profitStyle.borderColor = WHOLESALE_CELL_COLORS.BLUE
        roiStyle.borderColor = WHOLESALE_CELL_COLORS.BLUE
    }

    const handleFeeEdit = (val?: string) => {
        if (val) {
            if (Number.isNaN(parseFloat(val))) {
                return
            }            

            handleSave({...record, editedShippingCost: parseFloat(val), editSiblings: false});
            setShippingCost(parseFloat(val).toString())
        }
    }

    return (
        <Card size="small">
            <Space align="center" direction="horizontal" style={{display: 'flex', justifyContent: 'space-around'}}>
                <Statistic title="Avg Sell:" value={record.AvgSellPrice}  precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Referral Fee:" value={record.replenRefFee} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="FBA Fee:" value={record.replenPrepFee}  precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Ship Fee:" value={shippingCost} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                    icon: false,
                    tooltip: `Set the shipping fee`,
                    onChange: (val) => handleFeeEdit(val),
                    onEnd: () => handleFeeEdit(""),
                    onCancel: () => handleFeeEdit(""),
                }}>${val}</Typography.Text>}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Returns:" value={record.totalReturnCost} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Storage:" value={record.storageFee} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Cost:" value={record.AskPrice} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> = </Typography>
                <Divider></Divider>
                <Statistic style={profitStyle} title="Profit:" value={record.replenProfit} precision={2} prefix={'$'} />
                <Divider></Divider>
                <Statistic style={roiStyle} title="ROI:" value={record.replenROI} precision={0} suffix={'%'}/>
            </Space>
        </Card>
    )
})

export default ReplenProfitPopover;