import { Button, Card, Col, Divider, Row, Space, Statistic, Typography, Select, Popover, message } from "antd";
import React, { useEffect, useState } from "react"
import { getReviewData, updateProduct } from "../../../services/WholesaleService";
import { WholesaleItem } from "../../../types/WholesaleItem";
import { GetCategoryEstSalesData } from "../../brand/SalesRankConstants";
import './Popover.scss';
import StockPopover from "./StockPopover";
import { useAuth } from "../../../contexts/AuthContext";

const { Option } = Select;

const EstSalesPopover: React.FC<{ record: WholesaleItem, handleSave: any }> = React.memo(({ record, handleSave }) => {
    const { currentUser } = useAuth()
    const [bbMultiplier, setBbMultiplier] = useState("")
    const [reviewMultiplier, setReviewMultiplier] = useState("")
    const [calcRank, setCalcRank] = useState("")
    const [category, setCategory] = useState("")

    useEffect(() => {
        setBbMultiplier(record.bbMultiplier.toString());
        setReviewMultiplier(record.reviewMultiplier.toFixed(3));
        setCalcRank(record.calcRank.toFixed(0));
        setCategory(record.salesRankReference ?? record.rootCategory);
        console.log(record);
    }, [record])

    const handleBBSave = (val: string) => {
        if (Number.isNaN(parseFloat(val))) {
            return
        }

        setBbMultiplier(parseFloat(val).toString())
        handleSave({ ...record, bbMultiplier: parseFloat(val) });
    }

    const handleReviewSave = (val?: string) => {
        if (val !== undefined) {
            if (Number.isNaN(parseFloat(val))) {
                return
            }

            setReviewMultiplier(parseFloat(val).toString())
            handleSave({ ...record, reviewMultiplier: parseFloat(val) });
        }
    }

    const handleCalcRankSave = (val?: string) => {
        if (val) {
            if (Number.isNaN(parseFloat(val))) {
                return
            }

            setCalcRank(parseInt(val).toString())
            handleSave({ ...record, calcRank: parseInt(val), editSiblings: true });
        }
    }

    const handleCategorySave = (val?: string) => {
        if (val) {
            setCategory(val)
            handleSave({ ...record, salesRankReference: val });
        }
    }

    const GrabSingularReviewData = (asin: string) => {
        message.info('Starting singular review grab!');
        currentUser?.getIdToken()
            .then((token) => getReviewData(asin, token))
            .then((res) => {
                console.log('Singular Rev Data grab lmao', res, res.parentCount, res.childCount, res.parentCount >= 0 && res.childCount >= 0);

                if (res.parentCount >= 0 && res.childCount >= 0) {
                    const newItem = { ...record };
                    if (res.parentCount > 0) {
                        newItem.reviewMultiplier = res.childCount / res.parentCount;
                    } else if (res.parentCount === 0 && res.childCount === 0) {
                        newItem.reviewMultiplier = 1;
                    }

                    newItem.variationReviewsTotal = res.parentCount;
                    newItem.variationReviews = res.childCount;
                    newItem.variationChecked = true;

                    console.log('newi tem during process', newItem)

                    handleSave(newItem);
                    updateProduct([newItem]);
                    message.success('Grab successful!');
                } else {
                    message.error('Failed to grab - please re-try, but not too much!')
                }
            }).catch((ex) => {
                message.error('Failed to grab - please re-try, but not too much!')
            })
    };

    return (
        <Space data-asin={record.ASIN} direction="horizontal">
            <Card size="small">
                <Row gutter={8}>
                    <Col span={12}>
                        <Statistic title="Calculated Rank:" value={calcRank} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                            icon: false,
                            tooltip: `Set the item's calcRank`,
                            onChange: handleCalcRankSave,
                            onEnd: handleCalcRankSave,
                            onCancel: handleCalcRankSave,
                        }}>{val}</Typography.Text>} />
                    </Col>
                    <Col span={12}>
                        <Statistic title="Current Rank:" value={record.currentSalesRank} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={8}>
                    <Col span={12}>
                        <Statistic title="SalesRank (30 days):" value={record.salesRank30Avg} />
                    </Col>
                    <Col span={12}>
                        <Statistic title="SalesRank (90 days):" value={record.salesRank90Avg} />
                    </Col>
                </Row>
            </Card>
            <Card size="small" style={{ minWidth: '275px' }}>
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="Category:" value={category} formatter={(val) =>
                            <Select size={"small"} bordered={false} style={{ width: '150px' }} value={category} onChange={handleCategorySave}>
                                {Object.keys(GetCategoryEstSalesData().mainSalesRankCorrelation).map((possibleCategory, idx) => (<Option value={possibleCategory} key={idx}>{possibleCategory}</Option>))}
                            </Select>}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Variations:" value={record.VariationCount} precision={0} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="BB ×:" value={bbMultiplier} formatter={(val) =>
                            <Select size={"small"} bordered={false} style={{ width: '65px' }} value={`${(parseFloat(bbMultiplier) * 100).toFixed(0)}%`} onChange={handleBBSave}>
                                <Option key={1} value={0.05}>5%</Option>
                                <Option key={2} value={0.10}>10%</Option>
                                <Option key={3} value={0.15}>15%</Option>
                                <Option key={4} value={0.20}>20%</Option>
                                <Option key={5} value={0.25}>25%</Option>
                                <Option key={6} value={0.30}>30%</Option>
                                <Option key={7} value={0.30}>35%</Option>
                                <Option key={8} value={0.40}>40%</Option>
                                <Option key={9} value={0.45}>45%</Option>
                                <Option key={10} value={0.50}>50%</Option>
                                <Option key={11} value={0.55}>55%</Option>
                                <Option key={12} value={0.60}>60%</Option>
                                <Option key={13} value={0.65}>65%</Option>
                                <Option key={14} value={0.70}>70%</Option>
                                <Option key={15} value={0.75}>75%</Option>
                                <Option key={16} value={0.80}>80%</Option>
                                <Option key={17} value={0.85}>85%</Option>
                                <Option key={18} value={0.90}>90%</Option>
                                <Option key={19} value={0.95}>95%</Option>
                                <Option key={20} value={1}>100%</Option>
                            </Select>
                        } />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Review ×:" value={reviewMultiplier} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                            icon: false,
                            tooltip: 'Set the variation\'s review multiplier',
                            onChange: handleReviewSave,
                            onEnd: handleReviewSave,
                            onCancel: handleReviewSave,
                        }}>{val}</Typography.Text>} />
                    </Col>
                </Row>
            </Card>
            <Card size="small">
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="Total Reviews:" value={record.variationReviewsTotal} precision={0} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Active:" value={record.variationActive} precision={0} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="Reviews:" value={record.variationReviews} precision={0} />
                    </Col>
                    <Col span={16}>
                        <Space direction="vertical" align="end" style={{ width: '100%', height: '100%', justifyContent: 'center', rowGap: '3px', columnGap: '3px' }}>
                            <Button size="small" id="wholesale-popover-button" data-asin={record.ASIN}>Grab Vars</Button>
                            <Button size="small" onClick={() => GrabSingularReviewData(record.ASIN)}>Grab Single</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
            <Card size="small">

                <Statistic title="Total Est. Sales:" value={`${record.EstSalesTotal! >= 0 ? record.EstSalesTotal?.toFixed(2) : record.EstSalesTotal} (${record.salesRankDropsUsed ? 'Drops' : 'Rank'})`} precision={2} />
                <Divider></Divider>
                <Statistic title="Variation's Est. Sales:" value={record.EstSales} precision={2} />
            </Card>
            <Card size="small" style={{ minWidth: '100px' }}>
                <Popover overlay="bot" autoAdjustOverflow={true} content={<StockPopover {...record}></StockPopover>} trigger="hover">
                    <Statistic title="Stock:" value={record.stockBelow > 999 ? '999+' : record.stockBelow} precision={2} />
                </Popover>
                <Divider></Divider>
                <Statistic title="Est. TTS:" value={record.estTTBB > 999 ? 'Too long' : record.estTTBB} precision={2} />
            </Card>
            <Card size="small" style={{ minWidth: '100px', minHeight: '100%' }}>
                <Statistic title="GMROI:" value={record.GMROI} precision={2} />
                <Divider></Divider>
                <Button size="small" id="wholesale-stock-popover-button" data-asin={record.ASIN}>Grab Stock</Button>
            </Card>
        </Space>
    )
})

export default EstSalesPopover;