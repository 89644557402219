import {Avatar, Button, Col, Form, Input, List, Modal, Row, Skeleton, notification} from "antd";
import {InputRef} from "antd/es/input";
import React, {useRef, useState} from "react";
import {WarehouseProductPopup} from "./WarehouseProductPopup";
import {getKeepa} from "../../services/KeepaService";
import {KeepaProduct} from "../../types/KeepaTypes";
import {WarehouseShipLaterProduct} from "../../types/WarehouseTypes";
import {SaveStoredItem} from "../../services/WarehouseService";
import {useAuth} from "../../contexts/AuthContext";

const WarehouseImport: React.FC = () => {
    const {currentUser} = useAuth();
    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [code, setCode] = useState<string>("");
    const [product, setProduct] = useState<KeepaProduct | null>(null);
    const [visible, setVisible] = useState(false);
    const searchInputRef = useRef<InputRef>(null);

    const searchPressed = (value: string) => {
        setLoading(true);
        const codeType = value.length === 10 ? "asin" : "upc";
        getKeepa(value, value.length === 10 ? "asin" : "code", "&history=0").then((res) => {
            console.log(res);
            modal.confirm({
                footer: null,
                maskClosable: true,
                icon: null,
                centered: true,
                width: "80vw",
                content: (
                    <List
                        header={
                            <div style={{textAlign: "center"}}>
                                <b>{`Products found`}</b>
                            </div>
                        }
                        bordered
                        size="large"
                        dataSource={res.products}
                        style={{maxHeight: "100%", overflowY: "auto", maxWidth: "80vw", width: "100%"}}
                        footer={
                            <>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => {
                                        Modal.destroyAll();
                                        setProduct({
                                            asin: "",
                                            upc: "",
                                            title: "",
                                            [codeType]: value,
                                        });
                                        setVisible(true);
                                    }}
                                >
                                    Save Custom
                                </Button>
                            </>
                        }
                        renderItem={(item: any) => (
                            <List.Item style={{justifyContent: "center"}}>
                                <Skeleton avatar title={true} loading={item.loading} active>
                                    <List.Item.Meta
                                        style={{alignItems: "center", display: "flex"}}
                                        avatar={
                                            item.imagesCSV === null ? (
                                                <Skeleton.Image style={{height: 100, width: 100}} />
                                            ) : (
                                                <Avatar
                                                    shape="square"
                                                    size={100}
                                                    src={`https://images-na.ssl-images-amazon.com/images/I/${
                                                        item.imagesCSV?.split(",")[0]
                                                    }`}
                                                />
                                            )
                                        }
                                        title={item.title}
                                        description={item.description}
                                    />
                                    <div style={{marginRight: "20px"}}>{item.asin}</div>
                                    <Button
                                        size="large"
                                        type="primary"
                                        onClick={() => {
                                            Modal.destroyAll();
                                            setProduct(item);
                                            setVisible(true);
                                        }}
                                    >
                                        Select
                                    </Button>
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                ),
            });
            setLoading(false);
        });
    };

    const generate = (values: WarehouseShipLaterProduct) => {
        console.log("generate xdd", values);

        currentUser
            ?.getIdToken()
            .then((token) => SaveStoredItem(token, values))
            .then((res) => {
                console.log(res);
                if (res.error === null) {
                    notification.success({
                        message: "Item saved!",
                        description: (
                            <div>
                                Item <b>{product!.asin}</b> saved as stored at location <b>{values.location}</b> in <b>Box {values.box}</b>.
                            </div>
                        ),
                        placement: "bottomRight",
                    });

                    setVisible(false);
                    setCode("");
                    searchInputRef.current?.focus();
                    form.setFieldValue("quantity", 1);
                } else {
                    notification.error({
                        message: "Error saving item",
                        description: <div>There was an error saving the item. Please try again.</div>,
                        placement: "bottomRight",
                    });
                }
            });
    };

    return (
        <>
            <>{contextHolder}</>
            <Row gutter={[8, 8]}>
                <Col span={24} style={{display: "flex", alignContent: "center", justifyContent: "center", gap: "6px"}}>
                    <Input.Search
                        ref={searchInputRef}
                        placeholder="Scan or type in the item's UPC"
                        allowClear
                        enterButton="Scan"
                        size="large"
                        value={code}
                        onSearch={searchPressed}
                        onChange={(e) => setCode(e.target.value)}
                        loading={loading}
                    />
                </Col>
                {product && (
                    <WarehouseProductPopup
                        onFinish={generate}
                        onCancel={() => {
                            setVisible(false);
                            setCode("");
                            searchInputRef.current?.focus();
                        }}
                        product={product}
                        open={visible}
                        form={form}
                    />
                )}
            </Row>
        </>
    );
};

export default WarehouseImport;
