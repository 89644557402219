/* eslint-disable no-lone-blocks */
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Button, Divider, Form, FormInstance, InputNumber, message, notification, Select, Space, Tooltip} from "antd";
import {ModalStaticFunctions} from "antd/es/modal/confirm";
import React from "react";
import firebase from "firebase/app";
import {useAuth} from "../../../contexts/AuthContext";
import {
    updateProduct,
    getReviewData,
    getFeeData,
    getRestrictions,
    getHazmat,
    requestBackendKeepa,
    SUPPLIER_PREFIX,
} from "../../../services/WholesaleService";
import {WholesaleItem} from "../../../types/WholesaleItem";
import {WholesaleDataType, WholesaleDataUpdate} from "./WholesaleColumns";
import {confirmModal, confirmSave} from "./WholesaleUtilities";
import {BRAND_OPPORTUNITIES} from "../../../types/OmegaTypes";
import TextArea from "antd/es/input/TextArea";
import {GlobalNoteItem} from "../../../types/Brand";
import dayjs from "dayjs";
import {postGlobalNote} from "../../../services/BrandService";
import {FindSellPrice} from "../ItemCalcs";
import {copyToClipboard} from "../../omega/ItemBrowser";

enum WHOLESALE_BULK_ACTIONS {
    SAVE,
    UNSAVE,
    BUYQTY,
    BB_SHARE,
    ASKPRICE,
    ASKPRICE_MULTI,
    SELLPRICE,
    SHIPPING_FEES,
    RETURN_RATE,
    RETURN_UNSELLABLE_RATE,
    GRAB_REVS,
    GRAB_FEES,
    GRAB_KEEPA,
    CHECK_RESTRICTIONS,
    CHECK_HAZMAT,
    OPPORTUNITIES,
    NOTES,
    COPY_DATA,
}

const WholesaleTakeBulkAction = async (
    action: WHOLESALE_BULK_ACTIONS,
    tableData: WholesaleDataType[],
    filteredData: number[],
    updateTableData: React.Dispatch<WholesaleItem[] | WholesaleDataType | WholesaleDataType[] | WholesaleDataUpdate>,
    bulkForm: FormInstance<any>,
    modal: Omit<ModalStaticFunctions, "warn">,
    role: string | undefined,
    currentUser: firebase.User | null
) => {
    console.log("action to take, currently filtered", action, filteredData);

    const localTableData = tableData.map((prod) => ({...prod}));
    const getItemsToEdit = (localTableData: WholesaleDataType[], removeSaved: boolean) =>
        localTableData.filter((prod) => ((removeSaved && !prod.saved) || !removeSaved) && filteredData.includes(prod.key as number));

    console.log(
        localTableData[0]
            ? Object.keys(localTableData[0])
                  .filter((key) => key.includes(SUPPLIER_PREFIX))
                  .map((key) => ({
                      label: key,
                      value: key,
                  }))
            : []
    );
    switch (action) {
        case WHOLESALE_BULK_ACTIONS.BUYQTY:
            {
                modal.confirm({
                    title: `Fill in the values - priority is from top to bottom!`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form
                            form={bulkForm}
                            name="bulkFormCustom"
                            layout="vertical"
                            initialValues={{buyQtyAbsoluteValue: -1, buyQtyDays: 0, buyQtyColumnCopy: "Default"}}
                            onFinish={() => null}
                            preserve={false}
                        >
                            <Form.Item name="buyQtyAbsoluteValue" label="How many units do you want to buy? Leave -1 to not do anything.">
                                <InputNumber min={-1} precision={0} title="Units To Buy" />
                            </Form.Item>
                            <Form.Item name="buyQtyDays" label="How many days of stock do you want to buy? Leave 0 to not do anything.">
                                <InputNumber min={0} precision={0} title="Days To Buy" />
                            </Form.Item>
                            <Form.Item
                                name="buyQtyColumnCopy"
                                label="Which column do you want the BuyQTY to be based on? Leave Default to not do anything."
                            >
                                <Select
                                    options={[
                                        {
                                            label: "Default",
                                            value: "Default",
                                        },
                                        {
                                            label: "OH",
                                            value: "onHand",
                                        },
                                        {
                                            label: "ToBuy",
                                            value: "SuggestedQTY",
                                        },
                                        {
                                            label: "RepToBuy",
                                            value: "replenToBuy",
                                        },
                                        {
                                            label: "PastSoldMax",
                                            value: "KeepaMaxSold",
                                        },
                                        ...(localTableData[0]
                                            ? Object.keys(localTableData[0])
                                                  .filter((key) => key.includes(SUPPLIER_PREFIX))
                                                  .map((key) => ({
                                                      label: key,
                                                      value: key,
                                                  }))
                                            : []),
                                    ]}
                                ></Select>
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            // we can either set absolute sellprice, or we can set a multiplier, or we can set it to another column

                            console.log("bulk form values", values);

                            const itemsToEdit = getItemsToEdit(localTableData, true);
                            let newItems = itemsToEdit;

                            if (values.buyQtyAbsoluteValue > -1) {
                                newItems = newItems.map((item) => {
                                    item.BuyQTY = values.buyQtyAbsoluteValue;
                                    return item;
                                });
                            } else if (values.buyQtyDays > 0) {
                                newItems = newItems.map((item) => {
                                    item.BuyQTY = Math.min(
                                        item.EstSales! > 0 ? Math.round((item.EstSales! / 30) * values.buyQtyDays) : 0,
                                        item.onHand
                                    );
                                    return item;
                                });
                            } else if (values.buyQtyColumnCopy !== "Default") {
                                newItems = newItems.map((item) => {
                                    if (item[values.buyQtyColumnCopy] !== undefined && item[values.buyQtyColumnCopy] !== null) {
                                        if (typeof item[values.buyQtyColumnCopy] === "string") {
                                            item.BuyQTY = parseInt(item[values.buyQtyColumnCopy].replace(/[^0-9.-]+/g, ""));
                                        } else {
                                            item.BuyQTY = item[values.buyQtyColumnCopy];
                                        }
                                    }

                                    if (isNaN(item.BuyQTY)) {
                                        item.BuyQTY = 0;
                                    }

                                    item.BuyQTY = Math.max(0, Math.min(Math.round(item.BuyQTY), item.onHand));
                                    return item;
                                });
                            }

                            console.log("new items", newItems);

                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.ASKPRICE:
            {
                modal.confirm({
                    title: `Fill in the values - priority is from top to bottom!`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form
                            form={bulkForm}
                            name="bulkFormCustom"
                            layout="vertical"
                            preserve={false}
                            initialValues={{AskPriceAbsoluteValue: 0, AskPriceMultiplier: 0, AskPriceColumnCopy: "Default"}}
                            onFinish={() => null}
                        >
                            <Form.Item
                                name="AskPriceAbsoluteValue"
                                label="What do you want the AskPrice to be? Enter as a number (ie. 25.30 for $25.30)"
                            >
                                <InputNumber min={0} precision={2} title="AskPrice" prefix={"$"} />
                            </Form.Item>
                            <Form.Item
                                name="AskPriceMultiplier"
                                label="What do you want the Multiply the AskPrice by? Leave 0 to not do anything."
                            >
                                <InputNumber min={0} precision={0} title="AskPrice Multiplier" prefix={"%"} />
                            </Form.Item>
                            <Form.Item
                                name="AskPriceColumnCopy"
                                label="Which column do you want the AskPrice to copy? Leave Default to not do anything."
                            >
                                <Select
                                    options={[
                                        {
                                            label: "Default",
                                            value: "Default",
                                        },
                                        {
                                            label: "Cost",
                                            value: "cost",
                                        },
                                        {
                                            label: "MVC",
                                            value: "MaximumViableCost",
                                        },
                                        {
                                            label: "MAP",
                                            value: "Supplier_MAP",
                                        },
                                        {
                                            label: "SellPrice",
                                            value: "sellPrice",
                                        },
                                        {
                                            label: "AskPrice",
                                            value: "AskPrice",
                                        },
                                        ...(localTableData[0]
                                            ? Object.keys(localTableData[0])
                                                  .filter((key) => key.includes(SUPPLIER_PREFIX))
                                                  .map((key) => ({
                                                      label: key,
                                                      value: key,
                                                  }))
                                            : []),
                                    ]}
                                ></Select>
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            console.log("bulk form values", values);

                            const itemsToEdit = getItemsToEdit(localTableData, true);
                            let newItems = itemsToEdit;

                            if (values.AskPriceAbsoluteValue > 0) {
                                newItems = newItems.map((item) => {
                                    item.AskPrice = parseFloat(values.AskPriceAbsoluteValue.toFixed(2));
                                    return item;
                                });
                            } else if (values.AskPriceMultiplier > 0) {
                                newItems = newItems.map((item) => {
                                    item.AskPrice = item.AskPrice * parseFloat((values.AskPriceMultiplier / 100).toFixed(2));
                                    return item;
                                });
                            } else if (values.AskPriceColumnCopy !== "Default") {
                                if (values.AskPriceColumnCopy === "AskPrice") {
                                    newItems = newItems.map((item) => {
                                        item.AskPrice = item.cost * item.packSize * (1 - item.askPriceMultiplier);
                                        return item;
                                    });
                                } else {
                                    newItems = newItems.map((item) => {
                                        if (item[values.AskPriceColumnCopy] !== undefined && item[values.AskPriceColumnCopy] !== null) {
                                            if (typeof item[values.AskPriceColumnCopy] === "string") {
                                                item.AskPrice = parseFloat(item[values.AskPriceColumnCopy].replace(/[^0-9.-]+/g, ""));
                                            } else {
                                                item.AskPrice = item[values.AskPriceColumnCopy];
                                            }
                                        }

                                        if (isNaN(item.AskPrice)) {
                                            item.AskPrice = 1;
                                        }

                                        return item;
                                    });
                                }
                            }

                            console.log("new items", newItems);

                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.ASKPRICE_MULTI:
            {
                modal.confirm({
                    title: `Fill in the values for this action`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{days: 30}} onFinish={() => null}>
                            <Form.Item name="askpricemulti" label="What do you want the Cost Discount to be? Enter as a number.">
                                <InputNumber min={0} precision={2} title="AskPrice" prefix={"%"} />
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            console.log("bulk form values", values);
                            const newItems = getItemsToEdit(localTableData, true).map((item) => {
                                const askPriceMulti = parseFloat((values.askpricemulti / 100).toFixed(2));
                                item.AskPrice = item.cost * item.packSize * (1 - askPriceMulti);
                                item.askPriceMultiplier = askPriceMulti;
                                return item;
                            });

                            console.log("new items", newItems);

                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.SELLPRICE:
            {
                modal.confirm({
                    title: `Fill in the values - priority is from top to bottom!`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form
                            form={bulkForm}
                            name="bulkFormCustom"
                            layout="vertical"
                            initialValues={{sellPriceAbsoluteValue: 0, sellPriceMultiplier: 0, sellPriceColumnCopy: "Default"}}
                            onFinish={() => null}
                        >
                            <Form.Item
                                name="sellPriceAbsoluteValue"
                                label="What do you want the Sell Price to be? Enter as a number (ie. 25.30 for $25.30)"
                            >
                                <InputNumber min={0} precision={2} title="Sell Price" prefix={"$"} />
                            </Form.Item>
                            <Form.Item
                                name="sellPriceMultiplier"
                                label="What do you want the Sell Price Multiplier to be? Leave 0 to not do anything."
                            >
                                <InputNumber min={0} precision={0} title="Sell Price" prefix={"%"} />
                            </Form.Item>
                            <Form.Item
                                name="sellPriceColumnCopy"
                                label="Which column do you want the SellPrice to copy? Leave Default to not do anything."
                            >
                                <Select
                                    options={[
                                        {
                                            label: "Default",
                                            value: "Default",
                                        },
                                        {
                                            label: "Cost",
                                            value: "cost",
                                        },
                                        {
                                            label: "AskPrice",
                                            value: "AskPrice",
                                        },
                                        {
                                            label: "MAP",
                                            value: "Supplier_MAP",
                                        },
                                        {
                                            label: "SellPrice",
                                            value: "sellPrice",
                                        },
                                    ]}
                                ></Select>
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            // we can either set absolute sellprice, or we can set a multiplier, or we can set it to another column

                            console.log("bulk form values", values);

                            const itemsToEdit = getItemsToEdit(localTableData, true);
                            let newItems = itemsToEdit;

                            if (values.sellPriceAbsoluteValue > 0) {
                                newItems = newItems.map((item) => {
                                    item.sellPrice = parseFloat(values.sellPriceAbsoluteValue.toFixed(2));
                                    return item;
                                });
                            } else if (values.sellPriceMultiplier > 0) {
                                newItems = newItems.map((item) => {
                                    FindSellPrice(item);
                                    item.sellPrice = item.sellPrice * (1 - parseFloat((values.sellPriceMultiplier / 100).toFixed(2)));
                                    return item;
                                });
                            } else if (values.sellPriceColumnCopy !== "Default") {
                                if (values.sellPriceColumnCopy === "sellPrice") {
                                    newItems = newItems.map((item) => {
                                        FindSellPrice(item);
                                        return item;
                                    });
                                } else {
                                    newItems = newItems.map((item) => {
                                        if (item[values.sellPriceColumnCopy] !== undefined && item[values.sellPriceColumnCopy] !== null) {
                                            if (typeof item[values.sellPriceColumnCopy] === "string") {
                                                item.sellPrice = parseFloat(item[values.sellPriceColumnCopy].replace(/[^0-9.-]+/g, ""));
                                            } else {
                                                item.sellPrice = item[values.sellPriceColumnCopy];
                                            }
                                        }
                                        return item;
                                    });
                                }
                            }

                            console.log("new items", newItems);

                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.BB_SHARE:
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{days: 30}} onFinish={() => null}>
                        <Form.Item name="bbshare" label="How much BuyBox share do you want to give? Enter as a percentage (ie. 25 for 25%)">
                            <InputNumber min={0} precision={0} title="BB%" />
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    bulkForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        const newItems = getItemsToEdit(localTableData, true).map((item) => {
                            item.bbMultiplier = parseFloat((values.bbshare / 100).toFixed(2));

                            return item;
                        });

                        console.log("new items", newItems);

                        updateTableData({
                            type: "partial",
                            data: newItems,
                        });
                    });
                },
                onCancel() {
                    console.log("Cancelled Bulk");
                    bulkForm.resetFields();
                },
            });
            break;
        case WHOLESALE_BULK_ACTIONS.SHIPPING_FEES:
            {
                modal.confirm({
                    title: `Fill in the values - priority is from top to bottom!`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form
                            form={bulkForm}
                            name="bulkFormCustom"
                            layout="vertical"
                            initialValues={{shippingFeesAbsoluteValue: -1, shippingFeesMulti: 0}}
                            onFinish={() => null}
                            preserve={false}
                        >
                            <Form.Item
                                name="shippingFeesAbsoluteValue"
                                label="How much are we paying for shipping? Leave -1 to not do anything."
                            >
                                <InputNumber min={-1} precision={0} title="Absolute Value" />
                            </Form.Item>
                            <Form.Item
                                name="shippingFeesMulti"
                                label="How much do you want to pay for a dim. pound? Leave 0 to not do anything, $0.25 is the default value."
                            >
                                <InputNumber min={0} precision={2} title="$ per dim. pound" prefix={"$"} />
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            // we can either set absolute sellprice, or we can set a multiplier, or we can set it to another column

                            console.log("bulk form values", values);

                            const itemsToEdit = getItemsToEdit(localTableData, true);
                            let newItems = itemsToEdit;

                            if (values.shippingFeesAbsoluteValue >= 0) {
                                newItems = newItems.map((item) => {
                                    item.editedShippingCost = values.shippingFeesAbsoluteValue;
                                    return item;
                                });
                            } else if (values.shippingFeesMulti > 0) {
                                newItems = newItems.map((item) => {
                                    item.editedShippingCost = parseFloat(
                                        (item.shipDimWeight > 0
                                            ? item.shipDimWeight * values.shippingFeesMulti
                                            : item.adjustedWeight * item.shippingFeeMulti + item.shippingFeeBase
                                        ).toFixed(2)
                                    );
                                    return item;
                                });
                            } else {
                                message.error("What have you done?");
                            }

                            console.log("new items", newItems);

                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.RETURN_RATE:
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{days: 30}} onFinish={() => null}>
                        <Form.Item
                            name="returnrate"
                            label="How much Return Rate do you want to give? Enter as a percentage (ie. 25 for 25%)"
                        >
                            <InputNumber min={0} precision={0} title="Return Rate %" />
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    bulkForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        const newItems = getItemsToEdit(localTableData, true).map((item) => {
                            item.returnRate = parseFloat((values.returnrate / 100).toFixed(2));

                            return item;
                        });

                        console.log("new items", newItems);

                        updateTableData({
                            type: "partial",
                            data: newItems,
                        });
                    });
                },
                onCancel() {
                    console.log("Cancelled Bulk");
                    bulkForm.resetFields();
                },
            });
            break;
        case WHOLESALE_BULK_ACTIONS.RETURN_UNSELLABLE_RATE:
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{days: 30}} onFinish={() => null}>
                        <Form.Item
                            name="unsellablerate"
                            label="How much Unsellable Rate do you want to give? Enter as a percentage (ie. 25 for 25%)"
                        >
                            <InputNumber min={0} precision={0} title="Unsellable Rate %" />
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    bulkForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        const newItems = getItemsToEdit(localTableData, true).map((item) => {
                            item.returnUnsellableRate = parseFloat((values.unsellablerate / 100).toFixed(2));

                            return item;
                        });

                        console.log("new items", newItems);

                        updateTableData({
                            type: "partial",
                            data: newItems,
                        });
                    });
                },
                onCancel() {
                    console.log("Cancelled Bulk");
                    bulkForm.resetFields();
                },
            });
            break;
        case WHOLESALE_BULK_ACTIONS.SAVE:
            {
                const newItems = getItemsToEdit(localTableData, false).map((item) => {
                    item.saved = true;
                    item.sourceCollection = `${item.sourceCollection.split("_")[0]}_saved`;
                    return item;
                });

                confirmSave(modal, newItems, (newItemsToSave) => {
                    updateProduct(newItemsToSave)
                        .then((res) => res.json())
                        .then((res) => {
                            if (res.error === null) {
                                message.success("Saved!");
                                updateTableData({
                                    type: "partial",
                                    data: newItemsToSave,
                                });
                            } else {
                                message.error(res.error);
                            }
                        })
                        .catch((ex) => {
                            notification.error({message: `Failed to save the products!`, description: ex.toString()});
                        });
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.UNSAVE:
            {
                const newItems = getItemsToEdit(localTableData, false).map((item) => {
                    item.saved = false;
                    item.sourceCollection = `${item.sourceCollection.split("_")[0]}_saved`;
                    return item;
                });

                updateProduct(newItems)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error === null) {
                            message.success("Unsaved!");
                            updateTableData({
                                type: "partial",
                                data: newItems,
                            });
                        } else {
                            message.error(res.error);
                        }
                    })
                    .catch((ex) => {
                        notification.error({message: `Failed to unsave the products!`, description: ex.toString()});
                    });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.GRAB_REVS:
            {
                const newItems = getItemsToEdit(localTableData, false)
                    .filter((prod) => prod.variationChecked !== true && prod.parentASIN?.length > 0 && prod.parentASIN !== prod.ASIN)
                    .slice(0, role === "ADMIN" ? 7500 : 500);
                const temp: {[key: string]: any[]} = {};
                const updateItems: WholesaleDataType[] = [];
                const parentItems = newItems.reduce((acc, curr): {[key: string]: any[]} => {
                    acc[curr.parentASIN] = (acc[curr.parentASIN] || []).concat([curr]);
                    return acc;
                }, temp);

                if (newItems.length > 0) {
                    let resultMap: {[key: string]: any} = {};
                    let parentMap: {[key: string]: any} = {};
                    let fullCount = newItems.length;

                    message.info(
                        `Please be aware this will only process up to the first 500 un-checked ASINs with parent ASINs (${fullCount} in this run) - re-run if necessary!`
                    );

                    notification.info({
                        key: "review",
                        message: `Currently grabbed ${Object.values(resultMap).length} / ${fullCount}`,
                        placement: "bottomRight",
                    });

                    const token = await currentUser!.getIdToken();
                    await Promise.all(
                        [...Array(5)].map(async () => {
                            while (newItems.length > 0) {
                                let item = newItems.pop();
                                if (item) {
                                    await getReviewData(item.ASIN, token)
                                        .then((res) => {
                                            console.log(
                                                "Singular Rev Data grab lmao",
                                                item,
                                                res,
                                                res.parentCount,
                                                res.childCount,
                                                res.parentCount >= 0 && res.childCount >= 0
                                            );

                                            if (res.parentCount >= 0 && res.childCount >= 0) {
                                                resultMap[(item as WholesaleItem).ASIN] = {
                                                    item: item,
                                                    res: res,
                                                };
                                                parentMap[(item as WholesaleItem).parentASIN] = (
                                                    parentMap[(item as WholesaleItem).parentASIN] ?? []
                                                ).concat([res]);
                                                // message.success(`Grab for ${(newItem as WholesaleDataType).ASIN} successful`);

                                                notification.info({
                                                    key: "review",
                                                    message: `Grabbed so far: ${Object.values(resultMap).length} / ${fullCount}`,
                                                    placement: "bottomRight",
                                                });
                                            } else {
                                                message.error(
                                                    `Failed to grab for ${
                                                        (item as WholesaleDataType).ASIN
                                                    } - please re-try, but not too much!`
                                                );
                                            }
                                        })
                                        .catch((ex) => {
                                            message.error(
                                                `Failed to grab for ${(item as WholesaleDataType).ASIN} - please re-try, but not too much!`
                                            );
                                        });
                                }
                            }
                        })
                    );

                    for (const itemData of Object.values(resultMap)) {
                        const newItem: WholesaleDataType = {...(itemData.item as WholesaleDataType)};
                        const res = itemData.res;

                        const maxRevs = Math.max(
                            ...parentMap[newItem.parentASIN].map((obj: any) => obj.parentCount),
                            ...parentItems[newItem.parentASIN].map((obj: any) => obj.reviewCount)
                        );

                        if (maxRevs > 0) {
                            newItem.reviewMultiplier = res.childCount / maxRevs;
                        } else if (maxRevs === 0 && res.childCount === 0) {
                            newItem.reviewMultiplier = 1;
                        }

                        newItem.variationReviewsTotal = maxRevs;
                        newItem.variationReviews = res.childCount;
                        newItem.variationChecked = true;

                        console.log("new item during process", newItem);

                        updateItems.push(newItem);
                    }

                    updateProduct(updateItems);
                    updateTableData({
                        type: "partial",
                        data: updateItems,
                    });
                } else {
                    message.error("No items to grab! Maybe they were checked already?");
                }
            }
            break;
        case WHOLESALE_BULK_ACTIONS.GRAB_FEES:
            {
                const updateItems: WholesaleDataType[] = [];
                const newItems = getItemsToEdit(localTableData, false);
                const reqObjects = newItems
                    .filter((item) => item.sellPrice > 0)
                    .map((item) => ({
                        asin: item.ASIN,
                        price: item.sellPrice,
                    }));

                message.info(
                    `Please be aware this might take a little while to process, approximately 1 second per 5 ASINs (${newItems.length} in this run)!`
                );

                await currentUser!
                    .getIdToken()
                    .then((token) => getFeeData(token, reqObjects))
                    .then((res) => {
                        if (res.result?.length > 0) {
                            for (const feeResult of res.result) {
                                if (feeResult.Status === "Success") {
                                    const item = newItems.find((item) => item.ASIN === feeResult.FeesEstimateIdentifier.IdValue);
                                    if (item) {
                                        const newItem = {...item};
                                        const feeList = feeResult.FeesEstimate.FeeDetailList;
                                        const refFeeResult = feeList.find((fee: any) => fee.FeeType === "ReferralFee");
                                        const fbaFeeResult = feeList.find((fee: any) => fee.FeeType === "FBAFees");
                                        newItem.refFeeSPAPI = refFeeResult.FinalFee.Amount;
                                        newItem.refFeeSPAPIBase = feeResult.FeesEstimateIdentifier.PriceToEstimateFees.ListingPrice.Amount;
                                        newItem.Fees = fbaFeeResult.FinalFee.Amount;

                                        // If we don't have S&L Fees applied, we can update the current prepFee variable
                                        if (!newItem.SmallLightFeeApplied) {
                                            newItem.prepFee = newItem.Fees;
                                        }

                                        updateItems.push(newItem);
                                    }
                                } else {
                                    message.error(`Failed to grab data for ${feeResult.FeesEstimateIdentifier.IdValue}!`);
                                }
                            }
                        } else {
                            if (res.error) {
                                message.error(res.error);
                            } else {
                                message.info("No results found - please try again!");
                            }
                        }
                    });

                console.log(updateItems);

                updateProduct(updateItems);
                updateTableData({
                    type: "partial",
                    data: updateItems,
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.CHECK_RESTRICTIONS:
            {
                const updateItems: WholesaleDataType[] = [];
                const newItems = getItemsToEdit(localTableData, false);
                const reqObjects = newItems.map((item) => ({
                    asin: item.ASIN,
                }));

                message.info(
                    `Please be aware this might take a little while to process, approximately 5 second per 10 ASINs (${newItems.length} in this run)!`
                );

                for (let i = 0; i < reqObjects.length; i += 10) {
                    const reqObjectsSlice = reqObjects.slice(i, i + 10);
                    await currentUser!
                        .getIdToken()
                        .then((token) => getRestrictions(token, reqObjectsSlice))
                        .then((res) => {
                            console.log("Restriction result", res);
                            if (res.result) {
                                Object.entries(res.result).forEach(([asin, isRestricted]) => {
                                    const item = newItems.find((item) => item.ASIN === asin);
                                    console.log(asin, isRestricted, item);
                                    if (item) {
                                        const newItem = {...item};
                                        newItem.isRestricted = isRestricted;
                                        newItem.isRestrictedChecked = true;

                                        updateItems.push(newItem);
                                    }
                                });
                            } else {
                                if (res.error) {
                                    message.error(res.error);
                                } else {
                                    message.info("No results found - please try again!");
                                }
                            }
                        });
                    await new Promise((r) => setTimeout(r, 1500));
                }

                console.log(updateItems);

                updateProduct(updateItems);
                updateTableData({
                    type: "partial",
                    data: updateItems,
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.CHECK_HAZMAT:
            {
                const updateItems: WholesaleDataType[] = [];
                const newItems = getItemsToEdit(localTableData, false);
                const reqObjects = newItems.map((item) => ({
                    asin: item.ASIN,
                }));

                message.info(
                    `Please be aware this might take a little while to process, approximately 1 second per 1 ASINs (${newItems.length} in this run)!`
                );

                for (let i = 0; i < reqObjects.length; i += 1) {
                    const reqObjectsSlice = reqObjects.slice(i, i + 1);
                    await currentUser!
                        .getIdToken()
                        .then((token) => getHazmat(token, reqObjectsSlice))
                        .then((res) => {
                            if (res.result) {
                                Object.entries(res.result).forEach(([asin, isHazmat]) => {
                                    const item = newItems.find((item) => item.ASIN === asin);
                                    if (item) {
                                        const newItem = {...item};
                                        newItem.isHazmat = typeof isHazmat === "string" ? true : false;
                                        newItem.isHazmatChecked = true;
                                        newItem.isHazmatReason = typeof isHazmat === "string" ? isHazmat : "";

                                        if (newItem.isHazmat && !item.isHazmat) {
                                            newItem.prepFee = newItem.prepFee * 4;
                                            newItem.shippingCost = newItem.shippingCost * 2;
                                        }

                                        updateItems.push(newItem);
                                    }
                                });
                            } else {
                                if (res.error) {
                                    message.error(res.error);
                                } else {
                                    message.info("No results found - please try again!");
                                }
                            }
                        });
                    await new Promise((r) => setTimeout(r, 1000));
                }

                updateProduct(updateItems);
                updateTableData({
                    type: "partial",
                    data: updateItems,
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.GRAB_KEEPA:
            {
                const updateItems: WholesaleDataType[] = [];
                const newItems = getItemsToEdit(localTableData, false);
                const reqObjects = newItems
                    .filter((item) => item.KeepaMaxSoldChecked === false)
                    .slice(0, 100)
                    .map((item) => item.ASIN);

                if (reqObjects.length === 0) {
                    message.error("No items to grab! Maybe they were checked already?");
                    return;
                }

                message.info(
                    `Please be aware this might take a little while to process, approximately 2 second per 10 ASINs (${newItems.length} in this run)!`
                );

                let result = {};
                await currentUser!
                    .getIdToken()
                    .then((token) => requestBackendKeepa(token, reqObjects, "wholesale"))
                    .then((res) => {
                        if (res.result) {
                            console.log("Keepa result", res.result);
                            result = res.result;
                        } else {
                            if (res.error) {
                                message.error(res.error);
                            } else {
                                message.info("No results found - please try again!");
                            }
                        }
                    });

                const getTimeFromKeepa = (keepaTime: number) => {
                    return new Date((keepaTime + 21564000) * 60000);
                };

                const findMaxPastSold = (product: any) => {
                    const soldData = (product.monthlySoldHistory ?? [])
                        .map((val: any, idx: number) => {
                            if (idx % 2 === 0) {
                                return getTimeFromKeepa(parseInt(val));
                            } else {
                                return val;
                            }
                        })
                        .reduce((acc: any, curr: any, idx: any) => {
                            if (idx % 2 === 0) {
                                let initialDate = new Date(curr);
                                initialDate.setMonth(curr.getMonth() - 1);

                                acc.push({
                                    ASIN: product.asin,
                                    SoldDataTimestamp: curr.toLocaleDateString(),
                                    InitialDate: initialDate.toLocaleDateString(),
                                    FinalDate: curr.toLocaleDateString(),
                                    Sold: "",
                                });
                            } else {
                                acc[acc.length - 1].Sold = curr;
                            }

                            return acc;
                        }, []);

                    // find the highest sold and post initial date
                    const maxSold = Math.max(...soldData.map((val: any) => parseInt(val.Sold)), -1);
                    const maxSoldItem = soldData.find((val: any) => parseInt(val.Sold) === maxSold);
                    console.log("Max Sold Item", maxSoldItem);

                    const itemsToUpdate = newItems.filter((item) => item.ASIN === product.asin);
                    if (itemsToUpdate.length > 0) {
                        const item = itemsToUpdate[0];
                        item.KeepaMaxSold = maxSold;
                        item.KeepaMaxSoldDate = maxSoldItem?.InitialDate;
                        item.KeepaMaxSoldDateFinal = maxSoldItem?.FinalDate;
                        item.KeepaMaxSoldTimestamp = maxSoldItem?.SoldDataTimestamp;
                        item.KeepaMaxSoldChecked = true;

                        updateItems.push(item);
                    }
                };

                Object.values(result).forEach((product) => findMaxPastSold(product));

                // find items that have not had their keepa data found but exist in reqObjects
                const missingItems = newItems.filter(
                    (item) => reqObjects.includes(item.ASIN) && !updateItems.map((item) => item.ASIN).includes(item.ASIN)
                );
                updateItems.push(
                    ...missingItems.map((item) => {
                        item.KeepaMaxSoldChecked = true;
                        return item;
                    })
                );

                updateProduct(updateItems);
                updateTableData({
                    type: "partial",
                    data: updateItems,
                });
            }
            break;
        case WHOLESALE_BULK_ACTIONS.OPPORTUNITIES:
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{opportunities: []}} onFinish={() => null}>
                        <Form.Item name="opportunities" label="What do you want to add to the Opportunities? Select the Tags">
                            <Select
                                mode="tags"
                                style={{width: "100%"}}
                                placeholder="Tags"
                                allowClear
                                onChange={(val) => bulkForm.setFieldsValue({opportunities: val})}
                            >
                                {BRAND_OPPORTUNITIES.map((tag: string, idx: number) => (
                                    <Select.Option key={idx} value={tag}>
                                        {tag}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    bulkForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        const newItems = getItemsToEdit(localTableData, true).map((item) => {
                            item.opportunities = Array.from(new Set([...item.opportunities.split(";"), ...values.opportunities]))
                                .filter((opp) => opp.length > 0)
                                .join(";");
                            return item;
                        });

                        console.log("new items", newItems);

                        updateTableData({
                            type: "partial",
                            data: newItems,
                        });
                    });
                },
                onCancel() {
                    console.log("Cancelled Bulk");
                    bulkForm.resetFields();
                },
            });
            break;
        case WHOLESALE_BULK_ACTIONS.NOTES:
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={bulkForm} name="bulkFormCustom" layout="vertical" initialValues={{days: 30}} onFinish={() => null}>
                        <Form.Item name="note" label="What do you want your new Note to say?">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    bulkForm.validateFields().then((values) => {
                        console.log("bulk form values", values);

                        const newNotes: GlobalNoteItem[] = [];
                        const newItems = getItemsToEdit(localTableData, true).map((item) => {
                            const newNote: GlobalNoteItem = {
                                ASIN: item.ASIN,
                                ParentASIN: item.parentASIN,
                                isParentNote: false,
                                noteAuthor: currentUser!.email || "Unknown",
                                noteText: values.note,
                                noteTimestamp: dayjs().format("YY-MM-DD"),
                                edited: false,
                            };

                            newNotes.push(newNote);
                            return item;
                        });
                        // remove repeats by ASIN
                        const uniqueNotes: GlobalNoteItem[] = Array.from(new Set(newNotes.map((item) => item.ASIN))).map(
                            (asin) => newNotes.find((item) => item.ASIN === asin)!
                        );

                        postGlobalNote("", uniqueNotes)
                            .then((res) => {
                                if (res.error === null) {
                                    message.success("Notes saved!");

                                    if (res.writeResult.upserted?.length > 0) {
                                        for (const upsertInfo of res.writeResult.upserted) {
                                            newNotes[upsertInfo.index]._id = upsertInfo._id;
                                        }
                                    }

                                    newItems.map((item) => {
                                        const itemNote = uniqueNotes.find((note) => note.ASIN === item.ASIN)!;
                                        item.Notes = [itemNote, ...item.Notes];
                                        return item;
                                    });

                                    console.log("new items", newItems);

                                    updateTableData({
                                        type: "partial",
                                        data: newItems,
                                    });
                                } else {
                                    notification.error({message: `Failed to save new note!`, description: res.error});
                                }
                            })
                            .catch((ex) => {
                                notification.error({message: `Failed to save new note`, description: ex.toString()});
                            });
                    });
                },
                onCancel() {
                    console.log("Cancelled Bulk");
                    bulkForm.resetFields();
                },
            });
            break;
        case WHOLESALE_BULK_ACTIONS.COPY_DATA:
            {
                modal.confirm({
                    title: `Fill in the values - priority is from top to bottom!`,
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <Form
                            form={bulkForm}
                            name="bulkFormCustom"
                            layout="vertical"
                            initialValues={{clipboardColumnCopy: "ASIN"}}
                            onFinish={() => null}
                            preserve={false}
                        >
                            <Form.Item name="clipboardColumnCopy" label="Which column data do you to copy to clipboard?">
                                <Select
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label"
                                    options={[
                                        {
                                            label: "ASIN",
                                            value: "ASIN",
                                        },
                                        {
                                            label: "Parent",
                                            value: "parentASIN",
                                        },
                                        {
                                            label: "UPC",
                                            value: "UPC",
                                        },
                                        ...(localTableData[0]
                                            ? Object.keys(localTableData[0])
                                                  .filter((key) => key.includes(SUPPLIER_PREFIX))
                                                  .map((key) => ({
                                                      label: key,
                                                      value: key,
                                                  }))
                                            : []),
                                    ]}
                                />
                            </Form.Item>
                        </Form>
                    ),
                    onOk() {
                        bulkForm.validateFields().then((values) => {
                            const itemsToCopy = getItemsToEdit(localTableData, false);
                            const columnToCopy = bulkForm.getFieldValue("clipboardColumnCopy");
                            const copyText = itemsToCopy.map((item) => item[columnToCopy]).join("\n");
                            copyToClipboard(copyText, columnToCopy);
                            console.log(copyText);
                        });
                    },
                    onCancel() {
                        console.log("Cancelled Bulk");
                        bulkForm.resetFields();
                    },
                });
            }
            break;
        default:
            break;
    }
};

const GetActionTooltip = (action: keyof typeof WHOLESALE_BULK_ACTIONS) => {
    switch (WHOLESALE_BULK_ACTIONS[action]) {
        case WHOLESALE_BULK_ACTIONS.BUYQTY:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the BuyQTY of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    BuyQTY
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.ASKPRICE:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the AskPrice of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    AskPrice
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.ASKPRICE_MULTI:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the Cost Discount (AskPrice Multiplier) of the items by a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Cost Discount
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.SELLPRICE:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the SellPrice of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    SellPrice
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.BB_SHARE:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the BuyBox Share of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    BB Share
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.SHIPPING_FEES:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the Shipping Fees of the items, based either on dimensions or absolute value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Shipping Fees
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.RETURN_RATE:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the Return Rate of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Return Rate
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.RETURN_UNSELLABLE_RATE:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Sets the Unsellable Return Rate of the items to a custom value <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Unsellable Rate
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.SAVE:
            return "Save";
        case WHOLESALE_BULK_ACTIONS.UNSAVE:
            return "Unsave";
        case WHOLESALE_BULK_ACTIONS.GRAB_REVS:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Grabs review data for the filtered items, skipping items that have already been checked and those without a
                            parent or those that are a single variation
                        </div>
                    }
                >
                    Grab Reviews
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.GRAB_FEES:
            return (
                <Tooltip
                    placement="top"
                    title={<div>Grabs fee data for the filtered items directly from Amazon - items without SellPrice are SKIPPED!</div>}
                >
                    Grab Fees
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.CHECK_RESTRICTIONS:
            return (
                <Tooltip placement="top" title={<div>Grabs restrictions data for the filtered items directly from Amazon!</div>}>
                    Check Restrictions
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.CHECK_HAZMAT:
            return (
                <Tooltip placement="top" title={<div>Grabs Hazmat data for the filtered items directly from Amazon!</div>}>
                    Check Hazmat
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.GRAB_KEEPA:
            return (
                <Tooltip placement="top" title={<div>Grabs Keepa data for the filtered items directly from Amazon!</div>}>
                    Grab Keepa
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.OPPORTUNITIES:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Adds selected Opportunities to the filtered items! <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Opportunities
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.NOTES:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Adds Note to the filtered items! <br />
                            <br />
                            Doesn't update already Saved items
                        </div>
                    }
                >
                    Notes
                </Tooltip>
            );
        case WHOLESALE_BULK_ACTIONS.COPY_DATA:
            return (
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Copy data values for filtered items! <br />
                        </div>
                    }
                >
                    Copy Data
                </Tooltip>
            );
        default:
            return <>{action}</>;
    }
};

const GetActionModalPopup = (action: keyof typeof WHOLESALE_BULK_ACTIONS, filteredDataCount: number) => {
    switch (WHOLESALE_BULK_ACTIONS[action]) {
        case WHOLESALE_BULK_ACTIONS.SAVE:
            return `You are about to save ${filteredDataCount} ASINs`;
        case WHOLESALE_BULK_ACTIONS.UNSAVE:
            return `You are about to unsave ${filteredDataCount} ASINs`;
        case WHOLESALE_BULK_ACTIONS.COPY_DATA:
            return `You are about to copy the data of ${filteredDataCount} ASINs`;
        default:
            return (
                <div>
                    You are about to update the data of {filteredDataCount} ASINs <b>[IRREVERSIBLE]</b>
                </div>
            );
    }
};

const isWholesaleBulkAction = (action: string): action is keyof typeof WHOLESALE_BULK_ACTIONS => {
    return action in WHOLESALE_BULK_ACTIONS && isNaN(Number(action));
};

const WholesaleBulkActions: React.FC<{
    tableData: WholesaleDataType[];
    filteredData: number[];
    updateTableData: React.Dispatch<WholesaleItem[] | WholesaleDataType | WholesaleDataType[] | WholesaleDataUpdate>;
    modal: Omit<ModalStaticFunctions, "warn">;
}> = ({tableData, filteredData, updateTableData, modal}) => {
    const {currentUser, userData} = useAuth();
    const [bulkForm] = Form.useForm();

    return (
        <Space align="center" style={{width: "100%", overflow: "auto"}} split={<Divider type="vertical"></Divider>}>
            {Object.keys(WHOLESALE_BULK_ACTIONS)
                .filter(isWholesaleBulkAction)
                .map((action) => (
                    <Button
                        key={action}
                        style={{marginBottom: "4px", marginRight: "2px"}}
                        size="small"
                        type="primary"
                        onClick={() => {
                            confirmModal(
                                modal,
                                () =>
                                    WholesaleTakeBulkAction(
                                        WHOLESALE_BULK_ACTIONS[action],
                                        tableData,
                                        filteredData,
                                        updateTableData,
                                        bulkForm,
                                        modal,
                                        userData?.role,
                                        currentUser
                                    ),
                                GetActionModalPopup(action, filteredData.length)
                            );
                        }}
                    >
                        {GetActionTooltip(action)}
                    </Button>
                ))}
        </Space>
    );
};

export default WholesaleBulkActions;
