import React, {useMemo} from "react";
import {Modal, Table, Skeleton, Avatar, Typography, InputNumber, Button} from "antd";
import {InboundBox, InboundShipmentItem} from "../../../types/WarehouseTypes";
import {ColumnsType} from "antd/lib/table";
import {useStore} from "../../../store/useStore";
import {countItemsByKey} from "./helpers";
import {useShallow} from "zustand/react/shallow";
import {DeleteOutlined} from "@ant-design/icons";

interface EditBoxDialogProps {
    box: InboundBox;
    open: boolean;
    onCancel: () => void;
    title?: string;
}

const EditBoxDialog: React.FC<EditBoxDialogProps> = ({box, open, onCancel, title}) => {
    const boxesStore = useStore((state) => state.boxes);
    const boxingActions = useStore(
        useShallow((state) => ({
            changeItemQuantity: state.changeItemQuantity,
            removeItem: state.removeItem,
        }))
    );

    const columns: ColumnsType<InboundShipmentItem> = [
        {
            title: "Image",
            dataIndex: "ImgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "Name",
            key: "name",
            width: "150px",
            render: (name) => (
                <Typography.Text ellipsis style={{width: "150px"}}>
                    {name}
                </Typography.Text>
            ),
        },
        {
            title: "UPC",
            dataIndex: "UPC",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "ASIN",
            key: "asin",
        },
        {
            title: "FNSKU",
            dataIndex: "FulfillmentNetworkSKU",
            key: "fnsku",
        },
        {
            title: "SKU",
            dataIndex: "SellerSKU",
            key: "sku",
            width: "150px",
        },
        {
            title: "Quantity",
            key: "action",
            render: (item: InboundShipmentItem) => (
                <InputNumber
                    title="Quantity"
                    min={1}
                    max={(item.QuantityLeft || 0) + (item.QuantityInBox || 1)}
                    defaultValue={item.QuantityInBox}
                    onChange={(value) => boxingActions.changeItemQuantity(item.SellerSKU, box.id, value || 0)}
                />
            ),
        },
        {
            title: "Remove",
            key: "action",
            render: (item: InboundShipmentItem) => (
                <Button icon={<DeleteOutlined />} onClick={() => boxingActions.removeItem(item.SellerSKU, box.id)} type="text" />
            ),
        },
    ];

    const boxes = useMemo(() => {
        return Object.values(boxesStore.byId).filter((b) => b.shipmentId === box.shipmentId) as InboundBox[];
    }, [boxesStore, box]);

    const items = useMemo(() => {
        const boxed = countItemsByKey(boxes);

        const allItems = box.items.map((item) => ({
            ...item,
            QuantityLeft: item.QuantityShipped - (boxed[item.SellerSKU] || 0),
        }));
        return allItems;
    }, [boxes, box]);

    return (
        <Modal title={title || `Box ${box.number} Items`} open={open} onCancel={onCancel} footer={null} width={"80vw"}>
            <Table style={{height: "50vh", overflow: "auto"}} dataSource={items} columns={columns} rowKey="SellerSKU" pagination={false} />
        </Modal>
    );
};

export default EditBoxDialog;
