import React, { useRef, useState } from "react";
import { Table, Tooltip, InputRef } from "antd";
import {Item} from "../../types/Item";

import {
  CheckOutlined,
  CloseOutlined,
  FallOutlined,
  BulbOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import FilterDropdown from "../utilities/FilterDropdown";
import { FilterDropdownProps, ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import "moment-timezone";
import SliderFilterDropdown from "../utilities/SliderFilterDropdown";
import { MinMaxFilterDropdown } from "../utilities/MinMaxFilterDropdown";
import { DateRangeFilterDropdown } from "../utilities/DateRangeFilterDropdown";
import { Dayjs } from "dayjs";

function search(value: any, record: Item) {
  return record.name
    .toString()
    .toLowerCase()
    .includes(value.toString().toLowerCase());
}

function formatDate(val: string | number | undefined) {
  return moment(val).tz("America/Chicago").format("MM/DD/YYYY HH:mm");
}

const DataTable: React.FC<{
  data: Item[];
  loading?: boolean;
  showSite?: boolean;
  expanded?: boolean;
  sortTimestamps?: boolean;
  changeFilteredData?: (dataSource: Item[]) => void;
}> = ({ data, showSite, expanded, sortTimestamps, loading, changeFilteredData }) => {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>();
  const searchInputRef = useRef<InputRef>(null);
  const [minPrice, maxPrice] = data.reduce(
    ([min, max], item) => [
      item.price < min ? item.price : min,
      item.price > max ? item.price : max,
    ],
    [Number.MAX_SAFE_INTEGER, 0]
  );

  const columns: ColumnsType<Item> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: "20%",
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterDropdown {...props} inputRef={searchInputRef} />
      ),
      onFilter: search,
      onFilterDropdownOpenChange: (visible: boolean) =>
        visible && setTimeout(() => searchInputRef.current?.select()),
      render: (val, item) => (
        <a href={item.productUrl} target="_blank" rel="noopener noreferrer">
          {val}
        </a>
      ),
    },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      key: "originalPrice",
      render: (val: number) => <>{val ? "$" + val : "--"}</>,
      sorter: (a, b) => a.originalPrice - b.originalPrice,
      filterDropdown: (props) => (
        <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={0} />
      ),
      onFilter: (value: any, record) => {
        const [min, max] = value.split(" ");
        return record.price > +min && record.price < +max;
      },
    },
    {
      title: "Prev. Price",
      dataIndex: "oldPrice",
      key: "oldPrice",
      render: (val: number) => <>{val ? "$" + val : "--"}</>,
      sorter: (a, b) => a.originalPrice - b.originalPrice,
      filterDropdown: (props) => (
        <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={0} />
      ),
      onFilter: (value: any, record) => {
        const [min, max] = value.split(" ");
        return record.price > +min && record.price < +max;
      },
    },
    {
      title: "Lowest Price",
      dataIndex: "lowestPrice",
      key: "lowestPrice",
      render: (val: number) => <>{val ? "$" + val : "--"}</>,
      sorter: (a, b) => a.originalPrice - b.originalPrice,
      filterDropdown: (props) => (
        <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={0} />
      ),
      onFilter: (value: any, record) => {
        const [min, max] = value.split(" ");
        return record.price > +min && record.price < +max;
      },
    },
    {
      title: "Current Price",
      dataIndex: "price",
      key: "price",
      render: (val: number) => <>{val ? "$" + val : "--"}</>,
      sorter: (a, b) => a.price - b.price,
      filterDropdown: (props) => (
        <MinMaxFilterDropdown {...props} defaultMin={minPrice} defaultMax={maxPrice} />
      ),
      onFilter: (value: any, record) => {
        const [min, max] = value.split(" ");
        return record.price > +min && record.price < +max;
      },
    },
    {
      title: "Discount",
      dataIndex: "percentOff",
      key: "discount",
      render: (val: number) => <>{(val || 0).toFixed(2) + "%"}</>,
      sorter: (a, b) => a.percentOff - b.percentOff,
      filterDropdown: (props) => (
        <SliderFilterDropdown {...props} name="Discount" />
      ),
      onFilter: (value: any, record) => {
        const [min, max] = value.split(" ");
        return record.percentOff > +min && record.percentOff < +max;
      },
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (val: string) => <>{formatDate(val)}</>,
      sorter: (a, b) => a.timestamp.diff(b.timestamp),
      filterDropdown: (props) => (
        <DateRangeFilterDropdown
          dateRange={dateRange}
          setDateRange={setDateRange}
          {...props}
        />
      ),
      onFilter: (_, record) => {
        const [start, end] = dateRange!;
        return (
          record.timestamp.isSameOrAfter(moment(start.toISOString()), "day") &&
          record.timestamp.isSameOrBefore(moment(end.toISOString()), "day")
        );
      },
      defaultSortOrder: sortTimestamps ? "descend" : null,
    },
    {
      title: "Morning",
      dataIndex: "morning",
      key: "morning",
      render: (value) =>
        value ? (
          <CheckOutlined style={{ width: "100%" }} />
        ) : (
          <CloseOutlined style={{ width: "100%" }} />
        ),
      filters: [
        { text: "morning", value: true },
        { text: "afternoon", value: false },
      ],
      onFilter: (value, record) => record.morning === value,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "changed", value: "changed" },
        { text: "new", value: "new" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (value) =>
        value === "changed" ? (
          <Tooltip title="Price dropped">
            <FallOutlined style={{ width: "100%" }} />
          </Tooltip>
        ) : value === "new" ? (
          <Tooltip title="New product">
            <BulbOutlined style={{ width: "100%" }} />
          </Tooltip>
        ) : value === "reappeared" ? (
          <Tooltip title="Product reappeared">
            <RollbackOutlined style={{ width: "100%" }} />
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      title: "In Last Check",
      dataIndex: "inLastCheck",
      key: "inLastCheck",
      render: (value) =>
        value ? (
          <CheckOutlined style={{ width: "100%" }} />
        ) : (
          <CloseOutlined style={{ width: "100%" }} />
        ),
      filters: [
        { text: "present", value: true },
        { text: "absent", value: false },
      ],
      onFilter: (value, record) => record.inLastCheck === value,
    },
  ];

  if (expanded) {
    columns.push(
      {
        title: "ASIN",
        dataIndex: "asin",
        key: "asin",
        render: (text: string) => (
          <a rel="noopener noreferrer" href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">{text}</a>
      ),
      }
    );
    columns.push(
      {
        title: "SalesRank",
        dataIndex: "salesRank",
        key: "salesRank",
        sorter: (a, b) => a.salesRank! - b.salesRank!,
        filterDropdown: (props) => (
          <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={9999999} />
        ),
        onFilter: (value: any, record) => {
          const [min, max] = value.split(" ");
          return (record.salesRank!) > +min && (record.salesRank!) < +max;
        },
      }
    );
    columns.push(
      {
        title: "AMZPrice",
        dataIndex: "amzPrice",
        key: "amzPrice",
        render: (val: number) => <>{val ? "$" + val : "--"}</>,
        filterDropdown: (props) => (
          <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={9999999} />
        ),
        onFilter: (value: any, record) => {
          const [min, max] = value.split(" ");
          return (record.amzPrice!) > +min && (record.amzPrice!) < +max;
        },
      }
    );
  }

  if (showSite) {
    columns.push({
      title: "Site",
      dataIndex: "site",
      key: "site",
      filters: Array.from(
        new Set(data.map((item) => item.site || ""))
      ).map((site) => ({ text: site.toUpperCase(), value: site })),
      onFilter: (value, record) => record.site === value,
    });
  }

  if (data[0] && data[0].productUrl.includes("nike.com")) {
    columns.push({
      title: "AZ",
      dataIndex: "productUrl",
      key: "productUrl",
      render: (value) => {
        return <a rel="noopener noreferrer" target="_blank" href={`https://www.amazon.com/s?k=${value.split("/").pop().split("-")[0]}+Nike&ref=nb_sb_noss`}>AZ</a>
      }
    });
  }

  return (
    <Table
      loading={loading}
      scroll={{ y: "80vh" }}
      sticky={true}
      bordered={true}
      rowClassName={(record, index) => (record.lowStock ? "warning-row" : "")}
      dataSource={data}
      columns={columns}
      tableLayout="fixed"
      pagination={{
        pageSizeOptions: ["100", "500", "1000"],
        defaultPageSize: 100,
        onChange: () => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        },
      }}
      onChange={(pagination, filters, sorter, extra) => {
        if(changeFilteredData) changeFilteredData(extra.currentDataSource)
      }}
    />
  );
};

export default DataTable;
