import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import qs from "qs";

const DEVELOPMENT_CALL = process.env.NODE_ENV === "development";
const PS_DOMAIN = process.env.REACT_APP_PS_DOMAIN;
export const BASE_URL = PS_DOMAIN ? PS_DOMAIN : DEVELOPMENT_CALL ? "http://localhost:3001" : "https://api.projectsuite.io";

export const BASE_URL_V2 = DEVELOPMENT_CALL ? "http://localhost:3002" : "https://v2.api.repricer.projectsuite.io";

export interface RequestExtraParams {
    devMode?: boolean;
    [key: string]: any;
}

export function getIdToken() {
    const user = firebase.auth().currentUser;
    if (!user) {
        throw new Error("No user found");
    }
    return user.getIdToken();
}

export const handleError = async (response: Response) => {
    const res = await response.json();
    if (!response.ok) {
        throw Error(res.error || response.statusText);
    } else {
        return res;
    }
};

export const sleep = (t: number) => new Promise((resolve) => setTimeout(resolve, t));

export async function makeCall(
    path: string,
    request: RequestExtraParams,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body: any = undefined,
    version: "v1" | "v2" = "v1"
): Promise<any> {
    const token = await getIdToken();

    const params: {[key: string]: any} = request;

    if (version === "v1") {
        params.firebase_token = token;
    } else {
        params.token = token;
    }

    if ((params.devMode === undefined && process.env.NODE_ENV === "development") || params.devMode) {
        params.devMode = 1;
    } else {
        delete params.devMode;
    }

    const url = version === "v1" ? BASE_URL : BASE_URL_V2;

    const res = await fetch(`${url}/${path}?${qs.stringify(params)}`, {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: body ? JSON.stringify(body) : undefined,
    }).then(handleError);

    return res;
}
