import {message} from "antd";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {downloadHelper} from "./Downloads";
import {requestBackendKeepa} from "../../../services/WholesaleService";
import {getIdToken} from "../../../services/common";
import {fullKeepaAnalysis} from "../KeepaCalcs";
import {KeepaProduct} from "../../../types/KeepaTypes";

export const processDataKeepaNewItemsHelper = (oldItemsData: ArrayBuffer[], newItemsData: ArrayBuffer[]) => {
    console.log("processing");

    try {
        let fullInput: any[] = [];
        let fullOutput: any[] = [];
        for (const input of oldItemsData) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData);

            console.log(keepaFrame.first());

            fullInput = fullInput.concat(keepaFrame.toArray());
        }

        for (const input of newItemsData) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData);

            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        const result = fullOutput.filter((row) => !fullInput.some((row2) => row2["ASIN"] === row["ASIN"]));

        let chunkSize = 10000;
        for (let i = 0; i <= result.length; i += chunkSize) {
            const chunk = result.slice(i, i + chunkSize);
            downloadHelper(chunk, `${dayjs().format("MM-DD-YYYY")}-Keepa-NewItems-Result-${Math.floor(i / chunkSize) + 1}`);
        }
    } catch (ex) {
        message.error("Failed to process! Please send file to Karol.");
    }
};

export const processDataKeepaFilterHelper = (data: ArrayBuffer[]) => {
    console.log("processing");

    try {
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge
                .fromCSV(jnputData)
                .parseInts(["Sales Rank: Current", "Amazon: 90 days OOS", "Reviews: Review Count"]);

            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        const result = fullOutput.filter(
            (row) =>
                (row["New: Current"] === "" && row["Reviews: Review Count"] > 10) ||
                (row["Sales Rank: Current"] <= 800000 && row["Reviews: Review Count"] > 20)
        );

        let chunkSize = 10000;
        for (let i = 0; i <= result.length; i += chunkSize) {
            const chunk = result.slice(i, i + chunkSize);
            downloadHelper(chunk, `${dayjs().format("MM-DD-YYYY")}-Keepa-Result-${Math.floor(i / chunkSize) + 1}`);
        }
    } catch (ex) {
        message.error("Failed to process! Please send file to Karol.");
    }
};

export const processDataKeepaBBHelper = async (data: ArrayBuffer[]) => {
    try {
        // read all the files
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData).subset(["ASIN", "asin"]);
            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        // get unique ASINs
        const uniqueASINs = Array.from(new Set(fullOutput.map((row) => row["ASIN"] ?? row["asin"]))).slice(0, 200);

        // get all the ASINs
        const token = await getIdToken();
        const keepaData = await requestBackendKeepa(token, uniqueASINs);

        const analyzed = Object.values(keepaData.result).map((row: any) => {
            return fullKeepaAnalysis(row);
        });

        console.log("analyzed", analyzed);

        const result = analyzed.map((product) => {
            return {
                asin: product.asin,
                mostOftenWon30D: product.mostOftenWon30D[0],
                mostOftenWon30DShare: (product.mostOftenWon30D[1] * 100).toFixed(2),
                mostOftenWon365D: product.mostOftenWon365D[0],
                mostOftenWon365DShare: (product.mostOftenWon365D[1] * 100).toFixed(2),
                medianBBPrice30D: product.bbSharePredictor.buyBoxPriceHistoryPast30DaysMedian,
                medianBBPrice365D: product.bbSharePredictor.buyBoxPriceHistoryPast365DaysMedian,
            };
        });

        downloadHelper(result, `${dayjs().format("MM-DD-YYYY")}-Keepa_BB-Result`);
    } catch (ex) {
        console.log(ex);
        message.error("Failed to process! Please send file to Karol.");
    }
};
