import { CrownOutlined } from "@ant-design/icons";
import { Card, List } from "antd";
import React from "react";
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';

const StockPopover: React.FC<WholesaleItem> = React.memo((record: WholesaleItem) => {
    const stockData = record.StockData
    console.log('Stock Data!', stockData)
    return (
        <Card size="default">
            <List
                size="default"
                bordered
                dataSource={stockData}
                renderItem={item => <List.Item>{item.isAmazon ? <CrownOutlined style={{color: 'yellow'}}  /> : (item.isFBA ? '[FBA]' : '[FBM]')}{` ${item.sellerId} : $${item.offerPrice} (${item.freshStock})`}</List.Item>}
            />
        </Card>
    )
})

export default StockPopover;