import { Col, Row, Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import BrandBrowser from '../components/omega/BrandBrowser';
import SingleBrowser from '../components/omega/SingleBrowser';

const Omega: React.FC = () => {
    const [forceKey, setForceKey] = useState('0');

    const resetQueries = () => {
        window.history.replaceState(null, `Project Sourcing`, `/omega`);
        setForceKey('');
        console.log('Reset queries due to tab change!');
    };

    useEffect(() => {
        const localAsin = new URLSearchParams(
            window.location.href.split('?')[1]
        ).get('asin');
        const localSku = new URLSearchParams(
            window.location.href.split('?')[1]
        ).get('sku');
        if (localAsin || localSku) {
            setForceKey('2');
        } else {
            setForceKey('1');
        }
    }, []);

    const items: TabsProps['items'] = [
        {
            key: '0',
            label: null,
            children: <></>,
        },
        {
            key: '1',
            label: `Main Browser`,
            children: <BrandBrowser></BrandBrowser>,
        },
        {
            key: '2',
            label: `Single Browser`,
            children: <SingleBrowser></SingleBrowser>,
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    destroyInactiveTabPane={true}
                    onChange={resetQueries}
                    items={items}
                    {...(forceKey ? { activeKey: forceKey } : {})}
                ></Tabs>
            </Col>
        </Row>
    );
};

export default Omega;
