import React, { useState, useEffect } from "react";
import DataTable from "../components/sourcing/DataTable";
import { getCollections, getExpandedCollections, getProducts } from "../services/ProductsService";
import CollectionsDropdown from "../components/utilities/CollectionsDropdown";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BrandFilter,
  BrandSpecificFilters,
} from "../components/utilities/BrandSpecificFilters";
import { Item } from "../types/Item";
import { CSVDownload } from "../components/utilities/CSVDownload";
import { Button } from "antd";

const Sourcing: React.FC = () => {
  const [collection, setCollection] = useState<string>("");
  const [expanded, setExpanded] = useState<boolean>(false)
  const { data, isLoading } = useQuery({
    queryKey: [collection],
    queryFn: ({ queryKey }) => {
      const col = queryKey[0] as string;
      return col ? getProducts(col, expanded) : null
    }
  });
  const [filteredData, setFilteredData] = useState<Item[]>([]);
  const [exportData, setExportData] = useState<Item[]>([]);
  const [filters, setFilters] = useState<BrandFilter[]>([]);
  const queryClient = useQueryClient()

  const setFiltersWithLocalStorage = (filters: BrandFilter[]) => {
    setFilters(filters);
    window.localStorage.setItem(
      `${collection}_filters`,
      JSON.stringify(filters)
    );
  };

  useEffect(() => {
    const filtersStr = window.localStorage.getItem(`${collection}_filters`);
    if (filtersStr) {
      setFilters(JSON.parse(filtersStr));
    } else if (
      [
        "dickssportinggoods",
        "eastbay",
        "champssports",
        "finalscore",
        "footaction",
        "footlocker",
      ].includes(collection)
    ) {
      setFilters([
        { brand: "Nike", minDiscount: 19 },
        { brand: "", minDiscount: 24 },
      ]);
    } else {
      setFilters([]);
    }
  }, [collection]);

  useEffect(() => {
    let appendedFilters = filters;
    console.log('Current filters:', filters)
    if (!filters.find((filter) => filter.brand === ""))
      appendedFilters = [...appendedFilters, { brand: "", minDiscount: -1 }];
    setFilteredData(
      data?.filter((item) =>
        appendedFilters
          .map(({ brand, minDiscount }) =>
            item.name?.toLowerCase().includes(brand.toLowerCase())
              ? item.percentOff >= minDiscount
              : true
          )
          .reduce((a, b) => a && b, true)
      ) || []
    );
  }, [filters, data]);

  useEffect(() => {
    setExportData(filteredData)
  }, [filteredData])

  useEffect(() => {
    console.log('invalidating');
    queryClient.invalidateQueries({ queryKey: ['collections'] });
  }, [expanded, queryClient])

  return (
    <>
      <div style={{ display: "flex" }}>
        <CollectionsDropdown queryKey={expanded ? "expandedSourcing" : "defaultSourcing"} onChange={(x) => setCollection(x)} getCollections={() => expanded ? getExpandedCollections() : getCollections()} />
        <CSVDownload
          data={exportData}
          isLoading={isLoading}
          collection={collection}
        />
        <Button onClick={() => setExpanded((expanded) => !expanded)} >{expanded ? 'Simple Mode' : 'Expert Mode'}</Button>
      </div>
      <BrandSpecificFilters
        filters={filters}
        setFilters={setFiltersWithLocalStorage}
      />
      <DataTable expanded={expanded} loading={isLoading} data={filteredData} changeFilteredData={setExportData} />
    </>
  );
};

export default Sourcing;
