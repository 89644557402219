import { UploadOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { App, Button, Card, Col, Divider, Input, Row, Select, Space, Statistic, Table, Tag, Tooltip, Upload, Switch, Progress, Modal } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { ColumnsType } from "antd/es/table";
import Link from "antd/es/typography/Link";
import { RcFile } from "antd/es/upload";
import * as dataForge from 'data-forge';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getBrandInsightUploads, getBrandInsights, saveParentProducts } from "../../services/BrandService";
import { getKeepa } from "../../services/KeepaService";
import { getOrderSummariesForIDs } from "../../services/OmegaService";
import { getAggregatedWholesaleData, getExperimentalInventory } from "../../services/WholesaleService";
import { KeepaProduct } from "../../types/KeepaTypes";
import { OrderSummaryByDaysAggData } from "../../types/OmegaTypes";
import { InventoryItem } from "../../types/WholesaleItem";
import { findSales } from "../brand/AnalyzeData";
import { GetExpandedFilter } from "../utilities/ExpandedFilterDropdown";
import NotesRenderer, { ExpandedNote } from "../utilities/NotesRenderer";
import { getNumberFilterSorter, getNumberRenderer, getPriceRenderer, getSellPriceRenderer, getShareRenderer } from "../utilities/TableFilterSorters";
import { downloadHelper, excelDownloadHelper } from "../utilities/helpers/Downloads";
import { filterTableData } from "../wholesale/SeparatedTable";
import { getActualCategory } from "./utilities/insightCalcs";
import ExcelJS from "exceljs";
import { autoWidth } from "../wholesale/ExcelDownload";
import { KeepaApiKey } from "../../services/constants";
import { sleep } from "../../services/common";

const expandedRowRendererOurs = (message: MessageInstance, updateTableData: (record: KeepaProduct) => void, record: KeepaProduct, index: number, indent: number, expanded: boolean) => {
    const expandedColumns: ColumnsType<KeepaProduct> = [
        {
            title: 'ASIN',
            dataIndex: 'asin',
            key: 'asin',
            width: "100px",
            fixed: "left",
            ...GetExpandedFilter([], 'asin'),
            render: (text: string, record: KeepaProduct) => (
                <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                    {text}
                </Link>
            ),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if ((event.target as HTMLElement).nodeName.includes("TD")) {
                            navigator.clipboard.writeText(record.asin!).then((x) => {
                                message.success('ASIN copied!')
                            }).catch((err) => {
                                console.log('failed copy', err)
                                message.error('Failed to copy ASIN!')
                            })
                        }
                    }
                }
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: "250px",
            sorter: {
                compare: (a, b) => a.title!.localeCompare(b.title!),
                multiple: 1
            },
            ...GetExpandedFilter([], 'title'),
        },
        {
            title: <Tooltip trigger='hover' title="An approximation based on the BuyBox price in the past 30 days">SellPrice</Tooltip>,
            dataIndex: 'sellPrice',
            key: 'sellPrice',
            width: "95px",
            ...getNumberFilterSorter('sellPrice'),
            ...getSellPriceRenderer(),
        },
        {
            title: 'Offers',
            key: 'totalOfferCount',
            dataIndex: 'totalOfferCount',
            width: "85px",
            ...getNumberFilterSorter('totalOfferCount'),
            ...getNumberRenderer(),
        },
        {
            title: <Tooltip trigger='hover' title="Total parent's ratings (usually)">Ratings</Tooltip>,
            key: 'reviews',
            dataIndex: 'reviews',
            width: "85px",
            ...getNumberFilterSorter('reviews'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="Our currently available inventory">Stock</Tooltip>,
            key: 'ourInventory',
            dataIndex: 'ourInventory',
            width: "85px",
            defaultSortOrder: 'descend',
            ...getNumberFilterSorter('ourInventory'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="How long (out of the past 30 days) we have been in stock">DIS</Tooltip>,
            key: 'DIS',
            dataIndex: 'DIS',
            width: "85px",
            ...getNumberFilterSorter('DIS'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="Individual variation's reviews - requires Keepa upload">Ratings (Format)</Tooltip>,
            key: 'reviewsFormat',
            dataIndex: 'reviewsFormat',
            width: "85px",
            ...getNumberFilterSorter('reviewsFormat'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="How much we expect to sell over a month - requires Keepa upload">Expected Sales</Tooltip>,
            key: 'expectedSales',
            dataIndex: 'expectedSales',
            width: "85px",
            ...getNumberFilterSorter('expectedSales'),
            ...getPriceRenderer(),
        },
        {
            title: 'Our Sales',
            key: 'ourSales',
            dataIndex: 'ourSales',
            width: "85px",
            ...getNumberFilterSorter('ourSales'),
            ...getPriceRenderer(),
        },
        {
            title: 'Issues',
            key: 'Issues',
            dataIndex: 'Issues',
            width: "85px",
            ...GetExpandedFilter(BRAND_POSSIBLE_ISSUES, 'Issues'),
            render: (issues) =>
                <Space direction="vertical" align="center" style={{ width: "100%" }}>
                    {issues?.map((issue: string, idx: number) => (
                        <Tag style={{ marginRight: 0 }} key={idx}>
                            {issue.toUpperCase()}
                        </Tag>
                    ))}
                </Space>
        },
        {
            title: 'Comments',
            key: 'comments',
            dataIndex: 'comments',
            width: "325px",
            ...GetExpandedFilter([], 'comments', (record) => record.comments.filter((row: any) => typeof row === 'object').map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join(',')),
            render: (value, childRecord) => (
                <NotesRenderer
                    editable={true}
                    notes={value}
                    onFinish={notes => {
                        const newChildRecord = {
                            ...childRecord,
                            comments: notes,
                        };

                        const parentRecord = {
                            ...record,
                            childrenVars: record.childrenVars.map((item: any) => {
                                if (item.asin === childRecord.asin) {
                                    return newChildRecord
                                } else {
                                    return item
                                }
                            })
                        }

                        updateTableData(parentRecord)
                    }}
                />
            ),
        },
    ];

    return <Table
        sticky
        size="small"
        tableLayout={"fixed"}
        columns={expandedColumns}
        dataSource={record.childrenVars}
        key={index}
        rowKey={(record) => `${record.key}-${record.asin}`}
    />;
};

const getColumns = (message: MessageInstance, updateTableData: (record: KeepaProduct) => void): any[] => {
    const columns: ColumnsType<KeepaProduct> = [
        {
            title: 'Parent ASIN',
            dataIndex: 'asin',
            key: 'asin',
            width: "90px",
            fixed: "left",
            ...GetExpandedFilter([], 'asin'),
            render: (text: string, record: KeepaProduct) => (
                <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                    {text}
                </Link>
            ),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if ((event.target as HTMLElement).nodeName.includes("TD")) {
                            navigator.clipboard.writeText(record.asin!).then((x) => {
                                message.success('ASIN copied!')
                            }).catch((err) => {
                                console.log('failed copy', err)
                                message.error('Failed to copy ASIN!')
                            })
                        }
                    }
                }
            }
        },
        {
            title: () => <Tooltip placement="top" title={"Title of a random variation"}>Sample Title</Tooltip>,
            dataIndex: 'title',
            key: 'title',
            width: "275px",
            sorter: {
                compare: (a, b) => a.title!.localeCompare(b.title!),
                multiple: 1
            },
            ...GetExpandedFilter([], 'title'),
        },
        {
            title: () => <Tooltip title="Average SellPrice of this parent's variations">SellPrice</Tooltip>,
            dataIndex: 'sellPrice',
            key: 'sellPrice',
            width: "95px",
            ...getNumberFilterSorter('sellPrice'),
            ...getSellPriceRenderer(),
        },
        {
            title: <Tooltip title="How many units this parent will sell in 30 days at the current (averaged) SalesRank">Est. Unit Sales</Tooltip>,
            key: 'estSalesTotal',
            dataIndex: 'estSalesTotal',
            width: "85px",
            ...getNumberFilterSorter('estSalesTotal'),
            ...getNumberRenderer(2),
        },
        // {
        //     title: 'Est. Sales (BB)',
        //     key: 'estSalesBBTotal',
        //     dataIndex: 'estSalesBBTotal',
        //     width: "85px",
        //     ...getNumberFilterSorter('estSalesBBTotal'),
        //     ...getNumberRenderer(2),
        // },
        {
            title: <Tooltip trigger='hover' title="How many units of this parent we have sold in the past 30 days">Our Unit Sales</Tooltip>,
            key: 'ourUnitSales',
            dataIndex: 'ourUnitSales',
            width: "85px",
            ...getNumberFilterSorter('ourUnitSales'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="How much value we expect this parent to move over 30 days">Est. Sales</Tooltip>,
            key: 'estSalesValue',
            dataIndex: 'estSalesValue',
            width: "85px",
            defaultSortOrder: 'descend',
            ...getNumberFilterSorter('estSalesValue'),
            ...getPriceRenderer(),
        },
        {
            title: <Tooltip trigger='hover' title="How much we have sold in the past 30 days">Our Sales</Tooltip>,
            key: 'ourSales',
            dataIndex: 'ourSales',
            width: "85px",
            ...getNumberFilterSorter('ourSales'),
            ...getPriceRenderer(),
        },
        {
            title: <Tooltip trigger='hover' title="Our share of estimated sales (by value)">Market Share</Tooltip>,
            key: 'marketShare',
            dataIndex: 'marketShare',
            width: "85px",
            ...getNumberFilterSorter('marketShare'),
            ...getShareRenderer(2),
        },
        {
            title: 'Ratings',
            key: 'reviews',
            dataIndex: 'reviews',
            width: "85px",
            ...getNumberFilterSorter('reviews'),
            ...getNumberRenderer(0),
        },
        {
            title: <Tooltip trigger='hover' title="How many variations we stock out of the currently available ones">Vars</Tooltip>,
            key: 'variationCount',
            dataIndex: 'variationCount',
            width: "85px",
            ...getNumberFilterSorter('variationCount'),
            render: (val, record) => val === undefined || val === null || val === -1 ? '?' : `${record.ourVarCount}/${val}`
        },
        {
            title: 'Issues',
            key: 'Issues',
            dataIndex: 'Issues',
            width: "85px",
            ...GetExpandedFilter(BRAND_POSSIBLE_ISSUES, 'Issues'),
            render: (issues) =>
                <Space direction="vertical" align="center" style={{ width: "100%" }}>
                    {issues?.map((issue: string, idx: number) => (
                        <Tag style={{ marginRight: 0 }} key={idx}>
                            {issue.toUpperCase()}
                        </Tag>
                    ))}
                </Space>
        },
        {
            title: 'Keepa',
            key: 'keepaGraph',
            dataIndex: 'keepaGraph',
            width: "300px",
            render: (text: string, record) => {
                // const asinToDisplay = record.isParent ? [...record.childrenVars].sort((a, b) => {
                //     // if reviewsFormat exists, sort by it, otherwise sort by reviews
                //     const aReviews = a.reviewsFormat > 0 ? a.reviewsFormat : a.reviews
                //     const bReviews = b.reviewsFormat > 0 ? b.reviewsFormat : b.reviews
                //     return bReviews - aReviews
                // })[0].asin : record.asin
                const asinsWithFormatReviews = [...record.childrenVars].filter((item) => item.reviewsFormat > 0).sort((a, b) => b.reviewsFormat - a.reviewsFormat).map((item) => item.asin)
                const asinsWithReviews = [...record.childrenVars].sort((a, b) => b.reviews - a.reviews).map((item) => item.asin)

                let asinToDisplay = ''
                if (asinsWithFormatReviews.length > 0) {
                    asinToDisplay = asinsWithFormatReviews[0]
                } else {
                    asinToDisplay = asinsWithReviews[0]
                }

                return <a target="_blank" rel="noopener noreferrer" href={`https://keepa.com/#!product/1-${asinToDisplay}`}><img src={`https://graph.keepa.com/pricehistory.png?domain=com&bb=1&salesrank=1&asin=${asinToDisplay}&range=365`} height={125} width={325} alt='Keepa graph' /></a>
            },
        },
        {
            title: 'Comments',
            key: 'comments',
            dataIndex: 'comments',
            width: "325px",
            ...GetExpandedFilter([], 'comments', (record) => record.comments.filter((row: any) => typeof row === 'object').map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join(',')),
            render: (value, record) => (
                <NotesRenderer
                    editable={true}
                    notes={value}
                    onFinish={notes => {
                        const newRecord = {
                            ...record,
                            comments: notes,
                        };

                        updateTableData(newRecord)
                    }}
                />
            ),
        },
    ]

    return columns
}

const transformKeepaProducts = (products: KeepaProduct[]) => {
    console.log('transforming', products)
    const getCalcRank = (item: KeepaProduct) => {
        return item.stats?.avg30[3] > 0 && item.stats?.current[3] > 0 ?
            (item.stats.avg30[3] * 0.5 + item.stats.current[3] * 0.5) :
            (item.stats?.current[3] > 0 ? item.stats.current[3] :
                (item.stats?.avg30[3] > 0 ? item.stats.avg30[3] :
                    -1))
    }

    const setBBInfo = (item: KeepaProduct) => {
        let current = item.stats.current[18] > 0 ? item.stats.current[18] / 100 : -1;
        let avg30 = item.stats.avg30[18] > 0 ? item.stats.avg30[18] / 100 : -1;
        let avg90 = item.stats.avg90[18] > 0 ? item.stats.avg90[18] / 100 : -1;

        item.currentBuyBoxPrice = current;
        item.buyBox30Avg = avg30;
        item.buyBox90Avg = avg90;

        if (item?.stats) {
            item.sellPrice = 0
            const currentBuyBoxPrice = Math.max(item.stats.current[18], 0) / 100;
            if (item.stats.avg30[18] > 0 && currentBuyBoxPrice > 0) {
                item.sellPrice = (currentBuyBoxPrice + (item.stats.avg30[18] / 100)) / 2
            } else if (item.stats.avg30[18] > 0) {
                item.sellPrice = item.stats.avg30[18] / 100
            } else if (item.stats.avg90[18] > 0) {
                item.sellPrice = item.stats.avg90[18] / 100
            } else if (currentBuyBoxPrice > 0) {
                item.sellPrice = currentBuyBoxPrice
            } else {
                item.sellPrice = -1
            }

            if (currentBuyBoxPrice && item.sellPrice > currentBuyBoxPrice) {
                item.sellPrice = currentBuyBoxPrice
            }

        } else {
            item.sellPrice = -1;
        }

        if (item.stats.buyBoxPrice > 0 || item.stats.buyBoxSellerId?.length > 0) { // bb exists
            if (item.stats.buyBoxCondition !== 0 && item.stats.buyBoxCondition !== '0') { // bb is new
                if (item.stats.buyBoxIsAmazon) {
                    item.bbOwner = "Amazon";
                    item.bbMultiplier = 0.25;
                } else if (item.stats.buyBoxIsFBA) {
                    item.bbOwner = "FBA";
                    item.bbMultiplier = 0.50;
                } else {
                    item.bbOwner = "FBM";
                    item.bbMultiplier = 0.75;
                }
            }
        } else {
            item.bbOwner = "None";
            item.bbMultiplier = 1;
        }

        return item
    }

    return products.map((mainItem) => {
        return {
            ...setBBInfo(mainItem),
            rootCategory: getActualCategory(mainItem),
            salesRankReference: getActualCategory(mainItem),
            reviews: Math.max(mainItem.stats?.current[17] || 0, 0),
            reviews90Avg: Math.max(mainItem.stats?.avg90[17] || 0, 0),
            azImg: `https://images-na.ssl-images-amazon.com/images/I/${mainItem.imagesCSV?.split(',')[0]}`,
            totalOfferCount: mainItem.stats?.totalOfferCount,
            calcRank: getCalcRank(mainItem),
        }
    }).map((mainItem: any, idx) => {
        const dropSales = mainItem.stats.salesRankDrops30 * 0.625 + (mainItem.stats.salesRankDrops90 / 3) * 0.375
        const rankSales = Math.max(findSales(mainItem), 0)
        console.log(mainItem, mainItem.rootCategory)
        const usingDrops = mainItem.rootCategory.includes('Baby') ?
            mainItem.stats.salesRankDrops30 < 250
            :
            mainItem.stats.salesRankDrops30 < 15 || mainItem.stats.salesRankDrops90 < 50

        return {
            ...mainItem,
            dropSales: dropSales,
            rankSales: rankSales,
            usingDrops: usingDrops,
            key: idx,
        }
    }).map((mainItem: any) => {
        return {
            ...mainItem,
            estSalesTotal: mainItem.usingDrops ?
                mainItem.dropSales
                :
                mainItem.rankSales
            ,
            estSalesNoBB: mainItem.usingDrops ? mainItem.dropSales : (mainItem.rankSales > 0 ? mainItem.rankSales * (1 / mainItem.variations?.length || 1) : 0),
            estSales: mainItem.usingDrops ? mainItem.dropSales : (mainItem.rankSales > 0 ? mainItem.rankSales * mainItem.bbMultiplier * (1 / mainItem.variations?.length || 1) : 0),
        }
    }).map((mainItem: any) => {
        return {
            ...mainItem,
            estSalesNoBBValue: mainItem.sellPrice > 0 ? mainItem.estSalesNoBB * mainItem.sellPrice : 0,
        }
    })
}

const appendOrderSummaries = (products: KeepaProduct[], orderSummaries: OrderSummaryByDaysAggData[]) => {
    return products.map((item) => {
        const orderSummary = orderSummaries.find((order) => order.asin === item.asin)
        return {
            ...item,
            ourSales: orderSummary?.d30?.value || 0,
            ourUnitSales: orderSummary?.d30?.units || 0,
        }
    })
}

const appendInventoryLevels = (products: KeepaProduct[], inventory: InventoryItem[]) => {
    return products.map((item) => {
        const ourInventory = inventory.filter((inventory) => inventory.asin === item.asin).reduce((acc, curr) =>
            acc += (
                parseInt(curr["afn-fulfillable-quantity"] || "0")
                + parseInt(curr["mfn-fulfillable-quantity"] || "0")
                + parseInt(curr["afn-inbound-receiving-quantity"])
                + parseInt(curr["afn-inbound-shipped-quantity"])
                + parseInt(curr["afn-inbound-working-quantity"])
            ), 0)
        return {
            ...item,
            ourInventory: ourInventory,
        }
    })
}

const appendAggWholesaleData = (products: KeepaProduct[], aggWholesaleData: any[]) => {
    return products.map((item) => {
        const aggWholesaleItem = aggWholesaleData.find((aggWholesaleItem) => aggWholesaleItem.asin === item.asin)
        return {
            ...item,
            DIS: aggWholesaleItem?.days_in_stock || 0,
        }
    })
}

const prepareForWSExport = (products: any[]) => {
    let fullAsinList: string[] = [];
    const mappedProducts = products.map((parentItem) => parentItem.childrenVars.map((item: any) => {
        if (item.variationCSV) {
            fullAsinList.push(...item.variationCSV.split(','))
        } else {
            fullAsinList.push(item.asin)
        }

        return {
            'ParentAsin': item.parentAsin ?? item.asin,
            'ASIN': item.asin,
            'Title': item.title,
            'EstSales': item.estSalesNoBBValue.toFixed(2),
            'OurSales': item.ourSales.toFixed(2),
            'MarketShare': item.sellPrice > 0 && item.ourSales > 0 ? ((item.ourSales / item.estSalesNoBBValue) * 100).toFixed(2) : 0,
            'VarsAll': parentItem.variationCount,
            'VarsStocked': parentItem.ourVarCount,
            'ReviewsGrabbed': item.reviewsFormat > -1 ? 'YES' : 'NO',
            'Comments': [...(item.comments ?? []), ...(parentItem.comments ?? [])].filter((row: any) => typeof row === 'object').map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join('\n'),
            'SupplierName': '',
            'SKU': '',
            'Style': '',
            'OH': '',
            'Retail Price': '',
            'Price': '',
            'Cost': '',
            'MAP': '',
            'Ship Date': '',
        }
    })).flat().sort((a, b) => b.EstSales - a.EstSales)

    return [mappedProducts, Array.from(new Set(fullAsinList))]
}

const prepareForExcelExport = (products: any[]) => {
    const resultWorkbook = new ExcelJS.Workbook();
    const masterSheet = resultWorkbook.addWorksheet('Master Sheet');
    const parentSheet = resultWorkbook.addWorksheet('Parent Sheet');
    const childSheet = resultWorkbook.addWorksheet('Child Sheet');

    const headers = [
        'ParentAsin',
        'ASIN',
        'Title',
        'UnitEstSales',
        'ValueEstSales',
        'OurSales',
        'MarketShare',
        'VarsAll',
        'VarsStocked',
        'ReviewsGrabbed',
        'Comments',
    ]

    masterSheet.addRow(headers);
    parentSheet.addRow(headers);
    childSheet.addRow(headers);

    ([...products]).sort((a, b) => b.estSalesValue - a.estSalesValue).forEach((parentItem) => {
        const childrenRows = [...parentItem.childrenVars].sort((a, b) => b.estSales - a.estSales).map((item: any) => {
            return {
                'ParentAsin': '',
                'ASIN': item.asin,
                'Title': item.title,
                'UnitEstSales': item.estSalesNoBB,
                'ValueEstSales': item.estSalesNoBBValue,
                'OurSales': item.ourSales,
                'MarketShare': (item.sellPrice > 0 && item.ourSales > 0 ? (item.estSales > 0 ? (item.ourSales / (item.estSales * item.sellPrice)) : 1) : 0),
                'VarsAll': parentItem.variationCount,
                'VarsStocked': parentItem.ourVarCount,
                'ReviewsGrabbed': item.reviewsFormat > -1,
                'Comments': (item.comments ?? []).filter((row: any) => typeof row === 'object').map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join('\n'),
            }
        });

        const parentRow = [
            parentItem.asin,
            '',
            parentItem.title,
            parentItem.estSalesTotal,
            parentItem.estSalesValue,
            parentItem.ourSales,
            parentItem.marketShare / 100,
            parentItem.variationCount,
            parentItem.ourVarCount,
            '',
            [...(parentItem.comments ?? [])].filter((row: any) => typeof row === 'object').map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join('\n'),
        ]

        masterSheet.addRow(parentRow)
        parentSheet.addRow(parentRow)
        childrenRows.forEach((row) => {
            masterSheet.addRow(Object.values(row));
            childSheet.addRow(Object.values(row));
        })
    })

    masterSheet.getColumn(4).numFmt = '#0.00'
    masterSheet.getColumn(5).numFmt = '$#,##0.00'
    masterSheet.getColumn(6).numFmt = '$#,##0.00'
    masterSheet.getColumn(7).numFmt = '0%'

    autoWidth(masterSheet, 5, 50)
    autoWidth(parentSheet, 5, 50)
    autoWidth(childSheet, 5, 50)

    return resultWorkbook
}

const BRAND_POSSIBLE_ISSUES = [
    'Replen',
    'Not Stocked',
    'NoRank',
    'Orphaned (?)'
]

const analyzeIssues = (products: KeepaProduct[]) => {
    return products.map((item) => {
        const issues = [];

        if (item.ourInventory === 0 && item.ourUnitSales > 0) {
            issues.push('Replen')
        }

        if (item.DIS === 0 && item.ourInventory === 0 && item.ourUnitSales === 0 && item.estSales > 1) {
            issues.push('Not Stocked')
        }

        
        if (
            item.estSalesTotal <= 0 && 
            item.reviews > 25
            && item.reviews > 1.01 * item.reviews90Avg

        ) {
            issues.push('NoRank');
        }

        if (
            item.reviews > 0 && item.reviews90Avg > 0 && item.reviews <= (0.75 * item.reviews90Avg)
        ) {
            issues.push('Orphaned (?)');
        }

        return {
            ...item,
            Issues: issues,
        }
    })
}

const GetAsinList = async (supplier: string, singleVar: boolean, includeTitle: boolean): Promise<string[]> => {
    const bodySample = {
        "avg30_COUNT_REVIEWS_gte": 10,
        // "lastRatingUpdate_gte": 6711525,
        'sort': [
            [
                'current_SALES',
                'asc',
            ],
        ],
        "singleVariation": singleVar,
        'productType': [
            0,
            1
        ],
        'perPage': 10000,
        'page': 0,

    };

    const brandBody = {
        ...bodySample,
        "brand": [
            supplier
        ],
    }

    const manufacturerBody = {
        ...bodySample,
        "brand": [
            `✜${supplier}`
        ],
        "manufacturer": [
            supplier
        ],
    }

    const promiseList = [
        fetch(`https://api.keepa.com/query?key=${KeepaApiKey}&domain=1`, {
            method: 'POST',
            body: JSON.stringify(brandBody),
        }).then((res) => res.json()),
        fetch(`https://api.keepa.com/query?key=${KeepaApiKey}&domain=1`, {
            method: 'POST',
            body: JSON.stringify(manufacturerBody),
        }).then((res) => res.json()),
    ]

    if (includeTitle) {
        const titleBody = {
            ...bodySample,
            "brand": [
                `✜${supplier}`
            ],
            "manufacturer": [
                `✜${supplier}`
            ],
            "title": supplier,
        }

        promiseList.push(fetch(`https://api.keepa.com/query?key=${KeepaApiKey}&domain=1`, {
            method: 'POST',
            body: JSON.stringify(titleBody),
        }).then((res) => res.json()))
    }

    const resList = await Promise.all(promiseList)

    return resList.map((res) => res.asinList).flat()
}

const BrandInsightsAnalyze: React.FC = () => {
    const { currentUser, userData } = useAuth();
    const { message } = App.useApp()
    const [progressPercents, setProgressPercents] = useState<number>(0)
    const [keepaTokens, setKeepaTokens] = useState<number>(-1)
    const [asinList, setAsinList] = useState<string[]>([])
    const [activeSupplier, setActiveSupplier] = useState('')
    const [selectedCollection, setSelectedCollection] = useState('')
    const [parentProducts, setParentProducts] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [singleVar, setSingleVar] = useState<boolean>(true);
    const [includeTitle, setIncludeTitle] = useState<boolean>(false);
    const [fileMap, setFileMap] = React.useState<{ keepaExport: RcFile | undefined }>({ keepaExport: undefined })
    const { data: brandInsightsCollections } = useQuery({
        queryKey: ['brandInsightsCollections'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const names = await getBrandInsightUploads(token)
            return names;
        },
        staleTime: 120
    })

    const beforeUpload = (file: RcFile) => {
        setFileMap((prev) => ({ ...prev, keepaExport: file }))
        return false;
    }

    const onRemove = () => {
        setFileMap({ keepaExport: undefined })
    }

    const updateData = useCallback((record: any) => {
        const newParentProducts = parentProducts.map((item) => {
            if (item.asin === record.asin) {
                return record
            } else {
                return item
            }
        })

        setParentProducts(newParentProducts)
        currentUser?.getIdToken().then((token) => {
            saveParentProducts(token, selectedCollection, [record]);
        })
    }, [currentUser, parentProducts, selectedCollection])

    const columns = useMemo(() => {
        return getColumns(message, updateData)
    }, [message, updateData])

    const pivotParents = async (products: any[], newCollectionName: string) => {
        const asinList = products.map((item) => item.asin)
        console.log(asinList)

        // pivot by parent
        const pivotedByParent = products.reduce((acc, curr) => {
            const parent = curr.parentAsin || curr.asin!
            if (parent in acc) {
                acc[parent].push(curr)
            } else {
                acc[parent] = [curr]
            }
            return acc
        }
            , {} as { [key: string]: KeepaProduct[] }) as { [key: string]: KeepaProduct[] }

        const parentProducts = Object.entries(pivotedByParent).map(([asin, products], idx) => {
            // const parent = products[0]
            const children = products
            return {
                key: idx,
                asin: asin,
                isParent: products[0].parentAsin !== undefined && products[0].parentAsin !== null,
                childrenVars: children,
                variationCount: children.length,
                title: products[0].title,
                sellPrice: children.filter((item) => item.sellPrice > 0).length > 0 ? children.filter((item) => item.sellPrice > 0).reduce((acc, curr) => {
                    return acc + curr.sellPrice
                }
                    , 0) / children.filter((item) => item.sellPrice > 0).length : -1,
                currentBuyBoxPrice: children.filter((item) => item.currentBuyBoxPrice > 0).reduce((acc, curr) => {
                    return acc + curr.currentBuyBoxPrice
                }
                    , 0) / children.filter((item) => item.currentBuyBoxPrice > 0).length,
                buyBox30Avg: children.filter((item) => item.buyBox30Avg > 0).reduce((acc, curr) => {
                    return acc + curr.buyBox30Avg
                }
                    , 0) / children.filter((item) => item.buyBox30Avg > 0).length,
                buyBox90Avg: children.filter((item) => item.buyBox90Avg > 0).reduce((acc, curr) => {
                    return acc + curr.buyBox90Avg
                }
                    , 0) / children.filter((item) => item.buyBox90Avg > 0).length,
                estSalesTotal: Math.max(...children.map((item) => item.estSalesTotal)),
                estSalesBBTotal: children.reduce((acc, curr) => {
                    return acc + curr.estSales
                }, 0),
                estSalesValue: children.filter((item) => item.sellPrice > 0).length > 0 ? Math.max(...children.map((item) => item.estSalesTotal)) * children.filter((item) => item.sellPrice > 0).reduce((acc, curr) => {
                    return acc + curr.sellPrice
                }
                    , 0) / children.filter((item) => item.sellPrice > 0).length : 0,
                ourSales: children.reduce((acc, curr) => {
                    return acc + curr.ourSales
                }
                    , 0),
                ourUnitSales: children.reduce((acc, curr) => {
                    return acc + curr.ourUnitSales
                }
                    , 0),
                ourVarCount: children.reduce((acc, curr) => {
                    return acc + (curr.ourInventory > 0 ? 1 : 0)
                }
                    , 0),
                reviews: Math.max(...children.map((item) => item.reviews)),
                reviews90Avg: Math.max(...children.map((item) => item.reviews90Avg)),
                Issues: Array.from(new Set(children.map((item) => item.Issues).flat())),
            }
        }).map((prod) => ({
            ...prod,
            marketShare: prod.estSalesValue > 0 ? (prod.ourSales / prod.estSalesValue) * 100 : 0,
        }))

        console.log(parentProducts);
        setParentProducts(parentProducts)

        currentUser?.getIdToken().then((token) => {
            saveParentProducts(token, newCollectionName, parentProducts);
        })

        setIsLoading(false)
    }

    const getData = async () => {
        setIsLoading(true)
        setProgressPercents(0)

        const newCollectionName = `${dayjs().format('YYYY-MM-DD')}_${activeSupplier?.toLowerCase()}`
        setSelectedCollection(newCollectionName)

        if (brandInsightsCollections?.includes(newCollectionName)) {
            currentUser?.getIdToken().then((token) => {
                getBrandInsights(token, newCollectionName).then((products) => {
                    setParentProducts(products)
                }).finally(() => {
                    setIsLoading(false)
                })
            })
        } else {
            const asinList = await GetAsinList(activeSupplier, singleVar, includeTitle);
            setAsinList(asinList)
            setModalOpen(true)
        }
    }

    useEffect(() => {
        setFilteredData(filterTableData({}, columns, parentProducts))
    }, [parentProducts, columns])

    const handleUpload = async () => {
        setIsLoading(true)

        const reader = new FileReader();

        reader.onload = async (e) => {
            const csv = e.target?.result;
            const csvString = csv as string;

            const df = dataForge.fromCSV(csvString)
            // append "Ratings - Format Specific" column from df to childrenVars of parentProducts
            const newParents = parentProducts.map((parent) => {
                const children = parent.childrenVars.map((child: any) => {
                    const row = df.where((row) => row['ASIN'] === child.asin).toArray()?.[0]
                    return {
                        ...child,
                        reviewsFormat: row?.['Ratings - Format Specific'] ? parseInt(row['Ratings - Format Specific']) : -1,
                    }
                }).map((child: any) => {
                    return {
                        ...child,
                        expectedSales: child.reviewsFormat > 0 && child.estSalesTotal > 0 && child.sellPrice > 0 ? (child.reviewsFormat / child.reviews) * child.sellPrice * child.estSalesTotal : 0,
                    }
                })

                return {
                    ...parent,
                    childrenVars: children,
                }
            })

            setParentProducts(newParents)

            currentUser?.getIdToken().then((token) => {
                saveParentProducts(token, selectedCollection, newParents);
            })

            setIsLoading(false)
        };
        reader.readAsText(fileMap.keepaExport as Blob);
    }

    console.log(parentProducts)

    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Select
                    style={{ minWidth: '100%' }}
                    showSearch
                    options={(brandInsightsCollections ?? []).map((uploadName: string) => ({
                        value: uploadName,
                        label: uploadName,
                    }))}
                    value={selectedCollection}
                    filterOption={(input, option) =>
                        (option?.value?.toString().toLowerCase().indexOf(input.toLowerCase()) || 1) >= 0
                    }
                    onChange={(val) => {
                        if (val) {
                            setSelectedCollection(val)
                            setActiveSupplier(val.split('_').slice(1).join())
                            setIsLoading(true)
                            currentUser?.getIdToken().then((token) => {
                                getBrandInsights(token, val).then((products) => {
                                    setParentProducts(products)
                                }).finally(() => {
                                    setIsLoading(false)
                                })
                            })
                        } else {
                            setSelectedCollection('')
                            setActiveSupplier('')
                            setParentProducts([])
                        }
                    }}
                    allowClear
                />
            </Col>
            <Col span={24}>
                <Divider></Divider>
            </Col>
            <Col span={16}>
                <Input value={activeSupplier} onChange={(e) => {
                    if (e.target.value?.length === 0) {
                        setActiveSupplier('')
                    } else {
                        setActiveSupplier(e.target.value?.toLowerCase())
                    }
                }} />
            </Col>
            <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <Tooltip trigger={'hover'}  title="Whether to only grab one variation per parent - recommended for huge brands">Single Var Mode: </Tooltip><Switch checked={singleVar} disabled={!(userData?.role.includes('ADMIN'))} onChange={setSingleVar} title="Checked" />
            </Col>
            <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <Tooltip trigger={'hover'}  title="Whether to include title search in data grab - NOT recommended for generic brand names">Title search: </Tooltip><Switch checked={includeTitle} onChange={setIncludeTitle} title="Checked" />
            </Col>
            <Col span={4}>
                <Button
                    block
                    type="primary"
                    onClick={getData}
                    disabled={
                        (!(activeSupplier.length > 0) || (parentProducts.length > 0))
                    }
                >
                    Load
                </Button>
            </Col>
            <Col span={24}>
                <Space title="Various data metrics:" direction="horizontal" style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '90px' }}>
                    <Card>
                        <Space direction="vertical">
                            <Button block onClick={() => downloadHelper(parentProducts.map((prod) => prod.childrenVars.map((item: KeepaProduct) => item.asin)).flat().map((asin) => ({ ASIN: asin })), `${dayjs().format('YYYY-MM-DD')}_BrandInsights_ASIN-List`)} type="primary">Download ASIN List</Button>
                            <Button block onClick={() => {
                                const [wsExport, childList] = prepareForWSExport(parentProducts)
                                downloadHelper(wsExport, `${dayjs().format('YYYY-MM-DD')}_BrandInsights_WS-Export`)
                                downloadHelper(childList.map((asin) => ({ASIN: asin})), `${dayjs().format('YYYY-MM-DD')}_BrandInsights_ChildList`)
                            }} type="primary">WS Export</Button>
                        </Space>
                    </Card>
                    <Card>
                        <Space direction="vertical">
                            <Upload beforeUpload={(file: RcFile) => beforeUpload(file)} onRemove={onRemove} accept={'.csv'} name="keepaOld" listType="text">
                                <Button block icon={<UploadOutlined />}>Add KeepaExport</Button>
                            </Upload>
                            <Button
                                block
                                type="primary"
                                onClick={() => handleUpload()}
                                disabled={!fileMap.keepaExport}
                                loading={isLoading}
                            >
                                {isLoading ? 'Uploading' : 'Start Upload'}
                            </Button>
                        </Space>
                    </Card>
                    <Card>
                        <Statistic
                            title="Parent Count"
                            value={filteredData.length}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Child Count"
                            value={filteredData.reduce((acc, curr) => acc += curr.variationCount, 0)}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Stocked Children"
                            value={filteredData.reduce((acc, curr) => acc += curr.ourVarCount, 0)}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Expected Unit Sales"
                            value={filteredData.reduce((acc, curr) => acc += curr.estSalesTotal, 0)}
                            precision={0}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Our Unit Sales"
                            value={filteredData.reduce((acc, curr) => acc += curr.ourUnitSales, 0)}
                            precision={0}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Expected Sales"
                            value={filteredData.filter((row) => row.sellPrice > 0).reduce((acc, curr) => acc += (curr.estSalesValue), 0)}
                            precision={0}
                            prefix="$"
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Our Sales"
                            value={filteredData.reduce((acc, curr) => acc += curr.ourSales, 0)}
                            precision={0}
                            prefix="$"
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Market Share"
                            value={(filteredData.reduce((acc, curr) => acc += curr.ourSales, 0) / filteredData.filter((row) => row.sellPrice > 0).reduce((acc, curr) => acc += (curr.estSalesTotal * curr.sellPrice), 0)) * 100}
                            precision={2}
                            suffix="%"
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Hidden Revenue"
                            // 1 review per 40 sales, 2 to find out how much reviews grew over 3 months assuming linear growth and 3 to find the monthly increase, 
                            value={filteredData.filter((row) => row.Issues.includes('NoRank')).reduce((acc, curr) => acc += ((curr.reviews - curr.reviews90Avg) * 40 * (2/3) * curr.sellPrice), 0)} 
                            precision={2}
                            prefix="$"
                        />
                    </Card>
                    <Card>
                        <Space direction="vertical">
                            <Button block onClick={() => downloadHelper(parentProducts.map(({ childrenVars, ...prod }) => prod), `${dayjs().format('YYYY-MM-DD')}_BrandInsights_Parent`)} type="primary">Download Parents (CSV)</Button>
                            <Button onClick={() => excelDownloadHelper(prepareForExcelExport(parentProducts), `${dayjs().format('YYYY-MM-DD')}_BrandInsights_All`)} block type="primary">Export (Excel)</Button>
                        </Space>
                    </Card>
                    {
                        userData?.role?.includes('ADMIN') && (
                            <Card>
                                <Space direction="vertical">
                                    <Button
                                        disabled={!userData?.role?.includes('ADMIN')}
                                        onClick={() => pivotParents(analyzeIssues(transformKeepaProducts(parentProducts.map((prod) => prod.childrenVars).flat())), selectedCollection)}
                                        block
                                        type="primary"
                                    >
                                        Recalc Keepa
                                    </Button>
                                </Space>
                            </Card>
                        )
                    }
                </Space>
            </Col>
            <Col span={24}>
                <Table
                    loading={isLoading}
                    columns={columns}
                    sticky={true}
                    bordered={true}
                    size="small"
                    tableLayout={"auto"}
                    dataSource={parentProducts}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100'],
                        defaultPageSize: 25,
                    }}
                    expandable={{
                        expandedRowRender: (record, index, indent, expanded) => expandedRowRendererOurs(message, updateData, record, index, indent, expanded),
                        defaultExpandedRowKeys: []
                    }}
                    rowKey={(record) => `${record.key}-${record.asin}`}
                    showSorterTooltip={false}
                    scroll={{ y: "82vh", x: "max-content" }}
                    onChange={(pagination, filters, sorter, extra) => {
                        setFilteredData(filterTableData(filters, columns, parentProducts))
                        console.log(extra.currentDataSource)
                    }}
                />
            </Col>
            <Modal
                title="Keepa API"
                open={modalOpen}
                maskClosable={false}
                onCancel={() => {
                    setModalOpen(false)
                    setIsLoading(false)
                    setConfirmLoading(false);
                }}
                confirmLoading={confirmLoading}
                onOk={async () => {
                    // cross check for > 10k results later
                    // request 100 products at a time
                    setConfirmLoading(true);
                    const localProducts: KeepaProduct = [];
                    let i, chunk = 10;
                    for (i = 0; i < asinList.length; i += chunk) {
                        const results = await getKeepa(asinList.slice(i, i + chunk).join(','), 'asin', '&history=0&stats=365&buybox=1&rating=1')
                        // const results = await getKeepaRevSeller(asinList.slice(i, i + chunk).join(':'))

                        if (results.products === undefined) {
                            message.error('Keepa API empty response')
                        } else {
                            localProducts.push(...(results.products.map(({csv, ...prod}) => prod) ?? []))
                        }

                        console.log(((i + chunk) / asinList.length) * 100)
                        setProgressPercents(Math.min(100, Math.ceil(((i + chunk) / asinList.length) * 100)))
                        setKeepaTokens(results.tokensLeft ?? 30)

                        if (results.tokensLeft < 1000) {
                            message.warning('Keepa tokens running low, slowing the run down')
                            sleep(60000)
                        }
                    }

                    currentUser?.getIdToken().then((token) => {
                        // get orders
                        Promise.all([
                            getOrderSummariesForIDs(token, 'asin', asinList, false),
                            getExperimentalInventory(token, asinList),
                            getAggregatedWholesaleData(token, asinList, 30)
                        ]).then((res) => {
                            const products = analyzeIssues(appendAggWholesaleData(appendInventoryLevels(appendOrderSummaries(transformKeepaProducts(localProducts as unknown as KeepaProduct[]), res[0]), res[1]), res[2]))
                            pivotParents(products, selectedCollection)
                        }).finally(() => {
                            setConfirmLoading(false);
                            setModalOpen(false)
                        })
                    })
                }}
            >
                <>
                    <p>Do you want to get Keepa data for <b>{asinList.length}</b> ASINs?</p>
                    <p>Single Var Mode: {singleVar ? 'ON' : 'OFF'}</p>
                    <p>Title Search: {includeTitle ? 'ON' : 'OFF'}</p>

                    <p>Keepa Tokens remaining: {keepaTokens} / 3000</p>
                    <Progress style={{width: '100%'}} percent={progressPercents} />
                </>
            </Modal>
        </Row>
    )
}

export default BrandInsightsAnalyze;