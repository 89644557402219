import {Button, Divider, Form, Input, InputNumber, Modal, Select, Skeleton} from "antd";
import {FormInstance} from "antd/lib";
import React, {useEffect, useState} from "react";
import {KeepaProduct} from "../../types/KeepaTypes";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {WarehouseShipLaterProduct} from "../../types/WarehouseTypes";

export const WarehouseProductPopup: React.FC<{
    product: KeepaProduct | WarehouseShipLaterProduct;
    open: boolean;
    onFinish: (values: any, product: KeepaProduct | WarehouseShipLaterProduct) => void;
    onCancel: () => void;
    form: FormInstance<any>;
}> = ({product, open, onFinish, onCancel, form}) => {
    const [initialValues, setInitialValues] = useState<any>({
        quantity: null,
        upc: null,
        asin: null,
        title: null,
        image: null,
    });

    useEffect(() => {
        const newInitialValues = {
            quantity: product.quantity ?? 1,
            upc: product.upc ?? (product as KeepaProduct).upcList?.[0] ?? "",
            asin: product.asin,
            title: product.title,
            image:
                product.image ??
                ((product as KeepaProduct).imagesCSV?.split(",")?.[0]
                    ? `https://images-na.ssl-images-amazon.com/images/I/${(product as KeepaProduct).imagesCSV?.split(",")[0]}`
                    : `https://placehold.co/100?text=?`),
            aisle: product.aisle ?? form.getFieldValue("aisle") ?? "",
            location: product.location ?? form.getFieldValue("location") ?? "",
            box: product.box ?? form.getFieldValue("box") ?? "",
        };

        form.setFieldsValue(newInitialValues);
        setInitialValues(newInitialValues);
    }, [product, form]);

    return (
        <Modal
            maskClosable={true}
            centered
            width={420}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            open={open}
            onCancel={onCancel}
            mask
        >
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px", gap: "8px"}}>
                {initialValues.image ? (
                    <img
                        src={initialValues.image}
                        style={{maxWidth: "150px", maxHeight: "150px", width: "auto", height: "auto", borderRadius: "10%"}}
                        alt="Selected product"
                    />
                ) : (
                    <Skeleton.Image style={{height: 100, width: 100}} />
                )}
                <h3>
                    {product.asin}: {product.title}
                </h3>
            </div>
            <Form
                layout="vertical"
                action=""
                form={form}
                onFinishFailed={(val) => console.log(val)}
                onFinish={(values) => onFinish(values, product)}
                name="warehouse-product-popup"
            >
                <Form.Item name="aisle" label="Aisle" rules={[{required: true}]}>
                    <Select
                        size="large"
                        showSearch
                        options={Array.from({length: 10}, (_, i) => i + 1).map((location) => ({key: location, value: location}))}
                    />
                </Form.Item>
                <Form.Item name="location" label="Row" rules={[{required: true}]}>
                    <Select
                        size="large"
                        showSearch
                        options={Array.from({length: 26}, (_, i) => String.fromCharCode(65 + i))
                            .flatMap((letter) => [`${letter}1`, `${letter}2`])
                            .map((location) => ({key: location, value: location}))}
                    />
                </Form.Item>
                <Form.Item name="box" label="Box" rules={[{required: true}]}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item name="upc" label="UPC" hidden={!!product.upc} rules={[{required: true}]}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item name="asin" label="ASIN" hidden={!!product.asin}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item name="title" label="Title" hidden={!!product.title} rules={[{required: true}]}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item name="image" label="Image" hidden>
                    <Input size="large" />
                </Form.Item>
                <Form.Item
                    name="quantity"
                    label="Quantity"
                    rules={[{required: true}]}
                    style={{justifyContent: "center", display: "flex", width: "100%"}}
                >
                    <InputNumber
                        size="large"
                        addonAfter={
                            <Button type="text" onClick={() => form.setFieldValue("quantity", form.getFieldValue("quantity") + 1)}>
                                <PlusOutlined style={{color: "yellow"}} />
                            </Button>
                        }
                        addonBefore={
                            <Button
                                type="text"
                                onClick={() => form.setFieldValue("quantity", Math.max(form.getFieldValue("quantity") - 1, 1))}
                            >
                                <MinusOutlined style={{color: "yellow"}} />
                            </Button>
                        }
                        min={1}
                    />
                </Form.Item>
                <Divider></Divider>
                <Form.Item style={{alignItems: "center"}}>
                    <Button title="Store" type="primary" htmlType="submit" block>
                        Store
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
