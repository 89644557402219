import React, { useState } from "react";
import { Collapse, Input, Button, InputNumber } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export interface BrandFilter {
  brand: string;
  minDiscount: number;
}

export interface BrandSpecificFiltersProps {
  filters: BrandFilter[];
  setFilters: (filters: BrandFilter[]) => void;
}

export function BrandSpecificFilters({
  filters,
  setFilters,
}: BrandSpecificFiltersProps) {
  const [minDiscount, setMinDiscount] = useState(0);
  const [brand, setBrand] = useState("");
  return (
    <Collapse style={{ flexGrow: 1 }}>
      <Collapse.Panel header="Brand specific filters" key="0">
        {filters.map((filter, i) => (
          <div key={i}>
            <strong>{(filter.brand || "All") + ": "}</strong>
            {`>${filter.minDiscount}%`}
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              danger
              size="small"
              ghost
              style={{margin: 5}}
              onClick={() => setFilters(filters.filter((_, j) => j !== i))}
            />
          </div>
        ))}
        <div style={{ display: "flex" }}>
          <Input
            type="text"
            name="brand"
            placeholder="Brand (leave empty for default)"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
          <strong style={{ padding: 5 }}>{" > "}</strong>
          <InputNumber
            name="minDiscount"
            value={minDiscount}
            onChange={(e) => setMinDiscount(e as number)}
            formatter={(val) => val + "%"}
          />
          <Button
            type="primary"
            onClick={() => {
              setFilters([...filters, { minDiscount, brand }]);
              setBrand("");
              setMinDiscount(0);
            }}
          >
            Add
          </Button>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
