import {
    Button,
    Card,
    Col,
    Collapse,
    ConfigProvider,
    Form,
    Image,
    Input,
    InputNumber,
    List,
    message,
    Modal,
    notification,
    Popover,
    Row,
    Select,
    Space,
    Spin,
    Statistic,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {useOmega} from "../../contexts/OmegaContext";
import {getNoteDetails, pushSellerSnap, requestKeepaData, requestPectyData, saveNote} from "../../services/OmegaService";
import {InventoryHealthItem, NoteItem, OMSItem, ReplenItem, SingularItem} from "../../types/OmegaTypes";
import {prepareInsight} from "./InsightsCalc";
import InsightsTable from "./InsightsTable";
import {KeepaItem} from "../../types/WholesaleItem";
import {
    CopyOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoCircleFilled,
    OrderedListOutlined,
    ReconciliationOutlined,
    WarningFilled,
} from "@ant-design/icons";
import ProfitSimulator from "./ProfitSimulator";
import InvHealthPopover from "./InvHealthPopover";
import OrdersPopover from "./OrdersPopover";
import PreviousNotePopup from "./PreviousNotePopup";
import InsightsSummary from "./InsightsSummary";
import "../wholesale/Statistic.scss";
import ScheduleButton from "./ScheduleButton";
import {useQueryClient} from "@tanstack/react-query";
import BBSharePopover from "./BBSharePopover";
import {copyToClipboard} from "./ItemBrowser";
dayjs.extend(utc);
dayjs.extend(timezone);

const {Link} = Typography;
interface DataType extends SingularItem {
    key: React.Key;
}

const customizeRenderEmpty = () => <div style={{textAlign: "center"}}></div>;

export const POSSIBLE_ACTIONS = [
    "Increase BB Share",
    "Increase Price",
    "Lower Min Price",
    "Lower Max Price",
    "Advertise",
    "Coupon",
    "Wait",
    "Custom - check note",
];

export const POSSIBLE_TAGS = [
    "EG",
    "Summer",
    "Cool",
    "Winter",
    "Volleyball",
    "Football",
    "Halloween",
    "Christmas",
    "BTS",
    "SL",
    "Easter",
    "MAP",
    "Hold",
    "Exclusive",
    "Supp",
];

const getInventoryAge = (invHealthItem: InventoryHealthItem) => {
    let age = "Unknown";
    if (invHealthItem) {
        if (parseInt(invHealthItem["inv-age-0-to-30-days"]) > 0) {
            age = "<30";
        }
        if (parseInt(invHealthItem["inv-age-31-to-60-days"]) > 0) {
            age = "31+";
        }
        if (parseInt(invHealthItem["inv-age-61-to-90-days"]) > 0) {
            age = "61+";
        }
        if (parseInt(invHealthItem["inv-age-91-to-180-days"]) > 0) {
            age = "91+";
        }
        if (parseInt(invHealthItem["inv-age-181-to-270-days"]) > 0) {
            age = "181+";
        }
        if (parseInt(invHealthItem["inv-age-271-to-365-days"]) > 0) {
            age = "271+";
        }
        if (parseInt(invHealthItem["inv-age-365-plus-days"]) > 0) {
            age = "365+";
        }
    }

    return age;
};

const getStat = (curr: any, prev: any) => {
    // if (prev) {
    //     return `${curr} (${prev})`
    // }

    return curr;
};

const SingularItemCard: React.FC<{productData: DataType; prodIndex: number; prodCount: number}> = ({productData, prodIndex, prodCount}) => {
    const queryClient = useQueryClient();
    const [modal, contextHolder] = Modal.useModal();
    const [notificationApi, notifContextHolder] = notification.useNotification();
    const {currentUser} = useAuth();
    const [noteText, setNoteText] = useState("");
    const [noteSaveEnabled, setNoteSaveEnabled] = useState(true);
    const [actions, setActions] = useState<string[]>(["Wait"]);

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [repriceConfigPreset, setRepriceConfigPreset] = useState("");

    const {changesMade, setChangesMade, replenData, setReplenData, replenParams, setReplenParams} = useOmega();

    const [antBBShare, setAntBBShare] = useState(1);
    const [targetDIS, setTargetDIS] = useState(75);
    const [antVelocity, setAntVelocity] = useState(1);
    const [canCancel, setCanCancel] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [suggestedBuyQTY, setSuggestedBuyQTY] = useState(0);
    const [antStock, setAntStock] = useState(0);
    const [antAllSales, setAntAllSales] = useState(0);
    const [antAllSalesSource, setAntAllSalesSource] = useState("Unknown");
    const [insight, setInsight] = useState(null);

    const [tagForm] = Form.useForm();
    const minPriceInputRef = useRef<HTMLInputElement>(null);
    const maxPriceInputRef = useRef<HTMLInputElement>(null);

    const [replenQty, setReplenQty] = useState(0);
    const [tags, setTags] = useState<string[]>([]);
    const [cost, setCost] = useState<number>(0);
    const [mapVal, setMap] = useState<number>(0);
    const [mvsp, setMVSP] = useState<number>(0);

    const [previousData, setPreviousData] = useState<DataType | null>(null);

    const saveSingularNote = useCallback(() => {
        setNoteSaveEnabled(false);
        currentUser!.getIdToken().then((token: string) => {
            if (token) {
                const newNote = {
                    SKU: productData.SKU,
                    actions: actions,
                    noteText: noteText,
                    stats: {
                        prevMin: parseFloat(productData.ssItem.min_price),
                        newMin: minPrice,
                        prevMax: parseFloat(productData.ssItem.max_price),
                        newMax: maxPrice,
                        prevPreset: productData.ssItem.reprice_config_preset,
                        newPreset: repriceConfigPreset,
                    },
                    author: currentUser?.email || "Unknown",
                };

                console.log("Current note to save:", newNote, currentUser);
                const ssUpdateObj = {
                    sku: newNote.SKU,
                    min_price: newNote.stats.newMin,
                    max_price: newNote.stats.newMax,
                    reprice_config_preset: newNote.stats.newPreset,
                };

                pushSellerSnap([ssUpdateObj]).then((res) => {
                    if (res?.[0]?.data?.errors === 0) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const {itemArr, notes, ...productDataWithoutPreviousNotes} = productData;
                        saveNote(token, newNote, productDataWithoutPreviousNotes)
                            .then((res) => {
                                if (res.error) {
                                    notification.error({
                                        message: `Failed to push note - try again!`,
                                    });
                                } else {
                                    message.success("Note saved and SellerSnap updated!");

                                    queryClient.setQueryData(["singularNotes_data"], (oldData: any) => {
                                        return [{...newNote, insertTimestamp: new Date()}, ...(oldData ?? [])];
                                    });

                                    queryClient.setQueryData(["singularNotes_data", productData?.SKU], (oldData: any) => {
                                        return [{...newNote, insertTimestamp: new Date()}, ...(oldData ?? [])];
                                    });

                                    try {
                                        setChangesMade([
                                            ...changesMade,
                                            {...newNote, productData: productDataWithoutPreviousNotes, insertTimestamp: new Date()},
                                        ]);
                                    } catch (ex) {
                                        notification.info({
                                            message:
                                                "Failed to save note to local storage - consider clearing your Notes (current session) tab!",
                                        });
                                    }
                                }
                            })
                            .finally(() => {
                                setNoteSaveEnabled(true);
                            });
                    } else {
                        console.log("Failed SS Save", res, res[0]);
                        notification.error({
                            message: "Failed to update SellerSnap - not saving the note!",
                        });
                        setNoteSaveEnabled(true);
                    }
                });
            } else {
                message.error(`Failed to push note - try again!`);
            }
        });
    }, [productData, actions, noteText, currentUser, repriceConfigPreset, minPrice, maxPrice, changesMade, setChangesMade, queryClient]);

    useEffect(() => {
        if (actions.includes("Wait") && actions.length === 1) {
            setMinPrice(productData["Min Price"]);
        }
    }, [productData, actions]);

    useEffect(() => {
        setTags(productData.Tags);
        setCost(productData.Cost);
        setMap(productData.ssItem.map || -1);
    }, [productData]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const saveReplenQty = useCallback(() => {
        const newReplen: ReplenItem = {
            SKU: productData.SKU,
            ASIN: productData.ASIN,
            Title: productData.Title,
            UPC: productData.omsItems[0]?.UPC || "",
            Supplier_SKU: productData.omsItems[0]?.Supplier_SKU || "",
            Supplier_Title: productData.omsItems[0]?.Supplier_Title || "",
            Supplier_Notes: productData.omsItems[0]?.Supplier_Notes || "",
            Cost: productData.omsItems[0]?.Cost || productData.Cost || -1,
            antBBShare: antBBShare,
            targetDIS: targetDIS,
            antVelocity: antVelocity,
            antStock: antStock,
            antAllSales: antAllSales,
            replenQty: replenQty,
        };

        // remove unnecessary long Costs that are being stored in OMS
        newReplen.Cost = parseFloat(newReplen.Cost.toFixed(2));

        console.log("Current replen to save:", newReplen, currentUser);
        const localReplenData = {...replenData};
        localReplenData[productData.SKU] = newReplen;
        setReplenData(localReplenData);
        message.success("Replen QTY saved!");
    }, [productData, replenQty, currentUser, antBBShare, targetDIS, antVelocity, antStock, antAllSales, replenData, setReplenData]);

    const handleParamEdit = (key: string, value: string | null) => {
        let newVal;
        if (value !== null && value !== undefined) {
            switch (key) {
                case "antBBShare":
                    newVal = Math.min(100, parseFloat(value) / 100);
                    setAntBBShare(newVal);
                    setReplenParams({...replenParams, antBBShare: newVal});
                    break;
                case "targetDIS":
                    newVal = parseInt(value);
                    setTargetDIS(newVal);
                    setReplenParams({...replenParams, targetDIS: newVal});
                    break;
                case "antVelocity":
                    newVal = parseFloat(value) / 100;
                    setAntVelocity(newVal);
                    setReplenParams({...replenParams, antVelocity: newVal});
                    break;
                case "canCancel":
                    newVal = value === "YES" ? true : false;
                    setCanCancel(newVal);
                    setReplenParams({...replenParams, canCancel: newVal});
                    break;
                case "antStock":
                    setAntStock(parseInt(value));
                    break;
                case "antAllSales":
                    setAntAllSales(parseInt(value));
                    setAntAllSalesSource("Manual");
                    break;
                case "replenQty":
                    setReplenQty(parseInt(value));
                    break;
                case "minPrice":
                    if (productData.ssItem.tags.includes("Hold") || tags.includes("Hold")) {
                        console.log("hold tag found lol");
                        return;
                    }

                    const newMinPrice = parseFloat(value.replace("$", ""));

                    if (!Number.isNaN(newMinPrice)) {
                        setMinPrice(newMinPrice);
                    }

                    break;
                case "maxPrice":
                    if (productData.ssItem.tags.includes("Hold") || tags.includes("Hold")) {
                        console.log("hold tag found lol");
                        return;
                    }
                    const newMaxPrice = parseFloat(value.replace("$", ""));

                    if (!Number.isNaN(newMaxPrice)) {
                        setMaxPrice(newMaxPrice);
                    }

                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        setAntBBShare(replenParams.antBBShare);
        setTargetDIS(replenParams.targetDIS);
        setAntVelocity(replenParams.antVelocity);
        setCanCancel(replenParams.canCancel);
    }, [replenParams]);

    useEffect(() => {
        setMinPrice(parseFloat(productData.ssItem.min_price));
        setMaxPrice(parseFloat(productData.ssItem.max_price));
        setRepriceConfigPreset(productData.ssItem.reprice_config_preset);
    }, [productData]);

    useEffect(() => {
        if (antStock === 0) {
            setAntStock(productData.Stock);
        }

        if (antAllSales === 0) {
            setAntAllSales(parseFloat(productData["EstSales (30D)"].toFixed(2)));
            setAntAllSalesSource("Sales Data");
        } else {
            const localInsight = prepareInsight({...productData, "EstSales (Manual)": antAllSales});
            setInsight(localInsight);
        }
    }, [productData, antStock, antAllSales]);

    useEffect(() => {
        const suggestedQty = (antAllSales * antBBShare * antVelocity * (targetDIS / 30) - antStock) * (1 - +!canCancel * 0.1);
        setSuggestedBuyQTY(Math.max(suggestedQty, 0));
    }, [antBBShare, antVelocity, targetDIS, canCancel, antStock, antAllSales, productData]);

    // TO RE-TEST
    // useEffect(() => {
    //     console.log(minPrice, productData["Min Price"], actions)
    //     if (minPrice > 0 && minPrice < productData["Min Price"] && !actions.includes('Lower Min Price')) {
    //         if ((actions.length === 1 && actions[0] === 'Wait') || actions.length === 0) {
    //             setActions(['Lower Min Price'])
    //         } else if (actions.length > 1) {
    //             setActions((actions) => ([...actions, 'Lower Min Price']))
    //         }
    //     }
    // }, [productData, minPrice, actions])

    const editTags = (productData: SingularItem) => {
        console.log(tagForm);
        console.log(tagForm.getFieldsValue());
        modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form form={tagForm} name="tagFormCustom" layout="vertical" initialValues={{Tags: productData.Tags}} onFinish={() => null}>
                    <Form.Item name="Tags" label="What tags do you want to apply to this item?">
                        <Select
                            mode="tags"
                            style={{width: "100%"}}
                            placeholder="Tags"
                            allowClear
                            onChange={(val) => tagForm.setFieldsValue({Tags: val})}
                        >
                            {POSSIBLE_TAGS.map((tag: string, idx: number) => (
                                <Select.Option key={idx} value={tag}>
                                    {tag}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            ),
            onOk() {
                tagForm.validateFields().then((values) => {
                    console.log("bulk form values", values);
                    const ssUpdateObj = {
                        sku: productData.SKU,
                        tags: values.Tags.join(";"),
                    };

                    pushSellerSnap([ssUpdateObj]).then((res) => {
                        if (res?.[0]?.data?.errors === 0) {
                            message.success("SellerSnap updated with new tags!");
                            setTags(values.Tags);
                            productData.Tags = values.Tags;
                        } else {
                            console.log("Failed SS Save", res, res[0]);
                            message.error("Failed to update SellerSnap with new tags!");
                        }
                    });
                });
            },
            onCancel() {
                console.log("Cancelled Bulk");
                tagForm.setFieldsValue({Tags: productData.Tags});
            },
        });
    };

    const editCost = (productData: SingularItem) => {
        modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form
                    form={tagForm}
                    name="tagFormCustom"
                    layout="vertical"
                    initialValues={{Tags: productData.Tags, newCost: productData.Cost}}
                    onFinish={() => null}
                >
                    <Form.Item name="newCost" label="What new cost would like to have with your item sir">
                        <InputNumber min={productData.Cost * 0.5} precision={2} title="New Cost" />
                    </Form.Item>
                </Form>
            ),
            onOk() {
                tagForm.validateFields().then((values) => {
                    console.log("bulk form values", values);
                    const ssUpdateObj = {
                        sku: productData.SKU,
                        cost: values.newCost,
                    };

                    console.log("ssupdateobj", ssUpdateObj);
                    pushSellerSnap([ssUpdateObj]).then((res) => {
                        if (res?.[0]?.data?.errors === 0) {
                            message.success("SellerSnap updated with new cost!");
                            setCost(values.newCost);
                            productData.Cost = values.newCost;
                        } else {
                            console.log("Failed SS Save", res, res[0]);
                            message.error("Failed to update SellerSnap with new cost!");
                        }
                    });
                });
            },
            onCancel() {
                console.log("Cancelled Bulk");
                tagForm.setFieldsValue({newCost: productData.Cost});
            },
        });
    };

    const editMap = (productData: SingularItem) => {
        modal.confirm({
            title: `Fill in the values for this action`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form
                    form={tagForm}
                    name="tagFormCustom"
                    layout="vertical"
                    initialValues={{Tags: productData.Tags, newMap: productData.ssItem.map}}
                    onFinish={() => null}
                >
                    <Form.Item name="newMap" label="What new MAP would like to have with your item sir">
                        <InputNumber min={productData["Min Price"] * 0.5} precision={2} title="New MAP" />
                    </Form.Item>
                </Form>
            ),
            onOk() {
                tagForm.validateFields().then((values) => {
                    console.log("bulk form values", values);
                    const ssUpdateObj = {
                        sku: productData.SKU,
                        map: values.newMap,
                    };

                    console.log("ssupdateobj", ssUpdateObj);
                    pushSellerSnap([ssUpdateObj]).then((res) => {
                        if (res?.[0]?.data?.errors === 0) {
                            message.success("SellerSnap updated with new MAP!");
                            setMap(values.newMap);
                            productData.ssItem.map = values.newMap;
                        } else {
                            console.log("Failed SS Save", res, res[0]);
                            message.error("Failed to update SellerSnap with new MAP!");
                        }
                    });
                });
            },
            onCancel() {
                console.log("Cancelled Bulk");
                tagForm.setFieldsValue({newMap: productData.ssItem.map});
            },
        });
    };

    const requestPecty = () => {
        requestPectyData([productData.ASIN]).then((res) => {
            console.log("pectyrequest", res);
            if (res.error) {
                message.error("Failed to begin requesting Pecty Data");
            } else {
                message.success("Successfully began requesting Pecty Data");
            }
        });
    };

    const requestKeepa = () => {
        currentUser!.getIdToken().then((token: string) => {
            if (token) {
                requestKeepaData(token, productData.ASIN).then((res) => {
                    if (res.result) {
                        console.log("Got result from Keepa (Backend):", res.result.finalEstSales);
                        setAntAllSales(parseFloat(res.result.finalEstSales.toFixed(2)));
                        setAntAllSalesSource("Keepa");
                    } else {
                        message.error("No existing Keepa Data found for this ASIN");
                    }
                });
            }
        });
    };

    const refreshPecty = () => {
        queryClient.invalidateQueries({queryKey: ["pectySingular_data"]});
    };

    useEffect(() => {
        const handleKeepaData = (data: KeepaItem[]) => {
            let totalReviews = 0;
            let totalRatings = 0;
            let totalRR = 0;

            data.forEach((item) => {
                if (item.Offers > 0) {
                    totalRatings += item.Ratings;
                    totalReviews += item.Reviews;
                }
            });

            totalRR = data[0].totalRR ?? totalRatings + totalReviews;

            const parsedData = data.map((item) => ({
                ...item,
                totalMultiplier: item.totalMultiplier ?? (item.Reviews + item.Ratings) / (totalRR || 1),
                RR: item.RR ?? item.Reviews + item.Ratings,
            }));

            const parsedMap = Object.fromEntries(parsedData.map((item) => [item.ASIN, item]));
            const currProduct = parsedMap[productData.ASIN];
            console.log(currProduct);
            if (currProduct && currProduct.estSales && currProduct.bbMultiplier) {
                setAntAllSales(parseFloat((currProduct.estSales / currProduct.bbMultiplier).toFixed(2)));
                setAntAllSalesSource("Keepa");
                message.success("Grabbed sales from Keepa - data updated!");
            } else {
                message.error(`Couldn't find sales data for ASIN ${productData.ASIN} - probably Out of Stock!`);
            }
        };

        const handleWholesaleMessage = (event: MessageEvent) => {
            const eventMessage = event.data;
            if (eventMessage.action === "extracted_keepa_data_for_site") {
                console.log("Received Data Message from Extension!", eventMessage);
                console.log("Extension variation data", eventMessage.keepaData);
                if (eventMessage.keepaData?.length > 0) {
                    message.success("Variation data received!");
                    handleKeepaData(eventMessage.keepaData);
                } else {
                    message.error(`Received empty variation data! Check Keepa's tab and retry if needed.`);
                }
            } else if (eventMessage.action === "extract_started") {
                message.info("Variation data is being grabbed!");
            } else if (eventMessage.action === "stock_extract_started") {
                message.info("Stock data is being grabbed!");
            } else if (eventMessage.action === "extract_login_error_for_site") {
                message.error(eventMessage.message);
            }
        };

        console.log("Setting listener for messages!");
        window.addEventListener("message", handleWholesaleMessage);

        // cleanup this component
        return () => {
            console.log("Removing listener for messages!");
            window.removeEventListener("message", handleWholesaleMessage);
        };
    }, [productData]);

    return (
        <>
            {contextHolder}
            {notifContextHolder}
            <Spin spinning={!noteSaveEnabled}>
                <Card
                    size="small"
                    title={
                        <Space
                            direction="horizontal"
                            align="center"
                            style={{justifyContent: "space-between", width: "100%", overflow: "auto"}}
                        >
                            <Space>
                                <Typography.Text>
                                    ({prodIndex + 1}/{prodCount})
                                </Typography.Text>
                                <Typography.Text style={{width: "50vw"}} ellipsis={{tooltip: true}}>
                                    {productData.Title}
                                </Typography.Text>
                            </Space>
                            <Space direction="horizontal" align="center">
                                <Typography.Text>
                                    (SKU: {productData.SKU} <CopyOutlined onClick={() => copyToClipboard(productData.SKU, "SKU")} />)
                                </Typography.Text>
                                <Button size="small" type="primary" id="wholesale-popover-button" data-asin={productData.ASIN}>
                                    Get Sales (exts)
                                </Button>
                                <Button size="small" type="primary" onClick={requestKeepa}>
                                    Get Sales (server)
                                </Button>
                                <Button size="small" type="primary" onClick={requestPecty}>
                                    Request Pecty
                                </Button>
                                <Button size="small" type="primary" onClick={refreshPecty}>
                                    Refresh Pecty
                                </Button>
                                <Button
                                    size="small"
                                    type="primary"
                                    id="wholesale-stock-popover-button"
                                    data-asin={productData.ASIN}
                                    style={{display: "none"}}
                                >
                                    Request Stock
                                </Button>
                            </Space>
                        </Space>
                    }
                    style={{height: "99vh", marginTop: "12px"}}
                    bodyStyle={{height: "99vh", width: "97vw", alignContent: "center", justifyContent: "center"}}
                >
                    <Row justify="center" style={{height: "30vh"}}>
                        <Col style={{maxHeight: "100%"}} span={6}>
                            <Card size="small" style={{height: "100%"}} bodyStyle={{height: "100%", padding: "12px"}}>
                                <Row align="middle" justify="center" style={{height: "50%", textAlign: "center"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                        span={12}
                                    >
                                        <Link
                                            style={{height: "100%"}}
                                            href={`https://www.amazon.com/gp/offer-listing/${productData.ASIN}?th=1&psc=1`}
                                            target="_blank"
                                        >
                                            <Image
                                                preview={false}
                                                height={"100%"}
                                                src={productData.ssItem.image_url?.replace("SL75", "SL300")}
                                            />
                                        </Link>
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "space-around",
                                            flexDirection: "column",
                                        }}
                                        span={6}
                                    >
                                        <Statistic
                                            title="Confidence"
                                            value={`${productData["Days In Stock"]}+${productData.BBShare}`}
                                            formatter={(val) => {
                                                const [dis, bb] = (val as string).split("+").map((el) => parseInt(el));
                                                if (dis > 19 && bb > 34) {
                                                    return (
                                                        <Tag style={{marginRight: "0"}} color="success">
                                                            High
                                                        </Tag>
                                                    );
                                                } else if (dis > 10 && bb > 10) {
                                                    return (
                                                        <Tag style={{marginRight: "0"}} color="warning">
                                                            Medium
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                            <Tag style={{marginRight: "0"}} color="rgb(255 0 0)">
                                                                Low!
                                                            </Tag>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                        </>
                                                    );
                                                }
                                            }}
                                        />
                                        <Statistic
                                            title="SKU Count"
                                            value={productData.skuItemCount}
                                            formatter={(val) => {
                                                if (val === 1) {
                                                    return (
                                                        <Tag style={{marginRight: "0"}} color="green">
                                                            1
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Popover
                                                            placement="top"
                                                            title={"SKUs for this ASIN"}
                                                            content={productData.skuItemList.map((item, idx) => (
                                                                <p key={idx} style={{marginBottom: "0"}}>
                                                                    {item.sku}{" "}
                                                                    <CopyOutlined onClick={() => copyToClipboard(item.sku, "SKU")} /> -{" "}
                                                                    {item.fba_available_quantity ?? item.fulfillable_quantity}
                                                                </p>
                                                            ))}
                                                            trigger="click"
                                                        >
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                            <Tag style={{marginRight: "0"}} color="rgb(255 0 0)">
                                                                {val}
                                                            </Tag>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                        </Popover>
                                                    );
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "space-around",
                                            flexDirection: "column",
                                        }}
                                        span={6}
                                    >
                                        <Statistic
                                            title="Repricing"
                                            value={productData.ssItem.repricing as any}
                                            formatter={(val) => {
                                                if (val) {
                                                    return (
                                                        <Tag style={{marginRight: "0"}} color="success">
                                                            True
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                            <Tag style={{marginRight: "0"}} color="rgb(255 0 0)">
                                                                False!
                                                            </Tag>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                        </>
                                                    );
                                                }
                                            }}
                                        />
                                        <Statistic
                                            title="Backordered"
                                            value={Number(productData.isBackordered)}
                                            formatter={(val) => {
                                                if (!val) {
                                                    return (
                                                        <Tag style={{marginRight: "0"}} color="success">
                                                            False
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                            <Tag style={{marginRight: "0"}} color="rgb(255 0 0)">
                                                                True!
                                                            </Tag>
                                                            <WarningFilled style={{margin: "5px", color: "rgb(255 0 0)"}} />
                                                        </>
                                                    );
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row align="top" justify="center" style={{paddingTop: "10px", height: "25%", textAlign: "center"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                        }}
                                        flex={1}
                                        span={8}
                                    >
                                        <Statistic
                                            title={
                                                <span>
                                                    ASIN <CopyOutlined onClick={() => copyToClipboard(productData.ASIN, "ASIN")} />
                                                </span>
                                            }
                                            value={productData.ASIN}
                                            formatter={(val) => (
                                                <Link href={`https://www.amazon.com/gp/offer-listing/${val}?th=1&psc=1`} target="_blank">
                                                    {val}
                                                </Link>
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                        }}
                                        flex={1}
                                        span={8}
                                    >
                                        <Statistic title="Brand" value={productData.Brand} />
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                        }}
                                        flex={1}
                                        span={8}
                                    >
                                        {/* <Statistic title="MAP (SS)" value={productData.ssItem.map || '?'} /> */}
                                        <Statistic
                                            title={
                                                <span>
                                                    MAP (SS) <EditOutlined onClick={() => editMap(productData)} />
                                                </span>
                                            }
                                            value={mapVal > 0 ? mapVal : "?"}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                    </Col>
                                </Row>
                                <Row align="top" justify="start" style={{paddingTop: "10px", height: "25%", textAlign: "center"}}>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}
                                        flex={1}
                                        span={8}
                                    >
                                        <Statistic
                                            title={
                                                <span>
                                                    Tags{" "}
                                                    <EditOutlined
                                                        disabled={productData.ssItem.tags.includes("Hold")}
                                                        onClick={() => editTags(productData)}
                                                    />
                                                </span>
                                            }
                                            value={tags.join(",")}
                                            formatter={(value) =>
                                                (value as string).split(",").map((tag, idx) => (
                                                    <Tag key={idx} style={{marginRight: 0}}>
                                                        {tag}
                                                    </Tag>
                                                ))
                                            }
                                        />
                                    </Col>

                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}
                                        flex={1}
                                        span={8}
                                    >
                                        <Statistic
                                            title="Repricing preset"
                                            valueStyle={{overflowWrap: "anywhere"}}
                                            value={productData.ssItem.reprice_config_preset}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={3}>
                            <Card
                                size="small"
                                title="Pricing"
                                style={{height: "100%", textAlign: "center"}}
                                bodyStyle={{height: "95%", textAlign: "center", padding: "12px"}}
                            >
                                <Row align="top" justify="center" style={{height: "100%"}}>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={12}
                                    >
                                        <Statistic
                                            title={
                                                <span>
                                                    Cost <EditOutlined onClick={() => editCost(productData)} />
                                                </span>
                                            }
                                            value={cost}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                        <Statistic
                                            title="Min Price"
                                            value={getStat(productData.ssItem.min_price || "Unknown", previousData?.ssItem.min_price)}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                        <Statistic
                                            title={
                                                <>
                                                    Curr BB{" "}
                                                    <Tag
                                                        color={productData.ssItem.bb_fulfillment === "FBM" ? "rgb(255 0 0)" : "green"}
                                                        style={{marginRight: 0}}
                                                    >
                                                        {productData.ssItem.bb_fulfillment}
                                                    </Tag>
                                                </>
                                            }
                                            value={getStat(
                                                productData.ssItem.cur_buybox_price ?? "Suppressed",
                                                previousData?.ssItem.cur_buybox_price
                                            )}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={12}
                                    >
                                        <Statistic
                                            title={
                                                <>
                                                    Listed{" "}
                                                    <Tag
                                                        color={productData.ssItem.fulfillment_channel === "FBM" ? "rgb(255 0 0)" : "green"}
                                                        style={{marginRight: 0}}
                                                    >
                                                        {productData.ssItem.fulfillment_channel}
                                                    </Tag>
                                                </>
                                            }
                                            value={getStat(productData.ssItem.listed_price, previousData?.ssItem.listed_price)}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                        <Statistic
                                            title="Max Price"
                                            value={getStat(productData.ssItem.max_price || "Unknown", previousData?.ssItem.max_price)}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                        <Statistic
                                            title="Avg. Sell Price"
                                            value={getStat(
                                                productData.ssItem.avg_selling_price ?? "Unknown",
                                                previousData?.ssItem.avg_selling_price
                                            )}
                                            precision={2}
                                            prefix={"$"}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={7}>
                            <Card
                                size="small"
                                title={
                                    <>
                                        <span>
                                            Sales (Advertising:{" "}
                                            {
                                                <Popover
                                                    placement="bottom"
                                                    trigger="click"
                                                    content={
                                                        <Card
                                                            size="small"
                                                            bodyStyle={{
                                                                height: "100%",
                                                                textAlign: "center",
                                                                padding: "12px",
                                                                width: "200px",
                                                            }}
                                                        >
                                                            <Row align="middle" justify="center" style={{height: "100%"}}>
                                                                <Col
                                                                    style={{
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        justifyContent: "space-around",
                                                                        flexDirection: "column",
                                                                    }}
                                                                    flex={1}
                                                                    span={12}
                                                                >
                                                                    <Statistic
                                                                        title="Impressions"
                                                                        value={productData.ssItem.advertising_impressions || 0}
                                                                        precision={0}
                                                                    />
                                                                    <Statistic
                                                                        title="Attr. Sales"
                                                                        value={productData.ssItem.advertising_attr_sales || 0}
                                                                        precision={0}
                                                                    />
                                                                    <Statistic
                                                                        title="Spend"
                                                                        value={productData.ssItem.advertising_spend || 0}
                                                                        precision={0}
                                                                        prefix={"$"}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    style={{
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        justifyContent: "space-around",
                                                                        flexDirection: "column",
                                                                    }}
                                                                    flex={1}
                                                                    span={12}
                                                                >
                                                                    <Statistic
                                                                        title="Clicks"
                                                                        value={productData.ssItem.advertising_clicks || 0}
                                                                        precision={0}
                                                                    />
                                                                    <Statistic
                                                                        title="Conversion"
                                                                        value={productData.ssItem.advertising_CTR || 0}
                                                                        precision={2}
                                                                        suffix={"%"}
                                                                    />
                                                                    <Statistic
                                                                        title="CPC"
                                                                        value={productData.ssItem.advertising_CPC || 0}
                                                                        precision={0}
                                                                        prefix={"$"}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    }
                                                >
                                                    {productData.ssItem.advertising_impressions > 0 ? (
                                                        <Tag color="rgb(255 0 0)" style={{marginRight: 0, lineHeight: "15px"}}>
                                                            YES
                                                        </Tag>
                                                    ) : (
                                                        <Tag color="default" style={{marginRight: 0, lineHeight: "15px"}}>
                                                            No
                                                        </Tag>
                                                    )}
                                                </Popover>
                                            }{" "}
                                            || Coupon:{" "}
                                            {productData.isCoupon ? (
                                                <Tag color="rgb(255 0 0)" style={{marginRight: 0, lineHeight: "15px"}}>
                                                    YES
                                                </Tag>
                                            ) : (
                                                <Tag color="default" style={{marginRight: 0, lineHeight: "15px"}}>
                                                    No
                                                </Tag>
                                            )}
                                            )
                                        </span>
                                    </>
                                }
                                style={{height: "100%", textAlign: "center"}}
                                bodyStyle={{height: "95%", textAlign: "center", padding: "12px"}}
                            >
                                <Row align="top" justify="center" style={{height: "100%"}}>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={6}
                                    >
                                        <Statistic
                                            title="BuyBox Share"
                                            value={productData.BBShare}
                                            precision={2}
                                            formatter={(val) =>
                                                productData.bbShare14D !== undefined ? (
                                                    <Popover
                                                        trigger="click"
                                                        content={
                                                            <BBSharePopover
                                                                bbShare7D={productData.bbShare7D}
                                                                bbShare14D={productData.bbShare14D}
                                                                bbShare30D={productData.bbShare30D}
                                                            />
                                                        }
                                                    >
                                                        <span style={{textAlign: "center"}}>
                                                            {val}% <ReconciliationOutlined type="primary" />
                                                        </span>
                                                    </Popover>
                                                ) : (
                                                    `${val}%`
                                                )
                                            }
                                        />
                                        <Statistic
                                            title={
                                                <Popover
                                                    id="orders30d-popover"
                                                    content={"Click here to open SellerCentral with last 90 days of orders"}
                                                    trigger="hover"
                                                    title={null}
                                                >
                                                    <Space direction="horizontal" align="center">
                                                        <Typography>Orders{productData.ordersData ? " (7/14/30/60/90)" : ""}</Typography>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={`https://sellercentral.amazon.com/orders-v3/search?page=1&q=${productData.ASIN}&qt=asin&date-range=last-90`}
                                                        >
                                                            <InfoCircleFilled style={{color: "green"}} />
                                                        </a>
                                                    </Space>
                                                </Popover>
                                            }
                                            value={productData.ssItem.total_order_items}
                                            precision={0}
                                            formatter={(val) =>
                                                productData.ordersData ? (
                                                    <Popover
                                                        trigger="click"
                                                        content={<OrdersPopover ordersData={productData.ordersData} />}
                                                    >
                                                        <span style={{textAlign: "center"}}>
                                                            {productData.ordersData["7"].units} / {productData.ordersData["14"].units} /{" "}
                                                            {productData.ordersData["30"].units} / {productData.ordersData["60"].units} /{" "}
                                                            {productData.ordersData["90"].units}
                                                        </span>
                                                    </Popover>
                                                ) : (
                                                    `${val}`
                                                )
                                            }
                                        />
                                        <Statistic
                                            title="GMROI"
                                            value={productData.GMROI >= 0 ? productData.GMROI : "Unknown"}
                                            precision={2}
                                        />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={6}
                                    >
                                        <Statistic
                                            title="Sessions (Our/All)"
                                            value={`${productData.OurSessions} / ${productData.Sessions}`}
                                        />
                                        <Statistic
                                            title="All Sales:"
                                            value={antAllSales}
                                            formatter={(val) => (
                                                <Typography.Text
                                                    className="editable-stat"
                                                    editable={{
                                                        icon: false,
                                                        tooltip: `Set the item's all monthly sales`,
                                                        onChange: (val) => handleParamEdit("antAllSales", val),
                                                        onEnd: () => handleParamEdit("antAllSales", null),
                                                        onCancel: () => handleParamEdit("antAllSales", null),
                                                    }}
                                                >
                                                    {val}
                                                </Typography.Text>
                                            )}
                                        />
                                        <Statistic
                                            title="CR"
                                            value={productData.CR >= 0 ? productData.CR : "Unknown"}
                                            precision={2}
                                            suffix={"%"}
                                        />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={6}
                                    >
                                        <Statistic
                                            title="Days in Stock"
                                            value={productData["Days In Stock"]}
                                            precision={0}
                                            formatter={(val) =>
                                                productData.invHealthItem && getInventoryAge(productData.invHealthItem) !== "Unknown" ? (
                                                    <Popover
                                                        trigger="click"
                                                        placement="bottom"
                                                        content={<InvHealthPopover invHealthItem={productData.invHealthItem} />}
                                                    >
                                                        <span style={{textAlign: "center"}}>
                                                            {getInventoryAge(productData.invHealthItem) !== "<30"
                                                                ? getInventoryAge(productData.invHealthItem)
                                                                : val}{" "}
                                                            <ReconciliationOutlined type="primary" />
                                                        </span>
                                                    </Popover>
                                                ) : (
                                                    `${val}`
                                                )
                                            }
                                        />
                                        <Statistic title="Est. TTS" value={productData["EstTTS"]} precision={0} />
                                        <Statistic title="Sales Source" value={antAllSalesSource} />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={6}
                                    >
                                        <Statistic title="Below Min (h)" value={productData.hoursBelowMin} precision={0} />
                                        <Statistic
                                            title="Active / Inactive"
                                            value={productData.activeSellers}
                                            precision={0}
                                            formatter={(val) =>
                                                Array.isArray(productData.inactiveSellers) ? (
                                                    <Popover
                                                        trigger="click"
                                                        content={
                                                            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                                                <List
                                                                    header={
                                                                        <div style={{textAlign: "center"}}>
                                                                            <b>{`Inactive seller Info`}</b>
                                                                        </div>
                                                                    }
                                                                    bordered
                                                                    size="small"
                                                                    dataSource={productData.inactiveSellers.map(
                                                                        (val) => `${val.sellerId} - ${val.currPrice}`
                                                                    )}
                                                                    style={{maxHeight: "100%", overflowY: "auto"}}
                                                                    renderItem={(item: string) => (
                                                                        <List.Item style={{justifyContent: "center"}}>
                                                                            <Typography.Text style={{fontSize: "12px"}}>
                                                                                {item}
                                                                            </Typography.Text>
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                            </ConfigProvider>
                                                        }
                                                    >
                                                        {val} / {productData.inactiveSellers.length}{" "}
                                                        <ReconciliationOutlined type="primary" />
                                                    </Popover>
                                                ) : (
                                                    `${val} / ${-1}`
                                                )
                                            }
                                        />
                                        <Statistic
                                            title="FBA / FBM Comps"
                                            value={productData.fbaSellers}
                                            formatter={(val) => `${val} / ${productData.fbmSellers}`}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={3}>
                            <Card
                                size="small"
                                title={`Inventory ($${productData.availableInvValue.toFixed(2)})`}
                                style={{height: "100%", textAlign: "center"}}
                                bodyStyle={{height: "95%", textAlign: "center", padding: "4px"}}
                            >
                                <Row align="top" justify="center" style={{height: "100%"}}>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={12}
                                    >
                                        <Statistic title="Fulfillable" value={productData.ssItem.fulfillable_quantity} precision={0} />
                                        <Statistic
                                            title="Ordered"
                                            value={productData.omsItems.reduce((acc, omsItem) => acc + (omsItem.Quantity ?? 0), 0)}
                                            precision={0}
                                        />
                                        <Statistic title="Shipped" value={productData.shippedCount} precision={0} />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={12}
                                    >
                                        <Statistic title="Inbound" value={productData.ssItem.fba_inbound_quantity || 0} precision={0} />
                                        <Statistic
                                            title={
                                                <Tooltip title="The values in parenthesis correspond to FC Processing and FC Transfers, respectively">
                                                    Reserved (?)
                                                </Tooltip>
                                            }
                                            value={`${productData.ssItem.fba_reserved_quantity || 0} (${
                                                productData.ssItem.reserved_fc_processing || 0
                                            } | ${productData.ssItem.reserved_fc_transfers || 0})`}
                                            precision={0}
                                        />
                                        <Statistic title="Ship Later" value={productData["Ship Later"]} precision={0} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={5}>
                            <Card
                                size="small"
                                style={{height: "100%"}}
                                bodyStyle={{height: "90%", padding: "12px"}}
                                title={
                                    <div style={{textAlign: "center"}}>
                                        <b>
                                            {`OMS - Exists in ${productData.omsItems?.length || 0} POs (Avg. Cost: $${productData.omsItems
                                                .reduce(
                                                    (acc, curr) =>
                                                        (acc +=
                                                            (curr.Cost! * curr.Quantity!) /
                                                            productData.omsItems.reduce((acc, curr) => (acc += curr.Quantity!), 0)),
                                                    0
                                                )
                                                .toFixed(2)}, MAP: ${
                                                productData.omsItems
                                                    .find((item) => item.MAP !== undefined)
                                                    ?.MAP?.toFixed(2)
                                                    .replace(/^/, "$") || "Unknown"
                                            })`}
                                        </b>
                                    </div>
                                }
                            >
                                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                    <List
                                        bordered
                                        size="small"
                                        dataSource={[...productData.omsItems].sort(
                                            (a, b) => dayjs(a.ShipDate).toDate().getTime() - dayjs(b.ShipDate).toDate().getTime()
                                        )}
                                        style={{maxHeight: "100%", overflowY: "auto"}}
                                        rowKey={(item) => item._id!}
                                        renderItem={(item: OMSItem) => {
                                            const maxCost = productData.omsItems
                                                .map((item) => item.Cost)
                                                .reduce((acc: number, curr) => (curr !== null && curr > acc ? curr : acc), 0);
                                            const minCost = productData.omsItems
                                                .map((item) => item.Cost)
                                                .reduce(
                                                    (acc: number, curr) => (curr !== null && curr < acc ? curr : acc),
                                                    Number.POSITIVE_INFINITY
                                                );
                                            const tintFactor = maxCost !== minCost ? (item.Cost! - maxCost!) / (minCost! - maxCost!) : 1;

                                            return (
                                                <List.Item style={{justifyContent: "center", alignItems: "center", padding: "8px 0px"}}>
                                                    <Row style={{width: "100%", textAlign: "center"}}>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={4}
                                                        >
                                                            <Tag
                                                                color={`rgb(${199 * (1 - tintFactor)}, ${15 * (1 - tintFactor)}, ${
                                                                    15 * (1 - tintFactor)
                                                                }, 1)`}
                                                                style={{fontSize: "12px", marginRight: "0", padding: "0 4px"}}
                                                            >
                                                                ${item.Cost!.toFixed(2)}
                                                            </Tag>
                                                        </Col>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={6}
                                                        >
                                                            {item.Supplier_SO ? (
                                                                <Typography.Text style={{fontSize: "12px"}}>
                                                                    <b>PO:</b> {item.Supplier_PO}
                                                                    <br />
                                                                    <b>SO:</b> {item.Supplier_SO}
                                                                </Typography.Text>
                                                            ) : (
                                                                <Typography.Text style={{fontSize: "12px"}}>
                                                                    {item.Supplier_PO}
                                                                </Typography.Text>
                                                            )}
                                                        </Col>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={6}
                                                        >
                                                            <Typography.Text style={{fontSize: "12px"}}>{item.ShipDate}</Typography.Text>
                                                        </Col>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={3}
                                                        >
                                                            <Typography.Text style={{fontSize: "12px"}}>{item.Quantity}</Typography.Text>
                                                        </Col>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={2}
                                                        >
                                                            <Typography.Text style={{fontSize: "12px"}}>
                                                                {item.ShipDateType}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col
                                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                            span={3}
                                                        >
                                                            <Typography.Text style={{fontSize: "12px"}}>
                                                                {item.IsPaid ? "Paid" : ""}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            );
                                        }}
                                    />
                                </ConfigProvider>
                            </Card>
                        </Col>
                    </Row>
                    <Row justify="center" style={{height: "24vh"}}>
                        <Col style={{maxHeight: "100%"}} span={5}>
                            <Card
                                size="small"
                                title={<div style={{textAlign: "center"}}>Previous notes</div>}
                                style={{height: "100%", padding: "0px"}}
                                bodyStyle={{height: "95%", padding: "0px", overflowY: "auto"}}
                            >
                                <Collapse defaultActiveKey={["0", "1"]} style={{maxHeight: "95%", overflowY: "auto"}} bordered={true}>
                                    {productData.notes?.map((note: NoteItem, idx: number) => (
                                        <Collapse.Panel
                                            header={`[${dayjs(note.insertTimestamp).format("MM-DD-YY")}] ${
                                                note.author?.split("@")[0]
                                            } - ${note.actions.join(", ")}`}
                                            extra={
                                                note.hasData ? (
                                                    <OrderedListOutlined
                                                        onClick={(event) => {
                                                            message.info("Downloading Note data", 1);
                                                            console.log("Picked note", note);
                                                            currentUser!
                                                                .getIdToken()
                                                                .then((token: string) => getNoteDetails(token, note._id!))
                                                                .then((notes: NoteItem[]) => {
                                                                    if (notes.length > 0) {
                                                                        const note = notes[0];
                                                                        setPreviousData(note.productData! as SingularItem);
                                                                        notificationApi.info({
                                                                            icon: null,
                                                                            message: `Note saved on ${dayjs(note.insertTimestamp)
                                                                                .tz("America/Chicago")
                                                                                .format("MM-DD-YYYY HH:mm")}`,
                                                                            style: {width: "1000px"},
                                                                            duration: 0,
                                                                            placement: "bottomRight",
                                                                            description: (
                                                                                <PreviousNotePopup
                                                                                    productData={note.productData! as SingularItem}
                                                                                />
                                                                            ),
                                                                        });
                                                                    } else {
                                                                        message.error("Failed to download Note data");
                                                                    }
                                                                });

                                                            event.stopPropagation();
                                                        }}
                                                    />
                                                ) : null
                                            }
                                            key={idx}
                                        >
                                            {note?.stats?.prevMin !== note?.stats?.newMin ? (
                                                <div>
                                                    Min Price: {note?.stats?.prevMin} to {note?.stats?.newMin}
                                                </div>
                                            ) : null}
                                            {note?.stats?.prevMax !== note?.stats?.newMax ? (
                                                <div>
                                                    Max Price: {note?.stats?.prevMax} to {note?.stats?.newMax}
                                                </div>
                                            ) : null}
                                            {note?.stats?.prevPreset !== note?.stats?.newPreset ? (
                                                <div>
                                                    Preset: {note?.stats?.prevPreset} to {note?.stats?.newPreset}
                                                </div>
                                            ) : null}
                                            <div>{note.noteText}</div>
                                        </Collapse.Panel>
                                    ))}
                                </Collapse>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={2}>
                            <Card
                                title={
                                    <div style={{textAlign: "center"}}>
                                        <b>{`${productData.Issues.length || "No"} issues`}</b>
                                    </div>
                                }
                                size="small"
                                style={{height: "100%", textAlign: "center"}}
                                bodyStyle={{height: "100%", textAlign: "center", padding: "12px"}}
                            >
                                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                    <List
                                        bordered
                                        size="small"
                                        dataSource={productData.Issues}
                                        style={{overflow: "auto", maxHeight: "100%"}}
                                        renderItem={(item: string) => (
                                            <List.Item style={{justifyContent: "center"}}>
                                                <Typography.Text style={{fontSize: "12px"}}>{item}</Typography.Text>
                                            </List.Item>
                                        )}
                                    />
                                </ConfigProvider>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={5}>
                            <Card
                                size="small"
                                style={{height: "100%", padding: "0px"}}
                                bodyStyle={{textAlign: "center", height: "100%", padding: "0px"}}
                            >
                                <Row align="top" justify="center" style={{height: "25%"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column",
                                        }}
                                        flex={1}
                                        span={9}
                                    >
                                        <Typography.Text>New Min Price:</Typography.Text>
                                        <InputNumber
                                            precision={2}
                                            prefix={"$"}
                                            onFocus={(e) => e.target.select()}
                                            onBlur={() => {
                                                if (minPrice > maxPrice) {
                                                    setMaxPrice(minPrice);
                                                }
                                            }}
                                            onPressEnter={(e) => minPriceInputRef.current?.blur()}
                                            onChange={(val) => handleParamEdit("minPrice", val?.toFixed(2) ?? null)}
                                            ref={minPriceInputRef}
                                            value={minPrice}
                                        />
                                    </Col>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={6}
                                    >
                                        <Button
                                            type="primary"
                                            disabled={!(productData.insight?.SuggestedPrice > 0)}
                                            onClick={() => handleParamEdit("minPrice", productData.insight?.SuggestedPrice.toFixed(2))}
                                        >
                                            Suggested
                                        </Button>
                                        <Button type="primary" onClick={() => handleParamEdit("minPrice", mvsp.toFixed(2))}>
                                            MVSP
                                        </Button>
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column",
                                        }}
                                        flex={1}
                                        span={9}
                                    >
                                        <Typography.Text>New Max Price:</Typography.Text>
                                        <InputNumber
                                            precision={2}
                                            prefix={"$"}
                                            onFocus={(e) => e.target.select()}
                                            onBlur={() => {
                                                if (maxPrice < minPrice) {
                                                    setMinPrice(maxPrice);
                                                }
                                            }}
                                            onPressEnter={(e) => maxPriceInputRef.current?.blur()}
                                            onChange={(val) => handleParamEdit("maxPrice", val?.toFixed(2) ?? null)}
                                            ref={maxPriceInputRef}
                                            value={maxPrice}
                                        />
                                    </Col>
                                </Row>
                                <Row align="top" justify="center" style={{height: "15%"}}>
                                    <Col
                                        style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}
                                        flex={1}
                                        span={24}
                                    >
                                        <Select
                                            disabled={productData.ssItem.tags.includes("Hold") || tags.includes("Hold")}
                                            style={{width: "100%", maxHeight: "100%", overflowY: "auto"}}
                                            onChange={(val: string) => setRepriceConfigPreset(val)}
                                            value={repriceConfigPreset}
                                        >
                                            <Select.Option key={1} value={"Clothes and Shoes Preset"}>
                                                {"Clothes and Shoes Preset"}
                                            </Select.Option>
                                            <Select.Option key={2} value={"WinBB85"}>
                                                {"WinBB85"}
                                            </Select.Option>
                                            <Select.Option key={3} value={"Match"}>
                                                {"Match"}
                                            </Select.Option>
                                            <Select.Option key={3} value={"BreakMAPWinBB"}>
                                                {"BreakMAPWinBB"}
                                            </Select.Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row align="top" justify="center" style={{height: "25%"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "2px",
                                        }}
                                        span={24}
                                    >
                                        <Select
                                            mode="tags"
                                            value={actions}
                                            style={{width: "100%", maxHeight: "100%", overflowY: "auto"}}
                                            onChange={(val: string[]) => setActions(val)}
                                            tokenSeparators={[","]}
                                            placeholder="What we gonna do about this chief?"
                                        >
                                            {POSSIBLE_ACTIONS.map((action: string, idx: number) => (
                                                <Select.Option key={idx} value={action}>
                                                    {action}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>

                                <Row align="middle" justify="center" style={{height: "35%"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "2px",
                                        }}
                                        span={17}
                                    >
                                        <Input.TextArea
                                            value={noteText}
                                            onChange={(e) => setNoteText(e.target.value)}
                                            placeholder="Note text goes here chief"
                                            autoSize={{minRows: 3, maxRows: 4}}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                        span={6}
                                    >
                                        <Button
                                            style={{width: "80%"}}
                                            type="primary"
                                            disabled={!noteSaveEnabled || actions.length === 0}
                                            onClick={saveSingularNote}
                                        >
                                            Save
                                        </Button>
                                        <ScheduleButton productData={productData}></ScheduleButton>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={5}>
                            <Card
                                title={<div style={{textAlign: "center"}}>Profit Simulator</div>}
                                size="small"
                                style={{height: "100%"}}
                                bodyStyle={{height: "95%", padding: "12px", textAlign: "center"}}
                            >
                                <Row align="middle" justify="center" style={{height: "100%"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            overflow: "hidden",
                                        }}
                                        span={24}
                                    >
                                        <ProfitSimulator productData={productData} newMinPrice={minPrice} setMVSPCard={setMVSP} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{maxHeight: "100%"}} span={7}>
                            <Card style={{height: "100%"}} bodyStyle={{height: "100%", padding: "12px"}}>
                                <Row align="middle" justify="center" style={{height: "100%"}}>
                                    <Col
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            overflow: "hidden",
                                        }}
                                        span={24}
                                    >
                                        {/* <iframe style={{width: '200%', 'height': '200%', transform: 'scale(0.5)'}} title="Keepa Graph" src={`https://keepa.com/#!product/1-B07MVKTJJM#priceHistory`}></iframe> */}
                                        {/* <iframe style={{width: '200%', 'height': '500px', transform: 'scale(0.5)', position: 'absolute'}} title="Keepa Graph" src={`https://keepa.com/iframe_addon.html#1-0-B07MVKTJJM`}></iframe> */}
                                        {/* <object 
                                        style={{width: '100%', 'height': '300px', position: 'absolute'}}
                                        data="https://keepa.com/iframe_addon.html#1-0-B07MVKTJJM"
                                        type="text/html">
                                    </object> */}
                                        <Link
                                            style={{height: "100%", width: "100%"}}
                                            href={`https://keepa.com/#!product/1-${productData.ASIN}`}
                                            target="_blank"
                                        >
                                            <Image
                                                style={{height: "100%", width: "100%"}}
                                                preview={false}
                                                src={`https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&bb=1&asin=${productData.ASIN}&range=365`}
                                                alt="Keepa graph"
                                            />
                                        </Link>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row justify="center" style={{height: "40vh"}}>
                        <Col style={{height: "100%"}} span={4}>
                            <InsightsSummary insight={insight ?? productData.insight} productData={productData} />
                        </Col>
                        <Col style={{height: "100%"}} span={20}>
                            <InsightsTable
                                prevData={previousData?.insight}
                                lite={false}
                                insight={insight ?? productData.insight}
                            ></InsightsTable>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    );
};

export default SingularItemCard;
