import {DownloadOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Button, Modal, Switch, Space, Form, Divider} from "antd";
import React from "react";
import * as dataForge from "data-forge";
import {CSVDownloadProps} from "../utilities/CSVDownload";
import {WholesaleItem} from "../../types/WholesaleItem";
import ExcelJS from "exceljs";
import {SUPPLIER_PREFIX} from "../../services/WholesaleService";
import {getColumnTitle} from "../utilities/TableFilterSorters";

/**
 * Autofit columns by width
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
export const autoWidth = (worksheet: any, minimalWidth = 10, maximumWidth = 0) => {
    worksheet.columns.forEach((column: any) => {
        let maxColumnLength = 0;
        column.eachCell({includeEmpty: true}, (cell: any) => {
            maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0);
        });
        if (maximumWidth > 0) {
            column.width = Math.min(maxColumnLength + 2, maximumWidth);
        } else {
            column.width = maxColumnLength + 2;
        }
    });
};

const PrepData = (data: WholesaleItem[], filteredData: number[], skipZeroes: boolean, columnData: any[], extraOptions: any) => {
    const filteredFrame = new dataForge.DataFrame(data).where(
        (row) => filteredData.length === 0 || (filteredData.length > 0 && filteredData.includes(row.key))
    );

    const globalFrame = filteredFrame.where((row) => !skipZeroes || row.BuyQTY > 0);

    console.log("export data", globalFrame.head(5).toArray());

    const supplierColumns = globalFrame.getColumnNames().filter((name) => name.includes(SUPPLIER_PREFIX));

    const firstSheetData = globalFrame
        .subset(["ASIN", "name", "sellPrice", "BuyQTY", "AskPrice"])
        .generateSeries({
            MinPrice: (row) => (row.sellPrice > 0 ? parseFloat((row.sellPrice * 0.8).toFixed(2)) : 0),
            MaxPrice: (row) => (row.sellPrice > 0 ? parseFloat((row.sellPrice * 1.5).toFixed(2)) : 0),
        })
        .transformSeries({
            AskPrice: (val) => (val > 0 ? val : "Manual Input Needed"),
        })
        .dropSeries("sellPrice")
        .renameSeries({
            name: "Title",
            BuyQTY: "Quantity",
            AskPrice: "Cost",
        });

    const secondSheetData = globalFrame;

    const thirdSheetData = globalFrame.subset(["UPC", "BuyQTY", "AskPrice", ...supplierColumns]).renameSeries({
        BuyQTY: "Quantity",
    });

    const fourthSheetData = globalFrame
        .subset(columnData.map((column) => column.dataIndex).filter((col) => col !== "keepaGraph"))
        .renameSeries(
            columnData
                .filter((col) => col.dataIndex !== "keepaGraph" && col.dataIndex !== getColumnTitle(col))
                .reduce((acc, curr) => ({...acc, [curr.dataIndex]: getColumnTitle(curr)}), {})
        )
        .reorderSeries(columnData.map((column) => getColumnTitle(column)).filter((col) => col !== "Keepa"));

    const fifthSheetData = globalFrame
        .renameSeries({
            BuyQTY: "Final Quantity",
            onHand: "Order Quantity",
        })
        .subset([
            "Supplier_PO",
            "UPC",
            "Supplier_SKU",
            "Supplier_Description",
            "Supplier_Price",
            `Order Quantity`,
            `Add Quantity`,
            `Cancel Quantity`,
            `Final Quantity`,
        ])
        .select((row) => {
            const newRow = {...row};
            const difference = row["Order Quantity"] - row["Final Quantity"];
            if (difference < 0) {
                newRow["Add Quantity"] = -difference;
            } else {
                newRow["Cancel Quantity"] = difference;
            }
            return newRow;
        })
        .renameSeries({
            Supplier_PO: "PO",
            Supplier_SKU: "SKU",
            Supplier_Description: "Description",
            Supplier_Price: "Price",
        });

    const orderFromPriceColumn = extraOptions?.orderFromPriceColumn ?? "Supplier_Price";
    const sixthSheetData = globalFrame
        .subset([
            "UPC",
            "Supplier_SKU",
            "Supplier_Style",
            "Supplier_Color",
            "Supplier_Size",
            "Supplier_Width",
            "Supplier_Description",
            "BuyQTY",
            "Supplier_Retail Price",
            orderFromPriceColumn,
            "Total",
        ])
        .select((row) => {
            const newRow = {...row};
            if (typeof row[orderFromPriceColumn] === "string" && row[orderFromPriceColumn].length > 0) {
                newRow[orderFromPriceColumn] = parseFloat(row[orderFromPriceColumn].replace(/\$/g, ""));
            }
            newRow["Total"] = parseFloat((row["BuyQTY"] * (newRow[orderFromPriceColumn] ?? 0)).toFixed(2));
            return newRow;
        })
        .renameSeries({
            "Supplier_SKU": "SKU",
            "Supplier_Style": "Style",
            "Supplier_Color": "Color",
            "Supplier_Size": "Size",
            "Supplier_Width": "Width",
            "Supplier_Description": "Description",
            "Supplier_Retail Price": "Retail Price",
            [orderFromPriceColumn]: "Price",
        });

    const reviewSheetData = globalFrame.subset(["ASIN", "BuyQTY", "AskPrice"]).generateSeries({
        OrderAmount: (row) => parseFloat((row.BuyQTY * row.AskPrice).toFixed(2)),
    });

    const omsOneSheetData = globalFrame
        .generateSeries({
            Supplier_Name: (row) => row.sourceCollection.split("-")[1][0] + row.sourceCollection.split("-")[1].slice(1).toLowerCase(),
            Supplier_SKU: (row) => row.Supplier_SKU || row.Supplier_SupplierSKU || row.SKU || "",
            Supplier_Title: (row) => row.Supplier_Title || row.Supplier_SupplierTitle || row.Title || "",
        })
        .subset([
            "Supplier_Name",
            "Supplier_Date",
            "Supplier_PO",
            "Supplier_SO",
            "Supplier_SKU",
            "Supplier_Title",
            "Supplier_Notes",
            "ASIN",
            "UPC",
            "BuyQTY",
            "AskPrice",
            "Ship_Requested",
            "Replenishable?",
            "OrderType",
            "VendorType",
            "MAP",
        ])
        .renameSeries({
            BuyQTY: "Quantity",
            AskPrice: "Cost",
        });

    const dashboardData = filteredFrame
        .generateSeries({
            Brand: (row) => row.sourceCollection.split("_")[1],
            SupplierSKU: (row) => row.Supplier_SKU || row.Supplier_SupplierSKU || row.SKU || "",
            ExclusivityValue: (row) => row.EstChildSales * (1 - row.bbMultiplier),
        })
        .ensureSeries({
            Supplier_MAP: (df) => df.getSeries("ASIN").select((val) => ""),
            Supplier_MSRP: (df) => df.getSeries("ASIN").select((val) => ""),
        })
        .subset([
            "ASIN",
            "Brand",
            "SupplierSKU",
            "Supplier_MAP",
            "Supplier_MSRP",
            "UPC",
            "parentASIN",
            "name",
            "AskPrice",
            "EstChildSales",
            "EstChildSpend",
            "EstChildUnitSales",
            "EstParentSales",
            "DiscountNeeded",
            "ExclusivityValue",
            "opportunities",
        ])
        .renameSeries({
            Supplier_MAP: "MAP",
            Supplier_MSRP: "MSRP",
            name: "Title",
            parentASIN: "ParentASIN",
            AskPrice: "Cost",
        });

    const workbook = new ExcelJS.Workbook();
    const firstSheet = workbook.addWorksheet("Buy Sheet");
    firstSheet.columns = firstSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const secondSheet = workbook.addWorksheet("Purchase Data");
    secondSheet.columns = secondSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const reviewSheet = workbook.addWorksheet("Review Data");
    reviewSheet.columns = reviewSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const omsOneSheet = workbook.addWorksheet("OMS S01 Data");
    omsOneSheet.columns = omsOneSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const thirdSheet = workbook.addWorksheet("Offer Data");
    thirdSheet.columns = thirdSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const fourthSheet = workbook.addWorksheet("Column Data");
    fourthSheet.columns = fourthSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const fifthSheet = workbook.addWorksheet("PO Eval");
    fifthSheet.columns = fifthSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const sixthSheet = workbook.addWorksheet("Order Form");
    sixthSheet.columns = sixthSheetData.getColumnNames().map((column) => ({header: column, key: column}));
    const dashboardSheet = workbook.addWorksheet("Dashboard Data");
    dashboardSheet.columns = dashboardData.getColumnNames().map((column) => ({header: column, key: column}));

    firstSheet.addRows(firstSheetData.toArray());
    secondSheet.addRows(secondSheetData.toArray());
    thirdSheet.addRows(thirdSheetData.toArray());
    fourthSheet.addRows(fourthSheetData.toArray());
    fifthSheet.addRows(fifthSheetData.toArray());
    sixthSheet.addRows(sixthSheetData.toArray());
    reviewSheet.addRows(reviewSheetData.toArray());
    omsOneSheet.addRows(omsOneSheetData.toArray());
    dashboardSheet.addRows(dashboardData.toArray());

    autoWidth(firstSheet);
    autoWidth(secondSheet);
    autoWidth(thirdSheet);
    autoWidth(fourthSheet);
    autoWidth(fifthSheet);
    autoWidth(sixthSheet);
    autoWidth(reviewSheet);
    autoWidth(omsOneSheet);
    autoWidth(dashboardSheet);

    return workbook;
};

export function ExcelDownload({data, isLoading, collection, filteredData, columnData, extraOptions}: CSVDownloadProps) {
    const [settingForm] = Form.useForm();

    const download = async ({skipZeroes, useOFPrice}: any) => {
        if (!data || !data.length) return;

        const workbook = PrepData(data, filteredData!, skipZeroes, columnData ?? [], {
            orderFromPriceColumn: useOFPrice ? "Supplier_Price" : "AskPrice",
        });
        const text = await workbook.xlsx.writeBuffer();

        const file = new Blob([text], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = collection + ".xlsx";
        a.click();
        URL.revokeObjectURL(url);
        a.parentNode?.removeChild(a);
    };

    const {confirm} = Modal;

    function showConfirm() {
        let content = [];

        if (filteredData!.length !== 0 && filteredData!.length !== data!.length) {
            content.push(<span key={1}> - Some items have been filtered out!</span>);
        }

        if (data?.some((item) => item.AskPrice !== item.cost)) {
            content.push(
                <span key={2}>
                    {" "}
                    - Some items have had their AskPrice edited! If you want to carry the discount please pay extra attention and do not use
                    Cost from <b>"Order Form"</b> or <b>"PO Eval"</b> Tabs.
                </span>
            );
        }

        if (data?.some((item) => item.saved !== true)) {
            content.push(<span key={4}> - You're exporting a collection that contains unsaved items!</span>);
        }

        if (data?.some((item) => item.isBundle)) {
            content.push(
                <span key={5}>
                    {" "}
                    - You're exporting a collection that contains items that had <b>IsBundle</b> set to true!
                </span>
            );
        }

        if (data?.some((item) => item.isRestricted)) {
            content.push(
                <span key={6}>
                    {" "}
                    - You're exporting a collection that contains items that are <b>Restricted</b>!
                </span>
            );
        }

        if (data?.some((item) => item.isHazmat)) {
            content.push(
                <span key={7}>
                    {" "}
                    - You're exporting a collection that contains items that had <b>Hazmat</b> set to true!
                </span>
            );
        }

        if (data?.some((item) => item.packSize && item.packSize > 1)) {
            content.push(
                <span key={8}>
                    {" "}
                    - You're exporting a collection that contains items that had their <b>Pack Size</b> set to above 1!
                </span>
            );
        }

        if (content.length > 0) {
            content.unshift(<b key={0}>Please review the following:</b>);
            content.push(<Divider style={{margin: "4px 0px"}} />);
            content.push(<b>Settings:</b>);
        }

        content.push(
            <Form
                form={settingForm}
                name="settingFormValues"
                layout="vertical"
                initialValues={{
                    skipZeroes: true,
                    useOFPrice: true,
                }}
                onFinish={() => null}
            >
                <Form.Item name="skipZeroes" label="Do you want to skip items with 0 BuyQTY?" valuePropName="checked">
                    <Switch checkedChildren="Skip 0s" unCheckedChildren="Include 0s" title=""></Switch>
                </Form.Item>
                <Form.Item name="useOFPrice" label="Do you want to use Price column in the Order Form?" valuePropName="checked">
                    <Switch checkedChildren="OF Price" unCheckedChildren="OF AskPrice" title=""></Switch>
                </Form.Item>
            </Form>
        );

        confirm({
            title: "Export Information",
            icon: <ExclamationCircleOutlined />,
            content: <Space direction="vertical">{content}</Space>,
            onOk() {
                const values = settingForm.getFieldsValue();
                download(values);
            },
            onCancel() {
                console.log("Cancel");
                settingForm.resetFields();
            },
        });
    }

    return (
        <Button block type="primary" onClick={showConfirm} disabled={!data || data.length === 0 || isLoading}>
            <DownloadOutlined /> Download XLSX
        </Button>
    );
}
