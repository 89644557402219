import { Button, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useOmega } from "../../contexts/OmegaContext";
import { NoteItem } from "../../types/OmegaTypes";
import { getPriceRenderer } from "../utilities/TableFilterSorters";
import * as dataForge from "data-forge"
import dayjs from "dayjs";

interface DataType extends NoteItem {
    key: React.Key;
}

const download = (data: any[]) => {
    if (!data || !data.length) return;

    console.log(data)
    const text = (new dataForge.DataFrame(data)).toCSV()

    const file = new Blob([text], { type: "text/plain;charset=utf-8" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = 'Omega_Notes_SSImport_' + dayjs().format('MM-DD-YY') + ".csv";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
};

const NoteBrowser: React.FC = () => {
    const [tableData, setTableData] = useState<(NoteItem & { key: number })[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { changesMade, setChangesMade } = useOmega();

    console.log('Note browser', changesMade)

    const columns: ColumnsType<DataType> = [
        {
            title: 'SKU',
            dataIndex: 'SKU',
            key: 'SKU',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (actions) =>
                <Space direction="vertical" align="center" style={{ width: "100%" }}>
                    {
                        actions.map((action: string, idx: number) =>
                            <Tag style={{ marginRight: 0 }} key={idx}>
                                {action}
                            </Tag>
                        )
                    }
                </Space>
        },
        {
            title: 'Note',
            dataIndex: 'noteText',
            key: 'noteText',
            width: "200px",
        },
        {
            title: 'prevMin',
            dataIndex: ['stats', 'prevMin'],
            key: 'prevMin',
            ...getPriceRenderer(),
        },
        {
            title: 'newMin',
            dataIndex: ['stats', 'newMin'],
            key: 'newMin',
            ...getPriceRenderer(),
        },
        {
            title: 'prevMax',
            dataIndex: ['stats', 'prevMax'],
            key: 'prevMax',
            ...getPriceRenderer(),
        },
        {
            title: 'newMax',
            dataIndex: ['stats', 'newMax'],
            key: 'newMax',
            ...getPriceRenderer(),
        },
        {
            title: 'prevPreset',
            dataIndex: ['stats', 'prevPreset'],
            key: 'prevPreset',
        },
        {
            title: 'newPreset',
            dataIndex: ['stats', 'newPreset'],
            key: 'newPreset',
        },

    ]

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteNotes = () => {
        const newNoteSet = tableData.filter((row) => !selectedRowKeys.includes(row.key)).map(({ key, ...note }) => note)
        setChangesMade(newNoteSet);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        setTableData(changesMade.map((note, idx) => ({ ...note, key: idx })))
    }, [changesMade])

    return (
        <Table<DataType>
            rowClassName={() => 'editable-row'}
            scroll={{ y: "80vh", x: "max-content" }}
            sticky={true}
            bordered={true}
            size="small"
            tableLayout={"auto"}
            dataSource={tableData}
            title={() =>
                <Space direction="horizontal" style={{ width: '100%', justifyContent: "end" }}>
                    <Button onClick={() => download(tableData.map((note) => {
                        const newRow = {
                            'Date': dayjs(note.insertTimestamp).tz('America/Chicago').format("MM-DD-YYYY HH:mm"),
                            'SKU': note.SKU,
                            'ASIN': note.productData?.ASIN,
                            'InvValue': note.productData?.invValue.toFixed(2),
                            'Actions': note.actions.join(';'),
                            'PrevMin': note.stats.prevMin,
                            'NewMin': note.stats.newMin,
                            'PrevMax': note.stats.prevMax,
                            'NewMax': note.stats.newMax,
                            'Text': note.noteText,
                            'Author': note.author
                        }

                        return newRow;
                    }))} type="primary">Export Notes</Button>
                    <Button onClick={deleteNotes} type="primary" danger>Delete Selected</Button>
                </Space>
            }
            pagination={{
                pageSizeOptions: ["25", "50", "100"],
                defaultPageSize: 25,
                showSizeChanger: true,
            }}
            rowSelection={rowSelection}
            columns={columns}
            showSorterTooltip={false}
        >
        </Table>
    )
}

export default NoteBrowser