import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider, message, Space } from "antd";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { getAdidasUPC, getAdidasUPCNew, postAdidasUPC, postAdidasUPCNew } from "../../../services/OmegaService";
import { downloadHelper, excelDownloadHelper, sheetJSDownloadObjects } from "./Downloads";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { sizeIndex } from "../common";
import * as SheetJS from "xlsx";

export const mapHeadersSheetJS = (headers: any[], row: any) => {
    let result: any = {};
    Object.entries(headers).forEach(([key, value]) => {
        result[value] = row[key];
    })

    return result;
}

export const processDataAdidasHelper = async (modal: any, currentUser: any, data: ArrayBuffer) => {
    console.log('processing');
    const adidasUPCs = await currentUser!.getIdToken().then((token: string) => getAdidasUPC(token))
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);

    const sheetsToProcess = workbook.worksheets.filter((sheet) => sheet.name.includes('My Worksheet'));
    console.log(sheetsToProcess);

    const finalResult: any[] = [];
    const finalIndividualResult: any[] = [];
    const sizesDict = new Map();
    const upcMap: { [key: string]: any } = {};
    const upcFound: { [key: string]: any } = {};
    const quantityMap: { [key: string]: any } = {};
    const encounterMap: { [key: string]: any } = {};
    const styleSheetMap: { [key: string]: any } = {};

    for (const upcData of adidasUPCs) {
        upcMap[upcData.Style] = { ...(upcMap[upcData.Style] || {}), [upcData.Size]: upcData.UPC }
    }

    for (const sheet of sheetsToProcess) {
        let i = 1;
        let replacementRowIdx = i;

        const lastRowNum = sheet.rowCount;

        for (let i = 1; i < lastRowNum; i++) {
            replacementRowIdx = i;
            const currentRow = (sheet.getRow(i).values as any[]);
            const currentData: { [key: string]: any } = {};
            if (currentRow[1 + 1]?.toString().length > 0 && currentRow[1 + 1] !== "Style" && currentRow[1 + 1] !== ' ') {
                currentData.styleNumber = currentRow[1 + 1].toString().length === 5 ? `0${currentRow[1 + 1].toString()}` : currentRow[1 + 1].toString();
                currentData.title = currentRow[12 + 1];
                currentData.wholesalePrice = currentRow[23 + 1];
                currentData.retailPrice = currentRow[26 + 1];
                currentData.adiCost = currentData.wholesalePrice * 0.8;
                currentData.ourCost = currentData.adiCost * 1.1;

                if (styleSheetMap[currentData.styleNumber]) continue;
                else styleSheetMap[currentData.styleNumber] = true;

                let sizesFinished = false;
                let quantitiesFinished = false;
                let helperIndex = 1;
                let DATE_INDEX = 4;
                let localSizedDict = new Map();
                // Column E (index 4) contains "Total", we will use it to get sizes (var j), which start on index 5 and continue until first empty cell
                while (!quantitiesFinished) {
                    const helperRow = (sheet.getRow(replacementRowIdx + helperIndex).values as any[]);
                    if (!sizesFinished && helperRow?.[5] === "Total") {
                        // Find the sizes
                        for (let j = 6; !sizesFinished; j++) {
                            console.log('trying size index', j)
                            if (helperRow[j] && (helperRow[j]?.toString().length > 0)) {
                                sizesDict.set(helperRow[j].toString(), 0); // Add to global size compedium
                                localSizedDict.set(helperRow[j].toString(), 0); // Also add to local size compedium
                            } else {
                                sizesFinished = true; // If the cell is empty, we've found all the sizes for the item
                            }
                        }
                    } else if (sizesFinished) {
                        // Column D (index 3) contains the available dates
                        if (helperRow && helperRow.length > 0 && helperRow?.[DATE_INDEX]?.length > 0) {
                            const newItem = { ...currentData };
                            // @ts-ignore
                            const sizes = [...localSizedDict.keys()];
                            newItem.date = helperRow[DATE_INDEX];

                            newItem.quantities = new Map();
                            helperRow.slice(6, 6 + sizes.length).forEach((quant, idx) => {
                                newItem.quantities.set(sizes[idx], quant === '+300' ? '300' : quant)
                            });

                            // eslint-disable-next-line no-loop-func
                            [...newItem.quantities.entries()].forEach(([size, quant], idx) => {
                                const quantityItem: { [key: string]: any } = { ...newItem };
                                delete quantityItem.quantities;
                                quantityItem.size = size;
                                quantityItem.quantity = quant;
                                // Find next non-zero quantity
                                let nextFound = false;
                                for (let j = 1; !nextFound; j++) {
                                    const nextRow = (sheet.getRow(replacementRowIdx + helperIndex + j)?.values as any[]);
                                    // console.log('nextRow processing', nextRow, replacementRowIdx + helperIndex + j)
                                    if (nextRow) {
                                        if (nextRow?.[DATE_INDEX]?.length > 0) {
                                            if (parseInt(nextRow[6 + idx]) > 0) {
                                                // if (new Date(nextRow[SIZE_INDEX]).getMonth() < 11 && new Date(nextRow[SIZE_INDEX]).getMonth() > 7) {
                                                quantityItem.nextDate = nextRow[DATE_INDEX];
                                                quantityItem.nextQuant = (nextRow[6 + idx] === '+300' ? '300' : nextRow[6 + idx]);
                                                // }
                                                nextFound = true;
                                            }
                                        } else {
                                            nextFound = true;
                                        }
                                    } else {
                                        nextFound = true;
                                    }
                                }
                                quantityItem.upc = upcMap[quantityItem.styleNumber]?.[quantityItem.size] ?? 'Unknown'
                                if (parseInt(quant) > 0) {
                                    // if(new Date(quantityItem.date).getMonth() < 11 && new Date(quantityItem.date).getMonth() > 7) {
                                    quantityMap[quantityItem.upc] = parseInt(quant) + (quantityMap[quantityItem.upc] || 0)
                                    encounterMap[quantityItem.upc] = 1 + (encounterMap[quantityItem.upc] || 0)

                                    quantityItem.encounterCount = encounterMap[quantityItem.upc];

                                    if (!upcFound[quantityItem.upc]) {
                                        upcFound[quantityItem.upc] = quantityItem.date;
                                    }

                                    quantityItem.sinceFirstShip = quantityItem.nextDate ? dayjs(dayjs(quantityItem.nextDate)).diff(upcFound[quantityItem.upc], 'day') : -1;
                                    quantityItem.runningQuantitySum = quantityMap[quantityItem.upc]
                                    finalIndividualResult.push(quantityItem);
                                    // }
                                }
                            })

                            finalResult.push(newItem);
                        } else {
                            quantitiesFinished = true;
                        }
                    }
                    replacementRowIdx++;
                    i++;
                }
            }
        }

    }

    let upcsNotFoundStyleNumbers: { [key: string]: any } = {};
    let uniqueUpcs: { [key: string]: any } = {}
    for (const resultRow of finalIndividualResult) {
        if (resultRow.upc === 'Unknown') {
            upcsNotFoundStyleNumbers[resultRow.styleNumber] = true;
        } else {
            uniqueUpcs[resultRow.upc] = true;
        }
    }

    console.log(finalIndividualResult);

    let processFinal = (uniqueUpcs: { [key: string]: any }, finalResult: any[]) => {
        const upcFile = Object.keys(uniqueUpcs).map((val) => ({ 'UPC': val }))
        downloadHelper(upcFile, `Adidas-${dayjs().format('MM-DD-YYYY')}-Unique-UPCs`)
        const chunkSize = 10000;
        for (let i = 0; i < finalResult.length; i += chunkSize) {
            const chunk = finalResult.slice(i, i + chunkSize).map((row) => {
                return {
                    'PO': '',
                    'UPC': row.upc,
                    'SKU': `${row.styleNumber}_${row.size}`,
                    'Style': row.styleNumber,
                    'Color': '',
                    'Size': row.size,
                    'Width': '',
                    'Description': row.title,
                    'OH': row.quantity,
                    'Retail Price': row.retailPrice,
                    'Price': row.adiCost,
                    'Cost': row.ourCost,
                    'Ship Date': row.date,
                    'Wholesale Price': row.wholesalePrice,
                    // 'Adidas Cost': row.adiCost,
                    'Next Date': row.nextDate,
                    'Next Quant': row.nextQuant,
                    'RunningQtySum': row.runningQuantitySum,
                    'SinceFirstShip': row.sinceFirstShip,
                    'Encounter': row.encounterCount,
                    'MultipleDates': encounterMap[row.upc] > 1 ? 'Yes' : 'No',
                }
            });
            downloadHelper(chunk, `Adidas-${dayjs().format('MM-DD-YYYY')}-Result-${Math.floor(i / chunkSize) + 1}`)
        }
    }

    if (Object.keys(upcsNotFoundStyleNumbers).length > 0) {
        const chunkSize = 200;
        const styleNumberChunks = [];
        for (let i = 0; i < Object.keys(upcsNotFoundStyleNumbers).length; i += chunkSize) {
            const chunk = Object.keys(upcsNotFoundStyleNumbers).slice(i, i + chunkSize);
            styleNumberChunks.push(chunk);
        }

        modal.confirm({
            title: `You're missing some UPCs (currently ${finalIndividualResult.filter((row) => row.upc !== 'Unknown').length}/${finalIndividualResult.length} items have ${Object.keys(uniqueUpcs).length} unique UPCs) for the following style numbers, do you want to upload them first (press Cancel) or download anyways (press OK)?`,
            icon: <ExclamationCircleOutlined />,
            content:
                <Space style={{ width: '90%' }} direction="vertical" split={<Divider type="horizontal"></Divider>}>
                    {
                        styleNumberChunks.map((chunk, idx) => {
                            return <div key={idx}>
                                <label htmlFor={`chunk${idx}`}>Chunk of 200 ({idx + 1}/{styleNumberChunks.length})</label>
                                <TextArea id={`chunk${idx}`} value={chunk.join(',')} />
                            </div>
                        }
                        )
                    }
                </Space>,
            onOk() {
                processFinal(uniqueUpcs, finalIndividualResult)
            },
            onCancel() {
                console.log('Cancelled download');
            },
        })
    } else {
        processFinal(uniqueUpcs, finalIndividualResult);
    }
}

export const processDataAdidasUPCHelper = async (currentUser: any, data: ArrayBuffer, isNew: boolean = false) => {
    console.log('processing');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);

    let result: any[] = [];
    workbook.worksheets[0].eachRow({ includeEmpty: true }, function (row, rowNumber) {
        const rowValues = row.values as any[];
        if (rowValues[2] === 'Source' || rowValues[2] === 'Description') {
            // header row, skip
        } else {
            // big file
            if (rowValues[3]?.match(/\d{12}/g)) {
                const [style, size] = rowValues[5].split("_")
                result.push({
                    'UPC': rowValues[3],
                    'Style': style,
                    'Size': size,
                })
            } else if (rowValues[6]?.match(/\d{12}/g)) {
                result.push({
                    'UPC': rowValues[6],
                    'Style': rowValues[1].toString().trim(),
                    'Size': rowValues[5].toString().trim(),
                })
            } else if (rowValues[7]?.match(/\d{12}/g)) {
                result.push({
                    'UPC': rowValues[7],
                    'Style': rowValues[2].toString().trim(),
                    'Size': rowValues[6].toString().trim(),
                })
            }
        }
    });

    message.info(`Parsed ${result.length} UPCs`)
    console.log(result);

    if (isNew) {
        const previousProds = (await currentUser!.getIdToken().then((token: string) => getAdidasUPCNew(token)))
            .reduce((acc: any, curr: any) => ((acc[curr.UPC] = curr, acc)), {})

        const newProds = result.filter((prod) => !!!previousProds[prod.UPC])
        if (newProds.length > 0) {
            downloadHelper(newProds, `${dayjs().format('MM-DD-YYYY')}-NewAdidasUPCs`)
        } else {
            message.error('No new UPCs found!')
        }

        await currentUser!.getIdToken().then((token: string) => postAdidasUPCNew(token, result)).then((res: any) => {
            if (res.error) {
                message.error('UPCs failed to upload to PastWeek collection - try again')
            } else {
                message.success('UPCs successfully uploaded to PastWeek collection')
            }
        })

        await currentUser!.getIdToken().then((token: string) => postAdidasUPC(token, result)).then((res: any) => {
            if (res.error) {
                message.error('UPCs failed to upload to DB - try again')
            } else {
                message.success('UPCs successfully uploaded to DB')
            }
        })
    } else {
        await currentUser!.getIdToken().then((token: string) => postAdidasUPC(token, result)).then((res: any) => {
            if (res.error) {
                message.error('UPCs failed to upload - try again')
            } else {
                message.success('UPCs successfully uploaded')
            }
        })
    }
}

export const processDataAdidasOrderHelper = async (data: ArrayBuffer) => {
    console.log('processing');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);

    let result: { [key: string]: any } = {};
    const headers: any[] = [];
    const values: any[] = [];
    workbook.worksheets.find((worksheet) => worksheet.name === 'Offer Data')?.eachRow((row) => {
        const currentRow = (row.values as any[]).slice(1);
        if (currentRow[0]) {
            if (currentRow[0] !== 'UPC') {
                values.push(currentRow);
            } else {
                headers.push(...currentRow);
            }
        }
    });

    const inputRows = values.map((row) => {
        const newItem: { [key: string]: any } = {};
        for (let i = 0; i < row.length; i++) {
            newItem[headers[i]] = row[i];
        }
        return newItem;
    })

    let sizeMap: any = {}
    let existingDates: string[] = [];
    let dateMap: any = {};
    let allDates: string[] = Object.keys(inputRows.map((item) => item['Supplier_Ship Date']).reduce((acc, curr) => ((acc[curr] = true, acc)), {})).sort((a, b) => dayjs(a).diff(dayjs(b))) as string[];
    console.log(allDates);

    while (allDates.length > 0) {
        let currentDate = allDates.pop();
        if (currentDate) {
            if (existingDates.length === 0) {
                existingDates.push(currentDate);
                dateMap[currentDate] = currentDate;
            } else {
                let lastDate = existingDates[existingDates.length - 1]
                if (dayjs(lastDate).diff(dayjs(currentDate), 'days') < 20) {
                    dateMap[currentDate] = lastDate;
                } else {
                    existingDates.push(currentDate);
                    dateMap[currentDate] = currentDate;
                }
            }
        }
    }

    console.log(dateMap);

    let updateData = (date: string, item: any) => {
        result[date] = (result[date] ?? []).concat([item])
        sizeMap[date] = { ...(sizeMap[date] ?? {}), [item["Supplier_Size"]]: true }
    }

    inputRows.forEach((item) => {
        updateData(dateMap[item['Supplier_Ship Date']], item)
        // if (existingDates.includes(item['Supplier_Ship Date'])) {
        //     updateData(item['Supplier_Ship Date'], item)
        // } else {
        //     let closestDate = existingDates.find((date) => Math.abs(dayjs(date).diff(dayjs(item['Supplier_Ship Date']), 'days')) < 15);
        //     if (closestDate) {
        //         updateData(closestDate, item)
        //     } else {
        //         existingDates.push(item['Supplier_Ship Date'])
        //         updateData(item['Supplier_Ship Date'], item)
        //     }
        // }
    })

    // for each date in the result, check if items sum up to 5k of value, if not, add them to the next date instead, but only if its within 45 days
    Object.keys(result).forEach((date) => {
        let itemSet = result[date];
        let total = itemSet.reduce((acc: number, curr: any) => acc + curr['Supplier_Adidas Cost'] * curr['Quantity'], 0)
        if (total < 5000) {
            let nextDate = existingDates.find((existingDate) => dayjs(existingDate).diff(dayjs(date), 'days') < 45 && dayjs(existingDate).diff(dayjs(date), 'days') > 0)
            if (nextDate) {
                result[nextDate] = (result[nextDate] ?? []).concat(itemSet)
                sizeMap[nextDate] = { ...(sizeMap[nextDate] ?? {}), ...sizeMap[date] }
                delete result[date]
                delete sizeMap[date]
                existingDates = existingDates.filter((existingDate) => existingDate !== date)
            }
        }
    })

    const resultWorkbook = new ExcelJS.Workbook();
    Object.entries(result).forEach(([date, itemSet]) => {
        const resultSheet = resultWorkbook.addWorksheet(date.replace(/\//g, '-'));

        const sizes = Object.keys(sizeMap[date]).map((size) => size.toString()).sort((a, b) => sizeIndex(a) - sizeIndex(b))
        const headers = ['BRAND', 'STYLE #', 'DESCRIPTION', 'COLOR', 'WIDTH', 'GEN', ...sizes, 'TOTAL', 'LINE PRICE', 'DISC.', 'UNIT COST', 'TOTAL AMOUNT']

        const uniqueStyles: any = {}
        itemSet.forEach((item: any) => {
            uniqueStyles[item['Supplier_Style']] = (uniqueStyles[item['Supplier_Style']] ?? []).concat([item])
        })

        let finalRows: any[] = [];
        Object.entries(uniqueStyles).forEach(([style, styleItemSet]) => {
            const altRow = new Map();
            Object.values(headers).forEach((header) => altRow.set(header, ''))
            const [description, color] = (styleItemSet as any[])[0]['Supplier_Description'].split(' / ')
            altRow.set('BRAND', 'ADIDAS');
            altRow.set('STYLE #', style);
            altRow.set('DESCRIPTION', description);
            altRow.set('COLOR', color);

            (styleItemSet as any[]).forEach((item) => altRow.set(item['Supplier_Size'].toString(), item.Quantity));

            altRow.set('TOTAL', (styleItemSet as any[]).reduce((acc, curr): number => acc += parseInt(curr.Quantity), 0))
            altRow.set('UNIT COST', (styleItemSet as any[])[0]['Supplier_Adidas Cost'])
            altRow.set('TOTAL AMOUNT', (parseFloat(altRow.get('UNIT COST')) * altRow.get('TOTAL')).toFixed(2))

            // @ts-ignore
            finalRows.push([...altRow.values()])
        })

        const totalValue = finalRows.reduce((acc, curr) => acc + parseFloat(curr[headers.indexOf('TOTAL AMOUNT')]), 0)
        resultSheet.name = `${date.replace(/\//g, '-')} - ${totalValue.toFixed(2)}`
        resultSheet.addRows([headers, ...finalRows])
    })

    excelDownloadHelper(resultWorkbook);
}

export const processDataAdidasOMSHelper = async (adidasOCData: ArrayBuffer, adidasOSDData: ArrayBuffer) => {
    console.log('processing adidas oms');

    const osWorkbook = SheetJS.read(adidasOSDData, { raw: true });
    const osSheets = osWorkbook.SheetNames
    
    let osProducts: any[] = [];
    for (let i = 0; i < osSheets.length; i++) {
        const sheetRows: any[] = SheetJS.utils.sheet_to_json(osWorkbook.Sheets[osWorkbook.SheetNames[i]], {
            blankrows: true,
            header: "A"
        })

        let headers;
        for (const row of sheetRows) {
            if (row.A === "BRAND") {
                headers = row;
            } else if (row.A && headers) {
                const product = mapHeadersSheetJS(headers, row);
                product.PO = osSheets[i].split('-')[0].trim();
                product.ShipDate =  osSheets[i].split(' - ')[1].trim();
                osProducts.push(product);
            }
        } 
    }

    console.log(osProducts);

    const orgillWorkbook = SheetJS.read(adidasOCData, { raw: true });
    const sheets = orgillWorkbook.SheetNames
    
    let products = [];
    for (let i = 0; i < sheets.length; i++) {
        const sheetRows: any[] = SheetJS.utils.sheet_to_json(orgillWorkbook.Sheets[orgillWorkbook.SheetNames[i]], {
            blankrows: true,
            header: "A"
        })

        let headers;
        for (const row of sheetRows) {
            console.log(row);
            if (Object.values(row).includes("CUSTOMER PO #")) {
                headers = row;
            } else if (row.B && headers) {
                const product = mapHeadersSheetJS(headers, row);
                products.push(product);
            }
        }
    }

    console.log(products);

    const fillLackingZeroForStyle = (style: string) => style.length === 5 ? `0${style}` : style;

    let finalProducts = products.map((product) => {
        let newProduct = {...product};
        let correspondingProduct = osProducts.find((osProduct) => osProduct.PO === newProduct["CUSTOMER PO #"] && fillLackingZeroForStyle(osProduct['STYLE #']) === fillLackingZeroForStyle(newProduct['STYLE #']));
        newProduct.SKU = `${fillLackingZeroForStyle(product['STYLE #'])}_${product['SIZE']}`
        newProduct.ShipDate = correspondingProduct?.ShipDate ?? "";
        newProduct.OSQuantity = correspondingProduct?.[newProduct.SIZE] ?? "";
        newProduct.OSPrice = correspondingProduct?.["UNIT COST"] ?? "";
        newProduct.OCPrice = product["ORDER AMOUNT"] && parseFloat(product["OPEN QTY"]) ? parseFloat(product["ORDER AMOUNT"]) / parseFloat(product["OPEN QTY"]) : -1;
        return newProduct;
    })

    sheetJSDownloadObjects(finalProducts, `${dayjs().format('YYYY-MM-DD')}_Adidas_OMS_Result`)
}