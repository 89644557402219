import { Card, Col, Divider, Row, Space, Statistic, Typography } from "antd";
import React, { useEffect, useState } from "react"
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';

const ReturnsPopover: React.FC<{ record: WholesaleItem, handleSave: any }> = React.memo(({ record, handleSave }) => {
    const [returnRate, setReturnRate] = useState("")
    const [unsellableRate, setUnsellableRate] = useState("")

    const handleRateEdit = (val: string | undefined, rate: string) => {
        if (val) {
            if (Number.isNaN(parseFloat(val))) {
                return
            }

            if (rate === 'return') {
                handleSave({ ...record, returnRate: parseFloat(val) / 100, editSiblings: false });
                setReturnRate(parseFloat(val).toFixed(2))
            } else {
                handleSave({ ...record, returnUnsellableRate: parseFloat(val) / 100, editSiblings: false });
                setUnsellableRate(parseFloat(val).toFixed(2))
            }

        }
    }

    useEffect(() => {
        setReturnRate((record.returnRate * 100).toFixed(2));
        setUnsellableRate((record.returnUnsellableRate * 100).toFixed(2));
    }, [record.returnRate, record.returnUnsellableRate])

    return (
        <Card size="small">
            <Row gutter={8} justify="space-around" align="middle" >
                <Col span={7}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Statistic title="Level:" value={'ASIN'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Return Rate:" value={record.ReturnASINData ? (record.ReturnASINData.Returns / record.ReturnASINData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '8px' }}  gutter={8}>
                        <Col span={12}>
                            <Statistic title="Confidence:" value={record.ReturnASINData?.Orders >= 50 ? 'High' : 'Low'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Unsellable Rate:" value={record.ReturnASINData ? (record.ReturnASINData.Unsellable / record.ReturnASINData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                </Col>
                
                <Divider type="vertical"></Divider>
                <Col span={7}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Statistic title="Level:" value={'Parent'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Return Rate:" value={record.ReturnParentData ? (record.ReturnParentData.Returns / record.ReturnParentData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '8px' }}  gutter={8}>
                        <Col span={12}>
                            <Statistic title="Confidence:" value={record.ReturnParentData?.Orders >= 50 ? 'High' : 'Low'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Unsellable Rate:" value={record.ReturnParentData ? (record.ReturnParentData.Unsellable / record.ReturnParentData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                </Col>
                <Divider type="vertical"></Divider>
                <Col span={7}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Statistic title="Level:" value={'Category'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Return Rate:" value={record.ReturnCategoryData ? (record.ReturnCategoryData.Returns / record.ReturnCategoryData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '8px' }}  gutter={8}>
                        <Col span={12}>
                            <Statistic title="Confidence:" value={record.ReturnCategoryData?.Orders >= 50 ? 'High' : 'Low'} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Unsellable Rate:" value={record.ReturnCategoryData ? (record.ReturnCategoryData.Unsellable / record.ReturnCategoryData.Orders * 100) : -1} precision={2} suffix={'%'} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider type="horizontal"></Divider>
            <Row style={{ marginTop: '8px' }} gutter={8} align="middle">
                <Col span={24}>
                    <Space align="center" direction="horizontal" style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {/* <Statistic title="Size Tier:" value={record.SizeTier}/>
                <Divider></Divider> */}
                        <Statistic title="Return Rate:" value={returnRate} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                            icon: false,
                            tooltip: `Set the base return rate`,
                            onChange: (val) => handleRateEdit(val, "return"),
                            onEnd: () => handleRateEdit("", "return"),
                            onCancel: () => handleRateEdit("", "return"),
                        }}>{val}%</Typography.Text>} />
                        <Divider></Divider>
                        <Statistic title="Processing Fee:" value={record.returnProcessingFee} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Statistic title="Admin Fee:" value={record.returnAdminFee} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Statistic title="FBA Fee:" value={record.Fees} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Divider></Divider>
                        <Statistic title="Unsellable Rate:" value={unsellableRate} formatter={(val) => <Typography.Text className="editable-stat" editable={{
                            icon: false,
                            tooltip: `Set the unsellable rate`,
                            onChange: (val) => handleRateEdit(val, "unsellable"),
                            onEnd: () => handleRateEdit("", "unsellable"),
                            onCancel: () => handleRateEdit("", "unsellable"),
                        }}>{val}%</Typography.Text>} />
                        <Divider></Divider>
                        <Statistic title="Removal Fee:" value={record.returnRemovalFee} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Statistic title="Cost Write-off:" value={record.returnCostWriteOff} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Statistic title="Processing Fee:" value={record.returnProcessingFlatWSFee} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Statistic title="Ship Back:" value={record.returnShipBackFee} precision={2} prefix={'$'} />
                        <Divider></Divider>
                        <Typography> = </Typography>
                        <Divider></Divider>
                        <Statistic title="Total:" value={record.totalReturnCost} precision={2} prefix={'$'} />
                    </Space>
                </Col>
            </Row>

        </Card>
    )
})

export default ReturnsPopover;