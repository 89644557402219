import { message } from 'antd';
import { KeepaProduct, KeepaProductResponse } from '../types/KeepaTypes';
import { OMSItem } from '../types/OmegaTypes';
import { BASE_URL } from './WholesaleService';
import { handleError } from './common';
import { KeepaApiKey } from './constants';

const MM_TO_INCH_RATIO = 25.4;
const GRAM_TO_LB_RATIO = 454;
// const GRAM_TO_OUNCE_RATIO = 28.35;

export type KeepaCodeType = 'asin' | 'code';

export const getKeepa = async (
    code: string,
    type: KeepaCodeType,
    options: string = '&stats=365&buybox=1'
): Promise<KeepaProductResponse> => {
    const baseUrl = `https://api.keepa.com/product?key=${KeepaApiKey}&domain=1${options}&${type}=`;
    const resp = await fetch(baseUrl + code);
    const data = await handleError(resp);
    return data;
};

export const getKeepaRevSeller = async (
    code: string,
): Promise<KeepaProductResponse> => {
    const baseUrl = `${BASE_URL}/keepa/revsellerGrab?key=buyerteam2907&queryType=data2&asins=`;
    const resp = await fetch(baseUrl + code);
    const data = await handleError(resp);
    return data;
};

const grabKeepaBatches = async (
    codes: string[],
    type: KeepaCodeType
): Promise<KeepaProduct[]> => {
    const resultData: KeepaProduct[] = [];
    let i;
    let temparray;
    const chunk = 100;
    for (i = 0; i < codes.length; i += chunk) {
        temparray = codes.slice(i, i + chunk);

        const queryResult = await getKeepa(temparray.join(','), type);
        if (queryResult.products) {
            resultData.push(...queryResult.products);
        } else if (queryResult.error) {
            throw new Error(queryResult.error.message);
        }

        if (queryResult.tokensLeft < 1200) {
            message.warning('Keepa tokens running low, slowing the run down')
            await new Promise((resolve) => setTimeout(resolve, 50000));
        }
    }

    return resultData;
};

const grabKeepaData = async (codes: string[]) => {
    const resultData = [];

    const asins = codes.filter(code => code.length === 10);
    const upcs = codes.filter(code => code.length !== 10);

    resultData.push(...(await grabKeepaBatches(asins, 'asin')));
    resultData.push(...(await grabKeepaBatches(upcs, 'code')));

    if (resultData.length > 0) {
        await fetch(
            `https://api.projectsuite.io/keepa/add?key=maximumsecurity`,
            {
                method: 'POST',
                body: JSON.stringify({
                    listings: resultData,
                    hasReviews: false,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then(handleError);
    }

    const productMap = resultData.reduce<{ [key: string]: KeepaProduct[] }>(
        (acc, curr) => {
            if (curr.asin) {
                acc[curr.asin] = (acc[curr.asin] ?? []).concat([curr]);
            }

            curr.eanList?.forEach(ean => {
                acc[ean] = (acc[ean] ?? []).concat([curr]);
            });

            curr.upcList?.forEach(upc => {
                acc[upc] = (acc[upc] ?? []).concat([curr]);
            });

            return acc;
        },
        {}
    );

    return productMap;
};

const pickKeepaProduct = (products: KeepaProduct[], asinToChoose?: string) => {
    let minProduct = products[0];

    if (asinToChoose) {
        for (const prod of products) {
            if (prod.asin === asinToChoose) {
                return prod;
            }
        }

        return undefined;
    }

    for (const prod of products) {
        if (
            prod.stats?.current[3] !== -1 &&
            (minProduct?.stats?.current[3] === -1 ||
                prod.stats?.current[3] < minProduct?.stats?.current[3])
        ) {
            minProduct = prod;
        }
    }

    return minProduct;
};

const setSizeTier = (product: KeepaProduct) => {
    const dims = [
        (product.packageWidth || 0) / MM_TO_INCH_RATIO,
        (product.packageHeight || 0) / MM_TO_INCH_RATIO,
        (product.packageLength || 0) / MM_TO_INCH_RATIO,
    ];
    dims.sort(function (a, b) {
        return a - b;
    });

    const weight = (product.packageWeight || 0) / GRAM_TO_LB_RATIO;

    if (weight > 0 && dims[2] > 0) {
        //Small standard-size
        if (weight <= 0.75) {
            if (dims[2] <= 15 && dims[1] <= 12 && dims[0] <= 0.75) {
                product.SizeTier = 'Small';
                return product;
            }
        }

        //Large standard-size
        if (weight <= 20) {
            if (dims[2] <= 18 && dims[1] <= 14 && dims[0] <= 8) {
                product.SizeTier = 'Large';
                return product;
            }
        }

        //Small oversize
        const girth = (dims[0] + dims[1]) * 2;
        console.log('Girth: ' + girth);
        if (weight <= 70) {
            if (dims[2] <= 60 && dims[1] <= 30 && dims[2] + girth <= 130) {
                product.SizeTier = 'Small oversize';
                return product;
            }
        }

        //Medium overisze
        if (weight <= 150) {
            if (dims[2] <= 108 && dims[2] + girth <= 130) {
                product.SizeTier = 'Medium oversize';
                return product;
            }
        }

        //Large oversize
        if (weight <= 150) {
            if (dims[2] + girth <= 165) {
                product.SizeTier = 'Large oversize';
                return product;
            }
        }

        product.SizeTier = 'Special oversize';
        return product;
    } else {
        product.SizeTier = 'Unknown';
        return product;
    }
};

const determinOrphanImproved = (keepaProduct: KeepaProduct) => {
    if (!keepaProduct.parentAsin) {
        if (
            keepaProduct.rootCategory === 7141123011 ||
            keepaProduct.rootCategory === 3375251
        ) {
            return 'POSSIBLY (No Parent ASIN)';
        } // Clothing or Sports
    }

    if (
        keepaProduct.stats?.maxInInterval?.[17]?.[1] * 0.75 >
        keepaProduct.stats?.current?.[17]?.[1]
    ) {
        return 'POSSIBLY (Review Count Drop detected)';
    }

    if (
        keepaProduct.stats?.minInInterval?.[3]?.[1] * 4 <
        keepaProduct.stats?.current?.[3] &&
        keepaProduct.stats?.minInInterval?.[3]?.[1] -
        keepaProduct.stats?.current?.[3] >
        5000
    ) {
        return 'POSSIBLY (Huge SalesRank Increase detected)';
    }

    return '';
};

export const grabKeepa = async (products: OMSItem[], identifierToUse?: string): Promise<OMSItem[]> => {
    const productsCopy = products.map(item => ({ ...item }));
    const productCodes = productsCopy.map(prod => identifierToUse ? prod[identifierToUse] : (prod['ASIN'] || prod['UPC'])).filter((code) => code && ((code.length === 10 && (code[0] === 'B' || /^\d+$/.test(code))) || (code.match(/^(\d{12}|\d{13}|\d{14})$/g) !== null)));
    const productMap = await grabKeepaData(productCodes);

    for (const prod of productsCopy) {
        console.log('picking keepa product')

        const keepaData = (productMap[identifierToUse ? prod[identifierToUse] : prod['ASIN'] || prod['UPC']]) ?? [];
        let correctKeepaProduct = pickKeepaProduct(keepaData, prod['ASIN']);

        if (prod['ASIN'] && correctKeepaProduct === undefined) {
            console.log('downloading asin data for prod', prod['UPC'], prod)
            const localMap = await grabKeepaData([prod['ASIN']]);
            correctKeepaProduct = pickKeepaProduct(localMap[prod['ASIN']], prod['ASIN']);
        }

        prod['ASINCount'] = keepaData.length;
        prod['ASINList'] = keepaData.map((prod) => prod.asin);

        console.log(prod.UPC, prod['ASINList'], prod, keepaData, correctKeepaProduct)

        if (correctKeepaProduct) {
            prod['Upc Match?'] = 'YES';
            prod.ASIN = correctKeepaProduct.asin;
            prod.AMZ_SalesRank =
                correctKeepaProduct.stats?.current[3] || -1;
            prod.AMZ_Title = correctKeepaProduct.title;
            prod.AMZ_SizeTier = setSizeTier({
                ...correctKeepaProduct,
            }).SizeTier;
            prod.AMZ_Orphan = determinOrphanImproved(correctKeepaProduct);
            prod['S2_Pushed?'] = 'NO';
            prod.KeepaData = correctKeepaProduct;
            prod.ParentASIN = correctKeepaProduct.parentAsin || '';
        } else {
            prod['Upc Match?'] = 'NO';
            prod['AMZ_SizeTier'] = 'Unknown';
            prod['AMZ_Orphan'] = '';
            prod['S2_Pushed?'] = 'NO';
            prod.KeepaData = undefined;
            prod.ParentASIN = '';
        }

        prod['Checked?'] = true;
    }

    return productsCopy;
};
