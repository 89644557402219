import React, {forwardRef, useEffect, useImperativeHandle, useState, useRef} from "react";
import {
    Modal,
    List,
    Button,
    Skeleton,
    Typography,
    Select,
    Steps,
    Space,
    Breadcrumb,
    Form,
    Input,
    notification,
    InputNumber,
    Image,
    Upload,
    UploadProps,
    UploadFile,
    Row,
    Col,
    Card,
    Empty,
    Tag,
    Progress,
    Tooltip,
    Spin,
} from "antd";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {EBayCategorySuggestion, FileWithBytes} from "../../../types/WarehouseTypes";
import {DeleteFilled, PlusCircleFilled, UploadOutlined} from "@ant-design/icons";
import {convertToUploadFile, getFileBytes} from "./helpers";
import "./styles.css";
import {randID} from "../boxing/helpers";

interface EBayCategorySelectProps {
    initialCategory?: EBayCategorySuggestion;
    suggestions?: EBayCategorySuggestion[];
    loading?: boolean;
    onSelect: (category: EBayCategorySuggestion) => void;
}

const EbayCategorySelect: React.FC<EBayCategorySelectProps> = ({initialCategory, suggestions, onSelect, loading}) => {
    const [selected, setSelected] = useState<EBayCategorySuggestion | null>(null);

    useEffect(() => {
        if (initialCategory) {
            setSelected(initialCategory);
            onSelect(initialCategory);
        }
    }, [initialCategory, onSelect]);

    return (
        <List
            dataSource={suggestions}
            style={{maxHeight: "50vh", overflow: "auto", padding: 4, marginTop: 16, width: "100%"}}
            loading={loading}
            renderItem={(item) => {
                const categories = [...item.categoryTreeNodeAncestors];
                categories.sort((a, b) => (b.categoryTreeNodeLevel || 0) - (a.categoryTreeNodeLevel || 0));
                categories.push(item.category);
                return (
                    <List.Item
                        style={{
                            border: selected?.category === item.category ? "1px solid #3166D4" : "1px solid #00000000",
                            borderRadius: 5,
                            padding: 4,
                        }}
                    >
                        <Space
                            direction="horizontal"
                            style={{justifyContent: "center", alignItems: "center"}}
                            styles={{item: {justifyContent: "center", alignItems: "center"}}}
                        >
                            <Breadcrumb
                                separator="<"
                                items={categories.map((cat) => ({title: cat.categoryName}))}
                                itemRender={(route, params, routes, paths) => {
                                    const first = routes.indexOf(route) === 0;
                                    return first ? (
                                        <Typography.Text strong>{route.title}</Typography.Text>
                                    ) : (
                                        <Typography.Text type="secondary">{route.title}</Typography.Text>
                                    );
                                }}
                            />
                        </Space>
                        <Button
                            onClick={() => {
                                setSelected(item);
                                onSelect(item);
                            }}
                        >
                            Select
                        </Button>
                    </List.Item>
                );
            }}
        />
    );
};

const customizeRequiredMark = (label: React.ReactNode, {required}: {required: boolean}) => (
    <>
        {required ? <Tag color="error">REQUIRED</Tag> : null}
        {label}
    </>
);

const DynamicForm = forwardRef((props, ref) => {
    const {returnsData} = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
        }))
    );
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        getValues: async () => {
            const values = await form.validateFields();
            return values;
        },
    }));

    useEffect(() => {
        if (returnsData.eBayItem) {
            const values: any = {
                eBayCondition: returnsData.eBayItem?.eBayCondition,
                ...returnsData.eBayItem.eBayAspects,
                Brand: returnsData.eBayItem.brand,
            };
            // Check if product aspects have MPN and fill it
            if (returnsData.productAspects) {
                const mpnAspect = returnsData.productAspects.find((aspect) => aspect.localizedAspectName === "MPN");
                if (mpnAspect) {
                    values.MPN = returnsData.eBayItem.partNumber;
                }
            }
            form.setFieldsValue(values);
        }
    }, [returnsData.eBayItem, returnsData.productAspects, form]);

    if (returnsData.isEBayFormLoading) {
        return <Skeleton active />;
    }

    return (
        <Form form={form} layout="vertical" requiredMark={customizeRequiredMark}>
            {returnsData.policyConditions?.itemConditions && (
                <Form.Item name="eBayCondition" label="Condition" rules={[{required: true, message: "Please select a condition"}]}>
                    <Select
                        placeholder="Select a condition"
                        options={returnsData.policyConditions.itemConditions.map((cond) => ({
                            label: cond.conditionDescription,
                            value: cond.conditionId,
                        }))}
                    />
                </Form.Item>
            )}
            {returnsData.productAspects &&
                returnsData.productAspects.map((aspect) => {
                    let input: JSX.Element;
                    if (aspect.aspectConstraint.aspectDataType === "NUMBER") {
                        if (aspect.aspectConstraint.aspectFormat === "int32") {
                            input = <Input type="number" placeholder={`Enter ${aspect.localizedAspectName}`} />;
                        } else {
                            input = <Input type="number" step="0.01" placeholder={`Enter ${aspect.localizedAspectName}`} />;
                        }
                    } else if (aspect.aspectConstraint.aspectDataType === "DATE") {
                        input = <Input type="date" placeholder={`Enter ${aspect.localizedAspectName}`} />;
                    } else if (!aspect.aspectValues) {
                        input = <Input placeholder={`Enter ${aspect.localizedAspectName}`} />;
                    } else if (aspect.aspectConstraint.aspectMode === "SELECTION_ONLY") {
                        input = (
                            <Select
                                placeholder={`Select ${aspect.localizedAspectName}`}
                                options={aspect.aspectValues.map((value) => ({
                                    label: value.localizedValue,
                                    value: value.localizedValue,
                                }))}
                            />
                        );
                    } else {
                        input = (
                            <Select
                                placeholder={`Enter ${aspect.localizedAspectName}`}
                                mode="tags"
                                maxCount={aspect.aspectConstraint.itemToAspectCardinality === "MULTI" ? undefined : 1}
                                options={aspect.aspectValues.map((value) => ({
                                    label: value.localizedValue,
                                    value: value.localizedValue,
                                }))}
                            />
                        );
                    }
                    return (
                        <Form.Item
                            key={aspect.localizedAspectName}
                            name={aspect.localizedAspectName}
                            label={aspect.localizedAspectName}
                            rules={[
                                {required: aspect.aspectConstraint.aspectRequired, message: `Please enter ${aspect.localizedAspectName}`},
                            ]}
                        >
                            {input}
                        </Form.Item>
                    );
                })}
        </Form>
    );
});

const ProductForm = forwardRef((_, ref) => {
    const {returnsData} = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
        }))
    );
    const [form] = Form.useForm();

    useEffect(() => {
        if (returnsData.eBayItem) {
            form.setFieldsValue({
                name: returnsData.eBayItem.name,
                description: returnsData.eBayItem.description,
                ean: returnsData.eBayItem.ean,
                upc: returnsData.eBayItem.upc,
                eBayQuantity: returnsData.eBayItem.eBayQuantity,
                price: returnsData.eBayItem.price,
            });
        }
    }, [returnsData.eBayItem, form]);

    useImperativeHandle(ref, () => ({
        getValues: async () => {
            return form.validateFields();
        },
    }));

    return (
        <Form form={form} layout="vertical" requiredMark={customizeRequiredMark}>
            <Form.Item name="name" label="Title" rules={[{required: true, message: "Please enter the title"}, {max: 80}]}>
                <Input placeholder="Enter title" />
            </Form.Item>

            <Form.Item name="description" label="Description" rules={[{required: true, message: "Please enter the description"}]}>
                <Input.TextArea rows={6} placeholder="Enter description" />
            </Form.Item>

            <Form.Item name="ean" label="EAN" rules={[{required: true, message: "Please enter the EAN"}]}>
                <Input placeholder="Enter EAN" />
            </Form.Item>

            <Form.Item name="upc" label="UPC" rules={[{required: true, message: "Please enter the UPC"}]}>
                <Input placeholder="Enter UPC" />
            </Form.Item>

            <Form.Item name="eBayQuantity" label="Quantity" rules={[{required: true, message: "Please enter the quantity"}]}>
                <InputNumber placeholder="Enter quantity" min={1} />
            </Form.Item>

            <Form.Item name="price" label="Price" rules={[{required: true, message: "Please enter the price"}]}>
                <InputNumber placeholder="Enter price" min={0} />
            </Form.Item>
        </Form>
    );
});

interface ImageCardProps {
    src: string;
    width: number;
    height: number;
    onAction: () => void;
    actionIcon: React.ReactNode;
}

const ImageCard: React.FC<ImageCardProps> = ({src, width, height, onAction, actionIcon}) => {
    return (
        <Card
            style={{
                width: width,
                // height: height + 20,
            }}
            styles={{body: {width, height, padding: 2}, actions: {padding: 0}}}
            actions={[<Button type="text" key="action" onClick={onAction} icon={actionIcon} />]}
        >
            <Image style={{borderRadius: 8, height: height - 4, objectFit: "contain"}} src={src} />
        </Card>
    );
};

const ImagesForm: React.FC = () => {
    const {returnsData, setChosenImages} = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
            setChosenImages: state.setChosenImages,
        }))
    );
    const [amazonFiles, setAmazonFiles] = useState<FileWithBytes[]>([]);
    const [amazonFilesLoading, setAmazonFilesLoading] = useState(false);

    const chosenImages = returnsData.chosenImages || [];

    useEffect(() => {
        const getImages = async () => {
            setAmazonFiles([]);
            setAmazonFilesLoading(true);
            const images = returnsData.eBayItem?.allImgURLs || [];
            const fileList: FileWithBytes[] = [];
            for (const img of images) {
                fileList.push(await convertToUploadFile(img, img));
            }
            setAmazonFiles(fileList);
            setAmazonFilesLoading(false);
        };
        getImages();
    }, [returnsData.eBayItem]);

    const handleUpload = async ({fileList}: {fileList: UploadFile[]}) => {
        const files = await Promise.all(
            fileList.map(async (file) => {
                const bytes = await getFileBytes(file.originFileObj as File);
                return {file, bytes};
            })
        );
        setChosenImages([...chosenImages, ...files]);
    };

    const props: UploadProps = {
        name: "file",
        multiple: true,
        fileList: [],
        onChange: handleUpload,
    };

    let emptyComponent: React.ReactNode = null;
    if (amazonFiles.length === 0 && amazonFilesLoading) {
        emptyComponent = <Spin tip="Downloading images..." size="large" />;
    } else if (amazonFiles.length === 0) {
        emptyComponent = <Empty />;
    }

    return (
        <>
            <Row style={{height: "90%", overflow: "auto"}}>
                <Col span={12} style={{padding: 2, height: "100%", overflow: "auto"}}>
                    <Typography.Title level={5}>Chosen images</Typography.Title>
                    <Space direction="horizontal" wrap>
                        {chosenImages.length === 0 && <Empty />}
                        {chosenImages.map((file) => (
                            <ImageCard
                                onAction={() => {
                                    setChosenImages(chosenImages.filter((f) => f.file.uid !== file.file.uid));
                                }}
                                actionIcon={<DeleteFilled />}
                                key={file.file.uid}
                                width={150}
                                height={150}
                                src={URL.createObjectURL(file.file.originFileObj as File)}
                            />
                        ))}
                    </Space>
                </Col>
                <Col span={12} style={{padding: 2, height: "100%", overflow: "auto"}}>
                    <Typography.Title level={5}>Amazon images</Typography.Title>
                    <Space direction="horizontal" wrap>
                        {emptyComponent}
                        {amazonFiles.map((file) => (
                            <ImageCard
                                onAction={() => {
                                    setChosenImages([...chosenImages, {bytes: file.bytes, file: {...file.file, uid: randID()}}]);
                                }}
                                actionIcon={<PlusCircleFilled />}
                                key={file.file.uid}
                                width={150}
                                height={150}
                                src={URL.createObjectURL(file.file.originFileObj as File)}
                            />
                        ))}
                    </Space>
                </Col>
            </Row>
            <Space style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
                <Upload {...props}>
                    <Button style={{marginTop: 8}} icon={<UploadOutlined />}>
                        Click to Upload
                    </Button>
                </Upload>
            </Space>
        </>
    );
};

const FinalizeForm: React.FC = () => {
    const {returnsData, createOffer, resetEBayData} = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
            createOffer: state.createOffer,
            resetEBayData: state.resetEBayData,
        }))
    );

    return (
        <Space
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            {returnsData.listingId && (
                <Typography.Link
                    href={`https://www.ebay.com/lstng?mode=ReviseItem&itemId=${returnsData.listingId}`}
                    target="_blank"
                    style={{marginBottom: 16}}
                >
                    View or edit your listing
                </Typography.Link>
            )}
            <Tooltip title={returnsData.eBayOfferError ? `Error: ${returnsData.eBayOfferError}` : ""} color="red">
                <Progress
                    percent={returnsData.eBayOfferProgress}
                    type="circle"
                    status={returnsData.eBayOfferError ? "exception" : undefined}
                    style={{marginBottom: 16}}
                />
            </Tooltip>
            <Space>
                <Button
                    onClick={() => {
                        if (returnsData.eBayItem) {
                            createOffer(returnsData.eBayItem);
                        }
                    }}
                    loading={returnsData.isEBayFormLoading}
                    disabled={returnsData.eBayOfferProgress === 100}
                >
                    Create offer
                </Button>
                <Button
                    onClick={() => {
                        resetEBayData();
                    }}
                    disabled={!returnsData.eBayOfferProgress || returnsData.eBayOfferProgress < 100}
                >
                    Finish
                </Button>
            </Space>
        </Space>
    );
};

interface EBayFormDialogProps {
    open: boolean;
    onClose: () => void;
}

const EBayFormDialog: React.FC<EBayFormDialogProps> = ({open, onClose}) => {
    const {returnsData, getCategorySuggestions, getRequiredDetails, setEBayAspects, setEBayItem} = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
            getCategorySuggestions: state.getCategorySuggestions,
            getRequiredDetails: state.getRequiredEBayDetails,
            setEBayAspects: state.setEBayAspects,
            setEBayItem: state.setEBayItem,
        }))
    );
    const [current, setCurrent] = useState(0);
    const [category, setCategory] = useState<EBayCategorySuggestion | null>(null);
    const aspectsRef = useRef<any>(null);
    const productRef = useRef<any>(null);

    useEffect(() => {
        if (!open) {
            setCurrent(0);
            setCategory(null);
        }
    }, [open]);

    useEffect(() => {
        if (open && current === 0 && !returnsData.categorySuggestions) {
            getCategorySuggestions(returnsData.eBayItem?.name || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, open, returnsData.eBayItem]);

    const steps = [
        {
            title: "Category",
            content: (
                <EbayCategorySelect
                    suggestions={returnsData.categorySuggestions || []}
                    loading={returnsData.isEBayFormLoading}
                    onSelect={(cat) => {
                        setCategory(cat);
                        setEBayAspects(undefined);
                    }}
                    initialCategory={category || undefined}
                />
            ),
        },
        {
            title: "Details",
            content: <DynamicForm ref={aspectsRef} />,
        },
        {
            title: "General info",
            content: <ProductForm ref={productRef} />,
        },
        {
            title: "Images",
            content: <ImagesForm />,
        },
        {
            title: "Finalize",
            content: <FinalizeForm />,
        },
    ];

    const items = steps.map((item, index) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <Modal
            open={open}
            onCancel={onClose}
            style={{maxHeight: "70vh"}}
            styles={{content: {overflow: "auto"}}}
            width={"80vw"}
            footer={
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Typography.Link href={`https://www.amazon.com/dp/${returnsData.eBayItem?.asin}?th=1&psc=1`} target="_blank">
                        View on Amazon
                    </Typography.Link>
                    <Space>
                        <Button onClick={() => setCurrent(current - 1)} disabled={current === 0}>
                            Previous
                        </Button>
                        <Button
                            onClick={async () => {
                                try {
                                    switch (current) {
                                        case 0:
                                            if (category && !returnsData.productAspects) {
                                                getRequiredDetails(category.category.categoryId);
                                            } else {
                                                throw new Error("Please select a category");
                                            }
                                            if (returnsData.eBayItem && category) {
                                                setEBayItem({...returnsData.eBayItem, eBayCategory: category?.category});
                                            }
                                            break;
                                        case 1:
                                            if (returnsData.eBayItem) {
                                                const data = await aspectsRef.current.getValues();
                                                const {eBayCondition, ...aspects} = data;
                                                setEBayItem({...returnsData.eBayItem, eBayCondition: eBayCondition, eBayAspects: aspects});
                                            }
                                            break;
                                        case 2:
                                            if (returnsData.eBayItem) {
                                                const data = await productRef.current.getValues();
                                                setEBayItem({...returnsData.eBayItem, ...data});
                                            }
                                            break;
                                    }
                                    setCurrent(current + 1);
                                } catch (error: any) {
                                    notification.error({
                                        message: "Error",
                                        description: error.message,
                                    });
                                }
                            }}
                            disabled={current === steps.length - 1}
                        >
                            Next
                        </Button>
                    </Space>
                </Space>
            }
        >
            <Space style={{width: "100%", marginTop: 16}} styles={{item: {width: "100%", height: "60vh", overflow: "auto"}}}>
                {steps[current].content}
            </Space>
            <Steps items={items} current={current} />
        </Modal>
    );
};

export default EBayFormDialog;
