import ExcelJS from "exceljs";
import * as SheetJS from "xlsx";
import * as dataForge from 'data-forge'

export const downloadHelper = (result: any[], filename: string = 'new_era') => {
    const download = (data: any[]) => {
        if (!data || !data.length) return;

        const text = (new dataForge.DataFrame(data)).toCSV()

        const file = new Blob([text], { type: "text/plain;charset=utf-8" });
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = `${filename}.csv`;
        a.click();
        URL.revokeObjectURL(url);
        a.parentNode?.removeChild(a);
    };

    download(result)
}

export const jsonDownloadHelper = (result: any[], filename: string = 'new_era') => {
    const download = (data: any[]) => {
        if (!data || !data.length) return;

        const text = JSON.stringify(result)

        const file = new Blob([text], { type: "application/json;charset=utf-8" });
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = `${filename}.json`;
        a.click();
        URL.revokeObjectURL(url);
        a.parentNode?.removeChild(a);
    };

    download(result)
}

export const excelDownloadHelper = async (worksheet: ExcelJS.Workbook, filename = "result") => {
    if (!worksheet) return;

    const text = await worksheet.xlsx.writeBuffer();

    const file = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename + ".xlsx";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
}

export const sheetJSDownload = async (data: string[][], filename = 'result') => {
    if (!data) return;

    function fitToColumn(arrayOfArray: string[][]) {
        // get maximum character of each column
        return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? (a2[i].toString().length + 1) : 0)) }));
    }

    const wb = SheetJS.utils.book_new();
    wb.SheetNames.push("Result");
    const ws = SheetJS.utils.aoa_to_sheet(data);
    ws['!cols'] = fitToColumn(data);
    wb.Sheets["Result"] = ws;

    const text = await SheetJS.writeXLSX(wb, {bookType:'xlsx',  type: "buffer"});

    const file = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename + ".xlsx";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
}

export const sheetJSDownloadObjects = async (data: any[], filename = 'result') => {
    if (!data) return;

    const wb = SheetJS.utils.book_new();
    wb.SheetNames.push("Result");
    const ws = SheetJS.utils.json_to_sheet(data);
    wb.Sheets["Result"] = ws;

    const text = await SheetJS.writeXLSX(wb, {bookType:'xlsx',  type: "buffer"});

    const file = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename + ".xlsx";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
}

export const downloadAsExcel = (data: BlobPart, filename: string) => {
    if (!data) return;

    const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
}