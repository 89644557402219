import React, { useState } from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './Signin.scss';
import { useAuth } from '../../contexts/AuthContext';

interface ForgotPasswordFormType {
    email: string;
}

export default function ForfotPassword() {
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { resetPassword } = useAuth();

    const onFinish = async (values: ForgotPasswordFormType) => {
        console.log('Received values of form: ', values);

        let { email } = values;
        try {
            setError('');
            setMessage('');
            setLoading(true);
            await resetPassword(email);
            setMessage('Check your inbox for further instructions');
        } catch {
            setError('Failed to reset password');
        }
        setLoading(false);
    };

    return (
        <div id='login-section'>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                Password Reset
            </Typography.Title>
            {error && (
                <Alert message={error} type='error' className='error-alert' />
            )}
            {message && (
                <Alert
                    message={message}
                    type='success'
                    className='error-alert'
                />
            )}
            <Form
                name='normal_login'
                className='login-form'
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name='email'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className='site-form-item-icon' />
                        }
                        placeholder='Email'
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                        loading={loading}
                        disabled={loading}
                    >
                        Reset password
                    </Button>
                    Or <a href='/login'>login now!</a>
                </Form.Item>
            </Form>
        </div>
    );
}
