import { BASE_URL } from "../../services/WholesaleService";
import { BrandApiKey } from "../../services/constants";

// export const mainSalesRankCorrelation = {
//     'Clothing, Shoes & Jewelry': [
//         {
//             'Rank': 100,
//             'Sales': 13290,
//         },
//         {
//             'Rank': 200,
//             'Sales': 10500,
//         },
//         {
//             'Rank': 500,
//             'Sales': 7290,
//         },
//         {
//             'Rank': 1000,
//             'Sales': 5280,
//         },
//         {
//             'Rank': 3000,
//             'Sales': 2880,
//         },
//         {
//             'Rank': 5000,
//             'Sales': 2070,
//         },
//         {
//             'Rank': 7500,
//             'Sales': 3330,
//         },
//         {
//             'Rank': 10000,
//             'Sales': 1260,
//         },
//         {
//             'Rank': 15000,
//             'Sales': 900,
//         },
//         {
//             'Rank': 20000,
//             'Sales': 700,
//         },
//         {
//             'Rank': 25000,
//             'Sales': 570,
//         },
//         {
//             'Rank': 50000,
//             'Sales': 300,
//         },
//         {
//             'Rank': 100000,
//             'Sales': 120,
//         },
//         {
//             'Rank': 150000,
//             'Sales': 150,
//         },
//         {
//             'Rank': 250000,
//             'Sales': 30,
//         },
//         {
//             'Rank': 500000,
//             'Sales': 15,
//         },
//         {
//             'Rank': 750000,
//             'Sales': 5,
//         },
//         {
//             'Rank': 1000000,
//             'Sales': 1,
//         },
//         {
//             'Rank': 1500000,
//             'Sales': 0.5,
//         },
//         {
//             'Rank': 2000000,
//             'Sales': 0,
//         },
//     ],
//     'Sports & Outdoors': [
//         {
//             'Rank': 100,
//             'Sales': 15930,
//         },
//         {
//             'Rank': 200,
//             'Sales': 11070,
//         },
//         {
//             'Rank': 300,
//             'Sales': 8790,
//         },
//         {
//             'Rank': 400,
//             'Sales': 7440,
//         },
//         {
//             'Rank': 500,
//             'Sales': 6480,
//         },
//         {
//             "Rank": 1000,
//             "Sales": 3450
//         },
//         {
//             "Rank": 2000,
//             "Sales": 2160
//         },
//         {
//             "Rank": 3000,
//             "Sales": 1620
//         },
//         {
//             "Rank": 4000,
//             "Sales": 1290
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1080
//         },
//         {
//             "Rank": 7500,
//             "Sales": 780
//         },
//         {
//             "Rank": 10000,
//             "Sales": 600
//         },
//         {
//             "Rank": 15000,
//             "Sales": 420
//         },
//         {
//             "Rank": 20000,
//             "Sales": 300
//         },
//         {
//             "Rank": 30000,
//             "Sales": 210
//         },
//         {
//             "Rank": 50000,
//             "Sales": 120
//         },
//         {
//             "Rank": 75000,
//             "Sales": 90
//         },
//         {
//             "Rank": 100000,
//             "Sales": 60
//         },
//         {
//             "Rank": 125000,
//             "Sales": 45
//         },
//         {
//             "Rank": 150000,
//             "Sales": 30
//         },
//         {
//             "Rank": 175000,
//             "Sales": 20
//         },
//         {
//             "Rank": 200000,
//             "Sales": 10
//         },
//         {
//             "Rank": 225000,
//             "Sales": 8
//         },
//         {
//             "Rank": 250000,
//             "Sales": 5
//         },
//         {
//             "Rank": 300000,
//             "Sales": 3
//         },
//         {
//             "Rank": 500000,
//             "Sales": 1
//         },
//         {
//             "Rank": 750000,
//             "Sales": 0
//         }
//     ],
//     'Patio, Lawn & Garden': [
//         {
//             'Rank': 100,
//             'Sales': 15510,
//         },
//         {
//             'Rank': 200,
//             'Sales': 10530,
//         },
//         {
//             'Rank': 300,
//             'Sales': 8310,
//         },
//         {
//             'Rank': 400,
//             'Sales': 6960,
//         },
//         {
//             "Rank": 500,
//             "Sales": 6060
//         },
//         {
//             "Rank": 1000,
//             "Sales": 3870
//         },
//         {
//             "Rank": 2500,
//             "Sales": 2040
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1200
//         },
//         {
//             "Rank": 7500,
//             "Sales": 870
//         },
//         {
//             "Rank": 10000,
//             "Sales": 690
//         },
//         {
//             "Rank": 15000,
//             "Sales": 480
//         },
//         {
//             "Rank": 20000,
//             "Sales": 360
//         },
//         {
//             "Rank": 30000,
//             "Sales": 240
//         },
//         {
//             "Rank": 50000,
//             "Sales": 120
//         },
//         {
//             "Rank": 75000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 200000,
//             "Sales": 0
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         },
//         {
//             "Rank": 500000,
//             "Sales": 0
//         },
//         {
//             "Rank": 1000000,
//             "Sales": 0
//         }
//     ],
//     'Grocery & Gourmet Food': [
//         {
//             "Rank": 500,
//             "Sales": 7950
//         },
//         {
//             "Rank": 1000,
//             "Sales": 5310
//         },
//         {
//             "Rank": 2000,
//             "Sales": 3330
//         },
//         {
//             "Rank": 3000,
//             "Sales": 2430
//         },
//         {
//             "Rank": 4000,
//             "Sales": 1920
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1560
//         },
//         {
//             "Rank": 7500,
//             "Sales": 1050
//         },
//         {
//             "Rank": 10000,
//             "Sales": 780
//         },
//         {
//             "Rank": 15000,
//             "Sales": 510
//         },
//         {
//             "Rank": 20000,
//             "Sales": 360
//         },
//         {
//             "Rank": 30000,
//             "Sales": 210
//         },
//         {
//             "Rank": 50000,
//             "Sales": 90
//         },
//         {
//             "Rank": 75000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 200000,
//             "Sales": 0
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         },
//         {
//             "Rank": 500000,
//             "Sales": 0
//         }
//     ],
//     'Automotive': [
//         {
//             "Rank": 500,
//             "Sales": 3060
//         },
//         {
//             "Rank": 1000,
//             "Sales": 1950
//         },
//         {
//             "Rank": 2000,
//             "Sales": 1200
//         },
//         {
//             "Rank": 3000,
//             "Sales": 870
//         },
//         {
//             "Rank": 4000,
//             "Sales": 720
//         },
//         {
//             "Rank": 5000,
//             "Sales": 600
//         },
//         {
//             "Rank": 7500,
//             "Sales": 420
//         },
//         {
//             "Rank": 10000,
//             "Sales": 330
//         },
//         {
//             "Rank": 15000,
//             "Sales": 240
//         },
//         {
//             "Rank": 20000,
//             "Sales": 180
//         },
//         {
//             "Rank": 30000,
//             "Sales": 120
//         },
//         {
//             "Rank": 50000,
//             "Sales": 90
//         },
//         {
//             "Rank": 75000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 200000,
//             "Sales": 30
//         },
//         {
//             "Rank": 250000,
//             "Sales": 30
//         },
//         {
//             "Rank": 500000,
//             "Sales": 0
//         },
//         {
//             "Rank": 1000000,
//             "Sales": 0
//         }
//     ],
//     'Appliances': [
//         {
//             "Rank": 20,
//             "Sales": 2130
//         },
//         {
//             "Rank": 35,
//             "Sales": 1410
//         },
//         {
//             "Rank": 75,
//             "Sales": 750
//         },
//         {
//             "Rank": 100,
//             "Sales": 570
//         },
//         {
//             "Rank": 250,
//             "Sales": 210
//         },
//         {
//             "Rank": 500,
//             "Sales": 90
//         },
//         {
//             "Rank": 1000,
//             "Sales": 30
//         },
//         {
//             "Rank": 1500,
//             "Sales": 0
//         }
//     ],
//     'Arts, Crafts & Sewing': [
//         {
//             "Rank": 250,
//             "Sales": 6360
//         },
//         {
//             "Rank": 400,
//             "Sales": 4680
//         },
//         {
//             "Rank": 1000,
//             "Sales": 2430
//         },
//         {
//             "Rank": 2500,
//             "Sales": 1200
//         },
//         {
//             "Rank": 5000,
//             "Sales": 660
//         },
//         {
//             "Rank": 10000,
//             "Sales": 360
//         },
//         {
//             "Rank": 25000,
//             "Sales": 150
//         },
//         {
//             "Rank": 50000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Baby': [
//         {
//             "Rank": 250,
//             "Sales": 5640
//         },
//         {
//             "Rank": 500,
//             "Sales": 3720
//         },
//         {
//             "Rank": 1000,
//             "Sales": 2220
//         },
//         {
//             "Rank": 3000,
//             "Sales": 750
//         },
//         {
//             "Rank": 10000,
//             "Sales": 150
//         },
//         {
//             "Rank": 25000,
//             "Sales": 30
//         },
//         {
//             "Rank": 50000,
//             "Sales": 0
//         },
//     ],
//     'Baby Products': [
//         {
//             "Rank": 250,
//             "Sales": 5640
//         },
//         {
//             "Rank": 500,
//             "Sales": 3720
//         },
//         {
//             "Rank": 1000,
//             "Sales": 2220
//         },
//         {
//             "Rank": 3000,
//             "Sales": 750
//         },
//         {
//             "Rank": 10000,
//             "Sales": 150
//         },
//         {
//             "Rank": 25000,
//             "Sales": 30
//         },
//         {
//             "Rank": 50000,
//             "Sales": 0
//         },
//     ],
//     'Beauty & Personal Care': [
//         {
//             "Rank": 250,
//             "Sales": 17220
//         },
//         {
//             "Rank": 500,
//             "Sales": 11700
//         },
//         {
//             "Rank": 750,
//             "Sales": 9120
//         },
//         {
//             "Rank": 1000,
//             "Sales": 7560
//         },
//         {
//             "Rank": 2000,
//             "Sales": 4620
//         },
//         {
//             "Rank": 3000,
//             "Sales": 3390
//         },
//         {
//             "Rank": 10000,
//             "Sales": 1140
//         },
//         {
//             "Rank": 25000,
//             "Sales": 420
//         },
//         {
//             "Rank": 50000,
//             "Sales": 180
//         },
//         {
//             "Rank": 100000,
//             "Sales": 60
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Camera & Photo': [
//         {
//             "Rank": 250,
//             "Sales": 690
//         },
//         {
//             "Rank": 500,
//             "Sales": 330
//         },
//         {
//             "Rank": 750,
//             "Sales": 180
//         },
//         {
//             "Rank": 1000,
//             "Sales": 120
//         },
//         {
//             "Rank": 2000,
//             "Sales": 30
//         },
//         {
//             "Rank": 5000,
//             "Sales": 0
//         }
//     ],
//     'Cell Phones & Accessories': [
//         {
//             "Rank": 250,
//             "Sales": 11580
//         },
//         {
//             "Rank": 500,
//             "Sales": 7020
//         },
//         {
//             "Rank": 750,
//             "Sales": 5130
//         },
//         {
//             "Rank": 1000,
//             "Sales": 4050
//         },
//         {
//             "Rank": 2000,
//             "Sales": 2220
//         },
//         {
//             "Rank": 5000,
//             "Sales": 900
//         },
//         {
//             "Rank": 10000,
//             "Sales": 420
//         },
//         {
//             "Rank": 25000,
//             "Sales": 120
//         },
//         {
//             "Rank": 50000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Computers & Accessories': [
//         {
//             "Rank": 100,
//             "Sales": 2790
//         },
//         {
//             "Rank": 250,
//             "Sales": 1530
//         },
//         {
//             "Rank": 500,
//             "Sales": 900
//         },
//         {
//             "Rank": 750,
//             "Sales": 630
//         },
//         {
//             "Rank": 1000,
//             "Sales": 480
//         },
//         {
//             "Rank": 2000,
//             "Sales": 240
//         },
//         {
//             "Rank": 5000,
//             "Sales": 90
//         },
//         {
//             "Rank": 10000,
//             "Sales": 30
//         },
//         {
//             "Rank": 25000,
//             "Sales": 0
//         }
//     ],
//     'Electronics': [
//         {
//             "Rank": 100,
//             "Sales": 9270
//         },
//         {
//             "Rank": 250,
//             "Sales": 5280
//         },
//         {
//             "Rank": 500,
//             "Sales": 3270
//         },
//         {
//             "Rank": 1000,
//             "Sales": 1920
//         },
//         {
//             "Rank": 2000,
//             "Sales": 1050
//         },
//         {
//             "Rank": 5000,
//             "Sales": 420
//         },
//         {
//             "Rank": 10000,
//             "Sales": 210
//         },
//         {
//             "Rank": 25000,
//             "Sales": 60
//         },
//         {
//             "Rank": 50000,
//             "Sales": 30
//         },
//         {
//             "Rank": 100000,
//             "Sales": 0
//         }
//     ],
//     'Health & Household': [
//         {
//             "Rank": 100,
//             "Sales": 46650
//         },
//         {
//             "Rank": 500,
//             "Sales": 21540
//         },
//         {
//             "Rank": 1000,
//             "Sales": 14160
//         },
//         {
//             "Rank": 2000,
//             "Sales": 8760
//         },
//         {
//             "Rank": 5000,
//             "Sales": 4110
//         },
//         {
//             "Rank": 10000,
//             "Sales": 2100
//         },
//         {
//             "Rank": 25000,
//             "Sales": 750
//         },
//         {
//             "Rank": 50000,
//             "Sales": 300
//         },
//         {
//             "Rank": 100000,
//             "Sales": 90
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Home & Kitchen': [
//         {
//             "Rank": 100,
//             "Sales": 28650

//         },
//         {
//             "Rank": 500,
//             "Sales": 14280

//         },
//         {
//             "Rank": 1000,
//             "Sales": 9960

//         },
//         {
//             "Rank": 2000,
//             "Sales": 6660

//         },
//         {
//             "Rank": 5000,
//             "Sales": 3660
//         },
//         {
//             "Rank": 10000,
//             "Sales": 2160
//         },
//         {
//             "Rank": 25000,
//             "Sales": 990
//         },
//         {
//             "Rank": 50000,
//             "Sales": 510
//         },
//         {
//             "Rank": 100000,
//             "Sales": 240
//         },
//         {
//             "Rank": 250000,
//             "Sales": 90
//         },
//         {
//             "Rank": 500000,
//             "Sales": 30
//         },
//         {
//             "Rank": 1000000,
//             "Sales": 0
//         }
//     ],
//     "Industrial & Scientific": [
//         {
//             Rank: 100,
//             Sales: 16380,
//         },
//         {
//             Rank: 250,
//             Sales: 9210,
//         },
//         {
//             Rank: 500,
//             Sales: 5010,
//         },
//         {
//             Rank: 1000,
//             Sales: 3510,
//         },
//         {
//             Rank: 2000,
//             Sales: 2010,
//         },
//         {
//             Rank: 5000,
//             Sales: 900,
//         },
//         {
//             Rank: 10000,
//             Sales: 480,
//         },
//         {
//             Rank: 25000,
//             Sales: 180,
//         },
//         {
//             Rank: 50000,
//             Sales: 90,
//         },
//         {
//             Rank: 100000,
//             Sales: 30,
//         },
//         {
//             Rank: 150000,
//             Sales: 0,
//         },
//     ],
//     'Kitchen & Dining': [
//         {
//             "Rank": 100,
//             "Sales": 14880
//         },
//         {
//             "Rank": 250,
//             "Sales": 9450
//         },
//         {
//             "Rank": 500,
//             "Sales": 6390
//         },
//         {
//             "Rank": 1000,
//             "Sales": 4110
//         },
//         {
//             "Rank": 2000,
//             "Sales": 2520
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1200
//         },
//         {
//             "Rank": 10000,
//             "Sales": 660
//         },
//         {
//             "Rank": 25000,
//             "Sales": 270
//         },
//         {
//             "Rank": 50000,
//             "Sales": 120
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Musical Instruments': [
//         {
//             Rank: 100,

//             Sales: 2190,
//         },
//         {
//             Rank: 250,

//             Sales: 1290,
//         },
//         {
//             Rank: 500,

//             Sales: 810,
//         },
//         {
//             Rank: 1000,
//             Sales: 480,
//         },
//         {
//             Rank: 2000,
//             Sales: 270,
//         },
//         {
//             "Rank": 5000,
//             "Sales": 120
//         },
//         {
//             "Rank": 10000,
//             "Sales": 60
//         },
//         {
//             "Rank": 25000,
//             "Sales": 0
//         }
//     ],
//     'Office Products': [
//         {
//             "Rank": 100,
//             "Sales": 32010
//         },
//         {
//             "Rank": 250,
//             "Sales": 18390
//         },
//         {
//             "Rank": 500,
//             "Sales": 11520
//         },
//         {
//             "Rank": 1000,
//             "Sales": 6870
//         },
//         {
//             "Rank": 2000,
//             "Sales": 3870
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1650
//         },
//         {
//             "Rank": 10000,
//             "Sales": 810
//         },
//         {
//             "Rank": 25000,
//             "Sales": 270
//         },
//         {
//             "Rank": 50000,
//             "Sales": 120
//         },
//         {
//             "Rank": 100000,
//             "Sales": 30
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Pet Supplies': [
//         {
//             "Rank": 100,
//             "Sales": 22230
//         },
//         {
//             "Rank": 250,
//             "Sales": 13800
//         },
//         {
//             "Rank": 500,
//             "Sales": 9000
//         },
//         {
//             "Rank": 1000,
//             "Sales": 5520
//         },
//         {
//             "Rank": 2000,
//             "Sales": 3120
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1290
//         },
//         {
//             "Rank": 10000,
//             "Sales": 570
//         },
//         {
//             "Rank": 25000,
//             "Sales": 180
//         },
//         {
//             "Rank": 50000,
//             "Sales": 60
//         },
//         {
//             "Rank": 100000,
//             "Sales": 0
//         }
//     ],
//     'Tools & Home Improvement': [
//         {
//             "Rank": 100,
//             "Sales": 24450
//         },
//         {
//             "Rank": 250,
//             "Sales": 14130
//         },
//         {
//             "Rank": 500,
//             "Sales": 9000
//         },
//         {
//             "Rank": 1000,
//             "Sales": 5550
//         },
//         {
//             "Rank": 2000,
//             "Sales": 3300
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1560
//         },
//         {
//             "Rank": 10000,
//             "Sales": 840
//         },
//         {
//             "Rank": 25000,
//             "Sales": 360
//         },
//         {
//             "Rank": 50000,
//             "Sales": 180
//         },
//         {
//             "Rank": 100000,
//             "Sales": 60
//         },
//         {
//             "Rank": 250000,
//             "Sales": 30
//         },
//         {
//             "Rank": 500000,
//             "Sales": 0
//         }
//     ],
//     'Toys & Games': [
//         {
//             "Rank": 100,
//             "Sales": 10980
//         },
//         {
//             "Rank": 250,
//             "Sales": 7050
//         },
//         {
//             "Rank": 500,
//             "Sales": 4890
//         },
//         {
//             "Rank": 1000,
//             "Sales": 3300
//         },
//         {
//             "Rank": 2000,
//             "Sales": 2160
//         },
//         {
//             "Rank": 5000,
//             "Sales": 1200
//         },
//         {
//             "Rank": 10000,
//             "Sales": 750
//         },
//         {
//             "Rank": 25000,
//             "Sales": 330
//         },
//         {
//             "Rank": 50000,
//             "Sales": 150
//         },
//         {
//             "Rank": 100000,
//             "Sales": 60
//         },
//         {
//             "Rank": 250000,
//             "Sales": 0
//         }
//     ],
//     'Video Games': [
//         {
//             "Rank": 100,
//             "Sales": 5250
//         },
//         {
//             "Rank": 250,
//             "Sales": 2760
//         },
//         {
//             "Rank": 500,
//             "Sales": 1620
//         },
//         {
//             "Rank": 1000,
//             "Sales": 870
//         },
//         {
//             "Rank": 2000,
//             "Sales": 450
//         },
//         {
//             "Rank": 5000,
//             "Sales": 150
//         },
//         {
//             "Rank": 10000,
//             "Sales": 60
//         },
//         {
//             "Rank": 25000,
//             "Sales": 30
//         },
//         {
//             "Rank": 50000,
//             "Sales": 0
//         }
//     ],
//     'default': [
//         {
//             'Rank': 500,
//             'Sales': 3000,
//         },
//         {
//             'Rank': 1000,
//             'Sales': 2500,
//         },
//         {
//             'Rank': 2000,
//             'Sales': 2000,
//         },
//         {
//             'Rank': 3000,
//             'Sales': 1750,
//         },
//         {
//             'Rank': 4000,
//             'Sales': 1500,
//         },
//         {
//             'Rank': 5000,
//             'Sales': 1200,
//         },
//         {
//             'Rank': 7500,
//             'Sales': 1000,
//         },
//         {
//             'Rank': 10000,
//             'Sales': 800,
//         },
//         {
//             'Rank': 12500,
//             'Sales': 600,
//         },
//         {
//             'Rank': 15000,
//             'Sales': 500,
//         },
//         {
//             'Rank': 17500,
//             'Sales': 450,
//         },
//         {
//             'Rank': 20000,
//             'Sales': 400,
//         },
//         {
//             'Rank': 25000,
//             'Sales': 350,
//         },
//         {
//             'Rank': 30000,
//             'Sales': 300,
//         },
//         {
//             'Rank': 35000,
//             'Sales': 250,
//         },
//         {
//             'Rank': 40000,
//             'Sales': 200,
//         },
//         {
//             'Rank': 45000,
//             'Sales': 175,
//         },
//         {
//             'Rank': 50000,
//             'Sales': 150,
//         },
//         {
//             'Rank': 55000,
//             'Sales': 125,
//         },
//         {
//             'Rank': 60000,
//             'Sales': 100,
//         },
//         {
//             'Rank': 65000,
//             'Sales': 80,
//         },
//         {
//             'Rank': 70000,
//             'Sales': 70,
//         },
//         {
//             'Rank': 75000,
//             'Sales': 60,
//         },
//         {
//             'Rank': 80000,
//             'Sales': 50,
//         },
//         {
//             'Rank': 85000,
//             'Sales': 40,
//         },
//         {
//             'Rank': 90000,
//             'Sales': 30,
//         },
//         {
//             'Rank': 95000,
//             'Sales': 25,
//         },
//         {
//             'Rank': 100000,
//             'Sales': 22,
//         },
//         {
//             'Rank': 110000,
//             'Sales': 20,
//         },
//         {
//             'Rank': 120000,
//             'Sales': 18,
//         },
//         {
//             'Rank': 130000,
//             'Sales': 15,
//         },
//         {
//             'Rank': 140000,
//             'Sales': 12,
//         },
//         {
//             'Rank': 150000,
//             'Sales': 10,
//         },
//         {
//             'Rank': 160000,
//             'Sales': 8,
//         },
//         {
//             'Rank': 170000,
//             'Sales': 7,
//         },
//         {
//             'Rank': 180000,
//             'Sales': 6,
//         },
//         {
//             'Rank': 190000,
//             'Sales': 5,
//         },
//         {
//             'Rank': 200000,
//             'Sales': 5,
//         },
//         {
//             'Rank': 210000,
//             'Sales': 4,
//         },
//         {
//             'Rank': 220000,
//             'Sales': 4,
//         },
//         {
//             'Rank': 230000,
//             'Sales': 3,
//         },
//         {
//             'Rank': 240000,
//             'Sales': 3,
//         },
//         {
//             'Rank': 250000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 260000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 270000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 280000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 290000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 300000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 350000,
//             'Sales': 2,
//         },
//         {
//             'Rank': 400000,
//             'Sales': 1,
//         },
//         {
//             'Rank': 500000,
//             'Sales': 1,
//         },
//         {
//             'Rank': 750000,
//             'Sales': 0.5,
//         },
//         {
//             'Rank': 1000000,
//             'Sales': 0.5,
//         },
//     ]
// }

// export const categorySales = {
//     "Clothing, Shoes & Jewelry": 1,
//     "Sports & Outdoors": 1,
//     "Patio, Lawn & Garden": 1,
//     "Grocery & Gourmet Food": 1,
//     "Automotive": 1,
//     "Appliances": 1,
//     "Arts, Crafts & Sewing": 1,
//     "Baby": 1,
//     "Baby Products": 1,
//     "Beauty & Personal Care": 1,
//     "Camera & Photo": 1,
//     "Cell Phones & Accessories": 1,
//     "Computers & Accessories": 1,
//     "Electronics": 1,
//     "Health & Household": 1,
//     "Home & Kitchen": 1,
//     "Industrial & Scientific": 1,
//     "Kitchen & Dining": 1,
//     "Musical Instruments": 1,
//     "Office Products": 1,
//     "Pet Supplies": 1,
//     "Tools & Home Improvement": 1,
//     "Toys & Games": 1,
//     "Video Games": 1,
//     "default": 1
// }

// export const categoryIds = {
//     5174: "CDs & Vinyl",
//     172282: "Electronics",
//     228013: "Tools & Home Improvement",
//     229534: "Software",
//     283155: "Books",
//     468642: "Video Games",
//     599858: "Magazine Subscriptions",
//     1055398: "Home & Kitchen",
//     1064954: "Office Products",
//     3375251: "Sports & Outdoors",
//     3760901: "Health & Household",
//     3760911: "Beauty & Personal Care",
//     10272111: "Everything Else",
//     11091801: "Musical Instruments",
//     15684181: "Automotive",
//     16310091: "Industrial & Scientific",
//     16310101: "Grocery & Gourmet Food",
//     133140011: "Kindle Store",
//     163856011: "Digital Music",
//     165793011: "Toys & Games",
//     165796011: "Baby Products",
//     2335752011: "Cell Phones & Accessories",
//     2350149011: "Apps & Games",
//     2617941011: "Arts, Crafts & Sewing",
//     2619525011: "Appliances",
//     2619533011: "Pet Supplies",
//     2625373011: "Movies & TV",
//     2972638011: "Patio, Lawn & Garden",
//     10677469011: "Vehicles",
//     9013971011: "Video Shorts",
//     13727921011: "Alexa Skills",
//     18145289011: "Audible Books & Originals",
//     7141123011: "Clothing, Shoes & Jewelry",
//     4991425011: "Collectibles & Fine Art",
//     11260432011: "Handmade Products",
// };

export const DownloadEstSalesData = async () => {
    const result = await fetch(`${BASE_URL}/keepa/currentEstimateLevels?key=${BrandApiKey}`).then((res) => res.json())

    localStorage.setItem('mainSalesRankCorrelation', JSON.stringify(result.mainSalesRankCorrelation));
    localStorage.setItem('categorySales', JSON.stringify(result.categorySales));
    localStorage.setItem('categoryIds', JSON.stringify(result.categoryIds));
}

export const GetCategoryEstSalesData = () => {
    const mainSalesRankCorrelationFromStorage = localStorage.getItem('mainSalesRankCorrelation');
    const categorySalesFromStorage = localStorage.getItem('categorySales');
    const categoryIdsFromStorage = localStorage.getItem('categoryIds');

    return {
        mainSalesRankCorrelation: JSON.parse(mainSalesRankCorrelationFromStorage),
        categorySales: JSON.parse(categorySalesFromStorage),
        categoryIds: JSON.parse(categoryIdsFromStorage),
    };
}