import { useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import { useAuth } from '../../../contexts/AuthContext';
import { getAvailableSuppliers } from '../../../services/WholesaleService';
import { Supplier } from '../../../types/OmegaTypes';

export function useSuppliers() {
    const { currentUser } = useAuth();
    const query = useQuery({
        queryKey: ['supplier_names'],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const names = (await getAvailableSuppliers(token)) as Supplier[];
            return names;
        },
    });

    if (query.error) {
        notification.error({
            message: 'Error',
            // @ts-ignore
            description: query.error.message,
        });
    }

    return query;
}
