import { KeepaProduct } from "../../../types/KeepaTypes"
import { GetCategoryEstSalesData } from "../../brand/SalesRankConstants"

export function getActualCategory(item: KeepaProduct) {
    const categoryIds = GetCategoryEstSalesData().categoryIds
    // sales rank reference history will be the easiest to determine
    if (item.salesRankReferenceHistory) {
        return [...item.salesRankReferenceHistory].map((catId => categoryIds[catId])).filter((el) => el).pop()
        // return categoryIds[[...item.salesRankReferenceHistory].pop()! as keyof typeof categoryIds]
    } 
    
    if (item.salesRankReference && item.categoryTree) {
        // otherwise let's try grabbing the salesRankReference, this should exist for all items
        const categoryId = item.salesRankReference
        const categoryTree = item.categoryTree

        // the category tree should contain the ID-Name correlation we're looking for in our item
        const category = categoryTree.find(cat => cat.catId === categoryId)
        if (category) {
            return category.name
        }
    }
    
    // if the category tree didn't contain the category, fall back to our own ids
    if (item.salesRankReference) {
        const categoryId = item.salesRankReference
        const category: string = categoryIds[categoryId as keyof typeof categoryIds]

        if (category) {
            return category;
        }
    }

    // fall back to our previous method
    return ((typeof item.rootCategory === 'string' ? item.rootCategory : categoryIds[item.rootCategory as keyof typeof categoryIds]) || 'default')
}