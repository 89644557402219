import { Col, Row, Statistic } from "antd";
import React from "react";
import { OrderSummaryByDaysData } from "../../types/OmegaTypes";

// const OrdersPopover: React.FC<{ invHealthItem: InventoryHealthItem }> = ({ invHealthItem }) => {
//     return (
//         <div style={{width: '400px'}}>
//             <Row justify="center" style={{ width: '100%', height: '50%', textAlign: 'center' }}>
//                 <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
//                     <Statistic title="7 Days" value={invHealthItem["units-shipped-t7"] ?? -1} precision={0} />
//                 </Col>
//                 <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
//                     <Statistic title="30 Days" value={invHealthItem["units-shipped-t30"] ?? -1} precision={0} />
//                 </Col>
//                 <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
//                     <Statistic title="60 Days" value={invHealthItem["units-shipped-t60"] ?? -1} precision={0} />
//                 </Col>
//                 <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
//                     <Statistic title="90 Days" value={invHealthItem["units-shipped-t90"] ?? -1} precision={0} />
//                 </Col>
//                 <Col span={8} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
//                     <Statistic title="Snap Date" value={invHealthItem["snapshot-date"]}/>
//                 </Col>
//             </Row>
//         </div>
//     )
// }


const OrdersPopover: React.FC<{ ordersData: OrderSummaryByDaysData }> = ({ ordersData }) => {
    return (
        <div style={{width: '400px'}}>
            <Row justify="center" style={{ width: '100%', height: '50%', textAlign: 'center' }}>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="7 Days" value={ordersData["7"].units} precision={0} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="14 Days" value={ordersData["14"].units} precision={0} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="30 Days" value={ordersData["30"].units} precision={0} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="60 Days" value={ordersData["60"].units} precision={0} />
                </Col>
                <Col span={4} style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                    <Statistic title="90 Days" value={ordersData["90"].units} precision={0} />
                </Col>
            </Row>
        </div>
    )
}

export default OrdersPopover