import React, {forwardRef, useEffect, useLayoutEffect, useRef} from "react";
import Quill from "quill";

const toolbarOptions = [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['link', 'image'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    ['clean']                                         // remove formatting button
  ];

// Editor is an uncontrolled React component
const Editor = forwardRef(({readOnly, defaultValue, onTextChange, onSelectionChange}, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange;
        onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
        const container = containerRef.current;
        const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
        const quill = new Quill(editorContainer, {
            modules: {
                toolbar: toolbarOptions,
            },
            theme: "snow",
        });

        ref.current = quill;
        ref.current?.enable(!readOnly)

        if (defaultValueRef.current) {
            quill.setContents(defaultValueRef.current);
        }

        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
            onTextChangeRef.current?.(...args);
        });

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
            onSelectionChangeRef.current?.(...args);
        });

        return () => {
            ref.current = null;
            container.innerHTML = "";
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return <div ref={containerRef}></div>;
});

Editor.displayName = "Editor";

export default Editor;
