import React, {useEffect, useState} from "react";
import {Modal, List, Button, Row, Col, Skeleton, Typography, Card, Select, Image} from "antd";
import {ReturnProduct, ReturnAction, RETURN_ACTION_LOOKUP} from "../../../types/WarehouseTypes";

interface ExtractedItemsDialogProps {
    items: ReturnProduct[];
    open: boolean;
    onClose: () => void;
    onOk: (updatedItem: ReturnProduct) => void;
}

const properties: {label: string; key: keyof ReturnProduct}[] = [
    {label: "Name", key: "name"},
    {label: "ASIN", key: "asin"},
    {label: "SKU", key: "sku"},
    {label: "Size", key: "size"},
    {label: "Order ID", key: "order-id"},
    {label: "Quantity", key: "quantity"},
    {label: "Price", key: "price"},
    {label: "Reason", key: "reason"},
    {label: "Status", key: "status"},
    {label: "Customer comments", key: "customer-comments"},
];

const actionOptions = [
    {value: "relist", label: RETURN_ACTION_LOOKUP["relist"]},
    {value: "mf-used", label: RETURN_ACTION_LOOKUP["mf-used"]},
    {value: "other-sell-elsewhere", label: RETURN_ACTION_LOOKUP["other-sell-elsewhere"]},
    {value: "damage-discard", label: RETURN_ACTION_LOOKUP["damage-discard"]},
    {value: "fraud-discard", label: RETURN_ACTION_LOOKUP["fraud-discard"]},
    {value: "fraud-keep", label: RETURN_ACTION_LOOKUP["fraud-keep"]},
];

const ExtractedItemsDialog: React.FC<ExtractedItemsDialogProps> = ({items, open, onClose, onOk}) => {
    const [selectedItem, setSelectedItem] = useState<ReturnProduct | null>(null);
    const [action, setAction] = useState<ReturnAction>("relist");

    useEffect(() => {
        if (open && items.length > 0) {
            setSelectedItem(items[0]);
            setAction("relist");
        } else {
            setSelectedItem(null);
        }
    }, [open, items]);

    const handleOk = () => {
        if (!selectedItem) return;
        const updatedItem = {...selectedItem, action};
        onOk(updatedItem);
    };

    const renderStatRow = (item: ReturnProduct, key: keyof ReturnProduct, label: string) => (
        <List.Item style={{padding: 4}}>
            <Row style={{width: "100%"}}>
                <Col span={6} style={{justifyContent: "end", width: "100%"}}>
                    <Typography.Text type="secondary" style={{fontSize: 10}}>
                        {label}
                    </Typography.Text>
                </Col>
                <Col span={18}>
                    <Typography.Paragraph style={{padding: 0, margin: 0}} ellipsis={{rows: 1, expandable: true}}>
                        {item[key] ?? "n/a"}
                    </Typography.Paragraph>
                </Col>
            </Row>
        </List.Item>
    );

    return (
        <Modal title="Return Products" open={open} onOk={handleOk} onCancel={onClose} style={{maxHeight: "60vh"}}>
            <List
                dataSource={items}
                style={{maxHeight: "50vh", overflow: "auto"}}
                renderItem={(item) => (
                    <Card
                        style={{
                            border: item === selectedItem ? "2px solid #28682EFF" : "2px solid #00000000",
                            borderRadius: "5px",
                        }}
                    >
                        <Row style={{width: "100%", paddingLeft: 4}}>
                            <Col span={9} style={{paddingRight: 8}}>
                                <Row>
                                    {!item.imgURL ? (
                                        <Skeleton.Image style={{height: 130}} />
                                    ) : (
                                        <Image width={"100%"} height={"auto"} style={{borderRadius: 4}} src={item.imgURL} />
                                    )}
                                </Row>
                                <Row style={{marginTop: 10, width: "100%"}}>
                                    {item === selectedItem ? null : (
                                        <Button onClick={() => setSelectedItem(item)} style={{width: 130, height: 50}}>
                                            Select
                                        </Button>
                                    )}
                                </Row>
                            </Col>
                            <Col span={15}>
                                <List
                                    dataSource={properties}
                                    renderItem={(property) => renderStatRow(item, property.key, property.label)}
                                />
                            </Col>
                        </Row>
                    </Card>
                )}
            />
            <Row style={{marginTop: 16}}>
                <Col span={12} style={{textAlign: "end", width: "100%", paddingTop: 4}}>
                    <Typography.Text style={{marginTop: 10, marginRight: 10}}>Action:</Typography.Text>
                </Col>
                <Col span={12}>
                    <Select
                        value={action}
                        onChange={(value: ReturnAction) => setAction(value)}
                        style={{width: "100%"}}
                        options={actionOptions}
                        placement="topLeft"
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default ExtractedItemsDialog;
