import React from "react";
import { Select } from "antd";
import { useQuery } from "@tanstack/react-query";

const CollectionsDropdown: React.FC<{ onChange: ((value: any) => void), getCollections: ((value: any) => Promise<string[]>), queryKey?: string, value?: string}> = ({ onChange, getCollections, queryKey = 'default', value }) => {
  const { data } = useQuery({queryKey: ["collections", queryKey], queryFn: getCollections});
  
  return (
    <Select 
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      {...(value ? {value: value} : {})} 
      defaultValue="Choose collection..." 
      onChange={onChange} 
      style={{width:"100%"}}
    >
      {[...(data || [])].sort().map((collection, i) => (
        <Select.Option  value={collection} key={i}>
          {collection.toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CollectionsDropdown;
