import dayjs from "dayjs";
import * as dataForge from 'data-forge'
import { downloadHelper } from "./Downloads";
import { message } from "antd";
import { sleep } from "../../../services/common";

async function getRestrictions(asins: string[]) {
    const baseUrl = `https://api.projectsuite.io/spApi/restrictions/getRestrictions?key=maximumsecurity`;

    const resp = await fetch(baseUrl, {
        method: 'POST',
        body: JSON.stringify(asins.map((asin) => ({ asin: asin }))),
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((res) => res.json())

    return resp.result;
}

const MAX_ERROR_COUNT = 5

export const processDataRestrictionHelper = async (data: ArrayBuffer[]) => {
    console.log('processing');

    try {
        let fullInput: any[] = []
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData)

            console.log(keepaFrame.first())

            fullInput = fullInput.concat(keepaFrame.toArray())
        }

        const inputAsins = new dataForge.DataFrame(fullInput).subset(["ASIN"]).distinct().toArray().map((row) => row.ASIN.trim());
        const resultData: any[] = []
        let errorCount = 0;
        let i; let temparray; const chunk = 10;
        for (i = 0; i < inputAsins.length && errorCount < MAX_ERROR_COUNT; i += chunk) {
            temparray = inputAsins.slice(i, i + chunk);
            try {
                const queryResult = await getRestrictions(temparray);
                if (queryResult) {
                    resultData.push(...Object.entries(queryResult).map(([asin, isRestricted]) => ({ asin, isRestricted })))
                } else {
                    // throttled probably
                    await sleep(2500)
                    i -= chunk;
                }
            } catch (ex) {
                i -= chunk;
                errorCount += 1;
                await sleep(2500)
            }

            await sleep(1000)
        }

        console.log(resultData)
        const resultMap = resultData.reduce((acc, val) => ((acc[val.asin] = val.isRestricted, acc)), {})
        if (errorCount >= MAX_ERROR_COUNT) {
            message.error(`The run has errored out, please contact Karol`)
        } else {
            if (inputAsins.some((asin) => !resultData.map((val) => val.asin).includes(asin))) {
                message.error(`Some of the ASINs have not returned any data, please double-check if any were missed!`)
            }

            const finalResult = fullInput.filter((row) => resultMap[row.ASIN] === false);
            downloadHelper(finalResult, `KeepaClearOfRestrictions_${dayjs().format('YYYY-MM-DD')}.csv`)
        }
    } catch (ex) {
        console.log(ex);
        message.error('Failed to process! Please send file to Karol.')
    }
}