import { Card, Divider, Space, Statistic } from "antd";
import React from "react"
import { WholesaleItem } from "../../../types/WholesaleItem";
import './Popover.scss';

const SellPricePopover: React.FC<WholesaleItem> = React.memo((record: WholesaleItem) => {
    const val = record["Buy Box Seller"]
    return (
        <Space direction="horizontal">
            <Card size="small">
                <Statistic title="Current BuyBox:" value={record.currentBuyBoxPrice} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Statistic title="BuyBox Owner:" value={(val && val !== '' ? (val.includes('Party') ? '3rdParty' : val) : '') + (` (FBA: ${record["Buy Box: Is FBA"] || 'unknown'})`)} />
            </Card>
            <Card size="small">
                <Statistic title="BuyBox (30 days):" value={record.buyBox30Avg} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Statistic title="BuyBox (90 days):" value={record.buyBox90Avg} precision={2} prefix={'$'}/>
            </Card>
        </Space>
    )
})

export default SellPricePopover;