import { Button, InputRef, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import React, { useEffect, useRef, useState } from "react";
import { useOmega } from "../../contexts/OmegaContext";
import { ReplenItem } from "../../types/OmegaTypes";
import FilterDropdown from "../utilities/FilterDropdown";
import { getNumberFilterSorter, getNumberRenderer, getPriceRenderer } from "../utilities/TableFilterSorters";
import * as dataForge from "data-forge"
import dayjs from "dayjs";

interface DataType extends ReplenItem {
    key: React.Key;
}

function search(dataIndex: keyof ReplenItem, value: any, record: ReplenItem) {
    return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
}

function getSearch(dataIndex: keyof ReplenItem) {
    return (value: any, record: ReplenItem) => search(dataIndex, value, record)
}

const download = (data: any[]) => {
    if (!data || !data.length) return;

    const text = (new dataForge.DataFrame(data)).toCSV()

    const file = new Blob([text], { type: "text/plain;charset=utf-8" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = 'Omega_Replen_' + dayjs().format('MM-DD-YY') + ".csv";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
};

const ReplenBrowser: React.FC = () => {
    const [tableData, setTableData] = useState<(ReplenItem & { key: number })[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const searchInputRef = useRef<InputRef>(null);

    const { replenData, setReplenData } = useOmega();

    console.log('ReplenBrowser', replenData)

    const columns: ColumnsType<DataType> = [
        {
            title: 'SKU',
            dataIndex: 'SKU',
            key: 'SKU',
            width: "110px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('SKU'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
            width: "220px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('Title'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'ASIN',
            dataIndex: 'ASIN',
            key: 'ASIN',
            width: "110px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('ASIN'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'UPC',
            dataIndex: 'UPC',
            key: 'UPC',
            width: "120px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('UPC'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'Supplier_SKU',
            dataIndex: 'Supplier_SKU',
            key: 'Supplier_SKU',
            width: "120px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('Supplier_SKU'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'Supplier_Title',
            dataIndex: 'Supplier_Title',
            key: 'Supplier_Title',
            width: "120px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('Supplier_Title'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'Supplier_Notes',
            dataIndex: 'Supplier_Notes',
            key: 'Supplier_Notes',
            width: "120px",
            filterDropdown: (props: FilterDropdownProps) => (
                <FilterDropdown {...props} inputRef={searchInputRef} />
            ),
            onFilter: getSearch('Supplier_Notes'),
            onFilterDropdownOpenChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
        },
        {
            title: 'Cost',
            dataIndex: 'Cost',
            key: 'Cost',
            width: "110px",
            ...getNumberFilterSorter('Cost'),
            ...getPriceRenderer(),
        },
        {
            title: 'antBBShare',
            dataIndex: 'antBBShare',
            key: 'antBBShare',
            width: "110px",
            ...getNumberFilterSorter('antBBShare'),
            render: (val, record) => <Typography>{`${(val * 100).toFixed(0)}%`}</Typography>,
        },
        {
            title: 'targetDIS',
            dataIndex: 'targetDIS',
            key: 'targetDIS',
            width: "110px",
            ...getNumberFilterSorter('targetDIS'),
            ...getNumberRenderer(),
        },
        {
            title: 'antVelocity',
            dataIndex: 'antVelocity',
            key: 'antVelocity',
            width: "110px",
            ...getNumberFilterSorter('antVelocity'),
            render: (val, record) => <Typography>{`${(val * 100).toFixed(0)}%`}</Typography>,
        },
        {
            title: 'antStock',
            dataIndex: 'antStock',
            key: 'antStock',
            width: "110px",
            ...getNumberFilterSorter('antStock'),
            ...getNumberRenderer(),
        },
        {
            title: 'antAllSales',
            dataIndex: 'antAllSales',
            key: 'antAllSales',
            width: "120px",
            ...getNumberFilterSorter('antAllSales'),
            ...getNumberRenderer(),
        },
        {
            title: 'Replen QTY',
            dataIndex: 'replenQty',
            key: 'replenQty',
            width: "110px",
            ...getNumberFilterSorter('replenQty'),
            ...getNumberRenderer(),
        },
    ]

    useEffect(() => {
        setTableData(Object.values(replenData).map((replen, idx) => ({ ...replen, key: idx })))
    }, [replenData])

    const deleteReplens = () => {
        console.log('deleting replen', selectedRowKeys)
        const newNoteSet = tableData.filter((row) => !selectedRowKeys.includes(row.key)).map(({ key, ...note }) => note)
        setReplenData(Object.fromEntries(newNoteSet.map((note) => ([note.SKU, note]))));
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table<DataType>
            rowClassName={() => 'editable-row'}
            scroll={{ y: "80vh", x: "max-content" }}
            sticky={true}
            bordered={true}
            size="small"
            dataSource={tableData}
            title={() =>
                <Space direction="horizontal" style={{ width: '100%', justifyContent: "end" }}>
                    <Button onClick={() => download(tableData)} type="primary">Export Replen File</Button>
                    <Button onClick={deleteReplens} type="primary" danger>DELETE SELECTED</Button>
                </Space>
            }
            pagination={{
                pageSizeOptions: ["25", "50", "100"],
                defaultPageSize: 25,
                showSizeChanger: true,
            }}
            rowSelection={rowSelection}
            columns={columns}
            showSorterTooltip={false}
        >
        </Table>
    )
}

export default ReplenBrowser